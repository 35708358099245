import {PageNamesEnum} from 'enums';

export enum RoutesEnum {
  HOME = '/',
  LEADS = `/${PageNamesEnum.LEADS}`,
  LEADS_DETAILED = `/${PageNamesEnum.LEADS}/:mode/:id?`,
  LEADS_VIEW = `/${PageNamesEnum.LEADS}/view/:id?`,
  BUILDINGS = `/${PageNamesEnum.BUILDINGS}`,
  BUILDING_DETAILED = `/${PageNamesEnum.BUILDINGS}/:mode/:id?`,
  BUILDING_VIEW = `/${PageNamesEnum.BUILDINGS}/view/:id?`,
  PROPERTIES = `/${PageNamesEnum.PROPERTIES}`,
  PROPERTIES_DETAILED = `/${PageNamesEnum.PROPERTIES}/:mode/:id?`,
  PROPERTIES_VIEW = `/${PageNamesEnum.PROPERTIES}/view/:id?`,
  INQUIRIES = `/${PageNamesEnum.INQUIRIES}`,
  INQUIRIES_DETAILED = `/${PageNamesEnum.INQUIRIES}/:id?`,
  PERSONS = `/${PageNamesEnum.PERSONS}`,
  PERSONS_DETAILED = `/${PageNamesEnum.PERSONS}/:mode/:id`,
  ACCOUNTS = `/${PageNamesEnum.ACCOUNTS}`,
  SETTINGS = `/${PageNamesEnum.SETTINGS}`,
  ANALYTICS = `/${PageNamesEnum.ANALYTICS}`,
  LOGIN = `/${PageNamesEnum.LOGIN}`,
  NOT_FOUND = `/${PageNamesEnum.NOT_FOUND}`,
}
