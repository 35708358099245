import qs from 'qs';

import {TServerResponse} from 'types/common';
import {Fetcher} from 'utils';
import {ApiUrl} from 'services/constants';
import {TAuthResponse} from 'types/api';

const fetcher = new Fetcher({baseURL: `${process.env.REACT_APP_API_URL}`});

export const postAuthService = ({email, password}: any): Promise<TServerResponse<TAuthResponse>> =>
  fetcher.request({
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    url: ApiUrl.login,
    data: qs.stringify({email, password}),
  });
