/* eslint-disable import/order */
import {SlideshowLightbox} from 'lightbox.js-react';
import {SlideshowLightboxProps} from 'lightbox.js-react/dist/SlideshowLightbox';
import 'lightbox.js-react/dist/index.css';
import {memo} from 'react';

const PLightbox = ({children, ...props}: SlideshowLightboxProps) => {
  return (
    <SlideshowLightbox
      {...props}
      imgAnimation="fade"
      animateThumbnails={false}
      downloadImages
      captionStyle={{fontSize: '20px', color: 'white'}}
    >
      {children}
    </SlideshowLightbox>
  );
};

export const PLightboxMemoized = memo(PLightbox);
