import {Col, Form, Row} from 'antd';
import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {PButton} from 'components/primitives/p-button';
import {PInput} from 'components/primitives/p-input';
import {PSelect} from 'components/primitives/p-select';
import {accountTypeOptions} from 'constants/select-options';
import {getAccountsAction} from 'store/account/actions';
import {AccountFieldsEnum} from 'components/forms/account/form-account.enums';
import {PPhoneInput} from 'components/primitives/p-phone-input';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {FormsEnum} from 'enums';
import {CSubmitButton} from 'components/containers/c-submit-button';
import {Icon} from 'assets/icons';
import {formatPhoneNumber} from 'utils';

import {IAccountsFilterFields} from './form-accounts-filter.interfaces';

export const FormAccountsFilter = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [isFormSubmited, setIsFormSubmited] = useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [form] = Form.useForm<IAccountsFilterFields>();
  const values = Form.useWatch([], form);

  const handleSubmit = useCallback(
    (values: IAccountsFilterFields) => {
      setIsButtonLoading(true);

      dispatch(
        getAccountsAction(
          {...values, phone: formatPhoneNumber(values.phone!)},
          {onFulfilled: () => setIsButtonLoading(false), onReject: () => setIsButtonLoading(false)}
        )
      );

      setIsFormSubmited(true);
    },
    [dispatch]
  );

  const handleFilterClear = useCallback(() => {
    dispatch(getAccountsAction());

    form.resetFields();

    setIsFormSubmited(false);
  }, [form, dispatch]);

  return (
    <Form form={form} onFinish={handleSubmit} name={FormsEnum.ACCOUNTS_FILTER}>
      <Row gutter={16}>
        <Col>
          <Form.Item name={AccountFieldsEnum.FIRST_NAME} rules={[{required: false}]}>
            <PInput label={t('form.firstName')} id={AccountFieldsEnum.FIRST_NAME} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name={AccountFieldsEnum.PHONE}
            rules={[
              {required: false},
              {min: 9, message: fieldsMessageTranslations.phoneMin},
              {max: 14, message: fieldsMessageTranslations.phoneMax},
            ]}
          >
            <PPhoneInput label={t('form.phone')} id={AccountFieldsEnum.PHONE} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name={AccountFieldsEnum.EMAIL}
            rules={[
              {
                type: 'email',
                message: fieldsMessageTranslations.validEmail,
              },
              {required: false},
            ]}
          >
            <PInput label={t('form.email')} id={AccountFieldsEnum.EMAIL} type="email" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={AccountFieldsEnum.TYPE} rules={[{required: false}]}>
            <PSelect label={t('form.userType')} options={accountTypeOptions} />
          </Form.Item>
        </Col>
        <Col>
          <CSubmitButton
            allowValidate={false}
            form={form}
            values={values}
            isButtonLoading={isButtonLoading}
            title={t('actions.filter')}
            icon={<Icon.Filter fill="white" />}
          />
        </Col>
        <Col>
          {isFormSubmited && !isButtonLoading && (
            <PButton htmlType="button" type="text" onClick={handleFilterClear}>
              {t('actions.clear')}
            </PButton>
          )}
        </Col>
      </Row>
    </Form>
  );
};
