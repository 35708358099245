import {Navigate, useLocation} from 'react-router-dom';
import {ReactElement, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {Route} from 'router/routes';
import {Sidebar} from 'components/layout/sidebar/sidebar';
import {getOptGroupsAction} from 'store/app/actions';

export const PrivateRoute = ({children}: {children: ReactElement<any, any>}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [authorized, setAuthorized] = useState<string>();
  const token = localStorage.getItem('TOKEN');

  useEffect(() => {
    dispatch(getOptGroupsAction({}));
  }, [dispatch]);

  useEffect(() => {
    setAuthorized(token!);
    setLoading(false);
  }, [token]);

  return !authorized && !isLoading ? (
    <Navigate to={Route.login.path} state={{from: location}} replace />
  ) : token ? (
    <>
      <Sidebar />
      <main>{children}</main>
    </>
  ) : null;
};
