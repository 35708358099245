import {memo} from 'react';
import {NavLink} from 'react-router-dom';
import {Tooltip} from 'antd';

import {PNavItemProps} from './p-nav-item.types';
import {CSS} from './p-nav-item.cssconfig';

const PNavItem = ({label, href, expanded, icon, asButton, textColor, onClick}: PNavItemProps) => {
  return asButton ? (
    <div className={CSS.button} onClick={onClick}>
      {!expanded ? (
        <Tooltip title={label} placement="right">
          {icon}
        </Tooltip>
      ) : (
        icon
      )}
      {expanded && <div className={`${CSS.text.base} ${textColor ? `${textColor}` : CSS.text.color}`}>{label}</div>}
    </div>
  ) : (
    <NavLink
      to={href!}
      className={({isActive}) =>
        `${CSS.link.base} ${isActive ? `${CSS.link.active}` : ''} ${!expanded ? CSS.link.collapsed : ''}`
      }
      onClick={onClick}
    >
      {!expanded ? (
        <Tooltip title={label} placement="right">
          {icon}
        </Tooltip>
      ) : (
        icon
      )}

      {expanded && <div className={`${CSS.text.base} ${textColor ? `${textColor}` : CSS.text.color}`}>{label}</div>}
    </NavLink>
  );
};

export const PNavItemMemoized = memo(PNavItem);
