const rotateSize = (width: number, height: number, rotation: number): Record<string, number> => {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
};

const cropImage = async (
  imageSrc: any,
  pixelCrop: any,
  rotation = 0,
  flip = {horizontal: false, vertical: false}
): Promise<HTMLCanvasElement | null> => {
  const image = (await createImage(imageSrc)) as any;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  const {width: bBoxWidth, height: bBoxHeight} = rotateSize(image.width, image.height, rotation);

  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  ctx.drawImage(image, 0, 0);

  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(data, 0, 0);

  return canvas;
};

const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

const getRadianAngle = (degreeValue: number) => (degreeValue * Math.PI) / 180;

export const getCroppedImage = async (imageSrc: any, crop: any, rotation: number, originalFile: any) => {
  if (!crop || !imageSrc) {
    return;
  }

  const canvas = (await cropImage(imageSrc, crop, rotation)) as any;

  return new Promise((resolve, reject) => {
    canvas.toBlob((item: any) => {
      item.name = originalFile.name;

      resolve({item, url: URL.createObjectURL(item)});
    }, originalFile.type || 'image/jpeg');
  });
};
