export const getFullAddress = ({
  location,
  number,
  showCountry = true,
  showRegion = true,
  showSettlement = true,
  showDistrict = true,
  showNumber = true,
}: {
  location: any;
  number?: string;
  showCountry?: boolean;
  showRegion?: boolean;
  showSettlement?: boolean;
  showDistrict?: boolean;
  showNumber?: boolean;
}): string => {
  const fullAddressParts = [
    (showCountry && location?.country?.name) || '',
    showRegion && location?.region?.name && location?.region?.name !== location?.settlement?.name
      ? location?.region?.name
      : '',
    (showSettlement && location?.settlement?.name) || '',
    (showDistrict && location?.district?.name) || '',
    location?.street?.name || '',
    (showNumber && location?.address?.number) || '',
    (showNumber && location?.address?.number !== number && number) || '',
  ];

  return fullAddressParts.filter(p => p !== '').join(', ');
};
