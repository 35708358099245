import {memo, useCallback, useEffect, useState} from 'react';
import {Form} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {PopupBase} from 'components/popups/base';
import {Icon} from 'assets/icons';
import {FormPropertyAddons} from 'components/forms/property-addons';
import {IPropertyAddonsFields} from 'components/forms/property-addons/form-property-addons.interfaces';
import {clearMediaAction, getFolderByIdAction} from 'store/app/actions';
import {appFloorFileSelector, appFloorImageSelector, appRoomImageSelector} from 'store/app/selectors';
import {ActionModesEnum, AddonsEnum, FoldersEnum, HandledFieldsEnum} from 'enums';
import {RoomNameTranslationEnum} from 'enums/translations';
import {PropertyAddonsFieldsEnum} from 'components/forms/property-addons/form-property-addons.enums';
import {isWidgetNotValidated} from 'utils';

import {IPropertyPopupProps} from './popup-property-addons.interfaces';

const PopupPropertyAddons = ({
  mode,
  type = 'rooms',
  entity,
  id,
  onClose,
  setAttachedRooms,
  setAttachedFloors,
}: IPropertyPopupProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm<IPropertyAddonsFields>();
  const [isSubmittable, setIsSubmittable] = useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [handledFileds, setHandledFields] = useState<Record<string, boolean | undefined>>({
    [HandledFieldsEnum.IS_FILES_ATTACHED]: undefined,
    [HandledFieldsEnum.IS_IMAGES_ATTACHED]: undefined,
    [HandledFieldsEnum.IS_IMAGES_EMPTY]: undefined,
    [HandledFieldsEnum.IS_FILES_EMPTY]: undefined,
  });
  const values = Form.useWatch([], form);
  const roomImage = useSelector(appRoomImageSelector);
  const floorImage = useSelector(appFloorImageSelector);
  const floorFile = useSelector(appFloorFileSelector);

  const handleSubmit = useCallback(
    (values: IPropertyAddonsFields) => {
      if (
        isWidgetNotValidated({
          isFieldHandled: handledFileds[HandledFieldsEnum.IS_IMAGES_ATTACHED],
          querySelecor: 'image-upload-complete-addon',
          state: HandledFieldsEnum.IS_IMAGES_ATTACHED,
          setState: setHandledFields,
        })
      )
        return;

      if (
        isWidgetNotValidated({
          isFieldHandled: handledFileds[HandledFieldsEnum.IS_FILES_ATTACHED],
          querySelecor: 'file-upload-complete-addon',
          state: HandledFieldsEnum.IS_FILES_ATTACHED,
          setState: setHandledFields,
        })
      )
        return;

      setIsButtonLoading(true);

      const {name_other} = values;

      try {
        const state = {
          ...values,
          image_folder: !handledFileds[HandledFieldsEnum.IS_IMAGES_EMPTY]
            ? entity?.image_folder
              ? entity?.image_folder?.id || entity?.image_folder
              : type === AddonsEnum.ROOMS
              ? roomImage
              : floorImage
            : 0,
          file_folder: !handledFileds[HandledFieldsEnum.IS_FILES_EMPTY]
            ? entity?.file_folder
              ? entity?.file_folder?.id || entity?.file_folder
              : type === AddonsEnum.ROOMS
              ? null
              : floorFile
            : 0,
          house: type === AddonsEnum.FLOORS ? Number(id) : null,
          commercial: type === AddonsEnum.COMMERCIAL ? Number(id) : null,
          id: entity?.id ? entity?.id : Math.floor(Math.random() * 899999 + 100000),
          name: values.name !== RoomNameTranslationEnum.OTHER ? values.name : name_other,
        };

        const injectedState = (prev: any) => {
          const index = prev?.findIndex((obj: any) => obj.id === entity?.id);

          const updatedObj = {...prev[index], ...state};
          let updatedState: any[] = [];

          if (index > -1) {
            updatedState = [...prev.slice(0, index), updatedObj, ...prev.slice(index + 1)];
          }

          return mode === ActionModesEnum.UPDATE ? updatedState : [...prev!, state];
        };

        if (type === 'rooms') {
          setAttachedRooms && setAttachedRooms(injectedState);
        } else {
          setAttachedFloors && setAttachedFloors(injectedState);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setTimeout(() => {
          setIsButtonLoading(false);
          onClose();
        }, 600);
      }
    },
    [
      type,
      handledFileds,
      roomImage,
      floorImage,
      floorFile,
      mode,
      id,
      entity,
      setAttachedRooms,
      setAttachedFloors,
      onClose,
    ]
  );

  const handleUploadClick = useCallback((propName: string, value: boolean | undefined) => {
    setHandledFields(prev => ({...prev, [propName]: value}));
  }, []);

  const onAdd = useCallback(() => {
    handleSubmit(values);
  }, [values, handleSubmit]);

  useEffect(() => {
    if (!entity) return;

    const isNameOption = Object.values(RoomNameTranslationEnum).includes(entity?.name as RoomNameTranslationEnum);

    if (type === 'rooms') {
      if (isNameOption) {
        form.setFieldValue(PropertyAddonsFieldsEnum.NAME, entity?.name);
      } else {
        form.setFieldValue(PropertyAddonsFieldsEnum.NAME, RoomNameTranslationEnum.OTHER);
        form.setFieldValue(PropertyAddonsFieldsEnum.NAME_OTHER, entity?.name);
      }
    }
  }, [entity, type, form]);

  useEffect(() => {
    form.validateFields({validateOnly: true}).then(
      () => {
        setIsSubmittable(true);
      },
      () => {
        setIsSubmittable(false);
      }
    );
  }, [values, form]);

  useEffect(() => {
    dispatch(clearMediaAction({type: 'attached'}, {folder: FoldersEnum.IMAGE, addon: AddonsEnum.ROOMS}));
    dispatch(clearMediaAction({type: 'attached'}, {folder: FoldersEnum.IMAGE, addon: AddonsEnum.FLOORS}));
    dispatch(clearMediaAction({type: 'attached'}, {folder: FoldersEnum.FILE, addon: AddonsEnum.ROOMS}));
    dispatch(clearMediaAction({type: 'attached'}, {folder: FoldersEnum.FILE, addon: AddonsEnum.FLOORS}));
    dispatch(clearMediaAction({}, {folder: FoldersEnum.IMAGE, addon: AddonsEnum.ROOMS}));
    dispatch(clearMediaAction({}, {folder: FoldersEnum.IMAGE, addon: AddonsEnum.FLOORS}));
    dispatch(clearMediaAction({}, {folder: FoldersEnum.FILE, addon: AddonsEnum.ROOMS}));
    dispatch(clearMediaAction({}, {folder: FoldersEnum.FILE, addon: AddonsEnum.FLOORS}));

    if (entity?.image_folder) {
      dispatch(
        getFolderByIdAction(
          {
            id: entity?.image_folder?.id || entity?.image_folder,
          },
          {folder: FoldersEnum.IMAGE, addon: type}
        )
      );
    }

    if (entity?.file_folder) {
      dispatch(
        getFolderByIdAction(
          {
            id: entity?.file_folder?.id || entity?.file_folder,
          },
          {folder: FoldersEnum.FILE, addon: type}
        )
      );
    }
  }, [entity, form, type, dispatch]);

  return (
    <PopupBase
      title={`${
        mode === ActionModesEnum.UPDATE
          ? t('actions.change')
          : mode === ActionModesEnum.VIEW
          ? t('actions.view')
          : `${t('actions.add')} ${type === 'rooms' ? t('common.room') : t('common.floor')}`
      }`}
      onCrossClick={onClose}
      onSubmit={handleSubmit}
      layerClose={mode === ActionModesEnum.VIEW}
      open
      size="lg"
      buttons={{
        position: 'right',
        reject: {
          name: t('actions.cancel'),
          type: 'text',
          theme: 'default',
          onClick: onClose,
        },
        confirm:
          mode === ActionModesEnum.VIEW
            ? undefined
            : {
                icon: mode === ActionModesEnum.UPDATE ? null : <Icon.AddCircle fill="white" />,
                name: mode === ActionModesEnum.UPDATE ? t('actions.change') : t('actions.add'),
                type: 'primary',
                htmlType: 'button',
                disabled: !isSubmittable,
                loading: isButtonLoading,
                onClick: onAdd,
              },
      }}
    >
      <FormPropertyAddons
        form={form}
        values={values}
        type={type}
        isImagesAttached={handledFileds[HandledFieldsEnum.IS_IMAGES_ATTACHED]}
        isFilesAttached={handledFileds[HandledFieldsEnum.IS_FILES_ATTACHED]}
        initialValues={entity!}
        onUploadClick={handleUploadClick}
        onSumbit={handleSubmit}
      />
    </PopupBase>
  );
};

export const PopupPropertyAddonsMemoized = memo(PopupPropertyAddons);
