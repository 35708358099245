export enum FormsEnum {
  ANALYTICS_PERFORMANCE = 'analytics-performance',
  BUILDING = 'building',
  BUILDING_FILTER = 'building-filter',
  LEAD = 'lead',
  LEAD_FILTER = 'lead-filter',
  PROPERTY = 'property',
  PROPERTY_FILTER = 'property-filter',
  ACCOUNT = 'account',
  ACCOUNTS_FILTER = 'accounts-filter',
  CHANGE_BUSINESS_PROCESS = 'change-business-process',
  BOOKING = 'booking',
  INQUIRIES_FILTER = 'inquiries-filter',
  LOGIN = 'login',
  PERSONS_FILTER = 'persons-filter',
  PERSON_ADD = 'person-add',
  PERSON_EDIT = 'person-edit',
  PERSON_RELATION = 'person-relation',
  PERSON_SEARCH = 'person-search',
  PROPERTY_ADDONS = 'property-addons',
  UPDATE_PRICE = 'update-price',
}
