import {createSelector} from '@reduxjs/toolkit';

import {State} from 'store/types';

import {AccountsState} from './types';

const getState = (state: State): AccountsState => state.accounts;

export const accountsLoadingSelector = createSelector(getState, state => state.loading);
export const accountsErrorSelector = createSelector(getState, state => state.error);
export const accountsListSelector = createSelector(getState, state => state.accounts);
export const accountsCountSelector = createSelector(getState, state => state.accountsCount);
export const accountSelector = createSelector(getState, state => state.account);
export const accountCurrentSelector = createSelector(getState, state => state.currentAccount);
