import {ColumnsType} from 'antd/es/table';
import {Popover} from 'antd';
import {Link} from 'react-router-dom';

import {InquiriesFilterFieldsEnum} from 'components/forms/inquiries-filter/form-inquiries.enums';
import {TInquiry} from 'types/api';
import {getTranslation} from 'utils';
import {InquiryStateTranslationEnum} from 'enums/translations';
import {CTextCopy} from 'components/containers/c-text-copy';
import {RoutesEnum} from 'router/routes.enum';
import {ActionModesEnum, PageNamesEnum} from 'enums';
import {TTranslation} from 'types/common';

interface ITableColumns extends TInquiry {}

export const columns = ({translation}: TTranslation): ColumnsType<ITableColumns> => {
  return [
    {
      width: '25%',
      title: translation('form.customer'),
      dataIndex: InquiriesFilterFieldsEnum.NAME,
      key: InquiriesFilterFieldsEnum.NAME,
      render: (_, record) => (
        <div>
          {record.persons[0] && (
            <Link
              onClick={e => e.stopPropagation()}
              className="text-inherit"
              to={`${RoutesEnum.PERSONS}/${ActionModesEnum.UPDATE}/${
                record.persons[0].id
              }${`?pageFrom=${PageNamesEnum.INQUIRIES}`}`}
            >
              {record.persons[0].name}
            </Link>
          )}{' '}
          <Popover
            content={
              record.persons.length > 1 ? (
                <div className="grid gap-2">
                  {record.persons
                    .filter((_, i) => i > 0)
                    .map(p => (
                      <div key={p.id}>
                        <strong>
                          <Link
                            onClick={e => e.stopPropagation()}
                            className="text-inherit"
                            to={`${RoutesEnum.PERSONS}/${ActionModesEnum.UPDATE}/${
                              p.id
                            }${`?pageFrom=${PageNamesEnum.INQUIRIES}`}`}
                          >
                            {p.name}
                          </Link>{' '}
                          :{' '}
                        </strong>{' '}
                        {p?.phones?.map(({id, number}) => <CTextCopy key={`${id}-${number}`} text={number} />)}
                      </div>
                    ))}
                </div>
              ) : null
            }
          >
            <span className="text-[11px]">{record.persons.length > 1 ? `(+${record.persons.length - 1})` : ''}</span>
          </Popover>
        </div>
      ),
    },
    {
      width: '25%',
      title: translation('form.phone'),
      dataIndex: InquiriesFilterFieldsEnum.PHONE,
      key: InquiriesFilterFieldsEnum.PHONE,
      render: (_, record) => (
        <div>
          {record.persons[0]?.phones?.map(({id, number}) => <CTextCopy key={`${id}-${number}`} text={number} />)}
        </div>
      ),
    },
    {
      width: '25%',
      title: translation('form.info'),
      dataIndex: InquiriesFilterFieldsEnum.INFO,
      key: InquiriesFilterFieldsEnum.INFO,
      render: (_, record) => <div className="line-clamp-2">{record.info}</div>,
    },
    {
      width: '25%',
      title: translation('form.status'),
      dataIndex: InquiriesFilterFieldsEnum.STATE,
      key: InquiriesFilterFieldsEnum.STATE,
      render: (_, record) => <div>{getTranslation(InquiryStateTranslationEnum, record.state)}</div>,
    },
  ];
};
