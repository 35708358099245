import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {Col, Row, Tooltip} from 'antd';
import qs from 'query-string';
import {useTranslation} from 'react-i18next';

import {Icon} from 'assets/icons';
import {PCardWrapper} from 'components/primitives/p-card-wrapper';
import {PLoader} from 'components/primitives/p-loader';
import {PPageTitle} from 'components/primitives/p-page-title';
import {PButton} from 'components/primitives/p-button';
import {
  inquiriesAddedCommentSelector,
  inquiriesCurrentSelector,
  inquiriesErrorSelector,
  inquiriesEventSelector,
  inquiriesFilterPresetsSelector,
  inquiriesLoadingSelector,
  inquiriesUpdatedInfoSelector,
  inquiriesUpdatedPersonsSelector,
  inquiryEventPropertyCommentSelector,
} from 'store/inquiries/selectors';
import {
  clearInquiriesErrorsAction,
  clearInquiryBookmarkAction,
  getInquiryByIdAction,
  updateInquiryPersonsAction,
} from 'store/inquiries/actions';
import {PFormLabel} from 'components/primitives/p-form-label';
import {ScreenProperties} from 'components/screens/properties';
import {CInquiryComment} from 'components/containers/c-inquiry-comment';
import {CInquiryInfo} from 'components/containers/c-inquiry-info';
import {PTable} from 'components/primitives/p-table';
import {CInquiryEventComments} from 'components/containers/c-inquiry-event-comments';
import {RoutesEnum} from 'router/routes.enum';
import {EmptyPage} from 'components/layout/empty-page';
import {PCarousel} from 'components/primitives/p-carousel';
import {ActionModesEnum, ServerErrorsEnum} from 'enums';
import {PopupPersonAttach} from 'components/popups/person-attach';
import {getTranslation} from 'utils';
import {ActionModeHero} from 'constants/translations';
import {TInquiryEvent, TPerson} from 'types/api';
import {usePopup} from 'hooks';
import {CInquiryPerson} from 'components/containers/c-inquiry-person';

import {eventsHistoryColumns} from './constants/events-history-columns';

export const PageInquiriesDetailed = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();
  const {show} = usePopup();

  const isLoading = useSelector(inquiriesLoadingSelector);
  const error = useSelector(inquiriesErrorSelector);
  const addedInquiryComment = useSelector(inquiriesAddedCommentSelector);
  const updatedInquiryInfo = useSelector(inquiriesUpdatedInfoSelector);
  const inquiriesUpdatedPersons = useSelector(inquiriesUpdatedPersonsSelector);
  const inquiryEventPropertyComment = useSelector(inquiryEventPropertyCommentSelector);
  const inquiriesFilterPresets = useSelector(inquiriesFilterPresetsSelector);
  const currentInquiry = useSelector(inquiriesCurrentSelector);
  const inquiryEvent = useSelector(inquiriesEventSelector);

  const [isInfoUptadeAreaVisible, setIsInfoUptadeAreaVisible] = useState<boolean>(false);
  const [isInHistoryMode, setIsInHistoryMode] = useState<boolean>(false);
  const [selectedHistoryEvent, setSelectedHistoryEvent] = useState<TInquiryEvent | null>(null);
  const [expandedRowKey, setExpandedRowKey] = useState<number | null>();
  const [attachedPersons, setAttachedPersons] = useState<TPerson[]>([]);
  const [isPersonsLoading, setIsPersonsLoading] = useState<boolean>(true);

  const handleUpdateInforArea = useCallback(() => {
    setIsInfoUptadeAreaVisible(!isInfoUptadeAreaVisible);
  }, [isInfoUptadeAreaVisible]);

  const handleHistoryRowClick = useCallback(
    (entity: TInquiryEvent) => {
      setSelectedHistoryEvent(entity);
      dispatch(clearInquiryBookmarkAction());
    },
    [dispatch]
  );

  const handleHistoryTableVisibility = useCallback(() => {
    setIsInHistoryMode(!isInHistoryMode);

    isInHistoryMode && setSelectedHistoryEvent(null);
  }, [isInHistoryMode]);

  const onRow = useCallback(
    (record: any, rowIndex: any) => {
      const activeClassName = record.id === expandedRowKey ? 'active' : '';
      const activeHistoryRowClassName =
        record.id === selectedHistoryEvent?.id ? 'selected-history-row cursor-pointer' : 'cursor-pointer';

      return {
        onClick: (event: any) => {
          handleHistoryRowClick(record);
        },
        className: `${activeClassName} ${activeHistoryRowClassName}`,
      };
    },
    [selectedHistoryEvent, expandedRowKey, handleHistoryRowClick]
  );

  const onExpand = useCallback((expanded: boolean, record: any) => {
    if (expanded) {
      setExpandedRowKey(record.id);
    } else {
      setExpandedRowKey(null);
    }
  }, []);

  const onExpandIconClick = useCallback((event: any, record: any, onExpand: any) => {
    event.stopPropagation();
    onExpand(record, event);
  }, []);

  const onPersonAdd = useCallback(
    (persons?: TPerson[]) => {
      setIsPersonsLoading(true);

      dispatch(
        updateInquiryPersonsAction(
          {inquiry: currentInquiry?.id, persons},
          {onFulfilled: () => setIsPersonsLoading(false)}
        )
      );
    },
    [currentInquiry, dispatch]
  );

  const onPersonRemove = useCallback(
    (id: number) => {
      setAttachedPersons(prev => {
        const newState = [...prev.filter(p => p.id !== id)];

        dispatch(updateInquiryPersonsAction({inquiry: currentInquiry?.id, persons: newState}));

        return newState;
      });
    },
    [currentInquiry, dispatch]
  );

  const handlePersonAttachPopup = useCallback(() => {
    show(PopupPersonAttach, {
      mode: getTranslation(ActionModeHero, ActionModesEnum.ADD),
      showRelation: false,
      attachedPersons,
      setAttachedPersons,
      onConfirm: onPersonAdd,
    });
  }, [attachedPersons, show, onPersonAdd, setAttachedPersons]);

  useEffect(() => {
    if (id || inquiryEvent) {
      !inquiryEventPropertyComment && window.scroll({top: 0});
      setIsPersonsLoading(true);

      setIsInfoUptadeAreaVisible(false);
      dispatch(
        getInquiryByIdAction(
          {id},
          {
            isWithoutLoading:
              addedInquiryComment || updatedInquiryInfo || inquiryEventPropertyComment || inquiriesUpdatedPersons
                ? true
                : false,
            onFulfilled: () => setIsPersonsLoading(false),
          }
        )
      );
    }
  }, [
    id,
    inquiryEvent,
    addedInquiryComment,
    updatedInquiryInfo,
    inquiriesUpdatedPersons,
    inquiryEventPropertyComment,
    dispatch,
  ]);

  useEffect(() => {
    if (currentInquiry) {
      setAttachedPersons(currentInquiry.persons);
    }
  }, [currentInquiry]);

  useEffect(() => {
    return () => {
      dispatch(clearInquiriesErrorsAction());
    };
  }, [dispatch]);

  if (!isLoading && (error === ServerErrorsEnum.INVALID_ID || error?.response?.status === 404)) {
    return <EmptyPage showHeader />;
  }

  return isLoading ? (
    <PLoader />
  ) : (
    <PLoader spinning={isLoading} asLayer>
      <Row justify="space-between" gutter={16}>
        <Col className="grow">
          <PPageTitle
            text={
              <PButton
                type="text"
                theme="white"
                icon={<Icon.ChevronLeftRounded fill="currentColor" />}
                onClick={() =>
                  Object.keys(inquiriesFilterPresets).length > 0
                    ? navigate(-1)
                    : navigate({pathname: RoutesEnum.INQUIRIES, search: qs.stringify(inquiriesFilterPresets)})
                }
              >
                {t('actions.back')}
              </PButton>
            }
          />
        </Col>
        {currentInquiry?.id && (
          <Col>
            <PButton
              type={isInHistoryMode ? 'primary' : 'text'}
              theme={isInHistoryMode ? '' : 'white'}
              icon={isInHistoryMode ? null : <Icon.Document stroke="currentColor" />}
              onClick={handleHistoryTableVisibility}
            >
              {isInHistoryMode ? t('actions.backToBusinessProcess') : t('actions.history')}
            </PButton>
          </Col>
        )}
      </Row>

      <>
        <Row gutter={[20, 20]} className="mb-[20px]">
          <Col span={6}>
            <PCardWrapper padding="md" className="h-full">
              <div className="flex justify-between items-center mb-4">
                <PFormLabel isStatic text={t('common.aboutCustomer')} className="!mb-0" />
                <Tooltip title={t('actions.add')}>
                  <Icon.AddCircle
                    fill="currentColor"
                    width={24}
                    height={24}
                    className="cursor-pointer hover:text-primary transition-all"
                    onClick={handlePersonAttachPopup}
                  />
                </Tooltip>
              </div>
              {currentInquiry && (
                <PCarousel
                  dots={false}
                  arrows={Object.keys(currentInquiry?.persons!).length > 1}
                  arrowsProps={{position: 'outset'}}
                >
                  {currentInquiry?.persons?.map(perosn => (
                    <CInquiryPerson key={id} entity={perosn} loading={isPersonsLoading} onRemove={onPersonRemove} />
                  ))}
                </PCarousel>
              )}
            </PCardWrapper>
          </Col>
          <Col span={18}>
            <PCardWrapper padding="md" className="h-full flex flex-col !mb-0">
              <div className="flex items-center justify-between mb-4">
                <PFormLabel isStatic text={t('common.customerNeeds')} className="!m-0" />
                <div className="text-primary font-bold cursor-pointer" onClick={handleUpdateInforArea}>
                  {isInfoUptadeAreaVisible ? t('actions.close') : t('actions.change')}
                </div>
              </div>
              {isInfoUptadeAreaVisible ? (
                <CInquiryInfo entity={currentInquiry} />
              ) : (
                <div className="p-4 rounded-[16px] border-2 border-solid border-grey-20 grow">
                  {currentInquiry?.info}
                </div>
              )}
            </PCardWrapper>
          </Col>

          <Col span={24}>
            <CInquiryComment entity={currentInquiry} />
          </Col>

          {isInHistoryMode ? (
            <Col span={24}>
              <PCardWrapper padding="md" className="!mb-0">
                <PFormLabel isStatic text={t('common.businessProcessHistory')} />
                <PTable
                  paginationOptions={{pageSize: 5}}
                  columns={eventsHistoryColumns({translation: t})}
                  dataSource={currentInquiry?.events}
                  expandable={{
                    rowExpandable: record => record.comments.length,
                    onExpand,
                    expandedRowRender: record => <CInquiryEventComments record={record} />,
                    expandIcon: ({expanded, onExpand, record}) => {
                      const icon = !record.comments.length ? null : expanded ? (
                        <div className="min-w-[15px] min-h-[15px]">
                          <Icon.ChevronUpRounded onClick={(e: any) => onExpandIconClick(e, record, onExpand)} />
                        </div>
                      ) : (
                        <div className="min-w-[15px] min-h-[15px]">
                          <Icon.ChevronDownRounded onClick={(e: any) => onExpandIconClick(e, record, onExpand)} />
                        </div>
                      );

                      return icon;
                    },
                  }}
                  rowKey="id"
                  onRow={onRow}
                />
              </PCardWrapper>
            </Col>
          ) : null}
        </Row>

        <ScreenProperties
          inquiry={id}
          isInquiryInHistoryMode={isInHistoryMode}
          selectedHistoryEvent={selectedHistoryEvent}
        />
      </>
    </PLoader>
  );
};
