import brandLogo from './brand-logo.svg';
import logo from './logo.png';
import noImageAvatar from './no-image-avatar.png';
import noImage from './no-image.jpg';
import noImageProperty from './no-image-property.jpg';
import avatar from './avatar.jpg';
import carousel from './carousel.png';
import location from './location.png';
import pdf from './file-pdf.svg';
import xls from './file-xls.svg';
import xlsx from './file-xlsx.svg';
import ppt from './file-ppt.svg';
import pptx from './file-pptx.svg';
import doc from './file-doc.svg';
import docx from './file-docx.svg';
import noWifi from './no-wifi.svg';

export const Image = {
  brandLogo,
  logo,
  noImageAvatar,
  noImage,
  noImageProperty,
  carousel,
  avatar,
  location,
  pdf,
  xls,
  xlsx,
  ppt,
  pptx,
  doc,
  docx,
  noWifi,
};
