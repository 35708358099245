import {memo, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Collapse, Divider, Form} from 'antd';
import {useTranslation} from 'react-i18next';

import {IChangeBusinessProcessFields} from 'components/forms/change-business-process/form-change-business-process.interfaces';
import {ChangeBusinessProcessFieldsEnum} from 'components/forms/change-business-process/form-change-business-process.enums';
import {PInput} from 'components/primitives/p-input';
import {addInquiryEventCommentAction} from 'store/inquiries/actions';
import {PCardWrapper} from 'components/primitives/p-card-wrapper';
import {PFormLabel} from 'components/primitives/p-form-label';
import {TInquiryComment} from 'types/api';
import {Icon} from 'assets/icons';
import {inquiriesAddedCommentSelector} from 'store/inquiries/selectors';
import {PCommentItem} from 'components/primitives/p-comment-item';
import {CSubmitButton} from 'components/containers/c-submit-button';

const CInquiryComment = ({entity}: {entity: any}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const addedInquiryComment = useSelector(inquiriesAddedCommentSelector);
  const [form] = Form.useForm<IChangeBusinessProcessFields>();

  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const values = Form.useWatch([], form);

  const handleSubmit = useCallback(
    (values: IChangeBusinessProcessFields) => {
      setIsButtonLoading(true);

      const valuesToSend = {
        ...values,
        event: entity.events[0]?.id,
        inquiry: entity.id,
      };

      dispatch(addInquiryEventCommentAction(valuesToSend));
    },
    [entity, dispatch]
  );

  useEffect(() => {
    let timeout = () =>
      setTimeout(() => {
        form.resetFields();
        setIsButtonLoading(false);
      }, 600);

    if (addedInquiryComment) {
      timeout();
    }

    return () => clearTimeout(timeout());
  }, [addedInquiryComment, form]);

  return (
    <PCardWrapper padding="md" className="flex flex-col !mb-0">
      <PFormLabel isStatic text={t('form.comments')} />
      <Form form={form} onFinish={handleSubmit} className="flex flex-wrap justify-end">
        <Form.Item
          className="mb-4 w-full"
          name={ChangeBusinessProcessFieldsEnum.COMMENT}
          rules={[{required: true, message: ''}]}
        >
          <PInput placeholder={t('form.writeComment')} isTextarea rows={2} />
        </Form.Item>

        <CSubmitButton form={form} values={values} isButtonLoading={isButtonLoading} title={t('actions.save')} />
      </Form>
      <Divider />
      <Collapse
        ghost
        expandIcon={() => null}
        className="p-0"
        collapsible={entity?.events[0]?.comments?.length > 0 ? undefined : 'disabled'}
        items={[
          {
            key: '1',
            label: (
              <div>
                {`${t('common.allComments')} (${entity?.events[0]?.comments?.length ?? 0})`}{' '}
                <Icon.ChevronDownRounded style={{transform: 'rotate(0deg)'}} />
              </div>
            ),
            children: (
              <>
                <Divider className="mt-3 mb-5" />
                {entity?.events[0]?.comments?.map(({comment, created, state}: TInquiryComment) => (
                  <PCommentItem key={created} entity={{created, state: entity?.events[0]?.type, comment}} />
                ))}
              </>
            ),
          },
        ]}
      ></Collapse>
    </PCardWrapper>
  );
};

export const CInquiryCommentMemoized = memo(CInquiryComment);
