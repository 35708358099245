import {Spin} from 'antd';
import {memo} from 'react';

import {CSS} from './p-loader.cssconfig';
import {PLoaderProps} from './p-loader.types';

const PLoader = ({children, asLayer, ...props}: PLoaderProps) => {
  return (
    <div className={!asLayer ? CSS.root : 'min-h-[100vh] h-full'}>
      <Spin size="large" className={CSS.spin} {...props}>
        {children}
      </Spin>
    </div>
  );
};

export const PLoaderMemoized = memo(PLoader);
