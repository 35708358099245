import {useCallback, useEffect, useState} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import {useDispatch, useSelector} from 'react-redux';
import {Skeleton} from 'antd';
import {useTranslation} from 'react-i18next';

import {appProfileSelector, appProfileLoadingSelector} from 'store/app/selectors';
import {PNavItem} from 'components/primitives/p-nav-item';
import {PSidebarToggler} from 'components/primitives/p-sidebar-toggler';
import {PUser} from 'components/primitives/p-user';
import {logoutAction} from 'store/auth/actions';
import {getProfileAction} from 'store/app/actions';

import {extraLinks, navLinks} from './constants';
import {CSS} from './sidebar.cssconfig';

export const Sidebar = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const isProfileLoading = useSelector(appProfileLoadingSelector);
  const profile = useSelector(appProfileSelector);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isLogoutLoading, setIsLogoutLoading] = useState<boolean>(false);

  const handleSidebarOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const onLogout = useCallback(() => {
    setIsLogoutLoading(true);

    setTimeout(() => {
      dispatch(logoutAction());
      setIsLogoutLoading(false);
    }, 600);
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProfileAction({}));
  }, [dispatch]);

  return (
    <aside className={`${CSS.sidebar.base} ${isOpen ? CSS.sidebar.expanded : CSS.sidebar.collapsed}`}>
      <PSidebarToggler open={isOpen} onClick={handleSidebarOpen} />
      <Scrollbars style={{minHeight: '90vh'}}>
        <div className={CSS.sidebarInner}>
          {isProfileLoading || isLogoutLoading ? (
            <Skeleton active />
          ) : (
            profile && (
              <PUser name={`${profile?.first_name} ${profile?.last_name}`} role={profile?.type} open={isOpen} />
            )
          )}

          <div className={CSS.navLinksWrapper}>
            {isProfileLoading || isLogoutLoading ? (
              <Skeleton active />
            ) : (
              navLinks({translation: t})
                .filter(link => link.visibleTo?.find(role => role === profile?.type))
                .map(({label, icon, href}) => (
                  <PNavItem key={label} href={href} icon={icon} expanded={isOpen} label={label} />
                ))
            )}
          </div>

          {isProfileLoading || isLogoutLoading ? (
            <Skeleton active />
          ) : (
            <div>
              {extraLinks({
                translation: t,
                onLogout,
              }).map(({label, icon, href, textColor, asButton, onClick}) => (
                <PNavItem
                  key={label}
                  label={label}
                  href={href}
                  icon={icon}
                  expanded={isOpen}
                  asButton={asButton}
                  textColor={textColor}
                  onClick={onClick}
                />
              ))}
            </div>
          )}
        </div>
      </Scrollbars>
    </aside>
  );
};
