export const CSS = {
  wrapper: 'text-xxs',
  wrapperStatic: 'text-base',
  label: {
    static: 'text-common font-bold mb-4 text-base block',
    input:
      'bg-grey-40 rounded-2xl py-[1px] px-1.5 absolute top-[-10px] left-[11px] z-10 text-common font-medium uppercase',
    success: 'text-success',
    danger: 'text-danger',
    info: 'text-info font-medium',
    default: 'text-common',
  },
} as const;
