export enum PermissionsEnum {
  APARTMENT_SALE = 'apartment_sale',
  APARTMENT_RENT = 'apartment_rent',
  COMMERCIAL_SALE = 'commercial_sale',
  COMMERCIAL_RENT = 'commercial_rent',
  HOUSE_SALE = 'house_sale',
  HOUSE_RENT = 'house_rent',
  LAND_SALE = 'land_sale',
  LAND_RENT = 'land_rent',
  PARKING_SALE = 'parking_sale',
  PARKING_RENT = 'parking_rent',
}
