import {Col, Form, Row} from 'antd';
import {useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {PRangepicker} from 'components/primitives/p-rangepicker';
import {dateDefaultFormat} from 'constants/date-formats';
import {FormsEnum, PerformanceTypeEnum} from 'enums';
import {IFilterProps} from 'types/common';
import {useMountedOnce} from 'hooks';

import {IAnalyticsPerformanceFields} from './form-analytics-performance-filter.interfaces';
import {AnalyticsPerformanceFilterFieldsEnum} from './form-analytics-performance-filter.enums';

export const FormAnalyticsPerformanceFilter = ({
  type,
  form,
  values,
  initialValues,
  onSubmit,
  onReset,
}: IFilterProps<IAnalyticsPerformanceFields> & {type: `${PerformanceTypeEnum}`}) => {
  const {t} = useTranslation();
  const hasInitialValues = useMemo(() => initialValues && Object.keys(initialValues).length > 0, [initialValues]);

  const handleRangePickerChange = useCallback(
    (v: any) => {
      if (v) {
        onSubmit(v);
      } else {
        onReset();
      }
    },
    [onSubmit, onReset]
  );

  const setInitialValus = useMountedOnce(() => {
    if (hasInitialValues) {
      form.setFieldsValue(initialValues!);
    }
  });

  useEffect(() => {
    setInitialValus();
  }, [setInitialValus]);

  return (
    <Form form={form} name={`${FormsEnum.ANALYTICS_PERFORMANCE}-${type}`}>
      <Row>
        <Col span={24}>
          <Form.Item name={AnalyticsPerformanceFilterFieldsEnum.DATE} rules={[{required: false}]}>
            <PRangepicker
              label={t('form.date')}
              size="large"
              format={dateDefaultFormat}
              placeholder={[t('form.start'), t('form.end')]}
              onChange={handleRangePickerChange}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
