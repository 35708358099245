import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Divider, Row} from 'antd';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Icon} from 'assets/icons';
import {PCardWrapper} from 'components/primitives/p-card-wrapper';
import {PLoader} from 'components/primitives/p-loader';
import {RoutesEnum} from 'router/routes.enum';
import {
  ActionModesEnum,
  OptGroupsEnum,
  PropertyActionsTypesEnum,
  PropertyTypesEnum,
  SubmitModesEnum,
  ContractTypesEnum,
  StatusEnum,
  ServerErrorsEnum,
} from 'enums';
import {leadsCurrentSelector, leadsErrorSelector, leadsLoadingSelector} from 'store/leads/selectors';
import {clearCurrentLeadAction, clearLeadsErrorsAction, getLeadByIdAction, updateLeadAction} from 'store/leads/actions';
import {PButton} from 'components/primitives/p-button';
import {PPageTitle} from 'components/primitives/p-page-title';
import {PInfoBar} from 'components/primitives/p-info-bar';
import {PTitle} from 'components/primitives/p-title';
import {palette} from 'constants/theme';
import {appCatalogSelector} from 'store/app/selectors';
import {
  ContractTypesTranslationEnum,
  PropertyActionsTypesTranslationEnum,
  PropertyTypesTranslationEnum,
  StatusTranslationEnum,
  FlagsTranslationEnum,
} from 'enums/translations';
import {currencySymbols} from 'constants/currency-symbols';
import {formatDate, formatNumber, getTranslation} from 'utils';
import {CTextCopy} from 'components/containers/c-text-copy';
import {EmptyPage} from 'components/layout/empty-page';

export const PageLeadsView = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {id} = useParams();
  const navigate = useNavigate();

  const isLoading = useSelector(leadsLoadingSelector);
  const error = useSelector(leadsErrorSelector);
  const currentLead = useSelector(leadsCurrentSelector);
  const catalog = useSelector(appCatalogSelector);

  const titleParams = useMemo(
    () => [
      currentLead?.mode === ContractTypesEnum.SALE ? t('derivatives.sale') : null,
      currentLead?.mode === ContractTypesEnum.RENT && currentLead?.rent === ContractTypesEnum.MONTHLY
        ? t('derivatives.monthly')
        : null,
      currentLead?.mode === ContractTypesEnum.RENT && currentLead?.rent === ContractTypesEnum.DAILY
        ? t('derivatives.daily')
        : null,
      currentLead?.mode === ContractTypesEnum.RENT && currentLead?.rent === ContractTypesEnum.ALL
        ? t('derivatives.rent')
        : null,
    ],
    [currentLead, t]
  );

  const [isStatusChangeLoading, setIsStatusChangeLoading] = useState(false);

  const onStatusChangeToFinalized = useCallback(() => {
    setIsStatusChangeLoading(true);

    dispatch(
      updateLeadAction(
        {...currentLead, lead: id, status: StatusEnum.FINALIZED},
        {
          mode: SubmitModesEnum.SEND,
          onFulfilled: () => setIsStatusChangeLoading(false),
          onReject: () => setIsStatusChangeLoading(false),
        }
      )
    );
  }, [id, currentLead, dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(clearCurrentLeadAction());
      dispatch(getLeadByIdAction({id}));
    } else {
      dispatch(clearCurrentLeadAction());
    }
  }, [id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearLeadsErrorsAction());
    };
  }, [dispatch]);

  if (!isLoading && (error === ServerErrorsEnum.INVALID_ID || error?.response?.status === 404)) {
    return <EmptyPage showHeader />;
  }

  return isLoading ? (
    <PLoader />
  ) : (
    <>
      <PPageTitle
        text={
          <PButton
            type="text"
            theme="white"
            icon={<Icon.ChevronLeftRounded fill="currentColor" />}
            onClick={() => navigate(-1)}
          >
            {t('actions.back')}
          </PButton>
        }
      />

      <PCardWrapper padding="md">
        {currentLead?.action && (
          <PTitle
            className="mb-8"
            textSize="large"
            text={`${getTranslation(PropertyActionsTypesTranslationEnum, currentLead?.action)} ${
              currentLead?.type === PropertyTypesEnum.OTHER
                ? `${t('derivatives.other')} ${t('common.property')}`
                : getTranslation(PropertyTypesTranslationEnum, currentLead?.type).toLowerCase()
            } ${titleParams.filter(item => item !== null).join(', ')}  `}
          ></PTitle>
        )}

        {currentLead?.address && (
          <>
            <PTitle
              textSize="large"
              theme="danger"
              text={currentLead?.address}
              icon={<Icon.Location fill={palette.danger.primary} />}
            ></PTitle>
            <Divider />
          </>
        )}

        <Row gutter={28}>
          <Col span={12}>
            <div className="mb-5">
              <PTitle
                textSize="medium"
                text={t('common.commonInfo')}
                icon={<Icon.Note fill={palette.common} />}
              ></PTitle>
              <div>
                <PInfoBar
                  label={t('form.status')}
                  value={getTranslation(StatusTranslationEnum, currentLead?.status!)}
                ></PInfoBar>

                {currentLead?.flag && (
                  <PInfoBar
                    label={t('form.flag')}
                    value={getTranslation(FlagsTranslationEnum, currentLead?.flag)}
                  ></PInfoBar>
                )}

                {currentLead?.created && (
                  <PInfoBar label={t('form.entranceDate')} value={formatDate({value: currentLead?.created})}></PInfoBar>
                )}

                {currentLead?.action && (
                  <PInfoBar
                    label={t('form.actionTarget')}
                    value={getTranslation(PropertyActionsTypesTranslationEnum, currentLead?.action)}
                  ></PInfoBar>
                )}

                {currentLead?.type && (
                  <PInfoBar
                    label={t('form.propertyType')}
                    value={getTranslation(PropertyTypesTranslationEnum, currentLead?.type)}
                  ></PInfoBar>
                )}

                {currentLead?.action === PropertyActionsTypesEnum.OFFER &&
                  (currentLead?.area || currentLead?.living_area) &&
                  (currentLead?.type === PropertyTypesEnum.HOUSE ? (
                    <PInfoBar
                      label={t('form.livingArea')}
                      value={`${currentLead.living_area} ${t('shorts.squareMeter')}`}
                    ></PInfoBar>
                  ) : (
                    <PInfoBar
                      label={t('form.area')}
                      value={`${currentLead?.area} ${t('shorts.squareMeter')}`}
                    ></PInfoBar>
                  ))}

                {currentLead?.action === PropertyActionsTypesEnum.OFFER && currentLead?.land_area && (
                  <PInfoBar
                    label={t('form.landArea')}
                    value={`${currentLead.land_area} ${t('shorts.squareMeter')}`}
                  ></PInfoBar>
                )}

                {currentLead?.action === PropertyActionsTypesEnum.OFFER &&
                  currentLead?.type === PropertyTypesEnum.LAND && (
                    <>
                      <PInfoBar
                        label={t('form.area')}
                        value={`${currentLead.square_metre} ${t('shorts.squareMeter')} | ${currentLead.hectare} ${t(
                          'shorts.hectare'
                        )}`}
                      ></PInfoBar>
                    </>
                  )}

                {currentLead?.action === PropertyActionsTypesEnum.OFFER &&
                  (currentLead?.entrance_floor || currentLead?.floor || currentLead?.floors) &&
                  (currentLead?.type === PropertyTypesEnum.COMMERCIAL ? (
                    <PInfoBar label={t('form.floor')} value={currentLead.entrance_floor}></PInfoBar>
                  ) : currentLead?.type === PropertyTypesEnum.HOUSE ? (
                    <PInfoBar label={t('form.floors')} value={currentLead.floors}></PInfoBar>
                  ) : (
                    <PInfoBar label={t('form.floor')} value={currentLead.floor}></PInfoBar>
                  ))}

                {currentLead?.action === PropertyActionsTypesEnum.OFFER && currentLead?.rooms && (
                  <PInfoBar label={t('form.rooms')} value={currentLead?.rooms}></PInfoBar>
                )}

                {currentLead?.usage && (
                  <PInfoBar
                    label={t('form.usage')}
                    value={
                      catalog[OptGroupsEnum.SOIL_IMPORTANCE]?.find(o => o.value === currentLead?.usage)?.label as string
                    }
                  ></PInfoBar>
                )}

                {currentLead?.mode && (
                  <PInfoBar
                    label={t('form.contractType')}
                    value={
                      currentLead?.mode === ContractTypesEnum.ALL && currentLead?.rent === ContractTypesEnum.MONTHLY
                        ? `${ContractTypesTranslationEnum.SALE}, ${ContractTypesTranslationEnum.MONTHLY} `
                        : currentLead?.mode === ContractTypesEnum.ALL && currentLead?.rent === ContractTypesEnum.DAILY
                        ? `${ContractTypesTranslationEnum.SALE}, ${ContractTypesTranslationEnum.DAILY} `
                        : currentLead?.mode === ContractTypesEnum.ALL && currentLead?.rent === ContractTypesEnum.ALL
                        ? `${ContractTypesTranslationEnum.SALE}, ${ContractTypesTranslationEnum.MONTHLY}, ${ContractTypesTranslationEnum.DAILY} `
                        : currentLead?.mode === ContractTypesEnum.RENT && currentLead?.rent === ContractTypesEnum.ALL
                        ? `${ContractTypesTranslationEnum.MONTHLY}, ${ContractTypesTranslationEnum.DAILY} `
                        : currentLead?.rent === ContractTypesEnum.MONTHLY
                        ? `${ContractTypesTranslationEnum.MONTHLY}`
                        : currentLead?.rent === ContractTypesEnum.DAILY
                        ? `${ContractTypesTranslationEnum.DAILY}`
                        : getTranslation(ContractTypesTranslationEnum, currentLead?.mode)
                    }
                  ></PInfoBar>
                )}

                <PInfoBar
                  label={t('form.source')}
                  value={catalog[OptGroupsEnum.SOURCE]?.find(o => o.value === currentLead?.source)?.label as string}
                ></PInfoBar>
              </div>
            </div>
          </Col>
          <Col span={12}>
            {currentLead?.persons?.length ? (
              <div className="mb-5">
                <PTitle
                  textSize="medium"
                  text={t('common.contacts')}
                  icon={<Icon.User fill={palette.common} />}
                ></PTitle>
                <div>
                  {currentLead?.persons.map(p => (
                    <div key={p.id} className="rounded-[16px] px-4 bg-grey-40 mb-[16px] last:mb-0">
                      <PInfoBar label={t('form.firstName')} value={p.name}></PInfoBar>
                      <PInfoBar
                        label={t('form.phone')}
                        value={p.phones.map(n => (
                          <CTextCopy key={`${n.id}-${n?.number}`} text={n?.number} />
                        ))}
                      ></PInfoBar>

                      {p.email && <PInfoBar label={t('form.email')} value={p.email}></PInfoBar>}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}

            {currentLead?.action === PropertyActionsTypesEnum.OFFER && currentLead?.mode && (
              <div className="mb-5">
                <PTitle
                  textSize="medium"
                  text={t('common.price')}
                  icon={<Icon.Price color={palette.common} />}
                ></PTitle>
                <div className="rounded-[16px] px-4 bg-grey-40">
                  {currentLead?.total_price_sale && (
                    <PInfoBar
                      label={t('form.sale')}
                      value={` ${formatNumber(currentLead?.total_price_sale)} ${
                        currentLead?.currency_sale
                          ? getTranslation(currencySymbols, currentLead?.currency_sale)?.props.children
                          : ''
                      } ${
                        currentLead?.unit_price_sale
                          ? ` | ${formatNumber(currentLead?.unit_price_sale)} ${
                              currentLead?.currency_sale
                                ? getTranslation(currencySymbols, currentLead?.currency_sale)?.props.children
                                : ''
                            }/${t('shorts.squareMeter')} `
                          : ''
                      }`}
                    ></PInfoBar>
                  )}

                  {(currentLead.rent === ContractTypesEnum.MONTHLY || currentLead.rent === ContractTypesEnum.ALL) && (
                    <PInfoBar
                      label={t('form.rent')}
                      value={`${formatNumber(currentLead?.total_price_monthly)} ${
                        currentLead?.currency_monthly
                          ? getTranslation(currencySymbols, currentLead?.currency_monthly)?.props.children
                          : ''
                      } ${
                        currentLead?.unit_price_monthly
                          ? ` | ${formatNumber(currentLead?.unit_price_monthly)} ${
                              currentLead?.currency_monthly
                                ? getTranslation(currencySymbols, currentLead?.currency_monthly)?.props.children
                                : ''
                            }/${t('form.shorts')} `
                          : ''
                      }`}
                    ></PInfoBar>
                  )}

                  {(currentLead.rent === ContractTypesEnum.DAILY || currentLead.rent === ContractTypesEnum.ALL) && (
                    <PInfoBar
                      label={t('form.rentDaily')}
                      value={`${formatNumber(currentLead?.total_price_daily)} ${
                        currentLead?.currency_daily
                          ? getTranslation(currencySymbols, currentLead?.currency_daily)?.props.children
                          : ''
                      }`}
                    ></PInfoBar>
                  )}
                </div>
              </div>
            )}

            {currentLead?.info && (
              <div className="mb-5">
                <PTitle
                  textSize="medium"
                  text={t('common.description')}
                  icon={<Icon.Pen fill={palette.common} />}
                ></PTitle>
                <div className="text-[14px] text-[#848484] leading-[140%]">{currentLead?.info}</div>
              </div>
            )}
          </Col>
        </Row>
        {currentLead?.status !== StatusEnum.CANCELED && currentLead?.status !== StatusEnum.FINALIZED && (
          <Row justify="end" gutter={15}>
            <Col className="text-right">
              <PButton type="text" theme="default" loading={isStatusChangeLoading} onClick={onStatusChangeToFinalized}>
                {t('actions.changeToFinal')}
              </PButton>
            </Col>
            <Col className="text-right">
              <Link to={`${RoutesEnum.LEADS}/${ActionModesEnum.UPDATE}/${id}`}>
                <PButton type="primary" icon={<Icon.Edit width={24} height={24} />}>
                  {t('actions.change')}
                </PButton>
              </Link>
            </Col>
          </Row>
        )}
      </PCardWrapper>
    </>
  );
};
