import {TActionSettings} from 'types/common';

export const getInquiriesAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'shared/getInquiries',
  payload,
  settings: settings || {},
});

export const getInquiryByIdAction = (payload?: {id?: number | string}, settings?: TActionSettings) => ({
  type: 'shared/getInquiryById',
  payload,
  settings: settings || {},
});

export const addInquiryBookmarkAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/addInquiryBookmark',
  payload,
  settings: settings || {},
});

export const removeInquiryBookmarkAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/removeInquiryBookmark',
  payload,
  settings: settings || {},
});

export const addInquiryEventCommentAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/addInquiryEventComment',
  payload,
  settings: settings || {},
});

export const updateInquiryInfoAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/updateInquiryInfo',
  payload,
  settings: settings || {},
});

export const updateInquiryPersonsAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/updateInquiryPersonsAction',
  payload,
  settings: settings || {},
});

export const addInquiryEventAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/addInquiryEvent',
  payload,
  settings: settings || {},
});

export const setInquiryEventPropertyCommentAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/setInquiryEventPropertyComment',
  payload,
  settings: settings || {},
});

export const updateInquiryEventSearchAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/updateInquiryEventSearchAction',
  payload,
  settings: settings || {},
});

export const saveInquiriesFilterPresetsAction = (payload?: any) => ({
  type: 'inquiries/saveInquiriesFilterPresetsAction',
  payload,
});

export const clearInquiriesErrorsAction = () => ({
  type: 'inquiries/clearInquiriesErrors',
});

export const clearCurrentInquiryAction = () => ({
  type: 'inquiries/clearCurrentInquiryAction',
});

export const clearInquiryInfoAndCommentAction = () => ({
  type: 'inquiries/clearInquiryInfoAndCommentAction',
});

export const clearInquiryBookmarkAction = () => ({
  type: 'inquiries/clearInquiryBookmarkAction',
});
