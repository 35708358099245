import {Routes, Route as ReactRoute, Navigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {Route, routes} from 'router/routes';
import {ErrorBoundary} from 'components/layout/fallback/error-boundary';
import {appProfileSelector} from 'store/app/selectors';
import {RolesEnum} from 'enums';
import {AutoScroller} from 'components/layout/auto-scroller/auto-scroller';
import {useNetwork, usePopup} from 'hooks';
import {PopupNetworkOffline} from 'components/popups/network-offline';

import {RoutesEnum} from './routes.enum';
import {PrivateRoute} from './private-route';

export const Router = () => {
  const profile = useSelector(appProfileSelector);
  const token = localStorage.getItem('TOKEN');
  const type = localStorage.getItem('TYPE');
  const [routesToShow, setRoutesToShow] = useState(routes);
  const [baseRoute, setBaseRoute] = useState<RoutesEnum>();

  const {online} = useNetwork();
  const {show} = usePopup();

  useEffect(() => {
    if (!online) {
      show(PopupNetworkOffline);
    }
  }, [online, show]);

  useEffect(() => {
    const userBasedRoute =
      type === RolesEnum.SUPER
        ? RoutesEnum.LEADS
        : type === RolesEnum.ADMIN
        ? RoutesEnum.LEADS
        : type === RolesEnum.BROKER
        ? RoutesEnum.BUILDINGS
        : type === RolesEnum.INFO
        ? RoutesEnum.LEADS
        : type === RolesEnum.MARKETING
        ? RoutesEnum.PROPERTIES
        : RoutesEnum.LOGIN;

    setRoutesToShow(token ? routes.filter(r => r.visibleTo?.find(v => v === type)) : routes);
    setBaseRoute(userBasedRoute);
  }, [token, type, profile]);

  return (
    <ErrorBoundary>
      <AutoScroller />
      <div className="flex items-start grow pb-4 bg-grey-20 page">
        <Routes>
          {routesToShow.map(({element, path, privateEntity}) => (
            <ReactRoute
              path={path}
              key={path}
              element={
                privateEntity ? (
                  <PrivateRoute>
                    <>{element}</>
                  </PrivateRoute>
                ) : (
                  <>{element}</>
                )
              }
            ></ReactRoute>
          ))}
          <ReactRoute path={RoutesEnum.HOME} element={<Navigate to={baseRoute!} replace />} />
          <ReactRoute path="*" element={<Navigate to={Route.notFound.path} />} />;
        </Routes>
      </div>
    </ErrorBoundary>
  );
};
