import {memo, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form} from 'antd';
import {Navigate, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {
  addBuildingAction,
  clearBuildingErrorsAction,
  clearCurrentBuildingAction,
  getBuildingByIdAction,
  updateBuildingAction,
} from 'store/buildings/actions';
import {
  buildingCurrentSelector,
  buildingFilterPresetsSelector,
  buildingsErrorSelector,
  buildingsLoadingSelector,
} from 'store/buildings/selectors';
import {FormBuilding} from 'components/forms/building';
import {IBuildingFields} from 'components/forms/building/form-building.interfaces';
import {ActionModesEnum, AddonsEnum, FoldersEnum, HandledFieldsEnum, ServerErrorsEnum} from 'enums';
import {usePopup, useToast} from 'hooks';
import {TPerson} from 'types/api';
import {appAddressSelector, appBuildingFileSelector, appBuildingImageSelector} from 'store/app/selectors';
import {addAddressAction, clearMediaAction, getFolderByIdAction} from 'store/app/actions';
import {IAddressFields} from 'components/forms/address/form-address.interfaces';
import {getTranslation, injectAddressFields, isWidgetNotValidated} from 'utils';
import {ServerErrorsTranslationEnum} from 'enums/translations';
import {PopupPersonAttach} from 'components/popups/person-attach';
import {ActionModeHero} from 'constants/translations';
import {PLoader} from 'components/primitives/p-loader';
import {EmptyPage} from 'components/layout/empty-page';
import {PFormLabel} from 'components/primitives/p-form-label';
import {PCardWrapper} from 'components/primitives/p-card-wrapper';
import {RoutesEnum} from 'router/routes.enum';

import {TScreenBuildingDetailedProps} from './screen-building-detailed.types';
import {personColumns} from './constants/person-columns';

const ScreenBuildingDetailed = ({
  id,
  mode,
  property,
  isFromPopup,
  propertyType,
  setButtonLoading,
  onClose,
}: TScreenBuildingDetailedProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {show} = usePopup();
  const error = useSelector(buildingsErrorSelector);
  const currentBuilding = useSelector(buildingCurrentSelector);
  const buildingFile = useSelector(appBuildingFileSelector);
  const buildingImage = useSelector(appBuildingImageSelector);
  const address = useSelector(appAddressSelector);
  const buildingFilterPresets = useSelector(buildingFilterPresetsSelector);
  const isLoading = useSelector(buildingsLoadingSelector);

  const [form] = Form.useForm<IBuildingFields>();
  const values = Form.useWatch([], form);
  const {contextHolder, showError, showSuccess} = useToast();
  const [initialValues, setInitialValues] = useState<IBuildingFields>();
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isAddressLoading, setAddressLoading] = useState<boolean>(false);
  const [handledFileds, setHandledFields] = useState<Record<string, boolean | undefined>>({
    [HandledFieldsEnum.IS_ADDRESS_ATTACHED]: undefined,
    [HandledFieldsEnum.IS_FILES_ATTACHED]: undefined,
    [HandledFieldsEnum.IS_IMAGES_ATTACHED]: undefined,
    [HandledFieldsEnum.IS_IMAGES_EMPTY]: undefined,
    [HandledFieldsEnum.IS_FILES_EMPTY]: undefined,
  });
  const [addressInfo, setAddressInfo] = useState<IAddressFields>();
  const [attachedPersons, setAttachedPersons] = useState<TPerson[]>([]);

  const handleSubmit = useCallback(
    (values: IBuildingFields) => {
      if (
        isWidgetNotValidated({
          fieldToCheck: addressInfo,
          isFieldHandled: handledFileds[HandledFieldsEnum.IS_ADDRESS_ATTACHED],
          querySelecor: 'map-submit',
          state: HandledFieldsEnum.IS_ADDRESS_ATTACHED,
          setState: setHandledFields,
        })
      )
        return;

      if (
        isWidgetNotValidated({
          isFieldHandled: handledFileds[HandledFieldsEnum.IS_IMAGES_ATTACHED],
          querySelecor: 'image-upload-complete-buildingImage',
          state: HandledFieldsEnum.IS_IMAGES_ATTACHED,
          setState: setHandledFields,
        })
      )
        return;

      if (
        isWidgetNotValidated({
          isFieldHandled: handledFileds[HandledFieldsEnum.IS_FILES_ATTACHED],
          querySelecor: 'file-upload-complete-buildingFile',
          state: HandledFieldsEnum.IS_FILES_ATTACHED,
          setState: setHandledFields,
        })
      )
        return;

      setIsButtonLoading(true);
      setButtonLoading && setButtonLoading(true);

      const data = {
        ...values,
        address,
        fileFolder: buildingFile,
        imageFolder: buildingImage,
        persons: [...attachedPersons],
      };

      const updateData = {
        ...initialValues,
        fileFolder: !handledFileds[HandledFieldsEnum.IS_FILES_EMPTY]
          ? buildingFile
            ? buildingFile
            : initialValues?.file_folder
          : 0,
        imageFolder: !handledFileds[HandledFieldsEnum.IS_IMAGES_EMPTY]
          ? buildingImage
            ? buildingImage
            : initialValues?.image_folder
          : 0,
        persons: [...attachedPersons],
        address: address ? address : initialValues?.address?.id,
        active: initialValues?.active,
        building: initialValues?.building,
        ...values,
      };

      mode === ActionModesEnum.CREATE
        ? dispatch(
            addBuildingAction(
              {...data},
              {
                property: Number(property),
                allowRedirect: isFromPopup ? false : true,
                onFulfilled: () => {
                  setIsButtonLoading(false);
                  setButtonLoading && setButtonLoading(false);
                },
                onReject: e => {
                  showError({message: getTranslation(ServerErrorsTranslationEnum, e)});
                  setIsButtonLoading(false);
                  setButtonLoading && setButtonLoading(false);
                },
              }
            )
          )
        : dispatch(
            updateBuildingAction(updateData, {
              property: Number(property),
              allowRedirect: true,
              filterPresets: buildingFilterPresets,
              onFulfilled: () => {
                setIsButtonLoading(false);
                setButtonLoading && setButtonLoading(false);
              },
              onReject: e => {
                showError({message: getTranslation(ServerErrorsTranslationEnum, e)});
                setIsButtonLoading(false);
                setButtonLoading && setButtonLoading(false);
              },
            })
          );
    },
    [
      address,
      mode,
      buildingFile,
      buildingImage,
      attachedPersons,
      initialValues,
      addressInfo,
      handledFileds,
      buildingFilterPresets,
      property,
      isFromPopup,
      showError,
      setButtonLoading,
      dispatch,
    ]
  );

  const handleAddressAddSubmit = useCallback(() => {
    setAddressLoading(true);

    const data = {
      ...addressInfo,
      ...injectAddressFields({values: form.getFieldsValue()}),
    };

    dispatch(
      addAddressAction(data, {
        onFulfilled: () => {
          setAddressLoading(false);
          setHandledFields(prev => ({...prev, [HandledFieldsEnum.IS_ADDRESS_ATTACHED]: true}));
          showSuccess({message: 'Հասցեն հաջողությամբ պահպանվեց'});
          setAddressInfo(undefined);
        },
        onReject: () => {
          setAddressLoading(false);
          showError({message: getTranslation(ServerErrorsTranslationEnum, error as string)});
        },
      })
    );
  }, [addressInfo, form, error, showError, showSuccess, dispatch]);

  const handlePersonEdit = useCallback(
    (entity?: any) => {
      show(PopupPersonAttach, {
        mode: getTranslation(ActionModeHero, ActionModesEnum.UPDATE),
        entity,
        setAttachedPersons,
      });
    },
    [show]
  );

  const handlePersonUpdate = useCallback(
    (personId?: number) => {
      const route = `${RoutesEnum.PERSONS}/${ActionModesEnum.UPDATE}/${personId}${id ? `?building=${id}` : ''}`;

      navigate(route);
    },
    [id, navigate]
  );

  const handlePersonDelete = useCallback(
    (id: number) => {
      setAttachedPersons(prev => [...prev.filter(p => p.id !== id)]);
    },
    [setAttachedPersons]
  );

  const handleUploadClick = useCallback((propName: string, value: boolean | undefined) => {
    setHandledFields(prev => ({...prev, [propName]: value}));
  }, []);

  useEffect(() => {
    dispatch(clearCurrentBuildingAction());

    if (id) {
      dispatch(getBuildingByIdAction({id}));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (addressInfo) {
      form.setFieldsValue({...addressInfo});
      setInitialValues(prevItems => ({...prevItems, ...addressInfo}));
    }
  }, [addressInfo, form]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({});
    setInitialValues({});

    if (id && currentBuilding && currentBuilding?.id === Number(id)) {
      const {
        country,
        settlement,
        district,
        region,
        street,
        address,
        floors,
        info,
        type,
        plan,
        persons,
        subtype,
        active,
        id,
        image_folder,
        file_folder,
        ...rest
      } = currentBuilding;

      const initialValues = {
        country_name: country?.name!,
        settlement_name: settlement?.name!,
        district_name: district?.name!,
        region_name: region?.name!,
        street_name: street?.name!,
        number: address?.number,
        floors,
        info,
        type,
        plan,
        persons,
        address,
        subtype,
        active,
        building: id,
        image_folder,
        file_folder,
        ...rest,
      };

      form.setFieldsValue(initialValues!);

      setInitialValues(initialValues!);

      setAttachedPersons(initialValues.persons);
    }

    if (id && currentBuilding?.image_folder) {
      dispatch(
        getFolderByIdAction(
          {
            id: currentBuilding.image_folder,
          },
          {folder: FoldersEnum.IMAGE, addon: AddonsEnum.BUILDING_IMAGE}
        )
      );
    } else {
      !isFromPopup && dispatch(clearMediaAction({}, {folder: FoldersEnum.IMAGE, addon: AddonsEnum.BUILDING_IMAGE}));
    }

    if (id && currentBuilding?.file_folder) {
      dispatch(
        getFolderByIdAction(
          {
            id: currentBuilding.file_folder,
          },
          {
            folder: FoldersEnum.FILE,
            addon: AddonsEnum.BUILDING_FILE,
          }
        )
      );
    } else {
      !isFromPopup && dispatch(clearMediaAction({}, {folder: FoldersEnum.FILE, addon: AddonsEnum.BUILDING_FILE}));
    }
  }, [id, currentBuilding, isFromPopup, form, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearBuildingErrorsAction());
    };
  }, [dispatch]);

  if (
    !isLoading &&
    mode === ActionModesEnum.UPDATE &&
    (error === ServerErrorsEnum.INVALID_ID || error?.response?.status === 404)
  ) {
    return <EmptyPage />;
  } else if (mode !== ActionModesEnum.UPDATE && mode !== ActionModesEnum.VIEW && mode !== ActionModesEnum.CREATE) {
    return <Navigate to={RoutesEnum.BUILDINGS} />;
  }

  return isLoading ? (
    <PLoader />
  ) : (
    <>
      <PCardWrapper padding="md" className={isFromPopup ? '!p-0' : 'pb-0'}>
        {!isFromPopup ? (
          <PFormLabel
            text={
              mode === ActionModesEnum.CREATE
                ? `${t('actions.add')} ${t('common.building')}`
                : `${t('actions.change')} ${t('common.building')}`
            }
            isStatic
          />
        ) : null}
        <FormBuilding
          isFromPopup={isFromPopup}
          mode={mode}
          form={form}
          values={values}
          initialValues={initialValues}
          attachedPersons={attachedPersons}
          addressInfo={addressInfo!}
          isButtonLoading={isButtonLoading}
          isAddressLoading={isAddressLoading}
          isAddressAttached={handledFileds[HandledFieldsEnum.IS_ADDRESS_ATTACHED]}
          isImagesAttached={handledFileds[HandledFieldsEnum.IS_IMAGES_ATTACHED]}
          isFilesAttached={handledFileds[HandledFieldsEnum.IS_FILES_ATTACHED]}
          propertyType={propertyType}
          personColumns={personColumns({
            onDelete: handlePersonDelete,
            onEdit: handlePersonEdit,
            onUpdate: handlePersonUpdate,
            translation: t,
          })}
          onUploadClick={handleUploadClick}
          setAttachedPersons={setAttachedPersons}
          setAddressInfo={setAddressInfo}
          onAddressAdd={handleAddressAddSubmit}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
        {contextHolder}
      </PCardWrapper>
    </>
  );
};

export const ScreenBuildingDetailedMemoized = memo(ScreenBuildingDetailed);
