import {ReactNode, memo, useEffect, useState} from 'react';

import {PButton} from 'components/primitives/p-button';

const CSubmitButton = ({
  form,
  values,
  isButtonLoading,
  title,
  icon,
  allowValidate = true,
}: {
  form: any;
  values: any;
  isButtonLoading: boolean;
  allowValidate?: boolean;
  title: string;
  icon?: ReactNode;
}) => {
  const [isSubmittable, setIsSubmittable] = useState<boolean>(!allowValidate);

  useEffect(() => {
    if (!allowValidate) return;

    form.validateFields({validateOnly: true}).then(
      () => {
        setIsSubmittable(true);
      },
      () => {
        setIsSubmittable(false);
      }
    );
  }, [allowValidate, values, form]);

  return (
    <PButton htmlType="submit" type="primary" loading={isButtonLoading} icon={icon} disabled={!isSubmittable}>
      {title}
    </PButton>
  );
};

export const CSubmitButtonMemoized = memo(CSubmitButton);
