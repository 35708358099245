import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Form} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {PopupBase} from 'components/popups/base';
import {Icon} from 'assets/icons';
import {useToast} from 'hooks';
import {IPersonFields, IPersonSearchFields} from 'components/forms/person/form-person.interfaces';
import {appProfileSelector} from 'store/app/selectors';
import {FormPersonRelation} from 'components/forms/person/form-person-relation';
import {FormPersonSearch} from 'components/forms/person/form-person-search';
import {FormPersonAdd} from 'components/forms/person/form-person-add';
import {PLoader} from 'components/primitives/p-loader';
import {formatPhoneNumber} from 'utils';
import {ActionModeHero} from 'constants/translations';
import {personRelationOptions} from 'constants/select-options';
import {PersonRelationsTranslationEnum} from 'enums/translations';
import {personSelector, personsErrorsSelector, personsSearchedSelector} from 'store/persons/selectors';
import {
  addPersonAction,
  clearCreatedPersonAction,
  clearPersonErrorsAction,
  clearSearchedPersonAction,
  searchPersonsAction,
} from 'store/persons/actions';
import {PersonFieldsEnum} from 'components/forms/lead/form-lead.enums';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';

import {PPersonAttachPopupProps} from './popup-person-attach.types';

const PopupPersonAttach = ({
  mode,
  action,
  showRelation = true,
  entity,
  attachedPersons,
  setAttachedPersons,
  onClose,
  onConfirm,
}: PPersonAttachPopupProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [searchForm] = Form.useForm<IPersonSearchFields>();
  const [relationForm] = Form.useForm<IPersonFields>();
  const relationValues = Form.useWatch([], relationForm);
  const [isSubmittable, setIsSubmittable] = useState<boolean>(false);
  const [createForm] = Form.useForm<IPersonFields>();
  const createValues = Form.useWatch([], createForm);
  const {contextHolder, showError} = useToast();
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
  const [isSearchDone, setIsSearchDone] = useState<boolean>(false);
  const [isPersonFinds, setIsPersonFinds] = useState<boolean>(false);
  const personErrors = useSelector(personsErrorsSelector);
  const person = useSelector(personSelector);
  const searchedPerson = useSelector(personsSearchedSelector);
  const profile = useSelector(appProfileSelector);
  const isCreateMode = useMemo(() => mode === ActionModeHero.add || mode === 'createLead', [mode]);

  const onSearch = useCallback(
    async (values: IPersonSearchFields) => {
      const phone = {phone: formatPhoneNumber(values.phone!)};

      setIsSearchLoading(true);

      dispatch(
        searchPersonsAction(
          {...phone},
          {
            isAttaching: true,
            onFulfilled: () => {
              createForm.setFieldValue(PersonFieldsEnum.PHONES, [{number: phone.phone}]);

              setIsSearchDone(true);
              setIsSearchLoading(false);
            },
            onReject: () => {
              setIsSearchDone(false);
              setIsSearchLoading(false);
              showError({message: fieldsMessageTranslations.commonErrors});
            },
          }
        )
      );
    },
    [createForm, showError, dispatch]
  );

  const onCreate = useCallback(
    (values: IPersonFields) => {
      setIsButtonLoading(true);

      const phones = Object.values(values.phones!).map(p => formatPhoneNumber(p?.number!));

      const data = {
        name: values.name,
        email: values.email,
        phones,
      };

      dispatch(
        addPersonAction(data, {
          role: profile?.type,
          onFulfilled: () => {
            setIsButtonLoading(false);
          },
          onReject: () => {
            setIsButtonLoading(false);
            showError({message: fieldsMessageTranslations.commonErrors});
          },
        })
      );
    },
    [profile, showError, dispatch]
  );

  const handleRelationAfterCreate = useCallback(() => {
    const values = createForm.getFieldsValue();
    const relation = createForm.getFieldValue(PersonFieldsEnum.RELATION)
      ? createForm.getFieldValue(PersonFieldsEnum.RELATION)
      : createForm.getFieldValue(PersonFieldsEnum.TYPE);

    const state = {
      id: person,
      ...values,
      relation,
    };

    setAttachedPersons &&
      setAttachedPersons(prev => {
        const newState = [...prev!, state];

        onConfirm && onConfirm(newState);

        return newState;
      });

    onConfirm && onConfirm();
  }, [person, createForm, setAttachedPersons, onConfirm]);

  const onRelationSubmit = useCallback(
    (values: IPersonFields) => {
      const {name, email, phones} = values;
      const id = (searchedPerson && searchedPerson![0].id) || entity?.id;
      const buildings = (searchedPerson && searchedPerson![0].buildings) || entity?.buildings;
      const addresses = (searchedPerson && searchedPerson![0].addresses) || entity?.addresses;

      const state = {
        id,
        name,
        email,
        phones,
        phone: searchForm.getFieldValue(PersonFieldsEnum.PHONE),
        relation: values.relation ? values.relation : values.type,
        buildings,
        addresses,
      };

      if (action === 'createLead') {
        setAttachedPersons && setAttachedPersons([]);
      }

      setAttachedPersons &&
        setAttachedPersons(prev => {
          const newState = [...prev.filter(p => p.id !== id), state];

          onConfirm && onConfirm(newState);

          return newState;
        });

      relationForm.resetFields();
    },
    [searchedPerson, relationForm, searchForm, entity, action, onConfirm, setAttachedPersons]
  );

  const handleClose = useCallback(() => {
    searchedPerson && dispatch(clearSearchedPersonAction());
    onClose();
  }, [searchedPerson, dispatch, onClose]);

  const onAdd = useCallback(() => {
    if (isPersonFinds || mode === ActionModeHero.update) {
      onRelationSubmit(relationValues);
      onClose();
    } else {
      onCreate(createValues);
    }

    dispatch(clearSearchedPersonAction());
  }, [relationValues, isPersonFinds, createValues, mode, dispatch, onRelationSubmit, onCreate, onClose]);

  useEffect(() => {
    if ((personErrors as string)?.length && !isButtonLoading) {
      showError({description: personErrors! as string});
    }
  }, [personErrors, isButtonLoading, showError]);

  useEffect(() => {
    if (person && !isButtonLoading) {
      handleRelationAfterCreate();
      onClose();
      dispatch(clearCreatedPersonAction());
    }
  }, [person, isButtonLoading, onClose, handleRelationAfterCreate, dispatch]);

  useEffect(() => {
    if (person) {
      dispatch(clearPersonErrorsAction());
    }
  }, [person, dispatch]);

  useEffect(() => {
    if (attachedPersons && attachedPersons?.length > 0) {
      relationForm.setFieldsValue(attachedPersons[0]);
    }
  }, [attachedPersons, relationForm]);

  useEffect(() => {
    if (entity) {
      const {phones, relation, ...rest} = entity;

      const isOtherRelationType =
        !personRelationOptions.filter(item => item.label === relation).length && relation !== null;

      relationForm.setFieldsValue({
        ...rest,
        phone: phones?.length > 0 ? phones[0]?.number : '',
        type: isOtherRelationType ? PersonRelationsTranslationEnum.OTHER : relation,
        relation: isOtherRelationType ? relation : '',
      });
    }
  }, [entity, relationForm]);

  useEffect(() => {
    if (searchedPerson?.length) {
      const values = {
        id: searchedPerson[0].id,
        name: searchedPerson[0].name,
        email: searchedPerson[0].email,
        phones: searchedPerson[0].phones,
        phone:
          searchForm.getFieldValue(PersonFieldsEnum.PHONE) || searchedPerson[0]?.phones.map(p => p?.number).join(', '),
      };

      relationForm.setFieldsValue({...values});

      setIsPersonFinds(true);
    }
  }, [searchedPerson, relationForm, searchForm]);

  useEffect(() => {
    if (searchedPerson) {
      relationForm.validateFields({validateOnly: true}).then(
        () => {
          setIsSubmittable(true);
        },
        () => {
          setIsSubmittable(false);
        }
      );
    }
  }, [searchedPerson, relationForm, relationValues]);

  useEffect(() => {
    if (!isPersonFinds) {
      createForm.validateFields({validateOnly: true}).then(
        () => {
          setIsSubmittable(true);
        },
        () => {
          setIsSubmittable(false);
        }
      );
    }
  }, [isPersonFinds, createForm, createValues]);

  return (
    <>
      <PopupBase
        title={`${mode} ${t('common.person')}`}
        onCrossClick={onClose}
        open
        size={
          !isSearchDone && isCreateMode
            ? 'md'
            : (isPersonFinds && isSearchDone && !isSearchLoading) || mode === ActionModeHero.update
            ? 'lg'
            : 'sm'
        }
        buttons={
          (!isSearchLoading && isSearchDone) || mode === ActionModeHero.update
            ? {
                position: 'right',
                reject: {
                  name: t('actions.cancel'),
                  type: 'text',
                  theme: 'default',
                  onClick: () => handleClose(),
                },
                confirm: {
                  icon: <Icon.AddCircle fill="white" />,
                  name: t('actions.confirm'),
                  type: 'primary',
                  htmlType: 'button',
                  disabled: !isSubmittable,
                  loading: isButtonLoading,
                  onClick: onAdd,
                },
              }
            : null
        }
      >
        {isSearchLoading ? (
          <PLoader className="mb-3" />
        ) : !isSearchDone && isCreateMode ? (
          <FormPersonSearch form={searchForm} isSearchLoading={isSearchLoading} onSumbit={onSearch} />
        ) : isPersonFinds || mode === ActionModeHero.update ? (
          <FormPersonRelation
            showRelation={showRelation}
            form={relationForm}
            values={relationValues}
            initialValues={entity}
            onSumbit={onRelationSubmit}
          />
        ) : (
          <FormPersonAdd
            showRelation={showRelation}
            form={createForm}
            values={createValues}
            initialValues={createValues!}
            onSumbit={onCreate}
          />
        )}
      </PopupBase>

      {contextHolder}
    </>
  );
};

export const PopupPersonAttachMemoized = memo(PopupPersonAttach);
