import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {TServerResponseError} from 'types/common';

import {LeadsState} from './types';

const sliceLeads = createSlice({
  name: 'leads',
  initialState: {
    loading: false,
    error: null,
    leads: [],
    leadsCount: 0,
    currentLead: null,
    leadsFilterPresets: {},
    hasUnsavedChanges: false,
  },
  reducers: {
    setErrors(state: LeadsState, {payload}: PayloadAction<TServerResponseError | null>) {
      state.error = payload;
    },
    setLoading(state: LeadsState, {payload}: PayloadAction<LeadsState['loading']>) {
      state.loading = payload;
    },
    setLeads(state: LeadsState, {payload}: PayloadAction<LeadsState['leads']>) {
      state.leads = payload;
    },
    setLeadsCount(state: LeadsState, {payload}: PayloadAction<LeadsState['leadsCount']>) {
      state.leadsCount = payload;
    },
    setCurrentLead(state: LeadsState, {payload}: PayloadAction<LeadsState['currentLead']>) {
      state.currentLead = payload;
    },
    setLeadsFilterPresets(state: LeadsState, {payload}: PayloadAction<LeadsState['leadsFilterPresets']>) {
      state.leadsFilterPresets = payload;
    },
    setHasUnsavedChanges(state: LeadsState, {payload}: PayloadAction<LeadsState['hasUnsavedChanges']>) {
      state.hasUnsavedChanges = payload;
    },
  },
});

export const {
  setErrors,
  setLoading,
  setLeads,
  setLeadsCount,
  setCurrentLead,
  setLeadsFilterPresets,
  setHasUnsavedChanges,
} = sliceLeads.actions;

export const leadsReducer = sliceLeads.reducer;
