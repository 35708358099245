import {FormatsEnum, SeparatorEnum} from 'enums';

export const formatDate = ({
  value,
  format = FormatsEnum.NORMAL,
  separator = SeparatorEnum.DOT,
}: {
  value: string | Date;
  format?: FormatsEnum;
  separator?: SeparatorEnum;
}): string => {
  const date = new Date(value);

  const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
  const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
  const year = date.getFullYear();

  let formattedDate = '';

  if (format === FormatsEnum.REVERSE) {
    formattedDate = `${year}${separator}${month}${separator}${day}`;
  } else {
    formattedDate = `${day}${separator}${month}${separator}${year}`;
  }

  return formattedDate;
};
