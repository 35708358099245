import {takeEvery, all, call, put} from 'redux-saga/effects';
import {push} from 'redux-first-history';
import qs from 'query-string';

import {TActionSettings, TServerResponseError} from 'types/common';
import {TProperty} from 'types/api';
import {
  addPropertyHoldService,
  addPropertyService,
  archivePropertyService,
  disablePropertyService,
  enablePropertyService,
  getPropertyByIdService,
  refreshPropertyPriceService,
  removePropertyHoldService,
  searchPropertiesService,
  updatePropertyCommentService,
  updatePropertyPriceService,
  updatePropertyService,
} from 'services/properties';
import {RoutesEnum} from 'router/routes.enum';
import {clearFromEmptyKeys} from 'utils';
import {getByIdFn, postHelperFn} from 'store/core/sagas';

import {
  addPropertyAction,
  addPropertyHoldAction,
  archivePropertyAction,
  clearCurrentPropertyAction,
  clearPropertiesAction,
  clearPropertiesErrorsAction,
  disablePropertyAction,
  enablePropertyAction,
  getPropertyByIdAction,
  hasUnsavedChangesAction,
  refreshPropertyPriceAction,
  removePropertyHoldAction,
  savePropertyFilterPresetsAction,
  searchPropertiesAction,
  updatePropertyAction,
  updatePropertyCommentAction,
  updatePropertyPriceAction,
} from './actions';
import {
  setArchivedProperty,
  setCurrentProperty,
  setDisabled,
  setEnabled,
  setErrors,
  setHasUnsavedChanges,
  setHoldedProperty,
  setLoading,
  setProperties,
  setPropertiesCount,
  setPropertyFilterPresets,
  setRefreshedPrice,
  setRemovedHold,
  setUpdatedComment,
  setUpdatedPrice,
} from './slice-properties';

const addPropertySaga = function* ({
  payload: {...payload},
  settings: {onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  try {
    const {data}: {data: {success: any; error: TServerResponseError}} = yield call(addPropertyService, payload);

    const params = {type: payload.type};
    const query = qs.stringify(clearFromEmptyKeys(params));

    if (data.error) {
      throw data.error;
    } else {
      yield put(setErrors(null));
      yield put(setCurrentProperty(data.success));
      yield put(push(`${RoutesEnum.PROPERTIES}?${query}`));
      onFulfilled && onFulfilled();
    }
  } catch (e) {
    console.warn(e);
    yield put(setErrors(e as TServerResponseError));
    onReject && onReject(e);
  }
};

const updatePropertySaga = function* ({
  payload: {...payload},
  settings: {filterPresets, onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  try {
    const {data}: {data: {success: any; error: TServerResponseError}} = yield call(updatePropertyService, payload);

    const params = {type: payload.type};
    const query = qs.stringify(clearFromEmptyKeys(Object.keys(filterPresets!).length ? filterPresets! : params));

    if (data.error) {
      throw data.error;
    } else {
      yield put(setErrors(null));
      yield put(setCurrentProperty(data.success));
      yield put(push(`${RoutesEnum.PROPERTIES}?${query}`));
      onFulfilled && onFulfilled();
    }
  } catch (e) {
    console.warn(e);
    yield put(setErrors(e as TServerResponseError));
    onReject && onReject(e);
  }
};

const searchPropertiesSaga = function* ({
  payload: {...payload},
  settings: {isWithoutLoading = false, withCount = true, onFulfilled, onReject},
}: {payload: {offset?: number; length?: number}; settings: TActionSettings} & {type: string}) {
  yield !isWithoutLoading && put(setLoading(true));

  yield put(setUpdatedComment(null));
  yield put(setUpdatedPrice(null));
  yield put(setRefreshedPrice(null));
  yield put(setRemovedHold(null));
  yield put(setHoldedProperty(null));
  yield put(setArchivedProperty(null));
  yield put(setDisabled(null));
  yield put(setEnabled(null));

  try {
    const response: {data: TProperty[]} = yield call(searchPropertiesService, payload);

    yield put(setProperties(response.data));

    if (withCount) {
      const count: {data: number} = yield call(searchPropertiesService, {...payload, ...{cnt: ''}});

      yield put(setPropertiesCount(count.data));
    }

    onFulfilled && onFulfilled();
  } catch (e) {
    console.warn(e);
    yield put(setErrors(e as TServerResponseError));
    onReject && onReject(e);
  } finally {
    yield !isWithoutLoading && put(setLoading(false));
  }
};

const getPropertyByIdSaga = function* ({
  payload: {...payload},
  settings: {...settings},
}: {payload: {id?: number}; settings: TActionSettings} & {type: string}) {
  yield getByIdFn<TProperty>({
    actions: {loading: setLoading, error: setErrors, success: setCurrentProperty},
    service: getPropertyByIdService,
    payload,
    settings,
  });
};

const addPropertyHoldSaga = function* ({
  payload: {...payload},
  settings: {onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield postHelperFn<any>({
    actions: {loading: setLoading, error: setErrors, success: setHoldedProperty},
    service: addPropertyHoldService,
    payload,
    settings: {onFulfilled, onReject},
  });
};

const archivePropertySaga = function* ({
  payload: {...payload},
  settings: {onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield postHelperFn<any>({
    actions: {loading: setLoading, error: setErrors, success: setArchivedProperty},
    service: archivePropertyService,
    payload,
    settings: {onFulfilled, onReject},
  });
};

const updatePropertyPriceSaga = function* ({
  payload: {...payload},
  settings: {onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield postHelperFn<any>({
    actions: {loading: setLoading, error: setErrors, success: setUpdatedPrice},
    service: updatePropertyPriceService,
    payload,
    settings: {onFulfilled, onReject},
  });
};

const refreshPropertyPriceSaga = function* ({
  payload: {...payload},
  settings: {onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield postHelperFn<any>({
    actions: {loading: setLoading, error: setErrors, success: setRefreshedPrice},
    service: refreshPropertyPriceService,
    payload,
    settings: {onFulfilled, onReject},
  });
};

const updatePropertyCommentSaga = function* ({
  payload: {...payload},
  settings: {onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield postHelperFn<any>({
    actions: {loading: setLoading, error: setErrors, success: setUpdatedComment},
    service: updatePropertyCommentService,
    payload,
    settings: {onFulfilled, onReject},
  });
};

const removePropertyHoldSaga = function* ({
  payload: {...payload},
  settings: {onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield postHelperFn<any>({
    actions: {loading: setLoading, error: setErrors, success: setRemovedHold},
    service: removePropertyHoldService,
    payload,
    settings: {onFulfilled, onReject},
  });
};

const disablePropertySaga = function* ({
  payload: {...payload},
  settings: {onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield postHelperFn<any>({
    actions: {loading: setLoading, error: setErrors, success: setDisabled},
    service: disablePropertyService,
    payload,
    settings: {onFulfilled, onReject},
  });
};

const enablePropertySaga = function* ({
  payload: {...payload},
  settings: {onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield postHelperFn<any>({
    actions: {loading: setLoading, error: setErrors, success: setEnabled},
    service: enablePropertyService,
    payload,
    settings: {onFulfilled, onReject},
  });
};

const savePropertyFilterPresetsSaga = function* ({payload}: {payload: any} & {type: string}) {
  yield put(setPropertyFilterPresets(payload));
};

const hasUnsavedChangesSaga = function* ({payload}: {payload: boolean} & {type: string}) {
  yield put(setHasUnsavedChanges(payload));
};

const clearPropertiesErrorsSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setErrors(null));
};

const clearPropertiesActionSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setProperties([]));
};

const clearCurrentPropertySaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setCurrentProperty(null));
};

export const propertySaga = function* () {
  yield all([
    takeEvery(addPropertyAction().type, addPropertySaga),
    takeEvery(updatePropertyAction().type, updatePropertySaga),
    takeEvery(searchPropertiesAction().type, searchPropertiesSaga),
    takeEvery(getPropertyByIdAction().type, getPropertyByIdSaga),
    takeEvery(addPropertyHoldAction().type, addPropertyHoldSaga),
    takeEvery(archivePropertyAction().type, archivePropertySaga),
    takeEvery(updatePropertyPriceAction().type, updatePropertyPriceSaga),
    takeEvery(refreshPropertyPriceAction().type, refreshPropertyPriceSaga),
    takeEvery(updatePropertyCommentAction().type, updatePropertyCommentSaga),
    takeEvery(removePropertyHoldAction().type, removePropertyHoldSaga),
    takeEvery(disablePropertyAction().type, disablePropertySaga),
    takeEvery(enablePropertyAction().type, enablePropertySaga),
    takeEvery(savePropertyFilterPresetsAction().type, savePropertyFilterPresetsSaga),
    takeEvery(hasUnsavedChangesAction().type, hasUnsavedChangesSaga),
    takeEvery(clearPropertiesErrorsAction().type, clearPropertiesErrorsSaga),
    takeEvery(clearPropertiesAction().type, clearPropertiesActionSaga),
    takeEvery(clearCurrentPropertyAction().type, clearCurrentPropertySaga),
  ]);
};
