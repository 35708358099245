import {memo, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Row} from 'antd';
import {useTranslation} from 'react-i18next';

import {getLeadTotalsAction} from 'store/analytics/actions';
import {analyticsLeadTotalsSelector, analyticsLoadingSelector} from 'store/analytics/selectors';
import {CPie} from 'components/containers/c-pie';
import {PLoader} from 'components/primitives/p-loader';

export const LeadAnalyticsView = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(analyticsLoadingSelector);
  const leadTotals = useSelector(analyticsLeadTotalsSelector);

  useEffect(() => {
    dispatch(getLeadTotalsAction());
  }, [dispatch]);

  return isLoading ? (
    <PLoader />
  ) : (
    <Row gutter={[20, 20]}>
      <Col span={12}>
        <CPie headline={t('common.leadType')} data={{key: 'type', items: leadTotals?.type!}} />
      </Col>
      <Col span={12}>
        <CPie headline={t('common.process')} data={{key: 'flag', items: leadTotals?.flag!}} />
      </Col>
      <Col span={12}>
        <CPie headline={t('common.coworkers')} data={{key: 'author', items: leadTotals?.author!}} />
      </Col>
    </Row>
  );
};

export const LeadAnalyticsViewMemoized = memo(LeadAnalyticsView);
