import {createSelector} from '@reduxjs/toolkit';

import {State} from 'store/types';

import {LeadsState} from './types';

const getState = (state: State): LeadsState => state.leads;

export const leadsLoadingSelector = createSelector(getState, state => state.loading);
export const leadsErrorSelector = createSelector(getState, state => state.error);
export const leadsListSelector = createSelector(getState, state => state.leads);
export const leadsCountSelector = createSelector(getState, state => state.leadsCount);
export const leadsCurrentSelector = createSelector(getState, state => state.currentLead);
export const leadsFilterPresetsSelector = createSelector(getState, state => state.leadsFilterPresets);
export const leadsHasUnsavedChangesSelector = createSelector(getState, state => state.hasUnsavedChanges);
