import {Col, Divider, Form, Row, Space, Tooltip} from 'antd';
import {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Icon} from 'assets/icons';
import {PButton} from 'components/primitives/p-button';
import {PInput} from 'components/primitives/p-input';
import {PSelect} from 'components/primitives/p-select';
import {
  actionTypeOptions,
  contractModeOptions,
  currencyOptions,
  flagOptions,
  propertyTypeOptions,
  statusOptions,
} from 'constants/select-options';
import {PFormLabel} from 'components/primitives/p-form-label';
import {usePopup} from 'hooks';
import {
  ActionModesEnum,
  CrossCalculationModesEnum,
  OptGroupsEnum,
  PropertyActionsTypesEnum,
  PropertyTypesEnum,
  SubmitModesEnum,
  ContractTypesEnum,
  FormsEnum,
} from 'enums';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {PopupPersonAttach} from 'components/popups/person-attach';
import {appCatalogSelector} from 'store/app/selectors';
import {PropertyTypesTranslationEnum} from 'enums/translations';
import {RoutesEnum} from 'router/routes.enum';
import {getTranslation} from 'utils';
import {PPhoneInput} from 'components/primitives/p-phone-input';
import {PNumericInput} from 'components/primitives/p-numeric-input';
import {ActionModeHero} from 'constants/translations';
import {hasUnsavedChangesAction} from 'store/leads/actions';
import {leadsHasUnsavedChangesSelector} from 'store/leads/selectors';

import {PFormLeadProps} from './form-lead.types';
import {LeadFieldsEnum, PersonFieldsEnum} from './form-lead.enums';

export const FormLead = ({
  form,
  values,
  initialValues = {status: 'NEW'},
  isButtonLoading,
  isFieldsRequired = false,
  handleFormSubmition,
  handlePriceChange,
  mode,
  onSubmit,
  setAttachedPersons,
}: PFormLeadProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const catalog = useSelector(appCatalogSelector);
  const hasUnsavedChanges = useSelector(leadsHasUnsavedChangesSelector);
  const {show} = usePopup();
  const contractOptions = useMemo(
    () =>
      values?.type === PropertyTypesEnum.LAND
        ? contractModeOptions.filter(c => c.value === ContractTypesEnum.SALE)
        : values?.type === PropertyTypesEnum.COMMERCIAL
        ? contractModeOptions.filter(c => c.value !== ContractTypesEnum.DAILY)
        : contractModeOptions,
    [values]
  );

  const handlePersonAttachPopup = useCallback(() => {
    show(PopupPersonAttach, {
      mode: getTranslation(ActionModeHero, ActionModesEnum.ADD),
      action: 'createLead',
      showRelation: false,
      setAttachedPersons,
    });
  }, [show, setAttachedPersons]);

  const handleUnsavedChanges = useCallback(() => {
    if (!hasUnsavedChanges) {
      dispatch(hasUnsavedChangesAction(true));
    }
  }, [hasUnsavedChanges, dispatch]);

  useEffect(() => {
    if (values?.action !== PropertyActionsTypesEnum.OFFER) return;

    if (values?.type === PropertyTypesEnum.LAND) {
      form.setFieldValue(
        LeadFieldsEnum.RENT,
        form.getFieldValue(LeadFieldsEnum.RENT)?.filter((v: any) => v === ContractTypesEnum.SALE)
      );
    }

    if (values?.type === PropertyTypesEnum.COMMERCIAL) {
      form.setFieldValue(
        LeadFieldsEnum.RENT,
        form.getFieldValue(LeadFieldsEnum.RENT)?.filter((v: any) => v !== ContractTypesEnum.DAILY)
      );
    }
  }, [values, form]);

  useEffect(() => {
    if (mode === ActionModesEnum.CREATE) {
      handlePersonAttachPopup();
    }
  }, [mode, handlePersonAttachPopup]);

  return (
    <Form
      form={form}
      initialValues={initialValues}
      name={FormsEnum.LEAD}
      scrollToFirstError
      onFinish={onSubmit}
      onFinishFailed={() => {
        window.scroll({top: 0, behavior: 'smooth'});
      }}
      onValuesChange={handleUnsavedChanges}
    >
      <Row gutter={16}>
        <Col>
          <div>
            <PButton type="text" theme="default" onClick={handlePersonAttachPopup}>
              <Tooltip title={`${t('actions.add')} ${t('common.person')}`} className="flex">
                <Icon.Add fill="currentColor" />
              </Tooltip>
            </PButton>
          </div>
        </Col>
        <Col span={3}>
          <Form.Item
            name={PersonFieldsEnum.PHONE}
            rules={[
              {required: isFieldsRequired, message: fieldsMessageTranslations.common},
              {min: 9, message: fieldsMessageTranslations.phoneMin},
              {max: 14, message: fieldsMessageTranslations.phoneMax},
            ]}
          >
            <PPhoneInput label={t('form.phone')} id={PersonFieldsEnum.PHONE} />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            name={PersonFieldsEnum.NAME}
            rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
          >
            <PInput label={t('form.firstName')} id={PersonFieldsEnum.NAME} />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            name={PersonFieldsEnum.EMAIL}
            rules={[
              {
                type: 'email',
                message: fieldsMessageTranslations.validEmail,
              },
              {required: isFieldsRequired, message: fieldsMessageTranslations.common},
            ]}
          >
            <PInput label={t('form.email')} id={PersonFieldsEnum.EMAIL} />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={LeadFieldsEnum.SOURCE} rules={[{required: true, message: fieldsMessageTranslations.common}]}>
            <PSelect label={t('form.source')} options={catalog[OptGroupsEnum.SOURCE]} id={LeadFieldsEnum.SOURCE} />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={LeadFieldsEnum.ACTION} rules={[{required: true, message: fieldsMessageTranslations.common}]}>
            <PSelect label={t('form.actionTarget')} options={actionTypeOptions} id={LeadFieldsEnum.ACTION} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        {values?.action !== PropertyActionsTypesEnum.OTHER && (
          <Col span={3}>
            <Form.Item name={LeadFieldsEnum.TYPE} rules={[{required: true, message: fieldsMessageTranslations.common}]}>
              <PSelect label={t('form.propertyType')} options={propertyTypeOptions} id={LeadFieldsEnum.TYPE} />
            </Form.Item>
          </Col>
        )}
        <Col span={3}>
          <Form.Item name={LeadFieldsEnum.STATUS} rules={[{required: true, message: fieldsMessageTranslations.common}]}>
            <PSelect
              label={t('form.status')}
              disabled={mode === ActionModesEnum.CREATE}
              options={statusOptions}
              id={LeadFieldsEnum.STATUS}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            name={LeadFieldsEnum.FLAG}
            rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
          >
            <PSelect label={t('form.flag')} options={flagOptions} id={LeadFieldsEnum.FLAG} />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      {values?.action !== PropertyActionsTypesEnum.OTHER && (
        <>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                className="mb-0"
                name={LeadFieldsEnum.RENT}
                rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
              >
                <PSelect
                  label={t('form.contractType')}
                  mode={values?.action !== PropertyActionsTypesEnum.SEEK ? 'multiple' : undefined}
                  options={contractOptions}
                  id="contractType"
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
        </>
      )}

      {values?.action === PropertyActionsTypesEnum.OFFER && values?.type !== PropertyTypesEnum.OTHER && (
        <>
          <Row gutter={16}>
            {values?.type && (
              <Col span={24}>
                <PFormLabel text={getTranslation(PropertyTypesTranslationEnum, values?.type)} isStatic />
              </Col>
            )}
            <Col span={4}>
              <Form.Item
                name={LeadFieldsEnum.ADDRESS}
                rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
              >
                <PInput label={t('form.address')} id={LeadFieldsEnum.ADDRESS} />
              </Form.Item>
            </Col>
            {values?.type !== PropertyTypesEnum.HOUSE &&
              values?.type !== PropertyTypesEnum.PARKING &&
              values?.type !== PropertyTypesEnum.LAND && (
                <Col span={2}>
                  <Form.Item
                    name={LeadFieldsEnum.AREA}
                    rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
                  >
                    <PNumericInput
                      onChange={(value: number | string | null) =>
                        handlePriceChange(value, CrossCalculationModesEnum.AREA)
                      }
                      label={`${t('form.area')} (${t('shorts.squareMeter')})`}
                      id={LeadFieldsEnum.AREA}
                    />
                  </Form.Item>
                </Col>
              )}

            {values?.type === PropertyTypesEnum.LAND && (
              <Col span={4}>
                <Space.Compact>
                  <Form.Item
                    name={LeadFieldsEnum.SQUARE_METRE}
                    rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
                  >
                    <PNumericInput
                      onChange={(value: number | string | null) =>
                        handlePriceChange(value, CrossCalculationModesEnum.LAND_SQUARE, LeadFieldsEnum.HECTARE)
                      }
                      label={`${t('form.area')} (${t('shorts.squareMeter')})`}
                      id={LeadFieldsEnum.SQUARE_METRE}
                    />
                  </Form.Item>
                  <Form.Item
                    name={LeadFieldsEnum.HECTARE}
                    rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
                  >
                    <PNumericInput
                      withoutFormatter
                      onChange={(value: number | string | null) =>
                        handlePriceChange(value, CrossCalculationModesEnum.LAND_HECTARE, LeadFieldsEnum.SQUARE_METRE)
                      }
                      label={`(${t('shorts.hectare')})`}
                      id={LeadFieldsEnum.HECTARE}
                    />
                  </Form.Item>
                </Space.Compact>
              </Col>
            )}

            {values?.type === PropertyTypesEnum.HOUSE && (
              <>
                <Col span={3}>
                  <Form.Item
                    name={LeadFieldsEnum.LIVING_AREA}
                    rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
                  >
                    <PNumericInput
                      label={`${t('form.livingArea')} (${t('shorts.squareMeter')})`}
                      id={LeadFieldsEnum.LIVING_AREA}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item
                    name={LeadFieldsEnum.LAND_AREA}
                    rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
                  >
                    <PNumericInput
                      label={`${t('form.landArea')} (${t('shorts.squareMeter')})`}
                      id={LeadFieldsEnum.LAND_AREA}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            {(values?.type === PropertyTypesEnum.APARTMENT || values?.type === PropertyTypesEnum.HOUSE) && (
              <Col span={2}>
                <Form.Item
                  name={values?.type === PropertyTypesEnum.APARTMENT ? LeadFieldsEnum.FLOOR : LeadFieldsEnum.FLOORS}
                  rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
                >
                  <PNumericInput
                    label={values?.type === PropertyTypesEnum.APARTMENT ? t('form.floor') : t('form.floors')}
                    id="floor"
                    withoutFormatter
                  />
                </Form.Item>
              </Col>
            )}

            {values?.type === PropertyTypesEnum.COMMERCIAL && (
              <Col span={2}>
                <Form.Item
                  name={LeadFieldsEnum.ENTRANCE_FLOOR}
                  rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
                >
                  <PNumericInput type="number" label={t('form.entranceFloor')} id={LeadFieldsEnum.ENTRANCE_FLOOR} />
                </Form.Item>
              </Col>
            )}

            {values?.type === PropertyTypesEnum.APARTMENT && (
              <Col span={2}>
                <Form.Item
                  name={LeadFieldsEnum.ROOMS}
                  rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
                >
                  <PNumericInput withoutFormatter label={t('form.rooms')} id={LeadFieldsEnum.ROOMS} />
                </Form.Item>
              </Col>
            )}

            {values?.type === PropertyTypesEnum.LAND && (
              <Col span={4}>
                <Form.Item
                  name={LeadFieldsEnum.USAGE}
                  rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
                >
                  <PSelect
                    label={t('form.usage')}
                    options={catalog[OptGroupsEnum.SOIL_IMPORTANCE]}
                    id={LeadFieldsEnum.USAGE}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Divider />
        </>
      )}
      {values?.rent?.length! > 0 && values?.action === PropertyActionsTypesEnum.OFFER && (
        <>
          <Row gutter={[16, 30]}>
            <Col span={24}>
              <PFormLabel text={t('form.price')} innerClassName="mb-0" isStatic />
            </Col>
            {values?.rent?.includes(ContractTypesEnum.SALE) && (
              <Col span={24}>
                <Row gutter={16} align="middle">
                  <Col span={2}>
                    <PFormLabel text={t('form.sale')} innerClassName="mb-0" isStatic />
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      noStyle
                      name={LeadFieldsEnum.TOTAL_PRICE_SALE}
                      rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
                    >
                      <PNumericInput
                        onChange={e =>
                          handlePriceChange(e, CrossCalculationModesEnum.PRICE_TOTAL, LeadFieldsEnum.UNIT_PRICE_SALE)
                        }
                        label={t('form.price')}
                        id={LeadFieldsEnum.TOTAL_PRICE_SALE}
                      />
                    </Form.Item>
                  </Col>
                  {values?.type !== PropertyTypesEnum.HOUSE && (
                    <Col span={3}>
                      <Form.Item
                        noStyle
                        name={LeadFieldsEnum.UNIT_PRICE_SALE}
                        rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
                      >
                        <PNumericInput
                          onChange={e =>
                            handlePriceChange(e, CrossCalculationModesEnum.PRICE_UNIT, LeadFieldsEnum.TOTAL_PRICE_SALE)
                          }
                          label={`${t('form.pricePerArea')} (${t('shorts.squareMeter')})`}
                          id={LeadFieldsEnum.UNIT_PRICE_SALE}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  <Col span={3}>
                    <Form.Item
                      noStyle
                      name={LeadFieldsEnum.CURRENCY_SALE}
                      rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
                    >
                      <PSelect label={t('form.currency')} options={currencyOptions} id={LeadFieldsEnum.CURRENCY_SALE} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}

            {values?.rent?.includes(ContractTypesEnum.MONTHLY) && (
              <Col span={24}>
                <Row gutter={16} align="middle">
                  <Col span={2}>
                    <PFormLabel text={t('form.rent')} innerClassName="mb-0" isStatic />
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      noStyle
                      name={LeadFieldsEnum.TOTAL_PRICE_MONTHLY}
                      rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
                    >
                      <PNumericInput
                        label={t('form.price')}
                        id={LeadFieldsEnum.TOTAL_PRICE_MONTHLY}
                        onChange={e =>
                          handlePriceChange(e, CrossCalculationModesEnum.PRICE_TOTAL, LeadFieldsEnum.UNIT_PRICE_MONTHLY)
                        }
                      />
                    </Form.Item>
                  </Col>
                  {values?.type === PropertyTypesEnum.COMMERCIAL && (
                    <Col span={3}>
                      <Form.Item
                        noStyle
                        name={LeadFieldsEnum.UNIT_PRICE_MONTHLY}
                        rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
                      >
                        <PNumericInput
                          label={`${t('form.pricePerArea')} (${t('shorts.squareMeter')})`}
                          id={LeadFieldsEnum.UNIT_PRICE_MONTHLY}
                          onChange={e =>
                            handlePriceChange(
                              e,
                              CrossCalculationModesEnum.PRICE_UNIT,
                              LeadFieldsEnum.TOTAL_PRICE_MONTHLY
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={3}>
                    <Form.Item
                      noStyle
                      name={LeadFieldsEnum.CURRENCY_MONTHLY}
                      rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
                    >
                      <PSelect
                        label={t('form.currency')}
                        options={currencyOptions}
                        id={LeadFieldsEnum.CURRENCY_MONTHLY}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}

            {values?.rent?.includes(ContractTypesEnum.DAILY) && (
              <Col span={24}>
                <Row gutter={16} align="middle">
                  <Col span={2}>
                    <PFormLabel text={t('form.rentDaily')} innerClassName="mb-0" isStatic />
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      noStyle
                      name={LeadFieldsEnum.TOTAL_PRICE_DAILY}
                      rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
                    >
                      <PNumericInput label={t('form.price')} id={LeadFieldsEnum.TOTAL_PRICE_DAILY} />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      noStyle
                      name={LeadFieldsEnum.CURRENCY_DAILY}
                      rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
                    >
                      <PSelect
                        label={t('form.currency')}
                        options={currencyOptions}
                        id={LeadFieldsEnum.CURRENCY_DAILY}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          <Divider />
        </>
      )}
      <Row gutter={16} className="mb-9">
        <Col span={12}>
          <Form.Item
            noStyle
            name={LeadFieldsEnum.INFO}
            rules={[{required: isFieldsRequired, message: fieldsMessageTranslations.common}]}
          >
            <PInput label={t('form.info')} id={LeadFieldsEnum.INFO} isTextarea rows={5} />
          </Form.Item>
        </Col>
      </Row>
      <div className="bg-white/[0.4] sticky bottom-0 pb-[1.5%] z-20 backdrop-blur-sm">
        <Divider className="mb-[1.5%]" />
        <Row gutter={16} justify="space-between">
          <Col>
            <Link to={RoutesEnum.LEADS} className="flex">
              <PButton type="text" scheme="danger" theme="default">
                {t('actions.cancel')}
              </PButton>
            </Link>
          </Col>
          <Col>
            <Row gutter={16}>
              {mode === ActionModesEnum.CREATE && (
                <Col>
                  <Form.Item noStyle>
                    <PButton
                      htmlType="submit"
                      type="text"
                      scheme="warning"
                      theme="default"
                      loading={isButtonLoading![SubmitModesEnum.CREATE_NEW]}
                      onClick={() => handleFormSubmition(SubmitModesEnum.CREATE_NEW)}
                    >
                      {t('actions.saveAndCreateNew')}
                    </PButton>
                  </Form.Item>
                </Col>
              )}
              <Col>
                <Form.Item noStyle>
                  <PButton
                    htmlType="submit"
                    type="primary"
                    onClick={() => handleFormSubmition(SubmitModesEnum.SEND)}
                    loading={isButtonLoading![SubmitModesEnum.SEND]}
                  >
                    {mode === ActionModesEnum.CREATE ? t('actions.saveAndSend') : t('actions.save')}
                  </PButton>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Form>
  );
};
