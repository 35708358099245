import {memo, useCallback, useState} from 'react';
import {Popconfirm, Skeleton} from 'antd';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {PInput} from 'components/primitives/p-input';
import {setInquiryEventPropertyCommentAction} from 'store/inquiries/actions';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {useToast} from 'hooks';

import {ICUpdateCommentProps} from './c-inquiry-event-property-comment-update.types';

const CInquiryEventPropertyCommentUpdate = ({
  children,
  initialValue,
  propertyId,
  inquiry,
  event,
  ...props
}: ICUpdateCommentProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {showError, contextHolder} = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [comment, setComment] = useState<string>(initialValue!);

  const onCommentsUpdate = useCallback(() => {
    const data = {
      property: propertyId,
      inquiry,
      event,
      comment,
    };

    setIsLoading(true);

    dispatch(
      setInquiryEventPropertyCommentAction(data, {
        onFulfilled: () => setIsLoading(false),
        onReject: () => {
          setIsLoading(false);
          showError({message: fieldsMessageTranslations.commonErrors});
        },
      })
    );
  }, [comment, propertyId, inquiry, event, showError, dispatch]);

  return (
    <>
      <Popconfirm
        {...props}
        icon={null}
        description={<PInput isTextarea rows={4} defaultValue={comment} onChange={e => setComment(e.target.value)} />}
        onConfirm={onCommentsUpdate}
        cancelText={t('actions.close')}
        okText={t('actions.change')}
      >
        <>
          {isLoading ? (
            <Skeleton.Button shape="default" size="small" className="min-w-[90px]" block active />
          ) : (
            children
          )}
        </>
      </Popconfirm>
      {contextHolder}
    </>
  );
};

export const CInquiryEventPropertyCommentUpdateMemoized = memo(CInquiryEventPropertyCommentUpdate);
