import {Col, Form, Row} from 'antd';
import {useTranslation} from 'react-i18next';

import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {Icon} from 'assets/icons';
import {PPhoneInput} from 'components/primitives/p-phone-input';
import {FormsEnum} from 'enums';
import {CSubmitButton} from 'components/containers/c-submit-button';

import {PersonFieldsEnum} from './form-person.enums';
import {PersonSearchProps} from './form-person.types';

export const FormPersonSearch = ({form, isSearchLoading, onSumbit}: PersonSearchProps) => {
  const {t} = useTranslation();
  const values = Form.useWatch([], form);

  return (
    <Form form={form} onFinish={onSumbit} name={FormsEnum.PERSON_SEARCH}>
      <Row gutter={20}>
        <Col span={18}>
          <Form.Item
            name={PersonFieldsEnum.PHONE}
            rules={[
              {
                required: true,
                message: fieldsMessageTranslations.common,
              },
              {min: 9, message: fieldsMessageTranslations.phoneMin},
              {max: 14, message: fieldsMessageTranslations.phoneMax},
            ]}
          >
            <PPhoneInput label={t('form.phone')} autoFocus />
          </Form.Item>
        </Col>
        <Col span={6}>
          <CSubmitButton
            form={form}
            values={values}
            isButtonLoading={isSearchLoading!}
            title={t('actions.search')}
            icon={<Icon.Search width={24} height={24} stroke="white" />}
          />
        </Col>
      </Row>
    </Form>
  );
};
