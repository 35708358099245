import {palette} from 'constants/theme';
import {ContractTypesEnum, PropertyActionsTypesEnum} from 'enums';
import {CustomerTypesTranslationEnum} from 'enums/translations';

export const injectCustomerModeTags = <T extends {[x: string]: any}>(record: T): Array<Record<string, string>> => {
  const customerTypes: Array<Record<string, string>> = [];

  record.action === PropertyActionsTypesEnum.OFFER && record.mode === ContractTypesEnum.ALL
    ? customerTypes.push(
        {text: CustomerTypesTranslationEnum.SALE, color: palette.success.primary},
        {text: CustomerTypesTranslationEnum.RENT, color: palette.primary}
      )
    : record.action === PropertyActionsTypesEnum.OFFER && record.mode === ContractTypesEnum.RENT
    ? customerTypes.push({text: CustomerTypesTranslationEnum.RENT, color: palette.primary})
    : record.action === PropertyActionsTypesEnum.OFFER && record.mode === ContractTypesEnum.SALE
    ? customerTypes.push({text: CustomerTypesTranslationEnum.SALE, color: palette.success.primary})
    : record.action === PropertyActionsTypesEnum.SEEK && record.mode === ContractTypesEnum.ALL
    ? customerTypes.push(
        {text: CustomerTypesTranslationEnum.CUSTOMER, color: palette.purple},
        {text: CustomerTypesTranslationEnum.RENTER, color: palette.info.primary}
      )
    : record.action === PropertyActionsTypesEnum.SEEK && record.mode === ContractTypesEnum.RENT
    ? customerTypes.push({text: CustomerTypesTranslationEnum.RENTER, color: palette.info.primary})
    : record.action === PropertyActionsTypesEnum.SEEK && record.mode === ContractTypesEnum.SALE
    ? customerTypes.push({text: CustomerTypesTranslationEnum.CUSTOMER, color: palette.purple})
    : customerTypes.push({text: CustomerTypesTranslationEnum.OTHER, color: palette.grey[100]});

  return customerTypes;
};
