import {memo, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Row} from 'antd';
import {useTranslation} from 'react-i18next';

import {getInquiryTotalsAction} from 'store/analytics/actions';
import {analyticsInquiryTotalsSelector, analyticsLoadingSelector} from 'store/analytics/selectors';
import {CPie} from 'components/containers/c-pie';
import {PLoader} from 'components/primitives/p-loader';

export const InquiryAnalyticsView = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const isLoading = useSelector(analyticsLoadingSelector);
  const inquiryTotals = useSelector(analyticsInquiryTotalsSelector);

  useEffect(() => {
    dispatch(getInquiryTotalsAction());
  }, [dispatch]);

  return isLoading ? (
    <PLoader />
  ) : (
    <Row gutter={[20, 20]}>
      <Col span={12}>
        <CPie headline={t('form.status')} data={{key: 'state', items: inquiryTotals?.state!}} />
      </Col>
      <Col span={12}>
        <CPie headline={t('common.broker')} data={{key: 'assignee', items: inquiryTotals?.assignee!}} />
      </Col>
    </Row>
  );
};

export const InquiryAnalyticsViewMemoized = memo(InquiryAnalyticsView);
