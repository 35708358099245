import {PermissionsEnum} from 'enums';
import {PropertyActionsTranslationEnum} from 'enums/translations';

export const permissionsOptions = [
  {
    label: PropertyActionsTranslationEnum.APARTMENT_SALE,
    value: PermissionsEnum.APARTMENT_SALE,
  },
  {
    label: PropertyActionsTranslationEnum.APARTMENT_RENT,
    value: PermissionsEnum.APARTMENT_RENT,
  },
  {
    label: PropertyActionsTranslationEnum.COMMERCIAL_SALE,
    value: PermissionsEnum.COMMERCIAL_SALE,
  },
  {
    label: PropertyActionsTranslationEnum.COMMERCIAL_RENT,
    value: PermissionsEnum.COMMERCIAL_RENT,
  },
  {
    label: PropertyActionsTranslationEnum.HOUSE_SALE,
    value: PermissionsEnum.HOUSE_SALE,
  },
  {
    label: PropertyActionsTranslationEnum.HOUSE_RENT,
    value: PermissionsEnum.HOUSE_RENT,
  },
  {
    label: PropertyActionsTranslationEnum.LAND_SALE,
    value: PermissionsEnum.LAND_SALE,
  },
  {
    label: PropertyActionsTranslationEnum.LAND_RENT,
    value: PermissionsEnum.LAND_RENT,
  },
  {
    label: PropertyActionsTranslationEnum.PARKING_SALE,
    value: PermissionsEnum.PARKING_SALE,
  },
  {
    label: PropertyActionsTranslationEnum.PARKING_RENT,
    value: PermissionsEnum.PARKING_RENT,
  },
];
