import {memo} from 'react';
import {useSelector} from 'react-redux';

import {getLeadPerformanceAction} from 'store/analytics/actions';
import {analyticsLeadPerformanceSelector} from 'store/analytics/selectors';
import {CPerformanceTable} from 'components/containers/c-performance-table';
import {PerformanceTypeEnum} from 'enums';

export const InfoAnalyticsView = () => {
  const leadPerformance = useSelector(analyticsLeadPerformanceSelector);

  return (
    <CPerformanceTable
      headline="Լիդեր"
      type={PerformanceTypeEnum.LEAD}
      action={getLeadPerformanceAction}
      dataSource={leadPerformance!}
    />
  );
};

export const InfoAnalyticsViewMemoized = memo(InfoAnalyticsView);
