import {Col, Form, Row} from 'antd';
import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {Icon} from 'assets/icons';
import {PInput} from 'components/primitives/p-input';
import {postAuthAction} from 'store/auth/actions';
import {useToast} from 'hooks';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {getTranslation} from 'utils';
import {ServerErrorsTranslationEnum} from 'enums/translations';
import {FormsEnum} from 'enums';
import {CSubmitButton} from 'components/containers/c-submit-button';

import {ILoginFields} from './form-login.interfaces';
import {FormLoginFieldsEnum} from './form-login.enums';

export const FormLogin = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {contextHolder, showError} = useToast();
  const [form] = Form.useForm<ILoginFields>();
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const values = Form.useWatch([], form);

  const handleSubmit = useCallback(
    (values: ILoginFields) => {
      setIsButtonLoading(true);

      dispatch(
        postAuthAction(
          {email: values.email!, password: values.password!},
          {
            onFulfilled: () => setIsButtonLoading(false),
            onReject: e => {
              showError({description: getTranslation(ServerErrorsTranslationEnum, e as string)});
              setIsButtonLoading(false);
            },
          }
        )
      );
    },
    [showError, dispatch]
  );

  return (
    <>
      <Form form={form} onFinish={handleSubmit} name={FormsEnum.LOGIN}>
        <Row>
          <Col span={24}>
            <Form.Item
              name={FormLoginFieldsEnum.EMAIL}
              rules={[
                {
                  type: 'email',
                  message: fieldsMessageTranslations.validEmail,
                },
                {required: true, message: fieldsMessageTranslations.common},
              ]}
            >
              <PInput label={t('form.email')} id={FormLoginFieldsEnum.EMAIL} type="email" autoComplete="on" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={FormLoginFieldsEnum.PASSWORD}
              rules={[{required: true, message: fieldsMessageTranslations.common}]}
            >
              <PInput isPassword id={FormLoginFieldsEnum.PASSWORD} label={t('form.password')} minLength={8} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <CSubmitButton
                form={form}
                values={values}
                isButtonLoading={isButtonLoading}
                title={t('actions.login')}
                icon={<Icon.LogIn fill="white" />}
              />
            </Row>
          </Col>
        </Row>
      </Form>
      {contextHolder}
    </>
  );
};
