import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Divider, Row} from 'antd';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Icon} from 'assets/icons';
import {PCardWrapper} from 'components/primitives/p-card-wrapper';
import {PLoader} from 'components/primitives/p-loader';
import {RoutesEnum} from 'router/routes.enum';
import {ActionModesEnum, BuildingSubTypesEnum, FoldersEnum, OptGroupsEnum, ServerErrorsEnum} from 'enums';
import {PButton} from 'components/primitives/p-button';
import {PPageTitle} from 'components/primitives/p-page-title';
import {PInfoBar} from 'components/primitives/p-info-bar';
import {PTitle} from 'components/primitives/p-title';
import {palette} from 'constants/theme';
import {CMap} from 'components/containers/c-map';
import {buildingCurrentSelector, buildingsErrorSelector, buildingsLoadingSelector} from 'store/buildings/selectors';
import {clearBuildingErrorsAction, clearCurrentBuildingAction, getBuildingByIdAction} from 'store/buildings/actions';
import {BuildingTypesTranslationEnum, HeatingTranslationEnum, PropertyTypesTranslationEnum} from 'enums/translations';
import {clearMediaAction, getFolderByIdAction} from 'store/app/actions';
import {appCatalogSelector, appCurrentFileSelector, appCurrentImageSelector} from 'store/app/selectors';
import {PFileEntity} from 'components/primitives/p-file-entity';
import {PCarousel} from 'components/primitives/p-carousel';
import {getTranslation, formatNumber, injectMediaPath} from 'utils';
import {currencySymbols} from 'constants/currency-symbols';
import {Image} from 'assets/images';
import {PLightbox} from 'components/primitives/p-lightbox';
import {CTextCopy} from 'components/containers/c-text-copy';
import {EmptyPage} from 'components/layout/empty-page';

export const PageBuildingView = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();
  const catalog = useSelector(appCatalogSelector);
  const isLoading = useSelector(buildingsLoadingSelector);
  const error = useSelector(buildingsErrorSelector);
  const currentBuilding = useSelector(buildingCurrentSelector);
  const currentFile = useSelector(appCurrentFileSelector);
  const currentImage = useSelector(appCurrentImageSelector);
  const [srcReservePath, setSrcReservePath] = useState<string[]>([]);
  const [isLighboxVisible, setIsLighboxVisible] = useState<boolean>(false);
  const [lighboxStartIndex, setLighboxStartIndex] = useState<number>(0);

  useEffect(() => {
    if (id) {
      dispatch(clearCurrentBuildingAction());
      dispatch(clearMediaAction());
      dispatch(getBuildingByIdAction({id}));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (currentBuilding?.image_folder) {
      dispatch(
        getFolderByIdAction(
          {
            id: currentBuilding.image_folder,
          },
          {folder: FoldersEnum.IMAGE}
        )
      );
    } else {
      dispatch(clearMediaAction({}, {folder: FoldersEnum.IMAGE}));
    }

    if (currentBuilding?.file_folder) {
      dispatch(
        getFolderByIdAction({
          id: currentBuilding.file_folder,
        })
      );
    } else {
      dispatch(clearMediaAction({}, {folder: FoldersEnum.FILE}));
    }
  }, [currentBuilding, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearBuildingErrorsAction());
    };
  }, [dispatch]);

  if (!isLoading && (error === ServerErrorsEnum.INVALID_ID || error?.response?.status === 404)) {
    return <EmptyPage showHeader />;
  }

  return isLoading ? (
    <PLoader />
  ) : (
    <PLoader spinning={isLoading} asLayer>
      <PPageTitle
        text={
          <PButton
            type="text"
            theme="white"
            icon={<Icon.ChevronLeftRounded fill="currentColor" />}
            onClick={() => navigate(-1)}
          >
            {t('actions.back')}
          </PButton>
        }
      />
      <PCardWrapper padding="md">
        <PTitle
          textSize="large"
          theme="danger"
          text={currentBuilding?.address?.combined}
          icon={<Icon.Location fill={palette.danger.primary} />}
        ></PTitle>
        <Divider />

        <Row gutter={28}>
          <Col span={12}>
            {currentImage?.id === currentBuilding?.image_folder && currentImage?.files.length > 0 ? (
              <>
                <PCarousel
                  className="rounded-[16px] overflow-hidden"
                  dots={false}
                  arrows={Object.keys(currentImage?.files).length > 1}
                >
                  {currentImage.files.map((img: any, i: number) => (
                    <img
                      key={img}
                      onError={() => {
                        setSrcReservePath([...srcReservePath, img.path]);
                      }}
                      src={srcReservePath.includes(img.path) ? Image.noImage : injectMediaPath(img?.path)}
                      onClick={e => {
                        e.stopPropagation();
                        setIsLighboxVisible(true);
                        setLighboxStartIndex(i);
                      }}
                      width="100%"
                      className="cursor-pointer"
                      alt="pic"
                    />
                  ))}
                </PCarousel>
                <div className="hidden">
                  <PLightbox
                    showThumbnails={true}
                    modalClose="clickOutside"
                    open={isLighboxVisible}
                    startingSlideIndex={lighboxStartIndex}
                    onClose={() => setIsLighboxVisible(false)}
                    className="hidden"
                  >
                    {currentImage?.files.map((img: any) => (
                      <img
                        key={img}
                        onError={() => {
                          setSrcReservePath([...srcReservePath, img.path]);
                        }}
                        src={srcReservePath.includes(img.path) ? Image.noImage : injectMediaPath(img?.path)}
                        width="100%"
                        alt="pic"
                      />
                    ))}
                  </PLightbox>
                </div>
              </>
            ) : null}

            <div className="mb-5">
              <div>
                <PInfoBar label={t('form.propertyId')} value={currentBuilding?.id}></PInfoBar>
                <PInfoBar label={t('form.floors')} value={currentBuilding?.floors}></PInfoBar>
                <PInfoBar
                  label={t('form.buildingUsage')}
                  value={getTranslation(PropertyTypesTranslationEnum, currentBuilding?.type!)}
                ></PInfoBar>
                {currentBuilding?.subtype && (
                  <PInfoBar
                    label={t('form.buildingType')}
                    value={getTranslation(BuildingTypesTranslationEnum, currentBuilding?.subtype)}
                  ></PInfoBar>
                )}

                {currentBuilding?.stone && (
                  <PInfoBar
                    label={t('form.buildingSubtype')}
                    value={
                      catalog[OptGroupsEnum.BUILDING_SUBTYPE_STONE]?.find(o => o.value === currentBuilding?.stone)
                        ?.label as string
                    }
                  ></PInfoBar>
                )}

                {currentBuilding?.subtype === BuildingSubTypesEnum.NEW && (
                  <PInfoBar label={t('catalog.buildingTypes.new')} value={t('common.yes')}></PInfoBar>
                )}

                {currentBuilding?.heating && (
                  <PInfoBar
                    label={t('form.heating')}
                    value={getTranslation(HeatingTranslationEnum, currentBuilding?.heating)}
                  ></PInfoBar>
                )}

                {currentBuilding?.fee && (
                  <PInfoBar
                    label={t('form.fee')}
                    value={`${formatNumber(currentBuilding?.fee)} ${currencySymbols.AMD.props.children}`}
                  ></PInfoBar>
                )}
              </div>
            </div>
          </Col>
          <Col span={12}>
            {currentBuilding?.persons?.length ? (
              <div className="mb-[32px]">
                <PTitle
                  textSize="medium"
                  text={t('common.contacts')}
                  icon={<Icon.User fill={palette.common} />}
                ></PTitle>
                <div>
                  {currentBuilding?.persons.map(p => (
                    <div key={p.id} className="rounded-[16px] px-4 bg-grey-40 mb-[16px] last:mb-0">
                      <PInfoBar label={t('form.firstName')} value={p?.name}></PInfoBar>
                      <PInfoBar
                        label={t('form.phone')}
                        value={p.phones.map(n => (
                          <CTextCopy key={`${n.id}-${n?.number}`} text={n?.number} />
                        ))}
                      ></PInfoBar>

                      {p.email && <PInfoBar label={t('form.email')} value={p.email}></PInfoBar>}

                      {p.relation && <PInfoBar label={t('form.relationWithBuilding')} value={p.relation}></PInfoBar>}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}

            {currentFile?.files.length && (
              <div className="mb-[32px]">
                <PTitle
                  textSize="medium"
                  text={t('common.attachedFiles')}
                  icon={<Icon.File width={20} color={palette.common} />}
                ></PTitle>
                <div className="rounded-[16px] px-4 bg-grey-40">
                  {currentFile?.files.map((file: any) => <PFileEntity key={file?.name} entity={file} />)}
                </div>
              </div>
            )}

            {currentBuilding?.info && (
              <div className="mb-5">
                <PTitle
                  textSize="medium"
                  text={t('common.buildingDescription')}
                  icon={<Icon.Pen fill={palette.common} />}
                ></PTitle>
                <div className="text-[14px] text-[#848484] leading-[140%]">{currentBuilding?.info}</div>
              </div>
            )}
          </Col>
          <Col span={24}>
            <div className="mb-6 mt-[30px]">
              <PTitle
                textSize="medium"
                theme="danger"
                text={`${currentBuilding?.country?.name} / ${
                  currentBuilding?.region?.name ? `${currentBuilding?.region?.name} / ` : ''
                } ${currentBuilding?.district?.name ? `${currentBuilding?.district?.name} / ` : ''} ${
                  currentBuilding?.settlement?.name &&
                  currentBuilding?.settlement?.name !== currentBuilding?.region?.name
                    ? `${currentBuilding?.settlement?.name} / `
                    : ''
                } ${currentBuilding?.street?.name ? `${currentBuilding?.street?.name}` : ''}`}
                icon={<Icon.Map fill={palette.danger.primary} />}
              ></PTitle>

              <Col span={22} className="!p-0">
                <CMap zoom={16} initialAddress={currentBuilding?.address!} />
              </Col>
            </div>
          </Col>
          <Col span={24} className="text-right">
            <Link to={`${RoutesEnum.BUILDINGS}/${ActionModesEnum.UPDATE}/${id}`}>
              <PButton type="primary" icon={<Icon.Edit width={24} height={24} />}>
                {t('actions.update')}
              </PButton>
            </Link>
          </Col>
        </Row>
      </PCardWrapper>
    </PLoader>
  );
};
