export const CSS = {
  theme: {
    white: 'text-common bg-white',
    default: 'text-common bg-grey-60',
  },
  scheme: {
    danger: 'border-danger',
    warning: 'border-primary',
  },
} as const;
