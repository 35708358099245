import {Col, Form, FormInstance, Radio, RadioChangeEvent, Row} from 'antd';
import {useTranslation} from 'react-i18next';

import {PInput} from 'components/primitives/p-input';
import {PDatepicker} from 'components/primitives/p-datepicker';
import {PRadio} from 'components/primitives/p-radio';
import {FormsEnum, StatusEnum} from 'enums';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';

import {ChangeBusinessProcessFieldsEnum} from './form-change-business-process.enums';
import {IChangeBusinessProcessFields} from './form-change-business-process.interfaces';

export const FormChangeBusinessProcess = ({
  form,
  values,
  initialValues,
  statusType,
  onSumbit,
  handleStatusChange,
}: {
  statusType: string;
  form: FormInstance<IChangeBusinessProcessFields>;
  values: IChangeBusinessProcessFields;
  initialValues?: IChangeBusinessProcessFields;
  onSumbit: (v: IChangeBusinessProcessFields) => void;
  handleStatusChange: (e: RadioChangeEvent) => void;
}) => {
  const {t} = useTranslation();

  return (
    <Form form={form} onFinish={onSumbit} initialValues={initialValues} name={FormsEnum.CHANGE_BUSINESS_PROCESS}>
      <Row gutter={20}>
        <Col span={24}>
          <Radio.Group
            onChange={handleStatusChange}
            defaultValue={statusType}
            value={statusType}
            optionType="button"
            buttonStyle="outline"
            size="large"
            className="w-full flex gap-5 mb-10"
          >
            <PRadio
              value={StatusEnum.ARCHIVED}
              label={t('actions.archivate')}
              className="w-full text-center bg-grey-20"
            ></PRadio>
            <PRadio
              value={StatusEnum.ONHOLD}
              label={t('actions.hold')}
              className="w-full text-center bg-grey-20"
            ></PRadio>
          </Radio.Group>
        </Col>

        {statusType === StatusEnum.ONHOLD && (
          <Col span={24}>
            <Form.Item
              name={ChangeBusinessProcessFieldsEnum.TO}
              rules={[{required: true, message: fieldsMessageTranslations.common}]}
            >
              <PDatepicker
                label={t('form.freezeToDate')}
                placeholder=""
                disabledDate={d => !d || d.isBefore(new Date())}
              />
            </Form.Item>
            <Form.Item
              name={ChangeBusinessProcessFieldsEnum.COMMENTS}
              rules={[{required: true, message: fieldsMessageTranslations.common}]}
            >
              <PInput label={t('form.noteReason')} isTextarea rows={4} />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
};
