import {NotFound} from 'components/layout/not-found';
import {PageLogin} from 'components/pages/login';
import {PageAccounts} from 'components/pages/accounts';
import {RoutesEnum} from 'router/routes.enum';
import {RolesEnum} from 'enums';
import {PageBuildings} from 'components/pages/buildings';
import {PageLeads} from 'components/pages/leads';
import {PageBuildingDetailed} from 'components/pages/building-detailed';
import {PagePersons} from 'components/pages/persons';
import {PageLeadsDetailed} from 'components/pages/leads-detailed';
import {PageLeadsView} from 'components/pages/leads-view';
import {PageBuildingView} from 'components/pages/building-view';
import {PageProperties} from 'components/pages/properties';
import {PagePropertyDetailed} from 'components/pages/property-detailed';
import {PagePropertyView} from 'components/pages/property-view';
import {PageInquiries} from 'components/pages/inquiries';
import {PageInquiriesDetailed} from 'components/pages/inquiries-detailed';
import {PageAnalytics} from 'components/pages/analytics';

export const Route = {
  leads: {
    path: RoutesEnum.LEADS,
    element: <PageLeads />,
    privateEntity: true,
    visibleTo: [RolesEnum.SUPER, RolesEnum.ADMIN, RolesEnum.INFO],
  },
  leadsDetailed: {
    path: RoutesEnum.LEADS_DETAILED,
    element: <PageLeadsDetailed />,
    privateEntity: true,
    visibleTo: [RolesEnum.SUPER, RolesEnum.ADMIN, RolesEnum.INFO],
  },
  leadsView: {
    path: RoutesEnum.LEADS_VIEW,
    element: <PageLeadsView />,
    privateEntity: true,
    visibleTo: [RolesEnum.SUPER, RolesEnum.ADMIN, RolesEnum.INFO],
  },
  buildings: {
    path: RoutesEnum.BUILDINGS,
    element: <PageBuildings />,
    privateEntity: true,
    visibleTo: [RolesEnum.SUPER, RolesEnum.ADMIN, RolesEnum.BROKER],
  },
  buildingDetailed: {
    path: RoutesEnum.BUILDING_DETAILED,
    element: <PageBuildingDetailed />,
    privateEntity: true,
    visibleTo: [RolesEnum.SUPER, RolesEnum.ADMIN, RolesEnum.BROKER],
  },
  buildingView: {
    path: RoutesEnum.BUILDING_VIEW,
    element: <PageBuildingView />,
    privateEntity: true,
    visibleTo: [RolesEnum.SUPER, RolesEnum.ADMIN, RolesEnum.BROKER],
  },
  properties: {
    path: RoutesEnum.PROPERTIES,
    element: <PageProperties />,
    privateEntity: true,
    visibleTo: [RolesEnum.SUPER, RolesEnum.BROKER, RolesEnum.MARKETING],
  },
  propertiesDetailed: {
    path: RoutesEnum.PROPERTIES_DETAILED,
    element: <PagePropertyDetailed />,
    privateEntity: true,
    visibleTo: [RolesEnum.SUPER, RolesEnum.BROKER, RolesEnum.MARKETING],
  },
  propertiesView: {
    path: RoutesEnum.PROPERTIES_VIEW,
    element: <PagePropertyView />,
    privateEntity: true,
    visibleTo: [RolesEnum.SUPER, RolesEnum.BROKER, RolesEnum.MARKETING],
  },
  inquiries: {
    path: RoutesEnum.INQUIRIES,
    element: <PageInquiries />,
    privateEntity: true,
    visibleTo: [RolesEnum.SUPER, RolesEnum.ADMIN, RolesEnum.BROKER],
  },
  inquiriesDetailed: {
    path: RoutesEnum.INQUIRIES_DETAILED,
    element: <PageInquiriesDetailed />,
    privateEntity: true,
    visibleTo: [RolesEnum.SUPER, RolesEnum.ADMIN, RolesEnum.BROKER],
  },
  persons: {
    path: RoutesEnum.PERSONS,
    element: <PagePersons />,
    privateEntity: true,
    visibleTo: [RolesEnum.SUPER, RolesEnum.ADMIN, RolesEnum.BROKER, RolesEnum.INFO],
  },
  personsDetailed: {
    path: RoutesEnum.PERSONS_DETAILED,
    element: <PagePersons />,
    privateEntity: true,
    visibleTo: [RolesEnum.SUPER, RolesEnum.ADMIN, RolesEnum.BROKER, RolesEnum.INFO],
  },
  accounts: {
    path: RoutesEnum.ACCOUNTS,
    element: <PageAccounts />,
    privateEntity: true,
    visibleTo: [RolesEnum.SUPER, RolesEnum.ADMIN],
  },
  analytics: {
    path: RoutesEnum.ANALYTICS,
    element: <PageAnalytics />,
    privateEntity: true,
    visibleTo: [RolesEnum.SUPER],
  },
  login: {
    path: RoutesEnum.LOGIN,
    element: <PageLogin />,
    visibleTo: [RolesEnum.SUPER, RolesEnum.ADMIN, RolesEnum.INFO, RolesEnum.BROKER, RolesEnum.MARKETING],
  },

  // this entity must be at the end of the object!
  notFound: {
    path: RoutesEnum.NOT_FOUND,
    element: <NotFound />,
    visibleTo: [RolesEnum.SUPER, RolesEnum.ADMIN, RolesEnum.INFO, RolesEnum.BROKER, RolesEnum.MARKETING],
  },
};

export const routes = Object.values(Route).map(({path, element, visibleTo, ...other}) => ({
  path,
  element,
  privateEntity: (other as {privateEntity: boolean}).privateEntity,
  visibleTo,
}));
