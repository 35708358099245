import {memo, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form} from 'antd';
import {useTranslation} from 'react-i18next';

import {IChangeBusinessProcessFields} from 'components/forms/change-business-process/form-change-business-process.interfaces';
import {ChangeBusinessProcessFieldsEnum} from 'components/forms/change-business-process/form-change-business-process.enums';
import {PInput} from 'components/primitives/p-input';
import {updateInquiryInfoAction} from 'store/inquiries/actions';
import {inquiriesUpdatedInfoSelector} from 'store/inquiries/selectors';
import {CSubmitButton} from 'components/containers/c-submit-button';

const CInquiryInfo = ({entity}: {entity: any}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const updatedInquiryInfo = useSelector(inquiriesUpdatedInfoSelector);
  const [form] = Form.useForm<IChangeBusinessProcessFields>();

  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const values = Form.useWatch([], form);

  const handleSubmit = useCallback(
    (values: IChangeBusinessProcessFields) => {
      setIsButtonLoading(true);

      const valuesToSend = {
        ...values,
        inquiry: entity.id,
      };

      dispatch(updateInquiryInfoAction(valuesToSend));
    },
    [entity, dispatch]
  );

  useEffect(() => {
    const timeout = () =>
      setTimeout(() => {
        form.resetFields();
        setIsButtonLoading(false);
      }, 600);

    if (updatedInquiryInfo) {
      timeout();
    }

    return () => clearTimeout(timeout());
  }, [updatedInquiryInfo, form]);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={{[ChangeBusinessProcessFieldsEnum.INFO]: entity.info}}
      className="flex flex-wrap justify-end"
    >
      <Form.Item
        className="mb-4 w-full"
        name={ChangeBusinessProcessFieldsEnum.INFO}
        rules={[{required: true, message: ''}]}
      >
        <PInput isTextarea rows={3} allowClear />
      </Form.Item>

      <CSubmitButton form={form} values={values} isButtonLoading={isButtonLoading} title={t('actions.save')} />
    </Form>
  );
};

export const CInquiryInfoMemoized = memo(CInquiryInfo);
