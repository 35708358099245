import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Divider, Form, RadioChangeEvent, Row} from 'antd';
import {v4 as uuidv4} from 'uuid';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {PCardWrapper} from 'components/primitives/p-card-wrapper';
import {PLoader} from 'components/primitives/p-loader';
import {PPageTitle} from 'components/primitives/p-page-title';
import {PTable} from 'components/primitives/p-table';
import {PropertyTypesEnum, ContractTypesEnum, CustomerTypesEnum, PaginationEnum} from 'enums';
import {PRadio} from 'components/primitives/p-radio';
import {clearMediaAction} from 'store/app/actions';
import {PFormLabel} from 'components/primitives/p-form-label';
import {PSelect} from 'components/primitives/p-select';
import {contractModeOptions} from 'constants/select-options';
import {RoutesEnum} from 'router/routes.enum';
import {useQueryParams} from 'hooks';
import {clearFromEmptyKeys, formatPhoneNumber, injectedContractMode, omittedParams} from 'utils';
import {FormInquiriesFilter} from 'components/forms/inquiries-filter';
import {IInquiriesFilterFields} from 'components/forms/inquiries-filter/form-inquiries-filter.interfaces';
import {inquiriesCountSelector, inquiriesListSelector, inquiriesLoadingSelector} from 'store/inquiries/selectors';
import {
  clearInquiryInfoAndCommentAction,
  getInquiriesAction,
  saveInquiriesFilterPresetsAction,
} from 'store/inquiries/actions';
import {TPaginationOptions, TSortOptions} from 'types/common';
import {LISTING_LENGTH, LISTING_OFFSET, paginationDefaultOptions, paginationEmptyOptions} from 'constants/pagination';
import {useFilterPresets} from 'hooks';

import {columns} from './constants/columns';

export const PageInquiries = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useQueryParams();
  const handlePresetsSave = useFilterPresets();
  const isLoading = useSelector(inquiriesLoadingSelector);
  const inquiries = useSelector(inquiriesListSelector);
  const inquiriesCount = useSelector(inquiriesCountSelector);

  const contractTypeFromParams = useMemo(() => {
    const contractTypeToSet = [];
    (params['mode'] === ContractTypesEnum.SALE || params['mode'] === ContractTypesEnum.ALL) &&
      contractTypeToSet.push(ContractTypesEnum.SALE);

    (params['rent'] === ContractTypesEnum.MONTHLY || params['rent'] === ContractTypesEnum.ALL) &&
      contractTypeToSet.push(ContractTypesEnum.MONTHLY);
    (params['rent'] === ContractTypesEnum.DAILY || params['rent'] === ContractTypesEnum.ALL) &&
      contractTypeToSet.push(ContractTypesEnum.DAILY);

    return contractTypeToSet;
  }, [params]);

  const [propertyType, setPropertyType] = useState<PropertyTypesEnum | null>(
    params.type || PropertyTypesEnum.APARTMENT
  );

  const filterInitialValuesFromParams = useMemo(() => {
    const {type, mode, ...rest} = omittedParams(params);

    return clearFromEmptyKeys<IInquiriesFilterFields>({...rest});
  }, [params]);

  const [contractType, setContractType] = useState<Array<ContractTypesEnum> | null>(contractTypeFromParams || null);
  const [filterForm] = Form.useForm<IInquiriesFilterFields>();
  const filterValues = Form.useWatch([], filterForm);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isFetchedFromParams, setIsFetchedFromParams] = useState<boolean>(Object.keys(params).length > 0);
  const [paginationOptions, setPaginationOptions] = useState<TPaginationOptions>({
    [PaginationEnum.PAGE]: params[PaginationEnum.PAGE] || 1,
    [PaginationEnum.OFFSET]: params[PaginationEnum.OFFSET] || LISTING_OFFSET,
    [PaginationEnum.LENGTH]: params[PaginationEnum.LENGTH] || LISTING_LENGTH,
  });

  const contractOptions = useMemo(
    () =>
      propertyType === PropertyTypesEnum.HOUSE || propertyType === PropertyTypesEnum.COMMERCIAL
        ? contractModeOptions.filter(c => c.value !== ContractTypesEnum.DAILY)
        : propertyType === PropertyTypesEnum.LAND
        ? contractModeOptions.filter(c => c.value === ContractTypesEnum.SALE)
        : contractModeOptions,
    [propertyType]
  );
  const presets = useMemo(
    () => ({
      type: propertyType === PropertyTypesEnum.APARTMENT ? PropertyTypesEnum.APARTMENT : propertyType,
      'mode[]': injectedContractMode(contractType).mode,
      'rent[]': injectedContractMode(contractType).rent,
    }),
    [propertyType, contractType]
  );

  const handleFilterSubmit = useCallback(
    (values: IInquiriesFilterFields) => {
      setIsButtonLoading(true);

      const valuesToSend = {
        ...values,
        phone: formatPhoneNumber(values.phone!),
      };

      handlePresetsSave({
        route: RoutesEnum.INQUIRIES,
        params: {...params, ...valuesToSend, ...paginationEmptyOptions},
        action: saveInquiriesFilterPresetsAction,
      });

      setPaginationOptions(paginationDefaultOptions);

      dispatch(
        getInquiriesAction(
          {...presets, ...valuesToSend},
          {onFulfilled: () => setIsButtonLoading(false), onReject: () => setIsButtonLoading(false)}
        )
      );
    },
    [presets, params, dispatch, handlePresetsSave]
  );

  const handleFilterClear = useCallback(() => {
    handlePresetsSave({
      route: RoutesEnum.INQUIRIES,
      params: {
        type: params.type,
        mode: params['mode'],
        rent: params['rent'],
        ...paginationEmptyOptions,
      },
      action: saveInquiriesFilterPresetsAction,
    });

    setPaginationOptions(paginationDefaultOptions);
    setIsFetchedFromParams(false);

    dispatch(getInquiriesAction({...presets}));

    filterForm.resetFields();
  }, [filterForm, presets, params, handlePresetsSave, dispatch]);

  const handlePropertyTypeChange = useCallback(
    (e: RadioChangeEvent) => {
      const isLand = e.target.value === PropertyTypesEnum.LAND;
      const isHouseOrCommercial =
        e.target.value === PropertyTypesEnum.HOUSE || e.target.value === PropertyTypesEnum.COMMERCIAL;

      setContractType(prev =>
        isLand
          ? prev?.filter(c => c === ContractTypesEnum.SALE)!
          : isHouseOrCommercial
          ? prev?.filter(c => c !== ContractTypesEnum.DAILY)!
          : prev
      );

      const contractTypeToSet = {
        mode:
          isLand && (params['mode'] === ContractTypesEnum.ALL || params['mode'] === ContractTypesEnum.SALE)
            ? ContractTypesEnum.SALE
            : isLand && params['mode'] === ContractTypesEnum.RENT
            ? null
            : isHouseOrCommercial &&
              params['mode'] === ContractTypesEnum.RENT &&
              params['rent'] === ContractTypesEnum.DAILY
            ? null
            : isHouseOrCommercial &&
              params['mode'] === ContractTypesEnum.ALL &&
              params['rent'] === ContractTypesEnum.DAILY
            ? ContractTypesEnum.SALE
            : params['mode'],
        rent: isLand
          ? null
          : isHouseOrCommercial && params['rent'] === ContractTypesEnum.ALL
          ? ContractTypesEnum.MONTHLY
          : isHouseOrCommercial && params['rent'] === ContractTypesEnum.DAILY
          ? null
          : params['rent'],
      };

      handlePresetsSave({
        route: RoutesEnum.INQUIRIES,
        params: {
          ...params,
          type: e.target.value,
          ...contractTypeToSet,
          ...paginationEmptyOptions,
        },
        action: saveInquiriesFilterPresetsAction,
      });

      setPropertyType(e.target.value);
      setIsFetchedFromParams(false);
      setPaginationOptions(paginationDefaultOptions);
    },
    [params, handlePresetsSave]
  );

  const handleContractTypeChange = useCallback(
    (val: Array<ContractTypesEnum>) => {
      setContractType(val);

      const contractTypeToSet = {
        mode:
          val?.includes(ContractTypesEnum.SALE) &&
          !val?.includes(ContractTypesEnum.MONTHLY) &&
          !val?.includes(ContractTypesEnum.DAILY)
            ? CustomerTypesEnum.SALE
            : !val?.includes(ContractTypesEnum.SALE) &&
              (val?.includes(ContractTypesEnum.MONTHLY) || val?.includes(ContractTypesEnum.DAILY))
            ? ContractTypesEnum.RENT
            : val?.includes(ContractTypesEnum.SALE) &&
              (val?.includes(ContractTypesEnum.MONTHLY) || val?.includes(ContractTypesEnum.DAILY))
            ? CustomerTypesEnum.ALL
            : null,
        rent:
          val?.includes(ContractTypesEnum.MONTHLY) && val?.includes(ContractTypesEnum.DAILY)
            ? ContractTypesEnum.ALL
            : val?.includes(ContractTypesEnum.MONTHLY)
            ? ContractTypesEnum.MONTHLY
            : val?.includes(ContractTypesEnum.DAILY)
            ? ContractTypesEnum.DAILY
            : null,
      };

      handlePresetsSave({
        route: RoutesEnum.INQUIRIES,
        params: {...params, ...contractTypeToSet, ...paginationEmptyOptions},
        action: saveInquiriesFilterPresetsAction,
      });

      setIsFetchedFromParams(false);
      setPaginationOptions(paginationDefaultOptions);
    },
    [params, handlePresetsSave]
  );

  const handleTableChange = useCallback(
    (pagination: TPaginationOptions, sorter: TSortOptions) => {
      setPaginationOptions(pagination);

      handlePresetsSave({
        route: RoutesEnum.INQUIRIES,
        params: {...params, ...pagination},
        action: saveInquiriesFilterPresetsAction,
      });
    },
    [params, handlePresetsSave]
  );

  useEffect(() => {
    //TODO: refactor presets
    const type = {
      type: propertyType === PropertyTypesEnum.APARTMENT ? PropertyTypesEnum.APARTMENT : propertyType,
    };
    const mode = {
      mode: presets['mode[]'],
      rent: presets['rent[]'],
    };

    const filterPresets = filterForm.getFieldsValue();

    dispatch(getInquiriesAction({...type, ...mode, ...filterPresets}));
  }, [isFetchedFromParams, propertyType, contractType, presets, filterForm, dispatch]);

  useEffect(() => {
    dispatch(clearInquiryInfoAndCommentAction());
    dispatch(clearMediaAction());
  }, [dispatch]);

  return (
    <PLoader spinning={isLoading} asLayer>
      <PCardWrapper padding="md">
        <Row justify="space-between">
          <Col>
            <PPageTitle text={t('pages.inquiries.title')} />
          </Col>
        </Row>
        <Row gutter={32}>
          <Col>
            <PFormLabel isStatic text={t('form.propertyType')} />
            <PRadio
              isWrapper
              onChange={handlePropertyTypeChange}
              defaultValue={propertyType}
              options={[
                {label: t('catalog.propertyTypes.apartment'), value: PropertyTypesEnum.APARTMENT},
                {label: t('catalog.propertyTypes.house'), value: PropertyTypesEnum.HOUSE},
                {label: t('catalog.propertyTypes.land'), value: PropertyTypesEnum.LAND},
                {label: t('catalog.propertyTypes.commercial'), value: PropertyTypesEnum.COMMERCIAL},
              ]}
            ></PRadio>
          </Col>
          <Col span={7}>
            <PFormLabel isStatic text={t('form.contractType')} />
            <PSelect
              mode="multiple"
              defaultValue={contractType}
              value={contractType}
              options={contractOptions}
              onChange={handleContractTypeChange}
            />
          </Col>
        </Row>
        <Divider />
        <FormInquiriesFilter
          isButtonLoading={isButtonLoading}
          form={filterForm}
          values={filterValues}
          initialValues={filterInitialValuesFromParams}
          onSubmit={handleFilterSubmit}
          onReset={handleFilterClear}
        />
      </PCardWrapper>

      <PCardWrapper padding="md">
        <PTable
          showLeged
          loading={isLoading}
          columns={columns({translation: t})}
          paginationOptions={{
            total: inquiriesCount,
            defaultPageSize: paginationOptions.length,
            pageSize: paginationOptions.length,
            defaultCurrent: paginationOptions.page,
            current: paginationOptions.page,
          }}
          handleChange={handleTableChange}
          dataSource={inquiries}
          rowKey={() => uuidv4()}
          rowClassName="cursor-pointer"
          onRow={(record, rowIndex) => ({
            onClick: event => {
              navigate(`${RoutesEnum.INQUIRIES}/${record.id}`);
            },
          })}
        />
      </PCardWrapper>
    </PLoader>
  );
};
