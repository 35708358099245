import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Badge, Col, Divider, Form, Radio, RadioChangeEvent, Row, Tag} from 'antd';
import dayjs from 'dayjs';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {PButton} from 'components/primitives/p-button';
import {PCardWrapper} from 'components/primitives/p-card-wrapper';
import {PLoader} from 'components/primitives/p-loader';
import {PPageTitle} from 'components/primitives/p-page-title';
import {PTable} from 'components/primitives/p-table';
import {
  FormatsEnum,
  InquiryStateEnum,
  PropertyActionsTypesEnum,
  PropertyTypesEnum,
  RolesEnum,
  SeparatorEnum,
  ContractTypesEnum,
  StatusEnum,
  CustomerTypesEnum,
  ActionModesEnum,
  SortOrderEnum,
  PaginationEnum,
  LocationPropsEnum,
} from 'enums';
import {PRadio} from 'components/primitives/p-radio';
import {InquiryStateTranslationEnum, StatusTranslationEnum} from 'enums/translations';
import {clearMediaAction} from 'store/app/actions';
import {appCatalogSelector, appProfileSelector} from 'store/app/selectors';
import {PStatus} from 'components/primitives/p-status';
import {
  archivedPropertySelector,
  holdedPropertySelector,
  propertiesCountSelector,
  propertiesListSelector,
  propertiesLoadingSelector,
  propertyDisabledSelector,
  propertyEnabledSelector,
  propertyRefreshedPriceSelector,
  propertyUpdatedCommentSelector,
  propertyUpdatedPriceSelector,
} from 'store/properties/selectors';
import {
  clearCurrentPropertyAction,
  clearPropertiesAction,
  savePropertyFilterPresetsAction,
  searchPropertiesAction,
  updatePropertyAction,
} from 'store/properties/actions';
import {PFormLabel} from 'components/primitives/p-form-label';
import {clearCurrentLeadAction, clearLeadsAction, searchLeadsAction} from 'store/leads/actions';
import {leadsListSelector, leadsLoadingSelector} from 'store/leads/selectors';
import {useFilterPresets, usePopup, useToast} from 'hooks';
import {PopupPropertyPersons} from 'components/popups/property-persons';
import {TPerson} from 'types/api';
import {PopupChangeProcess} from 'components/popups/change-process';
import {FormPropertyFilter, FormPropertyLeadsFilter} from 'components/forms/property-filter';
import {IPropertyFilterFields} from 'components/forms/property-filter/form-property-filter.interfaces';
import {PropertyFilterFieldsEnum} from 'components/forms/property-filter/form-property-filter.enums';
import {ILeadsFilterFields} from 'components/forms/leads-filter/form-leads-filter.interfaces';
import {
  checkForArrayValidity,
  formatDate,
  formatPhoneNumber,
  translateFilter,
  getTranslation,
  injectedContractMode,
  injectLocationFilterValues,
  injectPropertyFilterValues,
} from 'utils';
import {PSelect} from 'components/primitives/p-select';
import {contractModeOptions, inquiryStateOptions} from 'constants/select-options';
import {RoutesEnum} from 'router/routes.enum';
import {useQueryParams} from 'hooks';
import {clearFromEmptyKeys, omittedParams} from 'utils';
import {CPropertyGallery} from 'components/containers/c-property-gallery';
import {
  addInquiryBookmarkAction,
  addInquiryEventAction,
  removeInquiryBookmarkAction,
  updateInquiryEventSearchAction,
} from 'store/inquiries/actions';
import {
  inquiriesBookmarkeAddedSelector,
  inquiriesBookmarkeRemovedSelector,
  inquiriesCurrentSelector,
  inquiriesEventSelector,
} from 'store/inquiries/selectors';
import {TPaginationOptions, TSortOptions} from 'types/common';
import {LISTING_LENGTH, LISTING_OFFSET, paginationDefaultOptions, paginationEmptyOptions} from 'constants/pagination';
import {clearCurrentBuildingAction} from 'store/buildings/actions';
import {Icon} from 'assets/icons';
import {CCountBadge} from 'components/containers/c-count-badge';
import {searchPropertiesService} from 'services/properties';
import {PopupBooking} from 'components/popups/booking';
import {IPropertyFields} from 'components/forms/property/form-property.interfaces';
import {PopupConfirm} from 'components/popups/confirm';
import {BookingFieldsEnum} from 'components/forms/booking/form-booking.enums';
import {PAlert} from 'components/primitives/p-alert';

import {columns} from './constants/columns';
import {statuses} from './constants/statuses';
import {leadsColumns} from './constants/leads-columns';
import {TScreenPropertiesProps} from './screen-properties.types';

const ScreenProperties = ({inquiry, isInquiryInHistoryMode, selectedHistoryEvent}: TScreenPropertiesProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {show} = usePopup();
  const {showWarning, contextHolder} = useToast();
  const params = useQueryParams();
  const handlePresetsSave = useFilterPresets();
  const isLeadsLoading = useSelector(leadsLoadingSelector);
  const isLoading = useSelector(propertiesLoadingSelector);
  const properties = useSelector(propertiesListSelector);
  const propertiesCount = useSelector(propertiesCountSelector);
  const profile = useSelector(appProfileSelector);
  const leads = useSelector(leadsListSelector);
  const catalog = useSelector(appCatalogSelector);
  const archivedProperty = useSelector(archivedPropertySelector);
  const holdedProperty = useSelector(holdedPropertySelector);
  const disabled = useSelector(propertyDisabledSelector);
  const enabled = useSelector(propertyEnabledSelector);
  const updatedComment = useSelector(propertyUpdatedCommentSelector);
  const updatedPrice = useSelector(propertyUpdatedPriceSelector);
  const refreshedPrice = useSelector(propertyRefreshedPriceSelector);
  const bookmarkAddedInquiry = useSelector(inquiriesBookmarkeAddedSelector);
  const bookmarkRemovedInquiry = useSelector(inquiriesBookmarkeRemovedSelector);
  const inquiryEvent = useSelector(inquiriesEventSelector);
  const currentInquiry = useSelector(inquiriesCurrentSelector);

  const statusFromParams = useMemo(
    () => (Array.isArray(params['status[]']) ? StatusEnum.ALL : (params['status[]'] as string)),
    [params]
  );

  const contractTypeFromParams = useMemo(() => {
    const contractTypeToSet = [];
    const mode = params['mode[]'] || (inquiry && [currentInquiry?.mode]);
    const rent = params['rent[]'] || (inquiry && [currentInquiry?.rent]);

    (mode?.includes(ContractTypesEnum.SALE) || (!inquiry && mode?.includes(ContractTypesEnum.ALL))) &&
      contractTypeToSet.push(ContractTypesEnum.SALE);

    (mode?.includes(ContractTypesEnum.RENT) || mode?.includes(ContractTypesEnum.ALL)) &&
      (rent?.includes(ContractTypesEnum.MONTHLY) || rent?.includes(ContractTypesEnum.ALL)) &&
      contractTypeToSet.push(ContractTypesEnum.MONTHLY);

    (mode?.includes(ContractTypesEnum.RENT) || mode?.includes(ContractTypesEnum.ALL)) &&
      (rent?.includes(ContractTypesEnum.DAILY) || rent?.includes(ContractTypesEnum.ALL)) &&
      contractTypeToSet.push(ContractTypesEnum.DAILY);

    return contractTypeToSet;
  }, [params, currentInquiry, inquiry]);

  const [statusType, setStatusType] = useState<StatusEnum | string>(statusFromParams || StatusEnum.ALL);
  const [propertyType, setPropertyType] = useState<PropertyTypesEnum | null>(
    params.type || (inquiry && currentInquiry?.type) || PropertyTypesEnum.APARTMENT
  );
  const [contractType, setContractType] = useState<Array<ContractTypesEnum> | null>(contractTypeFromParams || null);
  const [paginationOptions, setPaginationOptions] = useState<TPaginationOptions>({
    [PaginationEnum.PAGE]: params[PaginationEnum.PAGE] || 1,
    [PaginationEnum.OFFSET]: params[PaginationEnum.OFFSET] || LISTING_OFFSET,
    [PaginationEnum.LENGTH]: params[PaginationEnum.LENGTH] || LISTING_LENGTH,
  });
  const [sortOptions, setSortOptions] = useState<TSortOptions>({
    [SortOrderEnum.SORT]: params[SortOrderEnum.SORT] || null,
    [SortOrderEnum.ASC]: params[SortOrderEnum.ASC] || null,
    [SortOrderEnum.DESC]: params[SortOrderEnum.DESC] || null,
  });

  const [filterForm] = Form.useForm<IPropertyFilterFields>();
  const [leadsFilterForm] = Form.useForm<ILeadsFilterFields>();
  const leadsFilterValues = Form.useWatch([], leadsFilterForm);
  const filterValues = Form.useWatch([], filterForm);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isLighboxVisible, setIsLighboxVisible] = useState<boolean>(false);
  const [isLighboxLoading, setIsLighboxLoading] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<number>(0);
  const [isLeadsLoaded, setLeadsLoaded] = useState<boolean>(false);
  const [isFormSubmited, setIsFormSubmited] = useState<boolean>(false);
  const [isFetchedFromParams, setIsFetchedFromParams] = useState<boolean>(false);
  const [isInquiryEventButtonLoading, setIsInquiryEventButtonLoading] = useState<boolean>(false);
  const [isInquiryInSearchMode, setIsInquiryInSearchMode] = useState<boolean>(
    (currentInquiry?.events[0]?.type === InquiryStateEnum.SEARCH && !inquiryEvent) ||
      currentInquiry?.state === InquiryStateEnum.SEARCH
  );
  const [bookmarkedFilter, setBookmarkedFilter] = useState<string | undefined>(params.bookmarked || undefined);
  const [selectedRows, setSelectedRows] = useState<{keys: React.Key[]; rows: IPropertyFields[]} | null>({
    keys: [],
    rows: [],
  });
  const [prevEventProperties, setPrevEventProperties] = useState<React.Key[]>([]);
  const [isRowKeysDisabled, setIsRowKeysDisabled] = useState<boolean>(false);
  const [inquiryState, setInquiryState] = useState<string>(
    currentInquiry?.state !== InquiryStateEnum.SEARCH && currentInquiry?.state !== InquiryStateEnum.NEW
      ? currentInquiry?.state!
      : InquiryStateEnum.SEARCH
  );
  const isEventButtonDisabled = useMemo(
    () => (inquiryState === InquiryStateEnum.END && selectedRows?.keys?.length! > 1 ? true : false),
    [selectedRows?.keys, inquiryState]
  );

  useEffect(() => {
    if (isEventButtonDisabled) {
      showWarning({message: 'Պրոցեսն ավարտելու համար կարող եք ընտրել միայն մեկ գույք։'});
    }
  }, [isEventButtonDisabled, showWarning]);

  const pathname = useMemo(() => (inquiry ? `${RoutesEnum.INQUIRIES}/${inquiry}` : RoutesEnum.PROPERTIES), [inquiry]);
  const inquriryPreset = useMemo(
    () =>
      inquiry
        ? {
            inquiry,
            bookmarked: bookmarkedFilter,
          }
        : null,
    [inquiry, bookmarkedFilter]
  );
  const contractOptions = useMemo(
    () =>
      propertyType === PropertyTypesEnum.HOUSE || propertyType === PropertyTypesEnum.COMMERCIAL
        ? contractModeOptions.filter(c => c.value !== ContractTypesEnum.DAILY)
        : propertyType === PropertyTypesEnum.LAND
        ? contractModeOptions.filter(c => c.value === ContractTypesEnum.SALE)
        : contractModeOptions,
    [propertyType]
  );
  const presets = useMemo(
    () => ({
      type: propertyType === PropertyTypesEnum.APARTMENT ? PropertyTypesEnum.APARTMENT : propertyType,
      bookmarked: bookmarkedFilter,
      'status[]':
        statusType === StatusEnum.DISABLED
          ? {disabled: 'anytime'}
          : statusType === StatusEnum.ALL
          ? [StatusEnum.FINALIZED, StatusEnum.CURRENT]
          : statusType,
      'mode[]': injectedContractMode(contractType).mode,
      'rent[]': injectedContractMode(contractType).rent,
    }),
    [statusType, propertyType, contractType, bookmarkedFilter]
  );
  const leadsPresets = useMemo(
    () =>
      profile?.type === RolesEnum.SUPER
        ? {
            'status[]': StatusEnum.FINALIZED,
            action: PropertyActionsTypesEnum.OFFER,
          }
        : null,
    [profile]
  );
  const filterInitialValuesFromParams = useMemo(() => {
    const {
      modified,
      available,
      stone,
      buildingResidentialHeating,
      buildingResidentialSubtype,
      heating,
      entryPosition,
      entryType,
      view,
      markers,
      yardMarkers,
      options,
      usage,
      'region[]': region,
      'district[]': district,
      'settlement[]': settlement,
      'street[]': street,
      settlementtNames,
      districtNames,
      streetNames,
      repair,
      'status[]': status,
      'mode[]': mode,
      'rent[]': rent,
      type,
      bookmarked,
      ...rest
    } = omittedParams(params);

    const valuesFromParams = {
      ...rest,
      [PropertyFilterFieldsEnum.MODIFIED]:
        modified && modified[0] && modified[1] ? [dayjs(new Date(modified[0])), dayjs(new Date(modified[1]))] : null,
      [PropertyFilterFieldsEnum.AVAILABLE]:
        available && available[0] && available[1]
          ? [dayjs(new Date(available[0])), dayjs(new Date(available[1]))]
          : null,
      [PropertyFilterFieldsEnum.REGION]: checkForArrayValidity(region)?.map((v: number) => ({value: v})),
      [PropertyFilterFieldsEnum.SETTLEMENT]: checkForArrayValidity(settlement)?.map((v: number) => ({value: v})),
      [PropertyFilterFieldsEnum.DISTRICT]: checkForArrayValidity(district)?.map((v: number) => ({value: v})),
      [PropertyFilterFieldsEnum.STREET]: checkForArrayValidity(street)?.map((v: number) => ({value: v})),
      [PropertyFilterFieldsEnum.STONE]: checkForArrayValidity(stone),
      [PropertyFilterFieldsEnum.BUILDING_RESIDENTIAL_HEATING]: checkForArrayValidity(buildingResidentialHeating),
      [PropertyFilterFieldsEnum.BUILDING_RESIDENTIAL_SUBTYPE]: checkForArrayValidity(buildingResidentialSubtype),
      [PropertyFilterFieldsEnum.HEATING]: checkForArrayValidity(heating),
      [PropertyFilterFieldsEnum.ENTRY_POSITION]: checkForArrayValidity(entryPosition),
      [PropertyFilterFieldsEnum.ENTRY_TYPE]: checkForArrayValidity(entryType),
      [PropertyFilterFieldsEnum.VIEW]: checkForArrayValidity(view),
      [PropertyFilterFieldsEnum.MARKERS]: checkForArrayValidity(markers),
      [PropertyFilterFieldsEnum.YARD_MARKERS]: checkForArrayValidity(yardMarkers),
      [PropertyFilterFieldsEnum.OPTIONS]: checkForArrayValidity(options),
      [PropertyFilterFieldsEnum.USAGE]: checkForArrayValidity(usage),
      [PropertyFilterFieldsEnum.REPAIR]: checkForArrayValidity(repair),
    };

    return clearFromEmptyKeys<IPropertyFilterFields>(valuesFromParams);
  }, [params]);

  const inquiryFilterTranslation = useMemo(
    () =>
      translateFilter<IPropertyFilterFields>({
        params: selectedHistoryEvent?.search ? JSON.parse(selectedHistoryEvent?.search!) : {},
        FiledsEnum: PropertyFilterFieldsEnum,
        catalog,
      }),
    [selectedHistoryEvent, catalog]
  );

  const onPersonView = useCallback(
    (address: string, attachedPersons: TPerson[]) => {
      show(PopupPropertyPersons, {
        address,
        attachedPersons,
      });
    },
    [show]
  );

  const onPropcessChange = useCallback(
    (id: number) => {
      show(PopupChangeProcess, {
        id,
      });
    },
    [show]
  );

  const onFilterSubmit = useCallback(
    (values: IPropertyFilterFields) => {
      setIsButtonLoading(true);

      const {
        phone,
        'region[]': region,
        'settlement[]': settlement,
        'district[]': district,
        'street[]': street,
      } = values;

      const queryParams = {
        ...params,
        ...{
          ...values,
          phone: formatPhoneNumber(phone!),
          ...injectLocationFilterValues({propName: LocationPropsEnum.REGION, value: region!, withNames: true}),
          ...injectLocationFilterValues({propName: LocationPropsEnum.SETTLEMENT, value: settlement!, withNames: true}),
          ...injectLocationFilterValues({propName: LocationPropsEnum.DISTRICT, value: district!, withNames: true}),
          ...injectLocationFilterValues({propName: LocationPropsEnum.STREET, value: street!, withNames: true}),
        },
        ...paginationEmptyOptions,
      };

      handlePresetsSave({
        route: pathname,
        params: queryParams,
        action: savePropertyFilterPresetsAction,
      });

      setPaginationOptions(paginationDefaultOptions);

      dispatch(
        searchPropertiesAction(
          {...inquriryPreset, ...presets, ...injectPropertyFilterValues({values})},
          {onFulfilled: () => setIsButtonLoading(false), onReject: () => setIsButtonLoading(false)}
        )
      );
    },
    [presets, params, pathname, inquriryPreset, dispatch, handlePresetsSave]
  );

  const onLeadsFilterSubmit = useCallback(
    (values: ILeadsFilterFields) => {
      setIsButtonLoading(true);
      setIsFormSubmited(true);

      const valuesToSend = {
        ...values,
        mode: presets['mode[]'],
        rent: presets['rent[]'],
        type: presets.type,
      };

      setPaginationOptions(paginationDefaultOptions);

      dispatch(
        searchLeadsAction(
          {...valuesToSend, ...leadsPresets},
          {onFulfilled: () => setIsButtonLoading(false), onReject: () => setIsButtonLoading(false)}
        )
      );
    },
    [presets, leadsPresets, dispatch]
  );

  const onFilterClear = useCallback(() => {
    if (isLeadsLoaded) {
      const presetToSend = {type: presets.type, mode: presets['mode[]']};

      dispatch(searchLeadsAction({...presetToSend, ...leadsPresets}));

      leadsFilterForm.resetFields();
      leadsFilterForm.setFieldsValue({});
    } else {
      handlePresetsSave({
        route: pathname,
        params: {
          type: params.type,
          'status[]': params['status[]'],
          'mode[]': params['mode[]'],
          'rent[]': params['rent[]'],
        },
        action: savePropertyFilterPresetsAction,
      });

      setIsFetchedFromParams(false);

      dispatch(searchPropertiesAction({...inquriryPreset, ...presets}));

      filterForm.resetFields();
    }

    setPaginationOptions(paginationDefaultOptions);
    setIsFormSubmited(false);
  }, [
    filterForm,
    leadsFilterForm,
    isLeadsLoaded,
    presets,
    leadsPresets,
    params,
    pathname,
    inquriryPreset,
    handlePresetsSave,
    dispatch,
  ]);

  const handleStatusTypeChange = useCallback(
    (e: RadioChangeEvent) => {
      setLeadsLoaded(false);
      setStatusType(e.target.value);

      handlePresetsSave({
        route: pathname,
        params: {...params, 'status[]': e.target.value, ...paginationEmptyOptions},
        action: savePropertyFilterPresetsAction,
      });

      leadsFilterForm.resetFields();
      setIsFetchedFromParams(false);
      setPaginationOptions(paginationDefaultOptions);
    },
    [leadsFilterForm, params, pathname, handlePresetsSave]
  );

  const handlePropertyTypeChange = useCallback(
    (e: RadioChangeEvent) => {
      const isLand = e.target.value === PropertyTypesEnum.LAND;
      const isHouseOrCommercial =
        e.target.value === PropertyTypesEnum.HOUSE || e.target.value === PropertyTypesEnum.COMMERCIAL;

      setContractType(prev =>
        isLand
          ? prev?.filter(c => c === ContractTypesEnum.SALE)!
          : isHouseOrCommercial
          ? prev?.filter(c => c !== ContractTypesEnum.DAILY)!
          : prev
      );

      const contractTypeToSet = {
        'mode[]':
          isLand && (params['mode[]'] === ContractTypesEnum.ALL || params['mode[]'] === ContractTypesEnum.SALE)
            ? ContractTypesEnum.SALE
            : isLand && params['mode[]'] === ContractTypesEnum.RENT
            ? null
            : isHouseOrCommercial &&
              params['mode[]'] === ContractTypesEnum.RENT &&
              params['rent[]'] === ContractTypesEnum.DAILY
            ? null
            : isHouseOrCommercial &&
              params['mode[]'] === ContractTypesEnum.ALL &&
              params['rent[]'] === ContractTypesEnum.DAILY
            ? ContractTypesEnum.SALE
            : params['mode[]'],
        'rent[]': isLand
          ? null
          : isHouseOrCommercial && params['rent[]'] === ContractTypesEnum.ALL
          ? ContractTypesEnum.MONTHLY
          : isHouseOrCommercial && params['rent[]'] === ContractTypesEnum.DAILY
          ? null
          : params['rent[]'],
      };

      handlePresetsSave({
        route: pathname,
        params: {
          ...params,
          type: e.target.value,
          ...contractTypeToSet,
          ...paginationEmptyOptions,
        },
        action: savePropertyFilterPresetsAction,
      });

      setPropertyType(e.target.value);
      setIsFetchedFromParams(false);
      setPaginationOptions(paginationDefaultOptions);
    },
    [params, pathname, handlePresetsSave]
  );

  const handleContractTypeChange = useCallback(
    (val: Array<ContractTypesEnum>) => {
      setContractType(val);

      const contractTypeToSet = {
        'mode[]':
          val?.includes(ContractTypesEnum.SALE) &&
          !val?.includes(ContractTypesEnum.MONTHLY) &&
          !val?.includes(ContractTypesEnum.DAILY)
            ? [CustomerTypesEnum.SALE, CustomerTypesEnum.ALL]
            : !val?.includes(ContractTypesEnum.SALE) &&
              (val?.includes(ContractTypesEnum.MONTHLY) || val?.includes(ContractTypesEnum.DAILY))
            ? ContractTypesEnum.RENT
            : val?.includes(ContractTypesEnum.SALE) &&
              (val?.includes(ContractTypesEnum.MONTHLY) || val?.includes(ContractTypesEnum.DAILY))
            ? CustomerTypesEnum.ALL
            : null,
        'rent[]':
          val?.includes(ContractTypesEnum.MONTHLY) && val?.includes(ContractTypesEnum.DAILY)
            ? ContractTypesEnum.ALL
            : val?.includes(ContractTypesEnum.MONTHLY)
            ? ContractTypesEnum.MONTHLY
            : val?.includes(ContractTypesEnum.DAILY)
            ? ContractTypesEnum.DAILY
            : null,
      };

      handlePresetsSave({
        route: pathname,
        params: {...params, ...contractTypeToSet, ...paginationEmptyOptions},
        action: savePropertyFilterPresetsAction,
      });

      setPaginationOptions(paginationDefaultOptions);
      setIsFetchedFromParams(false);
    },
    [params, pathname, handlePresetsSave]
  );

  const handleBookmarkedFilter = useCallback(
    (e: RadioChangeEvent) => {
      setBookmarkedFilter(e.target.value);

      handlePresetsSave({
        route: pathname,
        params: {...params, bookmarked: e.target.value, ...paginationEmptyOptions},
      });

      setPaginationOptions(paginationDefaultOptions);
      setIsFetchedFromParams(false);
    },
    [params, pathname, handlePresetsSave]
  );

  const handleLeads = useCallback(() => {
    setLeadsLoaded(true);
    setStatusType('');

    handlePresetsSave({
      route: pathname,
      params: {},
    });

    filterForm.resetFields();
  }, [filterForm, pathname, handlePresetsSave]);

  const beforeLightboxOpen = useCallback(
    (entityId: number, id?: number) => {
      dispatch(clearMediaAction());

      setEntityId(entityId);
      setIsLighboxLoading(true);
    },
    [dispatch]
  );

  const handleLightboxClose = useCallback(() => {
    setEntityId(0);
    setTimeout(() => setIsLighboxLoading(false), 600);

    if (!isLighboxLoading) {
      setIsLighboxVisible(false);
      dispatch(clearCurrentPropertyAction());
    }
  }, [isLighboxLoading, dispatch]);

  const onBookmark = useCallback(
    ({mode, propertyId}: {mode: 'add' | 'remove'; propertyId: number}) => {
      dispatch(
        mode === 'add'
          ? addInquiryBookmarkAction({inquiry, property: propertyId})
          : removeInquiryBookmarkAction({inquiry, property: propertyId})
      );
    },
    [inquiry, dispatch]
  );

  const handleRowSelect = useCallback((newSelectedRowKeys: React.Key[], selectedRows: IPropertyFields[]) => {
    setSelectedRows({keys: newSelectedRowKeys, rows: selectedRows});
  }, []);

  const handleInqiryStateChange = useCallback(
    (value: string, option: any) => {
      const prevStep = inquiryStateOptions.find(inq => inq.value === currentInquiry?.state)?.step;
      const nextStep = inquiryStateOptions.find(inq => inq.value === value)?.step;

      // if (value === InquiryStateEnum.SEARCH) {
      //   setIsInquiryInSearchMode(true);
      // } else {
      //   setIsInquiryInSearchMode(false);
      // }

      if (nextStep! < prevStep!) {
        const prevStepProperties = currentInquiry?.events.find(ev => ev.type === value)?.properties.map(p => p.id);

        setSelectedRows({keys: [], rows: []});
        setPrevEventProperties(prevStepProperties!);
        setIsRowKeysDisabled(true);
      } else {
        setIsRowKeysDisabled(false);
      }

      setInquiryState(value);
    },
    [currentInquiry]
  );

  const handleInqirySearchMode = useCallback(() => {
    setIsInquiryInSearchMode(true);
  }, []);

  const handleInqiryProcessBack = useCallback(() => {
    setIsInquiryInSearchMode(false);
    setInquiryState(currentInquiry?.state!);
  }, [currentInquiry]);

  const handleInquiryEvent = useCallback(
    (onFinish?: any) => {
      setIsInquiryEventButtonLoading(true);

      const search =
        currentInquiry?.state === InquiryStateEnum.SEARCH
          ? {
              search: JSON.stringify(
                clearFromEmptyKeys({
                  ...filterForm.getFieldsValue(),
                  type: propertyType,
                  mode: injectedContractMode(contractType).mode,
                  rent: injectedContractMode(contractType).rent,
                })
              ),
            }
          : {};

      const searchUpdateData = {
        inquiry,
        event: currentInquiry?.events[0]?.id,
        ...search,
      };

      const data = {
        inquiry,
        type: inquiryState,
        date: formatDate({value: new Date(), format: FormatsEnum.REVERSE, separator: SeparatorEnum.HYPHEN}),
        properties: selectedRows?.keys?.length ? selectedRows.keys : prevEventProperties,
      };

      if (currentInquiry?.state === InquiryStateEnum.SEARCH) {
        dispatch(updateInquiryEventSearchAction(searchUpdateData));
      }

      dispatch(
        addInquiryEventAction(data, {
          onFulfilled: () => {
            setIsInquiryEventButtonLoading(false);
            setSelectedRows({keys: [], rows: []});
            setPrevEventProperties([]);
            handlePresetsSave({
              route: pathname,
              params: {...paginationEmptyOptions},
              action: savePropertyFilterPresetsAction,
            });
            setPaginationOptions(paginationDefaultOptions);
            onFinish && onFinish();
          },
        })
      );
    },
    [
      inquiry,
      inquiryState,
      selectedRows,
      prevEventProperties,
      pathname,
      filterForm,
      propertyType,
      contractType,
      currentInquiry,
      handlePresetsSave,
      dispatch,
    ]
  );

  const onInquiryEventSubmit = useCallback(() => {
    const propertyId = Number(selectedRows?.keys[0]);
    const property = selectedRows?.rows[0];

    if (inquiryState === InquiryStateEnum.END) {
      if (property?.rent) {
        const entity = {
          [BookingFieldsEnum.DATE]: filterInitialValuesFromParams.available,
        };

        show(PopupBooking, {
          id: propertyId,
          property,
          entity,
          mode: 'end',
          inquiry,
          onSubmit: handleInquiryEvent,
        });
      } else {
        show(PopupConfirm, {
          title: 'Ավարտել հարցումը',
          content: (
            <PAlert
              type="warning"
              message="Հարցումն ավարտելու դեպքում տվյալ գույքը կարխիվացվի։"
              showIcon
              className="mb-5"
            />
          ),
          onSubmit: onFinish => {
            dispatch(
              updatePropertyAction(
                {
                  ...property,
                  property: property?.id,
                  building: property?.building.id,
                  status: StatusEnum.ARCHIVED,
                  archive_info: 'Հարցումն ավարտվել է։',
                },
                {
                  filterPresets: {},
                  onFulfilled: () => {
                    handleInquiryEvent(onFinish);
                  },
                }
              )
            );
          },
        });
      }
    } else {
      handleInquiryEvent();
    }
  }, [inquiryState, selectedRows, inquiry, filterInitialValuesFromParams, handleInquiryEvent, show, dispatch]);

  const handleTableChange = useCallback(
    (pagination: TPaginationOptions, sorter: TSortOptions) => {
      setPaginationOptions(pagination);
      setSortOptions(sorter);

      handlePresetsSave({
        route: pathname,
        params: {...params, ...pagination, ...sorter},
        action: savePropertyFilterPresetsAction,
      });
    },
    [params, pathname, handlePresetsSave]
  );

  useEffect(() => {
    if (entityId) {
      setIsLighboxVisible(true);
    }
  }, [entityId, dispatch]);

  useEffect(() => {
    const status =
      statusType === StatusEnum.DISABLED
        ? {disabled: 'anytime'}
        : {
            'status[]': statusType === StatusEnum.ALL ? [StatusEnum.FINALIZED, StatusEnum.CURRENT] : statusType,
          };
    const type = {
      type: propertyType === PropertyTypesEnum.APARTMENT ? PropertyTypesEnum.APARTMENT : propertyType,
    };
    const mode = {
      [isLeadsLoaded ? 'mode' : 'mode[]']: presets['mode[]'],
      [isLeadsLoaded ? 'rent' : 'rent[]']: presets['rent[]'],
    };
    const pagination = {
      offset: paginationOptions.offset,
      length: paginationOptions.length,
    };

    const filterPresets = isLeadsLoaded
      ? {...leadsFilterForm.getFieldsValue()}
      : {
          ...injectPropertyFilterValues({values: filterForm.getFieldsValue()}),
        };
    const withoutLoading =
      archivedProperty ||
      holdedProperty ||
      updatedComment ||
      disabled ||
      enabled ||
      updatedPrice ||
      refreshedPrice ||
      bookmarkAddedInquiry ||
      bookmarkRemovedInquiry;

    const allParams = selectedHistoryEvent?.id
      ? {
          ...inquriryPreset,
          event: selectedHistoryEvent?.id,
        }
      : inquiry && currentInquiry?.events.length && !isInquiryInSearchMode
      ? {...inquriryPreset, event: inquiry && !isInquiryInSearchMode ? currentInquiry?.events[0]?.id : null}
      : {...type, ...status, ...mode, ...filterPresets, ...inquriryPreset};

    if (isLeadsLoaded) {
      dispatch(clearPropertiesAction());
      dispatch(searchLeadsAction({...type, ...mode, ...filterPresets, ...leadsPresets}));
    } else {
      dispatch(clearLeadsAction());
      dispatch(
        searchPropertiesAction({...pagination, ...sortOptions, ...allParams}, {isWithoutLoading: withoutLoading})
      );
    }

    // TODO: bugfix: paginationOptions triggers rerender on filter submit
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    statusType,
    propertyType,
    contractType,

    filterForm,
    leadsFilterForm,
    presets,
    leadsPresets,
    // paginationOptions,
    sortOptions,

    currentInquiry,
    inquriryPreset,

    isLeadsLoaded,
    isInquiryInSearchMode,
    isFetchedFromParams,
    selectedHistoryEvent,

    archivedProperty,
    holdedProperty,
    disabled,
    enabled,
    updatedComment,
    updatedPrice,
    refreshedPrice,
    bookmarkAddedInquiry,
    bookmarkRemovedInquiry,
    inquiry,

    dispatch,
  ]);

  useEffect(() => {
    dispatch(clearCurrentPropertyAction());
    dispatch(clearCurrentLeadAction());
    dispatch(clearMediaAction());
    dispatch(clearCurrentBuildingAction());
  }, [dispatch]);

  return (
    <PLoader spinning={isLoading || isLeadsLoading} asLayer>
      {!inquiry && (
        <Radio.Group
          onChange={handleStatusTypeChange}
          defaultValue={statusType}
          value={statusType}
          optionType="button"
          buttonStyle="outline"
          size="large"
          className="w-full"
        >
          <Row className="mb-7" gutter={32}>
            {profile?.type !== RolesEnum.MARKETING ? (
              <Col>
                <PButton
                  type={isLeadsLoaded ? 'default' : 'text'}
                  theme={isLeadsLoaded ? '' : 'white'}
                  onClick={isLeadsLoaded ? () => {} : handleLeads}
                >
                  {StatusTranslationEnum.NEW}
                </PButton>
              </Col>
            ) : null}

            <Col className="flex-grow">
              <div className="bg-white inline-flex rounded-[16px]">
                <PRadio value={StatusEnum.ALL} label={t('common.all')}></PRadio>
                <PRadio value={StatusEnum.FINALIZED} label={t('catalog.statuses.final')}></PRadio>
                <PRadio value={StatusEnum.CURRENT} label={t('catalog.statuses.current')}></PRadio>
                <PRadio
                  value={StatusEnum.DISABLED}
                  label={
                    <CCountBadge
                      type="danger"
                      title={t('catalog.statuses.onhold')}
                      tooltip={t('info.onhold')}
                      service={searchPropertiesService}
                      payload={{disabled: 'expired', type: presets.type, 'status[]': presets['status[]']}}
                      propertyType={propertyType!}
                      statusType={statusType}
                    />
                  }
                ></PRadio>
              </div>
            </Col>
            <Col className="justify-self-end">
              <div className="flex items-center gap-4">
                <PRadio value={StatusEnum.ARCHIVED} label={t('catalog.statuses.archived')}></PRadio>
                {profile?.type !== RolesEnum.MARKETING ? (
                  <Link to={`${RoutesEnum.PROPERTIES}/${ActionModesEnum.CREATE}`}>
                    <PButton type="primary" icon={<Icon.AddCircle fill="white" />}>
                      {t('actions.add')}
                    </PButton>
                  </Link>
                ) : null}
              </div>
            </Col>
          </Row>
        </Radio.Group>
      )}
      <PCardWrapper
        padding="md"
        className={`${
          (inquiry && !isInquiryInSearchMode && currentInquiry?.state !== InquiryStateEnum.NEW) ||
          isInquiryInHistoryMode
            ? 'hidden'
            : ''
        }`}
      >
        <Row justify="space-between">
          {!inquiry && (
            <Col>
              <PPageTitle text={t('pages.properties.title')} />
            </Col>
          )}
        </Row>
        <Row gutter={32} className="items-end">
          <Col>
            <PFormLabel isStatic text={t('form.propertyType')} />
            <PRadio
              isWrapper
              onChange={handlePropertyTypeChange}
              defaultValue={propertyType}
              disabled={inquiry ? true : undefined}
              options={[
                {label: t('catalog.propertyTypes.apartment'), value: PropertyTypesEnum.APARTMENT},
                {label: t('catalog.propertyTypes.house'), value: PropertyTypesEnum.HOUSE},
                {label: t('catalog.propertyTypes.land'), value: PropertyTypesEnum.LAND},
                {label: t('catalog.propertyTypes.commercial'), value: PropertyTypesEnum.COMMERCIAL},
              ]}
            ></PRadio>
          </Col>
          <Col span={7}>
            <PFormLabel isStatic text={t('form.contractType')} />
            <PSelect
              mode="multiple"
              defaultValue={contractType}
              value={contractType}
              options={contractOptions}
              disabled={inquiry ? true : undefined}
              onChange={handleContractTypeChange}
            />
          </Col>
          <Col className="grow text-right">
            {inquiry && (
              <Radio.Group
                onChange={handleBookmarkedFilter}
                defaultValue={bookmarkedFilter}
                value={bookmarkedFilter}
                optionType="button"
                buttonStyle="outline"
                size="large"
                className="w-full"
              >
                <PRadio value={undefined} label={t('common.all')}></PRadio>
                <PRadio value={1} label={t('catalog.statuses.saved')}></PRadio>
              </Radio.Group>
            )}
          </Col>
        </Row>
        <Divider />
        {isLeadsLoaded ? (
          <FormPropertyLeadsFilter
            contractType={contractType!}
            propertyType={propertyType!}
            isButtonLoading={isButtonLoading}
            isFormSubmited={isFormSubmited}
            form={leadsFilterForm}
            values={leadsFilterValues}
            onSubmit={onLeadsFilterSubmit}
            onReset={onFilterClear}
          />
        ) : (
          <FormPropertyFilter
            contractType={contractType!}
            propertyType={propertyType!}
            isButtonLoading={isButtonLoading}
            form={filterForm}
            values={filterValues}
            initialValues={filterInitialValuesFromParams}
            onSubmit={onFilterSubmit}
            onReset={onFilterClear}
          />
        )}
      </PCardWrapper>

      {inquiry && !isInquiryInHistoryMode && (
        <Row className="justify-between mb-5" gutter={15}>
          <Col span={3}>
            {currentInquiry?.state !== InquiryStateEnum.SEARCH && (
              <PButton htmlType="button" type="primary" onClick={handleInqirySearchMode}>
                {t('actions.propertySearch')}
              </PButton>
            )}
          </Col>
          <Col className="grow">
            <Row gutter={10} className="justify-end">
              {isInquiryInSearchMode && currentInquiry?.state !== InquiryStateEnum.SEARCH ? (
                <Col>
                  <PButton type="text" theme="white" onClick={handleInqiryProcessBack}>
                    {t('actions.backToBusinessProcess')}
                  </PButton>
                </Col>
              ) : (
                <>
                  <Col span={4}>
                    <div className="relative">
                      <PSelect
                        theme="white"
                        placeholder={t('form.noteProcess')}
                        defaultValue={inquiryState}
                        value={inquiryState}
                        options={inquiryStateOptions}
                        onChange={handleInqiryStateChange}
                        allowClear={false}
                      />
                      <div className="absolute top-[-8px] right-[-8px]">
                        <Badge count={selectedRows?.keys?.length || ''} color="blue"></Badge>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <Row gutter={10}>
                      <Col>
                        <PLoader spinning={isInquiryEventButtonLoading}>
                          <PButton
                            type="primary"
                            disabled={
                              isEventButtonDisabled ||
                              (!isRowKeysDisabled &&
                                (!selectedRows?.keys?.length ||
                                  !inquiryState ||
                                  inquiryState === currentInquiry?.state))
                            }
                            onClick={onInquiryEventSubmit}
                          >
                            {inquiryState === InquiryStateEnum.END ? t('actions.end') : t('actions.change')}
                          </PButton>
                        </PLoader>
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      )}

      <PCardWrapper padding="md">
        {isInquiryInHistoryMode ? (
          <PFormLabel
            isStatic
            text={getTranslation(
              InquiryStateTranslationEnum,
              selectedHistoryEvent?.type! || currentInquiry?.events[0]?.type!
            )}
          />
        ) : null}
        {inquiry && selectedHistoryEvent?.type === InquiryStateEnum.SEARCH ? (
          <div>
            <Divider />
            <Row justify="space-between" gutter={30}>
              <Col span={24} className="flex flex-wrap gap-y-3">
                {Object.entries(inquiryFilterTranslation).map((item, i) => (
                  <Tag
                    key={i}
                    color={item[0] === t('form.propertyType') || item[0] === t('form.mode') ? 'blue' : 'default'}
                    className="text-[14px] leading-[26px]"
                  >
                    <strong>{item[0]}:</strong> {item[1]}
                  </Tag>
                ))}
              </Col>
            </Row>
          </div>
        ) : (
          <PTable
            showLeged={!isInquiryInHistoryMode}
            rowSelection={
              inquiry
                ? {
                    selectedRowKeys: selectedRows?.keys,
                    preserveSelectedRowKeys: true,
                    onChange: handleRowSelect,
                    getCheckboxProps: record => ({
                      disabled: isRowKeysDisabled || isInquiryInHistoryMode,
                    }),
                  }
                : undefined
            }
            columns={
              isLeadsLoaded
                ? leadsColumns({propertyType, translation: t})
                : columns({
                    beforeLightboxOpen,
                    onPersonView,
                    onPropcessChange,
                    onBookmark,
                    isLighboxLoading,
                    entityId,
                    propertyType,
                    contractType,
                    catalog,
                    isLoading,
                    inquiry,
                    currentInquiry,
                    isInquiryInSearchMode,
                    sortOptions,
                    profile,
                    translation: t,
                  })
            }
            paginationOptions={{
              total: !isLeadsLoaded ? propertiesCount : undefined,
              defaultPageSize: paginationOptions.length,
              pageSize: paginationOptions.length,
              defaultCurrent: paginationOptions.page,
              current: paginationOptions.page,
            }}
            dataSource={isLeadsLoaded ? leads : properties}
            rowKey="id"
            handleChange={handleTableChange}
          />
        )}
      </PCardWrapper>
      {!isLeadsLoaded && !inquiry && <PStatus types={statuses} />}
      <CPropertyGallery entityId={entityId} isLighboxVisible={isLighboxVisible} onLightboxClose={handleLightboxClose} />
      {contextHolder}
    </PLoader>
  );
};

export const ScreenPropertiesMemoized = memo(ScreenProperties);
