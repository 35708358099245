import {memo, useCallback, useState} from 'react';
import {Input} from 'antd';

import {PFormLabel} from 'components/primitives/p-form-label';
import {numbersAndSymbols} from 'constants/regular-expressions';

import {PPhoneInputProps} from './p-phone-input.types';

const PPhoneInput = ({
  label,
  id,
  className,
  innerClassName,
  withoutFormatter,
  onChange,
  ...props
}: PPhoneInputProps) => {
  const [maxLength, setMaxLength] = useState<number>(14);

  const onKeyPress = useCallback((e: any) => {
    const regex = new RegExp(numbersAndSymbols);
    const key = e.charCode ? e.which : e.charCode;
    const char = String.fromCharCode(key);

    if (!regex.test(char) && key !== 13) {
      e.preventDefault();

      return false;
    }
  }, []);

  const handleChange = useCallback(
    (e: any) => {
      onChange && onChange(e);
      setMaxLength(e.target.value.startsWith('0') ? 9 : 14);
    },
    [onChange]
  );

  return (
    <div className={`${className} relative`}>
      {label && <PFormLabel text={label} id={id} />}

      <Input
        {...props}
        className={innerClassName}
        id={id}
        size="large"
        style={{width: '100%'}}
        minLength={9}
        maxLength={maxLength}
        onKeyPress={onKeyPress}
        onChange={handleChange}
      />
    </div>
  );
};

export const PPhoneInputMemoized = memo(PPhoneInput);
