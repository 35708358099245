export enum LeadFilterFieldsEnum {
  TYPE = 'type',
  MIN_CREATED = 'minCreated',
  MAX_CREATED = 'maxCreated',

  MIN_TOTAL_PRICE_SALE = 'minTotalPriceSale',
  MAX_TOTAL_PRICE_SALE = 'maxTotalPriceSale',
  MIN_UNIT_PRICE_SALE = 'minUnitPriceSale',
  MAX_UNIT_PRICE_SALE = 'maxUnitPriceSale',

  MIN_TOTAL_PRICE_MONTHLY = 'minTotalPriceMonthly',
  MAX_TOTAL_PRICE_MONTHLY = 'maxTotalPriceMonthly',
  MIN_UNIT_PRICE_MONTHLY = 'minUnitPriceMonthly',
  MAX_UNIT_PRICE_MONTHLY = 'maxUnitPriceMonthly',

  MIN_TOTAL_PRICE_DAILY = 'minTotalPriceDaily',
  MAX_TOTAL_PRICE_DAILY = 'maxTotalPriceDaily',
  MIN_UNIT_PRICE_DAILY = 'minUnitPriceDaily',
  MAX_UNIT_PRICE_DAILY = 'maxUnitPriceDaily',

  CURRENCY_SALE = 'currency_sale',
  CURRENCY_MONTHLY = 'currency_monthly',
  CURRENCY_DAILY = 'currency_daily',

  MIN_AREA = 'minArea',
  MAX_AREA = 'maxArea',

  MIN_LIVING_AREA = 'minLivingArea',
  MAX_LIVING_AREA = 'maxLivingArea',

  MIN_LAND_AREA = 'minLandArea',
  MAX_LAND_AREA = 'maxLandArea',

  MIN_HECTARE = 'minHectare',
  MAX_HECTARE = 'maxHectare',

  MIN_SQUARE_METRE = 'minSquareMetre',
  MAX_SQUARE_METRE = 'maxSquareMetre',

  MIN_ROOMS = 'minRooms',
  MAX_ROOMS = 'maxRooms',

  MIN_FLOOR = 'minFloor',
  MAX_FLOOR = 'maxFloor',

  MIN_FLOORS = 'minFloors',
  MAX_FLOORS = 'maxFloors',

  MIN_ENTRANCE_FLOOR = 'minEntranceFloor',
  MAX_ENTRANCE_FLOOR = 'maxEntranceFloor',
}
