import {memo} from 'react';
import {useSelector} from 'react-redux';
import {Col, Row} from 'antd';
import {useTranslation} from 'react-i18next';

import {CPerformanceTable} from 'components/containers/c-performance-table';
import {getInquiryPerformanceAction, getPropertyPerformanceAction} from 'store/analytics/actions';
import {analyticsInquiryPerformanceSelector, analyticsPropertyPerformanceSelector} from 'store/analytics/selectors';
import {PerformanceTypeEnum} from 'enums';

export const BrokersAnalyticsView = () => {
  const {t} = useTranslation();
  const propertyPerformance = useSelector(analyticsPropertyPerformanceSelector);
  const inquiryPerformance = useSelector(analyticsInquiryPerformanceSelector);

  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <CPerformanceTable
          headline={t('common.properties')}
          type={PerformanceTypeEnum.PROPERTY}
          action={getPropertyPerformanceAction}
          dataSource={propertyPerformance!}
        />
      </Col>
      <Col span={24}>
        <CPerformanceTable
          headline={t('common.transactions')}
          type={PerformanceTypeEnum.INQUIRY}
          action={getInquiryPerformanceAction}
          dataSource={inquiryPerformance!}
        />
      </Col>
    </Row>
  );
};

export const BrokersAnalyticsViewMemoized = memo(BrokersAnalyticsView);
