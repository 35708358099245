import {memo, useCallback, useState} from 'react';
import {Popconfirm, Skeleton} from 'antd';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {enablePropertyAction, getPropertyByIdAction} from 'store/properties/actions';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {useToast} from 'hooks';

import {ICEnablePropertyProps} from './c-enable-property.types';

const CEnableProperty = ({children, propertyId, ...props}: ICEnablePropertyProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {showError, contextHolder} = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onCommentsUpdate = useCallback(() => {
    setIsLoading(true);

    dispatch(
      enablePropertyAction(
        {
          property: propertyId,
        },
        {
          onFulfilled: () => {
            dispatch(getPropertyByIdAction({id: propertyId}, {isWithoutLoading: true}));

            setIsLoading(false);
          },
          onReject: () => {
            setIsLoading(false);
            showError({message: fieldsMessageTranslations.commonErrors});
          },
        }
      )
    );
  }, [propertyId, showError, dispatch]);

  return (
    <>
      <Popconfirm
        {...props}
        icon={null}
        onConfirm={onCommentsUpdate}
        cancelText={t('actions.close')}
        okText={t('actions.activate')}
      >
        <>
          {isLoading ? (
            <Skeleton.Button shape="default" size="small" className="min-w-[25px]" block active />
          ) : (
            children
          )}
        </>
      </Popconfirm>
      {contextHolder}
    </>
  );
};

export const CEnablePropertyMemoized = memo(CEnableProperty);
