import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Form, Row} from 'antd';
import {Link, Navigate, useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Icon} from 'assets/icons';
import {PLoader} from 'components/primitives/p-loader';
import {PPageTitle} from 'components/primitives/p-page-title';
import {RoutesEnum} from 'router/routes.enum';
import {
  ActionModesEnum,
  CrossCalculationModesEnum,
  OptGroupsEnum,
  PropertyTypesEnum,
  ContractTypesEnum,
  StatusEnum,
  AddonsEnum,
  FoldersEnum,
  HandledFieldsEnum,
  RolesEnum,
  ServerErrorsEnum,
} from 'enums';
import {usePopup, useToast} from 'hooks';
import {TPerson, TPropertyAddon} from 'types/api';
import {
  appAddressSelector,
  appCatalogSelector,
  appFileSelector,
  appImageSelector,
  appProfileSelector,
} from 'store/app/selectors';
import {addAddressAction, clearAddressAction, clearMediaAction, getFolderByIdAction} from 'store/app/actions';
import {PButton} from 'components/primitives/p-button';
import {FormProperty} from 'components/forms/property';
import {IPropertyFields} from 'components/forms/property/form-property.interfaces';
import {
  addPropertyAction,
  clearCurrentPropertyAction,
  clearPropertiesErrorsAction,
  getPropertyByIdAction,
  updatePropertyAction,
} from 'store/properties/actions';
import {
  archivedPropertySelector,
  holdedPropertySelector,
  propertiesErrorSelector,
  propertiesLoadingSelector,
  propertyCurrentSelector,
  propertyDisabledSelector,
  propertyEnabledSelector,
  propertyFilterPresetsSelector,
  propertyUpdatedPriceSelector,
} from 'store/properties/selectors';
import {getLeadByIdAction} from 'store/leads/actions';
import {calculateUnitPrices, findObjectByKeys, getTranslation, injectAddressFields, isWidgetNotValidated} from 'utils';
import {PropertyFieldsEnum} from 'components/forms/property/form-property.enums';
import {leadsCurrentSelector} from 'store/leads/selectors';
import {TAddon} from 'types/common';
import {IAddressFields} from 'components/forms/address/form-address.interfaces';
import {PopupPersonAttach} from 'components/popups/person-attach';
import {ActionModeHero} from 'constants/translations';
import {clearCurrentBuildingAction} from 'store/buildings/actions';
import {EmptyPage} from 'components/layout/empty-page';
import {CPdfGenerate} from 'components/containers/c-pdf-generate';
import {ServerErrorsTranslationEnum} from 'enums/translations';
import {updateWebsiteFieldsAction} from 'store/marketing/actions';

import {personColumns} from './constants/person-columns';

export const PagePropertyDetailed = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {mode, id} = useParams();
  const isLoading = useSelector(propertiesLoadingSelector);
  const error = useSelector(propertiesErrorSelector);
  const currentProperty = useSelector(propertyCurrentSelector);
  const currentLead = useSelector(leadsCurrentSelector);
  const file = useSelector(appFileSelector);
  const image = useSelector(appImageSelector);
  const address = useSelector(appAddressSelector);
  const propertyFilterPresets = useSelector(propertyFilterPresetsSelector);
  const profile = useSelector(appProfileSelector);

  const [form] = Form.useForm<IPropertyFields>();
  const values = Form.useWatch([], form);
  const {contextHolder, showError, showSuccess, showWarning} = useToast();
  const {show} = usePopup();
  const [initialValues, setInitialValues] = useState<IPropertyFields>();
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [handledFileds, setHandledFields] = useState<Record<string, boolean | undefined>>({
    [HandledFieldsEnum.IS_ADDRESS_ATTACHED]: undefined,
    [HandledFieldsEnum.IS_ADDRESS_SELECTED]: undefined,
    [HandledFieldsEnum.IS_BUILDING_SELECTED]: undefined,
    [HandledFieldsEnum.IS_FILES_ATTACHED]: undefined,
    [HandledFieldsEnum.IS_IMAGES_ATTACHED]: undefined,
    [HandledFieldsEnum.IS_IMAGES_EMPTY]: undefined,
    [HandledFieldsEnum.IS_FILES_EMPTY]: undefined,
  });
  const [isAddressLoading, setAddressLoading] = useState<boolean>(false);
  const [addressInfo, setAddressInfo] = useState<IAddressFields>();
  const [buildingInfo, setBuildingInfo] = useState<Record<string, any>>();
  const [attachedPersons, setAttachedPersons] = useState<TPerson[]>([]);
  const [attachedRooms, setAttachedRooms] = useState<TPropertyAddon[]>([]);
  const [attachedFloors, setAttachedFloors] = useState<TPropertyAddon[]>([]);
  const archivedProperty = useSelector(archivedPropertySelector);
  const holdedProperty = useSelector(holdedPropertySelector);
  const disabled = useSelector(propertyDisabledSelector);
  const enabled = useSelector(propertyEnabledSelector);
  const updatedPrice = useSelector(propertyUpdatedPriceSelector);
  const catalog = useSelector(appCatalogSelector);

  const handleSubmit = useCallback(
    (values: IPropertyFields) => {
      if (
        !buildingInfo &&
        isWidgetNotValidated({
          fieldToCheck: !buildingInfo,
          isFieldHandled: handledFileds[HandledFieldsEnum.IS_BUILDING_SELECTED],
          querySelecor: 'building-select',
          state: HandledFieldsEnum.IS_BUILDING_SELECTED,
          setState: setHandledFields,
        })
      )
        return;

      if (
        isWidgetNotValidated({
          fieldToCheck: addressInfo && !Object.keys(addressInfo).length,
          isFieldHandled: handledFileds[HandledFieldsEnum.IS_ADDRESS_SELECTED],
          querySelecor: 'address-select-error',
          state: HandledFieldsEnum.IS_ADDRESS_SELECTED,
          setState: setHandledFields,
        })
      )
        return;

      if (
        isWidgetNotValidated({
          isFieldHandled: handledFileds[HandledFieldsEnum.IS_IMAGES_ATTACHED],
          querySelecor: 'image-upload-complete',
          state: HandledFieldsEnum.IS_IMAGES_ATTACHED,
          setState: setHandledFields,
        })
      )
        return;

      if (
        isWidgetNotValidated({
          isFieldHandled: handledFileds[HandledFieldsEnum.IS_FILES_ATTACHED],
          querySelecor: 'file-upload-complete',
          state: HandledFieldsEnum.IS_FILES_ATTACHED,
          setState: setHandledFields,
        })
      )
        return;

      if (
        isWidgetNotValidated({
          fieldToCheck: addressInfo,
          isFieldHandled: handledFileds[HandledFieldsEnum.IS_ADDRESS_ATTACHED],
          querySelecor: 'map-submit',
          state: HandledFieldsEnum.IS_ADDRESS_ATTACHED,
          setState: setHandledFields,
        })
      )
        return;

      setIsButtonLoading(true);

      const {
        view,
        entry_position,
        entry_type,
        options,
        yard_markers,
        total_price_sale,
        total_price_monthly,
        total_price_daily,
        ...rest
      } = values;

      const propertyMode =
        values.rent?.includes(ContractTypesEnum.SALE) &&
        !values.rent?.includes(ContractTypesEnum.MONTHLY) &&
        !values.rent?.includes(ContractTypesEnum.DAILY)
          ? ContractTypesEnum.SALE
          : (values.rent?.includes(ContractTypesEnum.MONTHLY) || values.rent?.includes(ContractTypesEnum.DAILY)) &&
            !values.rent?.includes(ContractTypesEnum.SALE)
          ? ContractTypesEnum.RENT
          : values.rent?.includes(ContractTypesEnum.SALE) &&
            (values.rent?.includes(ContractTypesEnum.MONTHLY) || values.rent?.includes(ContractTypesEnum.DAILY))
          ? ContractTypesEnum.ALL
          : '';

      const rent =
        values.rent?.includes(ContractTypesEnum.MONTHLY) && values.rent?.includes(ContractTypesEnum.DAILY)
          ? ContractTypesEnum.ALL
          : values.rent?.includes(ContractTypesEnum.MONTHLY) && !values.rent?.includes(ContractTypesEnum.DAILY)
          ? ContractTypesEnum.MONTHLY
          : values.rent?.includes(ContractTypesEnum.DAILY) && !values.rent?.includes(ContractTypesEnum.MONTHLY)
          ? ContractTypesEnum.DAILY
          : '';

      const commonData = {
        ...rest,
        rent,
        marketing: values.marketing ? 1 : 0,
        website:
          profile?.type === RolesEnum.SUPER || profile?.type === RolesEnum.MARKETING ? (values.website ? 1 : 0) : null,
        total_price_sale:
          propertyMode === ContractTypesEnum.SALE || propertyMode === ContractTypesEnum.ALL ? total_price_sale : '',
        total_price_monthly:
          rent === ContractTypesEnum.ALL || rent === ContractTypesEnum.MONTHLY ? total_price_monthly : '',
        total_price_daily: rent === ContractTypesEnum.ALL || rent === ContractTypesEnum.DAILY ? total_price_daily : '',
        options: [
          ...(options || []),
          ...(yard_markers || []),
          ...(view || []),
          ...(entry_position || []),
          ...(entry_type || []),
        ],
      };

      const createData = {
        ...commonData,
        address: buildingInfo ? buildingInfo?.address?.id : address,
        building: buildingInfo ? buildingInfo?.id : null,
        lead: id,
        mode: propertyMode,
        fileFolder: file,
        imageFolder: image,
        persons: [...attachedPersons],
        rooms: attachedRooms?.length ? [...attachedRooms] : [],
        floors: attachedFloors?.length ? [...attachedFloors] : [],
      };

      const updateData = {
        ...commonData,
        address: address ? address : buildingInfo ? buildingInfo?.address?.id : initialValues?.location?.address?.id,
        building: buildingInfo ? buildingInfo?.id : initialValues?.building?.id,
        fileFolder: !handledFileds[HandledFieldsEnum.IS_FILES_EMPTY]
          ? file
            ? file
            : initialValues?.file_folder?.id
          : 0,
        imageFolder: !handledFileds[HandledFieldsEnum.IS_IMAGES_EMPTY]
          ? image
            ? image
            : initialValues?.image_folder?.id
          : 0,
        persons: [...attachedPersons],
        rooms: attachedRooms?.length ? [...attachedRooms] : [],
        floors: attachedFloors?.length ? [...attachedFloors] : [],
        code: initialValues?.code,
        property: initialValues?.id,
        mode: propertyMode,
      };

      const updateWebsiteFields = () =>
        dispatch(
          updateWebsiteFieldsAction(updateData, {
            filterPresets: propertyFilterPresets,
            onFulfilled: () => {
              setIsButtonLoading(false);
            },
            onReject: () => {
              setIsButtonLoading(false);
            },
          })
        );

      mode === ActionModesEnum.CREATE
        ? dispatch(
            addPropertyAction(createData, {
              onFulfilled: () => {
                setIsButtonLoading(false);
              },
              onReject: () => {
                setIsButtonLoading(false);
              },
            })
          )
        : profile?.type === RolesEnum.MARKETING
        ? updateWebsiteFields()
        : dispatch(
            updatePropertyAction(updateData, {
              filterPresets: propertyFilterPresets,
              onFulfilled: () => {
                profile?.type === RolesEnum.SUPER ? updateWebsiteFields() : setIsButtonLoading(false);
              },
              onReject: () => {
                setIsButtonLoading(false);
              },
            })
          );
    },
    [
      mode,
      address,
      buildingInfo,
      id,
      file,
      image,
      addressInfo,
      attachedPersons,
      attachedRooms,
      attachedFloors,
      initialValues,
      handledFileds,
      propertyFilterPresets,
      profile,
      dispatch,
    ]
  );

  const handleAddressAddSubmit = useCallback(() => {
    setAddressLoading(true);

    const data = {
      ...addressInfo,
      ...injectAddressFields({values: form.getFieldsValue()}),
    };

    dispatch(
      addAddressAction(data, {
        onFulfilled: () => {
          setAddressLoading(false);
          setHandledFields(prev => ({
            ...prev,
            [HandledFieldsEnum.IS_ADDRESS_ATTACHED]: true,
            [HandledFieldsEnum.IS_ADDRESS_SELECTED]: true,
          }));
          showSuccess({message: t('prompts.addressSaved')});
          setAddressInfo(undefined);
        },
        onReject: () => {
          setAddressLoading(false);
          showError({message: getTranslation(ServerErrorsTranslationEnum, error as string)});
        },
      })
    );
  }, [addressInfo, form, error, showSuccess, showError, dispatch, t]);

  const handlePriceChange = useCallback(
    (value: number | string | null, mode: CrossCalculationModesEnum, fieldName?: string) => {
      calculateUnitPrices({
        values,
        form,
        value,
        mode,
        fieldName,
        FiledsEnum: PropertyFieldsEnum,
        fieldTotalSale: PropertyFieldsEnum.TOTAL_PRICE_SALE,
        fieldUnitSale: PropertyFieldsEnum.UNIT_PRICE_SALE,
        fieldTotalMonthly: PropertyFieldsEnum.TOTAL_PRICE_MONTHLY,
        fieldUnitMonthly: PropertyFieldsEnum.UNIT_PRICE_MONTHLY,
      });
    },
    [form, values]
  );

  const handlePersonEdit = useCallback(
    (entity?: TPerson) => {
      show(PopupPersonAttach, {
        mode: getTranslation(ActionModeHero, ActionModesEnum.UPDATE),
        entity,
        setAttachedPersons,
      });
    },
    [show]
  );

  const handlePersonUpdate = useCallback(
    (personId?: number) => {
      const route = `${RoutesEnum.PERSONS}/${ActionModesEnum.UPDATE}/${personId}${id ? `?property=${id}` : ''}`;

      if (mode === ActionModesEnum.CREATE) {
        showWarning({
          duration: null,
          message: t('prompts.warning'),
          description: t('prompts.personUpdate'),
          btn: (
            <PButton size="middle" onClick={() => navigate(route)}>
              {t('actions.update')}
            </PButton>
          ),
        });
      } else {
        navigate(route);
      }
    },
    [id, mode, showWarning, navigate, t]
  );

  const handlePersonDelete = useCallback(
    (id: number) => {
      setAttachedPersons(prev => [...prev.filter(p => p.id !== id)]);
    },
    [setAttachedPersons]
  );

  const handleAddonDelete = useCallback(
    (id: number, addon: TAddon) => {
      if (addon === AddonsEnum.ROOMS) {
        setAttachedRooms(prev => [...prev.filter(p => p.id !== id)]);
      } else {
        setAttachedFloors(prev => [...prev.filter(p => p.id !== id)]);
      }
    },
    [setAttachedRooms, setAttachedFloors]
  );

  const handleUploadClick = useCallback((propName: string, value: boolean | undefined) => {
    setHandledFields(prev => ({...prev, [propName]: value}));
  }, []);

  useEffect(() => {
    dispatch(clearCurrentPropertyAction());

    if (id && mode === ActionModesEnum.CREATE) {
      dispatch(getLeadByIdAction({id}));
    }

    if (
      (id && mode === ActionModesEnum.UPDATE) ||
      holdedProperty ||
      archivedProperty ||
      updatedPrice ||
      disabled ||
      enabled
    ) {
      dispatch(clearMediaAction());
      dispatch(getPropertyByIdAction({id}));
    }
  }, [id, mode, holdedProperty, archivedProperty, updatedPrice, disabled, enabled, dispatch]);

  useEffect(() => {
    if (currentLead) {
      form.resetFields();
      form.setFieldsValue({});

      const {
        area,
        living_area,
        land_area,
        square_metre,
        hectare,
        floor,
        floors,
        entrance_floor,
        info,
        type,
        persons,
        id,
        mode,
        rent,
        rooms,
        currency_daily,
        currency_monthly,
        currency_sale,
        total_price_daily,
        total_price_monthly,
        total_price_sale,
        unit_price_daily,
        unit_price_monthly,
        unit_price_sale,
        usage,
      } = currentLead;

      const rentToSet = [];
      (mode === ContractTypesEnum.SALE || mode === ContractTypesEnum.ALL) && rentToSet.push(ContractTypesEnum.SALE);
      (rent === ContractTypesEnum.MONTHLY || rent === ContractTypesEnum.ALL) &&
        rentToSet.push(ContractTypesEnum.MONTHLY);
      (rent === ContractTypesEnum.DAILY || rent === ContractTypesEnum.ALL) && rentToSet.push(ContractTypesEnum.DAILY);

      const total_area = type === PropertyTypesEnum.COMMERCIAL ? area : undefined;

      const initialValues = {
        rent: rentToSet,
        status: StatusEnum.CURRENT,
        mode,
        lead: id,
        persons,
        type,
        area,
        total_area,
        living_area,
        land_area,
        square_metre,
        hectare,
        info,
        bedrooms: rooms,
        floor,
        floor_plan: floors,
        entrance_floor,
        currency_daily,
        currency_monthly,
        currency_sale,
        total_price_daily,
        total_price_monthly,
        total_price_sale,
        unit_price_daily,
        unit_price_monthly,
        unit_price_sale,
        usage,
      };

      form.setFieldsValue(initialValues);
      setInitialValues(initialValues);
      setAttachedPersons(persons);
    }
  }, [currentLead, form]);

  useEffect(() => {
    if (addressInfo) {
      form.setFieldsValue({...addressInfo});
      setInitialValues(prevItems => ({...prevItems, ...addressInfo}));
    }
  }, [addressInfo, form]);

  useEffect(() => {
    if (id && currentProperty) {
      form.resetFields();
      form.setFieldsValue({});

      setInitialValues({});
      const {mode, rent, type, price, status, marketing, website, location, options, number, ...rest} = currentProperty;

      const optionsToSet = findObjectByKeys({options, state: catalog[OptGroupsEnum.APPARTMENT_MARKERS], key: 'value'});
      const yardMarkersToSet = findObjectByKeys({options, state: catalog[OptGroupsEnum.YARD_MARKERS], key: 'value'});
      const viewsToSet = findObjectByKeys({options, state: catalog[OptGroupsEnum.VIEW], key: 'value'});
      const entryPositionToSet = findObjectByKeys({
        options,
        state: catalog[OptGroupsEnum.ENTRY_POSITION],
        key: 'value',
      });
      const entryTypeToSet = findObjectByKeys({options, state: catalog[OptGroupsEnum.ENTRY_TYPE], key: 'value'});

      const rentToSet = [];
      (mode === ContractTypesEnum.SALE || mode === ContractTypesEnum.ALL) && rentToSet.push(ContractTypesEnum.SALE);
      (rent === ContractTypesEnum.MONTHLY || rent === ContractTypesEnum.ALL) &&
        rentToSet.push(ContractTypesEnum.MONTHLY);
      (rent === ContractTypesEnum.DAILY || rent === ContractTypesEnum.ALL) && rentToSet.push(ContractTypesEnum.DAILY);

      const numberToSet =
        type === PropertyTypesEnum.LAND || type === PropertyTypesEnum.HOUSE ? location?.address?.number! : number;

      const initialValues = {
        status: status === StatusEnum.NEW ? StatusEnum.CURRENT : status,
        rent: rentToSet,
        total_price_monthly: price?.total_monthly,
        total_price_daily: price?.total_daily,
        total_price_sale: price?.total_sale,
        currency_sale: price?.currency_sale,
        currency_monthly: price?.currency_monthly,
        currency_daily: price?.currency_daily,
        unit_price_daily: price?.unit_daily,
        unit_price_monthly: price?.unit_monthly,
        unit_price_sale: price?.unit_sale,
        marketing: !marketing ? false : true,
        website: !website ? false : true,
        country_name: location?.country?.name!,
        region_name: location?.region?.name!,
        settlement_name: location?.settlement?.name!,
        district_name: location?.district?.name!,
        street_name: location?.street?.name!,
        number: numberToSet,
        searched_building: location?.address?.combined,
        location,
        type,
        options: optionsToSet,
        view: viewsToSet,
        entry_position: entryPositionToSet,
        yard_markers: yardMarkersToSet,
        entry_type: entryTypeToSet,
        ...rest,
      };

      form.setFieldsValue(initialValues);

      setInitialValues(initialValues);

      currentProperty?.building?.id &&
        setBuildingInfo({
          key: currentProperty?.building?.id,
          id: currentProperty?.building?.id,
          value: location?.address?.combined,
          address: location?.address,
          country: location?.country,
          district: location?.district,
          region: location?.region,
          settlement: location?.settlement,
          street: location?.street,
        });

      currentProperty?.location?.address?.id &&
        setHandledFields(prev => ({...prev, [HandledFieldsEnum.IS_ADDRESS_SELECTED]: true}));

      setAttachedPersons(initialValues.persons);
      setAttachedRooms(
        initialValues.rooms?.map(room => ({
          ...room,
          image_folder: room?.image_folder?.id,
          file_folder: room?.file_folder?.id,
        }))
      );
      setAttachedFloors(
        initialValues.floors?.map(floor => ({
          ...floor,
          image_folder: floor?.image_folder?.id,
          file_folder: floor?.file_folder?.id,
        }))
      );
    }

    if (id && currentProperty?.image_folder) {
      dispatch(
        getFolderByIdAction(
          {
            id: currentProperty?.image_folder?.id,
          },
          {folder: FoldersEnum.IMAGE}
        )
      );
    } else {
      dispatch(clearMediaAction({}, {folder: FoldersEnum.IMAGE}));
    }

    if (id && currentProperty?.file_folder) {
      dispatch(
        getFolderByIdAction({
          id: currentProperty.file_folder.id,
        })
      );
    } else {
      dispatch(clearMediaAction({}, {folder: FoldersEnum.FILE}));
    }
  }, [id, currentProperty, form, catalog, dispatch]);

  useEffect(() => {
    dispatch(clearAddressAction());
  }, [dispatch]);

  useEffect(() => {
    if (buildingInfo) {
      dispatch(clearCurrentBuildingAction());
    }
  }, [buildingInfo, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearPropertiesErrorsAction());
    };
  }, [dispatch]);

  if (
    !isLoading &&
    mode === ActionModesEnum.UPDATE &&
    (error === ServerErrorsEnum.INVALID_ID || error?.response?.status === 404)
  ) {
    return <EmptyPage showHeader />;
  } else if (
    (mode !== ActionModesEnum.UPDATE && mode !== ActionModesEnum.VIEW && mode !== ActionModesEnum.CREATE) ||
    (currentProperty && mode === ActionModesEnum.UPDATE && currentProperty?.status === StatusEnum.ARCHIVED) ||
    (mode === ActionModesEnum.CREATE && profile?.type === RolesEnum.MARKETING)
  ) {
    return <Navigate to={RoutesEnum.PROPERTIES} />;
  }

  return isLoading ? (
    <PLoader />
  ) : (
    <PLoader spinning={isLoading} asLayer>
      <Row justify="space-between" gutter={16}>
        <Col className="grow">
          <PPageTitle
            text={
              <PButton
                type="text"
                theme="white"
                icon={<Icon.ChevronLeftRounded fill="currentColor" />}
                onClick={() => navigate(-1)}
              >
                {t('actions.back')}
              </PButton>
            }
          />
        </Col>
        {currentProperty?.id && (
          <>
            <Col>
              {mode === ActionModesEnum.UPDATE && (
                <Link to={`${RoutesEnum.PROPERTIES}/${ActionModesEnum.VIEW}/${id}`}>
                  <PButton
                    htmlType="button"
                    type="text"
                    theme="white"
                    icon={<Icon.Eye color="currentColor" width={24} height={24} cursor="pointer" />}
                  >
                    {t('actions.view')}
                  </PButton>
                </Link>
              )}
            </Col>
            <Col>
              <CPdfGenerate />
            </Col>
          </>
        )}
      </Row>
      <FormProperty
        mode={mode}
        form={form}
        values={values}
        initialValues={initialValues}
        addressInfo={addressInfo!}
        buildingInfo={buildingInfo}
        isAddressLoading={isAddressLoading}
        attachedPersons={attachedPersons}
        attachedRooms={attachedRooms}
        attachedFloors={attachedFloors}
        isButtonLoading={isButtonLoading}
        isAddressAttached={handledFileds[HandledFieldsEnum.IS_ADDRESS_ATTACHED]}
        isImagesAttached={handledFileds[HandledFieldsEnum.IS_IMAGES_ATTACHED]}
        isFilesAttached={handledFileds[HandledFieldsEnum.IS_FILES_ATTACHED]}
        isBuildingSelected={handledFileds[HandledFieldsEnum.IS_BUILDING_SELECTED]}
        isAddressSelected={handledFileds[HandledFieldsEnum.IS_ADDRESS_SELECTED]}
        personColumns={personColumns({
          id,
          profile,
          addressInfo: address,
          buildingInfo,
          mode,
          onEdit: handlePersonEdit,
          onUpdate: handlePersonUpdate,
          onDelete: handlePersonDelete,
          translation: t,
        })}
        onUploadClick={handleUploadClick}
        setAttachedPersons={setAttachedPersons}
        setAttachedRooms={setAttachedRooms}
        setAttachedFloors={setAttachedFloors}
        handleAddonDelete={handleAddonDelete}
        setAddressInfo={setAddressInfo}
        setBuildingInfo={setBuildingInfo}
        handlePriceChange={handlePriceChange}
        onAddressAdd={handleAddressAddSubmit}
        setHandledFields={setHandledFields}
        onSubmit={handleSubmit}
      />

      {contextHolder}
    </PLoader>
  );
};
