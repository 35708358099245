import {memo, useCallback, useState} from 'react';
import {Col, RadioChangeEvent, Row} from 'antd';

import {PRadio} from 'components/primitives/p-radio';
import {
  InquiryAnalyticsView,
  LeadAnalyticsView,
  PropertyAnalyticsView,
} from 'components/screens/analytics-totals/components';

enum ReportTypesEnum {
  PROPERTY = 'property',
  INQUIRY = 'inquiry',
  LEAD = 'lead',
}

const ScreenAnalyticsTotals = () => {
  const [reportType, setReportType] = useState<ReportTypesEnum>(ReportTypesEnum.PROPERTY);

  const handleReportTypeChange = useCallback((e: RadioChangeEvent) => {
    setReportType(e.target.value);
  }, []);

  return (
    <>
      <Row className="mb-5">
        <Col>
          <PRadio
            onChange={handleReportTypeChange}
            defaultValue={reportType}
            optionType="button"
            buttonStyle="outline"
            size="large"
            options={[
              {label: 'Գույքեր', value: ReportTypesEnum.PROPERTY},
              {label: 'Բիզնես պրոցեսներ', value: ReportTypesEnum.INQUIRY},
              {label: 'Լիդեր', value: ReportTypesEnum.LEAD},
            ]}
          ></PRadio>
        </Col>
      </Row>
      {reportType === ReportTypesEnum.PROPERTY && <PropertyAnalyticsView />}
      {reportType === ReportTypesEnum.INQUIRY && <InquiryAnalyticsView />}
      {reportType === ReportTypesEnum.LEAD && <LeadAnalyticsView />}
    </>
  );
};

export const ScreenAnalyticsTotalsMemoized = memo(ScreenAnalyticsTotals);
