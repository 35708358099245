import {createSelector} from '@reduxjs/toolkit';

import {State} from 'store/types';

import {AppState} from './types';

const getState = (state: State): AppState => state.app;

export const appLoadingSelector = createSelector(getState, state => state.loading);
export const appProfileLoadingSelector = createSelector(getState, state => state.profileLoading);
export const appProfileSelector = createSelector(getState, state => state.profile);
export const appCatalogSelector = createSelector(getState, state => state.catalog);
export const appCountriesSelector = createSelector(getState, state => state.countries);
export const appStreetsSelector = createSelector(getState, state => state.streets);
export const appRegionsSelector = createSelector(getState, state => state.regions);
export const appSettlementsSelector = createSelector(getState, state => state.settlements);
export const appDistrictsSelector = createSelector(getState, state => state.districts);
export const appAddressSelector = createSelector(getState, state => state.address);
export const appFileSelector = createSelector(getState, state => state.file);
export const appImageSelector = createSelector(getState, state => state.image);
export const appCurrentFileSelector = createSelector(getState, state => state.currentFile);
export const appCurrentImageSelector = createSelector(getState, state => state.currentImage);

export const appRoomFileSelector = createSelector(getState, state => state.roomFile);
export const appRoomImageSelector = createSelector(getState, state => state.roomImage);
export const appCurrentRoomFileSelector = createSelector(getState, state => state.currentRoomFile);
export const appCurrentRoomImageSelector = createSelector(getState, state => state.currentRoomImage);

export const appFloorFileSelector = createSelector(getState, state => state.floorFile);
export const appFloorImageSelector = createSelector(getState, state => state.floorImage);
export const appCurrentFloorFileSelector = createSelector(getState, state => state.currentFloorFile);
export const appCurrentFloorImageSelector = createSelector(getState, state => state.currentFloorImage);

export const appPropertyBuildingImageSelector = createSelector(getState, state => state.propertyBuildingImage);
export const appPropertyBuildingFileSelector = createSelector(getState, state => state.propertyBuildingFile);

export const appBuildingImageSelector = createSelector(getState, state => state.buildingImage);
export const appBuildingFileSelector = createSelector(getState, state => state.buildingFile);
export const appCurrentBuildingImageSelector = createSelector(getState, state => state.currentBuildingImage);
export const appCurrentBuildingFileSelector = createSelector(getState, state => state.currentBuildingFile);
