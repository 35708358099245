import {TActionSettings} from 'types/common';

export const searchPersonsAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'persons/searchPersonsAction',
  payload,
  settings: settings || {},
});

export const addPersonAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'persons/addPersonAction',
  payload,
  settings: settings || {},
});

export const updatePersonAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'persons/updatePersonAction',
  payload,
  settings: settings || {},
});

export const getPersonByIdAction = (payload?: {id?: number | string}, settings?: TActionSettings) => ({
  type: 'shared/getPersonByIdAction',
  payload,
  settings: settings || {},
});

export const clearPersonErrorsAction = () => ({
  type: 'persons/clearPersonErrorsAction',
});

export const clearCurrentPersonAction = () => ({
  type: 'custom/clearCurrentPersonAction',
});

export const clearCreatedPersonAction = () => ({
  type: 'custom/clearCreatedPersonAction',
});

export const clearSearchedPersonAction = () => ({
  type: 'custom/clearSearchedPersonAction',
});
