import {memo, useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Form} from 'antd';
import {useTranslation} from 'react-i18next';

import {PopupBase} from 'components/popups/base';
import {addPropertyHoldAction} from 'store/properties/actions';
import {formatDate} from 'utils';
import {FormatsEnum, SeparatorEnum} from 'enums';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {useToast} from 'hooks';
import {FormBooking} from 'components/forms/booking';
import {IBookingFields} from 'components/forms/booking/form-booking.interfaces';
import {PAlert} from 'components/primitives/p-alert';
import {IPropertyFields} from 'components/forms/property/form-property.interfaces';

const PopupBooking = ({
  onClose,
  onSubmit,
  id,
  property,
  entity,
  inquiry,
  mode,
}: {
  id: number;
  property?: IPropertyFields;
  inquiry?: string | undefined;
  entity?: IBookingFields;
  mode?: 'default' | 'end';
  onSubmit?: () => void;
  onClose: () => void;
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {showError, contextHolder} = useToast();
  const [form] = Form.useForm<IBookingFields>();
  const [isSubmittable, setIsSubmittable] = useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const values = Form.useWatch([], form);

  const handleSubmit = useCallback(
    (values: IBookingFields) => {
      setIsButtonLoading(true);

      const valuesToSend = {
        ...values,
        property: id,
        start: values.date
          ? `${formatDate({
              value: new Date(values.date[0]),
              format: FormatsEnum.REVERSE,
              separator: SeparatorEnum.HYPHEN,
            })}`
          : null,
        end: values.date
          ? formatDate({value: new Date(values.date[1]), format: FormatsEnum.REVERSE, separator: SeparatorEnum.HYPHEN})
          : null,
      };

      dispatch(
        addPropertyHoldAction(valuesToSend, {
          onFulfilled: () => {
            setIsButtonLoading(false);
            onSubmit && onSubmit();
            onClose();
          },
          onReject: () => {
            setIsButtonLoading(false);
            showError({message: fieldsMessageTranslations.commonErrors});
          },
        })
      );
    },
    [id, showError, dispatch, onSubmit, onClose]
  );

  const submit = useCallback(() => {
    handleSubmit(values);
  }, [values, handleSubmit]);

  useEffect(() => {
    form.validateFields({validateOnly: true}).then(
      () => {
        setIsSubmittable(true);
      },
      () => {
        setIsSubmittable(false);
      }
    );
  }, [values, form]);

  return (
    <PopupBase
      title={t('actions.bookProperty')}
      onCrossClick={onClose}
      onSubmit={submit}
      open
      type="prompt"
      buttons={{
        reject: {
          name: t('actions.close'),
          type: 'text',
          onClick: onClose,
        },
        confirm: {
          name: t('actions.end'),
          type: 'primary',
          htmlType: 'button',
          disabled: !isSubmittable,
          loading: isButtonLoading,
          onClick: submit,
        },
      }}
    >
      {inquiry && mode === 'end' && (
        <PAlert type="warning" message={t('prompts.bookForEnd')} showIcon className="mb-6" />
      )}
      <FormBooking form={form} initialValues={entity} values={values} onSumbit={handleSubmit} />
      {contextHolder}
    </PopupBase>
  );
};

export const PopupBookingMemoized = memo(PopupBooking);
