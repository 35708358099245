import {memo} from 'react';

import {InquiryStateTranslationEnum} from 'enums/translations';
import {getTranslation} from 'utils';
import {TInquiryComment} from 'types/api';

const PCommentItem = ({entity}: {entity: TInquiryComment | Record<string, string>}) => {
  const {created, state, comment} = entity;

  return (
    <div className="mb-5 pb-5 border-solid border-t-0 border-l-0 border-r-0 border-b border-[#F4F4F4] last:pb-0 last:mb-0 last:border-0">
      <div className="flex items-center justify-end mb-1.5">
        {created ? <div className="text-xs text-[#969696]">{created}</div> : null}
        {state ? (
          <div className="grow flex justify-end">
            <div className="text-xs text-[#373435] uppercase rounded-[4px] p-1 border border-solid border-[#373435] opacity-50 leading-[1]">
              {getTranslation(InquiryStateTranslationEnum, state)}
            </div>
          </div>
        ) : null}
      </div>
      <div className="text-sm">{comment}</div>
    </div>
  );
};

export const PCommentItemMemoized = memo(PCommentItem);
