import {memo} from 'react';
import {DatePicker} from 'antd';

import {Icon} from 'assets/icons';
import {PFormLabel} from 'components/primitives/p-form-label';
import {dateDefaultFormat} from 'constants/date-formats';

import {PDatepickerProps} from './p-rangepicker.types';

const {RangePicker} = DatePicker;

const PRangepicker = ({label, id, name, size = 'large', ...props}: PDatepickerProps) => {
  return (
    <div className="relative">
      {label && <PFormLabel text={label} id={id} />}
      <RangePicker
        {...props}
        size={size}
        allowClear
        id={id}
        suffixIcon={<Icon.Calendar width={20} height={20} />}
        clearIcon={<Icon.CloseCircle width={20} height={20} />}
        format={dateDefaultFormat}
      />
    </div>
  );
};

export const PRangepickerMemoized = memo(PRangepicker);
