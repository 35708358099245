import {TActionSettings} from 'types/common';

export const getPropertyTotalsAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'analytics/getPropertyTotals',
  payload: payload || {},
  settings: settings || {},
});

export const getInquiryTotalsAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'analytics/getInquiryTotals',
  payload: payload || {},
  settings: settings || {},
});

export const getLeadTotalsAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'analytics/getLeadTotals',
  payload: payload || {},
  settings: settings || {},
});

export const getPropertyPerformanceAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'analytics/getPropertyPerformance',
  payload: payload || {},
  settings: settings || {},
});

export const getInquiryPerformanceAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'analytics/getInquiryPerformance',
  payload: payload || {},
  settings: settings || {},
});

export const getLeadPerformanceAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'analytics/getLeadPerformance',
  payload: payload || {},
  settings: settings || {},
});

export const clearAnalyticsErrorsAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'analytics/clearAnalyticsErrors',
  payload,
  settings: settings || {},
});
