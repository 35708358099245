import {optGroups} from 'constants/opt-groups';
import {LocationPropsEnum, OptGroupsEnum, SeparatorEnum} from 'enums';
import {PropertyFilterFieldsEnum} from 'components/forms/property-filter/form-property-filter.enums';
import {DAY_END, DAY_START} from 'constants/time';
import {formatDate, formatPhoneNumber, injectLocationFilterValues} from 'utils';

export const injectPropertyFilterValues = ({values}: {values: any}) => {
  const {
    modified,
    available,
    addressNumber,
    apartmentNumber,
    options,
    markers,
    yardMarkers,
    view,
    entryPosition,
    mainEntry,
    entryType,
    phone,
    'region[]': region,
    'settlement[]': settlement,
    'district[]': district,
    'street[]': street,
    ...rest
  } = values;

  return {
    ...rest,
    'addressNumber[]':
      addressNumber && addressNumber?.toString()?.includes(',')
        ? addressNumber?.split(',')
        : addressNumber?.toString().length
        ? addressNumber
        : null,
    'apartmentNumber[]':
      apartmentNumber && apartmentNumber?.toString()?.includes(',')
        ? apartmentNumber?.split(',')
        : apartmentNumber?.toString().length
        ? apartmentNumber
        : null,
    phone: formatPhoneNumber(phone),
    [`options[${optGroups[OptGroupsEnum.APPARTMENT_MARKERS]}]`]: [...(options || [])],
    [`options[${optGroups[OptGroupsEnum.YARD_MARKERS]}]`]: [...(yardMarkers || [])],
    [`options[${optGroups[OptGroupsEnum.VIEW]}]`]: [...(view || [])],
    [`options[${optGroups[OptGroupsEnum.ENTRY_POSITION]}]`]: [...(entryPosition || [])],
    [`options[${optGroups[OptGroupsEnum.ENTRY_TYPE]}]`]: [...(entryType || [])],
    [PropertyFilterFieldsEnum.MIN_MODIFIED]:
      modified && modified![0]
        ? `${formatDate({value: modified![0], separator: SeparatorEnum.HYPHEN})} ${DAY_START}`
        : null,
    [PropertyFilterFieldsEnum.MAX_MODIFIED]:
      modified && modified![1]
        ? `${formatDate({value: modified![1], separator: SeparatorEnum.HYPHEN})} ${DAY_END}`
        : null,
    [PropertyFilterFieldsEnum.AVAILABLE_FROM]:
      available && available![0] ? formatDate({value: available![0], separator: SeparatorEnum.HYPHEN}) : null,
    [PropertyFilterFieldsEnum.AVAILABLE_TO]:
      available && available![1] ? formatDate({value: available![1], separator: SeparatorEnum.HYPHEN}) : null,
    ...injectLocationFilterValues({propName: LocationPropsEnum.REGION, value: region!}),
    ...injectLocationFilterValues({propName: LocationPropsEnum.SETTLEMENT, value: settlement!}),
    ...injectLocationFilterValues({propName: LocationPropsEnum.DISTRICT, value: district!}),
    ...injectLocationFilterValues({propName: LocationPropsEnum.STREET, value: street!}),
  };
};
