import {PaginationEnum, SortOrderEnum} from 'enums';

export const omittedParams = (params: any) => {
  const {
    [PaginationEnum.PAGE]: page,
    [PaginationEnum.OFFSET]: offset,
    [PaginationEnum.LENGTH]: length,
    [SortOrderEnum.SORT]: sort,
    [SortOrderEnum.ASC]: asc,
    [SortOrderEnum.DESC]: desc,
    ...rest
  } = params;

  return {...rest};
};
