import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {TServerResponseError} from 'types/common';

import {AccountsState} from './types';

const sliceAccounts = createSlice({
  name: 'accounts',
  initialState: {
    loading: false,
    error: null,
    accounts: [],
    accountsCount: 0,
    currentAccount: null,
    account: null,
  },
  reducers: {
    setErrors(state: AccountsState, {payload}: PayloadAction<TServerResponseError | null>) {
      state.error = payload;
    },
    setLoading(state: AccountsState, {payload}: PayloadAction<AccountsState['loading']>) {
      state.loading = payload;
    },
    setCurrentAccount(state: AccountsState, {payload}: PayloadAction<AccountsState['currentAccount']>) {
      state.currentAccount = payload;
    },
    setAccounts(state: AccountsState, {payload}: PayloadAction<AccountsState['accounts']>) {
      state.accounts = payload;
    },
    setAccount(state: AccountsState, {payload}: PayloadAction<AccountsState['account']>) {
      state.account = payload;
    },
    setAccountsCount(state: AccountsState, {payload}: PayloadAction<AccountsState['accountsCount']>) {
      state.accountsCount = payload;
    },
  },
});

export const {setErrors, setLoading, setCurrentAccount, setAccounts, setAccount, setAccountsCount} =
  sliceAccounts.actions;

export const accountsReducer = sliceAccounts.reducer;
