import {memo, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {PopupBase} from 'components/popups/base';
import {ActionModeHero} from 'constants/translations';
import {buildingCurrentSelector} from 'store/buildings/selectors';
import {ScreenBuildingDetailed} from 'components/screens/building-detailed';

const PopupBuilding = ({
  mode,
  propertyType,
  onClose,
  onPopupClose,
}: {
  mode?: string;
  propertyType?: string;
  onClose: () => void;
  onPopupClose: () => void;
}) => {
  const {t} = useTranslation();
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const currentBuilding = useSelector(buildingCurrentSelector);

  useEffect(() => {
    if (currentBuilding && !isButtonLoading) {
      onClose();
      onPopupClose();
    }
  }, [currentBuilding, isButtonLoading, onClose, onPopupClose]);

  return (
    <>
      <PopupBase
        title={`${ActionModeHero.add} ${t('common.building')}`}
        onCrossClick={() => {
          onClose();
          onPopupClose();
        }}
        open
        size="xl"
        buttons={null}
      >
        <ScreenBuildingDetailed
          mode={mode}
          isFromPopup={true}
          propertyType={propertyType}
          setButtonLoading={setIsButtonLoading}
          onClose={() => {
            onClose();
            onPopupClose();
          }}
        />
      </PopupBase>
    </>
  );
};

export const PopupBuildingMemoized = memo(PopupBuilding);
