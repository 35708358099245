import {memo, ReactNode, useEffect, useMemo} from 'react';
import {Tooltip} from 'antd';

import {TPersonAddress, TPersonBuilding} from 'types/api';
import {useToast} from 'hooks';

type TPropertyUSeInformerProps = {
  children?: ReactNode;
  person: Record<string, any>;
  addressInfo: any | null;
  buildingInfo: Record<string, any> | undefined;
};

const CPropertyUseInformer = ({children, person, addressInfo, buildingInfo}: TPropertyUSeInformerProps) => {
  const {contextHolder, showWarning} = useToast();

  const buildingsCount = useMemo(
    () =>
      person.buildings
        ?.filter(({building}: TPersonBuilding) => building === buildingInfo?.id)
        .map(({cnt}: {cnt: number}) => cnt)[0],
    [person.buildings, buildingInfo?.id]
  );

  const addressesCount = useMemo(
    () =>
      person.addresses
        ?.filter(({address}: TPersonAddress) => address === addressInfo)
        .map(({cnt}: {cnt: number}) => cnt)[0],
    [person.addresses, addressInfo]
  );

  useEffect(() => {
    if ((buildingInfo && buildingsCount) || (addressInfo && addressesCount)) {
      showWarning({
        message: 'Ուշադրություն',
        description: (
          <div>
            Այս հասցեում{' '}
            <Tooltip title={<span className="text-black">{person.name}</span>} color="white" zIndex={2070}>
              անձը
            </Tooltip>{' '}
            ունի {buildingsCount || addressesCount} գույք։
          </div>
        ),
      });
    }
  }, [buildingInfo, addressInfo, buildingsCount, addressesCount, person, showWarning]);

  return (
    <div className="relative inline-flex gap-1.5">
      {children}
      {((buildingInfo && buildingsCount) || (addressInfo && addressesCount)) && (
        <Tooltip title={<span className="text-black">Հասցեում արդեն առկա գույքերի քանակ</span>} color="white">
          <div className="bg-danger min-w-[15px] px-1.5 h-[18px] flex justify-center rounded-lg text-xs text-white cursor-default">
            {buildingsCount || addressesCount}
          </div>
        </Tooltip>
      )}
      {contextHolder}
    </div>
  );
};

export const CPropertyUseInformerMemoized = memo(CPropertyUseInformer);
