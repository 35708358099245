export enum PersonFieldsEnum {
  NAME = 'name',
  EMAIL = 'email',
  PHONES = 'phones',
  PHONE = 'phone',
  TYPE = 'type',
  RELATION = 'relation',
  BINDING = 'binding',
  CUSTOMER_MODE = 'customerMode',
  MODE = 'mode',
  RENT = 'rent',
  ACTION = 'action',
  PROPERTY_TYPE = 'propertyType',
  STATS = 'stats',
}
