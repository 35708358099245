import {TablePaginationConfig} from 'antd';

export const LISTING_OFFSET = 0;
export const LISTING_LENGTH = 20;

export const tablePaginationConfig: TablePaginationConfig = {
  position: ['bottomLeft'],
  defaultPageSize: LISTING_LENGTH,
  pageSize: LISTING_LENGTH,
};

export const paginationDefaultOptions = {
  page: 1,
  offset: LISTING_OFFSET,
  length: LISTING_LENGTH,
} as const;

export const paginationEmptyOptions = {
  page: null,
  offset: null,
  length: null,
} as const;

export const sortDefaultOptions = {
  sort: '',
  asc: 0,
  desc: 0,
} as const;
