import {ColumnsType} from 'antd/es/table';
import {Tag, Tooltip} from 'antd';

import {Icon} from 'assets/icons';
import {RolesEnum, ActionModesEnum} from 'enums';
import {palette} from 'constants/theme';
import {IAccountFields} from 'components/forms/account/form-account.interfaces';
import {AccountFieldsEnum} from 'components/forms/account/form-account.enums';
import {IPersonTableColumnProps} from 'types/common';
import {CTextCopy} from 'components/containers/c-text-copy';

interface ITableColumns extends IAccountFields {}

export const columns = ({role, onEdit, translation}: IPersonTableColumnProps): ColumnsType<ITableColumns> => [
  {
    title: translation('form.firstName'),
    dataIndex: AccountFieldsEnum.FIRST_NAME,
    key: AccountFieldsEnum.FIRST_NAME,
  },
  {
    width: '18%',
    title: translation('form.phone'),
    dataIndex: AccountFieldsEnum.PHONE,
    key: AccountFieldsEnum.PHONE,
    render: (_, record) => <CTextCopy text={record.phone} />,
  },
  {
    title: translation('form.email'),
    dataIndex: AccountFieldsEnum.EMAIL,
    key: AccountFieldsEnum.EMAIL,
  },
  {
    title: translation('form.userType'),
    key: AccountFieldsEnum.TYPE,
    dataIndex: AccountFieldsEnum.TYPE,
    render: (_, record) => (
      <Tag
        color={
          record.type === RolesEnum.SUPER
            ? palette.danger.primary
            : record.type === RolesEnum.ADMIN
            ? palette.primary
            : record.type === RolesEnum.BROKER
            ? palette.success.primary
            : record.type === RolesEnum.INFO
            ? palette.info.primary
            : record.type === RolesEnum.MARKETING
            ? palette.purple
            : 'default'
        }
      >
        {record.type}
      </Tag>
    ),
  },
  {
    title: translation('form.action'),
    key: 'action',
    className: 'text-right',
    width: '140px',
    render: (_, record) =>
      role !== record.type &&
      record.type !== RolesEnum.SUPER && (
        <div className="flex justify-end items-center">
          <Tooltip title={translation('actions.update')}>
            <Icon.Edit
              color="#292D32"
              width={24}
              height={24}
              cursor="pointer"
              onClick={() => {
                onEdit!({mode: ActionModesEnum.UPDATE, entity: record});
              }}
            />
          </Tooltip>
        </div>
      ),
  },
];
