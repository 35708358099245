import {memo, useCallback, useState} from 'react';
import {Col, RadioChangeEvent, Row} from 'antd';
import {useTranslation} from 'react-i18next';

import {PRadio} from 'components/primitives/p-radio';
import {BrokersAnalyticsView, InfoAnalyticsView} from 'components/screens/analytics-performance/components';

enum PerformanceModesEnum {
  INFO = 'info',
  BROKERS = 'brokers',
}

const ScreenAnalyticsPerformance = () => {
  const {t} = useTranslation();
  const [performanceMode, setPerformanceMode] = useState<PerformanceModesEnum>(PerformanceModesEnum.INFO);

  const handlePerformanceTypeChange = useCallback((e: RadioChangeEvent) => {
    setPerformanceMode(e.target.value);
  }, []);

  return (
    <>
      <Row className="mb-5">
        <Col>
          <PRadio
            onChange={handlePerformanceTypeChange}
            defaultValue={performanceMode}
            optionType="button"
            buttonStyle="outline"
            size="large"
            options={[
              {label: t('common.infoDepartment'), value: PerformanceModesEnum.INFO},
              {label: t('common.brokers'), value: PerformanceModesEnum.BROKERS},
            ]}
          ></PRadio>
        </Col>
      </Row>
      {performanceMode === PerformanceModesEnum.INFO && <InfoAnalyticsView />}
      {performanceMode === PerformanceModesEnum.BROKERS && <BrokersAnalyticsView />}
    </>
  );
};

export const ScreenAnalyticsPerformanceMemoized = memo(ScreenAnalyticsPerformance);
