import {DefaultOptionType} from 'antd/es/select';
import {ReactNode} from 'react';

export const injectLocationFilterValues = ({
  propName,
  value,
  multiple = true,
  withNames,
}: {
  propName: string;
  value: DefaultOptionType[];
  multiple?: boolean;
  withNames?: boolean;
}): Record<string, ReactNode[] | string | number> => {
  const names = withNames ? {[`${propName}Names`]: value?.map((item: DefaultOptionType) => item.label)} : {};

  return {
    [`${propName}${multiple ? '[]' : ''}`]: value?.map((item: DefaultOptionType) => item.value),
    ...names,
  };
};
