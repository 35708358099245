import {memo, useCallback} from 'react';
import {Divider} from 'antd';
import {v4 as uuidv4} from 'uuid';
import {useTranslation} from 'react-i18next';

import {PopupBase} from 'components/popups/base';
import {PTable} from 'components/primitives/p-table';
import {PFormLabel} from 'components/primitives/p-form-label';

import {PPersonAttachPopupProps} from './popup-property-persons.types';
import {columns} from './constants/columns';

const PopupPropertyPersons = ({address, attachedPersons, onClose}: PPersonAttachPopupProps) => {
  const {t} = useTranslation();

  const onPersonUpdate = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <PopupBase title={address} onCrossClick={onClose} open layerClose size="xl">
      <Divider />
      <PFormLabel className="text-[14px]" text={t('common.propertyAttachedPersons')} isStatic />
      <PTable
        columns={columns({onUpdate: onPersonUpdate, translation: t})}
        dataSource={attachedPersons}
        rowKey={() => uuidv4()}
        paginationOptions={{pageSize: 5}}
      />
    </PopupBase>
  );
};

export const PopupPropertyPersonsMemoized = memo(PopupPropertyPersons);
