export enum ActionModesEnum {
  ADD = 'add',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  VIEW = 'view',
  CHOOSE = 'choose',
  CALL = 'call',
  CONTINUE = 'continue',
  SAVE = 'save',
}
