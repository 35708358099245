export const multiply = (a: number, b: number, {toFix}: {toFix?: number}): number | null => {
  const result = Number(Number(a * b).toFixed(toFix));

  return result > 0 ? result : null;
};

// TODO: make toFix be integer number and positive
// how to crash the system
export const divide = (a: number, b: number, {toFix}: {toFix?: number}): number | null => {
  const result = Number(Number(a / b).toFixed(toFix));

  return result > 0 ? result : null;
};
