import {memo} from 'react';

import {PTitleProps} from './p-title.types';

const PTitle = ({icon, text, iconSize, textSize, className, theme, ...props}: PTitleProps) => {
  return (
    <div
      className={`flex items-center gap-3 mb-4 font-semibold ${
        textSize === 'large' ? 'text-[20px]' : 'text-[16px]'
      } ${className}`}
      {...props}
    >
      {icon && (
        <div
          className={`w-[40px] h-[40px] rounded-full flex items-center justify-center flex-[0_0_auto] ${
            theme === 'danger' ? 'bg-danger-secondary' : 'bg-grey-60'
          }`}
        >
          {icon}
        </div>
      )}
      {text}
    </div>
  );
};

export const PTitleMemoized = memo(PTitle);
