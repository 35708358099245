import {TActionSettings} from 'types/common';

export const getAccountsAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'admin/getAccounts',
  payload: payload || {},
  settings: settings || {},
});

export const addAccountAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'admin/addAccountAction',
  payload,
  settings: settings || {},
});

export const updateAccountAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'admin/updateAccountAction',
  payload,
  settings: settings || {},
});

export const getAccountByIdAction = (payload?: {id: number}, settings?: TActionSettings) => ({
  type: 'account/getAccountById',
  payload,
  settings: settings || {},
});

export const clearAccountsErrorsAction = () => ({
  type: 'account/clearAccountsErrorsAction',
});

export const clearAccountAction = () => ({
  type: 'custom/clearAccount',
});
