import {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';

const PCountUp = ({start = 0, end}: {start?: number; end: number}) => {
  const [value, setValue] = useState<number | null>(null);
  const ref = useRef(start);

  const counter = useMemo(() => end / 20, [end]);

  const handleCountUp = useCallback(() => {
    if (ref.current < end) {
      const result = Math.ceil(ref.current + counter);

      if (result > end) return setValue(end);

      setValue(result);

      ref.current = result;
    }

    setTimeout(handleCountUp, 70);
  }, [counter, end]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      handleCountUp();
    }

    return () => {
      isMounted = false;
    };
  }, [end, handleCountUp]);

  return <div>{value}</div>;
};
export const PCountUpMemoized = memo(PCountUp);
