import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Divider, Form, Row} from 'antd';
import {v4 as uuidv4} from 'uuid';
import {useParams, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {PCardWrapper} from 'components/primitives/p-card-wrapper';
import {PLoader} from 'components/primitives/p-loader';
import {useFilterPresets, usePopup, useQueryParams, useToast} from 'hooks';
import {PPageTitle} from 'components/primitives/p-page-title';
import {PTable} from 'components/primitives/p-table';
import {ActionModeHero} from 'constants/translations';
import {IPersonFields} from 'components/forms/person/form-person.interfaces';
import {FormPersonFilter} from 'components/forms/persons-filter';
import {PopupPersonEdit} from 'components/popups/person-edit';
import {clearFromEmptyKeys, formatPhoneNumber, getTranslation, injectedPersonBinding, omittedParams} from 'utils';
import {RoutesEnum} from 'router/routes.enum';
import {IPersonsFilterFields} from 'components/forms/persons-filter/form-persons-filter.interfaces';
import {
  personsCountSelector,
  personsCreatedSelector,
  personsCurrentSelector,
  personsLoadingSelector,
  personsSelector,
} from 'store/persons/selectors';
import {clearCreatedPersonAction, getPersonByIdAction, searchPersonsAction} from 'store/persons/actions';
import {TPaginationOptions, TSortOptions} from 'types/common';
import {LISTING_LENGTH, LISTING_OFFSET, paginationDefaultOptions, paginationEmptyOptions} from 'constants/pagination';
import {ContractTypesEnum, CustomerTypesEnum, PageNamesEnum, PaginationEnum, PropertyActionsTypesEnum} from 'enums';
import {personRelationOptions} from 'constants/select-options';
import {PersonRelationsTranslationEnum} from 'enums/translations';

import {columns} from './constants/columns';

export const PagePersons = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {mode, id} = useParams();
  const params = useQueryParams();
  const [searchParams] = useSearchParams();
  const handlePresetsSave = useFilterPresets();

  const isLoading = useSelector(personsLoadingSelector);
  const persons = useSelector(personsSelector);
  const personsCount = useSelector(personsCountSelector);
  const createdPerson = useSelector(personsCreatedSelector);
  const currentPerson = useSelector(personsCurrentSelector);

  const {show} = usePopup();
  const {contextHolder, showSuccess} = useToast();

  const building = useMemo(() => searchParams.get('building'), [searchParams]);
  const property = useMemo(() => searchParams.get('property'), [searchParams]);
  const inquiry = useMemo(() => searchParams.get('inquiry'), [searchParams]);
  const pageFrom = useMemo(() => searchParams.get('pageFrom') as PageNamesEnum, [searchParams]);

  const filterInitialValuesFromParams = useMemo(() => {
    const {
      action,
      binding,
      'mode[]': mode,
      'rent[]': rent,
      type,
      property,
      inquiry,
      building,
      pageFrom,
      relation,
      ...rest
    } = omittedParams(params);

    const isRelationOtherType = relation && !personRelationOptions.filter(opt => opt.label === relation).length;

    const customerMode = {
      customerMode:
        binding === CustomerTypesEnum.PROPERTY &&
        action === PropertyActionsTypesEnum.OFFER &&
        mode.includes(ContractTypesEnum.ALL) &&
        mode.includes(ContractTypesEnum.SALE)
          ? CustomerTypesEnum.SALE
          : binding === CustomerTypesEnum.INQUIRY &&
            action === PropertyActionsTypesEnum.SEEK &&
            mode.includes(ContractTypesEnum.ALL) &&
            mode.includes(ContractTypesEnum.SALE)
          ? CustomerTypesEnum.CUSTOMER
          : binding === CustomerTypesEnum.PROPERTY &&
            action === PropertyActionsTypesEnum.OFFER &&
            mode.includes(ContractTypesEnum.ALL) &&
            mode.includes(ContractTypesEnum.RENT) &&
            rent.includes(ContractTypesEnum.ALL)
          ? CustomerTypesEnum.RENT
          : binding === CustomerTypesEnum.INQUIRY &&
            action === PropertyActionsTypesEnum.SEEK &&
            mode.includes(ContractTypesEnum.ALL) &&
            mode.includes(ContractTypesEnum.RENT) &&
            rent.includes(ContractTypesEnum.ALL)
          ? CustomerTypesEnum.RENTER
          : binding,
      propertyType: type,
    };

    const relationToSet = {
      relation: isRelationOtherType ? relation : null,
      type: isRelationOtherType ? PersonRelationsTranslationEnum.OTHER : relation,
    };

    const values = {
      ...rest,
      ...customerMode,
      ...relationToSet,
    };

    return clearFromEmptyKeys<IPersonsFilterFields>(values);
  }, [params]);

  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isFetchedFromParams, setIsFetchedFromParams] = useState<boolean>(Object.keys(params).length > 0);
  const [paginationOptions, setPaginationOptions] = useState<TPaginationOptions>({
    [PaginationEnum.PAGE]: params[PaginationEnum.PAGE] || 1,
    [PaginationEnum.OFFSET]: params[PaginationEnum.OFFSET] || LISTING_OFFSET,
    [PaginationEnum.LENGTH]: params[PaginationEnum.LENGTH] || LISTING_LENGTH,
  });
  const [form] = Form.useForm<IPersonsFilterFields>();
  const values = Form.useWatch([], form);

  const handleSubmit = useCallback(
    (values: IPersonsFilterFields) => {
      setIsButtonLoading(true);

      const {customerMode, propertyType, mode, rent, action, phone, ...rest} = values;
      const binding = customerMode
        ? {
            ...injectedPersonBinding({value: customerMode}),
          }
        : {};

      const valuesToSend = {
        ...rest,
        type: propertyType,
        phone: formatPhoneNumber(phone!),
        relation: values.relation || values.type,
        ...binding,
      };

      handlePresetsSave({
        route: RoutesEnum.PERSONS,
        params: {...params, ...valuesToSend, ...paginationEmptyOptions},
      });

      setPaginationOptions(paginationDefaultOptions);

      dispatch(
        searchPersonsAction(
          {...valuesToSend},
          {onFulfilled: () => setIsButtonLoading(false), onReject: () => setIsButtonLoading(false)}
        )
      );
    },
    [params, dispatch, handlePresetsSave]
  );

  const handleFilterClear = useCallback(() => {
    handlePresetsSave({
      route: RoutesEnum.PERSONS,
      params: {},
    });

    setPaginationOptions(paginationDefaultOptions);
    setIsFetchedFromParams(false);

    dispatch(searchPersonsAction({}));

    form.resetFields();
  }, [form, dispatch, handlePresetsSave]);

  const handleEditPopup = useCallback(
    ({mode, entity}: {mode?: string; entity?: IPersonFields}) => {
      show(PopupPersonEdit, {mode: getTranslation(ActionModeHero, mode!), entity});
    },
    [show]
  );

  const handleTableChange = useCallback(
    (pagination: TPaginationOptions, sorter: TSortOptions) => {
      setPaginationOptions(pagination);

      handlePresetsSave({
        route: RoutesEnum.PERSONS,
        params: {...params, ...pagination},
      });
    },
    [params, handlePresetsSave]
  );

  useEffect(() => {
    const filterPresets = form.getFieldsValue(true);

    const {customerMode, propertyType, mode, rent, action, relation, type, phone, ...rest} = filterPresets;
    const binding = customerMode
      ? {
          ...injectedPersonBinding({value: customerMode}),
        }
      : {};

    const valuesToSend = {
      ...rest,
      type: propertyType,
      phone: formatPhoneNumber(phone),
      relation: relation || type,
      ...binding,
    };

    const pagination = {offset: paginationOptions.offset, length: paginationOptions.length};

    dispatch(searchPersonsAction({...pagination, ...valuesToSend}));
  }, [isFetchedFromParams, form, paginationOptions, createdPerson, dispatch]);

  useEffect(() => {
    if (createdPerson) {
      showSuccess({});
      dispatch(clearCreatedPersonAction());
    }
  }, [createdPerson, showSuccess, dispatch]);

  useEffect(() => {
    if (currentPerson) {
      show(PopupPersonEdit, {
        mode: getTranslation(ActionModeHero, mode!),
        entity: currentPerson,
        propertyId: Number(property),
        inquiryId: Number(inquiry),
        buildingId: Number(building),
        pageFrom,
      });
    }
  }, [currentPerson, mode, building, property, inquiry, pageFrom, show]);

  useEffect(() => {
    if (id) {
      dispatch(getPersonByIdAction({id}));
    }
  }, [id, dispatch]);

  return (
    <PLoader spinning={isLoading} asLayer>
      <PCardWrapper padding="md">
        <Row justify="space-between">
          <Col>
            <PPageTitle text={t('pages.persons.title')} />
          </Col>
        </Row>
        <Divider />
        <FormPersonFilter
          form={form}
          values={values}
          initialValues={filterInitialValuesFromParams}
          isButtonLoading={isButtonLoading}
          onSubmit={handleSubmit}
          onReset={handleFilterClear}
        />
      </PCardWrapper>

      <PCardWrapper padding="md">
        <PTable
          showLeged
          loading={isLoading}
          columns={columns({
            onEdit: handleEditPopup,
            translation: t,
          })}
          paginationOptions={{
            total: personsCount,
            defaultPageSize: paginationOptions.length,
            pageSize: paginationOptions.length,
            defaultCurrent: paginationOptions.page,
            current: paginationOptions.page,
          }}
          dataSource={persons}
          rowKey={() => uuidv4()}
          handleChange={handleTableChange}
        />
      </PCardWrapper>

      {contextHolder}
    </PLoader>
  );
};
