export const fileAccept = {
  PNG: 'image/png',
  GIF: 'image/gif',
  JPEG: 'image/jpeg, .jpg',
  WEBM: '.webm',
  PDF: 'application/pdf',
  TEXT: 'text/plain',
  PPT: '.ppt, .pptx',
  DOC: '.doc, .docx',
  EXCEL: 'application/vnd.ms-excel',
} as const;
