export enum PropertyTypesEnum {
  APARTMENT = 'APARTMENT',
  COMMERCIAL = 'COMMERCIAL',
  HOUSE = 'HOUSE',
  LAND = 'LAND',
  PARKING = 'PARKING',
  RESIDENTIAL = 'RESIDENTIAL',
  COMMERCIAL_BUILDING = 'COMMERCIAL_BUILDING',
  OTHER = 'OTHER',
}
