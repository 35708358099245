import {Col, Row} from 'antd';
import {Component, ReactNode} from 'react';
import {Link} from 'react-router-dom';

import {Image} from 'assets/images';
import {PCardWrapper} from 'components/primitives/p-card-wrapper';
import {Icon} from 'assets/icons';
import {palette} from 'constants/theme';

interface IPropsWithChildren {
  children?: ReactNode;
}

interface IErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

export class ErrorBoundary extends Component<IPropsWithChildren, IErrorBoundaryState> {
  static getDerivedStateFromError(error: Error): Partial<IErrorBoundaryState> {
    return {hasError: true, error};
  }

  state: IErrorBoundaryState = {
    hasError: false,
  };

  render() {
    const {hasError} = this.state;

    if (hasError) {
      return (
        <main className="text-center bg-grey-20">
          <Row justify="center">
            <Col span={6}>
              <div className="mb-8">
                <Link to="/">
                  <img src={Image.brandLogo} alt="Kaskad Realty" />
                </Link>
              </div>
              <PCardWrapper padding="md">
                <Icon.Setting className="mb-4" width={55} height={55} fill={palette.danger.primary} />
                <h2 className="mb-6 leading-[140%]">
                  Համակարգում տեղի է ունեցել սխալ։ Խնդրում ենք թարմացնել էջը և կրկին փորձել։ Կամ դիմել ծրագրավորողների
                  թիմին:
                </h2>
                <a href="/" className="inline-flex gap-2 items-center">
                  Գլխավոր էջ
                </a>
              </PCardWrapper>
            </Col>
          </Row>
        </main>
      );
    }

    const {children} = this.props;

    return children;
  }
}
