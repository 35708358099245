import {Button} from 'antd';
import {memo} from 'react';

import {PButtonProps} from './p-button.types';
import {PButtonSchemeEnum, PButtonThemeEnum} from './p-button.enums';
import {CSS} from './p-button.cssconfig';

const PButton = ({theme, scheme, children, className, size = 'large', ...props}: PButtonProps) => {
  return (
    <Button
      {...props}
      size={size}
      className={`${
        theme === PButtonThemeEnum.WHITE ? CSS.theme.white : theme === PButtonThemeEnum.DEFAULT ? CSS.theme.default : ''
      } 
        ${
          scheme === PButtonSchemeEnum.DANGER
            ? CSS.scheme.danger
            : scheme === PButtonSchemeEnum.WARNING
            ? CSS.scheme.warning
            : ''
        } ${className}`}
    >
      {children}
    </Button>
  );
};

export const PButtonMemoized = memo(PButton);
