import {Col, Form, FormInstance, Row} from 'antd';
import {useSelector} from 'react-redux';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {PInput} from 'components/primitives/p-input';
import {PSelect} from 'components/primitives/p-select';
import {accountTypeOptions} from 'constants/select-options';
import {appProfileSelector} from 'store/app/selectors';
import {FormsEnum, RolesEnum} from 'enums';
import {DepartmentsTranslationEnum} from 'enums/translations';
import {PCheckbox} from 'components/primitives/p-checkbox';
import {permissionsOptions} from 'constants/checkbox-options';
import {ActionModeHero} from 'constants/translations';
import {PPhoneInput} from 'components/primitives/p-phone-input';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';

import {AccountFieldsEnum} from './form-account.enums';
import {IAccountFields} from './form-account.interfaces';

export const FormAccount = ({
  form,
  values,
  initialValues,
  mode,
  onSumbit,
}: {
  form: FormInstance<IAccountFields>;
  values: IAccountFields;
  initialValues: IAccountFields;
  mode: string;
  onSumbit: (v: IAccountFields) => void;
}) => {
  const {t} = useTranslation();

  const profile = useSelector(appProfileSelector);
  const departmentOptions = useMemo(
    () =>
      profile?.type === RolesEnum.ADMIN
        ? accountTypeOptions.filter(
            opt =>
              opt.label !== DepartmentsTranslationEnum.ADMINISTRATOR && opt.label !== DepartmentsTranslationEnum.SUPER
          )
        : accountTypeOptions.filter(opt => opt.label !== DepartmentsTranslationEnum.SUPER),
    [profile]
  );
  const permissions = useMemo(
    () =>
      initialValues?.type === RolesEnum.BROKER
        ? Object.entries(initialValues.permissions as Object)
            .filter(p => p[1] === 1 && p[0])
            .map(p => p[0])
        : [],
    [initialValues]
  );

  return (
    <Form
      form={form}
      onFinish={onSumbit}
      initialValues={
        initialValues?.type === RolesEnum.BROKER
          ? {
              ...initialValues,
              permissions,
            }
          : initialValues
      }
      name={FormsEnum.ACCOUNT}
    >
      <Row gutter={20}>
        <Col span={8}>
          <Form.Item
            name={AccountFieldsEnum.FIRST_NAME}
            rules={[{required: true, message: fieldsMessageTranslations.common}]}
          >
            <PInput label={t('form.firstName')} id={`account-${AccountFieldsEnum.FIRST_NAME}`} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={AccountFieldsEnum.LAST_NAME}
            rules={[{required: true, message: fieldsMessageTranslations.common}]}
          >
            <PInput label={t('form.lastName')} id={`account-${AccountFieldsEnum.LAST_NAME}`} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={AccountFieldsEnum.PHONE}
            rules={[
              {required: true, message: fieldsMessageTranslations.common},
              {min: 9, message: fieldsMessageTranslations.phoneMin},
              {max: 14, message: fieldsMessageTranslations.phoneMax},
            ]}
          >
            <PPhoneInput label={t('form.phone')} id={`account-${AccountFieldsEnum.PHONE}`} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={AccountFieldsEnum.TYPE}
            rules={[{required: true, message: fieldsMessageTranslations.common}]}
          >
            <PSelect label={t('form.department')} options={departmentOptions} />
          </Form.Item>
        </Col>
        <Col span={24}>
          {(values?.type || initialValues?.type) === RolesEnum.BROKER && (
            <Form.Item
              name={AccountFieldsEnum.PERMISSIONS}
              rules={[{required: true, message: fieldsMessageTranslations.common}]}
            >
              <PCheckbox legend={t('form.permissions')} options={permissionsOptions}></PCheckbox>
            </Form.Item>
          )}
        </Col>
        <Col span={12}>
          <Form.Item
            name={AccountFieldsEnum.EMAIL}
            rules={[
              {
                type: 'email',
                message: fieldsMessageTranslations.validEmail,
              },
              {required: true, message: fieldsMessageTranslations.common},
            ]}
          >
            <PInput label={t('form.email')} id={`account-${AccountFieldsEnum.EMAIL}`} type="email" autoComplete="on" />
          </Form.Item>
        </Col>
        <Col span={12}>
          {mode === ActionModeHero.create ? (
            <Form.Item
              name={AccountFieldsEnum.PASSWORD}
              rules={[{required: true, message: fieldsMessageTranslations.common}]}
            >
              <PInput isPassword id={AccountFieldsEnum.PASSWORD} label={t('form.password')} minLength={8} min={8} />
            </Form.Item>
          ) : null}
        </Col>
        <Col span={24}>
          <Form.Item name={AccountFieldsEnum.COMMENTS}>
            <PInput label={t('form.comments')} isTextarea rows={4} id="asdasdasd" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
