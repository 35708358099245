/* eslint-disable @typescript-eslint/naming-convention */

export enum ServerErrorsEnum {
  ADDRESS_IN_USE = 'Address in use',
  EMAIL_IN_USE = 'Used email',
  DUPLICATE_EMAIL = 'Duplicate email',
  PHONE_IN_USE = 'Used phones',
  DUPLICATE_PHONE = 'Duplicate phone',
  INVALID_ID = 'Invalid id',
}
