import {ColumnsType} from 'antd/es/table';

import {currencySymbols} from 'constants/currency-symbols';
import {CurrencyEnum} from 'enums';
import {formatDate, formatNumber} from 'utils';
import {IPricesFields} from 'components/forms/update-price/form-update-price.interfaces';
import {PricesFieldsEnum} from 'components/forms/update-price/form-update-price.enums';
import {TTranslation} from 'types/common';

interface ITableColumns extends IPricesFields {}

export const pricesColumns = ({translation}: TTranslation): ColumnsType<ITableColumns> => [
  {
    title: translation('form.date'),
    dataIndex: PricesFieldsEnum.CREATED,
    key: PricesFieldsEnum.CREATED,
    render: (_, record) => formatDate({value: record.created!}),
  },
  {
    title: translation('form.sale'),
    dataIndex: PricesFieldsEnum.TOTAL_SALE,
    key: PricesFieldsEnum.TOTAL_SALE,
    render: (_, record) =>
      record?.total_sale ? (
        <div>
          {formatNumber(record?.total_sale!)} {currencySymbols[record?.currency_sale as keyof typeof CurrencyEnum]}
        </div>
      ) : (
        '-'
      ),
  },
  {
    title: translation('form.rent'),
    dataIndex: PricesFieldsEnum.TOTAL_MONTHLY,
    key: PricesFieldsEnum.TOTAL_MONTHLY,
    render: (_, record) =>
      record?.total_monthly ? (
        <div>
          {formatNumber(record?.total_monthly!)}{' '}
          {currencySymbols[record?.currency_monthly as keyof typeof CurrencyEnum]}
        </div>
      ) : (
        '-'
      ),
  },
  {
    title: translation('form.rentDaily'),
    dataIndex: PricesFieldsEnum.TOTAL_DAILY,
    key: PricesFieldsEnum.TOTAL_DAILY,
    render: (_, record) =>
      record?.total_daily ? (
        <div>
          {formatNumber(record?.total_daily!)} {currencySymbols[record?.currency_daily as keyof typeof CurrencyEnum]}
        </div>
      ) : (
        '-'
      ),
  },
];
