import {useCallback, useState} from 'react';
import {Col, RadioChangeEvent, Row} from 'antd';
import {useTranslation} from 'react-i18next';

import {PCardWrapper} from 'components/primitives/p-card-wrapper';
import {PPageTitle} from 'components/primitives/p-page-title';
import {PRadio} from 'components/primitives/p-radio';
import {ScreenAnalyticsTotals} from 'components/screens/analytics-totals';
import {ScreenAnalyticsPerformance} from 'components/screens/analytics-performance';

enum ReportModesEnum {
  CURRENT = 'current',
  PERFORMANCE = 'performance',
}

export const PageAnalytics = () => {
  const {t} = useTranslation();
  const [reportMode, setReportMode] = useState<ReportModesEnum>(ReportModesEnum.CURRENT);

  const handleReportModesChange = useCallback((e: RadioChangeEvent) => {
    setReportMode(e.target.value);
  }, []);

  return (
    <>
      <PCardWrapper padding="md">
        <Row>
          <Col>
            <PPageTitle text={t('pages.reports.title')} />
          </Col>
          <Col span={24}>
            <PRadio
              onChange={handleReportModesChange}
              defaultValue={reportMode}
              optionType="button"
              buttonStyle="outline"
              size="large"
              options={[
                {label: t('catalog.statuses.current'), value: ReportModesEnum.CURRENT},
                {label: t('catalog.statuses.workersProductivity'), value: ReportModesEnum.PERFORMANCE},
              ]}
            ></PRadio>
          </Col>
        </Row>
      </PCardWrapper>
      <PCardWrapper padding="md">
        {reportMode === ReportModesEnum.CURRENT && <ScreenAnalyticsTotals />}
        {reportMode === ReportModesEnum.PERFORMANCE && <ScreenAnalyticsPerformance />}
      </PCardWrapper>
    </>
  );
};
