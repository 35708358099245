import {ColumnsType} from 'antd/es/table';
import {Col, Row, Tooltip} from 'antd';

import {Icon} from 'assets/icons';
import {IPersonFields} from 'components/forms/person/form-person.interfaces';
import {PersonFieldsEnum} from 'components/forms/person/form-person.enums';
import {TPhone} from 'types/api';
import {IPersonTableColumnProps} from 'types/common';
import {CTextCopy} from 'components/containers/c-text-copy';
import {ActionModesEnum, RolesEnum} from 'enums';
import {CPropertyUseInformer} from 'components/containers/c-property-use-informer';

interface ITableColumns extends IPersonFields {}

interface IPropertyPersonProps extends IPersonTableColumnProps {
  mode: string;
  addressInfo: Record<string, any> | null;
  buildingInfo: Record<string, any> | undefined;
}

export const personColumns = ({
  onDelete,
  onEdit,
  onUpdate,
  mode,
  addressInfo,
  buildingInfo,
  hideActions = false,
  profile,
  translation,
}: IPropertyPersonProps): ColumnsType<ITableColumns> => [
  {
    title: translation('form.firstName'),
    dataIndex: PersonFieldsEnum.NAME,
    key: PersonFieldsEnum.NAME,
    render: (_, record) =>
      mode === ActionModesEnum.CREATE ? (
        <CPropertyUseInformer person={record} addressInfo={addressInfo} buildingInfo={buildingInfo}>
          {record.name}
        </CPropertyUseInformer>
      ) : (
        record.name
      ),
  },
  {
    title: translation('form.type'),
    dataIndex: PersonFieldsEnum.RELATION,
    key: PersonFieldsEnum.RELATION,
  },
  {
    title: translation('form.phone'),
    key: PersonFieldsEnum.PHONES,
    dataIndex: PersonFieldsEnum.PHONES,
    render: (_, record) => record.phones?.map(p => <CTextCopy key={`${record.id}-${p?.number}`} text={p?.number} />),
  },
  {
    title: translation('form.action'),
    key: 'action',
    className: 'text-right',
    width: '160px',
    render: (_, record) =>
      !hideActions && (
        <Row gutter={8} justify="end" align="middle">
          <Col className="inline-flex">
            <Tooltip title={translation('actions.call')}>
              <a href={`tel:${(record.phones as TPhone[])[0]?.number}`} className="inline-flex">
                <Icon.Calling color="#292D32" width={24} height={24} cursor="pointer" />
              </a>
            </Tooltip>
          </Col>
          {profile?.type !== RolesEnum.MARKETING ? (
            <>
              <Col className="inline-flex">
                <Tooltip title={translation('actions.change')}>
                  <Icon.Edit
                    onClick={() => {
                      onEdit && onEdit(record);
                    }}
                    color="#292D32"
                    width={24}
                    height={24}
                    cursor="pointer"
                  />
                </Tooltip>
              </Col>
              <Col className="inline-flex">
                <Tooltip title={translation('actions.update')}>
                  <div className="flex">
                    <Icon.Setting
                      color="#292D32"
                      width={24}
                      height={24}
                      cursor="pointer"
                      onClick={() => onUpdate && onUpdate(record.id)}
                    />
                  </div>
                </Tooltip>
              </Col>
              <Col className="inline-flex">
                <Tooltip title={translation('actions.delete')}>
                  <Icon.CloseSquare
                    color="#292D32"
                    width={24}
                    height={24}
                    cursor="pointer"
                    onClick={() => {
                      onDelete && onDelete(record.id!);
                    }}
                  />
                </Tooltip>
              </Col>
            </>
          ) : null}
        </Row>
      ),
  },
];
