import {ColumnsType} from 'antd/es/table';

import {TAnalyticsPerformance} from 'types/api';
import {calculateTotal} from 'utils';
import {InquiryStateTranslationEnum} from 'enums/translations';
import {InquiryStateEnum, PerformanceTypeEnum, StatusEnum} from 'enums';

import {PerformanceCommonFildsEnum} from './columns-fields.enum';

interface ITableColumns extends TAnalyticsPerformance {}

export const performanceColumns = ({type}: {type: `${PerformanceTypeEnum}`}): ColumnsType<ITableColumns> => {
  const lead = (): ColumnsType<ITableColumns> =>
    type === PerformanceTypeEnum.LEAD
      ? [
          {
            title: 'Ընթացիկ',
            width: '13%',
            dataIndex: StatusEnum.CURRENT,
            key: StatusEnum.CURRENT,
            render: (_, record) => record.stats && record.stats[StatusEnum.CURRENT],
          },
          {
            title: 'Վերջնական',
            width: '13%',
            dataIndex: StatusEnum.FINALIZED,
            key: StatusEnum.FINALIZED,
            render: (_, record) => record.stats && record.stats[StatusEnum.FINALIZED],
          },
          {
            title: 'Չեղարկված',
            width: '13%',
            dataIndex: StatusEnum.CANCELED,
            key: StatusEnum.CANCELED,
            render: (_, record) => record.stats && record.stats[StatusEnum.CANCELED],
          },
        ]
      : [];

  const property = (): ColumnsType<ITableColumns> =>
    type === PerformanceTypeEnum.PROPERTY
      ? [
          {
            title: 'Ընթացիկ',
            width: '13%',
            dataIndex: StatusEnum.CURRENT,
            key: StatusEnum.CURRENT,
            render: (_, record) => record.stats && record.stats[StatusEnum.CURRENT],
          },
          {
            title: 'Վերջնական',
            width: '13%',
            dataIndex: StatusEnum.FINALIZED,
            key: StatusEnum.FINALIZED,
            render: (_, record) => record.stats && record.stats[StatusEnum.FINALIZED],
          },
          {
            title: 'Սառեցված',
            width: '13%',
            dataIndex: StatusEnum.ONHOLD,
            key: StatusEnum.ONHOLD,
            render: (_, record) => record.stats && record.stats[StatusEnum.ONHOLD],
          },
          {
            title: 'Արխիվացված',
            width: '13%',
            dataIndex: StatusEnum.ARCHIVED,
            key: StatusEnum.ARCHIVED,
            render: (_, record) => record.stats && record.stats[StatusEnum.ARCHIVED],
          },
        ]
      : [];

  const inquiry = (): ColumnsType<ITableColumns> =>
    type === PerformanceTypeEnum.INQUIRY
      ? [
          {
            title: InquiryStateTranslationEnum.SEARCH,
            width: '13%',
            dataIndex: InquiryStateEnum.SEARCH,
            key: InquiryStateEnum.SEARCH,
            render: (_, record) => record.stats && record.stats[InquiryStateEnum.SEARCH],
          },
          {
            title: InquiryStateTranslationEnum.DEMONSTRATION,
            width: '13%',
            dataIndex: InquiryStateEnum.DEMONSTRATION,
            key: InquiryStateEnum.DEMONSTRATION,
            render: (_, record) => record.stats && record.stats[InquiryStateEnum.DEMONSTRATION],
          },
          {
            title: InquiryStateTranslationEnum.PRESENTATION,
            width: '13%',
            dataIndex: InquiryStateEnum.PRESENTATION,
            key: InquiryStateEnum.PRESENTATION,
            render: (_, record) => record.stats && record.stats[InquiryStateEnum.PRESENTATION],
          },
          {
            title: InquiryStateTranslationEnum.NEGOTIATIONS,
            width: '13%',
            dataIndex: InquiryStateEnum.NEGOTIATIONS,
            key: InquiryStateEnum.NEGOTIATIONS,
            render: (_, record) => record.stats && record.stats[InquiryStateEnum.NEGOTIATIONS],
          },
          {
            title: InquiryStateTranslationEnum.CONTRACT,
            width: '13%',
            dataIndex: InquiryStateEnum.CONTRACT,
            key: InquiryStateEnum.CONTRACT,
            render: (_, record) => record.stats && record.stats[InquiryStateEnum.CONTRACT],
          },
          {
            title: InquiryStateTranslationEnum.END,
            width: '13%',
            dataIndex: InquiryStateEnum.END,
            key: InquiryStateEnum.END,
            render: (_, record) => record.stats && record.stats[InquiryStateEnum.END],
          },
        ]
      : [];

  return [
    {
      title: type === 'lead' ? 'Աշխատակից' : 'Բրոքեր',
      width: '13%',
      dataIndex: PerformanceCommonFildsEnum.NAME,
      key: PerformanceCommonFildsEnum.NAME,
    },
    {
      title: 'Ընդհանուր',
      width: '13%',
      dataIndex: PerformanceCommonFildsEnum.TOTAL,
      key: PerformanceCommonFildsEnum.TOTAL,
      render: (_, record) =>
        record.stats &&
        calculateTotal({
          data: Object.values(record.stats),
        }),
    },
    {
      title: 'Նոր',
      width: '13%',
      dataIndex: StatusEnum.NEW,
      key: StatusEnum.NEW,
      render: (_, record) => record.stats && record.stats[StatusEnum.NEW],
    },
    ...lead(),
    ...property(),
    ...inquiry(),
  ];
};
