import {takeEvery, all, call, put} from 'redux-saga/effects';
import {push} from 'redux-first-history';
import qs from 'query-string';

import {TActionSettings, TServerResponseError} from 'types/common';
import {TLead} from 'types/api';
import {addLeadService, getLeadByIdService, getLeadsService, updateLeadService} from 'services/leads';
import {RoutesEnum} from 'router/routes.enum';
import {SubmitModesEnum} from 'enums';
import {clearFromEmptyKeys} from 'utils';
import {getByIdFn} from 'store/core/sagas';

import {
  addLeadAction,
  clearCurrentLeadAction,
  clearLeadsAction,
  clearLeadsErrorsAction,
  getLeadByIdAction,
  saveLeadsFilterPresetsAction,
  searchLeadsAction,
  hasUnsavedChangesAction,
  updateLeadAction,
} from './actions';
import {
  setErrors,
  setLoading,
  setLeads,
  setCurrentLead,
  setLeadsCount,
  setLeadsFilterPresets,
  setHasUnsavedChanges,
} from './slice-leads';

const addLeadSaga = function* ({
  payload: {...payload},
  settings: {mode, onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  try {
    const {data}: {data: {success: any; error: TServerResponseError}} = yield call(addLeadService, payload);

    const params = {type: payload.type, action: payload.action};
    const query = qs.stringify(clearFromEmptyKeys(params));

    if (data.error) {
      throw data.error;
    } else {
      yield put(setErrors(null));
      yield put(setCurrentLead(data.success));

      onFulfilled && onFulfilled();

      if (mode === SubmitModesEnum.SEND) {
        yield put(push(`${RoutesEnum.LEADS}?${query}`));
      }
    }
  } catch (e) {
    console.error(e);
    yield put(setErrors(e as TServerResponseError));
    onReject && onReject(e);
  }
};

const updateLeadSaga = function* ({
  payload: {...payload},
  settings: {filterPresets, allowRedirect = true, onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  try {
    const {data}: {data: {success: any; error: TServerResponseError}} = yield call(updateLeadService, payload);

    if (data.error) {
      throw data.error;
    } else {
      const params = {type: payload.type, action: payload.action};
      const query = qs.stringify(
        clearFromEmptyKeys(filterPresets && Object.keys(filterPresets).length ? filterPresets! : params)
      );

      yield put(setErrors(null));
      yield put(setCurrentLead(data.success));
      yield put(push(`${RoutesEnum.LEADS}${allowRedirect ? `?${query}` : ''}`));

      onFulfilled && onFulfilled();
    }
  } catch (e) {
    console.error(e);
    yield put(setErrors(e as TServerResponseError));
    onReject && onReject(e);
  }
};

const searchLeadsSaga = function* ({
  payload: {...payload},
  settings: {isWithoutLoading = false, withCount = true, onFulfilled, onReject},
}: {payload: {offset?: number; length?: number}; settings: TActionSettings} & {type: string}) {
  yield !isWithoutLoading && put(setLoading(true));

  try {
    const response: {data: TLead[]} = yield call(getLeadsService, payload);

    yield put(setLeads(response.data));

    if (withCount) {
      const count: {data: number} = yield call(getLeadsService, {...payload, ...{cnt: ''}});

      yield put(setLeadsCount(count.data));
    }

    onFulfilled && onFulfilled();
  } catch (e) {
    console.error(e);
    yield put(setErrors(e as TServerResponseError));
    onReject && onReject(e);
  } finally {
    yield !isWithoutLoading && put(setLoading(false));
  }
};

const getLeadByIdSaga = function* ({payload: {...payload}}: {payload: {id?: number}} & {type: string}) {
  yield getByIdFn<TLead>({
    actions: {loading: setLoading, error: setErrors, success: setCurrentLead},
    service: getLeadByIdService,
    payload,
    settings: {},
  });
};

const saveLeadsFilterPresetsSaga = function* ({payload}: {payload: any} & {type: string}) {
  yield put(setLeadsFilterPresets(payload));
};

const clearLeadsErrorsSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setErrors(null));
};

const hasUnsavedChangesSaga = function* ({payload}: {payload: boolean} & {type: string}) {
  yield put(setHasUnsavedChanges(payload));
};

const clearLeadsSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setLeads([]));
};

const clearCurrentLeadSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setCurrentLead(null));
};

export const leadsSaga = function* () {
  yield all([
    takeEvery(searchLeadsAction().type, searchLeadsSaga),
    takeEvery(getLeadByIdAction().type, getLeadByIdSaga),
    takeEvery(addLeadAction().type, addLeadSaga),
    takeEvery(updateLeadAction().type, updateLeadSaga),
    takeEvery(saveLeadsFilterPresetsAction().type, saveLeadsFilterPresetsSaga),
    takeEvery(hasUnsavedChangesAction().type, hasUnsavedChangesSaga),
    takeEvery(clearLeadsErrorsAction().type, clearLeadsErrorsSaga),
    takeEvery(clearLeadsAction().type, clearLeadsSaga),
    takeEvery(clearCurrentLeadAction().type, clearCurrentLeadSaga),
  ]);
};
