import {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {ScreenProperties} from 'components/screens/properties';
import {clearCurrentInquiryAction, clearInquiryBookmarkAction} from 'store/inquiries/actions';

export const PageProperties = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearCurrentInquiryAction());
    dispatch(clearInquiryBookmarkAction());
  }, [dispatch]);

  return <ScreenProperties />;
};
