import {memo} from 'react';

import {TInquiryComment} from 'types/api';
import {PCommentItem} from 'components/primitives/p-comment-item';

const CInquiryEventComments = ({record}: any) => {
  const {comments} = record;

  return <div>{comments?.map((comment: TInquiryComment) => <PCommentItem key={comment.id} entity={comment} />)}</div>;
};

export const CInquiryEventCommentsMemoized = memo(CInquiryEventComments);
