import {memo, useCallback, useState} from 'react';
import {Popconfirm, Skeleton} from 'antd';
import {useDispatch} from 'react-redux';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';

import {refreshPropertyPriceAction} from 'store/properties/actions';
import {PDatepicker} from 'components/primitives/p-datepicker';
import {formatDate, formatTime} from 'utils';
import {FormatsEnum, SeparatorEnum} from 'enums';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {useToast} from 'hooks';

import {ICRefreshCommentProps} from './c-price-refresh.types';

const CPriceRefresh = ({children, property, ...props}: ICRefreshCommentProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {showError, contextHolder} = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refreshDate, setRefreshDate] = useState<any>(dayjs());

  const onRefresh = useCallback(() => {
    const data = {
      property: property.id,
      date: `${formatDate({
        value: refreshDate,
        format: FormatsEnum.REVERSE,
        separator: SeparatorEnum.HYPHEN,
      })} ${formatTime({value: new Date()})}`,
    };

    setIsLoading(true);

    dispatch(
      refreshPropertyPriceAction(data, {
        onFulfilled: () => setIsLoading(false),
        onReject: () => {
          setIsLoading(false);
          showError({message: fieldsMessageTranslations.commonErrors});
        },
      })
    );
  }, [property, refreshDate, showError, dispatch]);

  const handleRefreshDate = useCallback((value: any) => {
    setRefreshDate(value);
  }, []);

  return (
    <>
      <Popconfirm
        {...props}
        icon={null}
        description={
          <div className="mt-[14px] mb-[5px]">
            <PDatepicker
              label={t('form.price')}
              defaultValue={refreshDate}
              onChange={value => handleRefreshDate(value)}
            />
          </div>
        }
        onConfirm={onRefresh}
        cancelText={t('actions.close')}
        okText={t('actions.confirm')}
        okButtonProps={{disabled: !refreshDate}}
      >
        <>
          {isLoading ? (
            <Skeleton.Button shape="default" size="small" className="min-w-[90px]" block active />
          ) : (
            children
          )}
        </>
      </Popconfirm>
      {contextHolder}
    </>
  );
};

export const CPriceRefreshMemoized = memo(CPriceRefresh);
