import {takeEvery, all, call, put} from 'redux-saga/effects';
import {push} from 'redux-first-history';
import qs from 'query-string';

import {TActionSettings, TServerResponseError} from 'types/common';
import {TBuilding} from 'types/api';
import {
  addBuildingService,
  getBuildingByIdService,
  getBuildingsService,
  updateBuildingService,
} from 'services/buildings';
import {RoutesEnum} from 'router/routes.enum';
import {clearFromEmptyKeys} from 'utils';
import {getByIdFn} from 'store/core/sagas';

import {
  addBuildingAction,
  clearBuildingErrorsAction,
  clearBuildingsAction,
  clearCurrentBuildingAction,
  getBuildingByIdAction,
  getBuildingsAction,
  hasUnsavedChangesAction,
  saveBuildingFilterPresetsAction,
  updateBuildingAction,
} from './actions';
import {
  setErrors,
  setLoading,
  setBuildings,
  setCurrentBuilding,
  setBuildingsCount,
  setBuildingFilterPresets,
  setHasUnsavedChanges,
} from './slice-buildings';

const addBuildingSaga = function* ({
  payload: {...payload},
  settings: {property, allowRedirect = true, onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  try {
    const {data}: {data: {success: any; error: TServerResponseError}} = yield call(addBuildingService, payload);

    const params = {type: payload.type, subtype: payload.subtype};
    const query = qs.stringify(clearFromEmptyKeys(params));

    if (data.error) {
      throw data.error;
    } else {
      yield put(setErrors(null));
      yield put(setCurrentBuilding(data.success));

      if (allowRedirect) {
        yield property
          ? put(push(`${RoutesEnum.PROPERTIES}/update/${property}`))
          : put(push(`${RoutesEnum.BUILDINGS}?${query}`));
      }

      onFulfilled && onFulfilled();
    }
  } catch (e) {
    console.error(e);

    yield put(setErrors(e as TServerResponseError));

    onReject && onReject(e);
  }
};

const updateBuildingSaga = function* ({
  payload: {...payload},
  settings: {property, allowRedirect = true, filterPresets, onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  try {
    const {data}: {data: {success: any; error: TServerResponseError}} = yield call(updateBuildingService, payload);

    const params = {type: payload.type};
    const query = qs.stringify(clearFromEmptyKeys(Object.keys(filterPresets!).length ? filterPresets! : params));

    if (data.error) {
      throw data.error;
    } else {
      yield put(setErrors(null));
      yield put(setCurrentBuilding(data.success));

      if (allowRedirect) {
        yield property
          ? put(push(`${RoutesEnum.PROPERTIES}/view/${property}`))
          : put(push(`${RoutesEnum.BUILDINGS}?${query}`));
      }

      onFulfilled && onFulfilled();
    }
  } catch (e) {
    console.error(e);

    yield put(setErrors(e as TServerResponseError));

    onReject && onReject(e);
  }
};

const getBuildingsSaga = function* ({
  payload: {...payload},
  settings: {withCount = true, onFulfilled, onReject},
}: {payload: {offset?: number; length?: number}; settings: TActionSettings} & {type: string}) {
  yield put(setLoading(true));

  try {
    const response: {data: TBuilding[]} = yield call(getBuildingsService, payload);

    yield put(setBuildings(response.data));

    if (withCount) {
      const count: {data: number} = yield call(getBuildingsService, {...payload, ...{cnt: ''}});

      yield put(setBuildingsCount(count.data));
    }

    onFulfilled && onFulfilled();
  } catch (e) {
    console.error(e);
    yield put(setErrors(e as TServerResponseError));
    onReject && onReject(e);
  } finally {
    yield put(setLoading(false));
  }
};

const getBuildingByIdSaga = function* ({
  payload: {...payload},
  settings: {...settings},
}: {payload: {id?: number}; settings: TActionSettings} & {type: string}) {
  yield getByIdFn<TBuilding>({
    actions: {loading: setLoading, error: setErrors, success: setCurrentBuilding},
    service: getBuildingByIdService,
    payload,
    settings,
  });
};

const saveBuildingFilterPresetsSaga = function* ({payload}: {payload: any} & {type: string}) {
  yield put(setBuildingFilterPresets(payload));
};

const hasUnsavedChangesSaga = function* ({payload}: {payload: boolean} & {type: string}) {
  yield put(setHasUnsavedChanges(payload));
};

const clearBuildingsErrorsSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setErrors(null));
};

const clearBuildingsSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setBuildings([]));
};

const clearCurrentBuildingSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setCurrentBuilding(null));
};

export const buildingSaga = function* () {
  yield all([
    takeEvery(addBuildingAction().type, addBuildingSaga),
    takeEvery(updateBuildingAction().type, updateBuildingSaga),
    takeEvery(getBuildingsAction().type, getBuildingsSaga),
    takeEvery(getBuildingByIdAction().type, getBuildingByIdSaga),
    takeEvery(saveBuildingFilterPresetsAction().type, saveBuildingFilterPresetsSaga),
    takeEvery(hasUnsavedChangesAction().type, hasUnsavedChangesSaga),
    takeEvery(clearBuildingErrorsAction().type, clearBuildingsErrorsSaga),
    takeEvery(clearBuildingsAction().type, clearBuildingsSaga),
    takeEvery(clearCurrentBuildingAction().type, clearCurrentBuildingSaga),
  ]);
};
