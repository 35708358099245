import {ColumnsType} from 'antd/es/table';
import {Col, Row, Skeleton, Tag, Tooltip} from 'antd';
import {Link} from 'react-router-dom';

import {Icon} from 'assets/icons';
import {ILeadFields} from 'components/forms/lead/form-lead.interfaces';
import {LeadFieldsEnum, PersonFieldsEnum} from 'components/forms/lead/form-lead.enums';
import {FlagsTranslationEnum, StatusTranslationEnum} from 'enums/translations';
import {ActionModesEnum, OptGroupsEnum, StatusEnum, SortOrderEnum, SortFieldsEnum, PageNamesEnum} from 'enums';
import {getTranslation, formatDate, injectCustomerModeTags} from 'utils';
import {RoutesEnum} from 'router/routes.enum';
import {PSelect} from 'components/primitives/p-select';
import {flagOptions} from 'constants/select-options';
import {ITableColumnCommonProps} from 'types/common';
import {CTextCopy} from 'components/containers/c-text-copy';

interface ITableColumns extends ILeadFields {}

export const columns = ({
  catalog,
  flagRef,
  isFlagEditLoading,
  flagEntity,
  isFlagSelectVisibile,
  sortOptions,
  translation,
  handleFlagSelectVisibility,
  onFlagEdit,
}: ITableColumnCommonProps): ColumnsType<ITableColumns> => [
  {
    width: '15%',
    title: translation('form.status'),
    dataIndex: LeadFieldsEnum.STATUS,
    key: LeadFieldsEnum.STATUS,
    render: (_, record) => <div>{getTranslation(StatusTranslationEnum, record.status!)}</div>,
  },
  {
    width: '13%',
    title: translation('form.flag'),
    dataIndex: LeadFieldsEnum.FLAG,
    key: LeadFieldsEnum.FLAG,
    render: (_, record) => (
      <div ref={flagRef}>
        {flagEntity === record.id && isFlagEditLoading ? (
          <Skeleton.Button shape="default" size="small" block active />
        ) : isFlagSelectVisibile &&
          flagEntity === record.id &&
          record.status !== StatusEnum.FINALIZED &&
          record.status !== StatusEnum.CANCELED ? (
          <PSelect
            className="max-w-[150px]"
            defaultValue={record.flag}
            size="small"
            open={!isFlagEditLoading}
            options={flagOptions}
            allowClear={false}
            onChange={val => {
              onFlagEdit &&
                onFlagEdit({
                  ...record,
                  lead: record.id!,
                  flag: val,
                  source: record.source!,
                  status: record.status!,
                  type: record.type!,
                });
            }}
          />
        ) : (
          <div
            onClick={e => handleFlagSelectVisibility && handleFlagSelectVisibility(e, record.id!)}
            className={
              record.status !== StatusEnum.FINALIZED && record.status !== StatusEnum.CANCELED ? 'cursor-pointer' : ''
            }
          >
            {record.flag ? getTranslation(FlagsTranslationEnum, record.flag) : '-'}
          </div>
        )}
      </div>
    ),
  },
  {
    title: translation('form.source'),
    key: LeadFieldsEnum.SOURCE,
    dataIndex: LeadFieldsEnum.SOURCE,
    render: (_, record) => <div>{catalog![OptGroupsEnum.SOURCE]?.find(o => o.value === record.source)?.label}</div>,
  },
  {
    title: translation('form.firstName'),
    dataIndex: PersonFieldsEnum.NAME,
    key: PersonFieldsEnum.NAME,
    render: (_, record) =>
      record.persons?.length ? (
        <Link
          className="text-inherit"
          to={`${RoutesEnum.PERSONS}/${ActionModesEnum.UPDATE}/${
            record.persons[0].id
          }${`?pageFrom=${PageNamesEnum.LEADS}`}`}
        >
          {record.persons ? record.persons[0]?.name : null}
        </Link>
      ) : (
        '-'
      ),
  },
  {
    width: '12%',
    title: translation('form.phone'),
    dataIndex: LeadFieldsEnum.PHONE,
    key: LeadFieldsEnum.PHONE,
    render: (_, record) =>
      record.persons?.length
        ? record.persons.map(p => p.phones.map(n => <CTextCopy key={`${record.id}-${n?.number}`} text={n?.number} />))
        : '-',
  },
  {
    title: translation('form.customerMode'),
    dataIndex: LeadFieldsEnum.MODE,
    key: LeadFieldsEnum.MODE,
    render: (_, record) => {
      return injectCustomerModeTags<ILeadFields>(record).map(({text, color}) => (
        <Tag key={`${record.id}-${text}`} color={color}>
          {text}
        </Tag>
      ));
    },
  },
  {
    title: translation('form.date'),
    dataIndex: SortFieldsEnum.LEAD_CREATED,
    key: LeadFieldsEnum.LEAD_CREATED,
    sorter: true,
    defaultSortOrder:
      sortOptions?.sort === LeadFieldsEnum.LEAD_CREATED && sortOptions?.asc
        ? SortOrderEnum.ASCEND
        : sortOptions?.sort === LeadFieldsEnum.LEAD_CREATED && sortOptions?.desc
        ? SortOrderEnum.DESCEND
        : undefined,
    sortDirections: [SortOrderEnum.ASCEND, SortOrderEnum.DESCEND, SortOrderEnum.ASCEND],
    render: (_, record) => <div>{formatDate({value: record?.created!})}</div>,
  },
  {
    title: translation('form.action'),
    key: 'action',
    align: 'right',
    render: (_, record) => {
      const telHref = record.persons?.length ? `tel:${record.persons[0]?.phones[0]?.number}` : undefined;

      return (
        <Row gutter={8} justify="end" align="middle" wrap={false}>
          <Col className="inline-flex">
            <Tooltip title={translation('actions.call')}>
              <a href={telHref} className="inline-flex">
                <Icon.Calling color="#292D32" width={23} height={23} cursor="pointer" />
              </a>
            </Tooltip>
          </Col>
          <Col className="inline-flex">
            <Tooltip title={translation('actions.view')}>
              <Link to={`${RoutesEnum.LEADS}/${ActionModesEnum.VIEW}/${record.id}`} className="inline-flex">
                <Icon.Eye color="#292D32" width={25} height={25} cursor="pointer" />
              </Link>
            </Tooltip>
          </Col>
          <Col className="inline-flex">
            {record.status === StatusEnum.CANCELED || record.status === StatusEnum.FINALIZED ? (
              <button type="button" className="default disabled inline-flex">
                <Icon.Edit color="#292D32" width={24} height={24} />
              </button>
            ) : (
              <Tooltip title={translation('actions.change')}>
                <Link to={`${RoutesEnum.LEADS}/${ActionModesEnum.UPDATE}/${record.id}`} className="inline-flex">
                  <Icon.Edit color="#292D32" width={24} height={24} cursor="pointer" />
                </Link>
              </Tooltip>
            )}
          </Col>
        </Row>
      );
    },
  },
];
