import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import {LocalesEnum} from 'enums';

import hy from './locales/hy/translation.json';
import ru from './locales/ru/translation.json';

i18n.use(initReactI18next).init({
  lng: LocalesEnum.ARM,
  fallbackLng: LocalesEnum.ARM,
  debug: false,
  resources: {
    hy: {
      translation: hy,
    },
    ru: {
      translation: ru,
    },
  },
});
