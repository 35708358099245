export enum PropertyActionsTranslationEnum {
  APARTMENT_SALE = 'Բնակարանի վաճառք',
  APARTMENT_RENT = 'Բնակարանի վարձակալություն',
  COMMERCIAL_SALE = 'Կոմերցիոն վաճառք',
  COMMERCIAL_RENT = 'Կոմերցիոն վարձակալություն',
  HOUSE_SALE = 'Տան վաճառք',
  HOUSE_RENT = 'Տան վարձակալություն',
  LAND_SALE = 'Հողատարածքի վաճառք',
  LAND_RENT = 'Հողատարածքի վարձակալություն',
  PARKING_SALE = 'Կայանատեղիի վաճառք',
  PARKING_RENT = 'Կայանատեղիի վարձակալություն',
}
