import {Col, Row} from 'antd';
import {useTranslation} from 'react-i18next';

import {Image} from 'assets/images';
import {FormLogin} from 'components/forms/login';
import {PCardWrapper} from 'components/primitives/p-card-wrapper';
import {PPageTitle} from 'components/primitives/p-page-title';

import {CSS} from './login.cssconfig';

export const PageLogin = () => {
  const {t} = useTranslation();

  return (
    <main>
      <Row justify="center">
        <Col>
          <div className={CSS.loginWrapper}>
            <a href="/" target="_blank" rel="roreferer" className={CSS.logoLink}>
              <img src={Image.brandLogo} alt="Kaskad Realty" />
            </a>
          </div>
          <PCardWrapper animate padding="md" className="max-w-[465px]">
            <PPageTitle text={t('pages.login.title')} align="center" />
            <FormLogin />
          </PCardWrapper>
        </Col>
      </Row>
    </main>
  );
};
