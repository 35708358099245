import {memo} from 'react';
import {Col, Row, Tooltip} from 'antd';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {PInfoBar} from 'components/primitives/p-info-bar';
import {formatDate} from 'utils';
import {CEnableProperty} from 'components/containers/c-enable-property';
import {Icon} from 'assets/icons';
import {appProfileSelector} from 'store/app/selectors';
import {RolesEnum} from 'enums';

const PFrozenProperty = ({values, className}: {values: any; className?: string}) => {
  const {t} = useTranslation();
  const profile = useSelector(appProfileSelector);

  return (
    <Row className={`rounded-[16px] px-4 bg-info-secondary shadow-sm flex justify-between ${className}`}>
      <Col span={5}>
        <PInfoBar className="!border-0 !flex gap-1 pb-0" value={<b>{t('common.onholdTo')}։</b>}></PInfoBar>
        <PInfoBar className="!border-0 !flex gap-1 pt-2" value={formatDate({value: values?.disabled_to})}></PInfoBar>
      </Col>
      <Col span={16}>
        <PInfoBar className="!border-0 !flex gap-1 pb-0" value={<b>{t('form.reason')}</b>}></PInfoBar>
        <PInfoBar className="!border-0 !flex gap-1 pt-2" value={values?.disabled_comment}></PInfoBar>
      </Col>
      {profile?.type !== RolesEnum.MARKETING && (
        <div className="self-center">
          <CEnableProperty title={t('prompts.reactivate')} propertyId={values?.id} className="inline-flex">
            <Tooltip title={t('actions.reactivate')} placement="left">
              <Icon.Refresh width={24} height={24} cursor="pointer" className="hover:text-primary transition-all" />
            </Tooltip>
          </CEnableProperty>
        </div>
      )}
    </Row>
  );
};

export const PFrozenPropertyMemoized = memo(PFrozenProperty);
