import {ColumnsType} from 'antd/es/table';
import {Col, Row} from 'antd';

import {formatDate} from 'utils';
import {Icon} from 'assets/icons';
import {CHoldRemove} from 'components/containers/c-hold-remove';
import {IBookingFields} from 'components/forms/booking/form-booking.interfaces';
import {BookingFieldsEnum} from 'components/forms/booking/form-booking.enums';
import {ITableColumnCommonProps} from 'types/common';

interface ITableColumns extends IBookingFields {}

export const bookingsColumns = ({
  onDelete,
  propertyId,
  hideActions = false,
  profile,
  translation,
}: ITableColumnCommonProps): ColumnsType<ITableColumns> => {
  const actions = (): ColumnsType<ITableColumns> =>
    !hideActions
      ? [
          {
            title: translation('form.action'),
            key: 'action',
            className: 'text-right',
            width: '140px',
            render: (_, record) =>
              !hideActions && (
                <Row gutter={8} justify="end" align="middle">
                  <Col className="inline-flex">
                    <CHoldRemove
                      title={translation('prompts.delete')}
                      propertyId={propertyId}
                      holdId={record.id}
                      className="inline-flex"
                    >
                      <Icon.CloseSquare color="#292D32" width={24} height={24} cursor="pointer" />
                    </CHoldRemove>
                  </Col>
                </Row>
              ),
          },
        ]
      : [];

  return [
    {
      title: translation('form.date'),
      dataIndex: BookingFieldsEnum.DATE,
      key: BookingFieldsEnum.DATE,
      render: (_, record) => (
        <>
          {formatDate({value: record.start!})} - {formatDate({value: record.end!})}
        </>
      ),
    },
    {
      title: translation('form.comments'),
      dataIndex: BookingFieldsEnum.INFO,
      key: BookingFieldsEnum.INFO,
    },
    ...actions(),
  ];
};
