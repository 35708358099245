export enum CustomerTypesEnum {
  SALE = 'SALE',
  RENT = 'RENT',
  CUSTOMER = 'CUSTOMER',
  RENTER = 'RENTER',
  OTHER = 'OTHER',
  ALL = 'ALL',

  LEAD = 'LEAD',
  PROPERTY = 'PROPERTY',
  BUILDING = 'BUILDING',
  INQUIRY = 'INQUIRY',
}
