import {memo} from 'react';
import {DatePicker} from 'antd';

import {Icon} from 'assets/icons';
import {PFormLabel} from 'components/primitives/p-form-label';
import {dateDefaultFormat} from 'constants/date-formats';

import {PDatepickerProps} from './p-datepicker.types';

const PDatepicker = ({label, id, name, size = 'large', ...props}: PDatepickerProps) => {
  return (
    <div className="relative">
      {label && <PFormLabel text={label} id={id} />}
      <DatePicker
        {...props}
        id={id}
        size={size}
        allowClear
        suffixIcon={<Icon.Calendar width={20} height={20} />}
        clearIcon={<Icon.CloseCircle width={20} height={20} />}
        className="w-full"
        format={dateDefaultFormat}
        placeholder="Ընտրել"
      />
    </div>
  );
};

export const PDatepickerMemoized = memo(PDatepicker);
