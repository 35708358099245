import {memo, useCallback, useEffect, useRef, useState} from 'react';
import {useYMaps} from '@pbe/react-yandex-maps';

import {CENTER_DEFAULT} from 'constants/map';
import {useWindowResize} from 'hooks';
import {AddressFieldsEnum} from 'components/forms/address/form-address.enums';

import {CAddressHandleProps, CMapProps} from './c-map.types';
import {CSS} from './c-map.cssconfig';

const CMap = ({center = CENTER_DEFAULT, zoom, initialAddress, mode, setAddressInfo}: CMapProps) => {
  const dimensions = useWindowResize();
  const [address, setAddress] = useState({});
  const [map, setMap] = useState<any>();
  const [search, setSearch] = useState<any>();
  const mapRef = useRef(null);
  const ymaps = useYMaps(['Map', 'geocode', 'control.FullscreenControl', 'control.SearchControl', 'Placemark']);

  const handleAddress = useCallback(
    ({e, coords, map, searchControl, isSearch, isInitial = false}: CAddressHandleProps) => {
      if (isSearch) {
        map?.geoObjects.removeAll();
      } else {
        map?.geoObjects.removeAll();
        searchControl?.clear();
      }

      ymaps?.geocode(coords).then((res: any) => {
        const firstGeoObject = res.geoObjects.get(0);

        if (!isInitial) {
          setAddress({
            [AddressFieldsEnum.COUNTRY_CODE]: firstGeoObject.getCountryCode(),
            [AddressFieldsEnum.COUNTRY_NAME]: firstGeoObject.getCountry(),
            [AddressFieldsEnum.REGION_NAME]: firstGeoObject.getAdministrativeAreas()[0],
            [AddressFieldsEnum.SETTLEMENT_NAME]: firstGeoObject.getLocalities()[0],
            [AddressFieldsEnum.STREET_NAME]: firstGeoObject.getThoroughfare(),
            [AddressFieldsEnum.LAT]: coords[0],
            [AddressFieldsEnum.LNG]: coords[1],
            [AddressFieldsEnum.NUMBER]: firstGeoObject.getPremiseNumber(),
            [AddressFieldsEnum.COMBINED]: firstGeoObject.getAddressLine(),
          });
        }

        if (!isSearch) {
          const placemark = new ymaps.Placemark(
            coords,
            {
              balloonContentBody: ['<address>', firstGeoObject.getAddressLine(), '</address>'].join(''),
            },
            {}
          );

          map.geoObjects.add(placemark);
        }

        map.setCenter([coords[0], coords[1]]);
      });

      ymaps?.geocode(coords, {kind: 'district'}).then((res: any) => {
        const firstGeoObject = res.geoObjects.get(0);
        const district =
          firstGeoObject?.properties.getAll().metaDataProperty.GeocoderMetaData.AddressDetails.Country
            .AdministrativeArea.Locality.DependentLocality.DependentLocalityName;

        if (!isInitial) {
          setAddress(prev => ({...prev, [AddressFieldsEnum.DISTRICT_NAME]: district}));
        }

        if (!isSearch) {
          const placemark = new ymaps.Placemark(
            coords,
            {
              balloonContentBody: ['<address>', firstGeoObject.getAddressLine(), '</address>'].join(''),
            },
            {}
          );

          map.geoObjects.add(placemark);
        }

        map.setCenter([coords[0], coords[1]]);
      });
    },
    [ymaps]
  );

  useEffect(() => {
    setAddressInfo && setAddressInfo(address);
  }, [address, setAddressInfo]);

  useEffect(() => {
    if (dimensions) {
      map?.container.fitToViewport();
    }
  }, [dimensions, map]);

  useEffect(() => {
    if (!ymaps || !mapRef.current) {
      return;
    }

    const map = new ymaps.Map(
      mapRef.current!,
      {
        center,
        zoom,
      },
      {autoFitToViewport: 'always'}
    );

    const fullscreenControl = new ymaps.control.FullscreenControl();

    const searchControl = new ymaps.control.SearchControl({
      options: {
        float: 'right',
        fitMaxWidth: true,
        maxWidth: 460,
      },
    });

    searchControl.events.add('resultselect', e => {
      let index = e.get('index');

      searchControl.getResult(index).then((res: any) => {
        handleAddress({e, coords: res.geometry.getCoordinates(), map, isSearch: true});
      });
    });

    map.events.add('click', (e: any) => {
      const coords = e.get('coords');

      if (mode === 'create') {
        handleAddress({e, coords, map, searchControl, isSearch: false});
      }
    });

    map.controls.add(fullscreenControl);

    if (mode === 'create') {
      map.controls.add(searchControl);
    }

    setMap(map);
    setSearch(searchControl);
  }, [ymaps, mode, initialAddress, center, zoom, handleAddress, setAddressInfo]);

  useEffect(() => {
    if (!initialAddress) return;

    handleAddress({
      e: undefined,
      coords: [initialAddress?.lat, initialAddress?.lng],
      map,
      searchControl: search,
      isSearch: false,
      isInitial: true,
    });
  }, [initialAddress, map, search, handleAddress]);

  return (
    <div>
      <div ref={mapRef} className={CSS.mapContainer} />
    </div>
  );
};

export const CMapMemoized = memo(CMap);
