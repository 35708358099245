import {ActionModesEnum} from 'enums';

export const ActionModeHero = {
  [ActionModesEnum.ADD]: 'Ավելացնել',
  [ActionModesEnum.CREATE]: 'Ստեղծել',
  [ActionModesEnum.UPDATE]: 'Փոփոխել',
  [ActionModesEnum.VIEW]: 'Դիտել',
  [ActionModesEnum.DELETE]: 'Ջնջել',
  [ActionModesEnum.CALL]: 'Զանգահարել',
  [ActionModesEnum.CHOOSE]: 'Ընտրել',
  [ActionModesEnum.CONTINUE]: 'Շարունակել',
  [ActionModesEnum.SAVE]: 'Պահպանել',
} as const;
