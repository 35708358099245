import {ColumnsType} from 'antd/es/table';
import {Col, Popover, Row, Tooltip} from 'antd';
import {Link} from 'react-router-dom';

import {Icon} from 'assets/icons';
import {ILeadFields} from 'components/forms/lead/form-lead.interfaces';
import {LeadFieldsEnum, PersonFieldsEnum} from 'components/forms/lead/form-lead.enums';
import {ActionModesEnum, PropertyTypesEnum} from 'enums';
import {formatDate, formatNumber, getTranslation} from 'utils';
import {RoutesEnum} from 'router/routes.enum';
import {currencySymbols} from 'constants/currency-symbols';
import {ITableColumnCommonProps} from 'types/common';
import {CTextCopy} from 'components/containers/c-text-copy';

interface ITableColumns extends ILeadFields {}

export const leadsColumns = ({propertyType, translation}: ITableColumnCommonProps): ColumnsType<ITableColumns> => {
  const area = (): ColumnsType<ITableColumns> =>
    propertyType === PropertyTypesEnum.HOUSE
      ? [
          {
            title: translation('form.area'),
            key: LeadFieldsEnum.LIVING_AREA,
            dataIndex: LeadFieldsEnum.LIVING_AREA,
            render: (_, record) => (
              <div>{record?.living_area && `${record?.living_area} ${translation('shorts.squareMeter')}`}</div>
            ),
          },
          {
            title: translation('form.landArea'),
            key: LeadFieldsEnum.LAND_AREA,
            dataIndex: LeadFieldsEnum.LAND_AREA,
            render: (_, record) => (
              <div>{record?.land_area && `${record?.land_area} ${translation('shorts.squareMeter')}`}</div>
            ),
          },
        ]
      : propertyType === PropertyTypesEnum.LAND
      ? [
          {
            title: translation('form.area'),
            key: LeadFieldsEnum.SQUARE_METRE,
            dataIndex: LeadFieldsEnum.SQUARE_METRE,
            render: (_, record) => (
              <div>
                {record?.square_metre && `${record?.square_metre} ${translation('shorts.squareMeter')}`}{' '}
                {record?.hectare && `/ ${record?.hectare} ${translation('shorts.hectare')}`}
              </div>
            ),
          },
        ]
      : [
          {
            title: translation('form.area'),
            key: LeadFieldsEnum.AREA,
            dataIndex: LeadFieldsEnum.AREA,
            render: (_, record) => <div>{record?.area && `${record?.area} ${translation('shorts.squareMeter')}`}</div>,
          },
        ];

  const floor = (): ColumnsType<ITableColumns> =>
    propertyType === PropertyTypesEnum.HOUSE
      ? [
          {
            title: translation('form.floors'),
            key: LeadFieldsEnum.FLOORS,
            dataIndex: LeadFieldsEnum.FLOORS,
          },
        ]
      : propertyType === PropertyTypesEnum.APARTMENT
      ? [
          {
            title: translation('form.floor'),
            key: LeadFieldsEnum.FLOOR,
            dataIndex: LeadFieldsEnum.FLOOR,
          },
        ]
      : propertyType === PropertyTypesEnum.COMMERCIAL
      ? [
          {
            title: translation('form.entranceFloor'),
            key: LeadFieldsEnum.ENTRANCE_FLOOR,
            dataIndex: LeadFieldsEnum.ENTRANCE_FLOOR,
          },
        ]
      : [];

  const room = (): ColumnsType<ITableColumns> =>
    propertyType === PropertyTypesEnum.APARTMENT
      ? [
          {
            title: translation('form.room'),
            key: LeadFieldsEnum.ROOMS,
            dataIndex: LeadFieldsEnum.ROOMS,
            render: (_, record) => <div>{record.rooms}</div>,
          },
        ]
      : [];

  return [
    {
      title: translation('form.date'),
      dataIndex: LeadFieldsEnum.CREATED,
      key: LeadFieldsEnum.CREATED,
      render: (_, record) => <div>{formatDate({value: record?.created!})}</div>,
    },
    {
      title: translation('form.address'),
      dataIndex: LeadFieldsEnum.ADDRESS,
      key: LeadFieldsEnum.ADDRESS,
    },
    {
      title: translation('form.firstName'),
      dataIndex: PersonFieldsEnum.NAME,
      key: PersonFieldsEnum.NAME,
      render: (_, record) =>
        record.persons?.length ? <div>{record.persons ? record.persons[0]?.name : null}</div> : '-',
    },
    {
      title: translation('form.phone'),
      dataIndex: LeadFieldsEnum.PHONE,
      key: LeadFieldsEnum.PHONE,
      render: (_, record) =>
        record.persons?.length
          ? record.persons.map(p => p.phones.map(n => <CTextCopy key={`${record.id}-${n?.number}`} text={n?.number} />))
          : '-',
    },
    ...area(),
    ...floor(),
    ...room(),
    {
      title: translation('form.price'),
      key: LeadFieldsEnum.TOTAL_PRICE_SALE,
      dataIndex: LeadFieldsEnum.TOTAL_PRICE_SALE,
      render: (_, record) => (
        <Popover
          content={`${getTranslation(currencySymbols, record.currency_sale!)?.props.children} ${formatNumber(
            record.unit_price_sale!
          )} ${translation('shorts.squareMeter')}`}
        >
          {record.currency_sale && getTranslation(currencySymbols, record.currency_sale!)?.props.children}{' '}
          {formatNumber(record.total_price_sale!)}
        </Popover>
      ),
    },
    {
      title: translation('form.action'),
      key: 'action',
      width: '140px',
      render: (_, record) => (
        <Row gutter={8} justify="end" align="middle">
          <Col className="inline-flex">
            <Tooltip title={translation('actions.continue')}>
              <Link to={`${RoutesEnum.PROPERTIES}/${ActionModesEnum.CREATE}/${record.id}`} className="inline-flex">
                <Icon.AddCircle color="#292D32" width={25} height={25} cursor="pointer" />
              </Link>
            </Tooltip>
          </Col>
        </Row>
      ),
    },
  ];
};
