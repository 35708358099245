import {TActionSettings} from 'types/common';

export const addLeadAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/info/addLeadAction',
  payload,
  settings: settings || {},
});

export const updateLeadAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/info/updateLeadAction',
  payload,
  settings: settings || {},
});

export const searchLeadsAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'shared/searchLeadsAction',
  payload,
  settings: settings || {},
});

export const getLeadByIdAction = (payload?: {id?: number | string}, settings?: TActionSettings) => ({
  type: 'shared/getLeadIdAction',
  payload,
  settings: settings || {},
});

export const saveLeadsFilterPresetsAction = (payload?: any) => ({
  type: 'leads/saveLeadsFilterPresetsAction',
  payload,
});

export const hasUnsavedChangesAction = (payload?: boolean) => ({
  type: 'leads/hasUnsavedChangesAction',
  payload,
});

export const clearLeadsErrorsAction = () => ({
  type: 'leads/clearLeadsErrorsAction',
});

export const clearLeadsAction = () => ({
  type: 'leads/clearLeadsAction',
});

export const clearCurrentLeadAction = () => ({
  type: 'leads/clearCurrentLeadAction',
});
