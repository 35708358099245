import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {TServerResponseError} from 'types/common';

import {BuildingsState} from './types';

const sliceBuildings = createSlice({
  name: 'buildings',
  initialState: {
    loading: false,
    error: null,
    buildings: [],
    buildingsCount: 0,
    currentBuilding: null,
    buildingFilterPresets: {},
    hasUnsavedChanges: false,
  },
  reducers: {
    setErrors(state: BuildingsState, {payload}: PayloadAction<TServerResponseError | null>) {
      state.error = payload;
    },
    setLoading(state: BuildingsState, {payload}: PayloadAction<BuildingsState['loading']>) {
      state.loading = payload;
    },
    setBuildings(state: BuildingsState, {payload}: PayloadAction<BuildingsState['buildings']>) {
      state.buildings = payload;
    },
    setBuildingsCount(state: BuildingsState, {payload}: PayloadAction<BuildingsState['buildingsCount']>) {
      state.buildingsCount = payload;
    },
    setCurrentBuilding(state: BuildingsState, {payload}: PayloadAction<BuildingsState['currentBuilding']>) {
      state.currentBuilding = payload;
    },
    setBuildingFilterPresets(state: BuildingsState, {payload}: PayloadAction<BuildingsState['buildingFilterPresets']>) {
      state.buildingFilterPresets = payload;
    },
    setHasUnsavedChanges(state: BuildingsState, {payload}: PayloadAction<BuildingsState['hasUnsavedChanges']>) {
      state.hasUnsavedChanges = payload;
    },
  },
});

export const {
  setErrors,
  setLoading,
  setBuildings,
  setBuildingsCount,
  setCurrentBuilding,
  setBuildingFilterPresets,
  setHasUnsavedChanges,
} = sliceBuildings.actions;

export const buildingsReducer = sliceBuildings.reducer;
