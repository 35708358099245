import {memo, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuidv4} from 'uuid';

import {PLightbox} from 'components/primitives/p-lightbox';
import {Image} from 'assets/images';
import {clearMediaAction} from 'store/app/actions';
import {clearCurrentPropertyAction, getPropertyByIdAction} from 'store/properties/actions';
import {propertyCurrentSelector} from 'store/properties/selectors';
import {injectMediaPath} from 'utils';

const CPropertyGallery = ({
  entityId,
  isLighboxVisible,
  onLightboxClose,
}: {
  entityId: number;
  isLighboxVisible: boolean;
  onLightboxClose: () => void;
}) => {
  const dispatch = useDispatch();
  const currentProperty = useSelector(propertyCurrentSelector);
  const [srcReservePath, setSrcReservePath] = useState<string[]>([]);
  const [lighboxGallery, setLighboxGallery] = useState<any[]>([]);

  useEffect(() => {
    if (entityId) {
      dispatch(clearCurrentPropertyAction());
      dispatch(clearMediaAction());

      dispatch(getPropertyByIdAction({id: entityId}, {isWithoutLoading: true}));
    }
  }, [entityId, dispatch]);

  useEffect(() => {
    const addons = currentProperty?.rooms || currentProperty?.floors;
    setLighboxGallery([]);

    if (addons?.length) {
      const images = addons?.map(({name, image_folder}) => {
        const files = image_folder?.files.map((item: any) => ({...item, caption: name}));

        return files || [];
      });
      const gallery = [...[Array.prototype.concat.apply([], images)], ...[currentProperty?.image_folder?.files || []]];

      setLighboxGallery(...[Array.prototype.concat.apply([], gallery)]);
    } else {
      setLighboxGallery(currentProperty?.image_folder?.files);
    }
  }, [currentProperty, dispatch]);

  return (
    <div className="hidden">
      {lighboxGallery && (
        <PLightbox
          showThumbnails={true}
          modalClose="clickOutside"
          open={isLighboxVisible}
          onClose={onLightboxClose}
          className="hidden"
          images={lighboxGallery}
        >
          {lighboxGallery?.map((img: any) => (
            <img
              key={`${uuidv4()}`}
              onError={() => {
                setSrcReservePath([...srcReservePath, img?.path]);
              }}
              src={srcReservePath.includes(img?.path) ? Image.noImage : injectMediaPath(img?.path)}
              width="100%"
              alt="pic"
              title={img?.title}
            />
          ))}
        </PLightbox>
      )}
    </div>
  );
};

export const CPropertyGalleryMemoized = memo(CPropertyGallery);
