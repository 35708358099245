import {Col, Form, Row, Space} from 'antd';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {PButton} from 'components/primitives/p-button';
import {PInput} from 'components/primitives/p-input';
import {PropertyTypesEnum, ContractTypesEnum, CustomerTypesEnum} from 'enums';
import {LeadFilterFieldsEnum} from 'components/forms/leads-filter/form-leads-filter.enums';
import {PNumericInput} from 'components/primitives/p-numeric-input';
import {LeadFieldsEnum} from 'components/forms/lead/form-lead.enums';
import {ILeadsFilterFields} from 'components/forms/leads-filter/form-leads-filter.interfaces';
import {PPhoneInput} from 'components/primitives/p-phone-input';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {IFilterProps} from 'types/common';
import {CSubmitButton} from 'components/containers/c-submit-button';
import {Icon} from 'assets/icons';
import {FormPricesFilter} from 'components/forms/prices-filter';

export const FormPropertyLeadsFilter = ({
  form,
  contractType,
  propertyType,
  values,
  initialValues,
  isButtonLoading,
  isFormSubmited,
  onSubmit,
  onReset,
}: IFilterProps<ILeadsFilterFields>) => {
  const {t} = useTranslation();

  const presets = useMemo(
    () => ({
      mode:
        contractType?.includes(ContractTypesEnum.SALE) &&
        !contractType?.includes(ContractTypesEnum.MONTHLY) &&
        !contractType?.includes(ContractTypesEnum.DAILY)
          ? CustomerTypesEnum.SALE
          : !contractType?.includes(ContractTypesEnum.SALE) &&
            (contractType?.includes(ContractTypesEnum.MONTHLY) || contractType?.includes(ContractTypesEnum.DAILY))
          ? ContractTypesEnum.RENT
          : contractType?.includes(ContractTypesEnum.SALE) &&
            (contractType?.includes(ContractTypesEnum.MONTHLY) || contractType?.includes(ContractTypesEnum.DAILY))
          ? CustomerTypesEnum.ALL
          : null,
      rent:
        contractType?.includes(ContractTypesEnum.MONTHLY) && contractType?.includes(ContractTypesEnum.DAILY)
          ? ContractTypesEnum.ALL
          : contractType?.includes(ContractTypesEnum.MONTHLY)
          ? ContractTypesEnum.MONTHLY
          : contractType?.includes(ContractTypesEnum.DAILY)
          ? ContractTypesEnum.DAILY
          : null,
    }),
    [contractType]
  );

  return (
    <Form form={form} initialValues={initialValues} onFinish={onSubmit} name="building-filter">
      <Row gutter={16}>
        <Col span={4}>
          <Form.Item name={LeadFieldsEnum.ADDRESS} rules={[{required: false}]}>
            <PInput label={t('form.address')} id={LeadFieldsEnum.ADDRESS} />
          </Form.Item>
        </Col>
        {propertyType === PropertyTypesEnum.APARTMENT && (
          <>
            <Col span={4}>
              <Space.Compact>
                <Form.Item name={LeadFilterFieldsEnum.MIN_ROOMS}>
                  <PNumericInput label={`${t('form.room')} ${t('shorts.min')}`} id={LeadFilterFieldsEnum.MIN_ROOMS} />
                </Form.Item>
                <Form.Item name={LeadFilterFieldsEnum.MAX_ROOMS}>
                  <PNumericInput label={t('shorts.max')} id={LeadFilterFieldsEnum.MAX_ROOMS} />
                </Form.Item>
              </Space.Compact>
            </Col>
            <Col span={4}>
              <Space.Compact>
                <Form.Item name={LeadFilterFieldsEnum.MIN_FLOOR}>
                  <PNumericInput label={`${t('form.floor')} ${t('shorts.min')}`} id={LeadFilterFieldsEnum.MIN_FLOOR} />
                </Form.Item>
                <Form.Item name={LeadFilterFieldsEnum.MAX_FLOOR}>
                  <PNumericInput label={t('shorts.max')} id={LeadFilterFieldsEnum.MAX_FLOOR} />
                </Form.Item>
              </Space.Compact>
            </Col>
          </>
        )}
        {propertyType === PropertyTypesEnum.HOUSE && (
          <Col span={4}>
            <Space.Compact>
              <Form.Item name={LeadFilterFieldsEnum.MIN_FLOORS}>
                <PNumericInput label={`${t('form.floors')} ${t('shorts.min')}`} id={LeadFilterFieldsEnum.MIN_FLOORS} />
              </Form.Item>
              <Form.Item name={LeadFilterFieldsEnum.MAX_FLOORS}>
                <PNumericInput label={t('shorts.max')} id={LeadFilterFieldsEnum.MAX_FLOORS} />
              </Form.Item>
            </Space.Compact>
          </Col>
        )}
        {propertyType === PropertyTypesEnum.COMMERCIAL && (
          <Col span={4}>
            <Space.Compact>
              <Form.Item name={LeadFilterFieldsEnum.MIN_ENTRANCE_FLOOR}>
                <PNumericInput
                  type="number"
                  label={`${t('form.entranceFloor')} ${t('shorts.min')}`}
                  id={LeadFilterFieldsEnum.MIN_ENTRANCE_FLOOR}
                />
              </Form.Item>
              <Form.Item name={LeadFilterFieldsEnum.MAX_ENTRANCE_FLOOR}>
                <PNumericInput type="number" label={t('shorts.max')} id={LeadFilterFieldsEnum.MAX_ENTRANCE_FLOOR} />
              </Form.Item>
            </Space.Compact>
          </Col>
        )}
        <Col span={4}>
          <Form.Item name={LeadFieldsEnum.NAME}>
            <PInput label={t('form.firstName')} id={LeadFieldsEnum.NAME} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name={LeadFieldsEnum.PHONE}
            rules={[
              {min: 9, message: fieldsMessageTranslations.phoneMin},
              {max: 14, message: fieldsMessageTranslations.phoneMax},
            ]}
          >
            <PPhoneInput label={t('form.phone')} id={LeadFieldsEnum.PHONE} />
          </Form.Item>
        </Col>
        <Col span={24}></Col>
        {propertyType &&
          (propertyType === PropertyTypesEnum.HOUSE ? (
            <>
              <Col span={5}>
                <Space.Compact>
                  <Form.Item name={LeadFilterFieldsEnum.MIN_LIVING_AREA}>
                    <PNumericInput
                      label={`${t('form.livingArea')} ${t('shorts.min')}`}
                      suffix={t('shorts.squareMeter')}
                      id={LeadFilterFieldsEnum.MIN_LIVING_AREA}
                    />
                  </Form.Item>
                  <Form.Item name={LeadFilterFieldsEnum.MAX_LIVING_AREA}>
                    <PNumericInput
                      label={t('shorts.max')}
                      suffix={t('shorts.squareMeter')}
                      id={LeadFilterFieldsEnum.MAX_LIVING_AREA}
                    />
                  </Form.Item>
                </Space.Compact>
              </Col>
              <Col span={5}>
                <Space.Compact>
                  <Form.Item name={LeadFilterFieldsEnum.MIN_LAND_AREA}>
                    <PNumericInput
                      label={`${t('form.landArea')} ${t('shorts.min')}`}
                      suffix={t('shorts.squareMeter')}
                      id={LeadFilterFieldsEnum.MIN_LAND_AREA}
                    />
                  </Form.Item>
                  <Form.Item name={LeadFilterFieldsEnum.MAX_LAND_AREA}>
                    <PNumericInput
                      label={t('shorts.max')}
                      suffix={t('shorts.squareMeter')}
                      id={LeadFilterFieldsEnum.MAX_LAND_AREA}
                    />
                  </Form.Item>
                </Space.Compact>
              </Col>
            </>
          ) : propertyType === PropertyTypesEnum.LAND ? (
            <>
              <Col span={5}>
                <Space.Compact>
                  <Form.Item name={LeadFilterFieldsEnum.MIN_SQUARE_METRE}>
                    <PNumericInput
                      label={`${t('form.area')} ${t('shorts.min')}`}
                      suffix={t('shorts.squareMeter')}
                      id={LeadFilterFieldsEnum.MIN_SQUARE_METRE}
                    />
                  </Form.Item>
                  <Form.Item name={LeadFilterFieldsEnum.MAX_SQUARE_METRE}>
                    <PNumericInput
                      label={t('shorts.max')}
                      suffix={t('shorts.squareMeter')}
                      id={LeadFilterFieldsEnum.MAX_SQUARE_METRE}
                    />
                  </Form.Item>
                </Space.Compact>
              </Col>
              <Col span={5}>
                <Space.Compact>
                  <Form.Item name={LeadFilterFieldsEnum.MIN_HECTARE}>
                    <PNumericInput
                      withoutFormatter
                      label={`${t('form.area')} ${t('shorts.min')}`}
                      suffix={t('shorts.hectare')}
                      id={LeadFilterFieldsEnum.MIN_HECTARE}
                    />
                  </Form.Item>
                  <Form.Item name={LeadFilterFieldsEnum.MAX_HECTARE}>
                    <PNumericInput
                      withoutFormatter
                      label={t('shorts.max')}
                      suffix={t('shorts.hectare')}
                      id={LeadFilterFieldsEnum.MAX_HECTARE}
                    />
                  </Form.Item>
                </Space.Compact>
              </Col>
            </>
          ) : (
            <Col span={5}>
              <Space.Compact>
                <Form.Item name={LeadFilterFieldsEnum.MIN_AREA}>
                  <PNumericInput
                    label={`${t('form.area')} ${t('shorts.min')}`}
                    suffix={t('shorts.squareMeter')}
                    id={LeadFilterFieldsEnum.MIN_AREA}
                  />
                </Form.Item>
                <Form.Item name={LeadFilterFieldsEnum.MAX_AREA}>
                  <PNumericInput
                    label={t('shorts.max')}
                    suffix={t('shorts.squareMeter')}
                    id={LeadFilterFieldsEnum.MAX_AREA}
                  />
                </Form.Item>
              </Space.Compact>
            </Col>
          ))}
      </Row>
      {(presets.mode === ContractTypesEnum.SALE || presets.mode === ContractTypesEnum.ALL) && (
        <FormPricesFilter<ILeadsFilterFields>
          label={t('form.sale')}
          values={values}
          propertyType={propertyType!}
          fields={{
            minTotal: LeadFilterFieldsEnum.MIN_TOTAL_PRICE_SALE,
            maxTotal: LeadFilterFieldsEnum.MAX_TOTAL_PRICE_SALE,
            minUnit: LeadFilterFieldsEnum.MIN_UNIT_PRICE_SALE,
            maxUnit: LeadFilterFieldsEnum.MAX_UNIT_PRICE_SALE,
            currency: LeadFilterFieldsEnum.CURRENCY_SALE,
          }}
        />
      )}

      {(presets.mode === ContractTypesEnum.ALL || presets.mode === ContractTypesEnum.RENT) &&
        (presets.rent === ContractTypesEnum.ALL || presets.rent === ContractTypesEnum.MONTHLY) && (
          <FormPricesFilter<ILeadsFilterFields>
            rent={presets.rent}
            label={t('form.rent')}
            values={values}
            propertyType={propertyType!}
            fields={{
              minTotal: LeadFilterFieldsEnum.MIN_TOTAL_PRICE_MONTHLY,
              maxTotal: LeadFilterFieldsEnum.MAX_TOTAL_PRICE_MONTHLY,
              currency: LeadFilterFieldsEnum.CURRENCY_MONTHLY,
            }}
          />
        )}

      {(presets.mode === ContractTypesEnum.ALL || presets.mode === ContractTypesEnum.RENT) &&
        (presets.rent === ContractTypesEnum.ALL || presets.rent === ContractTypesEnum.DAILY) && (
          <FormPricesFilter<ILeadsFilterFields>
            rent={presets.rent}
            label={t('form.rentDaily')}
            values={values}
            propertyType={propertyType!}
            fields={{
              minTotal: LeadFilterFieldsEnum.MIN_TOTAL_PRICE_DAILY,
              maxTotal: LeadFilterFieldsEnum.MAX_TOTAL_PRICE_DAILY,
              currency: LeadFilterFieldsEnum.CURRENCY_DAILY,
            }}
          />
        )}
      <Row gutter={16}>
        <Col>
          <CSubmitButton
            form={form}
            values={values}
            isButtonLoading={isButtonLoading}
            title={t('actions.filter')}
            icon={<Icon.Filter fill="white" />}
          />
        </Col>
        <Col>
          {isFormSubmited && !isButtonLoading && (
            <PButton htmlType="button" type="text" onClick={onReset}>
              {t('actions.clear')}
            </PButton>
          )}
        </Col>
      </Row>
    </Form>
  );
};
