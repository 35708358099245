import {OptGroupsEnum} from 'enums';

export const optGroups = {
  [OptGroupsEnum.APPARTMENT_MARKERS]: 1,
  [OptGroupsEnum.YARD_MARKERS]: 2,
  [OptGroupsEnum.VIEW]: 3,
  [OptGroupsEnum.ENTRY_POSITION]: 4,
  [OptGroupsEnum.ENTRY_TYPE]: 5,
  [OptGroupsEnum.CONTRACT]: 6,
  [OptGroupsEnum.HEATING]: 7,
  [OptGroupsEnum.SOIL_IMPORTANCE]: 8,
  [OptGroupsEnum.REPAIR_TYPES]: 9,
  [OptGroupsEnum.SOURCE]: 10,
  [OptGroupsEnum.BUILDING_SUBTYPE_STONE]: 11,
  [OptGroupsEnum.HOUSE_TYPES_PRIVATE]: 12,
} as const;

export const CapitalNaming = {
  AM: 'երևան',
  RU: 'ереван',
  EN: 'yerevan',
};
