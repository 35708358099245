import {ColumnsType} from 'antd/es/table';

import {PropertyFieldsEnum} from 'components/forms/property/form-property.enums';
import {IPropertyFields} from 'components/forms/property/form-property.interfaces';
import {formatDate, formatNumber, getTranslation} from 'utils';
import {currencySymbols} from 'constants/currency-symbols';
import {InquiryStateTranslationEnum, PropertyTypesTranslationEnum} from 'enums/translations';
import {InquiriesFilterFieldsEnum} from 'components/forms/inquiries-filter/form-inquiries.enums';
import {TInquiry} from 'types/api';
import {PersonRelationModesEnum} from 'enums';
import {TTranslation} from 'types/common';

interface ITableColumns
  extends IPropertyFields,
    Omit<TInquiry, 'created' | 'type' | 'mode' | 'rent' | 'persons' | 'info' | 'modified' | 'comments' | 'id'> {}

export const columns = ({
  relationMode,
  translation,
}: {relationMode: string} & TTranslation): ColumnsType<ITableColumns> => {
  const priceSale = (): ColumnsType<ITableColumns> => [
    {
      title: translation('form.price'),
      key: PropertyFieldsEnum.PRICE,
      dataIndex: PropertyFieldsEnum.PRICE,
      render: (_, record) => (
        <div className="line-clamp-2 min-h-[25px] min-w-[50px]">
          {record.price?.total_sale && getTranslation(currencySymbols, record.price.currency_sale!)?.props.children}
          {formatNumber(record.price?.total_sale!) || '-'}
        </div>
      ),
    },
  ];

  const priceMonthly = (): ColumnsType<ITableColumns> => [
    {
      title: translation('form.rent'),
      key: PropertyFieldsEnum.PRICE,
      dataIndex: PropertyFieldsEnum.PRICE,
      render: (_, record) => (
        <div className="line-clamp-2 min-h-[25px] min-w-[50px]">
          {record.price?.total_monthly &&
            getTranslation(currencySymbols, record.price?.currency_monthly!)?.props.children}{' '}
          {formatNumber(record.price?.total_monthly!) || '-'}
        </div>
      ),
    },
  ];

  const priceDaily = (): ColumnsType<ITableColumns> => [
    {
      title: translation('form.rentDaily'),
      key: PropertyFieldsEnum.RENT,
      dataIndex: PropertyFieldsEnum.RENT,
      render: (_, record) => (
        <div className="line-clamp-2 min-h-[25px] min-w-[50px]">
          {record.price?.total_daily && getTranslation(currencySymbols, record.price?.currency_daily!)?.props.children}{' '}
          {formatNumber(record.price?.total_daily!) || '-'}
        </div>
      ),
    },
  ];

  const allPrices = (): ColumnsType<ITableColumns> => [...priceSale(), ...priceMonthly(), ...priceDaily()];

  const properties = (): ColumnsType<ITableColumns> => [
    {
      title: translation('form.address'),
      key: PropertyFieldsEnum.LOCATION,
      dataIndex: PropertyFieldsEnum.LOCATION,
      render: (_, record) => (
        <div>
          {record.location?.street.name} {record.location.address?.number && `${record.location.address?.number}`}
          {record?.number && `, ${record?.number}`}
        </div>
      ),
    },
    ...allPrices(),
    {
      width: '10%',
      title: translation('form.date'),
      dataIndex: PropertyFieldsEnum.MODIFIED,
      key: PropertyFieldsEnum.MODIFIED,
      render: (_, record) => formatDate({value: record.modified!}),
    },
    {
      title: translation('form.propertyType'),
      dataIndex: PropertyFieldsEnum.TYPE,
      key: PropertyFieldsEnum.TYPE,
      render: (_, record) => <div>{getTranslation(PropertyTypesTranslationEnum, record.type!)}</div>,
    },
    {
      title: translation('form.relationWithProperty'),
      dataIndex: PropertyFieldsEnum.PERSONS,
      key: PropertyFieldsEnum.PERSONS,
      render: (_, record) => <div>{record?.persons?.filter(p => p.id !== record.id)[0]?.relation || '-'}</div>,
    },
  ];

  const inquiries = (): ColumnsType<ITableColumns> => [
    {
      width: '15%',
      title: translation('form.propertyType'),
      dataIndex: PropertyFieldsEnum.TYPE,
      key: PropertyFieldsEnum.TYPE,
      render: (_, record) => <div>{getTranslation(PropertyTypesTranslationEnum, record.type!)}</div>,
    },
    {
      title: translation('form.info'),
      dataIndex: InquiriesFilterFieldsEnum.INFO,
      key: InquiriesFilterFieldsEnum.INFO,
      render: (_, record) => <div className="line-clamp-2">{record.info}</div>,
    },
    {
      title: translation('form.status'),
      dataIndex: InquiriesFilterFieldsEnum.STATE,
      key: InquiriesFilterFieldsEnum.STATE,
      render: (_, record) => <div>{getTranslation(InquiryStateTranslationEnum, record.state)}</div>,
    },
  ];

  return relationMode === PersonRelationModesEnum.PROPERTIES ? properties() : inquiries();
};
