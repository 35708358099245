import {memo, useCallback, useState} from 'react';
import {Popconfirm, Skeleton} from 'antd';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {getPropertyByIdAction, removePropertyHoldAction} from 'store/properties/actions';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {useToast} from 'hooks';

import {ICHoldRemoveProps} from './c-hold-remove.types';

const CHoldRemove = ({children, holdId, propertyId, ...props}: ICHoldRemoveProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {showError, contextHolder} = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onCommentsUpdate = useCallback(
    (e?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
      const data = {
        property: propertyId,
        hold: holdId,
      };

      setIsLoading(true);

      dispatch(
        removePropertyHoldAction(data, {
          onFulfilled: () => {
            dispatch(getPropertyByIdAction({id: propertyId}, {isWithoutLoading: true}));

            setIsLoading(false);
          },
          onReject: () => {
            setIsLoading(false);
            showError({message: fieldsMessageTranslations.commonErrors});
          },
        })
      );
    },
    [propertyId, holdId, showError, dispatch]
  );

  return (
    <>
      <Popconfirm
        {...props}
        icon={null}
        onConfirm={onCommentsUpdate}
        cancelText={t('actions.close')}
        okText={t('actions.delete')}
      >
        <>
          {isLoading ? (
            <Skeleton.Button shape="default" size="small" className="min-w-[25px]" block active />
          ) : (
            children
          )}
        </>
      </Popconfirm>
      {contextHolder}
    </>
  );
};

export const CHoldRemoveMemoized = memo(CHoldRemove);
