import {memo} from 'react';

import {Icon} from 'assets/icons';

import {PSidebarTogglerProps} from './p-sidebar-toggler.types';
import {CSS} from './p-sidebar-toggler.cssconfig';

export const PSidebarToggler = ({onClick, open}: PSidebarTogglerProps) => {
  return (
    <div className={CSS.toggler} onClick={onClick}>
      <div className={CSS.inner}>{open ? <Icon.ChevronLeftRounded /> : <Icon.ChevronRightRounded />}</div>
    </div>
  );
};

export const PSidebarTogglerMemoized = memo(PSidebarToggler);
