import {takeEvery, all, call, put} from 'redux-saga/effects';
import {push} from 'redux-first-history';
import qs from 'query-string';

import {TActionSettings, TServerResponseError} from 'types/common';
import {updateWebsiteFieldsService} from 'services/marketing';
import {RoutesEnum} from 'router/routes.enum';
import {clearFromEmptyKeys} from 'utils';

import {clearMarketingErrorsAction, updateWebsiteFieldsAction} from './actions';
import {setErrors, setLoading, setUpdatedWebsiteFields} from './slice-marketing';

const updateWebsiteFieldsSaga = function* ({
  payload: {...payload},
  settings: {filterPresets, onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield put(setLoading(true));

  try {
    const {data}: {data: {success: any; error: TServerResponseError}} = yield call(updateWebsiteFieldsService, payload);

    const params = {type: payload.type};
    const query = qs.stringify(clearFromEmptyKeys(Object.keys(filterPresets!).length ? filterPresets! : params));

    if (data.error) {
      throw data.error;
    } else {
      yield put(setErrors(null));
      yield put(setUpdatedWebsiteFields(data.success));
      yield put(push(`${RoutesEnum.PROPERTIES}?${query}`));
      onFulfilled && onFulfilled();
    }
  } catch (e) {
    console.error(e);
    yield put(setErrors(e as TServerResponseError));
    yield put(setLoading(false));
    onReject && onReject(e);
  }
};

const clearMarketingErrorsSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setErrors(null));
};

export const marketingSaga = function* () {
  yield all([
    takeEvery(updateWebsiteFieldsAction().type, updateWebsiteFieldsSaga),
    takeEvery(clearMarketingErrorsAction().type, clearMarketingErrorsSaga),
  ]);
};
