import {AddressFieldsEnum} from 'components/forms/address/form-address.enums';

export const injectAddressFields = ({values}: {values: any}): Record<string, string> => {
  const {country_name, settlement_name, district_name, street_name, number} = values;

  const combined = [
    country_name || null,
    settlement_name || null,
    district_name || null,
    street_name || null,
    number || null,
  ];

  return {
    [AddressFieldsEnum.SETTLEMENT_NAME]: settlement_name,
    [AddressFieldsEnum.DISTRICT_NAME]: district_name,
    [AddressFieldsEnum.STREET_NAME]: street_name,
    [AddressFieldsEnum.NUMBER]: number,
    [AddressFieldsEnum.COMBINED]: combined.filter(a => a !== null).join(', '),
  };
};
