import {useCallback, useEffect, useState} from 'react';

export const useMountedOnce = (callback: any) => {
  const [isMounted, setIsMounted] = useState(false);

  const fn = useCallback(callback, [callback]);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
      fn();
    }
  }, [isMounted, fn]);

  return () => {};
};
