/* eslint-disable @typescript-eslint/naming-convention */

export enum ServerErrorsTranslationEnum {
  'Address in use' = 'Հասցեն արդեն օգտագործվում է։ Խնդրում ենք կցել մեկ այլ հասցե',
  'Invalid id' = 'Սխալ id',
  'Invalid email/password' = 'Սխալ էլ․ հասցե կամ գաղտնաբառ',
  'Used email' = 'Էլ․ հասցեն արդեն օգտագործվում է։ Խնդրում ենք ստուգել տվյալները։',
  'Duplicate email' = 'Էլ․ հասցեն արդեն օգտագործվում է։ Խնդրում ենք ստուգել տվյալները։',
  'Used phones' = 'Հեռախոսահամարն արդեն օգտագործվում է։ Խնդրում ենք ստուգել տվյալները։',
  'Duplicate phone' = 'Հեռախոսահամարն արդեն օգտագործվում է։ Խնդրում ենք ստուգել տվյալները։',
}
