import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {TServerResponseError} from 'types/common';

import {AppState} from './types';

const sliceApp = createSlice({
  name: 'app',
  initialState: {
    loading: false,
    profileLoading: false,
    errors: null,
    profile: null,
    catalog: {},
    address: null,

    file: null,
    image: null,
    currentFile: null,
    currentImage: null,

    roomFile: null,
    roomImage: null,
    currentRoomFile: null,
    currentRoomImage: null,

    floorFile: null,
    floorImage: null,
    currentFloorFile: null,
    currentFloorImage: null,

    propertyBuildingImage: null,
    propertyBuildingFile: null,

    buildingImage: null,
    buildingFile: null,
    currentBuildingImage: null,
    currentBuildingFile: null,

    countries: null,
    regions: null,
    settlements: null,
    districts: null,
    streets: null,
  },
  reducers: {
    setErrors(state: AppState, {payload}: PayloadAction<TServerResponseError | null>) {
      state.errors = payload;
    },
    setLoading(state: AppState, {payload}: PayloadAction<AppState['loading']>) {
      state.loading = payload;
    },
    setProfileLoading(state: AppState, {payload}: PayloadAction<AppState['profileLoading']>) {
      state.profileLoading = payload;
    },
    setProfile(state: AppState, {payload}: PayloadAction<AppState['profile']>) {
      state.profile = payload;
    },
    setCatalog(state: AppState, {payload}: PayloadAction<AppState['catalog']>) {
      state.catalog = payload;
    },
    setAddress(state: AppState, {payload}: PayloadAction<AppState['address']>) {
      state.address = payload;
    },

    setFile(state: AppState, {payload}: PayloadAction<AppState['file']>) {
      state.file = payload;
    },
    setImage(state: AppState, {payload}: PayloadAction<AppState['image']>) {
      state.image = payload;
    },
    setCurrentFile(state: AppState, {payload}: PayloadAction<AppState['currentFile']>) {
      state.currentFile = payload;
    },
    setCurrentImage(state: AppState, {payload}: PayloadAction<AppState['currentImage']>) {
      state.currentImage = payload;
    },

    setRoomFile(state: AppState, {payload}: PayloadAction<AppState['roomFile']>) {
      state.roomFile = payload;
    },
    setRoomImage(state: AppState, {payload}: PayloadAction<AppState['roomImage']>) {
      state.roomImage = payload;
    },
    setCurrentRoomFile(state: AppState, {payload}: PayloadAction<AppState['currentRoomFile']>) {
      state.currentRoomFile = payload;
    },
    setCurrentRoomImage(state: AppState, {payload}: PayloadAction<AppState['currentImage']>) {
      state.currentRoomImage = payload;
    },

    setFloorFile(state: AppState, {payload}: PayloadAction<AppState['floorFile']>) {
      state.floorFile = payload;
    },
    setFloorImage(state: AppState, {payload}: PayloadAction<AppState['floorImage']>) {
      state.floorImage = payload;
    },
    setCurrentFloorFile(state: AppState, {payload}: PayloadAction<AppState['currentFloorFile']>) {
      state.currentFloorFile = payload;
    },
    setCurrentFloorImage(state: AppState, {payload}: PayloadAction<AppState['currentFloorImage']>) {
      state.currentFloorImage = payload;
    },

    setPropertyBuildingImage(state: AppState, {payload}: PayloadAction<AppState['propertyBuildingImage']>) {
      state.propertyBuildingImage = payload;
    },
    setPropertyBuildingFile(state: AppState, {payload}: PayloadAction<AppState['propertyBuildingFile']>) {
      state.propertyBuildingFile = payload;
    },

    setBuildingImage(state: AppState, {payload}: PayloadAction<AppState['buildingImage']>) {
      state.buildingImage = payload;
    },
    setBuildingFile(state: AppState, {payload}: PayloadAction<AppState['buildingFile']>) {
      state.buildingFile = payload;
    },
    setCurrentBuildingImage(state: AppState, {payload}: PayloadAction<AppState['currentBuildingImage']>) {
      state.currentBuildingImage = payload;
    },
    setCurrentBuildingFile(state: AppState, {payload}: PayloadAction<AppState['currentBuildingFile']>) {
      state.currentBuildingFile = payload;
    },

    setCountries(state: AppState, {payload}: PayloadAction<AppState['countries']>) {
      state.countries = payload;
    },
    setRegions(state: AppState, {payload}: PayloadAction<AppState['regions']>) {
      state.regions = payload;
    },
    setSettlements(state: AppState, {payload}: PayloadAction<AppState['settlements']>) {
      state.settlements = payload;
    },
    setDistricts(state: AppState, {payload}: PayloadAction<AppState['districts']>) {
      state.districts = payload;
    },
    setStreets(state: AppState, {payload}: PayloadAction<AppState['streets']>) {
      state.streets = payload;
    },
  },
});

export const {
  setErrors,
  setLoading,
  setProfile,
  setProfileLoading,
  setCatalog,
  setAddress,
  setFile,
  setImage,
  setCurrentFile,
  setCurrentImage,
  setRoomFile,
  setRoomImage,
  setCurrentRoomFile,
  setCurrentRoomImage,
  setFloorFile,
  setFloorImage,
  setCurrentFloorFile,
  setCurrentFloorImage,
  setPropertyBuildingImage,
  setPropertyBuildingFile,
  setBuildingImage,
  setBuildingFile,
  setCurrentBuildingImage,
  setCurrentBuildingFile,
  setCountries,
  setRegions,
  setSettlements,
  setDistricts,
  setStreets,
} = sliceApp.actions;

export const appReducer = sliceApp.reducer;
