import {memo, useMemo} from 'react';

const PStatusIndicator = ({
  type,
  className,
  ...props
}: {
  type: 'success' | 'warning' | 'info' | 'default' | 'danger' | string;
  className?: string;
}) => {
  const theme = useMemo(
    () =>
      type === 'success'
        ? 'bg-success shadow-[0_0_0_3px_rgba(64,255,80,0.2)]'
        : type === 'danger'
        ? 'bg-danger shadow-[0_0_0_3px_rgba(255,0,0,0.2)]'
        : type === 'warning'
        ? 'bg-primary shadow-[0_0_0_3px_rgba(245,134,52,0.2)]'
        : type === 'info'
        ? 'bg-info shadow-[0_0_0_3px_rgba(74,142,243,0.2)]'
        : type === 'default'
        ? 'bg-grey-100 shadow-[0_0_0_3px_rgba(0,0,0,0.2)]'
        : 'bg-white shadow-[0_0_0_3px_rgba(0,0,0,0.2)]',
    [type]
  );

  return <div className={`w-[10px] h-[10px] rounded-[50%] ${theme} ${className}`} {...props} />;
};

export const PStatusIndicatorMemoized = memo(PStatusIndicator);
