import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Checkbox, Col, Divider, Form, Radio, RadioChangeEvent, Row, Tooltip} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import {useNavigate} from 'react-router-dom';
import {CheckboxChangeEvent} from 'antd/es/checkbox';
import {useTranslation} from 'react-i18next';

import {PopupBase} from 'components/popups/base';
import {Icon} from 'assets/icons';
import {useToast} from 'hooks';
import {ActionModeHero} from 'constants/translations';
import {IPersonFields} from 'components/forms/person/form-person.interfaces';
import {appProfileSelector} from 'store/app/selectors';
import {FormPersonEdit} from 'components/forms/person';
import {formatPhoneNumber, getTranslation} from 'utils';
import {personSelector} from 'store/persons/selectors';
import {
  addPersonAction,
  clearCreatedPersonAction,
  clearCurrentPersonAction,
  clearPersonErrorsAction,
  searchPersonsAction,
  updatePersonAction,
} from 'store/persons/actions';
import {PTable} from 'components/primitives/p-table';
import {searchPropertiesAction} from 'store/properties/actions';
import {propertiesListSelector, propertiesLoadingSelector} from 'store/properties/selectors';
import {RoutesEnum} from 'router/routes.enum';
import {ActionModesEnum, InquiryStateEnum, PersonRelationModesEnum, StatusEnum} from 'enums';
import {PRadio} from 'components/primitives/p-radio';
import {ServerErrorsTranslationEnum} from 'enums/translations';
import {inquiriesListSelector, inquiriesLoadingSelector} from 'store/inquiries/selectors';
import {getInquiriesAction} from 'store/inquiries/actions';
import {palette} from 'constants/theme';
import {PInfoBar} from 'components/primitives/p-info-bar';
import {CTextCopy} from 'components/containers/c-text-copy';

import {columns} from './constants/columns';
import {PPersonEditPopupProps} from './popup-person-edit.types';

const PopupPersonEdit = ({
  mode,
  entity,
  buildingId,
  propertyId,
  inquiryId,
  pageFrom,
  onClose,
}: PPersonEditPopupProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm<IPersonFields>();
  const {contextHolder, showError} = useToast();
  const values = Form.useWatch([], form);
  const person = useSelector(personSelector);
  const profile = useSelector(appProfileSelector);
  const properties = useSelector(propertiesListSelector);
  const inquiries = useSelector(inquiriesListSelector);
  const isPropertiesLoading = useSelector(propertiesLoadingSelector);
  const isInquiriesLoading = useSelector(inquiriesLoadingSelector);

  const [isShowFrom, setIsShowFrom] = useState<boolean>(mode === ActionModeHero.update ? true : false);
  const [isSubmittable, setIsSubmittable] = useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [relationMode, setRelationMode] = useState<string>(PersonRelationModesEnum.PROPERTIES);
  const [isArchived, setIsArchived] = useState<boolean>(false);

  const isLoading = useMemo(() => isPropertiesLoading || isInquiriesLoading, [isPropertiesLoading, isInquiriesLoading]);
  const list = useMemo(
    () => (relationMode === PersonRelationModesEnum.PROPERTIES ? properties : inquiries),
    [relationMode, properties, inquiries]
  );

  const pathName = useMemo(
    (): string | null =>
      pageFrom
        ? `/${pageFrom}`
        : buildingId
        ? `${RoutesEnum.BUILDINGS}/${ActionModesEnum.UPDATE}/${buildingId}`
        : propertyId
        ? `${RoutesEnum.PROPERTIES}/${ActionModesEnum.UPDATE}/${propertyId}`
        : inquiryId
        ? `${RoutesEnum.INQUIRIES}/${inquiryId}`
        : null,
    [inquiryId, propertyId, buildingId, pageFrom]
  );

  const handleClose = useCallback(() => {
    dispatch(clearCurrentPersonAction());
    onClose();
  }, [onClose, dispatch]);

  const handleCancel = useCallback(() => {
    handleClose();

    if (pathName) {
      navigate(`${pathName}`);
    }
  }, [pathName, navigate, handleClose]);

  const handleSubmit = useCallback(
    (values: IPersonFields) => {
      setIsButtonLoading(true);

      const phones = Object.values(values.phones!).map(p => formatPhoneNumber(p?.number!));
      const type = mode === ActionModeHero.create ? values.type : null;
      const person = entity?.id;

      const data = {
        person,
        name: values.name,
        email: values.email,
        type,
        phones,
      };

      mode === ActionModeHero.create
        ? dispatch(
            addPersonAction(data, {
              role: profile?.type,
              onFulfilled: () => {
                setIsButtonLoading(false);
                handleClose();
              },
              onReject: e => {
                setIsButtonLoading(false);
                showError({message: getTranslation(ServerErrorsTranslationEnum, e)});
              },
            })
          )
        : dispatch(
            updatePersonAction(data, {
              role: profile?.type,
              isWithoutLoading: true,
              onFulfilled: () => {
                setIsButtonLoading(false);
                handleClose();

                if (pathName) {
                  navigate(`${pathName}`);
                }
              },
              onReject: e => {
                setIsButtonLoading(false);
                showError({message: getTranslation(ServerErrorsTranslationEnum, e)});
              },
            })
          );
    },
    [profile, entity, mode, pathName, showError, handleClose, dispatch, navigate]
  );

  const handleTableTypeChange = useCallback((e: RadioChangeEvent) => {
    setRelationMode(e.target.value);
  }, []);

  const handleArchivedChange = useCallback((e: CheckboxChangeEvent) => {
    setIsArchived(e.target.checked);
  }, []);

  const onAdd = useCallback(() => {
    handleSubmit(values);
  }, [values, handleSubmit]);

  useEffect(() => {
    if (person && !isButtonLoading) {
      handleClose();
      dispatch(searchPersonsAction());
      dispatch(clearCreatedPersonAction());
    }
  }, [person, isButtonLoading, handleClose, dispatch]);

  useEffect(() => {
    if (person) {
      dispatch(clearPersonErrorsAction());
    }
  }, [person, dispatch]);

  useEffect(() => {
    form.validateFields({validateOnly: true}).then(
      () => {
        setIsSubmittable(true);
      },
      () => {
        setIsSubmittable(false);
      }
    );
  }, [values, form]);

  useEffect(() => {
    mode === ActionModeHero.add && dispatch(searchPersonsAction());
    const actionToDispatch =
      relationMode === PersonRelationModesEnum.PROPERTIES ? searchPropertiesAction : getInquiriesAction;
    const archived = isArchived
      ? {
          [relationMode === PersonRelationModesEnum.PROPERTIES ? 'status[]' : 'state[]']:
            relationMode === PersonRelationModesEnum.PROPERTIES ? StatusEnum.ARCHIVED : InquiryStateEnum.END,
        }
      : {};

    entity && dispatch(actionToDispatch({person: entity?.id, ...archived}, {withCount: false}));
  }, [relationMode, isArchived, mode, entity, dispatch]);

  return (
    <>
      <PopupBase
        title={
          <div className="inline-flex gap-2">
            {`${isShowFrom ? ActionModeHero.update : ActionModeHero.view} ${t('common.person')}`}{' '}
            {isShowFrom ? (
              <Tooltip title={t('actions.view')} className="cursor-pointer">
                <Icon.Eye color={palette.primary} onClick={() => setIsShowFrom(false)} />
              </Tooltip>
            ) : null}
          </div>
        }
        onCrossClick={handleClose}
        onSubmit={handleSubmit}
        open
        size="xl"
        buttons={{
          position: 'right',
          reject: {
            name: t('actions.cancel'),
            type: 'text',
            theme: 'default',
            className: !isShowFrom ? 'hidden' : '',
            onClick: handleCancel,
          },
          confirm: {
            icon: isShowFrom ? <Icon.Edit stroke="white" /> : null,
            name:
              mode === ActionModeHero.add
                ? t('actions.confirm')
                : mode === ActionModeHero.view
                ? t('actions.update')
                : mode!,
            type: 'primary',
            htmlType: 'button',
            disabled: !isSubmittable,
            loading: isButtonLoading,
            onClick: !isShowFrom ? () => setIsShowFrom(true) : onAdd,
          },
        }}
      >
        {isShowFrom ? (
          <FormPersonEdit form={form} values={values} initialValues={entity!} mode={mode!} onSumbit={handleSubmit} />
        ) : (
          <div className="rounded-[16px] px-4 bg-grey-40 mb-[16px] last:mb-0">
            <PInfoBar label={t('form.firstName')} value={entity?.name}></PInfoBar>
            <PInfoBar
              label={t('form.phone')}
              value={entity?.phones?.map(n => <CTextCopy key={`${n?.id}-${n?.number}`} text={n?.number} />)}
            ></PInfoBar>

            {entity?.email && <PInfoBar label={t('form.email')} value={entity?.email}></PInfoBar>}

            {entity?.relation && <PInfoBar label={t('form.relationWithBuilding')} value={entity?.relation}></PInfoBar>}
          </div>
        )}
        <div className="mb-7">
          <Divider />
          <Row className="mb-5" justify="space-between" align="middle">
            <Col>
              <Radio.Group
                onChange={handleTableTypeChange}
                defaultValue={relationMode}
                value={relationMode}
                optionType="button"
                buttonStyle="outline"
                size="large"
                className="w-full flex gap-3"
              >
                <PRadio value={PersonRelationModesEnum.PROPERTIES} label={t('common.attachedProperties')}></PRadio>
                <PRadio value={PersonRelationModesEnum.INQUIRIES} label={t('common.inquiries')}></PRadio>
              </Radio.Group>
            </Col>

            <Col>
              <Checkbox onChange={handleArchivedChange}>
                {relationMode === PersonRelationModesEnum.PROPERTIES
                  ? t('catalog.statuses.archived')
                  : t('catalog.statuses.ended')}
              </Checkbox>
            </Col>
          </Row>
          <PTable
            loading={isLoading}
            columns={columns({relationMode, translation: t})}
            dataSource={isLoading ? [] : list}
            rowKey={() => uuidv4()}
            paginationOptions={{pageSize: 5}}
            rowClassName="cursor-pointer"
            onRow={(record, rowIndex) => ({
              onClick: event => {
                navigate(
                  `${
                    relationMode === PersonRelationModesEnum.PROPERTIES
                      ? `${RoutesEnum.PROPERTIES}/${ActionModesEnum.VIEW}`
                      : RoutesEnum.INQUIRIES
                  }/${record.id}`
                );
                handleClose();
              },
            })}
          />
        </div>
      </PopupBase>

      {contextHolder}
    </>
  );
};

export const PopupPersonEditMemoized = memo(PopupPersonEdit);
