import {memo, useCallback, useEffect, useState} from 'react';
import {Form} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {PopupBase} from 'components/popups/base';
import {Icon} from 'assets/icons';
import {FormAccount} from 'components/forms/account';
import {IAccountFields} from 'components/forms/account/form-account.interfaces';
import {RolesEnum, PermissionsEnum} from 'enums';
import {
  addAccountAction,
  clearAccountAction,
  clearAccountsErrorsAction,
  getAccountsAction,
  updateAccountAction,
} from 'store/account/actions';
import {useToast} from 'hooks';
import {ActionModeHero} from 'constants/translations';
import {formatPhoneNumber, getTranslation} from 'utils';
import {accountSelector} from 'store/account/selectors';
import {ServerErrorsTranslationEnum} from 'enums/translations';

const PopupAccount = ({mode, entity, onClose}: {mode?: string; entity?: IAccountFields; onClose: () => void}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm<IAccountFields>();
  const {contextHolder, showError} = useToast();
  const [isSubmittable, setIsSubmittable] = useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const values = Form.useWatch([], form);
  const account = useSelector(accountSelector);

  const handleSubmit = useCallback(
    (values: IAccountFields) => {
      setIsButtonLoading(true);

      let {permissions, phone, ...rest} = values;

      const phoneToSend = formatPhoneNumber(phone!);

      const permissionTypes = Object.fromEntries(Object.values(PermissionsEnum).map(item => [item, 0]));

      permissions =
        values?.type === RolesEnum.BROKER ? Object.fromEntries([...values.permissions!].map(p => [p, 1])) : {};

      Object.keys(permissions!).forEach(p => {
        permissionTypes[p] = 1;
      });

      const permissionsToSend = values?.type === RolesEnum.BROKER ? {...permissionTypes} : {};

      const data = {
        ...rest,
        ...permissionsToSend,
        account: mode === ActionModeHero.update ? entity?.id : null,
        active: mode === ActionModeHero.update ? entity?.active : null,
        phone: phoneToSend,
      };

      mode === ActionModeHero.create
        ? dispatch(
            addAccountAction(data, {
              onFulfilled: () => setIsButtonLoading(false),
              onReject: e => {
                setIsButtonLoading(false);
                showError({message: getTranslation(ServerErrorsTranslationEnum, e)});
              },
            })
          )
        : dispatch(
            updateAccountAction(data, {
              onFulfilled: () => setIsButtonLoading(false),
              onReject: e => {
                setIsButtonLoading(false);
                showError({message: getTranslation(ServerErrorsTranslationEnum, e)});
              },
            })
          );
    },
    [mode, entity, showError, dispatch]
  );

  const onAdd = useCallback(() => {
    handleSubmit(values);
  }, [values, handleSubmit]);

  useEffect(() => {
    if (account && !isButtonLoading) {
      onClose();
      dispatch(getAccountsAction());
      dispatch(clearAccountAction());
    }
  }, [account, isButtonLoading, onClose, dispatch]);

  useEffect(() => {
    if (account) {
      dispatch(clearAccountsErrorsAction());
    }
  }, [account, dispatch]);

  useEffect(() => {
    form.validateFields({validateOnly: true}).then(
      () => {
        setIsSubmittable(true);
      },
      () => {
        setIsSubmittable(false);
      }
    );
  }, [values, form]);

  return (
    <>
      <PopupBase
        title={`${mode} ${t('common.user')}`}
        onCrossClick={onClose}
        onSubmit={handleSubmit}
        open
        size="lg"
        buttons={{
          position: 'right',
          reject: {
            name: t('actions.cancel'),
            type: 'text',
            theme: 'default',
            onClick: onClose,
          },
          confirm: {
            icon: mode === ActionModeHero.create ? <Icon.AddCircle fill="white" /> : <Icon.Edit stroke="white" />,
            name: mode!,
            type: 'primary',
            htmlType: 'button',
            disabled: !isSubmittable,
            loading: isButtonLoading,
            onClick: onAdd,
          },
        }}
      >
        <FormAccount form={form} values={values} initialValues={entity!} mode={mode!} onSumbit={handleSubmit} />
      </PopupBase>

      {contextHolder}
    </>
  );
};

export const PopupAccountMemoized = memo(PopupAccount);
