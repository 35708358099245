import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {TServerResponseError} from 'types/common';

import {MarketingState} from './types';

const sliceMarketing = createSlice({
  name: 'marketing',
  initialState: {
    loading: false,
    error: null,
    updatedWebsiteFields: null,
  },
  reducers: {
    setLoading(state: MarketingState, {payload}: PayloadAction<MarketingState['loading']>) {
      state.loading = payload;
    },
    setErrors(state: MarketingState, {payload}: PayloadAction<TServerResponseError | null>) {
      state.error = payload;
    },
    setUpdatedWebsiteFields(state: MarketingState, {payload}: PayloadAction<MarketingState['updatedWebsiteFields']>) {
      state.updatedWebsiteFields = payload;
    },
  },
});

export const {setErrors, setLoading, setUpdatedWebsiteFields} = sliceMarketing.actions;

export const marketingReducer = sliceMarketing.reducer;
