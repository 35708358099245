import {TActionSettings} from 'types/common';

export const getOptGroupsAction = (payload?: {}) => ({
  type: 'private/shared/getOptgroups',
  payload,
});

export const getProfileAction = (payload?: {}) => ({
  type: 'private/shared/getProfile',
  payload,
});

export const addAddressAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/addAddressAction',
  payload,
  settings: settings || {},
});

export const addFolderAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/addFolderAction',
  payload,
  settings: settings || {},
});

export const updateFolderAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/updateFolderAction',
  payload,
  settings: settings || {},
});

export const getFolderByIdAction = (payload?: {id: number}, settings?: TActionSettings) => ({
  type: 'app/getFolderById',
  payload,
  settings: settings || {},
});

export const clearMediaAction = (payload?: {type?: 'attached' | 'current' | null}, settings?: TActionSettings) => ({
  type: 'app/clearMediaAction',
  payload: payload || {},
  settings: settings || {},
});

export const clearAddressAction = () => ({
  type: 'custom/clearAddressAction',
});

export const getCountriesAction = (payload?: {offset?: number; length?: number}, settings?: TActionSettings) => ({
  type: 'shared/getCountriesAction',
  payload,
  settings: settings || {},
});

export const getStreetsAction = (payload?: {offset?: number; length?: number}, settings?: TActionSettings) => ({
  type: 'shared/getStreetsAction',
  payload,
  settings: settings || {},
});

export const getRegionsAction = (payload?: {offset?: number; length?: number}, settings?: TActionSettings) => ({
  type: 'shared/getRegionsAction',
  payload,
  settings: settings || {},
});

export const getSettlementsAction = (payload?: {offset?: number; length?: number}, settings?: TActionSettings) => ({
  type: 'shared/getSettlementsAction',
  payload,
  settings: settings || {},
});

export const getDistrictsAction = (payload?: {offset?: number; length?: number}, settings?: TActionSettings) => ({
  type: 'shared/getDistrictsAction',
  payload,
  settings: settings || {},
});
