import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Divider, Row} from 'antd';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import {useTranslation} from 'react-i18next';

import {Icon} from 'assets/icons';
import {PCardWrapper} from 'components/primitives/p-card-wrapper';
import {PLoader} from 'components/primitives/p-loader';
import {RoutesEnum} from 'router/routes.enum';
import {
  ActionModesEnum,
  BuildingSubTypesEnum,
  BuildingTypeCommonEnum,
  OptGroupsEnum,
  PropertyTypesEnum,
  ContractTypesEnum,
  StatusEnum,
  FoldersEnum,
  AddonsEnum,
  RolesEnum,
  ServerErrorsEnum,
} from 'enums';
import {PButton} from 'components/primitives/p-button';
import {PPageTitle} from 'components/primitives/p-page-title';
import {PInfoBar} from 'components/primitives/p-info-bar';
import {PTitle} from 'components/primitives/p-title';
import {palette} from 'constants/theme';
import {CMap} from 'components/containers/c-map';
import {
  BuildingTypesTranslationEnum,
  ContractTypesTranslationEnum,
  HeatingTranslationEnum,
  PropertyTypesTranslationEnum,
  StatusTranslationEnum,
} from 'enums/translations';
import {clearMediaAction, getFolderByIdAction} from 'store/app/actions';
import {
  appCatalogSelector,
  appCurrentFileSelector,
  appProfileSelector,
  appPropertyBuildingFileSelector,
  appPropertyBuildingImageSelector,
} from 'store/app/selectors';
import {PFileEntity} from 'components/primitives/p-file-entity';
import {PCarousel} from 'components/primitives/p-carousel';
import {getTranslation, formatNumber, formatDate, findObjectByKeys, injectMediaPath, getFullAddress} from 'utils';
import {currencySymbols} from 'constants/currency-symbols';
import {Image} from 'assets/images';
import {PLightbox} from 'components/primitives/p-lightbox';
import {clearCurrentPropertyAction, clearPropertiesErrorsAction, getPropertyByIdAction} from 'store/properties/actions';
import {propertiesErrorSelector, propertiesLoadingSelector, propertyCurrentSelector} from 'store/properties/selectors';
import {PLightboxCaption} from 'components/primitives/p-lightbox-caption';
import {PTable} from 'components/primitives/p-table';
import {pricesColumns} from 'components/pages/property-detailed/constants/prices-columns';
import {clearCurrentBuildingAction} from 'store/buildings/actions';
import {CPdfGenerate} from 'components/containers/c-pdf-generate';
import {bookingsColumns} from 'components/pages/property-detailed/constants/bookings-columns';
import {PFrozenProperty} from 'components/primitives/p-frozed-property';
import {CTextCopy} from 'components/containers/c-text-copy';
import {EmptyPage} from 'components/layout/empty-page';

import {PropertyAddon} from './components/addon';

export const PagePropertyView = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {id} = useParams();
  const navigate = useNavigate();
  const profile = useSelector(appProfileSelector);
  const catalog = useSelector(appCatalogSelector);
  const isLoading = useSelector(propertiesLoadingSelector);
  const error = useSelector(propertiesErrorSelector);
  const currentProperty = useSelector(propertyCurrentSelector);
  const currentFile = useSelector(appCurrentFileSelector);
  const propertyBuildingImage = useSelector(appPropertyBuildingImageSelector);
  const propertyBuildingFile = useSelector(appPropertyBuildingFileSelector);
  const [srcReservePath, setSrcReservePath] = useState<string[]>([]);
  const [gallery, setGallery] = useState<any[]>([]);
  const [isLighboxVisible, setIsLighboxVisible] = useState({
    property: false,
    building: false,
  });
  const [lighboxStartIndex, setLighboxStartIndex] = useState<number>(0);
  const options = useMemo(
    () => ({
      view: findObjectByKeys({options: currentProperty?.options!, state: catalog[OptGroupsEnum.VIEW], key: 'value'}),
      apartmentMarkers: findObjectByKeys({
        options: currentProperty?.options!,
        state: catalog[OptGroupsEnum.APPARTMENT_MARKERS],
        key: 'value',
      }),
      yardMarkers: findObjectByKeys({
        options: currentProperty?.options!,
        state: catalog[OptGroupsEnum.YARD_MARKERS],
        key: 'value',
      }),
      entryPosition: findObjectByKeys({
        options: currentProperty?.options!,
        state: catalog[OptGroupsEnum.ENTRY_POSITION],
        key: 'value',
      }),
      entryType: findObjectByKeys({
        options: currentProperty?.options!,
        state: catalog[OptGroupsEnum.ENTRY_TYPE],
        key: 'value',
      }),
    }),
    [currentProperty, catalog]
  );
  const fullAdress = useMemo(
    () => getFullAddress({location: currentProperty?.location, number: currentProperty?.number}),
    [currentProperty]
  );

  const injectOptionsText = useCallback(
    (option: Array<number>, optgroup: string) => {
      return option
        .map((v: number) => catalog[optgroup].find(o => o.value === v))
        .map((item: any) => item?.label)
        .join(', ');
    },
    [catalog]
  );

  useEffect(() => {
    if (id) {
      dispatch(clearCurrentBuildingAction());
      dispatch(clearCurrentPropertyAction());
      dispatch(clearMediaAction());

      dispatch(getPropertyByIdAction({id}));
    }
  }, [id, dispatch]);

  useEffect(() => {
    const addons = currentProperty?.rooms || currentProperty?.floors;
    setGallery([]);

    if (addons?.length) {
      const images = addons?.map(({name, image_folder}) => {
        const files = image_folder?.files.map((item: any) => ({...item, caption: name}));

        return files || [];
      });
      const gallery = [...[currentProperty?.image_folder?.files || []], ...[Array.prototype.concat.apply([], images)]];

      setGallery(...[Array.prototype.concat.apply([], gallery)]);
    } else {
      setGallery(currentProperty?.image_folder?.files);
    }

    if (currentProperty?.image_folder) {
      dispatch(
        getFolderByIdAction(
          {
            id: currentProperty?.image_folder?.id,
          },
          {folder: FoldersEnum.IMAGE}
        )
      );
    } else {
      dispatch(clearMediaAction({}, {folder: FoldersEnum.IMAGE}));
    }

    if (currentProperty?.file_folder) {
      dispatch(
        getFolderByIdAction({
          id: currentProperty.file_folder.id,
        })
      );
    } else {
      dispatch(clearMediaAction({}, {folder: FoldersEnum.FILE}));
    }

    if (currentProperty?.building?.image_folder) {
      dispatch(
        getFolderByIdAction(
          {
            id: currentProperty?.building?.image_folder,
          },
          {folder: FoldersEnum.IMAGE, addon: AddonsEnum.PROPERTY_BUILDING_IMAGE}
        )
      );
    } else {
      dispatch(clearMediaAction({}, {folder: FoldersEnum.IMAGE}));
    }

    if (currentProperty?.building?.file_folder) {
      dispatch(
        getFolderByIdAction(
          {
            id: currentProperty?.building?.file_folder,
          },
          {folder: FoldersEnum.FILE, addon: AddonsEnum.PROPERTY_BUILDING_FILE}
        )
      );
    } else {
      dispatch(clearMediaAction({}, {folder: FoldersEnum.FILE}));
    }
  }, [currentProperty, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearPropertiesErrorsAction());
    };
  }, [dispatch]);

  if (!isLoading && (error === ServerErrorsEnum.INVALID_ID || error?.response?.status === 404)) {
    return <EmptyPage showHeader />;
  }

  return isLoading ? (
    <PLoader />
  ) : (
    <PLoader spinning={isLoading} asLayer>
      <Row justify="space-between">
        <Col>
          <PPageTitle
            text={
              <PButton
                type="text"
                theme="white"
                icon={<Icon.ChevronLeftRounded fill="currentColor" />}
                onClick={() => navigate(-1)}
              >
                {t('actions.back')}
              </PButton>
            }
          />
        </Col>
        {currentProperty?.id && (
          <Col>
            <CPdfGenerate />
          </Col>
        )}
      </Row>

      <Row gutter={25}>
        <Col span={12}>
          <PCardWrapper padding="md">
            <Row gutter={25}>
              <Col span={24}>
                <PTitle
                  className="pb-1"
                  textSize="large"
                  theme="danger"
                  text={fullAdress}
                  icon={<Icon.Location fill={palette.danger.primary} />}
                ></PTitle>
              </Col>
              <Col span={24}>
                {gallery?.length > 0 ? (
                  <div className="mb-6">
                    <PCarousel
                      dots={false}
                      arrows={Object.keys(gallery)?.length > 1}
                      className="rounded-[16px] overflow-hidden"
                    >
                      {gallery.map((img: any, i: number) => (
                        <div key={`${uuidv4()}`} className="max-h-[500px] relative">
                          <img
                            onError={() => {
                              setSrcReservePath([...srcReservePath, img?.path]);
                            }}
                            src={srcReservePath.includes(img.path) ? Image.noImage : injectMediaPath(img?.path)}
                            onClick={e => {
                              e.stopPropagation();
                              setIsLighboxVisible(prev => ({...prev, property: true}));
                              setLighboxStartIndex(i);
                            }}
                            width="100%"
                            className="cursor-pointer"
                            alt="pic"
                          />
                          {img.caption && <PLightboxCaption>{img.caption}</PLightboxCaption>}
                        </div>
                      ))}
                    </PCarousel>
                    <div className="hidden">
                      <PLightbox
                        showThumbnails={true}
                        modalClose="clickOutside"
                        open={isLighboxVisible.property}
                        startingSlideIndex={lighboxStartIndex}
                        onClose={() => setIsLighboxVisible(prev => ({...prev, property: false}))}
                        className="hidden"
                        images={gallery}
                      >
                        {gallery?.map((img: any) => (
                          <img
                            key={`${uuidv4()}`}
                            onError={() => {
                              setSrcReservePath([...srcReservePath, img?.path]);
                            }}
                            src={srcReservePath.includes(img?.path) ? Image.noImage : injectMediaPath(img?.path)}
                            width="100%"
                            alt="pic"
                            title={img?.title}
                          />
                        ))}
                      </PLightbox>
                    </div>
                  </div>
                ) : (
                  <Divider className="mb-5 mt-1" />
                )}

                <div className="mb-5">
                  <PTitle
                    textSize="medium"
                    text={t('common.commonInfo')}
                    icon={<Icon.Note fill={palette.common} />}
                  ></PTitle>
                  <div>
                    <PInfoBar label={t('form.propertyId')} value={currentProperty?.code}></PInfoBar>
                    <PInfoBar label={t('form.date')} value={formatDate({value: currentProperty?.created!})}></PInfoBar>
                    {currentProperty?.mode && (
                      <PInfoBar
                        label={t('form.contractType')}
                        value={
                          currentProperty?.mode === ContractTypesEnum.ALL &&
                          currentProperty?.rent === ContractTypesEnum.MONTHLY
                            ? `${ContractTypesTranslationEnum.SALE}, ${ContractTypesTranslationEnum.MONTHLY} `
                            : currentProperty?.mode === ContractTypesEnum.ALL &&
                              currentProperty?.rent === ContractTypesEnum.DAILY
                            ? `${ContractTypesTranslationEnum.SALE}, ${ContractTypesTranslationEnum.DAILY} `
                            : currentProperty?.mode === ContractTypesEnum.ALL &&
                              currentProperty?.rent === ContractTypesEnum.ALL
                            ? `${ContractTypesTranslationEnum.SALE}, ${ContractTypesTranslationEnum.MONTHLY}, ${ContractTypesTranslationEnum.DAILY} `
                            : currentProperty?.mode === ContractTypesEnum.RENT &&
                              currentProperty?.rent === ContractTypesEnum.ALL
                            ? `${ContractTypesTranslationEnum.MONTHLY}, ${ContractTypesTranslationEnum.DAILY} `
                            : currentProperty?.rent === ContractTypesEnum.MONTHLY
                            ? `${ContractTypesTranslationEnum.MONTHLY}`
                            : currentProperty?.rent === ContractTypesEnum.DAILY
                            ? `${ContractTypesTranslationEnum.DAILY}`
                            : getTranslation(ContractTypesTranslationEnum, currentProperty?.mode)
                        }
                      ></PInfoBar>
                    )}
                    <PInfoBar
                      label={t('form.propertyType')}
                      value={getTranslation(PropertyTypesTranslationEnum, currentProperty?.type!)}
                    ></PInfoBar>
                    <PInfoBar
                      label={t('form.contract')}
                      value={
                        catalog[OptGroupsEnum.CONTRACT]?.find(o => o.value === currentProperty?.contract)
                          ?.label as string
                      }
                    ></PInfoBar>
                    <PInfoBar
                      label={t('form.status')}
                      value={getTranslation(StatusTranslationEnum, currentProperty?.status!)}
                    ></PInfoBar>
                    {currentProperty?.area && (
                      <PInfoBar
                        label={t('form.area')}
                        value={`${currentProperty?.area} ${t('shorts.squareMeter')}`}
                      ></PInfoBar>
                    )}
                    {currentProperty?.state && (
                      <PInfoBar
                        label={t('form.houseType')}
                        value={
                          catalog[OptGroupsEnum.HOUSE_TYPES_PRIVATE]?.find(o => o.value === currentProperty?.state)
                            ?.label as string
                        }
                      ></PInfoBar>
                    )}
                    {currentProperty?.living_area && (
                      <PInfoBar
                        label={t('form.livingArea')}
                        value={`${currentProperty?.living_area} ${t('shorts.squareMeter')}`}
                      ></PInfoBar>
                    )}
                    {currentProperty?.floor && (
                      <>
                        <PInfoBar
                          label={t('form.floor')}
                          value={`${
                            currentProperty?.building?.floors ? `${currentProperty?.building?.floors}/` : ''
                          }${currentProperty?.floor}`}
                        ></PInfoBar>
                      </>
                    )}
                    {currentProperty?.title && (
                      <PInfoBar label={t('form.title')} value={currentProperty?.title!}></PInfoBar>
                    )}
                    {currentProperty?.total_area && (
                      <PInfoBar
                        label={t('form.totlaArea')}
                        value={`${currentProperty?.total_area} ${t('shorts.squareMeter')}`}
                      ></PInfoBar>
                    )}
                    {currentProperty?.commercial_area && (
                      <PInfoBar
                        label={t('form.commercialArea')}
                        value={`${currentProperty?.commercial_area} ${t('shorts.squareMeter')}`}
                      ></PInfoBar>
                    )}
                    {currentProperty?.land_area && (
                      <PInfoBar
                        label={t('form.landArea')}
                        value={`${currentProperty?.land_area} ${t('shorts.squareMeter')}`}
                      ></PInfoBar>
                    )}
                    {currentProperty?.floor_plan && (
                      <PInfoBar label={t('form.floors')} value={currentProperty?.floor_plan}></PInfoBar>
                    )}
                    {currentProperty?.entrance_count && (
                      <PInfoBar label={t('form.entranceCount')} value={currentProperty?.entrance_count}></PInfoBar>
                    )}
                    {currentProperty?.entrance_floor && (
                      <PInfoBar label={t('form.entranceFloor')} value={currentProperty?.entrance_floor}></PInfoBar>
                    )}

                    {currentProperty?.bedrooms && (
                      <PInfoBar
                        label={currentProperty?.type === PropertyTypesEnum.HOUSE ? t('form.bedroom') : t('form.room')}
                        value={currentProperty?.bedrooms}
                      ></PInfoBar>
                    )}

                    {currentProperty?.usage && (
                      <PInfoBar
                        label={t('form.usage')}
                        value={
                          catalog[OptGroupsEnum.SOIL_IMPORTANCE]?.find(o => o.value === currentProperty?.usage)
                            ?.label as string
                        }
                      ></PInfoBar>
                    )}
                    {currentProperty?.cadastre_number && (
                      <PInfoBar label={t('form.cadastreNumber')} value={currentProperty?.cadastre_number}></PInfoBar>
                    )}
                    {(currentProperty?.square_metre || currentProperty?.hectare) && (
                      <PInfoBar
                        label={t('form.area')}
                        value={`${currentProperty?.square_metre} ${t(
                          'shorts.squareMeter'
                        )} | ${currentProperty?.hectare} ${t('form.hectare')}`}
                      ></PInfoBar>
                    )}
                    {currentProperty?.front_length && (
                      <PInfoBar
                        label={t('form.frontLength')}
                        value={`${currentProperty?.front_length} մետր`}
                      ></PInfoBar>
                    )}
                    {currentProperty?.yard_info && (
                      <PInfoBar label={t('form.info')} value={currentProperty?.yard_info}></PInfoBar>
                    )}
                    {currentProperty?.comments && (
                      <PInfoBar label={t('form.comments')} value={currentProperty?.comments}></PInfoBar>
                    )}
                    {currentProperty?.archive_info && (
                      <PInfoBar label={t('form.archiveInfo')} value={currentProperty?.archive_info}></PInfoBar>
                    )}
                  </div>
                </div>

                <div className="mb-5">
                  <PTitle
                    textSize="medium"
                    text={t('common.otherInfo')}
                    icon={<Icon.Information className="text-grey-common" />}
                  ></PTitle>
                  {options.view?.length > 0 ? (
                    <PInfoBar
                      label={t('form.view')}
                      value={injectOptionsText(options.view, OptGroupsEnum.VIEW)}
                    ></PInfoBar>
                  ) : null}

                  {options.apartmentMarkers?.length > 0 ? (
                    <PInfoBar
                      label={t('form.markers')}
                      value={injectOptionsText(options.apartmentMarkers, OptGroupsEnum.APPARTMENT_MARKERS)}
                    ></PInfoBar>
                  ) : null}
                  {options.yardMarkers?.length > 0 ? (
                    <PInfoBar
                      label={t('form.yardMarkers')}
                      value={injectOptionsText(options.yardMarkers, OptGroupsEnum.YARD_MARKERS)}
                    ></PInfoBar>
                  ) : null}
                  {currentProperty?.repair && (
                    <PInfoBar
                      label={t('form.repair')}
                      value={
                        (catalog[OptGroupsEnum.REPAIR_TYPES]?.find(o => o.value === currentProperty?.repair)
                          ?.label as string) || '-'
                      }
                    ></PInfoBar>
                  )}
                  {currentProperty?.bathrooms && (
                    <PInfoBar label={t('form.bathrooms')} value={currentProperty?.bathrooms}></PInfoBar>
                  )}
                  {currentProperty?.balconies && (
                    <PInfoBar label={t('form.balconies')} value={currentProperty?.balconies}></PInfoBar>
                  )}
                  {currentProperty?.heating && (
                    <PInfoBar
                      label={t('form.heating')}
                      value={
                        catalog[OptGroupsEnum.HEATING]?.find(o => o.value === currentProperty?.heating)?.label as string
                      }
                    ></PInfoBar>
                  )}
                  {options.entryType?.length > 0 ? (
                    <PInfoBar
                      label={t('form.entryType')}
                      value={injectOptionsText(options.entryType, OptGroupsEnum.ENTRY_TYPE)}
                    ></PInfoBar>
                  ) : null}
                  {options.entryPosition?.length > 0 ? (
                    <PInfoBar
                      label={t('form.entryPosition')}
                      value={injectOptionsText(options.entryPosition, OptGroupsEnum.ENTRY_POSITION)}
                    ></PInfoBar>
                  ) : null}
                  {currentProperty?.parkings ? (
                    <PInfoBar label={t('form.parkings')} value={currentProperty?.parkings}></PInfoBar>
                  ) : null}

                  {currentProperty?.type === PropertyTypesEnum.APARTMENT && currentProperty?.parking !== null ? (
                    <PInfoBar
                      label={t('form.parking')}
                      value={currentProperty?.parking === 1 ? 'Այո' : currentProperty?.parking === 0 ? 'Ոչ' : '-'}
                    ></PInfoBar>
                  ) : null}

                  {currentProperty?.type === PropertyTypesEnum.COMMERCIAL && (
                    <>
                      {currentProperty?.cafe !== null && (
                        <PInfoBar
                          label={t('form.cafe')}
                          value={currentProperty?.cafe === 1 ? 'Այո' : currentProperty?.cafe === 0 ? 'Ոչ' : '-'}
                        ></PInfoBar>
                      )}
                      {currentProperty?.divisible !== null && (
                        <PInfoBar
                          label={t('form.divisible')}
                          value={
                            currentProperty?.divisible === 1 ? 'Այո' : currentProperty?.divisible === 0 ? 'Ոչ' : '-'
                          }
                        ></PInfoBar>
                      )}
                      {currentProperty?.is_building !== null && (
                        <PInfoBar
                          label={t('form.isBuilding')}
                          value={
                            currentProperty?.is_building === 1 ? 'Այո' : currentProperty?.is_building === 0 ? 'Ոչ' : '-'
                          }
                        ></PInfoBar>
                      )}
                      {currentProperty?.glass !== null && (
                        <PInfoBar label={t('form.glass')} value={currentProperty?.glass || '-'}></PInfoBar>
                      )}
                    </>
                  )}

                  {currentProperty?.youtube && (
                    <PInfoBar
                      label={t('form.video')}
                      value={
                        <a
                          href={currentProperty?.youtube}
                          target="_blank"
                          rel="noreferrer"
                          className="whitespace-nowrap text-ellipsis overflow-hidden max-w-full inline-block text-inherit"
                        >
                          {currentProperty?.youtube}
                        </a>
                      }
                    ></PInfoBar>
                  )}

                  {currentProperty && currentProperty!['3d'] && (
                    <PInfoBar
                      label={t('form.tour')}
                      value={
                        <a
                          href={currentProperty?.youtube}
                          target="_blank"
                          rel="noreferrer"
                          className="whitespace-nowrap text-ellipsis overflow-hidden max-w-full inline-block text-inherit"
                        >
                          {currentProperty!['3d']}
                        </a>
                      }
                    ></PInfoBar>
                  )}
                </div>

                {currentProperty?.info && (
                  <div className="mb-5">
                    <PTitle
                      textSize="medium"
                      text={t('common.propertyDescription')}
                      icon={<Icon.Pen fill={palette.common} />}
                    ></PTitle>
                    <div className="text-[14px] text-[#848484] leading-[140%]">{currentProperty?.info}</div>
                  </div>
                )}

                {currentFile?.files.length && (
                  <div className="mb-8">
                    <PTitle
                      textSize="medium"
                      text={t('common.attachedFiles')}
                      icon={<Icon.File width={20} color={palette.common} />}
                    ></PTitle>
                    <div className="rounded-[16px] px-4 bg-grey-40">
                      {currentFile?.files.map((file: any) => <PFileEntity key={file?.name} entity={file} />)}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </PCardWrapper>

          {currentProperty?.rooms?.length! > 0 ? (
            <PCardWrapper>
              <PTitle
                textSize="medium"
                text={t('form.rooms')}
                icon={<Icon.Building width={20} height={20} fill={palette.common} />}
              ></PTitle>

              <div className="flex flex-wrap gap-[16px] mb-2">
                {currentProperty?.rooms.map(room => (
                  <PropertyAddon entity={room} key={`${room.name}-${room.id}`}></PropertyAddon>
                ))}
              </div>
            </PCardWrapper>
          ) : null}

          {currentProperty?.floors?.length! > 0 ? (
            <PCardWrapper>
              <PTitle
                textSize="medium"
                text={t('form.floors')}
                icon={<Icon.Building width={20} height={20} fill={palette.common} />}
              ></PTitle>

              <div className="flex flex-wrap gap-[16px] mb-2">
                {currentProperty?.floors.map((floor: any) => (
                  <PropertyAddon entity={floor} key={`${floor.name}-${floor.id}`}></PropertyAddon>
                ))}
              </div>
            </PCardWrapper>
          ) : null}
        </Col>

        <Col span={12}>
          {currentProperty?.disabled_to && <PFrozenProperty values={currentProperty} className="mb-5" />}

          {currentProperty?.persons?.length ? (
            <PCardWrapper padding="md" className="mb-[20px]">
              <PTitle textSize="medium" text={t('common.contacts')} icon={<Icon.User fill={palette.common} />}></PTitle>
              <div>
                {currentProperty?.persons.map(p => (
                  <div
                    key={p.id}
                    className="rounded-[16px] px-4 bg-grey-40 mb-[16px] last:mb-0 flex items-center justify-between"
                  >
                    <div className="flex items-center gap-4">
                      <PInfoBar className="!border-0 !flex gap-1 min-w-[70px]" value={<b>{p?.name}:</b>}></PInfoBar>
                      <PInfoBar
                        className="!border-0 !flex gap-1"
                        value={p.phones.map(n => (
                          <CTextCopy key={`${n.id}-${n?.number}`} text={n?.number} />
                        ))}
                      ></PInfoBar>

                      {p.email && <PInfoBar className="!border-0 !flex gap-1" value={p.email}></PInfoBar>}
                      {p.relation && <PInfoBar className="!border-0 !flex gap-1" value={p.relation}></PInfoBar>}
                    </div>
                    <a href={`tel:${p.phones[0]?.number}`}>
                      <Icon.Calling width={20} height={20} />
                    </a>
                  </div>
                ))}
              </div>
            </PCardWrapper>
          ) : null}

          <PCardWrapper>
            <div>
              <PTitle textSize="medium" text={t('common.price')} icon={<Icon.Price color={palette.common} />}></PTitle>
              <div className="rounded-[16px] px-4 bg-grey-40">
                {currentProperty?.price?.total_sale && (
                  <PInfoBar
                    label={t('form.sale')}
                    value={` ${formatNumber(currentProperty?.price?.total_sale)} ${
                      currentProperty?.price?.currency_sale
                        ? getTranslation(currencySymbols, currentProperty?.price?.currency_sale)?.props.children
                        : ''
                    } ${
                      currentProperty?.price?.unit_sale
                        ? ` | ${formatNumber(Math.ceil(currentProperty?.price?.unit_sale))} ${
                            currentProperty?.price?.currency_sale
                              ? getTranslation(currencySymbols, currentProperty?.price?.currency_sale)?.props.children
                              : ''
                          }/${t('shorts.squareMeter')} `
                        : ''
                    }`}
                  ></PInfoBar>
                )}

                {(currentProperty?.rent === ContractTypesEnum.MONTHLY ||
                  currentProperty?.rent === ContractTypesEnum.ALL) && (
                  <PInfoBar
                    label={t('form.rent')}
                    value={`${formatNumber(currentProperty?.price?.total_monthly)} ${
                      currentProperty?.price?.currency_monthly
                        ? getTranslation(currencySymbols, currentProperty?.price?.currency_monthly)?.props.children
                        : ''
                    } ${
                      currentProperty?.price?.unit_monthly
                        ? ` | ${formatNumber(Math.ceil(currentProperty?.price?.unit_monthly))} ${
                            currentProperty?.price?.currency_monthly
                              ? getTranslation(currencySymbols, currentProperty?.price?.currency_monthly)?.props
                                  .children
                              : ''
                          }/${t('shorts.squareMeter')} `
                        : ''
                    }`}
                  ></PInfoBar>
                )}

                {(currentProperty?.rent === ContractTypesEnum.DAILY ||
                  currentProperty?.rent === ContractTypesEnum.ALL) && (
                  <PInfoBar
                    label={t('form.rentDaily')}
                    value={`${formatNumber(currentProperty?.price?.total_daily)} ${
                      currentProperty?.price?.currency_daily
                        ? getTranslation(currencySymbols, currentProperty?.price?.currency_daily)?.props.children
                        : ''
                    }`}
                  ></PInfoBar>
                )}
              </div>

              {currentProperty?.prices?.length! > 0 ? (
                <PTable
                  className="mt-[30px]"
                  columns={pricesColumns({translation: t})}
                  dataSource={currentProperty?.prices}
                  paginationOptions={{pageSize: 2}}
                  rowKey="id"
                  showHeader={true}
                />
              ) : null}
            </div>
          </PCardWrapper>

          {currentProperty?.holds?.length! > 0 ? (
            <PCardWrapper>
              <div>
                <PTitle
                  textSize="medium"
                  text={t('form.available')}
                  icon={<Icon.Bookmark width={20} color={palette.common} />}
                ></PTitle>

                <PTable
                  className="mt-[30px]"
                  columns={bookingsColumns({
                    propertyId: currentProperty?.id,
                    hideActions: profile?.type === RolesEnum.MARKETING,
                    translation: t,
                  })}
                  dataSource={currentProperty?.holds}
                  paginationOptions={{pageSize: 5}}
                  rowKey="id"
                  showHeader={true}
                />
              </div>
            </PCardWrapper>
          ) : null}

          {currentProperty?.building?.id && (
            <>
              <PCardWrapper padding="md" className="mb-[20px]">
                <div className="flex items-center justify-between mb-3">
                  <PTitle textSize="large" text={t('form.buildingInfo')}></PTitle>
                  {profile?.type !== RolesEnum.MARKETING ? (
                    <Link
                      to={`${RoutesEnum.BUILDINGS}/${ActionModesEnum.UPDATE}/${currentProperty?.building?.id}?property=${id}`}
                    >
                      <Icon.Edit
                        width={24}
                        height={24}
                        className="text-grey-common hover:text-primary transition-all"
                      />
                    </Link>
                  ) : null}
                </div>

                {propertyBuildingImage?.id === currentProperty?.building?.image_folder &&
                  propertyBuildingImage?.files?.length && (
                    <div className="mb-6">
                      <PCarousel
                        dots={false}
                        arrows={Object.keys(propertyBuildingImage?.files)?.length > 1}
                        className="rounded-[16px] overflow-hidden"
                      >
                        {propertyBuildingImage?.files.map((img: any, i: number) => (
                          <img
                            key={img}
                            onError={() => {
                              setSrcReservePath([...srcReservePath, img.path]);
                            }}
                            src={srcReservePath.includes(img.path) ? Image.noImage : injectMediaPath(img?.path)}
                            onClick={e => {
                              e.stopPropagation();
                              setIsLighboxVisible(prev => ({...prev, building: true}));
                              setLighboxStartIndex(i);
                            }}
                            width="100%"
                            className="cursor-pointer"
                            alt="pic"
                          />
                        ))}
                      </PCarousel>
                      <div className="hidden">
                        <PLightbox
                          showThumbnails={true}
                          modalClose="clickOutside"
                          open={isLighboxVisible.building}
                          startingSlideIndex={lighboxStartIndex}
                          onClose={() => setIsLighboxVisible(prev => ({...prev, building: false}))}
                          className="hidden"
                        >
                          {propertyBuildingImage?.files.map((img: any) => (
                            <img
                              key={img}
                              onError={() => {
                                setSrcReservePath([...srcReservePath, img.path]);
                              }}
                              src={srcReservePath.includes(img.path) ? Image.noImage : injectMediaPath(img?.path)}
                              width="100%"
                              alt="pic"
                            />
                          ))}
                        </PLightbox>
                      </div>
                    </div>
                  )}

                <PInfoBar label={t('form.address')} value={fullAdress}></PInfoBar>
                <PInfoBar label={t('form.propertyId')} value={currentProperty?.building?.id}></PInfoBar>
                <PInfoBar label={t('form.floors')} value={currentProperty?.building?.floors}></PInfoBar>
                <PInfoBar
                  label={t('form.buildingUsage')}
                  value={getTranslation(PropertyTypesTranslationEnum, currentProperty?.building?.type!)}
                ></PInfoBar>
                {currentProperty?.building?.type === BuildingTypeCommonEnum.RESIDENTIAL ? (
                  <PInfoBar
                    label={t('form.buildingType')}
                    value={getTranslation(
                      BuildingTypesTranslationEnum,
                      currentProperty?.building?.residential?.subtype
                    )}
                  ></PInfoBar>
                ) : null}
                {currentProperty?.building?.type === BuildingTypeCommonEnum.COMMERCIAL ? (
                  <PInfoBar
                    label={t('form.buildingType')}
                    value={getTranslation(BuildingTypesTranslationEnum, currentProperty?.building?.commercial?.subtype)}
                  ></PInfoBar>
                ) : null}
                {currentProperty?.building?.type === BuildingTypeCommonEnum.RESIDENTIAL
                  ? currentProperty?.building?.residential?.stone && (
                      <PInfoBar
                        label={t('form.buildingSubtype')}
                        value={
                          catalog[OptGroupsEnum.BUILDING_SUBTYPE_STONE]?.find(
                            o => o.value === currentProperty?.building?.residential?.stone
                          )?.label as string
                        }
                      ></PInfoBar>
                    )
                  : null}
                {currentProperty?.building?.subtype === BuildingSubTypesEnum.NEW ? (
                  <PInfoBar label={t('catalog.buildingTypes.new')} value={t('common.yes')}></PInfoBar>
                ) : null}
                {currentProperty?.building?.type === BuildingTypeCommonEnum.RESIDENTIAL
                  ? currentProperty?.building?.residential?.heating && (
                      <PInfoBar
                        label={t('form.heating')}
                        value={getTranslation(HeatingTranslationEnum, currentProperty?.building?.residential?.heating)}
                      ></PInfoBar>
                    )
                  : null}
                {currentProperty?.building?.type === BuildingTypeCommonEnum.RESIDENTIAL
                  ? currentProperty?.building?.residential?.fee && (
                      <PInfoBar
                        label={t('form.fee')}
                        value={`${formatNumber(currentProperty?.building?.residential?.fee)} ${
                          currencySymbols.AMD.props.children
                        }`}
                      ></PInfoBar>
                    )
                  : null}

                {propertyBuildingFile?.files?.length ? (
                  <div className="mt-8">
                    <PTitle
                      textSize="medium"
                      text={t('common.attachedFiles')}
                      icon={<Icon.File width={20} color={palette.common} />}
                    ></PTitle>
                    <div className="rounded-[16px] px-4 bg-grey-40">
                      {propertyBuildingFile?.files.map((file: any) => <PFileEntity key={file?.name} entity={file} />)}
                    </div>
                  </div>
                ) : null}
              </PCardWrapper>

              {currentProperty?.building?.info ? (
                <PCardWrapper padding="md" className="mb-[20px]">
                  <PTitle
                    textSize="medium"
                    text={t('common.buildingDescription')}
                    icon={<Icon.Pen fill={palette.common} />}
                  ></PTitle>
                  <div className="text-[14px] text-[#848484] leading-[140%]">{currentProperty?.building?.info}</div>
                </PCardWrapper>
              ) : null}
            </>
          )}

          {currentProperty?.location?.address?.lat && currentProperty?.location?.address?.lng && (
            <PCardWrapper>
              <div>
                <PTitle
                  textSize="medium"
                  theme="danger"
                  text={`${currentProperty?.location?.country?.name} / ${
                    currentProperty?.location?.region?.name ? `${currentProperty?.location?.region?.name} / ` : ''
                  } ${
                    currentProperty?.location?.district?.name ? `${currentProperty?.location?.district?.name} / ` : ''
                  } ${
                    currentProperty?.location?.settlement?.name &&
                    currentProperty?.location?.settlement?.name !== currentProperty?.location?.region?.name
                      ? `${currentProperty?.location?.settlement?.name} / `
                      : ''
                  } ${currentProperty?.location?.street?.name ? `${currentProperty?.location?.street?.name}` : ''}`}
                  icon={<Icon.Map fill={palette.danger.primary} />}
                ></PTitle>

                <CMap zoom={16} initialAddress={currentProperty?.location?.address!} />
              </div>
            </PCardWrapper>
          )}
        </Col>

        {currentProperty?.status !== StatusEnum.ARCHIVED && (
          <Col span={24} className="text-right">
            <Link to={`${RoutesEnum.PROPERTIES}/${ActionModesEnum.UPDATE}/${id}`}>
              <PButton type="primary">{t('actions.update')}</PButton>
            </Link>
          </Col>
        )}
      </Row>
    </PLoader>
  );
};
