import {memo} from 'react';

import {PCardWrapperProps} from './p-card-wrapper.types';
import {CSS} from './p-card-wrapper.cssconfig';

const PCardWrapper = ({children, padding, animate, className}: PCardWrapperProps) => {
  return (
    <div
      className={`${CSS.root.base} ${animate ? 'animate-[fadein_0.5s_ease-in-out] origin-top' : ''} ${
        padding === 'sm' ? CSS.root.spaceSM : padding === 'md' ? CSS.root.spaceMD : CSS.root.spaceDefault
      } ${className || ''}`}
    >
      {children}
    </div>
  );
};

export const PCardWrapperMemoized = memo(PCardWrapper);
