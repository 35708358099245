import {memo, useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Form, RadioChangeEvent} from 'antd';
import {useTranslation} from 'react-i18next';

import {PopupBase} from 'components/popups/base';
import {FormChangeBusinessProcess} from 'components/forms/change-business-process';
import {IChangeBusinessProcessFields} from 'components/forms/change-business-process/form-change-business-process.interfaces';
import {archivePropertyAction, disablePropertyAction} from 'store/properties/actions';
import {formatDate} from 'utils';
import {FormatsEnum, SeparatorEnum, StatusEnum} from 'enums';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {useToast} from 'hooks';

const PopupChangeProcess = ({onClose, id}: {id: number; onClose: () => void}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {showError, contextHolder} = useToast();
  const [form] = Form.useForm<IChangeBusinessProcessFields>();
  const [isSubmittable, setIsSubmittable] = useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const values = Form.useWatch([], form);
  const [status, setStatus] = useState<StatusEnum>(StatusEnum.ARCHIVED);

  const handleStatusChange = useCallback((e: RadioChangeEvent) => {
    setStatus(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    (values: IChangeBusinessProcessFields) => {
      setIsButtonLoading(true);

      const valuesToSend = {
        ...values,
        property: id,
        to: values.to
          ? formatDate({value: values.to!, format: FormatsEnum.REVERSE, separator: SeparatorEnum.HYPHEN})
          : null,
      };

      status === StatusEnum.ARCHIVED
        ? dispatch(
            archivePropertyAction(valuesToSend, {
              onFulfilled: () => {
                setIsButtonLoading(false);
                onClose();
              },
              onReject: () => {
                setIsButtonLoading(false);
                showError({message: fieldsMessageTranslations.commonErrors});
              },
            })
          )
        : dispatch(
            disablePropertyAction(valuesToSend, {
              onFulfilled: () => {
                setIsButtonLoading(false);
                onClose();
              },
              onReject: () => {
                setIsButtonLoading(false);
                showError({message: fieldsMessageTranslations.commonErrors});
              },
            })
          );
    },
    [id, status, showError, dispatch, onClose]
  );

  const submit = useCallback(() => {
    handleSubmit(values);
  }, [values, handleSubmit]);

  useEffect(() => {
    form.validateFields({validateOnly: true}).then(
      () => {
        setIsSubmittable(true);
      },
      () => {
        setIsSubmittable(false);
      }
    );
  }, [values, form, status]);

  return (
    <PopupBase
      title={t('actions.changeBusinessProcess')}
      onCrossClick={onClose}
      onSubmit={submit}
      open
      type="prompt"
      buttons={{
        reject: {
          name: t('actions.close'),
          type: 'text',
          onClick: onClose,
        },
        confirm: {
          name: t('actions.confirm'),
          type: 'primary',
          htmlType: 'button',
          disabled: !isSubmittable,
          loading: isButtonLoading,
          onClick: submit,
        },
      }}
    >
      <FormChangeBusinessProcess
        form={form}
        values={values}
        statusType={status}
        onSumbit={handleSubmit}
        handleStatusChange={handleStatusChange}
      />
      {contextHolder}
    </PopupBase>
  );
};

export const PopupChangeProcessMemoized = memo(PopupChangeProcess);
