import {memo, useCallback, useMemo} from 'react';
import {InputNumber} from 'antd';

import {PFormLabel} from 'components/primitives/p-form-label';
import {numberFormatter, numberParser, numbersAndSymbols} from 'constants/regular-expressions';

import {PNumericInputProps} from './p-numeric-input.types';

const PNumericInput = ({
  label,
  id,
  type,
  size = 'large',
  className,
  innerClassName,
  withoutFormatter,
  ...props
}: PNumericInputProps) => {
  const onKeyPress = useCallback((e: any) => {
    const regex = new RegExp(numbersAndSymbols);
    const key = e.charCode ? e.which : e.charCode;
    const char = String.fromCharCode(key);

    if (!regex.test(char) && key !== 13) {
      e.preventDefault();

      return false;
    }
  }, []);

  const formatterProps = useMemo(
    () =>
      type === 'number'
        ? {}
        : {
            formatter: (value: string | number | undefined) =>
              withoutFormatter ? `${value}` : `${value}`.replace(numberFormatter, ','),
            parser: (value: string | number | undefined) =>
              withoutFormatter ? `${value}` : (value as string)!.replace(numberParser, ''),
            onKeyPress,
          },
    [withoutFormatter, type, onKeyPress]
  );

  return (
    <div className={`${className} relative`}>
      {label && <PFormLabel text={label} id={id} />}

      <InputNumber
        {...props}
        id={id}
        className={innerClassName}
        size={size}
        controls={false}
        keyboard={false}
        style={{width: '100%'}}
        {...formatterProps}
      />
    </div>
  );
};

export const PNumericInputMemoized = memo(PNumericInput);
