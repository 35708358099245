import {memo} from 'react';
import {Table} from 'antd';

import {tablePaginationConfig} from 'constants/pagination';
import {PaginationEnum, SortOrderEnum} from 'enums';

import {ITableProps} from './p-table.interfaces';
import {CSS} from './p-table.cssconfig';

const PTable = ({dataSource, showLeged, paginationOptions, sortOptions, handleChange, ...props}: ITableProps) => {
  return (
    <div>
      {showLeged && (
        <div className={CSS.legend}>Գտնվել է {paginationOptions?.total || dataSource?.length} արդյունք </div>
      )}
      <Table
        {...props}
        dataSource={dataSource}
        pagination={{
          ...paginationOptions,
          pageSizeOptions: [tablePaginationConfig.pageSize!, 50, 100],
          showQuickJumper: false,
          showSizeChanger: false,
          hideOnSinglePage: true,
          position: tablePaginationConfig.position,
        }}
        onChange={(pagination, filters, sorter) => {
          const paging = {
            [PaginationEnum.PAGE]: pagination.current!,
            [PaginationEnum.OFFSET]: (pagination.current! - 1) * pagination.pageSize!,
            [PaginationEnum.LENGTH]: pagination.pageSize!,
          };
          const sorterOption = sorter['order' as keyof typeof sorter]
            ? {
                [SortOrderEnum.SORT]: sorter['field' as keyof typeof sorter],
                [SortOrderEnum.ASC]: sorter['order' as keyof typeof sorter] === SortOrderEnum.ASCEND ? 1 : null,
                [SortOrderEnum.DESC]: sorter['order' as keyof typeof sorter] === SortOrderEnum.DESCEND ? 1 : null,
              }
            : {[SortOrderEnum.SORT]: null, [SortOrderEnum.ASC]: null, [SortOrderEnum.DESC]: null};

          handleChange && handleChange(paging, sorterOption);
        }}
      />
    </div>
  );
};

export const PTableMemoized = memo(PTable);
