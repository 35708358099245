import {TActionSettings} from 'types/common';

export const addBuildingAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/addBuildingAction',
  payload,
  settings: settings || {},
});

export const updateBuildingAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/updateBuildingAction',
  payload,
  settings: settings || {},
});

export const getBuildingsAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'shared/getBuildingsAction',
  payload,
  settings: settings || {},
});

export const getBuildingByIdAction = (payload?: {id?: number | string}, settings?: TActionSettings) => ({
  type: 'shared/getBuildingByIdAction',
  payload,
  settings: settings || {},
});

export const saveBuildingFilterPresetsAction = (payload?: any) => ({
  type: 'buildings/saveBuildingFilterPresetsAction',
  payload,
});

export const hasUnsavedChangesAction = (payload?: boolean) => ({
  type: 'buildings/hasUnsavedChangesAction',
  payload,
});

export const clearBuildingErrorsAction = () => ({
  type: 'buildings/clearBuildingErrorsAction',
});

export const clearBuildingsAction = () => ({
  type: 'buildings/clearBuildingsAction',
});

export const clearCurrentBuildingAction = () => ({
  type: 'buildings/clearCurrentBuildingAction',
});
