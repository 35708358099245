import {DefaultOptionType} from 'antd/es/select';

import {formLabelTranslations} from 'constants/form-labels-translations';
import {ContractTypesEnum, OptGroupsEnum} from 'enums';
import {TCatalog} from 'types/api';
import {
  BuildingTypesTranslationEnum,
  ContractTypesTranslationEnum,
  PropertyTypesTranslationEnum,
} from 'enums/translations';

import {
  checkForArrayValidity,
  clearFromEmptyKeys,
  formatDate,
  formatNumber,
  getTranslation,
  omittedParams,
} from './index';

export const translateFilter = <T>({
  params,
  FiledsEnum,
  catalog,
}: {
  params: Record<string, any>;
  FiledsEnum: any;
  catalog: TCatalog;
}): T => {
  const {
    'status[]': status,
    mode,
    type,
    modified,
    available,
    stone,
    buildingResidentialHeating,
    buildingResidentialSubtype,
    heating,
    entryPosition,
    entryType,
    view,
    markers,
    yardMarkers,
    options,
    usage,
    repair,
    'region[]': region,
    'settlement[]': settlement,
    'district[]': district,
    'street[]': street,
    ...rest
  } = omittedParams(params);

  const formattedRest = Object.fromEntries(
    Object.entries(rest).map(([key, value]) => [
      [getTranslation(formLabelTranslations, key) || key],
      typeof value === 'number' ? formatNumber(value) : value,
    ])
  );

  const values = {
    [formLabelTranslations.propertyType]: getTranslation(PropertyTypesTranslationEnum, type),
    [getTranslation(formLabelTranslations, FiledsEnum.MODE)]: mode
      ?.filter((m: string) => m !== ContractTypesEnum.ALL)
      .map((m: string) => getTranslation(ContractTypesTranslationEnum, m))
      .join(', '),

    [getTranslation(formLabelTranslations, FiledsEnum.MODIFIED)]:
      modified && modified[0] && modified[1]
        ? `${formatDate({value: new Date(modified[0])})} - ${formatDate({value: new Date(modified[1])})}`
        : null,
    [getTranslation(formLabelTranslations, FiledsEnum.AVAILABLE)]:
      available && available[0] && available[1]
        ? `${formatDate({value: new Date(available[0])})} - ${formatDate({value: new Date(available[1])})}`
        : null,
    [getTranslation(formLabelTranslations, FiledsEnum.REGION)]: checkForArrayValidity(region)
      ?.map((v: DefaultOptionType) => v.label)
      ?.join(', '),
    [getTranslation(formLabelTranslations, FiledsEnum.SETTLEMENT)]: checkForArrayValidity(settlement)
      ?.map((v: DefaultOptionType) => v.label)
      ?.join(', '),
    [getTranslation(formLabelTranslations, FiledsEnum.DISTRICT)]: checkForArrayValidity(district)
      ?.map((v: DefaultOptionType) => v.label)
      ?.join(', '),
    [getTranslation(formLabelTranslations, FiledsEnum.STREET)]: checkForArrayValidity(street)
      ?.map((v: DefaultOptionType) => v.label)
      ?.join(', '),
    [getTranslation(formLabelTranslations, FiledsEnum.STONE)]: checkForArrayValidity(stone)
      ?.map(v => catalog[OptGroupsEnum.BUILDING_SUBTYPE_STONE]?.find((o: any) => o.value === v)?.label)
      .join(', '),
    [getTranslation(formLabelTranslations, FiledsEnum.BUILDING_RESIDENTIAL_HEATING)]:
      checkForArrayValidity(buildingResidentialHeating),
    [getTranslation(formLabelTranslations, FiledsEnum.BUILDING_RESIDENTIAL_SUBTYPE)]: checkForArrayValidity(
      buildingResidentialSubtype
    )
      ?.map(b => getTranslation(BuildingTypesTranslationEnum, b))
      .join(', '),
    [getTranslation(formLabelTranslations, FiledsEnum.HEATING)]: checkForArrayValidity(heating)
      ?.map(v => catalog[OptGroupsEnum.HEATING]?.find((o: any) => o.value === v)?.label)
      .join(', '),
    [getTranslation(formLabelTranslations, FiledsEnum.ENTRY_POSITION)]: checkForArrayValidity(entryPosition)
      ?.map(v => catalog[OptGroupsEnum.ENTRY_POSITION]?.find((o: any) => o.value === v)?.label)
      .join(', '),
    [getTranslation(formLabelTranslations, FiledsEnum.ENTRY_TYPE)]: checkForArrayValidity(entryType)
      ?.map(v => catalog[OptGroupsEnum.ENTRY_TYPE]?.find((o: any) => o.value === v)?.label)
      .join(', '),
    [getTranslation(formLabelTranslations, FiledsEnum.VIEW)]: checkForArrayValidity(view)
      ?.map(v => catalog[OptGroupsEnum.VIEW]?.find((o: any) => o.value === v)?.label)
      .join(', '),
    [getTranslation(formLabelTranslations, FiledsEnum.MARKERS)]: checkForArrayValidity(markers),
    [getTranslation(formLabelTranslations, FiledsEnum.YARD_MARKERS)]: checkForArrayValidity(yardMarkers)
      ?.map(v => catalog[OptGroupsEnum.YARD_MARKERS]?.find((o: any) => o.value === v)?.label)
      .join(', '),
    [getTranslation(formLabelTranslations, FiledsEnum.OPTIONS)]: checkForArrayValidity(options)
      ?.map(v => catalog[OptGroupsEnum.APPARTMENT_MARKERS]?.find((o: any) => o.value === v)?.label)
      .join(', '),
    [getTranslation(formLabelTranslations, FiledsEnum.USAGE)]: checkForArrayValidity(usage)
      ?.map(v => catalog[OptGroupsEnum.SOIL_IMPORTANCE]?.find((o: any) => o.value === v)?.label)
      .join(', '),
    [getTranslation(formLabelTranslations, FiledsEnum.REPAIR)]: checkForArrayValidity(repair)
      ?.map(v => catalog[OptGroupsEnum.REPAIR_TYPES]?.find((o: any) => o.value === v)?.label)
      .join(', '),
    ...formattedRest,
  };

  return clearFromEmptyKeys<T>(values);
};
