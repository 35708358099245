import {memo, useEffect, useMemo, useState} from 'react';
import {RcFile} from 'antd/es/upload';

import {PLightbox} from 'components/primitives/p-lightbox';
import {getBase64} from 'utils';

const ImageGallery = ({
  uploadedFiles,
  lighboxStartIndex,
  isPreviewOpen,
  onPreviewCancel,
}: {
  uploadedFiles: any;
  lighboxStartIndex: number;
  isPreviewOpen: boolean;
  onPreviewCancel: () => void;
}) => {
  const [galleryItems, setGalleryItems] = useState<any[]>([]);

  const images = useMemo(() => galleryItems?.map((f: any) => ({src: f.preview, alt: f.name})), [galleryItems]);

  useEffect(() => {
    const createImagesWithPreview = async () => {
      const gallery = await Promise.all(
        uploadedFiles.map(async (file: any) => {
          const preview = file.originFileObj ? await getBase64(file.originFileObj as RcFile) : file.url;

          return {
            ...file,
            preview,
          };
        })
      );

      setGalleryItems(gallery);
    };

    createImagesWithPreview();
  }, [uploadedFiles]);

  return galleryItems && galleryItems.length > 0 ? (
    <div className="hidden">
      <PLightbox
        images={images}
        showThumbnails={true}
        modalClose="clickOutside"
        startingSlideIndex={lighboxStartIndex}
        open={isPreviewOpen}
        onClose={onPreviewCancel}
        className="hidden"
      >
        {galleryItems?.map((img: any) => (
          <img key={img.uid} src={img.preview} width="100%" alt="pic" className={isPreviewOpen ? '' : 'hidden'} />
        ))}
      </PLightbox>
    </div>
  ) : (
    <></>
  );
};

export const PreviewGalleryMemoized = memo(ImageGallery);
