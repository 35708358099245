import {takeEvery, all, put, call} from 'redux-saga/effects';

import {TActionSettings, TServerResponseError} from 'types/common';
import {TAnalyticsPerformance, TInquiryTotals, TLeadTotals, TPropertyTotals} from 'types/api';
import {
  getInquiryPerformanceService,
  getInquiryTotalsService,
  getLeadPerformanceService,
  getLeadTotalsService,
  getPropertyPerformanceService,
  getPropertyTotalsService,
} from 'services/analytics';

import {
  clearAnalyticsErrorsAction,
  getInquiryPerformanceAction,
  getInquiryTotalsAction,
  getLeadPerformanceAction,
  getLeadTotalsAction,
  getPropertyPerformanceAction,
  getPropertyTotalsAction,
} from './actions';
import {
  setErrors,
  setInquiryPerformance,
  setInquiryTotals,
  setLeadPerformance,
  setLeadTotals,
  setLoading,
  setPropertyPerformance,
  setPropertyTotals,
} from './slice-analytics';

const getPropertyTotalsSaga = function* ({
  payload: {...payload},
  settings: {onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield put(setLoading(true));

  try {
    const response: {data: TPropertyTotals} = yield call(getPropertyTotalsService, payload);

    yield put(setPropertyTotals(response.data));

    onFulfilled && onFulfilled();
  } catch (e) {
    console.error(e);
    yield put(setErrors(e as TServerResponseError));
    onReject && onReject(e);
  } finally {
    yield put(setLoading(false));
  }
};

const getInquiryTotalsSaga = function* ({
  payload: {...payload},
  settings: {onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield put(setLoading(true));

  try {
    const response: {data: TInquiryTotals} = yield call(getInquiryTotalsService, payload);

    yield put(setInquiryTotals(response.data));

    onFulfilled && onFulfilled();
  } catch (e) {
    console.error(e);
    yield put(setErrors(e as TServerResponseError));
    onReject && onReject(e);
  } finally {
    yield put(setLoading(false));
  }
};

const getLeadTotalsSaga = function* ({
  payload: {...payload},
  settings: {onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield put(setLoading(true));

  try {
    const response: {data: TLeadTotals} = yield call(getLeadTotalsService, payload);

    yield put(setLeadTotals(response.data));

    onFulfilled && onFulfilled();
  } catch (e) {
    console.error(e);
    yield put(setErrors(e as TServerResponseError));
    onReject && onReject(e);
  } finally {
    yield put(setLoading(false));
  }
};

const getPropertyPerformanceSaga = function* ({
  payload: {...payload},
  settings: {onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield put(setLoading(true));

  try {
    const response: {data: TAnalyticsPerformance[]} = yield call(getPropertyPerformanceService, payload);

    yield put(setPropertyPerformance(response.data));

    onFulfilled && onFulfilled();
  } catch (e) {
    console.error(e);
    yield put(setErrors(e as TServerResponseError));
    onReject && onReject(e);
  } finally {
    yield put(setLoading(false));
  }
};

const getInquiryPerformanceSaga = function* ({
  payload: {...payload},
  settings: {onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield put(setLoading(true));

  try {
    const response: {data: TAnalyticsPerformance[]} = yield call(getInquiryPerformanceService, payload);

    yield put(setInquiryPerformance(response.data));

    onFulfilled && onFulfilled();
  } catch (e) {
    console.error(e);
    yield put(setErrors(e as TServerResponseError));
    onReject && onReject(e);
  } finally {
    yield put(setLoading(false));
  }
};

const getLeadPerformanceSaga = function* ({
  payload: {...payload},
  settings: {onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield put(setLoading(true));

  try {
    const response: {data: TAnalyticsPerformance[]} = yield call(getLeadPerformanceService, payload);

    yield put(setLeadPerformance(response.data));

    onFulfilled && onFulfilled();
  } catch (e) {
    console.error(e);
    yield put(setErrors(e as TServerResponseError));
    onReject && onReject(e);
  } finally {
    yield put(setLoading(false));
  }
};

const clearAnalyticsErrorsSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setErrors(null));
  yield put(setLoading(false));
};

export const analyticsSaga = function* () {
  yield all([takeEvery(getPropertyTotalsAction().type, getPropertyTotalsSaga)]);
  yield all([takeEvery(getInquiryTotalsAction().type, getInquiryTotalsSaga)]);
  yield all([takeEvery(getLeadTotalsAction().type, getLeadTotalsSaga)]);
  yield all([takeEvery(getPropertyPerformanceAction().type, getPropertyPerformanceSaga)]);
  yield all([takeEvery(getInquiryPerformanceAction().type, getInquiryPerformanceSaga)]);
  yield all([takeEvery(getLeadPerformanceAction().type, getLeadPerformanceSaga)]);
  yield all([takeEvery(clearAnalyticsErrorsAction().type, clearAnalyticsErrorsSaga)]);
};
