import {memo, useCallback, useState} from 'react';
import {Popconfirm, Skeleton} from 'antd';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {updatePropertyPriceAction} from 'store/properties/actions';
import {PNumericInput} from 'components/primitives/p-numeric-input';
import {PricesFieldsEnum} from 'components/forms/update-price/form-update-price.enums';
import {divide, multiply} from 'utils';
import {PSelect} from 'components/primitives/p-select';
import {currencyOptions} from 'constants/select-options';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {useToast} from 'hooks';

import {ICUpdateCommentProps} from './c-price-update.types';

const CPriceUpdate = ({
  children,
  initialValues,
  property,
  total_field,
  unit_field,
  currency_field,
  ...props
}: ICUpdateCommentProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {showError, contextHolder} = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [price, setPrice] = useState<number | null>(initialValues?.total!);
  const [unitPrice, setUnitPrice] = useState<number | null>(initialValues?.unit!);
  const [currency, setCurrency] = useState<string | null>(initialValues?.currency!);

  const onUpdate = useCallback(() => {
    const data = {
      property: property.id,
      [PricesFieldsEnum.TOTAL_PRICE_SALE]: property.price[PricesFieldsEnum.TOTAL_SALE],
      [PricesFieldsEnum.TOTAL_PRICE_MONTHLY]: property.price[PricesFieldsEnum.TOTAL_MONTHLY],
      [PricesFieldsEnum.TOTAL_PRICE_DAILY]: property.price[PricesFieldsEnum.TOTAL_DAILY],
      [PricesFieldsEnum.CURRENCY_SALE]: property.price[PricesFieldsEnum.CURRENCY_SALE],
      [PricesFieldsEnum.CURRENCY_MONTHLY]: property.price[PricesFieldsEnum.CURRENCY_MONTHLY],
      [PricesFieldsEnum.CURRENCY_DAILY]: property.price[PricesFieldsEnum.CURRENCY_DAILY],

      [`${total_field}`]: price,
      [`${unit_field}`]: unitPrice,
      [`${currency_field}`]: currency,
    };

    setIsLoading(true);

    dispatch(
      updatePropertyPriceAction(data, {
        onFulfilled: () => setIsLoading(false),
        onReject: () => {
          setIsLoading(false);
          showError({message: fieldsMessageTranslations.commonErrors});
        },
      })
    );
  }, [price, unitPrice, currency, property, total_field, unit_field, currency_field, showError, dispatch]);

  const handlePrice = useCallback(
    (value: number, mode: 'total' | 'unit') => {
      const area = property.area || property?.square_metre || property?.total_area;

      if (area && mode === 'total') {
        const unit = divide(value, area, {toFix: 0});

        setPrice(value);
        setUnitPrice(unit);
      }

      if (!area && mode === 'total') {
        setPrice(value);
      }

      if (area && mode === 'unit') {
        const total = multiply(value, area, {toFix: 0});

        setPrice(total);
        setUnitPrice(value);
      }

      if (!area && mode === 'unit') {
        setUnitPrice(value);
      }
    },
    [property]
  );

  const handleCurrency = useCallback((value: any) => {
    setCurrency(value);
  }, []);

  return (
    <>
      <Popconfirm
        {...props}
        icon={null}
        description={
          <div className="grid gap-4 mt-3 mb-1.5">
            <PNumericInput
              label={t('form.price')}
              size="middle"
              defaultValue={price!}
              value={price}
              onChange={value => handlePrice(Number(value), 'total')}
            />
            {unit_field && (
              <PNumericInput
                label={`${t('form.price')} ${t('shorts.squareMeter')}`}
                size="middle"
                defaultValue={unitPrice!}
                value={unitPrice}
                onChange={value => handlePrice(Number(value), 'unit')}
              />
            )}
            <PSelect
              label={t('form.currency')}
              options={currencyOptions}
              defaultValue={currency}
              size="middle"
              onChange={handleCurrency}
            />
          </div>
        }
        onConfirm={onUpdate}
        cancelText={t('actions.close')}
        okText={t('actions.confirm')}
      >
        <>
          {isLoading ? (
            <Skeleton.Button shape="default" size="small" className="min-w-[90px]" block active />
          ) : (
            children
          )}
        </>
      </Popconfirm>
      {contextHolder}
    </>
  );
};

export const CPriceUpdateMemoized = memo(CPriceUpdate);
