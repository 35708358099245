import {Col, Form, Row, Space} from 'antd';
import {useSelector} from 'react-redux';
import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {PButton} from 'components/primitives/p-button';
import {PInput} from 'components/primitives/p-input';
import {PSelect} from 'components/primitives/p-select';
import {actionTypeOptions, contractModeOptions, flagOptions, propertyTypeOptions} from 'constants/select-options';
import {LeadFieldsEnum} from 'components/forms/lead/form-lead.enums';
import {appCatalogSelector} from 'store/app/selectors';
import {
  OptGroupsEnum,
  PropertyActionsTypesEnum,
  PropertyTypesEnum,
  ContractTypesEnum,
  FormsEnum,
  CustomerTypesEnum,
} from 'enums';
import {dateDefaultFormat} from 'constants/date-formats';
import {PRangepicker} from 'components/primitives/p-rangepicker';
import {PNumericInput} from 'components/primitives/p-numeric-input';
import {PPhoneInput} from 'components/primitives/p-phone-input';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {IFilterProps} from 'types/common';
import {CSubmitButton} from 'components/containers/c-submit-button';
import {Icon} from 'assets/icons';
import {useMountedOnce} from 'hooks';
import {FormPricesFilter} from 'components/forms/prices-filter';

import {ILeadsFilterFields} from './form-leads-filter.interfaces';
import {LeadFilterFieldsEnum} from './form-leads-filter.enums';

export const FormLeadsFilter = ({
  form,
  values,
  initialValues,
  isButtonLoading,
  onSubmit,
  onReset,
}: IFilterProps<ILeadsFilterFields>) => {
  const {t} = useTranslation();
  const catalog = useSelector(appCatalogSelector);
  const presets = useMemo(
    () => ({
      mode:
        values?.mode?.includes(ContractTypesEnum.SALE) &&
        !values?.mode?.includes(ContractTypesEnum.MONTHLY) &&
        !values?.mode?.includes(ContractTypesEnum.DAILY)
          ? CustomerTypesEnum.SALE
          : !values?.mode?.includes(ContractTypesEnum.SALE) &&
            (values?.mode?.includes(ContractTypesEnum.MONTHLY) || values?.mode?.includes(ContractTypesEnum.DAILY))
          ? ContractTypesEnum.RENT
          : values?.mode?.includes(ContractTypesEnum.SALE) &&
            (values?.mode?.includes(ContractTypesEnum.MONTHLY) || values?.mode?.includes(ContractTypesEnum.DAILY))
          ? CustomerTypesEnum.ALL
          : null,
      rent:
        values?.mode?.includes(ContractTypesEnum.MONTHLY) && values?.mode?.includes(ContractTypesEnum.DAILY)
          ? ContractTypesEnum.ALL
          : values?.mode?.includes(ContractTypesEnum.MONTHLY)
          ? ContractTypesEnum.MONTHLY
          : values?.mode?.includes(ContractTypesEnum.DAILY)
          ? ContractTypesEnum.DAILY
          : null,
    }),
    [values?.mode]
  );
  const hasInitialValues = useMemo(() => initialValues && Object.keys(initialValues).length > 0, [initialValues]);

  const setInitialValus = useMountedOnce(() => {
    form.setFieldsValue(initialValues!);
  });

  useEffect(() => {
    setInitialValus();
  }, [setInitialValus]);

  return (
    <Form form={form} onFinish={onSubmit} name={FormsEnum.LEAD_FILTER}>
      <Row gutter={16}>
        <Col span={3}>
          <Form.Item name={LeadFieldsEnum.TYPE}>
            <PSelect
              label={t('form.propertyType')}
              disabled={values?.action === PropertyActionsTypesEnum.OTHER}
              options={propertyTypeOptions}
              id={LeadFieldsEnum.TYPE}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={LeadFieldsEnum.ACTION}>
            <PSelect label={t('form.actionTarget')} options={actionTypeOptions} />
          </Form.Item>
        </Col>
        {values?.action !== PropertyActionsTypesEnum.OTHER && (
          <Col span={3}>
            <Form.Item name={LeadFieldsEnum.MODE}>
              <PSelect mode="multiple" label={t('form.contractType')} options={contractModeOptions} />
            </Form.Item>
          </Col>
        )}
        <Col span={5}>
          <Form.Item name={LeadFieldsEnum.ENTRANCE_DATE}>
            <PRangepicker
              label={t('form.entranceDate')}
              size="large"
              format={dateDefaultFormat}
              name={LeadFieldsEnum.ENTRANCE_DATE}
              placeholder={[t('form.start'), t('form.end')]}
            />
          </Form.Item>
        </Col>
        <Col span={24}></Col>

        <Col span={4}>
          <Form.Item name={LeadFieldsEnum.NAME}>
            <PInput label={t('form.firstName')} id={LeadFieldsEnum.NAME} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name={LeadFieldsEnum.PHONE}
            rules={[
              {min: 9, message: fieldsMessageTranslations.phoneMin},
              {max: 14, message: fieldsMessageTranslations.phoneMax},
            ]}
          >
            <PPhoneInput label={t('form.phone')} id={LeadFieldsEnum.PHONE} />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item name={LeadFieldsEnum.SOURCE}>
            <PSelect label={t('form.source')} options={catalog[OptGroupsEnum.SOURCE]} id={LeadFieldsEnum.SOURCE} />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name={LeadFieldsEnum.FLAG}>
            <PSelect label={t('form.flag')} options={flagOptions} />
          </Form.Item>
        </Col>

        <Col span={24}></Col>

        {values?.type &&
          values?.action !== PropertyActionsTypesEnum.OTHER &&
          (values?.type === PropertyTypesEnum.HOUSE ? (
            <>
              <Col span={5}>
                <Space.Compact>
                  <Form.Item name={LeadFilterFieldsEnum.MIN_LIVING_AREA}>
                    <PNumericInput
                      label={`${t('form.livingArea')} ${t('shorts.min')}`}
                      suffix={t('shorts.squareMeter')}
                      id={LeadFilterFieldsEnum.MIN_LIVING_AREA}
                    />
                  </Form.Item>
                  <Form.Item name={LeadFilterFieldsEnum.MAX_LIVING_AREA}>
                    <PNumericInput
                      label={t('shorts.max')}
                      suffix={t('shorts.squareMeter')}
                      id={LeadFilterFieldsEnum.MAX_LIVING_AREA}
                    />
                  </Form.Item>
                </Space.Compact>
              </Col>
              <Col span={5}>
                <Space.Compact>
                  <Form.Item name={LeadFilterFieldsEnum.MIN_LAND_AREA}>
                    <PNumericInput
                      label={`${t('form.landArea')} ${t('shorts.min')}`}
                      suffix={t('shorts.squareMeter')}
                      id={LeadFilterFieldsEnum.MIN_LAND_AREA}
                    />
                  </Form.Item>
                  <Form.Item name={LeadFilterFieldsEnum.MAX_LAND_AREA}>
                    <PNumericInput
                      label={t('shorts.max')}
                      suffix={t('shorts.squareMeter')}
                      id={LeadFilterFieldsEnum.MAX_LAND_AREA}
                    />
                  </Form.Item>
                </Space.Compact>
              </Col>
            </>
          ) : values?.type === PropertyTypesEnum.LAND ? (
            <>
              <Col span={5}>
                <Space.Compact>
                  <Form.Item name={LeadFilterFieldsEnum.MIN_SQUARE_METRE}>
                    <PNumericInput
                      label={`${t('form.area')} ${t('shorts.min')}`}
                      suffix={t('shorts.squareMeter')}
                      id={LeadFilterFieldsEnum.MIN_SQUARE_METRE}
                    />
                  </Form.Item>
                  <Form.Item name={LeadFilterFieldsEnum.MAX_SQUARE_METRE}>
                    <PNumericInput
                      label={t('shorts.max')}
                      suffix={t('shorts.squareMeter')}
                      id={LeadFilterFieldsEnum.MAX_SQUARE_METRE}
                    />
                  </Form.Item>
                </Space.Compact>
              </Col>
              <Col span={5}>
                <Space.Compact>
                  <Form.Item name={LeadFilterFieldsEnum.MIN_HECTARE}>
                    <PNumericInput
                      withoutFormatter
                      label={`${t('form.area')} ${t('shorts.min')}`}
                      suffix={t('shorts.hectare')}
                      id={LeadFilterFieldsEnum.MIN_HECTARE}
                    />
                  </Form.Item>
                  <Form.Item name={LeadFilterFieldsEnum.MAX_HECTARE}>
                    <PNumericInput
                      withoutFormatter
                      label={t('shorts.max')}
                      suffix={t('shorts.hectare')}
                      id={LeadFilterFieldsEnum.MAX_HECTARE}
                    />
                  </Form.Item>
                </Space.Compact>
              </Col>
            </>
          ) : (
            <Col span={5}>
              <Space.Compact>
                <Form.Item name={LeadFilterFieldsEnum.MIN_AREA}>
                  <PNumericInput
                    label={`${t('form.area')} ${t('shorts.min')}`}
                    suffix={t('shorts.squareMeter')}
                    id={LeadFilterFieldsEnum.MIN_AREA}
                  />
                </Form.Item>
                <Form.Item name={LeadFilterFieldsEnum.MAX_AREA}>
                  <PNumericInput
                    label={t('shorts.max')}
                    suffix={t('shorts.squareMeter')}
                    id={LeadFilterFieldsEnum.MAX_AREA}
                  />
                </Form.Item>
              </Space.Compact>
            </Col>
          ))}

        {values?.action === PropertyActionsTypesEnum.OFFER && (
          <Col span={4}>
            <Form.Item name={LeadFieldsEnum.ADDRESS}>
              <PInput label={t('form.address')} id={LeadFieldsEnum.ADDRESS} />
            </Form.Item>
          </Col>
        )}

        {values?.action === PropertyActionsTypesEnum.OFFER && values?.mode?.length ? (
          <Col span={24}>
            {(presets.mode === ContractTypesEnum.SALE || presets.mode === ContractTypesEnum.ALL) && (
              <FormPricesFilter<ILeadsFilterFields>
                label={t('form.sale')}
                values={values}
                fields={{
                  minUnit: LeadFilterFieldsEnum.MIN_UNIT_PRICE_SALE,
                  maxUnit: LeadFilterFieldsEnum.MAX_UNIT_PRICE_SALE,
                  minTotal: LeadFilterFieldsEnum.MIN_TOTAL_PRICE_SALE,
                  maxTotal: LeadFilterFieldsEnum.MAX_TOTAL_PRICE_SALE,
                  currency: LeadFieldsEnum.CURRENCY_SALE,
                }}
              />
            )}

            {(presets.mode === ContractTypesEnum.ALL || presets.mode === ContractTypesEnum.RENT) &&
              (presets.rent === ContractTypesEnum.ALL || presets.rent === ContractTypesEnum.MONTHLY) && (
                <FormPricesFilter<ILeadsFilterFields>
                  label={t('form.rent')}
                  values={values}
                  rent={presets.rent}
                  fields={{
                    minTotal: LeadFilterFieldsEnum.MIN_TOTAL_PRICE_MONTHLY,
                    maxTotal: LeadFilterFieldsEnum.MAX_TOTAL_PRICE_MONTHLY,
                    minUnit: LeadFilterFieldsEnum.MIN_UNIT_PRICE_MONTHLY,
                    maxUnit: LeadFilterFieldsEnum.MAX_UNIT_PRICE_MONTHLY,
                    currency: LeadFieldsEnum.CURRENCY_MONTHLY,
                  }}
                />
              )}

            {(presets.mode === ContractTypesEnum.ALL || presets.mode === ContractTypesEnum.RENT) &&
              (presets.rent === ContractTypesEnum.ALL || presets.rent === ContractTypesEnum.DAILY) && (
                <FormPricesFilter<ILeadsFilterFields>
                  label={t('form.rentDaily')}
                  values={values}
                  rent={presets.rent}
                  fields={{
                    minTotal: LeadFilterFieldsEnum.MIN_TOTAL_PRICE_DAILY,
                    maxTotal: LeadFilterFieldsEnum.MAX_TOTAL_PRICE_DAILY,
                    currency: LeadFieldsEnum.CURRENCY_DAILY,
                  }}
                />
              )}
          </Col>
        ) : null}

        <Col span={24}></Col>
        <Col>
          <CSubmitButton
            allowValidate={false}
            form={form}
            values={values}
            isButtonLoading={isButtonLoading}
            title={t('actions.filter')}
            icon={<Icon.Filter fill="white" />}
          />
        </Col>
        <Col>
          {hasInitialValues && !isButtonLoading && (
            <PButton htmlType="button" type="text" onClick={onReset}>
              {t('actions.clear')}
            </PButton>
          )}
        </Col>
      </Row>
    </Form>
  );
};
