import {palette} from 'constants/theme';
import {ContractTypesEnum} from 'enums';
import {CustomerTypesTranslationEnum} from 'enums/translations';
import {TPersonStats} from 'types/api';

export const injectPersonModeTags = (stats: TPersonStats): Array<Record<string, string>> => {
  const personModes: Array<Record<string, string>> = [];

  if (
    stats?.property?.mode[ContractTypesEnum.ALL] ||
    stats?.property?.mode[ContractTypesEnum.SALE] ||
    stats?.property?.mode[ContractTypesEnum.RENT]
  ) {
    (stats?.property?.mode[ContractTypesEnum.ALL] || stats?.property?.mode[ContractTypesEnum.SALE]) &&
      personModes.push({text: CustomerTypesTranslationEnum.SALE, color: palette.success.primary});

    (stats?.property?.mode[ContractTypesEnum.ALL] || stats?.property?.mode[ContractTypesEnum.RENT]) &&
      personModes.push({text: CustomerTypesTranslationEnum.RENT, color: palette.primary});
  }

  if (
    stats?.inquiry?.mode[ContractTypesEnum.ALL] ||
    stats?.inquiry?.mode[ContractTypesEnum.SALE] ||
    stats?.inquiry?.mode[ContractTypesEnum.RENT]
  ) {
    (stats?.inquiry?.mode[ContractTypesEnum.ALL] || stats?.inquiry?.mode[ContractTypesEnum.SALE]) &&
      personModes.push({text: CustomerTypesTranslationEnum.CUSTOMER, color: palette.purple});

    (stats?.inquiry?.mode[ContractTypesEnum.ALL] || stats?.inquiry?.mode[ContractTypesEnum.RENT]) &&
      personModes.push({text: CustomerTypesTranslationEnum.RENTER, color: palette.info.primary});
  }

  return personModes;
};
