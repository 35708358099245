import {memo} from 'react';
import {Alert} from 'antd';

import {IPAlertProps} from './p-alert.types';

const PAlert = ({...props}: IPAlertProps) => {
  return <Alert {...props} />;
};

export const PAlertMemoized = memo(PAlert);
