import {Col, Form, Row} from 'antd';
import {useSelector} from 'react-redux';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {PNumericInput} from 'components/primitives/p-numeric-input';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {PInput} from 'components/primitives/p-input';
import {fileAccept} from 'constants/file-accept';
import {PFormLabel} from 'components/primitives/p-form-label';
import {PButton} from 'components/primitives/p-button';
import {Icon} from 'assets/icons';
import {
  appCurrentFloorFileSelector,
  appCurrentFloorImageSelector,
  appCurrentRoomImageSelector,
  appProfileSelector,
} from 'store/app/selectors';
import {normFile} from 'utils';
import {roomNameOptions} from 'constants/select-options';
import {PSelect} from 'components/primitives/p-select';
import {RoomNameTranslationEnum} from 'enums/translations';
import {FormsEnum, RolesEnum} from 'enums';
import {CImageUploader} from 'components/containers/c-image-uploader';
import {CFileUploader} from 'components/containers/c-file-uploader';

import {IPropertyAddonsFormProps} from './form-property-addons.interfaces';
import {PropertyAddonsFieldsEnum} from './form-property-addons.enums';

export const FormPropertyAddons = ({
  type,
  form,
  values,
  initialValues,
  isImagesAttached,
  isFilesAttached,
  onSumbit,
  onUploadClick,
}: IPropertyAddonsFormProps) => {
  const {t} = useTranslation();
  const profile = useSelector(appProfileSelector);
  const currentRoomImage = useSelector(appCurrentRoomImageSelector);
  const currentFloorImage = useSelector(appCurrentFloorImageSelector);
  const currentFloorFile = useSelector(appCurrentFloorFileSelector);
  const isReadonly = useMemo(() => profile?.type === RolesEnum.MARKETING, [profile]);

  return (
    <Form form={form} onFinish={onSumbit} initialValues={initialValues} name={FormsEnum.PROPERTY_ADDONS}>
      <Row gutter={16} align="middle">
        {type === 'rooms' ? (
          <>
            <Col span={8}>
              <Form.Item
                name={PropertyAddonsFieldsEnum.NAME}
                rules={[{required: true, message: fieldsMessageTranslations.common}]}
              >
                <PSelect label={t('form.firstName')} options={roomNameOptions} disabled={isReadonly} />
              </Form.Item>
            </Col>
            <Col span={8} className={values?.name === RoomNameTranslationEnum.OTHER ? '' : 'hidden'}>
              <Form.Item
                name={PropertyAddonsFieldsEnum.NAME_OTHER}
                rules={[
                  {
                    required: values?.name === RoomNameTranslationEnum.OTHER,
                    message: fieldsMessageTranslations.common,
                  },
                ]}
              >
                <PInput label={t('form.firstName')} id={PropertyAddonsFieldsEnum.NAME_OTHER} readOnly={isReadonly} />
              </Form.Item>
            </Col>
          </>
        ) : (
          <Col span={8}>
            <Form.Item
              name={PropertyAddonsFieldsEnum.NAME}
              rules={[{required: true, message: fieldsMessageTranslations.common}]}
            >
              <PInput label={t('form.firstName')} id={PropertyAddonsFieldsEnum.NAME} readOnly={isReadonly} />
            </Form.Item>
          </Col>
        )}

        {type === 'commercial' && (
          <>
            <Col span={8}>
              <Form.Item
                name={PropertyAddonsFieldsEnum.AREA}
                rules={[{required: false, message: fieldsMessageTranslations.common}]}
              >
                <PNumericInput
                  withoutFormatter
                  label={t('form.area')}
                  id={PropertyAddonsFieldsEnum.AREA}
                  suffix="քմ"
                  readOnly={isReadonly}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={PropertyAddonsFieldsEnum.HEIGHT}
                rules={[{required: false, message: fieldsMessageTranslations.common}]}
              >
                <PNumericInput
                  withoutFormatter
                  label={t('form.height')}
                  id={PropertyAddonsFieldsEnum.HEIGHT}
                  suffix="մ"
                  readOnly={isReadonly}
                />
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={24}></Col>
        <Col span={16}>
          <Form.Item
            name={PropertyAddonsFieldsEnum.DESCRIPTION}
            rules={[{required: false, message: fieldsMessageTranslations.common}]}
          >
            <PInput
              isTextarea
              rows={3}
              label={t('form.info')}
              id={PropertyAddonsFieldsEnum.DESCRIPTION}
              readOnly={isReadonly}
            />
          </Form.Item>
        </Col>
        <Col span={24}></Col>
      </Row>
      <Row gutter={16} className="mb-5">
        <Col span={24}>
          <PFormLabel text={t('form.images')} isStatic />
        </Col>
        <Col span={24}>
          <Form.Item noStyle valuePropName="fileList" getValueFromEvent={normFile}>
            <CImageUploader
              id="addon"
              initialFiles={type === 'rooms' ? currentRoomImage : currentFloorImage}
              addon={type}
              isImagesAttached={isImagesAttached}
              onUploadClick={onUploadClick}
              accept={`${fileAccept.JPEG}, ${fileAccept.PNG}, ${fileAccept.WEBM}, ${fileAccept.GIF}`}
              isDraggable
              multiple
              isReadonly={isReadonly}
            />
          </Form.Item>
        </Col>
      </Row>

      {(type === 'floors' || type === 'commercial') && (
        <Row gutter={16}>
          <Col span={24}>
            <PFormLabel text={t('form.files')} isStatic />
          </Col>
          <Col span={18}>
            <Form.Item noStyle valuePropName="fileList" getValueFromEvent={normFile}>
              <CFileUploader
                id="addon"
                initialFiles={currentFloorFile}
                addon={type}
                isFilesAttached={isFilesAttached}
                onUploadClick={onUploadClick}
                accept={`${fileAccept.PDF}, ${fileAccept.DOC}, ${fileAccept.PPT}, ${fileAccept.EXCEL}`}
                multiple
                isReadonly={isReadonly}
              >
                <PButton type="default" icon={<Icon.File width={24} height={24} stroke="currentColor" />}>
                  {t('actions.add')}
                </PButton>
              </CFileUploader>
            </Form.Item>
          </Col>
        </Row>
      )}
    </Form>
  );
};
