import {Col, Form, Row, Space} from 'antd';
import {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {PButton} from 'components/primitives/p-button';
import {PInput} from 'components/primitives/p-input';
import {BuildingFieldsEnum} from 'components/forms/building/form-building.enums';
import {PSelect} from 'components/primitives/p-select';
import {buildingPlanOptions, residentialTypeOptions} from 'constants/select-options';
import {PNumericInput} from 'components/primitives/p-numeric-input';
import {BuildingSubTypesEnum, BuildingTypeCommonEnum, FormsEnum, OptGroupsEnum} from 'enums';
import {appCatalogSelector} from 'store/app/selectors';
import {AddressFieldsEnum} from 'components/forms/address/form-address.enums';
import {PPhoneInput} from 'components/primitives/p-phone-input';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {IFilterProps} from 'types/common';
import {CSubmitButton} from 'components/containers/c-submit-button';
import {Icon} from 'assets/icons';
import {useMountedOnce} from 'hooks';

import {IBuildingFilterFields} from './form-building-filter.interfaces';

export const FormBuildingFilter = ({
  form,
  values,
  initialValues,
  buildingType,
  isButtonLoading,
  onSubmit,
  onReset,
}: IFilterProps<IBuildingFilterFields>) => {
  const {t} = useTranslation();
  const catalog = useSelector(appCatalogSelector);
  const hasInitialValues = useMemo(() => initialValues && Object.keys(initialValues).length > 0, [initialValues]);

  const setInitialValus = useMountedOnce(() => {
    if (hasInitialValues) {
      form.setFieldsValue(initialValues!);
    }
  });

  useEffect(() => {
    setInitialValus();
  }, [setInitialValus]);

  return (
    <Form form={form} onFinish={onSubmit} name={FormsEnum.BUILDING_FILTER}>
      <Row gutter={16}>
        <Col span={6}>
          <Space.Compact>
            <Form.Item name={AddressFieldsEnum.KEYWORD} rules={[{required: false}]} className="w-full">
              <PInput label={t('form.address')} id={AddressFieldsEnum.KEYWORD} />
            </Form.Item>
            <Form.Item name={AddressFieldsEnum.NUMBER} rules={[{required: false}]}>
              <PInput label={t('form.number')} id={AddressFieldsEnum.NUMBER} />
            </Form.Item>
          </Space.Compact>
        </Col>
        {buildingType !== BuildingTypeCommonEnum.ALL && (
          <Col span={4}>
            <Form.Item name={BuildingFieldsEnum.SUB_TYPE} rules={[{required: false}]}>
              <PSelect label={t('form.buildingType')} options={residentialTypeOptions} />
            </Form.Item>
          </Col>
        )}

        {values?.subtype === BuildingSubTypesEnum.STONE && buildingType === BuildingTypeCommonEnum.RESIDENTIAL && (
          <Col span={4}>
            <Form.Item name={BuildingFieldsEnum.STONE} rules={[{required: false}]}>
              <PSelect label={t('form.buildingSubType')} options={catalog[OptGroupsEnum.BUILDING_SUBTYPE_STONE]} />
            </Form.Item>
          </Col>
        )}

        <Col span={4}>
          <Space.Compact>
            <Form.Item name={BuildingFieldsEnum.MIN_FLOORS}>
              <PNumericInput label={`${t('form.floors')} ${t('shorts.min')}`} id={BuildingFieldsEnum.MIN_FLOORS} />
            </Form.Item>
            <Form.Item name={BuildingFieldsEnum.MAX_FLOORS}>
              <PNumericInput label={t('shorts.max')} id={BuildingFieldsEnum.MAX_FLOORS} />
            </Form.Item>
          </Space.Compact>
        </Col>
        <Col span={4}>
          <Form.Item name={BuildingFieldsEnum.INFO} rules={[{required: false}]}>
            <PInput label={t('form.info')} id={BuildingFieldsEnum.INFO} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        {buildingType === BuildingTypeCommonEnum.RESIDENTIAL && (
          <>
            {/* <Col span={4}>
              <Form.Item name={BuildingFieldsEnum.HEATING} rules={[{required: false}]}>
                <PSelect mode="multiple" label={t('form.heating')} options={heatingOptions} />
              </Form.Item>
            </Col> */}
            <Col span={5}>
              <Space.Compact>
                <Form.Item name={BuildingFieldsEnum.MIN_FEE} rules={[{required: false}]}>
                  <PNumericInput label={`${t('form.fee')} ${t('shorts.min')}`} />
                </Form.Item>
                <Form.Item name={BuildingFieldsEnum.MAX_FEE} rules={[{required: false}]}>
                  <PNumericInput label={t('shorts.max')} />
                </Form.Item>
              </Space.Compact>
            </Col>
          </>
        )}

        {buildingType === BuildingTypeCommonEnum.COMMERCIAL && (
          <Col span={4}>
            <Form.Item name={BuildingFieldsEnum.PLAN} rules={[{required: false}]}>
              <PSelect label={t('form.plan')} options={buildingPlanOptions} />
            </Form.Item>
          </Col>
        )}

        <Col span={4}>
          <Form.Item name={BuildingFieldsEnum.NAME}>
            <PInput label={t('form.firstName')} id={BuildingFieldsEnum.NAME} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name={BuildingFieldsEnum.PHONE}
            rules={[
              {min: 9, message: fieldsMessageTranslations.phoneMin},
              {max: 14, message: fieldsMessageTranslations.phoneMax},
            ]}
          >
            <PPhoneInput label={t('form.phone')} id={BuildingFieldsEnum.PHONE} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <CSubmitButton
            allowValidate={false}
            form={form}
            values={values}
            isButtonLoading={isButtonLoading}
            title={t('actions.filter')}
            icon={<Icon.Filter fill="white" />}
          />
        </Col>
        <Col>
          {hasInitialValues && !isButtonLoading && (
            <PButton htmlType="button" type="text" onClick={onReset}>
              {t('actions.clear')}
            </PButton>
          )}
        </Col>
      </Row>
    </Form>
  );
};
