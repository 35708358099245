import {memo, useCallback, useRef} from 'react';
import {Carousel} from 'antd';

import {Icon} from 'assets/icons';

import {CSS} from './p-carousel.cssconfig';
import {PCarouselProps} from './p-carousel.types';

const PCarousel = ({
  children,
  className,
  arrows,
  arrowsProps = {position: 'inset', type: 'button'},
  ...props
}: PCarouselProps) => {
  const ref = useRef<any>(null);

  const onPrev = useCallback(() => {
    ref.current.prev();
  }, []);

  const onNext = useCallback(() => {
    ref.current.next();
  }, []);

  return (
    <div className={`${CSS.root} ${className || ''}`}>
      {arrows && (
        <div
          onClick={onPrev}
          className={`${CSS.arrow} ${arrowsProps?.type === 'button' ? CSS.arrowBtn : ''} ${
            arrowsProps?.position === 'inset' ? 'left-[16px]' : 'left-[-20px]'
          }`}
        >
          <Icon.ChevronLeftRounded
            width={15}
            height={15}
            fill="currentColor"
            className={`${arrowsProps?.type !== 'button' ? 'hover:text-primary transition-colors' : ''}`}
          />
        </div>
      )}
      <Carousel ref={ref} adaptiveHeight {...props}>
        {children}
      </Carousel>
      {arrows && (
        <div
          onClick={onNext}
          className={`${CSS.arrow} ${arrowsProps?.type === 'button' ? CSS.arrowBtn : ''} ${
            arrowsProps?.position === 'inset' ? 'right-[16px]' : 'right-[-20px]'
          }`}
        >
          <Icon.ChevronRightRounded
            width={15}
            height={15}
            fill="currentColor"
            className={`${arrowsProps?.type !== 'button' ? 'hover:text-primary transition-colors' : ''}`}
          />
        </div>
      )}
    </div>
  );
};

export const PCarouselMemoized = memo(PCarousel);
