import {takeEvery, all, call, put} from 'redux-saga/effects';
import {push} from 'redux-first-history';

import {TActionSettings, TServerResponseError} from 'types/common';
import {RoutesEnum} from 'router/routes.enum';
import {postAuthService} from 'services/auth';
import {setProfile} from 'store/app/slice-app';
import {TAuthResponse} from 'types/api';
import {setAccounts} from 'store/account/slice-accounts';
import {RolesEnum} from 'enums';

import {clearAuthErrorsAction, logoutAction, postAuthAction} from './actions';
import {setErrors, setLoading, setAccount} from './slice-auth';

const postAuthSaga = function* ({
  payload: {...payload},
  settings: {onFulfilled, onReject},
}: {payload: {email: string; password: string}; settings: TActionSettings} & {type: string}) {
  yield put(setLoading(true));

  try {
    const {data}: {data: {success: TAuthResponse; error: TServerResponseError}} = yield call(postAuthService, payload);

    if (data.error) {
      throw data.error;
    } else {
      localStorage.setItem('TOKEN', data.success.token as string);
      localStorage.setItem('ID', data.success.id as string);
      localStorage.setItem('TYPE', data.success.type as string);

      const route =
        data.success.type === RolesEnum.ADMIN ||
        data.success.type === RolesEnum.SUPER ||
        data.success.type === RolesEnum.INFO
          ? RoutesEnum.LEADS
          : data.success.type === RolesEnum.MARKETING
          ? RoutesEnum.PROPERTIES
          : data.success.type === RolesEnum.BROKER
          ? RoutesEnum.BUILDINGS
          : RoutesEnum.HOME;

      yield put(setErrors(''));
      yield put(setAccount(data.success));
      yield put(push(route));

      onFulfilled && onFulfilled();
    }
  } catch (e) {
    console.error(e);
    yield put(setErrors(e as TServerResponseError));
    onReject && onReject(e);
  } finally {
    yield put(setLoading(false));
  }
};

const logoutSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setLoading(true));

  try {
    localStorage.removeItem('TOKEN');
    localStorage.removeItem('ID');
    localStorage.removeItem('TYPE');

    yield put(setAccount(null));
    yield put(setProfile(null));
    yield put(setAccounts([]));

    yield put(push(RoutesEnum.LOGIN));
  } catch (e) {
    yield put(setErrors(e as TServerResponseError));
  } finally {
    yield put(setLoading(false));
  }
};

const clearAuthErrorsSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setErrors(null));
};

export const authSaga = function* () {
  yield all([
    takeEvery(postAuthAction().type, postAuthSaga),
    takeEvery(logoutAction().type, logoutSaga),
    takeEvery(clearAuthErrorsAction().type, clearAuthErrorsSaga),
  ]);
};
