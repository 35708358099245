import {createContext, NamedExoticComponent} from 'react';

export const PopupContext = createContext<{
  Component: any;
  props: any;
  show: <T extends {[x in keyof T1]?: T1[x]}, T1 extends {}>(Component: NamedExoticComponent<T1>, props?: T) => void;
  close: () => void;
}>({
  Component: null,
  props: {},
  show: () => {},
  close: () => {},
});
