import {useState, useEffect} from 'react';

declare const navigator: any;

const getNetworkConnection = () => {
  return navigator.connection || navigator.mozConnection || navigator.webkitConnection || null;
};

const getNetworkConnectionInfo = () => {
  const connection = getNetworkConnection();

  if (!connection) {
    return {};
  }

  return {
    rtt: connection.rtt,
    type: connection.type,
    saveData: connection.saveData,
    downLink: connection.downLink,
    downLinkMax: connection.downLinkMax,
    effectiveType: connection.effectiveType,
  };
};

export const useNetwork = () => {
  const [state, setState] = useState(() => {
    return {
      since: undefined || new Date().toString(),
      online: navigator.onLine,
      ...getNetworkConnectionInfo(),
    };
  });

  useEffect(() => {
    const handleOnline = () => {
      setState(prevState => ({
        ...prevState,
        since: new Date().toString(),
        online: true,
      }));
    };

    const handleOffline = () => {
      setState(prevState => ({
        ...prevState,
        since: new Date().toString(),
        online: false,
      }));
    };

    const handleConnectionChange = () => {
      setState(prevState => ({
        ...prevState,
        ...getNetworkConnectionInfo(),
      }));
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    const connection = getNetworkConnection();

    connection?.addEventListener('change', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      connection?.removeEventListener('change', handleConnectionChange);
    };
  }, []);

  return state;
};
