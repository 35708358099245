import {Col, Row} from 'antd';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Image} from 'assets/images';
import {PCardWrapper} from 'components/primitives/p-card-wrapper';
import {PPageTitle} from 'components/primitives/p-page-title';
import {Icon} from 'assets/icons';
import {palette} from 'constants/theme';

export const NotFound = () => {
  const {t} = useTranslation();

  return (
    <main className="text-center">
      <Row justify="center">
        <Col span={6}>
          <div className="mb-8">
            <Link to="/">
              <img src={Image.brandLogo} alt="Kaskad Realty" />
            </Link>
          </div>
          <PCardWrapper padding="md">
            <Icon.CloseCircle className="mb-4" width={60} height={60} fill={palette.danger.primary} />
            <PPageTitle text={t('info.pageNotFound')} align="center" />
            <Link to="/" className="inline-flex gap-2 items-center">
              {t('links.homepage')}
            </Link>
          </PCardWrapper>
        </Col>
      </Row>
    </main>
  );
};
