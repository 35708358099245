import {TActionSettings} from 'types/common';

export const postAuthAction = (payload?: {email: string; password: string}, settings?: TActionSettings) => ({
  type: 'auth/postAuthAction',
  payload,
  settings: settings || {},
});

export const logoutAction = () => ({type: 'auth/logoutAction'});

export const clearAuthErrorsAction = () => ({type: 'auth/clearErrorsAction'});
