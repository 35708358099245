import {createSelector} from '@reduxjs/toolkit';

import {State} from 'store/types';

import {AnalyticState} from './types';

const getState = (state: State): AnalyticState => state.analytics;

export const analyticsErrorsSelector = createSelector(getState, state => state.errors);
export const analyticsLoadingSelector = createSelector(getState, state => state.loading);
export const analyticsPropertyTotalsSelector = createSelector(getState, state => state.propertyTotals);
export const analyticsInquiryTotalsSelector = createSelector(getState, state => state.inquiryTotals);
export const analyticsLeadTotalsSelector = createSelector(getState, state => state.leadTotals);
export const analyticsPropertyPerformanceSelector = createSelector(getState, state => state.propertyPerformance);
export const analyticsInquiryPerformanceSelector = createSelector(getState, state => state.inquiryPerformance);
export const analyticsLeadPerformanceSelector = createSelector(getState, state => state.leadPerformance);
