import {ColumnsType} from 'antd/es/table';
import {Col, Row, Tag, Tooltip} from 'antd';

import {Icon} from 'assets/icons';
import {ActionModesEnum} from 'enums';
import {IPersonFields} from 'components/forms/person/form-person.interfaces';
import {PersonFieldsEnum} from 'components/forms/person/form-person.enums';
import {IPersonTableColumnProps} from 'types/common';
import {injectPersonModeTags, injectPersonPropertyTypes} from 'utils';
import {CTextCopy} from 'components/containers/c-text-copy';

interface ITableColumns extends IPersonFields {}

export const columns = ({onEdit, translation}: IPersonTableColumnProps): ColumnsType<ITableColumns> => [
  {
    width: '10%',
    title: translation('form.firstName'),
    dataIndex: PersonFieldsEnum.NAME,
    key: PersonFieldsEnum.NAME,
  },
  {
    width: '20%',
    title: translation('form.personType'),
    dataIndex: PersonFieldsEnum.CUSTOMER_MODE,
    key: PersonFieldsEnum.CUSTOMER_MODE,
    render: (_, record) => (
      <div className="flex flex-wrap gap-2">
        {injectPersonModeTags(record.stats!).map(({text, color}) => (
          <Tag key={`${record.id}-${text}`} color={color}>
            {text}
          </Tag>
        ))}
      </div>
    ),
  },
  {
    width: '20%',
    title: translation('form.propertyType'),
    dataIndex: PersonFieldsEnum.PROPERTY_TYPE,
    key: PersonFieldsEnum.PROPERTY_TYPE,
    render: (_, record) => injectPersonPropertyTypes(record.stats!).join(', '),
  },
  {
    width: '20%',
    title: translation('form.phone'),
    dataIndex: PersonFieldsEnum.PHONES,
    key: PersonFieldsEnum.PHONES,
    render: (_, record) => record.phones?.map(p => <CTextCopy key={`${record.id}-${p?.number}`} text={p?.number} />),
  },
  {
    title: translation('form.email'),
    dataIndex: PersonFieldsEnum.EMAIL,
    key: PersonFieldsEnum.EMAIL,
  },
  {
    title: translation('form.action'),
    key: 'action',
    className: 'text-right',
    width: '140px',
    render: (_, record) => (
      <Row gutter={8} justify="end" align="middle" wrap={false}>
        <Col className="inline-flex">
          <Tooltip title={translation('actions.view')}>
            <Icon.Eye
              color="#292D32"
              width={24}
              height={24}
              cursor="pointer"
              onClick={() => {
                onEdit && onEdit({mode: ActionModesEnum.VIEW, entity: record});
              }}
            />
          </Tooltip>
        </Col>
        <Col className="inline-flex">
          <div className="flex justify-end items-center">
            <Tooltip title={translation('actions.change')}>
              <Icon.Edit
                color="#292D32"
                width={24}
                height={24}
                cursor="pointer"
                onClick={() => {
                  onEdit && onEdit({mode: ActionModesEnum.UPDATE, entity: record});
                }}
              />
            </Tooltip>
          </div>
        </Col>
      </Row>
    ),
  },
];
