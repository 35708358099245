export const isDateExpired = (date: string | Date): boolean => {
  const today = new Date();
  const past = new Date(date);

  if (past.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0)) {
    return true;
  }

  return false;
};
