import {takeEvery, all, call, put} from 'redux-saga/effects';

import {
  setBookmarkAddedInquiry,
  setInquiriesFilterPresets,
  setInquiryUpdatedEventSearch,
  setUpdatedInquiryPersons,
} from 'store/inquiries/slice-inquiries';
import {TActionSettings, TServerResponseError} from 'types/common';
import {TInquiry} from 'types/api';
import {
  addInquiryBookmarkService,
  addInquiryEventService,
  addInquiryEventCommentService,
  getInquiriesService,
  getInquiryByIdService,
  removeInquiryBookmarkService,
  updateInquiryInfoService,
  setInquiryEventPropertyCommentService,
  updateInquiryPersonsService,
  updateInquiryEventSearchService,
} from 'services/inquiries';
import {getByIdFn, postHelperFn} from 'store/core/sagas';

import {
  addInquiryBookmarkAction,
  addInquiryEventAction,
  addInquiryEventCommentAction,
  clearInquiriesErrorsAction,
  getInquiriesAction,
  getInquiryByIdAction,
  removeInquiryBookmarkAction,
  updateInquiryInfoAction,
  setInquiryEventPropertyCommentAction,
  clearCurrentInquiryAction,
  clearInquiryBookmarkAction,
  clearInquiryInfoAndCommentAction,
  saveInquiriesFilterPresetsAction,
  updateInquiryPersonsAction,
  updateInquiryEventSearchAction,
} from './actions';
import {
  setAddedInquiryComment,
  setBookmarkRemovedInquiry,
  setCurrentInquiry,
  setErrors,
  setInquiries,
  setInquiriesCount,
  setInquiryEvent,
  setInquiryEventPropertyComment,
  setLoading,
  setUpdatedInquiryInfo,
} from './slice-inquiries';

const getInquiriesSaga = function* ({
  payload: {...payload},
  settings: {withCount = true, onFulfilled, onReject},
}: {payload: {offset: number; length: number}; settings: TActionSettings} & {type: string}) {
  yield put(setLoading(true));

  try {
    const response: {data: TInquiry[]} = yield call(getInquiriesService, payload);

    if (withCount) {
      const count: {data: number} = yield call(getInquiriesService, {...payload, ...{cnt: ''}});

      yield put(setInquiriesCount(count.data));
    }

    yield put(setCurrentInquiry(null));
    yield put(setBookmarkAddedInquiry(null));
    yield put(setBookmarkRemovedInquiry(null));
    yield put(setAddedInquiryComment(null));
    yield put(setUpdatedInquiryPersons(null));

    yield put(setInquiries(response.data));

    onFulfilled && onFulfilled();
  } catch (e) {
    console.warn(e);
    yield put(setErrors(e as TServerResponseError));
    onReject && onReject(e);
  } finally {
    yield put(setLoading(false));
  }
};

const getInquiryByIdSaga = function* ({
  payload: {...payload},
  settings: {...settings},
}: {payload: {id?: number}; settings: TActionSettings} & {type: string}) {
  yield put(setInquiryEvent(null));

  yield getByIdFn<TInquiry>({
    actions: {loading: setLoading, error: setErrors, success: setCurrentInquiry},
    service: getInquiryByIdService,
    payload,
    settings,
  });
};

const addInquiryBookmarkSaga = function* ({
  payload: {...payload},
  settings: {...settings},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield postHelperFn<any>({
    actions: {loading: setLoading, error: setErrors, success: setBookmarkAddedInquiry},
    service: addInquiryBookmarkService,
    payload,
    settings,
  });
};

const removeInquiryBookmarkSaga = function* ({
  payload: {...payload},
  settings: {...settings},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield postHelperFn<any>({
    actions: {loading: setLoading, error: setErrors, success: setBookmarkRemovedInquiry},
    service: removeInquiryBookmarkService,
    payload,
    settings,
  });
};

const addInquiryEventCommentSaga = function* ({
  payload: {...payload},
  settings: {...settings},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield postHelperFn<any>({
    actions: {loading: setLoading, error: setErrors, success: setAddedInquiryComment},
    service: addInquiryEventCommentService,
    payload,
    settings,
  });
};

const updateInquiryInfoSaga = function* ({
  payload: {...payload},
  settings: {...settings},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield postHelperFn<any>({
    actions: {loading: setLoading, error: setErrors, success: setUpdatedInquiryInfo},
    service: updateInquiryInfoService,
    payload,
    settings,
  });
};

const updateInquiryPersonsSaga = function* ({
  payload: {...payload},
  settings: {...settings},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield postHelperFn<any>({
    actions: {loading: setLoading, error: setErrors, success: setUpdatedInquiryPersons},
    service: updateInquiryPersonsService,
    payload,
    settings,
  });
};

const addInquiryEventSaga = function* ({
  payload: {...payload},
  settings: {...settings},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield postHelperFn<any>({
    actions: {loading: setLoading, error: setErrors, success: setInquiryEvent},
    service: addInquiryEventService,
    payload,
    settings,
  });
};

const setInquiryEventPropertyCommentSaga = function* ({
  payload: {...payload},
  settings: {...settings},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield postHelperFn<any>({
    actions: {loading: setLoading, error: setErrors, success: setInquiryEventPropertyComment},
    service: setInquiryEventPropertyCommentService,
    payload,
    settings,
  });
};

const updateInquiryEventSearchSaga = function* ({
  payload: {...payload},
  settings: {...settings},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  yield postHelperFn<any>({
    actions: {loading: setLoading, error: setErrors, success: setInquiryUpdatedEventSearch},
    service: updateInquiryEventSearchService,
    payload,
    settings,
  });
};

const saveInquiriesFilterPresetsSaga = function* ({payload}: {payload: any} & {type: string}) {
  yield put(setInquiriesFilterPresets(payload));
};

const clearInquiriesErrorsSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setErrors(null));
};

const clearCurrentInquirySaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setCurrentInquiry(null));
};

const clearInquiryBookmarkSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setBookmarkAddedInquiry(null));
  yield put(setBookmarkRemovedInquiry(null));
};

const clearInquiryInfoAndCommentSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setUpdatedInquiryInfo(null));
  yield put(setAddedInquiryComment(null));
};

export const inquiriesSaga = function* () {
  yield all([
    takeEvery(getInquiriesAction().type, getInquiriesSaga),
    takeEvery(getInquiryByIdAction().type, getInquiryByIdSaga),
    takeEvery(addInquiryBookmarkAction().type, addInquiryBookmarkSaga),
    takeEvery(removeInquiryBookmarkAction().type, removeInquiryBookmarkSaga),
    takeEvery(addInquiryEventCommentAction().type, addInquiryEventCommentSaga),
    takeEvery(updateInquiryInfoAction().type, updateInquiryInfoSaga),
    takeEvery(updateInquiryPersonsAction().type, updateInquiryPersonsSaga),
    takeEvery(addInquiryEventAction().type, addInquiryEventSaga),
    takeEvery(setInquiryEventPropertyCommentAction().type, setInquiryEventPropertyCommentSaga),
    takeEvery(updateInquiryEventSearchAction().type, updateInquiryEventSearchSaga),
    takeEvery(saveInquiriesFilterPresetsAction().type, saveInquiriesFilterPresetsSaga),
    takeEvery(clearInquiriesErrorsAction().type, clearInquiriesErrorsSaga),
    takeEvery(clearCurrentInquiryAction().type, clearCurrentInquirySaga),
    takeEvery(clearInquiryBookmarkAction().type, clearInquiryBookmarkSaga),
    takeEvery(clearInquiryInfoAndCommentAction().type, clearInquiryInfoAndCommentSaga),
  ]);
};
