import {Col, Divider, Form, RadioChangeEvent, Row, Space} from 'antd';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {Icon} from 'assets/icons';
import {PButton} from 'components/primitives/p-button';
import {PInput} from 'components/primitives/p-input';
import {PSelect} from 'components/primitives/p-select';
import {
  buildingPlanOptions,
  commericalSubTypeOptions,
  heatingOptions,
  residentialTypeOptions,
} from 'constants/select-options';
import {BuildingFieldsEnum} from 'components/forms/building/form-building.enums';
import {PFormLabel} from 'components/primitives/p-form-label';
import {ActionModeHero} from 'constants/translations';
import {usePopup} from 'hooks';
import {
  ActionModesEnum,
  BuildingSubTypesEnum,
  BuildingTypeCommonEnum,
  FormsEnum,
  OptGroupsEnum,
  PropertyTypesEnum,
} from 'enums';
import {PTable} from 'components/primitives/p-table';
import {PRadio} from 'components/primitives/p-radio';
import {CENTER_DEFAULT} from 'constants/map';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {PopupPersonAttach} from 'components/popups/person-attach';
import {fileAccept} from 'constants/file-accept';
import {getTranslation} from 'utils';
import {
  appAddressSelector,
  appCatalogSelector,
  appCurrentBuildingFileSelector,
  appCurrentBuildingImageSelector,
} from 'store/app/selectors';
import {PNumericInput} from 'components/primitives/p-numeric-input';
import {normFile} from 'utils';
import {AddressFieldsEnum} from 'components/forms/address/form-address.enums';
import {buildingHasUnsavedChangesSelector} from 'store/buildings/selectors';
import {hasUnsavedChangesAction} from 'store/buildings/actions';
import {CMap} from 'components/containers/c-map';
import {CImageUploader} from 'components/containers/c-image-uploader';
import {CFileUploader} from 'components/containers/c-file-uploader';

import {PFormBuildingProps} from './form-building.types';

export const FormBuilding = ({
  form,
  values,
  initialValues,
  addressInfo,
  isButtonLoading,
  isAddressLoading,
  personColumns,
  attachedPersons,
  mode,
  isAddressAttached = true,
  isFilesAttached = true,
  isImagesAttached = true,
  isFromPopup,
  propertyType,
  onUploadClick,
  onSubmit,
  setAddressInfo,
  setAttachedPersons,
  onAddressAdd,
  onClose,
}: PFormBuildingProps) => {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const catalog = useSelector(appCatalogSelector);
  const address = useSelector(appAddressSelector);
  const currentBuildingImage = useSelector(appCurrentBuildingImageSelector);
  const currentBuildingFile = useSelector(appCurrentBuildingFileSelector);
  const hasUnsavedChanges = useSelector(buildingHasUnsavedChangesSelector);
  const columnsSize = useMemo(() => (isFromPopup ? 4 : 3), [isFromPopup]);
  const [buildingType, setBuildingType] = useState<string>(
    propertyType === PropertyTypesEnum.APARTMENT
      ? BuildingTypeCommonEnum.RESIDENTIAL
      : propertyType === PropertyTypesEnum.COMMERCIAL
      ? BuildingTypeCommonEnum.COMMERCIAL
      : BuildingTypeCommonEnum.RESIDENTIAL
  );
  const [residentialType, setResidentialType] = useState<any>();

  const {show} = usePopup();

  const handlePersonAttachPopup = useCallback(() => {
    show(PopupPersonAttach, {
      mode: getTranslation(ActionModeHero, ActionModesEnum.ADD),
      attachedPersons,
      setAttachedPersons,
    });
  }, [attachedPersons, show, setAttachedPersons]);

  const handleBuildingTypeChange = useCallback((e: RadioChangeEvent) => {
    setBuildingType(e.target.value);
  }, []);

  const handleResidentialTypeChange = useCallback((val: string) => {
    setResidentialType(val as keyof typeof BuildingSubTypesEnum);
  }, []);

  const handleUnsavedChanges = useCallback(() => {
    if (!hasUnsavedChanges) {
      dispatch(hasUnsavedChangesAction(true));
    }
  }, [hasUnsavedChanges, dispatch]);

  useEffect(() => {
    if (initialValues?.type) {
      setBuildingType(getTranslation(BuildingTypeCommonEnum, initialValues?.type));
    }
  }, [initialValues]);

  return (
    <Form
      form={form}
      name={FormsEnum.BUILDING}
      scrollToFirstError
      onFinish={onSubmit}
      onFinishFailed={() => {
        window.scroll({top: 0, behavior: 'smooth'});
      }}
      onValuesChange={handleUnsavedChanges}
    >
      <Form.Item
        name={BuildingFieldsEnum.TYPE}
        initialValue={buildingType}
        rules={[{required: true}]}
        id={BuildingFieldsEnum.TYPE}
      >
        <PRadio
          disabled={propertyType ? true : false}
          onChange={handleBuildingTypeChange}
          options={[
            {label: t('catalog.propertyTypes.residental'), value: BuildingTypeCommonEnum.RESIDENTIAL},
            {label: t('catalog.propertyTypes.commercial'), value: BuildingTypeCommonEnum.COMMERCIAL},
          ]}
        ></PRadio>
      </Form.Item>

      <Divider />
      <Row gutter={16}>
        <Col span={24}>
          <PFormLabel text={t('form.buildingInfo')} isStatic />
        </Col>
        <Col span={columnsSize}>
          <Form.Item
            name={AddressFieldsEnum.COUNTRY_NAME}
            initialValue={initialValues?.country_name}
            rules={[{required: true, message: fieldsMessageTranslations.address}]}
          >
            <PInput label={t('form.country')} id={AddressFieldsEnum.COUNTRY_NAME} autoComplete="nope" />
          </Form.Item>
        </Col>
        <Col span={columnsSize}>
          <Form.Item
            name={AddressFieldsEnum.SETTLEMENT_NAME}
            initialValue={initialValues?.settlement_name}
            rules={[{required: true, message: ''}]}
          >
            <PInput label={t('form.city')} id={AddressFieldsEnum.SETTLEMENT_NAME} autoComplete="nope" />
          </Form.Item>
        </Col>
        <Col span={columnsSize}>
          <Form.Item name={AddressFieldsEnum.DISTRICT_NAME} initialValue={initialValues?.district_name}>
            <PInput label={t('form.district')} id={AddressFieldsEnum.DISTRICT_NAME} autoComplete="nope" />
          </Form.Item>
        </Col>
        <Col span={columnsSize}>
          <Form.Item
            name={AddressFieldsEnum.STREET_NAME}
            initialValue={initialValues?.street_name}
            rules={[{required: true, message: ''}]}
          >
            <PInput label={t('form.street')} id={AddressFieldsEnum.STREET_NAME} autoComplete="nope" />
          </Form.Item>
        </Col>
        <Col span={columnsSize}>
          <Form.Item
            id={AddressFieldsEnum.NUMBER}
            name={AddressFieldsEnum.NUMBER}
            initialValue={initialValues?.number}
            rules={[{required: true, message: ''}]}
          >
            <PInput label={t('form.propertyNumber')} id={AddressFieldsEnum.NUMBER} autoComplete="nope" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} className="mb-9">
        <Col span={isFromPopup ? 16 : 12}>
          <CMap
            mode="create"
            center={CENTER_DEFAULT}
            zoom={16}
            initialAddress={initialValues?.address!}
            setAddressInfo={setAddressInfo}
          />
        </Col>
        <Col span={columnsSize}>
          {addressInfo && Object.keys(addressInfo).length !== 0 && (
            <PButton
              id="map-submit"
              type="primary"
              loading={isAddressLoading}
              icon={<Icon.Location width={24} height={24} fill="white" />}
              disabled={Object.keys(addressInfo).length === 0}
              onClick={() => onAddressAdd()}
            >
              {`${getTranslation(ActionModeHero, mode!)}`} {t('common.address')}
            </PButton>
          )}

          {!isAddressAttached ? (
            <PFormLabel type="danger" text={fieldsMessageTranslations.endAddress} isStatic />
          ) : null}
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={columnsSize}>
          <Form.Item
            name={BuildingFieldsEnum.FLOORS}
            initialValue={initialValues?.floors}
            rules={[{required: true, message: fieldsMessageTranslations.common}]}
          >
            <PNumericInput withoutFormatter label={t('form.floors')} id={BuildingFieldsEnum.FLOORS} />
          </Form.Item>
        </Col>
        {(buildingType || initialValues?.type) === BuildingTypeCommonEnum.RESIDENTIAL && (
          <>
            <Col span={columnsSize}>
              <Form.Item
                name={BuildingFieldsEnum.SUB_TYPE}
                initialValue={initialValues?.subtype}
                rules={[{required: true, message: fieldsMessageTranslations.common}]}
              >
                <PSelect
                  onChange={handleResidentialTypeChange}
                  label={t('form.buildingType')}
                  options={residentialTypeOptions}
                  id={BuildingFieldsEnum.SUB_TYPE}
                />
              </Form.Item>
            </Col>
            {(residentialType || initialValues?.subtype) === BuildingSubTypesEnum.NEW && (
              <>
                <Col span={columnsSize}>
                  <Form.Item
                    name={BuildingFieldsEnum.HEATING}
                    initialValue={initialValues?.heating}
                    rules={[{required: true, message: fieldsMessageTranslations.common}]}
                  >
                    <PSelect label={t('form.heating')} options={heatingOptions} id={BuildingFieldsEnum.HEATING} />
                  </Form.Item>
                </Col>
                <Col span={columnsSize}>
                  <Form.Item
                    name={BuildingFieldsEnum.FEE}
                    initialValue={initialValues?.fee}
                    rules={[{required: false, message: fieldsMessageTranslations.common}]}
                  >
                    <PNumericInput label={t('form.fee')} id={BuildingFieldsEnum.FEE} />
                  </Form.Item>
                </Col>
              </>
            )}

            {(residentialType || initialValues?.subtype) === BuildingSubTypesEnum.STONE && (
              <>
                <Col span={columnsSize}>
                  <Form.Item name={BuildingFieldsEnum.STONE} initialValue={initialValues?.stone}>
                    <PSelect
                      label={t('form.buildingSubType')}
                      options={catalog[OptGroupsEnum.BUILDING_SUBTYPE_STONE]}
                      id={BuildingFieldsEnum.STONE}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </>
        )}
        {(buildingType || initialValues?.type) === BuildingTypeCommonEnum.COMMERCIAL && (
          <>
            <Col span={columnsSize}>
              <Form.Item
                name={BuildingFieldsEnum.PLAN}
                initialValue={initialValues?.plan}
                rules={[{required: true, message: fieldsMessageTranslations.common}]}
              >
                <PSelect label={t('form.plan')} options={buildingPlanOptions} id={BuildingFieldsEnum.PLAN} />
              </Form.Item>
            </Col>
            <Col span={columnsSize}>
              <Form.Item
                name={BuildingFieldsEnum.SUB_TYPE}
                initialValue={initialValues?.subtype}
                rules={[{required: true, message: fieldsMessageTranslations.common}]}
              >
                <PSelect
                  label={
                    buildingType === BuildingTypeCommonEnum.RESIDENTIAL
                      ? t('form.buildingSubType')
                      : t('form.buildingType')
                  }
                  options={commericalSubTypeOptions}
                  id={BuildingFieldsEnum.SUB_TYPE}
                />
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={24}></Col>
        <Col span={isFromPopup ? 16 : 12}>
          <Form.Item name={BuildingFieldsEnum.INFO} initialValue={initialValues?.info} rules={[{required: false}]}>
            <PInput label={t('form.info')} id={BuildingFieldsEnum.INFO} isTextarea rows={5} />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16} className={`mb-9 ${isFromPopup ? 'hidden' : ''}`}>
        <Col span={24}>
          <PFormLabel text={t('form.persons')} isStatic />
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{width: '100%'}} size="large">
            {attachedPersons?.length! > 0 && (
              <PTable
                columns={personColumns}
                dataSource={attachedPersons}
                paginationOptions={{pageSize: 4}}
                rowKey="id"
                showHeader={false}
              />
            )}
            <Row gutter={16}>
              <Col>
                <PButton
                  type="default"
                  icon={<Icon.AddCircle width={24} height={24} fill="currentColor" />}
                  onClick={handlePersonAttachPopup}
                >
                  {t('actions.add')}
                </PButton>
              </Col>
            </Row>
          </Space>
        </Col>
      </Row>
      <Divider className={`${isFromPopup ? 'hidden' : ''}`} />

      <Row gutter={16} className="mb-9">
        <Col span={24}>
          <PFormLabel text={t('form.images')} isStatic />
        </Col>
        <Col span={24}>
          <Form.Item valuePropName="fileList" getValueFromEvent={normFile} noStyle>
            <CImageUploader
              id="buildingImage"
              addon="buildingImage"
              initialFiles={currentBuildingImage}
              isImagesAttached={isImagesAttached}
              onUploadClick={onUploadClick}
              accept={`${fileAccept.JPEG}, ${fileAccept.PNG}, ${fileAccept.WEBM}, ${fileAccept.GIF}`}
              isDraggable
              multiple
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={24}>
          <PFormLabel text={t('form.commonFiles')} isStatic />
        </Col>
        <Col span={12}>
          <Form.Item valuePropName="fileList" getValueFromEvent={normFile} noStyle>
            <CFileUploader
              id="buildingFile"
              addon="buildingFile"
              initialFiles={currentBuildingFile}
              isFilesAttached={isFilesAttached}
              onUploadClick={onUploadClick}
              accept={`${fileAccept.PDF}, ${fileAccept.DOC}, ${fileAccept.PPT}, ${fileAccept.EXCEL}`}
              multiple
            >
              <PButton type="default" icon={<Icon.File width={24} height={24} stroke="currentColor" />}>
                {t('actions.add')}
              </PButton>
            </CFileUploader>
          </Form.Item>
        </Col>
      </Row>

      <div className={`bg-white/[0.4] ${isFromPopup ? '' : 'sticky pb-[1.5%] '} bottom-0 z-20 backdrop-blur-sm`}>
        <Divider className="mb-[1.5%]" />

        <Row gutter={16} justify="end">
          {isFromPopup && (
            <Col>
              <PButton htmlType="button" type="text" theme="default" onClick={onClose}>
                {t('actions.cancel')}
              </PButton>
            </Col>
          )}

          <Col>
            <Form.Item noStyle>
              <PButton
                htmlType="submit"
                type="primary"
                loading={isButtonLoading}
                disabled={isFromPopup ? !address : false}
                icon={
                  mode === ActionModesEnum.CREATE ? (
                    <Icon.AddSquare width={24} height={24} fill="white" />
                  ) : mode === ActionModesEnum.UPDATE ? (
                    <Icon.Edit width={24} height={24} />
                  ) : null
                }
              >
                {`${getTranslation(ActionModeHero, mode!)}`}
              </PButton>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  );
};
