import {Col, Row, Upload, UploadFile, UploadProps} from 'antd';
import {memo, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {Icon} from 'assets/icons';
import {PButton} from 'components/primitives/p-button';
import {addFolderAction, updateFolderAction} from 'store/app/actions';
import {
  appBuildingFileSelector,
  appCurrentBuildingFileSelector,
  appCurrentFileSelector,
  appCurrentFloorFileSelector,
  appCurrentRoomFileSelector,
  appFileSelector,
  appFloorFileSelector,
  appRoomFileSelector,
} from 'store/app/selectors';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {PFormLabel} from 'components/primitives/p-form-label';
import {injectFormattedFile} from 'utils';
import {FILE_SIZE, MAX_UPLOAD_LENGTH} from 'constants/sizes';
import {useToast} from 'hooks';
import {ImageViewEnum, AddonsEnum, FoldersEnum, HandledFieldsEnum} from 'enums';
import {IUploadedFile} from 'types/common';

import {CFileUploaderProps} from './c-file-uploader.types';

const props = ({isReadonly}: {isReadonly?: boolean}): UploadProps => ({
  listType: 'picture',
  defaultFileList: [],
  showUploadList: {
    showDownloadIcon: true,
    downloadIcon: (file: IUploadedFile) => {
      return <Icon.DocumentDownload />;
    },
    showRemoveIcon: !isReadonly,
    removeIcon: <Icon.CloseSquare />,
  },
  onDownload: (file: IUploadedFile) => {
    fetch(file.downloadUrl!, {
      method: 'GET',
      mode: 'no-cors',
    })
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob], {type: 'application/pdf'}));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.downloadName!);
        document.body.appendChild(link);

        setTimeout(() => {
          link.click();
          link.parentNode?.removeChild(link);
          window.URL.revokeObjectURL(url);
        }, 100);
      });
  },
});

const CFileUploader = ({
  id,
  addon,
  children,
  initialFiles,
  isFilesAttached = true,
  isReadonly = false,
  onUploadClick,
  ...rest
}: CFileUploaderProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {contextHolder, showError, showSuccess, showInfo} = useToast();
  const [isShowUploadButton, setIsShowUploadButton] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadErrors, setUploadErrors] = useState<Record<string, boolean>>({maxFiles: false, fileSize: false});

  const [uploadedFiles, setUploadedFiles] = useState<IUploadedFile[]>([]);
  const [removals, setRemovals] = useState<number[]>([]);

  const file = useSelector(appFileSelector);
  const floorFile = useSelector(appFloorFileSelector);
  const roomFile = useSelector(appRoomFileSelector);
  const buildingFile = useSelector(appBuildingFileSelector);

  const currentFile = useSelector(appCurrentFileSelector);
  const currentRoomFile = useSelector(appCurrentRoomFileSelector);
  const currentFloorFile = useSelector(appCurrentFloorFileSelector);
  const currentBuildingFile = useSelector(appCurrentBuildingFileSelector);

  const beforeUpload = useCallback((file: UploadFile) => {
    return false;
  }, []);

  const handleChange: UploadProps['onChange'] = useCallback(
    ({file, fileList}: {file: any; fileList: any}) => {
      const duplicates = uploadedFiles.map((f: any) => (f.name === file.name ? file.name : null)).filter(f => f);
      const newFiles = fileList.filter((f: any) => !f.id);
      const largeFiles = fileList.filter((f: any) => f.size > FILE_SIZE);

      if (!newFiles.length) {
        setIsShowUploadButton(false);
        onUploadClick && onUploadClick(HandledFieldsEnum.IS_FILES_ATTACHED, undefined);
      }

      setUploadErrors(prev => ({...prev, maxFiles: newFiles.length > MAX_UPLOAD_LENGTH, fileSize: largeFiles.length}));

      if (duplicates.length) return;

      setUploadedFiles(fileList);
      setIsShowUploadButton(true);
    },
    [uploadedFiles, onUploadClick]
  );

  const handleRemove: UploadProps['onRemove'] = useCallback(
    (file: IUploadedFile) => {
      setUploadedFiles(uploadedFiles.filter((f: any) => f.uid !== file.uid));

      if (initialFiles && file.removal) {
        setRemovals(prev => [...prev, file.removal!]);
        setIsShowUploadButton(true);
      }
    },
    [uploadedFiles, initialFiles]
  );

  const handleUploadFiles = useCallback(() => {
    const folder =
      addon === AddonsEnum.ROOMS
        ? currentRoomFile
        : addon === AddonsEnum.FLOORS || addon === AddonsEnum.COMMERCIAL
        ? currentFloorFile
        : addon === AddonsEnum.BUILDING_FILE
        ? currentBuildingFile
        : currentFile;

    const recentlyCreatedFolder =
      addon === AddonsEnum.ROOMS
        ? roomFile
        : addon === AddonsEnum.FLOORS || addon === AddonsEnum.COMMERCIAL
        ? floorFile
        : addon === AddonsEnum.BUILDING_FILE
        ? buildingFile
        : file;

    const existedFolder = folder || recentlyCreatedFolder;

    const updates = uploadedFiles
      .map((file, i) => {
        if (file.id) {
          return {
            view: file.view,
            order: i + 1,
            id: file.id,
          };
        }

        return undefined;
      })
      .filter(item => item !== undefined);

    const uploads = uploadedFiles.map((file, i) => ({
      view: ImageViewEnum.NONE,
      order: i + 1,
    }));
    const list = uploadedFiles.map(f => f.originFileObj);

    setIsLoading(true);
    showInfo({message: t('info.filesSaving')});

    if (existedFolder) {
      dispatch(
        updateFolderAction(
          {
            folder: existedFolder.id || existedFolder,
            updates: [...updates],
            uploads: [...uploads],
            removals: [...removals],
            'uploadfiles[]': [...list],
          },
          {
            folder: FoldersEnum.FILE,
            addon,
            onFulfilled: data => {
              const updatedList = data.map((file: any) => injectFormattedFile(file, FoldersEnum.FILE));

              setUploadedFiles(updatedList);
              setRemovals([]);
              setIsLoading(false);
              setIsShowUploadButton(false);

              onUploadClick && onUploadClick(HandledFieldsEnum.IS_FILES_EMPTY, !data.length ? true : false);
              showSuccess({message: t('info.filesSaved')});
            },
            onReject: () => {
              setIsLoading(false);
              showError({message: fieldsMessageTranslations.commonErrors});
            },
          }
        )
      );
    } else {
      dispatch(
        addFolderAction(
          {
            uploads: [...uploads],
            'uploadfiles[]': [...list],
          },
          {
            folder: FoldersEnum.FILE,
            addon,
            onFulfilled: data => {
              const createdList = data.map((file: any) => injectFormattedFile(file, FoldersEnum.FILE));

              setUploadedFiles(createdList);
              setIsLoading(false);
              setIsShowUploadButton(false);
              showSuccess({message: t('info.filesSaved')});
            },
            onReject: () => {
              setIsLoading(false);
              showError({message: fieldsMessageTranslations.commonErrors});
            },
          }
        )
      );
    }

    onUploadClick && onUploadClick(HandledFieldsEnum.IS_FILES_ATTACHED, true);
  }, [
    uploadedFiles,
    file,
    roomFile,
    floorFile,
    buildingFile,
    currentFile,
    currentRoomFile,
    currentFloorFile,
    currentBuildingFile,
    removals,
    addon,
    dispatch,
    onUploadClick,
    showSuccess,
    showInfo,
    showError,
    t,
  ]);

  useEffect(() => {
    if (initialFiles) {
      const list = initialFiles.files.map((file: any) => injectFormattedFile(file, FoldersEnum.FILE));

      setUploadedFiles(list);
    } else {
      setUploadedFiles([]);
    }
  }, [initialFiles]);

  return (
    <Row gutter={[0, 16]}>
      <Col span={16}>
        {!uploadedFiles.length && isReadonly && (
          <div className="w-[130px] h-[130px] flex items-center justify-center bg-grey-60 rounded-sm">
            <Icon.File width={40} height={40} />
          </div>
        )}

        <Upload
          {...props({isReadonly})}
          {...rest}
          fileList={uploadedFiles}
          defaultFileList={[...uploadedFiles]}
          onChange={handleChange}
          beforeUpload={beforeUpload}
          onRemove={handleRemove}
        >
          {!isReadonly ? children : null}
        </Upload>
      </Col>
      <Col span={14}>
        {(isShowUploadButton || removals.length > 0) && (
          <PButton
            id={`file-upload-complete${id ? `-${id}` : ''}`}
            loading={isLoading}
            disabled={uploadErrors.maxFiles || uploadErrors.fileSize}
            size="large"
            type="primary"
            icon={<Icon.Upload fill="currentColor" />}
            onClick={handleUploadFiles}
          >
            {t('actions.save')}
          </PButton>
        )}
      </Col>

      <Col span={18}>
        {!isFilesAttached && (
          <PFormLabel type="danger" text={fieldsMessageTranslations.endFileAttach} isStatic className="mt-1" />
        )}
        {uploadErrors.maxFiles ? (
          <PFormLabel type="danger" text={fieldsMessageTranslations.uploadedFilesLength} isStatic className="!m-2" />
        ) : null}
        {uploadErrors.fileSize ? (
          <PFormLabel type="danger" text={fieldsMessageTranslations.fileSize} isStatic className="!m-2" />
        ) : null}
      </Col>

      {contextHolder}
    </Row>
  );
};

export const CFileUploaderMemoized = memo(CFileUploader);
