export enum PricesFieldsEnum {
  PROPERTY = 'property',
  TOTAL_SALE = 'total_sale',
  TOTAL_MONTHLY = 'total_monthly',
  TOTAL_DAILY = 'total_daily',
  UNIT_SALE = 'unit_sale',
  UNIT_MONTHLY = 'unit_monthly',
  UNIT_DAILY = 'unit_daily',
  CURRENCY_SALE = 'currency_sale',
  CURRENCY_MONTHLY = 'currency_monthly',
  CURRENCY_DAILY = 'currency_daily',
  CREATED = 'created',

  TOTAL_PRICE_SALE = 'total_price_sale',
  TOTAL_PRICE_MONTHLY = 'total_price_monthly',
  TOTAL_PRICE_DAILY = 'total_price_daily',
  UNIT_PRICE_SALE = 'unit_price_sale',
  UNIT_PRICE_MONTHLY = 'unit_price_monthly',
  UNIT_PRICE_DAILY = 'unit_price_daily',
}
