import {Col, Form, FormInstance, Row} from 'antd';
import {useTranslation} from 'react-i18next';

import {PSelect} from 'components/primitives/p-select';
import {PFormLabel} from 'components/primitives/p-form-label';
import {PNumericInput} from 'components/primitives/p-numeric-input';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {currencyOptions} from 'constants/select-options';
import {FormsEnum} from 'enums';

import {IPricesFields} from './form-update-price.interfaces';
import {PricesFieldsEnum} from './form-update-price.enums';

export const FormUpdatePrice = ({
  form,
  values,
  initialValues,
  onSumbit,
}: {
  form: FormInstance<IPricesFields>;
  values: IPricesFields;
  onSumbit: (v: IPricesFields) => void;
  initialValues?: IPricesFields;
}) => {
  const {t} = useTranslation();

  return (
    <Form form={form} onFinish={onSumbit} initialValues={initialValues} name={FormsEnum.UPDATE_PRICE}>
      <Row gutter={16} align="middle">
        <Col span={3}>
          <PFormLabel text={t('form.sale')} innerClassName="mb-0" isStatic />
        </Col>
        <Col span={7}>
          <Form.Item
            name={PricesFieldsEnum.TOTAL_PRICE_SALE}
            rules={[{required: false, message: fieldsMessageTranslations.common}]}
          >
            <PNumericInput label={t('form.price')} id={PricesFieldsEnum.TOTAL_PRICE_SALE} />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            name={PricesFieldsEnum.UNIT_PRICE_SALE}
            rules={[{required: false, message: fieldsMessageTranslations.common}]}
          >
            <PNumericInput
              label={`${t('form.pricePerArea')} (${t('shorts.squareMeter')})`}
              id={PricesFieldsEnum.UNIT_PRICE_SALE}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name={PricesFieldsEnum.CURRENCY_SALE}
            rules={[{required: false, message: fieldsMessageTranslations.common}]}
          >
            <PSelect label={t('form.currency')} options={currencyOptions} id={PricesFieldsEnum.CURRENCY_SALE} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} align="middle">
        <Col span={3}>
          <PFormLabel text={t('form.rent')} innerClassName="mb-0" isStatic />
        </Col>
        <Col span={7}>
          <Form.Item
            name={PricesFieldsEnum.TOTAL_PRICE_MONTHLY}
            rules={[{required: false, message: fieldsMessageTranslations.common}]}
          >
            <PNumericInput label={t('form.price')} id={PricesFieldsEnum.TOTAL_PRICE_MONTHLY} />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            name={PricesFieldsEnum.UNIT_PRICE_MONTHLY}
            rules={[{required: false, message: fieldsMessageTranslations.common}]}
          >
            <PNumericInput
              label={`${t('form.pricePerArea')} (${t('shorts.squareMeter')})`}
              id={PricesFieldsEnum.UNIT_PRICE_MONTHLY}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name={PricesFieldsEnum.CURRENCY_MONTHLY}
            rules={[{required: false, message: fieldsMessageTranslations.common}]}
          >
            <PSelect label={t('form.currency')} options={currencyOptions} id={PricesFieldsEnum.CURRENCY_MONTHLY} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} align="middle">
        <Col span={3}>
          <PFormLabel text={t('form.rentDaily')} innerClassName="mb-0" isStatic />
        </Col>
        <Col span={7}>
          <Form.Item
            name={PricesFieldsEnum.TOTAL_PRICE_DAILY}
            rules={[{required: false, message: fieldsMessageTranslations.common}]}
          >
            <PNumericInput label={t('form.price')} id={PricesFieldsEnum.TOTAL_PRICE_DAILY} />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            name={PricesFieldsEnum.UNIT_PRICE_DAILY}
            rules={[{required: false, message: fieldsMessageTranslations.common}]}
          >
            <PNumericInput
              label={`${t('form.pricePerArea')} (${t('shorts.squareMeter')})`}
              id={PricesFieldsEnum.UNIT_PRICE_DAILY}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name={PricesFieldsEnum.CURRENCY_DAILY}
            rules={[{required: false, message: fieldsMessageTranslations.common}]}
          >
            <PSelect label={t('form.currency')} options={currencyOptions} id={PricesFieldsEnum.CURRENCY_DAILY} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
