import {FILE_SIZE, MAX_UPLOAD_LENGTH} from './sizes';

export const fieldsMessageTranslations = {
  common: 'Խնդրում ենք լրացնել:',
  commonErrors: 'Տեղի է ունեցել սխալ։ Խնդրում ենք կրկին փորձել։',
  address: 'Խնդրում ենք ստեղծել կամ ավելացնել հասցե:',
  endAddress: 'Ավարտեք հասցեն:',
  endFileAttach: 'Ավարտեք ֆայլերի կցումը:',
  endImageAttach: 'Ավարտեք նկարների կցումը:',
  fillOrRemove: 'Լրացրեք կամ հեռացրեք դաշտը:',
  validEmail: 'Մուտքագրեք վավեր էլ․ փոստ:',
  phoneMin: 'Մուտքագրեք մինիմում 9 նիշ:',
  phoneMax: 'Մուտքագրեք մաքսիմում 14 նիշ:',
  fileSize: 'Յուրաքանչյուր ֆայլի չափը չպիտի գերազանցի ' + FILE_SIZE / 1024 / 1024 + 'MB-ը:',
  uploadedFilesLength: 'Նոր ներբեռնվող ֆայլերի քանակը չպիտի գերազանցի ' + MAX_UPLOAD_LENGTH + '-ը:',
} as const;
