export const CSS = {
  button:
    'cursor-pointer flex items-center min-h-[44px] gap-2.5 p-2.5 rounded-sm hover:bg-danger-secondary transition-all',
  link: {
    base: 'nav-link flex items-center min-h-[44px] p-2.5 rounded-sm gap-2.5 hover:bg-grey-20 transition-all',
    collapsed: 'justify-center gap-0',
    active: 'bg-primary hover:bg-primary active',
  },
  text: {
    base: 'text-sm',
    color: 'text-grey-80',
  },
} as const;
