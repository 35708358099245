import {Checkbox, Col, Divider, Form, Row, Space, Tooltip} from 'antd';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import {DefaultOptionType} from 'antd/es/select';
import debounce from 'lodash.debounce';
import {useTranslation} from 'react-i18next';

import {Icon} from 'assets/icons';
import {PButton} from 'components/primitives/p-button';
import {PInput} from 'components/primitives/p-input';
import {PFormLabel} from 'components/primitives/p-form-label';
import {ActionModeHero} from 'constants/translations';
import {usePopup} from 'hooks';
import {
  ActionModesEnum,
  BuildingTypeCommonEnum,
  CrossCalculationModesEnum,
  OptGroupsEnum,
  PropertyTypesEnum,
  ContractTypesEnum,
  StatusEnum,
  AddonsEnum,
  HandledFieldsEnum,
  FormsEnum,
  RolesEnum,
} from 'enums';
import {PTable} from 'components/primitives/p-table';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {PopupPersonAttach} from 'components/popups/person-attach';
import {fileAccept} from 'constants/file-accept';
import {getTranslation} from 'utils';
import {PNumericInput} from 'components/primitives/p-numeric-input';
import {PSelect} from 'components/primitives/p-select';
import {
  appCatalogSelector,
  appCurrentFileSelector,
  appCurrentImageSelector,
  appProfileSelector,
} from 'store/app/selectors';
import {
  booleanOptions,
  propertyTypeOptions,
  contractModeOptions,
  currencyOptions,
  propertyStatusOptions,
} from 'constants/select-options';
import {PopupChangeProcess} from 'components/popups/change-process';
import {pricesColumns} from 'components/pages/property-detailed/constants/prices-columns';
import {PopupUpdatePrice} from 'components/popups/update-price';
import {PopupPropertyAddons} from 'components/popups/property-addons';
import {IPropertyAddonsFields} from 'components/forms/property-addons/form-property-addons.interfaces';
import {normFile} from 'utils';
import {CENTER_DEFAULT} from 'constants/map';
import {CMap} from 'components/containers/c-map';
import {AddressFieldsEnum} from 'components/forms/address/form-address.enums';
import {buildingCurrentSelector, buildingsListSelector} from 'store/buildings/selectors';
import {getBuildingsAction} from 'store/buildings/actions';
import {leadsCurrentSelector} from 'store/leads/selectors';
import {PopupBuilding} from 'components/popups/building';
import {PLoader} from 'components/primitives/p-loader';
import {hasUnsavedChangesAction} from 'store/properties/actions';
import {propertyHasUnsavedChangesSelector} from 'store/properties/selectors';
import {bookingsColumns} from 'components/pages/property-detailed/constants/bookings-columns';
import {PopupBooking} from 'components/popups/booking';
import {PFrozenProperty} from 'components/primitives/p-frozed-property';
import {CImageUploader} from 'components/containers/c-image-uploader';
import {CFileUploader} from 'components/containers/c-file-uploader';
import {PCardWrapper} from 'components/primitives/p-card-wrapper';

import {PFormPropertyProps} from './form-property.types';
import {PropertyFieldsEnum} from './form-property.enums';
import {websiteFields} from './constants/website-fields';

export const FormProperty = ({
  form,
  values,
  initialValues = {
    status: StatusEnum.CURRENT,
  },
  addressInfo,
  buildingInfo,
  isButtonLoading,
  personColumns,
  attachedPersons,
  attachedRooms,
  attachedFloors,
  mode,
  isAddressLoading,
  isFilesAttached = true,
  isImagesAttached = true,
  isAddressAttached = true,
  isBuildingSelected = true,
  isAddressSelected = true,
  onAddressAdd,
  handlePriceChange,
  handleAddonDelete,
  onUploadClick,
  onSubmit,
  setAttachedPersons,
  setAttachedRooms,
  setAttachedFloors,
  setAddressInfo,
  setBuildingInfo,
  setHandledFields,
}: PFormPropertyProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(appProfileSelector);
  const buildings = useSelector(buildingsListSelector);
  const currentBuilding = useSelector(buildingCurrentSelector);
  const hasUnsavedChanges = useSelector(propertyHasUnsavedChangesSelector);
  const [isSearchDropdownOpen, setIsSearchDropdownOpen] = useState<boolean | undefined>(undefined);

  const {show} = usePopup();
  const {id} = useParams();
  const catalog = useSelector(appCatalogSelector);
  const currentLead = useSelector(leadsCurrentSelector);
  const currentImage = useSelector(appCurrentImageSelector);
  const currentFile = useSelector(appCurrentFileSelector);
  const isReadonly = useMemo(() => profile?.type === RolesEnum.MARKETING, [profile]);
  const contractOptions = useMemo(
    () =>
      values?.type === PropertyTypesEnum.LAND
        ? contractModeOptions?.filter(c => c.value === ContractTypesEnum.SALE)
        : values?.type === PropertyTypesEnum.COMMERCIAL || values?.type === PropertyTypesEnum.HOUSE
        ? contractModeOptions?.filter(c => c.value !== ContractTypesEnum.DAILY)
        : contractModeOptions,
    [values]
  );
  const repairOptions = useMemo(
    () =>
      values?.type === PropertyTypesEnum.COMMERCIAL
        ? catalog[OptGroupsEnum.REPAIR_TYPES]?.filter(c => c.value !== 41)
        : catalog[OptGroupsEnum.REPAIR_TYPES],
    [values, catalog]
  );
  const [buildingKeyword, setBuildingKeyword] = useState<string | null>();
  const [isBuildingsLoading, setIsBuildingsLoading] = useState<boolean>(false);
  const [searchedAddress, setSearchedAddress] = useState<DefaultOptionType[]>([]);

  const onPropcessChange = useCallback(
    (id: number) => {
      show(PopupChangeProcess, {
        id,
      });
    },
    [show]
  );

  const handleAddonPopup = useCallback(
    (entity?: IPropertyAddonsFields, mode?: ActionModesEnum) => {
      show(PopupPropertyAddons, {
        mode,
        type:
          values?.type === PropertyTypesEnum.COMMERCIAL
            ? AddonsEnum.COMMERCIAL
            : values?.type === PropertyTypesEnum.HOUSE
            ? AddonsEnum.FLOORS
            : AddonsEnum.ROOMS,
        entity,
        id,
        setAttachedRooms,
        setAttachedFloors,
      });
    },
    [id, values?.type, setAttachedRooms, setAttachedFloors, show]
  );

  const handleBuildingPopup = useCallback(() => {
    setIsSearchDropdownOpen(false);

    show(PopupBuilding, {
      mode: ActionModesEnum.CREATE,
      propertyType: values?.type,
      onPopupClose: () => setIsSearchDropdownOpen(undefined),
    });
  }, [values, show]);

  const handlePersonAttachPopup = useCallback(() => {
    show(PopupPersonAttach, {
      mode: getTranslation(ActionModeHero, ActionModesEnum.ADD),
      attachedPersons,
      setAttachedPersons,
    });
  }, [attachedPersons, show, setAttachedPersons]);

  const handlePricesPopup = useCallback(() => {
    show(PopupUpdatePrice, {
      porpertyId: initialValues.id,
    });
  }, [initialValues, show]);

  const handleBookingPopup = useCallback(() => {
    show(PopupBooking, {
      id: initialValues.id,
    });
  }, [initialValues, show]);

  useEffect(() => {
    if (values?.type === PropertyTypesEnum.LAND) {
      form.setFieldValue(
        PropertyFieldsEnum.RENT,
        form.getFieldValue(PropertyFieldsEnum.RENT)?.filter((v: any) => v === ContractTypesEnum.SALE)
      );
    }
  }, [values, form]);

  const onBuildingKeyUp = useMemo(
    () =>
      debounce((e: any) => {
        setBuildingKeyword(e.target.value);
      }, 500),
    []
  );

  const handleBuildingSelectSearch = useCallback(() => {
    setIsBuildingsLoading(true);
  }, []);

  const handleBuildingSelectOpen = useCallback(
    (open: boolean) => {
      if (!open) return;

      setIsBuildingsLoading(true);

      const type = {
        type:
          values?.type === PropertyTypesEnum.APARTMENT
            ? BuildingTypeCommonEnum.RESIDENTIAL
            : values?.type === PropertyTypesEnum.COMMERCIAL
            ? BuildingTypeCommonEnum.COMMERCIAL
            : null,
      };
      dispatch(
        getBuildingsAction(
          {...type, length: 100},
          {onFulfilled: () => setIsBuildingsLoading(false), onReject: () => setIsBuildingsLoading(false)}
        )
      );
    },
    [values?.type, dispatch]
  );

  const onBuildingSelect = useCallback(
    (value: any, option: any) => {
      const selectedBuilding = searchedAddress?.filter(a => a.id === option.id);

      setBuildingInfo(selectedBuilding[0]);
      setHandledFields && setHandledFields(prev => ({...prev, [HandledFieldsEnum.IS_BUILDING_SELECTED]: true}));
    },
    [searchedAddress, setBuildingInfo, setHandledFields]
  );

  const onBuildingClear = useCallback(() => {
    setBuildingKeyword('');
    setBuildingInfo(null);
    setHandledFields && setHandledFields(prev => ({...prev, [HandledFieldsEnum.IS_BUILDING_SELECTED]: false}));
  }, [setBuildingInfo, setHandledFields]);

  const handleUnsavedChanges = useCallback(() => {
    if (!hasUnsavedChanges) {
      dispatch(hasUnsavedChangesAction(true));
    }
  }, [hasUnsavedChanges, dispatch]);

  useEffect(() => {
    if (values?.type !== PropertyTypesEnum.APARTMENT && values?.type !== PropertyTypesEnum.COMMERCIAL) return;

    const type = {
      type:
        values?.type === PropertyTypesEnum.APARTMENT
          ? BuildingTypeCommonEnum.RESIDENTIAL
          : values?.type === PropertyTypesEnum.COMMERCIAL
          ? BuildingTypeCommonEnum.COMMERCIAL
          : null,
    };
    const keyword = currentBuilding ? null : {combined: buildingKeyword || null};

    dispatch(
      getBuildingsAction(
        {...type, ...keyword, length: 100},
        {onFulfilled: () => setIsBuildingsLoading(false), onReject: () => setIsBuildingsLoading(false)}
      )
    );
  }, [values?.type, currentBuilding, buildingKeyword, dispatch]);

  useEffect(() => {
    if (buildings.length) {
      const data = buildings.map(b => ({
        key: b?.id,
        id: b?.id,
        value: b?.address?.combined,
        label: `${b?.street?.name} ${b?.address?.number ?? ''}`,
        address: b?.address,
        country: b?.country,
        district: b?.district,
        region: b?.region,
        settlement: b?.settlement,
        street: b?.street,
      }));

      setSearchedAddress(data);
    } else {
      setSearchedAddress([]);
    }
  }, [buildings]);

  useEffect(() => {
    if (currentBuilding) {
      const selectedBuilding = searchedAddress?.filter(a => a.id === currentBuilding);

      form.setFieldValue(PropertyFieldsEnum.SEARCHED_BUILDING, selectedBuilding[0]);

      setBuildingInfo(selectedBuilding[0]);
      setBuildingKeyword('');
      setHandledFields && setHandledFields(prev => ({...prev, [HandledFieldsEnum.IS_BUILDING_SELECTED]: true}));
    }
  }, [currentBuilding, form, searchedAddress, setBuildingInfo, setHandledFields]);

  return (
    <PCardWrapper padding="md" className="pb-0">
      <Form
        form={form}
        name={FormsEnum.PROPERTY}
        scrollToFirstError
        onFinish={onSubmit}
        onFinishFailed={() => {
          window.scroll({top: 0, behavior: 'smooth'});
        }}
        onValuesChange={handleUnsavedChanges}
      >
        <Row justify="space-between">
          <Col span={16}>
            <PFormLabel isStatic text={t('form.mainInfo')} />

            <Row gutter={16}>
              <Col span={5}>
                <Form.Item
                  name={PropertyFieldsEnum.TYPE}
                  initialValue={initialValues?.type}
                  rules={[{required: true, message: fieldsMessageTranslations.common}]}
                >
                  <PSelect
                    label={t('form.propertyType')}
                    options={propertyTypeOptions}
                    id={PropertyFieldsEnum.TYPE}
                    disabled={isReadonly}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name={PropertyFieldsEnum.CONTRACT}
                  initialValue={initialValues?.contract}
                  rules={[{required: true, message: fieldsMessageTranslations.common}]}
                >
                  <PSelect
                    label={t('form.contract')}
                    id={PropertyFieldsEnum.CONTRACT}
                    options={catalog[OptGroupsEnum.CONTRACT]}
                    disabled={isReadonly}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name={PropertyFieldsEnum.STATUS}
                  initialValue={initialValues.status}
                  rules={[{required: false, message: fieldsMessageTranslations.common}]}
                >
                  <PSelect
                    label={t('form.status')}
                    options={propertyStatusOptions}
                    id={PropertyFieldsEnum.STATUS}
                    disabled={isReadonly}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              {values?.type === PropertyTypesEnum.LAND && (
                <>
                  <Col span={5}>
                    <Form.Item name={PropertyFieldsEnum.USAGE} initialValue={initialValues.usage}>
                      <PSelect
                        label={t('form.usage')}
                        options={catalog[OptGroupsEnum.SOIL_IMPORTANCE]}
                        disabled={isReadonly}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item name={PropertyFieldsEnum.CADASTRE_NUMBER} initialValue={initialValues.cadastre_number}>
                      <PInput label={t('form.cadastreNumber')} readOnly={isReadonly} />
                    </Form.Item>
                  </Col>
                </>
              )}
              {values?.type === PropertyTypesEnum.HOUSE && (
                <Col span={6}>
                  <Form.Item name={PropertyFieldsEnum.STATE}>
                    <PSelect
                      label={t('form.houseType')}
                      options={catalog[OptGroupsEnum.HOUSE_TYPES_PRIVATE]}
                      id={PropertyFieldsEnum.STATE}
                      disabled={isReadonly}
                      variant="filled"
                    />
                  </Form.Item>
                </Col>
              )}
              {values?.type === PropertyTypesEnum.COMMERCIAL && (
                <>
                  <Col span={5}>
                    <Form.Item
                      name={PropertyFieldsEnum.TITLE}
                      initialValue={initialValues?.title}
                      rules={[{required: false, message: fieldsMessageTranslations.address}]}
                    >
                      <PInput label={t('form.title')} id={PropertyFieldsEnum.TITLE} readOnly={isReadonly} />
                    </Form.Item>
                  </Col>
                </>
              )}
              {values?.type === PropertyTypesEnum.APARTMENT && (
                <Col span={3}>
                  <Form.Item
                    initialValue={initialValues?.area}
                    name={PropertyFieldsEnum.AREA}
                    rules={[{required: false, message: fieldsMessageTranslations.common}]}
                  >
                    <PNumericInput
                      onChange={(value: number | string | null) =>
                        handlePriceChange(value, CrossCalculationModesEnum.AREA)
                      }
                      label={`${t('form.area')} (${t('shorts.squareMeter')})`}
                      id={PropertyFieldsEnum.AREA}
                      readOnly={isReadonly}
                    />
                  </Form.Item>
                </Col>
              )}
              {values?.type === PropertyTypesEnum.COMMERCIAL && (
                <>
                  <Col span={3}>
                    <Form.Item
                      name={PropertyFieldsEnum.TOTAL_AREA}
                      initialValue={initialValues?.total_area}
                      rules={[{required: false, message: fieldsMessageTranslations.common}]}
                    >
                      <PNumericInput
                        label={t('form.area')}
                        id={PropertyFieldsEnum.TOTAL_AREA}
                        suffix={t('shorts.squareMeter')}
                        onChange={(value: number | string | null) =>
                          handlePriceChange(value, CrossCalculationModesEnum.AREA)
                        }
                        readOnly={isReadonly}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name={PropertyFieldsEnum.COMMERCIAL_AREA}
                      initialValue={initialValues?.commercial_area}
                      rules={[{required: false, message: fieldsMessageTranslations.common}]}
                    >
                      <PNumericInput
                        label={t('form.commercialArea')}
                        id={PropertyFieldsEnum.COMMERCIAL_AREA}
                        suffix={t('shorts.squareMeter')}
                        readOnly={isReadonly}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name={PropertyFieldsEnum.LAND_AREA}
                      initialValue={initialValues?.total_area}
                      rules={[{required: false, message: fieldsMessageTranslations.common}]}
                    >
                      <PNumericInput
                        label={t('form.landArea')}
                        id={PropertyFieldsEnum.LAND_AREA}
                        suffix={t('shorts.squareMeter')}
                        readOnly={isReadonly}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
              {values?.type === PropertyTypesEnum.LAND && (
                <Col span={5}>
                  <Space.Compact>
                    <Form.Item
                      name={PropertyFieldsEnum.SQUARE_METRE}
                      rules={[{required: false, message: fieldsMessageTranslations.common}]}
                    >
                      <PNumericInput
                        onChange={(value: number | string | null) =>
                          handlePriceChange(value, CrossCalculationModesEnum.LAND_SQUARE, PropertyFieldsEnum.HECTARE)
                        }
                        label={`${t('form.area')} (${t('shorts.squareMeter')})`}
                        id={PropertyFieldsEnum.SQUARE_METRE}
                        readOnly={isReadonly}
                      />
                    </Form.Item>
                    <Form.Item
                      name={PropertyFieldsEnum.HECTARE}
                      rules={[{required: false, message: fieldsMessageTranslations.common}]}
                    >
                      <PNumericInput
                        withoutFormatter
                        onChange={(value: number | string | null) =>
                          handlePriceChange(
                            value,
                            CrossCalculationModesEnum.LAND_HECTARE,
                            PropertyFieldsEnum.SQUARE_METRE
                          )
                        }
                        label={`(${t('shorts.hectare')})`}
                        id={PropertyFieldsEnum.HECTARE}
                        readOnly={isReadonly}
                      />
                    </Form.Item>
                  </Space.Compact>
                </Col>
              )}
              {values?.type === PropertyTypesEnum.HOUSE && (
                <>
                  <Col span={4}>
                    <Form.Item
                      name={PropertyFieldsEnum.LIVING_AREA}
                      rules={[{required: false, message: fieldsMessageTranslations.common}]}
                    >
                      <PNumericInput
                        label={`${t('form.livingArea')} (${t('shorts.squareMeter')})`}
                        id={PropertyFieldsEnum.LIVING_AREA}
                        readOnly={isReadonly}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name={PropertyFieldsEnum.LAND_AREA}
                      rules={[{required: false, message: fieldsMessageTranslations.common}]}
                    >
                      <PNumericInput
                        label={`${t('form.landArea')} (${t('shorts.squareMeter')})`}
                        id={PropertyFieldsEnum.LAND_AREA}
                        readOnly={isReadonly}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
              {(values?.type === PropertyTypesEnum.APARTMENT ||
                values?.type === PropertyTypesEnum.HOUSE ||
                values?.type === PropertyTypesEnum.COMMERCIAL) && (
                <Col span={3}>
                  <Form.Item
                    name={
                      values?.type === PropertyTypesEnum.APARTMENT
                        ? PropertyFieldsEnum.FLOOR
                        : PropertyFieldsEnum.FLOOR_PLAN
                    }
                    initialValue={values?.type === PropertyTypesEnum.APARTMENT ? values.floor : values.floor_plan}
                    rules={[{required: false, message: fieldsMessageTranslations.common}]}
                  >
                    <PNumericInput
                      label={values?.type === PropertyTypesEnum.APARTMENT ? t('form.floor') : t('form.floors')}
                      id="floor"
                      withoutFormatter
                      readOnly={isReadonly}
                    />
                  </Form.Item>
                </Col>
              )}
              {(values?.type === PropertyTypesEnum.APARTMENT || values?.type === PropertyTypesEnum.HOUSE) && (
                <Col span={3}>
                  <Form.Item
                    name={PropertyFieldsEnum.BEDROOMS}
                    initialValue={initialValues?.bedrooms}
                    rules={[{required: false, message: fieldsMessageTranslations.common}]}
                  >
                    <PNumericInput
                      withoutFormatter
                      label={values?.type === PropertyTypesEnum.APARTMENT ? t('form.room') : t('form.bedroom')}
                      id={PropertyFieldsEnum.BEDROOMS}
                      readOnly={isReadonly}
                    />
                  </Form.Item>
                </Col>
              )}
              {values?.type === PropertyTypesEnum.LAND && (
                <Col span={5}>
                  <Form.Item name={PropertyFieldsEnum.FRONT_LENGTH} initialValue={initialValues.front_length}>
                    <PNumericInput
                      withoutFormatter
                      label={`${t('form.frontLength')} (${t('shorts.meterShort')})`}
                      readOnly={isReadonly}
                    />
                  </Form.Item>
                </Col>
              )}
              {values?.type === PropertyTypesEnum.COMMERCIAL && (
                <>
                  <Col span={3}>
                    <Form.Item name={PropertyFieldsEnum.ENTRANCE_COUNT} initialValue={initialValues.entrance_count}>
                      <PNumericInput withoutFormatter label={t('form.entranceCount')} readOnly={isReadonly} />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item name={PropertyFieldsEnum.ENTRANCE_FLOOR} initialValue={initialValues.entrance_floor}>
                      <PNumericInput type="number" label={t('form.entranceFloor')} readOnly={isReadonly} />
                    </Form.Item>
                  </Col>
                </>
              )}
              {(values?.type === PropertyTypesEnum.APARTMENT || values?.type === PropertyTypesEnum.COMMERCIAL) && (
                <>
                  <Divider />
                  <Col span={24}>
                    <PFormLabel text={t('form.address')} type={!isBuildingSelected ? 'danger' : 'default'} isStatic />
                  </Col>
                  <Col span={8}>
                    <Form.Item name={PropertyFieldsEnum.SEARCHED_BUILDING} initialValue={initialValues?.street_name}>
                      <PSelect
                        open={isSearchDropdownOpen}
                        placeholder={`${t('form.choose')} ${t('common.building')}`}
                        size="large"
                        loading={isBuildingsLoading}
                        showSearch
                        options={searchedAddress}
                        filterOption={false}
                        onKeyUp={onBuildingKeyUp}
                        onSearch={handleBuildingSelectSearch}
                        onDropdownVisibleChange={handleBuildingSelectOpen}
                        onSelect={onBuildingSelect}
                        onClear={onBuildingClear}
                        disabled={isReadonly}
                        dropdownRender={
                          isBuildingsLoading
                            ? () => (
                                <div className="py-3">
                                  <PLoader />
                                </div>
                              )
                            : undefined
                        }
                        notFoundContent={
                          <div className="flex flex-col justify-center items-center pb-2 pt-2">
                            <div className="text-center mb-3 text-grey-80">{t('info.noInfo')}</div>
                            <div
                              onClick={() => handleBuildingPopup()}
                              className="inline-flex items-center gap-1.5 cursor-pointer text-primary"
                            >
                              <Icon.AddCircle width={20} height={20} fill="currentColor" />
                              {t('actions.add')} {t('common.building')}
                            </div>
                          </div>
                        }
                      ></PSelect>
                    </Form.Item>
                  </Col>
                  {values?.type === PropertyTypesEnum.APARTMENT && (
                    <Col span={4}>
                      <Form.Item
                        name={PropertyFieldsEnum.NUMBER}
                        initialValue={initialValues?.number}
                        rules={[{required: false, message: fieldsMessageTranslations.common}]}
                      >
                        <PInput
                          label={t('form.apartmentNumber')}
                          id={PropertyFieldsEnum.NUMBER}
                          readOnly={isReadonly}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {values?.type === PropertyTypesEnum.COMMERCIAL && (
                    <Col span={4}>
                      <Form.Item
                        name={PropertyFieldsEnum.NUMBER}
                        initialValue={initialValues?.commercial_number}
                        rules={[{required: false, message: fieldsMessageTranslations.common}]}
                      >
                        <PInput
                          label={t('form.commercialNumber')}
                          id={PropertyFieldsEnum.COMMERCIAL_NUMBER}
                          readOnly={isReadonly}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  <Col span={24} id="building-select">
                    {!isBuildingSelected && <PFormLabel type="danger" text={t('validations.addBuilding')} isStatic />}
                  </Col>
                  <Col span={24}>
                    {buildingInfo && (
                      <div className="mb-2">
                        <span className="font-medium">{t('form.selectedAddress')}։</span>{' '}
                        <span className="text-primary">
                          {buildingInfo?.country?.name}, {buildingInfo?.region?.name},{' '}
                          {buildingInfo?.district?.name ? `${buildingInfo?.district?.name}, ` : ''}{' '}
                          {buildingInfo?.settlement?.name}, {buildingInfo?.street?.name},{' '}
                          {buildingInfo?.address?.number}
                        </span>
                      </div>
                    )}
                  </Col>
                  {mode === ActionModesEnum.CREATE && currentLead?.address && (
                    <Col span={24}>
                      <div>
                        <span className="font-medium">{t('form.specifiedAddress')}։</span>
                        {'  '}
                        <span className="text-info text-base">{currentLead?.address}</span>
                      </div>
                    </Col>
                  )}

                  {/* <Col span={24} className="mt-4">
                  <PButton
                    type="default"
                    icon={<Icon.AddCircle width={24} height={24} fill="currentColor" />}
                    onClick={() => handleBuildingPopup()}
                  >
                    {t('actions.add')} շենք
                  </PButton>
                </Col> */}
                  <Divider />
                </>
              )}

              {(values?.type === PropertyTypesEnum.HOUSE ||
                values?.type === PropertyTypesEnum.LAND ||
                values?.type === PropertyTypesEnum.PARKING) && (
                <Col span={24}>
                  <div id="address-select-error">
                    {!isAddressSelected && (
                      <PFormLabel type="danger" text={t('validations.addAddress')} isStatic className="mt-[15px]" />
                    )}
                  </div>
                  <Row gutter={16}>
                    <Col span={24}>
                      <PFormLabel text={t('form.address')} type={!isAddressSelected ? 'danger' : 'default'} isStatic />
                    </Col>
                    {mode === ActionModesEnum.CREATE && currentLead?.address && (
                      <Col span={24}>
                        <div className="mb-[30px]">
                          <span className="font-medium">{t('form.specifiedAddress')}։</span>
                          {'  '}
                          <span className="text-info text-base">{currentLead?.address}</span>
                        </div>
                      </Col>
                    )}
                    <Col span={4}>
                      <Form.Item
                        name={AddressFieldsEnum.COUNTRY_NAME}
                        initialValue={initialValues?.country_name}
                        rules={[{required: false, message: fieldsMessageTranslations.address}]}
                      >
                        <PInput
                          label={t('form.country')}
                          id={AddressFieldsEnum.COUNTRY_NAME}
                          autoComplete="nope"
                          readOnly={isReadonly}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        name={AddressFieldsEnum.SETTLEMENT_NAME}
                        initialValue={initialValues?.settlement_name}
                        rules={[{required: false, message: ''}]}
                      >
                        <PInput
                          label={t('form.city')}
                          id={AddressFieldsEnum.SETTLEMENT_NAME}
                          autoComplete="nope"
                          readOnly={isReadonly}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item name={AddressFieldsEnum.DISTRICT_NAME} initialValue={initialValues?.district_name}>
                        <PInput
                          label={t('form.district')}
                          id={AddressFieldsEnum.DISTRICT_NAME}
                          autoComplete="nope"
                          readOnly={isReadonly}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        name={AddressFieldsEnum.STREET_NAME}
                        initialValue={initialValues?.street_name}
                        rules={[{required: false, message: ''}]}
                      >
                        <PInput
                          label={t('form.street')}
                          id={AddressFieldsEnum.STREET_NAME}
                          autoComplete="nope"
                          readOnly={isReadonly}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        id={AddressFieldsEnum.NUMBER}
                        name={AddressFieldsEnum.NUMBER}
                        initialValue={initialValues?.number}
                        rules={[{required: false, message: ''}]}
                      >
                        <PInput
                          label={t('form.propertyNumber')}
                          id={AddressFieldsEnum.NUMBER}
                          autoComplete="nope"
                          readOnly={isReadonly}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-9">
                    <Col span={19}>
                      <CMap
                        mode={isReadonly ? undefined : 'create'}
                        center={CENTER_DEFAULT}
                        zoom={16}
                        initialAddress={initialValues?.location?.address!}
                        setAddressInfo={setAddressInfo}
                      />
                    </Col>
                    <Col span={3}>
                      {addressInfo && Object.keys(addressInfo).length !== 0 && (
                        <PButton
                          id="map-submit"
                          type="primary"
                          loading={isAddressLoading}
                          icon={<Icon.Location width={24} height={24} fill="white" />}
                          disabled={Object.keys(addressInfo).length === 0}
                          onClick={() => onAddressAdd()}
                        >
                          {`${getTranslation(ActionModeHero, ActionModesEnum.SAVE)}`}
                        </PButton>
                      )}

                      {!isAddressAttached ? (
                        <PFormLabel type="danger" text={fieldsMessageTranslations.endAddress} isStatic />
                      ) : null}
                    </Col>
                  </Row>
                  <Divider />
                </Col>
              )}
              <Col span={24}></Col>
              {(values?.type === PropertyTypesEnum.APARTMENT ||
                values?.type === PropertyTypesEnum.COMMERCIAL ||
                values?.type === PropertyTypesEnum.LAND) && (
                <Col span={12}>
                  <Form.Item
                    noStyle
                    name={PropertyFieldsEnum.INFO}
                    initialValue={initialValues?.info}
                    rules={[{required: false, message: fieldsMessageTranslations.common}]}
                  >
                    <PInput
                      label={t('form.info')}
                      id={PropertyFieldsEnum.INFO}
                      isTextarea
                      rows={5}
                      readOnly={isReadonly}
                    />
                  </Form.Item>
                </Col>
              )}
              {values?.type === PropertyTypesEnum.HOUSE && (
                <Col span={12}>
                  <Form.Item
                    noStyle
                    name={PropertyFieldsEnum.YARD_INFO}
                    initialValue={initialValues?.yard_info}
                    rules={[{required: false, message: fieldsMessageTranslations.common}]}
                  >
                    <PInput
                      label={t('form.info')}
                      id={PropertyFieldsEnum.YARD_INFO}
                      isTextarea
                      rows={5}
                      readOnly={isReadonly}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col span={12}>
                <Form.Item
                  noStyle
                  name={PropertyFieldsEnum.COMMENTS}
                  initialValue={initialValues?.comments}
                  rules={[{required: false, message: fieldsMessageTranslations.common}]}
                >
                  <PInput
                    label={t('form.innerComments')}
                    id={PropertyFieldsEnum.COMMENTS}
                    isTextarea
                    rows={5}
                    readOnly={isReadonly}
                  />
                </Form.Item>
              </Col>
              {values?.status === StatusEnum.ARCHIVED && (
                <>
                  <Col span={24}></Col>
                  <Col span={12}>
                    <Divider />
                    <Form.Item
                      name={PropertyFieldsEnum.ARCHIVE_INFO}
                      initialValue={initialValues?.archive_info}
                      rules={[{required: true, message: fieldsMessageTranslations.common}]}
                    >
                      <PInput
                        label={t('form.archiveInfo')}
                        id={PropertyFieldsEnum.ARCHIVE_INFO}
                        isTextarea
                        rows={5}
                        readOnly={isReadonly}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>

            {values?.rent?.length! > 0 && (
              <>
                <Divider />
                <Row>
                  <Col span={24}>
                    <PFormLabel isStatic text={t('form.prices')} />
                  </Col>
                  <Col span={24}></Col>
                  {values?.rent?.includes(ContractTypesEnum.SALE) && (
                    <Col span={24}>
                      <Row gutter={16} align="middle">
                        <Col span={3}>
                          <PFormLabel text={t('form.sale')} innerClassName="mb-0" isStatic />
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            name={PropertyFieldsEnum.TOTAL_PRICE_SALE}
                            rules={[{required: false, message: fieldsMessageTranslations.common}]}
                          >
                            <PNumericInput
                              onChange={value =>
                                handlePriceChange(
                                  value,
                                  CrossCalculationModesEnum.PRICE_TOTAL,
                                  PropertyFieldsEnum.UNIT_PRICE_SALE
                                )
                              }
                              label={t('form.price')}
                              id={PropertyFieldsEnum.TOTAL_PRICE_SALE}
                              readOnly={isReadonly}
                            />
                          </Form.Item>
                        </Col>
                        {values?.type !== PropertyTypesEnum.HOUSE && (
                          <Col span={5}>
                            <Form.Item
                              name={PropertyFieldsEnum.UNIT_PRICE_SALE}
                              rules={[{required: false, message: fieldsMessageTranslations.common}]}
                            >
                              <PNumericInput
                                onChange={value =>
                                  handlePriceChange(
                                    value,
                                    CrossCalculationModesEnum.PRICE_UNIT,
                                    PropertyFieldsEnum.TOTAL_PRICE_SALE
                                  )
                                }
                                label={`${t('form.pricePerArea')} (${t('shorts.squareMeter')})`}
                                id={PropertyFieldsEnum.UNIT_PRICE_SALE}
                                readOnly={isReadonly}
                              />
                            </Form.Item>
                          </Col>
                        )}

                        <Col span={3}>
                          <Form.Item
                            name={PropertyFieldsEnum.CURRENCY_SALE}
                            rules={[{required: false, message: fieldsMessageTranslations.common}]}
                          >
                            <PSelect
                              label={t('form.currency')}
                              options={currencyOptions}
                              id={PropertyFieldsEnum.CURRENCY_SALE}
                              disabled={isReadonly}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  )}

                  {values?.rent?.includes(ContractTypesEnum.MONTHLY) && (
                    <Col span={24}>
                      <Row gutter={16} align="middle">
                        <Col span={3}>
                          <PFormLabel text={t('form.rent')} innerClassName="mb-0" isStatic />
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            name={PropertyFieldsEnum.TOTAL_PRICE_MONTHLY}
                            rules={[{required: false, message: fieldsMessageTranslations.common}]}
                          >
                            <PNumericInput
                              label={t('form.price')}
                              id={PropertyFieldsEnum.TOTAL_PRICE_MONTHLY}
                              onChange={value =>
                                handlePriceChange(
                                  value,
                                  CrossCalculationModesEnum.PRICE_TOTAL,
                                  PropertyFieldsEnum.UNIT_PRICE_MONTHLY
                                )
                              }
                              readOnly={isReadonly}
                            />
                          </Form.Item>
                        </Col>
                        {values?.type === PropertyTypesEnum.COMMERCIAL && (
                          <Col span={5}>
                            <Form.Item
                              name={PropertyFieldsEnum.UNIT_PRICE_MONTHLY}
                              rules={[{required: false, message: fieldsMessageTranslations.common}]}
                            >
                              <PNumericInput
                                label={`${t('form.pricePerArea')} (${t('shorts.squareMeter')})`}
                                id={PropertyFieldsEnum.UNIT_PRICE_MONTHLY}
                                onChange={value =>
                                  handlePriceChange(
                                    value,
                                    CrossCalculationModesEnum.PRICE_UNIT,
                                    PropertyFieldsEnum.TOTAL_PRICE_MONTHLY
                                  )
                                }
                                readOnly={isReadonly}
                              />
                            </Form.Item>
                          </Col>
                        )}
                        <Col span={3}>
                          <Form.Item
                            name={PropertyFieldsEnum.CURRENCY_MONTHLY}
                            rules={[{required: false, message: fieldsMessageTranslations.common}]}
                          >
                            <PSelect
                              label={t('form.currency')}
                              options={currencyOptions}
                              id={PropertyFieldsEnum.CURRENCY_MONTHLY}
                              disabled={isReadonly}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  )}

                  {values?.rent?.includes(ContractTypesEnum.DAILY) && (
                    <Col span={24}>
                      <Row gutter={16} align="middle">
                        <Col span={3}>
                          <PFormLabel text={t('form.rentDaily')} innerClassName="mb-0" isStatic />
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            name={PropertyFieldsEnum.TOTAL_PRICE_DAILY}
                            rules={[{required: false, message: fieldsMessageTranslations.common}]}
                          >
                            <PNumericInput
                              label={t('form.price')}
                              id={PropertyFieldsEnum.TOTAL_PRICE_DAILY}
                              readOnly={isReadonly}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Form.Item
                            name={PropertyFieldsEnum.CURRENCY_DAILY}
                            rules={[{required: false, message: fieldsMessageTranslations.common}]}
                          >
                            <PSelect
                              label={t('form.currency')}
                              options={currencyOptions}
                              id={PropertyFieldsEnum.CURRENCY_DAILY}
                              disabled={isReadonly}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </>
            )}

            <Divider />
            <Row gutter={16}>
              <Col span={24}>
                <PFormLabel isStatic text={t('form.otherInfo')} />
              </Col>
              {values?.type === PropertyTypesEnum.HOUSE && (
                <>
                  <Col span={6}>
                    <Form.Item name={PropertyFieldsEnum.YARD_MARKERS} initialValue={initialValues.yard_markers}>
                      <PSelect
                        mode="multiple"
                        label={t('form.yardMarkers')}
                        options={catalog[OptGroupsEnum.YARD_MARKERS]}
                        id={PropertyFieldsEnum.YARD_MARKERS}
                        disabled={isReadonly}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
              {values?.type === PropertyTypesEnum.APARTMENT && (
                <>
                  <Col span={6}>
                    <Form.Item name={PropertyFieldsEnum.VIEW} initialValue={initialValues.view}>
                      <PSelect
                        mode="multiple"
                        label={t('form.view')}
                        options={catalog[OptGroupsEnum.VIEW]}
                        id={PropertyFieldsEnum.VIEW}
                        disabled={isReadonly}
                      />
                    </Form.Item>
                  </Col>
                  {(values?.rent?.includes(ContractTypesEnum.MONTHLY) ||
                    values?.rent?.includes(ContractTypesEnum.DAILY)) && (
                    <Col span={6}>
                      <Form.Item name={PropertyFieldsEnum.OPTIONS} initialValue={initialValues.options}>
                        <PSelect
                          mode="multiple"
                          label={t('form.markers')}
                          options={catalog[OptGroupsEnum.APPARTMENT_MARKERS]}
                          id={PropertyFieldsEnum.OPTIONS}
                          disabled={isReadonly}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </>
              )}
              {values?.type !== PropertyTypesEnum.LAND && values?.type !== PropertyTypesEnum.PARKING && (
                <Col span={5}>
                  <Form.Item name={PropertyFieldsEnum.REPAIR}>
                    <PSelect
                      label={t('form.repair')}
                      options={repairOptions}
                      id={PropertyFieldsEnum.REPAIR}
                      disabled={isReadonly}
                    />
                  </Form.Item>
                </Col>
              )}
              {(values?.type === PropertyTypesEnum.APARTMENT || values?.type === PropertyTypesEnum.HOUSE) && (
                <Col span={4}>
                  <Form.Item
                    name={PropertyFieldsEnum.BATHROOMS}
                    initialValue={initialValues?.bathrooms}
                    rules={[{required: false, message: fieldsMessageTranslations.common}]}
                  >
                    <PNumericInput
                      withoutFormatter
                      label={t('form.bathrooms')}
                      id={PropertyFieldsEnum.BATHROOMS}
                      readOnly={isReadonly}
                    />
                  </Form.Item>
                </Col>
              )}

              {values?.type === PropertyTypesEnum.APARTMENT && (
                <>
                  <Col span={4}>
                    <Form.Item
                      name={PropertyFieldsEnum.BALCONIES}
                      initialValue={initialValues?.balconies}
                      rules={[{required: false, message: fieldsMessageTranslations.common}]}
                    >
                      <PNumericInput
                        withoutFormatter
                        label={t('form.balconies')}
                        id={PropertyFieldsEnum.BALCONIES}
                        readOnly={isReadonly}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item name={PropertyFieldsEnum.HEATING}>
                      <PSelect
                        label={t('form.heating')}
                        options={catalog[OptGroupsEnum.HEATING]}
                        id={PropertyFieldsEnum.HEATING}
                        disabled={isReadonly}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}

              {values?.type === PropertyTypesEnum.COMMERCIAL && (
                <Col span={5}>
                  <Form.Item name={PropertyFieldsEnum.ENTRY_TYPE}>
                    <PSelect
                      mode="multiple"
                      label={t('form.entryType')}
                      options={catalog[OptGroupsEnum.ENTRY_TYPE]}
                      id={PropertyFieldsEnum.ENTRY_TYPE}
                      disabled={isReadonly}
                    />
                  </Form.Item>
                </Col>
              )}

              {(values?.type === PropertyTypesEnum.APARTMENT || values?.type === PropertyTypesEnum.COMMERCIAL) && (
                <>
                  <Col span={5}>
                    <Form.Item name={PropertyFieldsEnum.ENTRY_POSITION}>
                      <PSelect
                        mode="multiple"
                        label={t('form.entryPosition')}
                        options={catalog[OptGroupsEnum.ENTRY_POSITION]}
                        id={PropertyFieldsEnum.ENTRY_POSITION}
                        disabled={isReadonly}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}

              {values?.type === PropertyTypesEnum.APARTMENT && (
                <Col span={3}>
                  <Form.Item name={PropertyFieldsEnum.PARKING} initialValue={initialValues.parking}>
                    <PSelect label={t('form.parking')} options={booleanOptions} disabled={isReadonly} />
                  </Form.Item>
                </Col>
              )}

              {values?.type === PropertyTypesEnum.COMMERCIAL && (
                <Col span={4}>
                  <Form.Item name={PropertyFieldsEnum.PARKINGS} initialValue={initialValues.parkings}>
                    <PNumericInput
                      withoutFormatter
                      label={t('form.parkings')}
                      id={PropertyFieldsEnum.PARKINGS}
                      readOnly={isReadonly}
                    />
                  </Form.Item>
                </Col>
              )}

              {values?.type === PropertyTypesEnum.COMMERCIAL && (
                <>
                  <Col span={3}>
                    <Form.Item name={PropertyFieldsEnum.CAFE} initialValue={initialValues.cafe}>
                      <PSelect label={t('form.cafe')} options={booleanOptions} disabled={isReadonly} />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name={PropertyFieldsEnum.DIVISIBLE} initialValue={initialValues.divisible}>
                      <PSelect label={t('form.divisible')} options={booleanOptions} disabled={isReadonly} />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name={PropertyFieldsEnum.IS_BUILDING} initialValue={initialValues.is_building}>
                      <PSelect label={t('form.isBuilding')} options={booleanOptions} disabled={isReadonly} />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item name={PropertyFieldsEnum.GLASS} initialValue={initialValues.glass}>
                      <PNumericInput withoutFormatter label={t('form.glass')} readOnly={isReadonly} />
                    </Form.Item>
                  </Col>
                </>
              )}

              <Col span={24}></Col>
              <Col span={7}>
                <Form.Item
                  noStyle
                  name={PropertyFieldsEnum.YOUTUBE}
                  initialValue={initialValues?.youtube}
                  rules={[{required: false, message: fieldsMessageTranslations.common}]}
                >
                  <PInput label={t('form.video')} id={PropertyFieldsEnum.YOUTUBE} />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  noStyle
                  name={PropertyFieldsEnum.TOUR}
                  initialValue={initialValues['3d']!}
                  rules={[{required: false, message: fieldsMessageTranslations.common}]}
                >
                  <PInput label={t('form.tour')} id={PropertyFieldsEnum.TOUR} />
                </Form.Item>
              </Col>
            </Row>

            {values?.type === PropertyTypesEnum.APARTMENT && (
              <>
                <Divider />
                <Col span={24}>
                  <PFormLabel text={t('form.rooms')} isStatic />
                </Col>
                <Col span={12}>
                  <Space direction="vertical" style={{width: '100%'}} size="large">
                    {attachedRooms?.length! > 0 && (
                      <div className="grid gap-2">
                        {attachedRooms?.map(room => (
                          <Row
                            key={`${uuidv4()}`}
                            className="bg-grey-60 rounded-[18px] border border-solid border-primary p-4"
                            justify="space-between"
                            align="middle"
                          >
                            <Col className="line-clamp-1">{room.name}</Col>
                            <Col>
                              <Row gutter={4} align="middle">
                                <Col className="flex">
                                  {isReadonly ? (
                                    <Tooltip title={t('actions.view')}>
                                      <Icon.Eye
                                        color="#292D32"
                                        width={24}
                                        height={24}
                                        cursor="pointer"
                                        onClick={() => handleAddonPopup(room, ActionModesEnum.VIEW)}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title={t('actions.change')}>
                                      <Icon.Edit
                                        color="#292D32"
                                        width={24}
                                        height={24}
                                        cursor="pointer"
                                        onClick={() => handleAddonPopup(room, ActionModesEnum.UPDATE)}
                                      />
                                    </Tooltip>
                                  )}
                                </Col>
                                {!isReadonly ? (
                                  <Col className="flex">
                                    <Tooltip title={t('actions.delete')}>
                                      <Icon.CloseSquare
                                        color="#292D32"
                                        width={24}
                                        height={24}
                                        cursor="pointer"
                                        onClick={() => {
                                          handleAddonDelete(room.id, 'rooms');
                                        }}
                                      />
                                    </Tooltip>
                                  </Col>
                                ) : null}
                              </Row>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    )}
                    {!isReadonly ? (
                      <Row gutter={16}>
                        <Col>
                          <PButton
                            type="default"
                            icon={<Icon.AddCircle width={24} height={24} fill="currentColor" />}
                            onClick={() => handleAddonPopup()}
                          >
                            {t('actions.add')}
                          </PButton>
                        </Col>
                      </Row>
                    ) : null}
                  </Space>
                </Col>
              </>
            )}
            {(values?.type === PropertyTypesEnum.COMMERCIAL || values?.type === PropertyTypesEnum.HOUSE) && (
              <>
                <Divider />
                <Col span={24}>
                  <PFormLabel text={t('form.floors')} isStatic />
                </Col>
                <Col span={12}>
                  <Space direction="vertical" style={{width: '100%'}} size="large">
                    {attachedFloors?.length! > 0 && (
                      <div className="grid gap-2">
                        {attachedFloors?.map(floor => (
                          <Row
                            key={`${uuidv4()}`}
                            className="bg-grey-60 rounded-[18px] border border-solid border-primary p-4"
                            justify="space-between"
                            align="middle"
                          >
                            <Col className="line-clamp-1">{floor.name}</Col>
                            <Col>
                              <Row gutter={4} align="middle">
                                <Col className="flex">
                                  {isReadonly ? (
                                    <Tooltip title={t('actions.view')}>
                                      <Icon.Eye
                                        color="#292D32"
                                        width={24}
                                        height={24}
                                        cursor="pointer"
                                        onClick={() => handleAddonPopup(floor, ActionModesEnum.VIEW)}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title={t('actions.change')}>
                                      <Icon.Edit
                                        color="#292D32"
                                        width={24}
                                        height={24}
                                        cursor="pointer"
                                        onClick={() => handleAddonPopup(floor, ActionModesEnum.UPDATE)}
                                      />
                                    </Tooltip>
                                  )}
                                </Col>
                                {!isReadonly ? (
                                  <Col className="flex">
                                    <Tooltip title={t('actions.delete')}>
                                      <Icon.CloseSquare
                                        color="#292D32"
                                        width={24}
                                        height={24}
                                        cursor="pointer"
                                        onClick={() => {
                                          handleAddonDelete(
                                            floor.id,
                                            values?.type === PropertyTypesEnum.COMMERCIAL
                                              ? AddonsEnum.COMMERCIAL
                                              : AddonsEnum.FLOORS
                                          );
                                        }}
                                      />
                                    </Tooltip>
                                  </Col>
                                ) : null}
                              </Row>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    )}
                    {!isReadonly ? (
                      <Row gutter={16}>
                        <Col>
                          <PButton
                            type="default"
                            icon={<Icon.AddCircle width={24} height={24} fill="currentColor" />}
                            onClick={() => handleAddonPopup()}
                          >
                            {t('actions.add')}
                          </PButton>
                        </Col>
                      </Row>
                    ) : null}
                  </Space>
                </Col>
              </>
            )}

            <Divider />
            <Row gutter={16} className="mb-9">
              <Col span={24}>
                <PFormLabel text={t('form.mainGallery')} isStatic />
              </Col>
              <Col span={24}>
                <Form.Item noStyle valuePropName="fileList" getValueFromEvent={normFile}>
                  <CImageUploader
                    initialFiles={currentImage}
                    isImagesAttached={isImagesAttached}
                    onUploadClick={onUploadClick}
                    accept={`${fileAccept.JPEG}, ${fileAccept.PNG}, ${fileAccept.WEBM}, ${fileAccept.GIF}`}
                    isDraggable
                    multiple
                    isReadonly={isReadonly}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider />
            <Row gutter={16}>
              <Col span={24}>
                <PFormLabel text={t('form.otherFiles')} isStatic />
              </Col>
              <Col span={18}>
                <Form.Item noStyle valuePropName="fileList" getValueFromEvent={normFile}>
                  <CFileUploader
                    initialFiles={currentFile}
                    isFilesAttached={isFilesAttached}
                    onUploadClick={onUploadClick}
                    accept={`${fileAccept.PDF}, ${fileAccept.DOC}, ${fileAccept.PPT}, ${fileAccept.EXCEL}`}
                    multiple
                    isReadonly={isReadonly}
                  >
                    <PButton type="default" icon={<Icon.File width={24} height={24} stroke="currentColor" />}>
                      {t('actions.add')}
                    </PButton>
                  </CFileUploader>
                </Form.Item>
              </Col>
            </Row>

            <Divider />
            <Col span={24}>
              <PFormLabel text={t('form.persons')} isStatic />
            </Col>
            <Col span={16}>
              <Space direction="vertical" style={{width: '100%'}} size="large">
                {attachedPersons?.length! > 0 && (
                  <PTable
                    columns={personColumns}
                    dataSource={attachedPersons}
                    paginationOptions={{pageSize: 10}}
                    rowKey="id"
                    showHeader={false}
                  />
                )}
                {!isReadonly ? (
                  <Row gutter={16}>
                    <Col>
                      <PButton
                        type="default"
                        icon={<Icon.AddCircle width={24} height={24} fill="currentColor" />}
                        onClick={handlePersonAttachPopup}
                      >
                        {t('actions.add')}
                      </PButton>
                    </Col>
                  </Row>
                ) : null}
              </Space>
            </Col>

            {mode !== ActionModesEnum.CREATE && (
              <>
                <Divider />
                <Col span={24}>
                  <PFormLabel text={t('form.updatePrice')} isStatic />
                </Col>
                <Col span={16}>
                  <Space direction="vertical" style={{width: '100%'}} size="large">
                    {initialValues?.prices?.length! > 0 && (
                      <PTable
                        columns={pricesColumns({translation: t})}
                        dataSource={initialValues?.prices}
                        paginationOptions={{pageSize: 2}}
                        rowKey="id"
                        showHeader={true}
                      />
                    )}
                    {!isReadonly ? (
                      <Row gutter={16}>
                        <Col>
                          <PButton
                            type={mode === ActionModesEnum.CREATE ? 'primary' : 'default'}
                            icon={<Icon.AddCircle width={24} height={24} fill="currentColor" />}
                            onClick={handlePricesPopup}
                          >
                            {t('actions.add')}
                          </PButton>
                        </Col>
                      </Row>
                    ) : null}
                  </Space>
                </Col>
              </>
            )}

            {(values?.rent?.includes(ContractTypesEnum.MONTHLY) || values?.rent?.includes(ContractTypesEnum.DAILY)) && (
              <>
                <Divider />
                <Col span={24}>
                  <PFormLabel text={t('form.available')} isStatic />
                </Col>
                <Col span={16}>
                  <Space direction="vertical" style={{width: '100%'}} size="large">
                    {initialValues?.holds?.length! > 0 && (
                      <PTable
                        columns={bookingsColumns({
                          propertyId: initialValues.id!,
                          hideActions: isReadonly,
                          translation: t,
                        })}
                        dataSource={initialValues?.holds}
                        paginationOptions={{pageSize: 5}}
                        rowKey="id"
                        showHeader={true}
                      />
                    )}
                    {isReadonly && !initialValues?.holds?.length && <p>{t('info.noInfo')}</p>}
                    {!isReadonly ? (
                      <Row gutter={16}>
                        <Col>
                          <PButton
                            type={mode === ActionModesEnum.CREATE ? 'primary' : 'default'}
                            icon={<Icon.AddCircle width={24} height={24} fill="currentColor" />}
                            onClick={handleBookingPopup}
                          >
                            {t('actions.add')}
                          </PButton>
                        </Col>
                      </Row>
                    ) : null}
                  </Space>
                </Col>
              </>
            )}

            {initialValues?.disabled_to && (
              <>
                <Divider />
                <Col span={16}>
                  <PFrozenProperty values={initialValues} />
                </Col>
              </>
            )}

            {(profile?.type === RolesEnum.SUPER || profile?.type === RolesEnum.MARKETING) && (
              <>
                <Divider />

                <PFormLabel text={t('common.websiteInfo')} isStatic className="mb-[15px]" />
                <Row gutter={20}>
                  {websiteFields({translation: t}).map(({icon, lang, fields}, i) => (
                    <Col span={8} key={i}>
                      <div className=" flex items-center gap-2 text-sm py-1 bg-white sticky top-0 left-0 z-[20]">
                        {icon} <b>{lang}</b>
                      </div>
                      <Divider />
                      {fields.map(({name, label, type}) => (
                        <Form.Item
                          key={name}
                          name={name}
                          initialValue={initialValues[name as keyof typeof initialValues]}
                          rules={[{required: false, message: fieldsMessageTranslations.common}]}
                        >
                          <PInput
                            label={<span className="flex gap-1 items-center">{label}</span>}
                            id={name}
                            isTextarea={type === 'textarea' ? true : false}
                            rows={type === 'textarea' ? 5 : undefined}
                          />
                        </Form.Item>
                      ))}
                    </Col>
                  ))}
                </Row>
                <Form.Item
                  className="mb-0"
                  name={PropertyFieldsEnum.WEBSITE}
                  valuePropName="checked"
                  initialValue={initialValues.website}
                  rules={[{required: false, message: fieldsMessageTranslations.common}]}
                >
                  <Checkbox>{t('common.showInWebsite')}</Checkbox>
                </Form.Item>
              </>
            )}
          </Col>

          <Col span={7}>
            <PFormLabel isStatic text={t('form.contractType')} />
            <Row>
              <Col span={18}>
                <Form.Item
                  className="mb-0"
                  name={PropertyFieldsEnum.RENT}
                  initialValue={initialValues.rent}
                  rules={[{required: true, message: fieldsMessageTranslations.common}]}
                >
                  <PSelect
                    mode="multiple"
                    options={contractOptions}
                    placeholder={t('form.choose')}
                    id={PropertyFieldsEnum.RENT}
                    disabled={isReadonly}
                  />
                </Form.Item>
              </Col>

              <Col span={18}>
                <Divider />
                <Form.Item
                  className="mb-0"
                  name={PropertyFieldsEnum.MARKETING}
                  valuePropName="checked"
                  initialValue={initialValues.marketing}
                  rules={[{required: false, message: fieldsMessageTranslations.common}]}
                >
                  <Checkbox disabled={isReadonly}>{t('common.availableToMarketing')}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="bg-white/[0.4] sticky bottom-0 pb-[1.5%] z-20 backdrop-blur-sm">
          <Divider className="mb-[1.5%]" />
          <Row gutter={16} justify={mode === ActionModesEnum.UPDATE && !isReadonly ? 'space-between' : 'end'}>
            {mode === ActionModesEnum.UPDATE && !isReadonly && (
              <Col>
                <Form.Item noStyle>
                  <PButton
                    htmlType="button"
                    type="text"
                    theme="default"
                    icon={<Icon.Archive fill="currentColor" width={24} height={24} cursor="pointer" />}
                    onClick={() => onPropcessChange(initialValues.id!)}
                  >
                    {t('actions.changeBusinessProcess')}
                  </PButton>
                </Form.Item>
              </Col>
            )}

            <Col>
              <Row gutter={16}>
                <Col>
                  <Form.Item noStyle>
                    <PButton htmlType="submit" type="primary" loading={isButtonLoading}>
                      {mode === ActionModesEnum.CREATE ? t('actions.create') : t('actions.save')}
                    </PButton>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Form>
    </PCardWrapper>
  );
};
