import {ColumnsType} from 'antd/es/table';
import {Link} from 'react-router-dom';

import {IPersonFields} from 'components/forms/person/form-person.interfaces';
import {PersonFieldsEnum} from 'components/forms/person/form-person.enums';
import {CTextCopy} from 'components/containers/c-text-copy';
import {RoutesEnum} from 'router/routes.enum';
import {ActionModesEnum, PageNamesEnum} from 'enums';
import {IPersonTableColumnProps} from 'types/common';

interface ITableColumns extends IPersonFields {}

export const columns = ({onUpdate, translation}: IPersonTableColumnProps): ColumnsType<ITableColumns> => [
  {
    width: '35%',
    title: translation('form.firstName'),
    dataIndex: PersonFieldsEnum.NAME,
    key: PersonFieldsEnum.NAME,
    render: (_, record) => (
      <Link
        onClick={onUpdate}
        className="text-inherit"
        to={`${RoutesEnum.PERSONS}/${ActionModesEnum.UPDATE}/${record.id}${`?pageFrom=${PageNamesEnum.PROPERTIES}`}`}
      >
        {record.name}
      </Link>
    ),
  },
  {
    width: '25%',
    title: translation('form.phone'),
    key: PersonFieldsEnum.PHONES,
    dataIndex: PersonFieldsEnum.PHONES,
    render: (_, record) => record.phones?.map(p => <CTextCopy key={`${record.id}-${p?.number}`} text={p?.number} />),
  },
  {
    title: translation('form.relationWithProperty'),
    dataIndex: PersonFieldsEnum.RELATION,
    key: PersonFieldsEnum.RELATION,
  },
];
