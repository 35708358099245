import {CSS} from '@dnd-kit/utilities';
import {useSortable} from '@dnd-kit/sortable';
import {Dispatch, memo, ReactNode, SetStateAction, useCallback, useEffect, useState} from 'react';
import {UploadFile} from 'antd/es/upload';
import {Tooltip} from 'antd';
import {useTranslation} from 'react-i18next';

import {Icon} from 'assets/icons';
import {palette} from 'constants/theme';
import {ImageViewEnum} from 'enums';
import {IMAGE_SIZE} from 'constants/sizes';
import {IUploadedFile} from 'types/common';
import {CImageCropper} from 'components/containers/c-image-cropper';

interface ISelectedFile extends UploadFile {
  removal?: number;
  id?: number;
  view?: string;
}

interface IDraggableUploadListItemProps {
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  file: ISelectedFile;
  isDraggable: boolean;
  children?: ReactNode;
  isReadonly?: boolean;
  onViewChange: ({file, view}: {file: any; view: string[]}) => void;
  setUploadedFiles: Dispatch<SetStateAction<IUploadedFile[]>>;
  setIsShowUploadButton: Dispatch<SetStateAction<boolean>>;
  setRemovals: Dispatch<SetStateAction<number[]>>;
}

const DraggableUploadListItem = ({
  originNode,
  file,
  isDraggable,
  isReadonly,
  onViewChange,
  setUploadedFiles,
  setIsShowUploadButton,
  setRemovals,
}: IDraggableUploadListItemProps) => {
  const {t} = useTranslation();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isGlobal, setIsGlobal] = useState(
    file?.view === ImageViewEnum.ALL || file?.view === ImageViewEnum.WEBSITE || false
  );
  const [isPresentational, setIsPresentational] = useState(
    file?.view === ImageViewEnum.ALL || file?.view === ImageViewEnum.PRESENTATION || false
  );
  const [fileViewModes, setFileViewModes] = useState<string[]>([]);
  const [isClicked, setIsClicked] = useState(false);

  const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({
    id: file.uid,
    disabled: !isDraggable || isReadonly,
  });
  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: isDraggable ? 'move' : 'default',
  };

  const handleEditPopup = useCallback(() => {
    setIsEditModalOpen(true);
  }, []);

  const handleView = useCallback(
    (event: any) => {
      event.stopPropagation();

      const value = event.target.id;

      value === ImageViewEnum.WEBSITE && setIsGlobal(!isGlobal);
      value === ImageViewEnum.PRESENTATION && setIsPresentational(!isPresentational);

      setFileViewModes(prev =>
        prev.includes(value)
          ? prev.filter(val => val !== value)
          : [...prev.filter(val => val !== ImageViewEnum.NONE), value]
      );

      setIsClicked(true);
    },
    [isGlobal, isPresentational]
  );

  useEffect(() => {
    file?.view &&
      setFileViewModes(
        file?.view === ImageViewEnum.ALL
          ? [ImageViewEnum.PRESENTATION, ImageViewEnum.WEBSITE]
          : file?.view === ImageViewEnum.NONE
          ? [ImageViewEnum.NONE]
          : [file?.view]
      );
  }, [file, setFileViewModes]);

  useEffect(() => {
    if (isClicked) {
      onViewChange({file, view: fileViewModes});

      setIsClicked(false);
    }
  }, [fileViewModes, file, isClicked, onViewChange]);

  return (
    <div>
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className={`relative border ${
          file.size! > IMAGE_SIZE ? 'border-danger opacity-[.8] hover:opacity-[1] !cursor-default' : 'border-grey-60'
        } border-solid rounded-[16px] shadow-sm ${isDragging ? 'is-dragging' : ''}`}
      >
        {file.size! > IMAGE_SIZE ? null : !isReadonly ? (
          <Tooltip title="Փոփոխել" placement="top">
            <div
              className="absolute top-[-5px] right-[-5px] cursor-pointer z-[2] bg-primary w-[24px] h-[24px] rounded-full flex items-center justify-center"
              onClick={handleEditPopup}
            >
              <Icon.Pen width={14} fill="white" />
            </div>
          </Tooltip>
        ) : null}

        {file.status === 'error' && isDragging ? originNode.props.children : originNode}

        {!isReadonly && (
          <div
            className={`align-middle gap-1 justify-center py-[5px] flex ${
              file.size! > IMAGE_SIZE ? 'pointer-events-none opacity-[.4]' : ''
            }`}
          >
            <Tooltip title={t('common.presentation')} placement="bottom">
              <div className="cursor-pointer inline-flex" id={ImageViewEnum.PRESENTATION} onClick={handleView}>
                <Icon.File
                  width={20}
                  height={20}
                  cursor="pointer"
                  className="pointer-events-none"
                  color={isPresentational ? palette.primary : ''}
                />
              </div>
            </Tooltip>
            <Tooltip title={t('common.website')} placement="bottom">
              <div className="cursor-pointer inline-flex" id={ImageViewEnum.WEBSITE} onClick={handleView}>
                <Icon.Global
                  width={20}
                  height={20}
                  cursor="pointer"
                  className="pointer-events-none"
                  fill={isGlobal ? palette.primary : undefined}
                />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
      <CImageCropper
        originalFile={file}
        imageUrl={file.url}
        imageFile={file.originFileObj}
        isOpen={isEditModalOpen}
        setUploadedFiles={setUploadedFiles}
        setIsShowUploadButton={setIsShowUploadButton}
        setRemovals={setRemovals}
        onClose={() => setIsEditModalOpen(false)}
      />
    </div>
  );
};

export const DraggableUploadListItemMemoized = memo(DraggableUploadListItem);
