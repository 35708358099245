import {memo, useEffect, useMemo, useState} from 'react';
import {Tooltip} from 'antd';

import {ICountBadge} from './c-count-badge.types';

const CCountBadge = ({
  type = 'warning',
  title,
  tooltip,
  count,
  service,
  payload,
  propertyType,
  statusType,
}: ICountBadge) => {
  const [counted, setCounted] = useState<number>();
  const theme = useMemo(
    () =>
      type === 'success'
        ? 'bg-success'
        : type === 'danger'
        ? 'bg-danger'
        : type === 'warning'
        ? 'bg-primary'
        : type === 'info'
        ? 'bg-info'
        : type === 'default'
        ? 'bg-grey-80'
        : 'bg-grey-100',
    [type]
  );

  useEffect(() => {
    if (payload && !count) {
      (async () => {
        try {
          const {data}: {data: number} = await service({
            ...payload,
            cnt: '',
          });

          setCounted(data);
        } catch (e) {
          console.error(e);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyType, statusType]);

  return (
    <div className="inline-flex items-center gap-2">
      {title}
      {count || counted ? (
        <Tooltip title={tooltip} placement="right">
          <div
            className={`min-w-[18px] h-[18px] leading-[18px] text-white text-[13px] text-center rounded-full px-[5px] ${theme}`}
          >
            {count || counted}
          </div>
        </Tooltip>
      ) : null}
    </div>
  );
};

export const CCountBadgeMemoized = memo(CCountBadge);
