import {FormatsEnum, SeparatorEnum} from 'enums';

export const formatTime = ({
  value,
  format = FormatsEnum.NORMAL,
  separator = SeparatorEnum.COLON,
}: {
  value: string | Date;
  format?: FormatsEnum;
  separator?: SeparatorEnum;
}): string => {
  let hours = (value as Date).getHours();
  let minutes = (value as Date).getMinutes();
  let seconds = (value as Date).getSeconds();

  return `${hours > 9 ? hours : `0${hours}`}${separator}${minutes > 9 ? minutes : `0${minutes}`}${separator}${
    seconds > 9 ? seconds : `0${seconds}`
  }`;
};
