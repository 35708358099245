export enum PropertyFilterFieldsEnum {
  CODE = 'code',
  RENT = 'rent',
  REPAIR = 'repair',
  INFO = 'info',
  MODE = 'mode',
  STATUS = 'status',
  CADASTRE_NUMBER = 'cadastreNumber',
  USAGE = 'usage',
  BUILDING_RESIDENTIAL_HEATING = 'buildingResidentialHeating',
  BUILDING_RESIDENTIAL_SUBTYPE = 'buildingResidentialSubtype',
  STONE = 'stone',
  HEATING = 'heating',
  BUILDING_TYPE = 'buildingType',
  ADDRESS_NUMBER = 'addressNumber',
  PHONE = 'phone',
  NAME = 'name',
  MODIFIED = 'modified',
  MIN_MODIFIED = 'minModified',
  MAX_MODIFIED = 'maxModified',
  TITLE = 'title',
  STREET = 'street[]',
  REGION = 'region[]',
  REGION_NAMES = 'regionNames',
  SETTLEMENT = 'settlement[]',
  DISTRICT = 'district[]',
  NUMBER = 'number',
  ADDRESS = 'address',
  BUILDING_NUMBER = 'building_numbers',
  APARTMENT_NUMBER = 'apartmentNumber',

  MIN_AREA = 'minArea',
  MAX_AREA = 'maxArea',
  MIN_LIVING_AREA = 'minLivingArea',
  MAX_LIVING_AREA = 'maxLivingArea',
  MIN_LAND_AREA = 'minLandArea',
  MAX_LAND_AREA = 'maxLandArea',
  MIN_HECTARE = 'minHectare',
  MAX_HECTARE = 'maxHectare',
  MIN_SQUARE_METRE = 'minSquareMetre',
  MAX_SQUARE_METRE = 'maxSquareMetre',
  MIN_BEDROOMS = 'minBedrooms',
  MAX_BEDROOMS = 'maxBedrooms',
  MIN_BATHROOMS = 'minBathrooms',
  MAX_BATHROOMS = 'maxBathrooms',
  MIN_FLOOR = 'minFloor',
  MAX_FLOOR = 'maxFloor',
  MIN_FLOOR_PLAN = 'minFloorPlan',
  MAX_FLOOR_PLAN = 'maxFloorPlan',
  MIN_ENTRANCE_FLOOR = 'minEntranceFloor',
  MAX_ENTRANCE_FLOOR = 'maxEntranceFloor',
  MIN_FRONT_LENGTH = 'minFrontLength',
  MAX_FRONT_LENGTH = 'maxFrontLength',
  MIN_TOTAL_PRICE = 'minTotalPrice',
  MAX_TOTAL_PRICE = 'maxTotalPrice',
  MIN_UNIT_PRICE = 'minUnitPrice',
  MAX_UNIT_PRICE = 'maxUnitPrice',

  CURRENCY = 'currency',
  COMMERCIAL_NUMBER = 'commercialNumber',
  DIVISIBLE = 'divisible',
  IS_BUILDING = 'isBuilding',
  PARKING = 'parking',
  CAFE = 'cafe',
  MIN_GLASS = 'minGlass',
  MAX_GLASS = 'maxGlass',
  MIN_COMMERCIAL_AREA = 'minCommercialArea',
  MAX_COMMERCIAL_AREA = 'maxCommercialArea',
  MIN_TOTAL_AREA = 'minTotalArea',
  MAX_TOTAL_AREA = 'maxTotalArea',
  ENTRY_POSITION = 'entryPosition',
  ENTRY_TYPE = 'entryType',
  VIEW = 'view',
  MARKERS = 'markers',
  MAIN_ENTRY = 'mainEntry',
  OPTIONS = 'options',
  YARD_MARKERS = 'yardMarkers',

  MIN_TOTAL_PRICE_SALE = 'minTotalPriceSale',
  MAX_TOTAL_PRICE_SALE = 'maxTotalPriceSale',
  MIN_UNIT_PRICE_SALE = 'minUnitPriceSale',
  MAX_UNIT_PRICE_SALE = 'maxUnitPriceSale',

  MIN_TOTAL_PRICE_MONTHLY = 'minTotalPriceMonthly',
  MAX_TOTAL_PRICE_MONTHLY = 'maxTotalPriceMonthly',
  MIN_UNIT_PRICE_MONTHLY = 'minUnitPriceMonthly',
  MAX_UNIT_PRICE_MONTHLY = 'maxUnitPriceMonthly',

  MIN_TOTAL_PRICE_DAILY = 'minTotalPriceDaily',
  MAX_TOTAL_PRICE_DAILY = 'maxTotalPriceDaily',
  MIN_UNIT_PRICE_DAILY = 'minUnitPriceDaily',
  MAX_UNIT_PRICE_DAILY = 'maxUnitPriceDaily',

  CURRENCY_SALE = 'currency_sale',
  CURRENCY_MONTHLY = 'currency_monthly',
  CURRENCY_DAILY = 'currency_daily',

  AVAILABLE = 'available',
  AVAILABLE_FROM = 'availableFrom',
  AVAILABLE_TO = 'availableTo',

  ON_HOLD_FROM = 'onHoldFrom',
  ON_HOLD_TO = 'onHoldTo',
}
