import {memo, useCallback, useEffect, useState} from 'react';
import {Form} from 'antd';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {PopupBase} from 'components/popups/base';
import {Icon} from 'assets/icons';
import {FormUpdatePrice} from 'components/forms/update-price';
import {IPricesFields} from 'components/forms/update-price/form-update-price.interfaces';
import {updatePropertyPriceAction} from 'store/properties/actions';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {useToast} from 'hooks';

const PopupUpdatePrice = ({porpertyId, onClose}: {porpertyId?: number; onClose: () => void}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {contextHolder, showError} = useToast();
  const [form] = Form.useForm<IPricesFields>();
  const [isSubmittable, setIsSubmittable] = useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const values = Form.useWatch([], form);

  const handleSubmit = useCallback(
    (values: IPricesFields) => {
      setIsButtonLoading(true);

      const data = {
        property: porpertyId,
        ...values,
      };

      dispatch(
        updatePropertyPriceAction(data, {
          onFulfilled: () => {
            setIsButtonLoading(false);
            onClose();
          },
          onReject: () => {
            setIsButtonLoading(false);
            showError({message: fieldsMessageTranslations.commonErrors});
          },
        })
      );
    },
    [porpertyId, showError, dispatch, onClose]
  );

  const onAdd = useCallback(() => {
    handleSubmit(values);
  }, [values, handleSubmit]);

  useEffect(() => {
    form.validateFields({validateOnly: true}).then(
      () => {
        setIsSubmittable(true);
      },
      () => {
        setIsSubmittable(false);
      }
    );
  }, [values, form]);

  return (
    <PopupBase
      title={t('actions.changePrice')}
      onCrossClick={onClose}
      onSubmit={handleSubmit}
      open
      size="lg"
      buttons={{
        position: 'right',
        reject: {
          name: t('actions.cancel'),
          type: 'text',
          theme: 'default',
          onClick: onClose,
        },
        confirm: {
          icon: <Icon.AddCircle fill="white" />,
          name: t('actions.add'),
          type: 'primary',
          htmlType: 'button',
          disabled: !isSubmittable,
          loading: isButtonLoading,
          onClick: onAdd,
        },
      }}
    >
      <FormUpdatePrice form={form} values={values} onSumbit={handleSubmit} />
      {contextHolder}
    </PopupBase>
  );
};

export const PopupUpdatePriceMemoized = memo(PopupUpdatePrice);
