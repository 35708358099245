import {Col, Form, Row} from 'antd';
import {useTranslation} from 'react-i18next';

import {PInput} from 'components/primitives/p-input';
import {PSelect} from 'components/primitives/p-select';
import {personRelationOptions} from 'constants/select-options';
import {PersonTypesTranslationEnum} from 'enums/translations';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {Icon} from 'assets/icons';
import {PPhoneInput} from 'components/primitives/p-phone-input';
import {FormsEnum} from 'enums';

import {PersonFieldsEnum} from './form-person.enums';
import {PersonFormProps} from './form-person.types';

export const FormPersonRelation = ({showRelation, form, values, initialValues, onSumbit}: PersonFormProps) => {
  const {t} = useTranslation();

  return (
    <Form form={form} onFinish={onSumbit} name={FormsEnum.PERSON_RELATION}>
      <Row gutter={20}>
        <Col span={8}>
          <Form.Item name={PersonFieldsEnum.NAME} rules={[{required: true, message: fieldsMessageTranslations.common}]}>
            <PInput label={t('form.firstName')} readOnly id="firstname-rel" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={PersonFieldsEnum.EMAIL}
            rules={[
              {
                type: 'email',
                message: fieldsMessageTranslations.validEmail,
              },
              {required: false, message: fieldsMessageTranslations.common},
            ]}
          >
            <PInput label={t('form.email')} readOnly id="email-rel" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={PersonFieldsEnum.PHONE}
            rules={[{required: true, message: fieldsMessageTranslations.common}]}
          >
            <PPhoneInput label={t('form.phone')} readOnly id="phone-rel" />
          </Form.Item>
        </Col>
        <Col span={8} className="hidden">
          <Form.List name={PersonFieldsEnum.PHONES} initialValue={initialValues?.phones}>
            {(fields, {add, remove}) => {
              return (
                <>
                  <Row gutter={20} align="top" justify="end">
                    {fields.map(({key, name, ...restField}, index) => (
                      <Col span={21} key={key}>
                        <Form.Item
                          {...restField}
                          name={[name, 'number']}
                          rules={[
                            {
                              required: true,
                              message: fieldsMessageTranslations.fillOrRemove,
                            },
                            {min: 9, message: fieldsMessageTranslations.phoneMin},
                            {max: 14, message: fieldsMessageTranslations.phoneMax},
                          ]}
                        >
                          <PPhoneInput
                            label={t('form.phone')}
                            id={`person-phone-${index}`}
                            suffix={
                              fields.length > 1 ? (
                                <Icon.CloseCircle cursor="pointer" onClick={() => remove(name)} />
                              ) : (
                                <span />
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    ))}
                  </Row>
                </>
              );
            }}
          </Form.List>
        </Col>
        {showRelation && (
          <>
            <Col span={8}>
              <Form.Item
                name={PersonFieldsEnum.TYPE}
                rules={[{required: true, message: fieldsMessageTranslations.common}]}
              >
                <PSelect label={t('form.noteRelation')} options={personRelationOptions} />
              </Form.Item>
            </Col>
            {values?.type === PersonTypesTranslationEnum.OTHER && (
              <Col span={8}>
                <Form.Item
                  name={PersonFieldsEnum.RELATION}
                  rules={[{required: true, message: fieldsMessageTranslations.common}]}
                  initialValue={initialValues?.relation}
                >
                  <PInput label={`${t('form.noteRelation')} (${t('form.other')})`} id="person-type-other" />
                </Form.Item>
              </Col>
            )}
          </>
        )}
      </Row>
    </Form>
  );
};
