import {TServerResponse} from 'types/common';
import {Fetcher} from 'utils';
import {ApiUrl} from 'services/constants';
import {TPerson} from 'types/api';
import {LISTING_LENGTH, LISTING_OFFSET} from 'constants/pagination';

const fetcher = new Fetcher({baseURL: `${process.env.REACT_APP_API_URL}`});

export const searchPersonsService = ({
  offset = LISTING_OFFSET,
  length = LISTING_LENGTH,
  ...params
}: {
  offset?: number;
  length: number;
}): Promise<TServerResponse<TPerson[]>> =>
  fetcher.request({
    url: ApiUrl.searchPersons(offset, length),
    params,
  });

export const addPersonByBrokerService = (data: any): Promise<TServerResponse<TPerson>> =>
  fetcher.request({
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    url: ApiUrl.addPersonByBroker,
    data,
  });

export const updatePersonByBrokerService = (data: any): Promise<TServerResponse<TPerson>> =>
  fetcher.request({
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    url: ApiUrl.updatePersonByBroker,
    data,
  });

export const addPersonByInfoService = (data: any): Promise<TServerResponse<TPerson>> =>
  fetcher.request({
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    url: ApiUrl.addPersonByInfo,
    data,
  });

export const getPersonByIdService = ({id, ...params}: any): Promise<TServerResponse<TPerson>> =>
  fetcher.request({
    url: ApiUrl.getPersonById(id),
    params,
  });

export const updatePersonByInfoService = (data: any): Promise<TServerResponse<TPerson>> =>
  fetcher.request({
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    url: ApiUrl.updatePersonByInfo,
    data,
  });
