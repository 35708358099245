export enum PropertyTypesTranslationEnum {
  APARTMENT = 'Բնակարան',
  COMMERCIAL = 'Կոմերցիոն',
  HOUSE = 'Առանձնատուն',
  LAND = 'Հողատարածք',
  PARKING = 'Կայանատեղի',
  RESIDENTIAL = 'Բնակելի',
  COMMERCIAL_BUILDING = 'Կոմերցիոն շենք',
  OTHER = 'Այլ',
}
