import {ActionCreatorWithPayload} from '@reduxjs/toolkit';
import {call, put} from 'redux-saga/effects';

import {TActionSettings, TServerResponse, TServerResponseError} from 'types/common';

interface ISagaFn<T> {
  actions: {
    loading: ActionCreatorWithPayload<boolean, string>;
    error: ActionCreatorWithPayload<TServerResponseError | null, string>;
    success: ActionCreatorWithPayload<any | null, string>;
  };
  service: ({payload, ...params}: any) => Promise<TServerResponse<T>>;
  payload: any;
  settings: TActionSettings;
}

export const getByIdFn = function* <T>({
  actions: {loading, error, success},
  service,
  payload: {...payload},
  settings: {isWithoutLoading = false, onFulfilled, onReject},
}: ISagaFn<T>) {
  yield !isWithoutLoading && put(loading(true));

  try {
    const {data}: {data: {success: T; error: TServerResponseError}} = yield call(service, payload);

    if (data.error) {
      throw data.error;
    } else {
      yield put(error(null));
      yield put(success(data.success));

      onFulfilled && onFulfilled();
    }
  } catch (e) {
    console.error(e);
    yield put(error(e as TServerResponseError));
    onReject && onReject(e);
  } finally {
    yield !isWithoutLoading && put(loading(false));
  }
};

export const getOptionsFn = function* <T>({
  actions: {loading, error, success},
  service,
  payload: {...payload},
  settings: {isWithoutLoading = false, onFulfilled, onReject},
}: ISagaFn<T>) {
  yield !isWithoutLoading && put(loading(true));

  try {
    const {data, errors}: {data: T; errors: TServerResponseError} = yield call(service, payload);

    if (errors) {
      throw errors;
    } else {
      yield put(error(null));

      const options = (data as Array<any>).map(({id, name}) => ({label: name, value: id}));

      yield put(success(options));

      onFulfilled && onFulfilled();
    }
  } catch (e) {
    console.error(e);
    yield put(error(e as TServerResponseError));
    onReject && onReject(e);
  } finally {
    yield !isWithoutLoading && put(loading(false));
  }
};

export const postHelperFn = function* <T>({
  actions: {loading, error, success},
  service,
  payload: {...payload},
  settings: {isWithoutLoading = true, onFulfilled, onReject},
}: ISagaFn<T>) {
  yield !isWithoutLoading && put(loading(true));

  try {
    const {data}: {data: {success: T; error: TServerResponseError}} = yield call(service, payload);

    yield put(success(data));
    if (data.error) {
      throw data.error;
    } else {
      yield put(error(null));
      onFulfilled && onFulfilled();
    }
  } catch (e) {
    console.error(e);
    yield put(error(e as TServerResponseError));
    onReject && onReject(e);
  } finally {
    yield !isWithoutLoading && put(loading(false));
  }
};
