import {Col, Form, Row, Space} from 'antd';
import {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {PButton} from 'components/primitives/p-button';
import {PInput} from 'components/primitives/p-input';
import {PRangepicker} from 'components/primitives/p-rangepicker';
import {dateDefaultFormat} from 'constants/date-formats';
import {
  BuildingSubTypesEnum,
  BuildingTypesEnum,
  OptGroupsEnum,
  PropertyTypesEnum,
  ContractTypesEnum,
  CustomerTypesEnum,
  FormsEnum,
  LocationPropsEnum,
} from 'enums';
import {PNumericInput} from 'components/primitives/p-numeric-input';
import {PSelect} from 'components/primitives/p-select';
import {
  appCatalogSelector,
  appDistrictsSelector,
  appRegionsSelector,
  appSettlementsSelector,
  appStreetsSelector,
} from 'store/app/selectors';
import {booleanOptions, heatingOptions, residentialTypeOptions} from 'constants/select-options';
import {PPhoneInput} from 'components/primitives/p-phone-input';
import {getDistrictsAction, getRegionsAction, getSettlementsAction, getStreetsAction} from 'store/app/actions';
import {CSelectAsync} from 'components/containers/c-select-async';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {IFilterProps} from 'types/common';
import {CSubmitButton} from 'components/containers/c-submit-button';
import {Icon} from 'assets/icons';
import {useMountedOnce} from 'hooks';
import {FormPricesFilter} from 'components/forms/prices-filter';
// import {CapitalNaming} from 'constants/opt-groups';
import {injectLocationFilterValues} from 'utils';

import {IPropertyFilterFields} from './form-property-filter.interfaces';
import {PropertyFilterFieldsEnum} from './form-property-filter.enums';

export const FormPropertyFilter = ({
  form,
  values,
  initialValues,
  propertyType,
  contractType,
  isButtonLoading,
  onSubmit,
  onReset,
}: IFilterProps<IPropertyFilterFields>) => {
  const {t} = useTranslation();
  const catalog = useSelector(appCatalogSelector);
  const regions = useSelector(appRegionsSelector);
  const streets = useSelector(appStreetsSelector);
  const settlements = useSelector(appSettlementsSelector);
  const districts = useSelector(appDistrictsSelector);

  // const isRegionCapital = useMemo(
  //   () =>
  //     (values &&
  //       values[PropertyFilterFieldsEnum.REGION]?.find(r =>
  //         Object.values(CapitalNaming).includes((r.label as string)?.toLowerCase())
  //       )) ||
  //     checkForArrayValidity(initialValues?.regionNames)?.some(v =>
  //       Object.values(CapitalNaming).includes(v.toLowerCase())
  //     ),
  //   [values, initialValues]
  // );
  const locationPresets = useMemo(
    () =>
      values
        ? {
            ...injectLocationFilterValues({
              propName: LocationPropsEnum.REGION,
              value: values[PropertyFilterFieldsEnum.REGION]!,
              multiple: false,
            }),
            ...injectLocationFilterValues({
              propName: LocationPropsEnum.SETTLEMENT,
              value: values[PropertyFilterFieldsEnum.SETTLEMENT]!,
              multiple: false,
            }),
            ...injectLocationFilterValues({
              propName: LocationPropsEnum.DISTRICT,
              value: values[PropertyFilterFieldsEnum.DISTRICT]!,
              multiple: false,
            }),
          }
        : {},
    [values]
  );

  const presets = useMemo(
    () => ({
      mode:
        contractType?.includes(ContractTypesEnum.SALE) &&
        !contractType?.includes(ContractTypesEnum.MONTHLY) &&
        !contractType?.includes(ContractTypesEnum.DAILY)
          ? CustomerTypesEnum.SALE
          : !contractType?.includes(ContractTypesEnum.SALE) &&
            (contractType?.includes(ContractTypesEnum.MONTHLY) || contractType?.includes(ContractTypesEnum.DAILY))
          ? ContractTypesEnum.RENT
          : contractType?.includes(ContractTypesEnum.SALE) &&
            (contractType?.includes(ContractTypesEnum.MONTHLY) || contractType?.includes(ContractTypesEnum.DAILY))
          ? CustomerTypesEnum.ALL
          : null,
      rent:
        contractType?.includes(ContractTypesEnum.MONTHLY) && contractType?.includes(ContractTypesEnum.DAILY)
          ? ContractTypesEnum.ALL
          : contractType?.includes(ContractTypesEnum.MONTHLY)
          ? ContractTypesEnum.MONTHLY
          : contractType?.includes(ContractTypesEnum.DAILY)
          ? ContractTypesEnum.DAILY
          : null,
    }),
    [contractType]
  );
  const unitPriceFields = useMemo(
    () =>
      propertyType === PropertyTypesEnum.COMMERCIAL
        ? {
            minUnit: PropertyFilterFieldsEnum.MIN_UNIT_PRICE_MONTHLY,
            maxUnit: PropertyFilterFieldsEnum.MAX_UNIT_PRICE_MONTHLY,
          }
        : {},
    [propertyType]
  );

  const hasInitialValues = useMemo(() => initialValues && Object.keys(initialValues).length > 0, [initialValues]);

  const setInitialValus = useMountedOnce(() => {
    if (hasInitialValues) {
      form.setFieldsValue(initialValues!);
    }
  });

  useEffect(() => {
    setInitialValus();
  }, [setInitialValus]);

  return (
    <Form form={form} onFinish={onSubmit} name={FormsEnum.PROPERTY_FILTER}>
      <Row gutter={16}>
        <Col span={4}>
          <Form.Item name={PropertyFilterFieldsEnum.CODE} rules={[{required: false}]}>
            <PInput label={t('form.propertyId')} id={PropertyFilterFieldsEnum.CODE} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={PropertyFilterFieldsEnum.MODIFIED} rules={[{required: false}]}>
            <PRangepicker
              label={t('form.modified')}
              size="large"
              format={dateDefaultFormat}
              placeholder={[t('form.start'), t('form.end')]}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={`${PropertyFilterFieldsEnum.REGION}`} rules={[{required: false}]}>
            <CSelectAsync
              mode="multiple"
              label={t('form.region')}
              id={PropertyFilterFieldsEnum.REGION}
              name={PropertyFilterFieldsEnum.REGION}
              options={regions!}
              dispatchAction={getRegionsAction}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={`${PropertyFilterFieldsEnum.SETTLEMENT}`} rules={[{required: false}]}>
            <CSelectAsync
              mode="multiple"
              label={t('form.settlement')}
              id={PropertyFilterFieldsEnum.SETTLEMENT}
              name={PropertyFilterFieldsEnum.SETTLEMENT}
              options={settlements!}
              dispatchAction={getSettlementsAction}
              dispatchActionParams={locationPresets}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={`${PropertyFilterFieldsEnum.DISTRICT}`} rules={[{required: false}]}>
            <CSelectAsync
              mode="multiple"
              label={t('form.district')}
              id={PropertyFilterFieldsEnum.DISTRICT}
              name={PropertyFilterFieldsEnum.DISTRICT}
              options={districts!}
              dispatchAction={getDistrictsAction}
              dispatchActionParams={locationPresets}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={PropertyFilterFieldsEnum.STREET} rules={[{required: false}]}>
            <CSelectAsync
              options={streets!}
              mode="multiple"
              label={t('form.street')}
              id={PropertyFilterFieldsEnum.STREET}
              name={PropertyFilterFieldsEnum.STREET}
              className="w-full"
              size="large"
              dispatchAction={getStreetsAction}
              dispatchActionParams={locationPresets}
            />
          </Form.Item>
        </Col>

        {propertyType === PropertyTypesEnum.APARTMENT && (
          <>
            <Col span={4}>
              <Form.Item name={PropertyFilterFieldsEnum.ADDRESS_NUMBER} rules={[{required: false}]}>
                <PInput label={t('form.buildingNumbers')} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name={PropertyFilterFieldsEnum.APARTMENT_NUMBER} rules={[{required: false}]}>
                <PInput label={t('form.apartmentNumbers')} />
              </Form.Item>
            </Col>
          </>
        )}
        {propertyType === PropertyTypesEnum.HOUSE && (
          <Col span={4}>
            <Form.Item name={PropertyFilterFieldsEnum.ADDRESS_NUMBER} rules={[{required: false}]}>
              <PInput label={t('form.houseNumbers')} />
            </Form.Item>
          </Col>
        )}
        {propertyType === PropertyTypesEnum.LAND && (
          <Col span={4}>
            <Form.Item name={PropertyFilterFieldsEnum.ADDRESS_NUMBER} rules={[{required: false}]}>
              <PInput label={t('form.numbers')} />
            </Form.Item>
          </Col>
        )}
        {propertyType === PropertyTypesEnum.COMMERCIAL && (
          <>
            <Col span={4}>
              <Form.Item name={PropertyFilterFieldsEnum.ADDRESS_NUMBER} rules={[{required: false}]}>
                <PInput label={t('form.buildingNumbers')} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name={PropertyFilterFieldsEnum.COMMERCIAL_NUMBER} rules={[{required: false}]}>
                <PInput label={t('form.commercialNumber')} />
              </Form.Item>
            </Col>
          </>
        )}

        {propertyType &&
          (propertyType === PropertyTypesEnum.HOUSE ? (
            <>
              <Col span={4}>
                <Space.Compact>
                  <Form.Item name={PropertyFilterFieldsEnum.MIN_LIVING_AREA}>
                    <PNumericInput
                      label={`${t('form.living')} ${t('shorts.min')}`}
                      suffix={t('shorts.squareMeter')}
                      id={PropertyFilterFieldsEnum.MIN_LIVING_AREA}
                    />
                  </Form.Item>
                  <Form.Item name={PropertyFilterFieldsEnum.MAX_LIVING_AREA}>
                    <PNumericInput
                      label={t('shorts.max')}
                      suffix={t('shorts.squareMeter')}
                      id={PropertyFilterFieldsEnum.MAX_LIVING_AREA}
                    />
                  </Form.Item>
                </Space.Compact>
              </Col>
            </>
          ) : propertyType === PropertyTypesEnum.LAND ? (
            <>
              <Col span={4}>
                <Space.Compact>
                  <Form.Item name={PropertyFilterFieldsEnum.MIN_SQUARE_METRE}>
                    <PNumericInput
                      label={`${t('form.area')} ${t('shorts.min')}`}
                      suffix={t('shorts.squareMeter')}
                      id={PropertyFilterFieldsEnum.MIN_SQUARE_METRE}
                    />
                  </Form.Item>
                  <Form.Item name={PropertyFilterFieldsEnum.MAX_SQUARE_METRE}>
                    <PNumericInput
                      label={t('shorts.max')}
                      suffix={t('shorts.squareMeter')}
                      id={PropertyFilterFieldsEnum.MAX_SQUARE_METRE}
                    />
                  </Form.Item>
                </Space.Compact>
              </Col>
              <Col span={4}>
                <Space.Compact>
                  <Form.Item name={PropertyFilterFieldsEnum.MIN_HECTARE}>
                    <PNumericInput
                      withoutFormatter
                      label={`${t('form.area')} ${t('shorts.min')}`}
                      suffix={t('shorts.hectare')}
                      id={PropertyFilterFieldsEnum.MIN_HECTARE}
                    />
                  </Form.Item>
                  <Form.Item name={PropertyFilterFieldsEnum.MAX_HECTARE}>
                    <PNumericInput
                      withoutFormatter
                      label={t('shorts.max')}
                      suffix={t('shorts.hectare')}
                      id={PropertyFilterFieldsEnum.MAX_HECTARE}
                    />
                  </Form.Item>
                </Space.Compact>
              </Col>
            </>
          ) : propertyType === PropertyTypesEnum.APARTMENT ? (
            <Col span={4}>
              <Space.Compact>
                <Form.Item name={PropertyFilterFieldsEnum.MIN_AREA}>
                  <PNumericInput
                    label={`${t('form.area')} ${t('shorts.min')}`}
                    suffix={t('shorts.squareMeter')}
                    id={PropertyFilterFieldsEnum.MIN_AREA}
                  />
                </Form.Item>
                <Form.Item name={PropertyFilterFieldsEnum.MAX_AREA}>
                  <PNumericInput
                    label={t('shorts.max')}
                    suffix={t('shorts.squareMeter')}
                    id={PropertyFilterFieldsEnum.MAX_AREA}
                  />
                </Form.Item>
              </Space.Compact>
            </Col>
          ) : null)}

        {(propertyType === PropertyTypesEnum.COMMERCIAL || propertyType === PropertyTypesEnum.HOUSE) && (
          <Col span={4}>
            <Space.Compact>
              <Form.Item name={PropertyFilterFieldsEnum.MIN_LAND_AREA}>
                <PNumericInput
                  label={`${t('form.land')} ${t('shorts.min')}`}
                  suffix={t('shorts.squareMeter')}
                  id={PropertyFilterFieldsEnum.MIN_LAND_AREA}
                />
              </Form.Item>
              <Form.Item name={PropertyFilterFieldsEnum.MAX_LAND_AREA}>
                <PNumericInput
                  label={t('shorts.max')}
                  suffix={t('shorts.squareMeter')}
                  id={PropertyFilterFieldsEnum.MAX_LAND_AREA}
                />
              </Form.Item>
            </Space.Compact>
          </Col>
        )}

        {propertyType === PropertyTypesEnum.COMMERCIAL && (
          <Col span={4}>
            <Form.Item name={PropertyFilterFieldsEnum.TITLE}>
              <PInput label={t('form.title')} />
            </Form.Item>
          </Col>
        )}

        {propertyType &&
          (propertyType === PropertyTypesEnum.HOUSE ? (
            <>
              <Col span={4}>
                <Space.Compact>
                  <Form.Item name={PropertyFilterFieldsEnum.MIN_FLOOR_PLAN}>
                    <PNumericInput
                      label={`${t('form.floors')} ${t('shorts.min')}`}
                      id={PropertyFilterFieldsEnum.MIN_FLOOR_PLAN}
                    />
                  </Form.Item>
                  <Form.Item name={PropertyFilterFieldsEnum.MAX_FLOOR_PLAN}>
                    <PNumericInput label={t('shorts.max')} id={PropertyFilterFieldsEnum.MAX_FLOOR_PLAN} />
                  </Form.Item>
                </Space.Compact>
              </Col>
            </>
          ) : propertyType === PropertyTypesEnum.COMMERCIAL ? (
            <>
              <Col span={4}>
                <Space.Compact>
                  <Form.Item name={PropertyFilterFieldsEnum.MIN_ENTRANCE_FLOOR}>
                    <PNumericInput
                      type="number"
                      label={`${t('form.entranceFloor')} ${t('shorts.min')}`}
                      id={PropertyFilterFieldsEnum.MIN_ENTRANCE_FLOOR}
                    />
                  </Form.Item>
                  <Form.Item name={PropertyFilterFieldsEnum.MAX_ENTRANCE_FLOOR}>
                    <PNumericInput
                      type="number"
                      label={t('shorts.max')}
                      id={PropertyFilterFieldsEnum.MAX_ENTRANCE_FLOOR}
                    />
                  </Form.Item>
                </Space.Compact>
              </Col>
            </>
          ) : propertyType === PropertyTypesEnum.APARTMENT ? (
            <Col span={4}>
              <Space.Compact>
                <Form.Item name={PropertyFilterFieldsEnum.MIN_FLOOR}>
                  <PNumericInput
                    label={`${t('form.floor')} ${t('shorts.min')}`}
                    id={PropertyFilterFieldsEnum.MIN_AREA}
                  />
                </Form.Item>
                <Form.Item name={PropertyFilterFieldsEnum.MAX_FLOOR}>
                  <PNumericInput label={t('shorts.max')} id={PropertyFilterFieldsEnum.MAX_AREA} />
                </Form.Item>
              </Space.Compact>
            </Col>
          ) : null)}

        {(propertyType === PropertyTypesEnum.APARTMENT || propertyType === PropertyTypesEnum.HOUSE) && (
          <Col span={5}>
            <Space.Compact>
              <Form.Item noStyle name={PropertyFilterFieldsEnum.MIN_BEDROOMS}>
                <PNumericInput
                  withoutFormatter
                  label={`${propertyType === PropertyTypesEnum.APARTMENT ? t('form.room') : t('form.bedroom')} ${t(
                    'shorts.min'
                  )}`}
                />
              </Form.Item>
              <Form.Item noStyle name={PropertyFilterFieldsEnum.MAX_BEDROOMS}>
                <PNumericInput withoutFormatter label={t('shorts.max')} />
              </Form.Item>
            </Space.Compact>
          </Col>
        )}
        {(propertyType === PropertyTypesEnum.APARTMENT || propertyType === PropertyTypesEnum.HOUSE) && (
          <Col span={5}>
            <Space.Compact>
              <Form.Item name={PropertyFilterFieldsEnum.MIN_BATHROOMS}>
                <PNumericInput withoutFormatter label={`${t('form.bathroom')} ${t('shorts.min')}`} />
              </Form.Item>
              <Form.Item name={PropertyFilterFieldsEnum.MAX_BATHROOMS}>
                <PNumericInput withoutFormatter label={`${t('shorts.max')} `} />
              </Form.Item>
            </Space.Compact>
          </Col>
        )}

        {propertyType !== PropertyTypesEnum.LAND && (
          <Col span={4}>
            <Form.Item name={PropertyFilterFieldsEnum.REPAIR}>
              <PSelect
                mode="multiple"
                label={t('form.repair')}
                options={catalog[OptGroupsEnum.REPAIR_TYPES]}
                id={PropertyFilterFieldsEnum.REPAIR}
              />
            </Form.Item>
          </Col>
        )}

        {propertyType === PropertyTypesEnum.HOUSE && (
          <Col span={4}>
            <Form.Item name={PropertyFilterFieldsEnum.YARD_MARKERS}>
              <PSelect
                mode="multiple"
                label={t('form.yardMarkers')}
                options={catalog[OptGroupsEnum.YARD_MARKERS]}
                id={PropertyFilterFieldsEnum.YARD_MARKERS}
              />
            </Form.Item>
          </Col>
        )}

        {propertyType === PropertyTypesEnum.COMMERCIAL && (
          <>
            <Col span={4}>
              <Form.Item name={PropertyFilterFieldsEnum.ENTRY_TYPE}>
                <PSelect mode="multiple" label={t('form.entryType')} options={catalog[OptGroupsEnum.ENTRY_TYPE]} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Space.Compact>
                <Form.Item name={PropertyFilterFieldsEnum.MIN_TOTAL_AREA}>
                  <PNumericInput withoutFormatter label={`${t('form.totlaArea')} ${t('shorts.min')}`} />
                </Form.Item>
                <Form.Item name={PropertyFilterFieldsEnum.MAX_TOTAL_AREA}>
                  <PNumericInput withoutFormatter label={`${t('shorts.max')} `} />
                </Form.Item>
              </Space.Compact>
            </Col>
          </>
        )}

        {propertyType === PropertyTypesEnum.LAND && (
          <>
            <Col span={4}>
              <Form.Item name={`${PropertyFilterFieldsEnum.USAGE}`}>
                <PSelect mode="multiple" label={t('form.usage')} options={catalog[OptGroupsEnum.SOIL_IMPORTANCE]} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Space.Compact>
                <Form.Item name={PropertyFilterFieldsEnum.MIN_FRONT_LENGTH}>
                  <PNumericInput
                    withoutFormatter
                    label={`${t('form.frontLength')} ${t('shorts.min')}`}
                    suffix={t('shorts.meterShort')}
                  />
                </Form.Item>
                <Form.Item name={PropertyFilterFieldsEnum.MAX_FRONT_LENGTH}>
                  <PNumericInput withoutFormatter label={`${t('shorts.max')} `} suffix={t('shorts.meterShort')} />
                </Form.Item>
              </Space.Compact>
            </Col>
            <Col span={4}>
              <Form.Item name={PropertyFilterFieldsEnum.CADASTRE_NUMBER}>
                <PInput label={`${t('form.cadastre')} `} />
              </Form.Item>
            </Col>
          </>
        )}
        {propertyType === PropertyTypesEnum.APARTMENT && (
          <>
            <Col span={4}>
              <Form.Item name={PropertyFilterFieldsEnum.BUILDING_RESIDENTIAL_SUBTYPE}>
                <PSelect mode="multiple" label={t('form.buildingType')} options={residentialTypeOptions} />
              </Form.Item>
            </Col>
            {values?.buildingResidentialSubtype?.includes(BuildingTypesEnum.STONE) && (
              <Col span={4}>
                <Form.Item name={PropertyFilterFieldsEnum.STONE}>
                  <PSelect
                    mode="multiple"
                    label={t('form.buildingSubType')}
                    options={catalog[OptGroupsEnum.BUILDING_SUBTYPE_STONE]}
                  />
                </Form.Item>
              </Col>
            )}

            {values?.buildingResidentialSubtype?.includes(BuildingSubTypesEnum.NEW) && (
              <Col span={4}>
                <Form.Item name={PropertyFilterFieldsEnum.BUILDING_RESIDENTIAL_HEATING}>
                  <PSelect mode="multiple" label={t('form.heatingNew')} options={heatingOptions} />
                </Form.Item>
              </Col>
            )}

            <Col span={4}>
              <Form.Item name={PropertyFilterFieldsEnum.HEATING}>
                <PSelect mode="multiple" label={t('form.heating')} options={catalog[OptGroupsEnum.HEATING]} />
              </Form.Item>
            </Col>
          </>
        )}
        {propertyType === PropertyTypesEnum.APARTMENT && (
          <Col span={4}>
            <Form.Item name={PropertyFilterFieldsEnum.VIEW}>
              <PSelect mode="multiple" label={t('form.view')} options={catalog[OptGroupsEnum.VIEW]} />
            </Form.Item>
          </Col>
        )}

        {propertyType === PropertyTypesEnum.APARTMENT && presets.rent && (
          <Col span={4}>
            <Form.Item name={PropertyFilterFieldsEnum.OPTIONS}>
              <PSelect mode="multiple" label={t('form.markers')} options={catalog[OptGroupsEnum.APPARTMENT_MARKERS]} />
            </Form.Item>
          </Col>
        )}
        {propertyType === PropertyTypesEnum.COMMERCIAL && (
          <>
            <Col span={4}>
              <Space.Compact>
                <Form.Item name={PropertyFilterFieldsEnum.MIN_GLASS}>
                  <PNumericInput withoutFormatter label={`${t('form.glass')} ${t('shorts.min')}`} />
                </Form.Item>
                <Form.Item name={PropertyFilterFieldsEnum.MAX_GLASS}>
                  <PNumericInput withoutFormatter label={`${t('shorts.max')} `} />
                </Form.Item>
              </Space.Compact>
            </Col>
            <Col span={3}>
              <Form.Item name={PropertyFilterFieldsEnum.DIVISIBLE}>
                <PSelect label={t('form.divisible')} options={booleanOptions} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name={PropertyFilterFieldsEnum.IS_BUILDING}>
                <PSelect label={t('form.isBuilding')} options={booleanOptions} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name={PropertyFilterFieldsEnum.CAFE}>
                <PSelect label={t('form.cafe')} options={booleanOptions} />
              </Form.Item>
            </Col>
          </>
        )}
        {(propertyType === PropertyTypesEnum.APARTMENT || propertyType === PropertyTypesEnum.COMMERCIAL) && (
          <>
            <Col span={3}>
              <Form.Item name={PropertyFilterFieldsEnum.PARKING}>
                <PSelect label={t('form.parking')} options={booleanOptions} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name={PropertyFilterFieldsEnum.ENTRY_POSITION}>
                <PSelect
                  mode="multiple"
                  label={t('form.entryPosition')}
                  options={catalog[OptGroupsEnum.ENTRY_POSITION]}
                />
              </Form.Item>
            </Col>
          </>
        )}

        <Col span={4}>
          <Form.Item name={PropertyFilterFieldsEnum.NAME}>
            <PInput label={t('form.firstName')} id={PropertyFilterFieldsEnum.NAME} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name={PropertyFilterFieldsEnum.PHONE}
            rules={[
              {min: 9, message: fieldsMessageTranslations.phoneMin},
              {max: 14, message: fieldsMessageTranslations.phoneMax},
            ]}
          >
            <PPhoneInput label={t('form.phone')} id={PropertyFilterFieldsEnum.PHONE} />
          </Form.Item>
        </Col>
      </Row>

      {(presets.mode === ContractTypesEnum.ALL || presets.mode === ContractTypesEnum.RENT) &&
        (presets.rent === ContractTypesEnum.ALL || presets.rent === ContractTypesEnum.MONTHLY) && (
          <Col span={4}>
            <Form.Item name={PropertyFilterFieldsEnum.AVAILABLE} rules={[{required: false}]}>
              <PRangepicker
                label={t('form.available')}
                size="large"
                format={dateDefaultFormat}
                placeholder={[t('form.start'), t('form.end')]}
              />
            </Form.Item>
          </Col>
        )}

      {(presets.mode === ContractTypesEnum.SALE || presets.mode === ContractTypesEnum.ALL) && (
        <FormPricesFilter<IPropertyFilterFields>
          label={t('form.sale')}
          values={values}
          propertyType={propertyType!}
          fields={{
            minTotal: PropertyFilterFieldsEnum.MIN_TOTAL_PRICE_SALE,
            maxTotal: PropertyFilterFieldsEnum.MAX_TOTAL_PRICE_SALE,
            minUnit: PropertyFilterFieldsEnum.MIN_UNIT_PRICE_SALE,
            maxUnit: PropertyFilterFieldsEnum.MAX_UNIT_PRICE_SALE,
            currency: PropertyFilterFieldsEnum.CURRENCY_SALE,
          }}
        />
      )}

      {(presets.mode === ContractTypesEnum.ALL || presets.mode === ContractTypesEnum.RENT) &&
        (presets.rent === ContractTypesEnum.ALL || presets.rent === ContractTypesEnum.MONTHLY) && (
          <FormPricesFilter<IPropertyFilterFields>
            rent={presets.rent}
            label={t('form.rent')}
            values={values}
            propertyType={propertyType!}
            fields={{
              ...unitPriceFields,
              minTotal: PropertyFilterFieldsEnum.MIN_TOTAL_PRICE_MONTHLY,
              maxTotal: PropertyFilterFieldsEnum.MAX_TOTAL_PRICE_MONTHLY,
              currency: PropertyFilterFieldsEnum.CURRENCY_MONTHLY,
            }}
          />
        )}

      {(presets.mode === ContractTypesEnum.ALL || presets.mode === ContractTypesEnum.RENT) &&
        (presets.rent === ContractTypesEnum.ALL || presets.rent === ContractTypesEnum.DAILY) && (
          <FormPricesFilter<IPropertyFilterFields>
            rent={presets.rent}
            label={t('form.rentDaily')}
            values={values}
            propertyType={propertyType!}
            fields={{
              minTotal: PropertyFilterFieldsEnum.MIN_TOTAL_PRICE_DAILY,
              maxTotal: PropertyFilterFieldsEnum.MAX_TOTAL_PRICE_DAILY,
              currency: PropertyFilterFieldsEnum.CURRENCY_DAILY,
            }}
          />
        )}

      <Row gutter={16}>
        <Col>
          <CSubmitButton
            allowValidate={false}
            form={form}
            values={values}
            isButtonLoading={isButtonLoading}
            title={t('actions.filter')}
            icon={<Icon.Filter fill="white" />}
          />
        </Col>
        <Col>
          {hasInitialValues && !isButtonLoading && (
            <PButton htmlType="button" type="text" onClick={onReset}>
              {t('actions.clear')}
            </PButton>
          )}
        </Col>
      </Row>
    </Form>
  );
};
