import {takeEvery, all, call, put} from 'redux-saga/effects';

import {
  addAddressService,
  addFolderService,
  getFolderService,
  getOptGroupsService,
  getProfileService,
  searchDistrictsService,
  searchRegionsService,
  searchSettlementsService,
  searchStreetsService,
  updateFolderService,
} from 'services/app';
import {TAccount, TDistrict, TFolder, TOptGroup, TRegion, TSettlement, TStreet} from 'types/api';
import {TServerResponseError, TActionSettings} from 'types/common';
import {OptGroupsTranslationEnum} from 'enums/translations';
import {getTranslation} from 'utils';
import {AddonsEnum, FoldersEnum} from 'enums';
import {getByIdFn, getOptionsFn} from 'store/core/sagas';

import {
  addFolderAction,
  clearMediaAction,
  getFolderByIdAction,
  getOptGroupsAction,
  getProfileAction,
  updateFolderAction,
  addAddressAction,
  getStreetsAction,
  getRegionsAction,
  getSettlementsAction,
  clearAddressAction,
  getDistrictsAction,
  getCountriesAction,
} from './actions';
import {
  setCurrentFile,
  setCurrentImage,
  setErrors,
  setFile,
  setImage,
  setLoading,
  setCatalog,
  setProfile,
  setProfileLoading,
  setCurrentRoomImage,
  setCurrentFloorImage,
  setCurrentRoomFile,
  setCurrentFloorFile,
  setRoomImage,
  setFloorImage,
  setRoomFile,
  setFloorFile,
  setAddress,
  setPropertyBuildingImage,
  setPropertyBuildingFile,
  setStreets,
  setRegions,
  setBuildingImage,
  setBuildingFile,
  setCurrentBuildingImage,
  setCurrentBuildingFile,
  setSettlements,
  setDistricts,
} from './slice-app';

const getProfileSaga = function* ({payload: {...payload}}: {payload: {}} & {type: string}) {
  yield put(setProfileLoading(true));

  try {
    const response: {data: {success: TAccount}} = yield call(getProfileService, payload);

    yield put(setProfile(response.data.success));
  } catch (e) {
    yield put(setErrors(e as string));
  } finally {
    yield put(setProfileLoading(false));
  }
};

const getOptGroupsSaga = function* ({payload: {...payload}}: {payload: {}} & {type: string}) {
  yield put(setLoading(true));

  try {
    const {data}: {data: TOptGroup[]} = yield call(getOptGroupsService, payload);

    const options = Object.fromEntries(
      data.map(group => [
        [getTranslation(OptGroupsTranslationEnum, group.name)],
        group.options.map(p => ({
          label: p.name,
          value: p.id,
        })),
      ])
    );

    yield put(setCatalog(options));
  } catch (e) {
    yield put(setErrors(e as TServerResponseError));
  } finally {
    yield put(setLoading(false));
  }
};

const addFolderSaga = function* ({
  payload: {...payload},
  settings: {folder, addon, onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  try {
    const {data}: {data: {success: number; error: TServerResponseError}} = yield call(addFolderService, payload);

    if (data.error) {
      throw data.error;
    } else {
      yield put(setErrors(null));
      yield put(
        folder === FoldersEnum.IMAGE
          ? addon === AddonsEnum.ROOMS
            ? setRoomImage(data.success)
            : addon === AddonsEnum.FLOORS || addon === AddonsEnum.COMMERCIAL
            ? setFloorImage(data.success)
            : addon === AddonsEnum.PROPERTY_BUILDING_IMAGE
            ? setPropertyBuildingImage(data.success)
            : addon === AddonsEnum.BUILDING_IMAGE
            ? setBuildingImage(data.success)
            : setImage(data.success)
          : addon === AddonsEnum.ROOMS
          ? setRoomFile(data.success)
          : addon === AddonsEnum.FLOORS || addon === AddonsEnum.COMMERCIAL
          ? setFloorFile(data.success)
          : addon === AddonsEnum.PROPERTY_BUILDING_FILE
          ? setPropertyBuildingFile(data.success)
          : addon === AddonsEnum.BUILDING_FILE
          ? setBuildingFile(data.success)
          : setFile(data.success)
      );

      if (data.success) {
        const {data: response}: {data: {success: any; error: TServerResponseError}} = yield call(getFolderService, {
          id: data.success,
        });

        onFulfilled && onFulfilled(response.success.files);
      }
    }
  } catch (e) {
    yield put(setErrors(e as TServerResponseError));

    onReject && onReject(e);
  }
};

const updateFolderSaga = function* ({
  payload: {...payload},
  settings: {folder, addon, onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  try {
    const {data}: {data: {add: any; update: any; remove: any; error: TServerResponseError}} = yield call(
      updateFolderService,
      payload
    );

    const folderId = payload.folder.id || payload.folder;

    if (data.error) {
      throw new Error();
    } else {
      yield put(setErrors(null));
      yield put(
        folder === FoldersEnum.IMAGE
          ? addon === AddonsEnum.ROOMS
            ? setRoomImage(folderId)
            : addon === AddonsEnum.FLOORS || addon === AddonsEnum.COMMERCIAL
            ? setFloorImage(folderId)
            : addon === AddonsEnum.PROPERTY_BUILDING_IMAGE
            ? setPropertyBuildingImage(folderId)
            : addon === AddonsEnum.BUILDING_IMAGE
            ? setBuildingImage(folderId)
            : setImage(folderId)
          : addon === AddonsEnum.ROOMS
          ? setRoomFile(folderId)
          : addon === AddonsEnum.FLOORS || addon === AddonsEnum.COMMERCIAL
          ? setFloorFile(folderId)
          : addon === AddonsEnum.PROPERTY_BUILDING_FILE
          ? setPropertyBuildingFile(folderId)
          : addon === AddonsEnum.BUILDING_FILE
          ? setBuildingFile(folderId)
          : setFile(folderId)
      );

      if (!data.error) {
        const {data: response}: {data: {success: any; error: TServerResponseError}} = yield call(getFolderService, {
          id: folderId,
        });

        onFulfilled && onFulfilled(response.success.files);
      }
    }
  } catch (e) {
    yield put(setErrors(e as TServerResponseError));

    onReject && onReject(e);
  }
};

const getFolderByIdSaga = function* ({
  payload: {...payload},
  settings: {folder, addon, onFulfilled, onReject, ...settings},
}: {payload: {id: number}; settings: TActionSettings} & {type: string}) {
  yield getByIdFn<TFolder>({
    actions: {
      loading: setLoading,
      error: setErrors,
      success:
        folder === FoldersEnum.IMAGE
          ? addon === AddonsEnum.ROOMS
            ? setCurrentRoomImage
            : addon === AddonsEnum.FLOORS || addon === AddonsEnum.COMMERCIAL
            ? setCurrentFloorImage
            : addon === AddonsEnum.PROPERTY_BUILDING_IMAGE
            ? setPropertyBuildingImage
            : addon === AddonsEnum.BUILDING_IMAGE
            ? setCurrentBuildingImage
            : setCurrentImage
          : addon === AddonsEnum.ROOMS
          ? setCurrentRoomFile
          : addon === AddonsEnum.FLOORS || addon === AddonsEnum.COMMERCIAL
          ? setCurrentFloorFile
          : addon === AddonsEnum.PROPERTY_BUILDING_FILE
          ? setPropertyBuildingFile
          : addon === AddonsEnum.BUILDING_FILE
          ? setCurrentBuildingFile
          : setCurrentFile,
    },
    service: getFolderService,
    payload,
    settings: {...settings, folder, addon, isWithoutLoading: false, onFulfilled, onReject},
  });
};

const clearMediaSaga = function* ({
  payload: {...payload},
  settings: {folder, addon},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  try {
    if (payload.type === 'attached' && folder && addon) {
      yield put(
        folder === FoldersEnum.IMAGE
          ? addon === AddonsEnum.ROOMS
            ? setRoomImage(null)
            : addon === AddonsEnum.FLOORS || addon === AddonsEnum.COMMERCIAL
            ? setFloorImage(null)
            : addon === AddonsEnum.PROPERTY_BUILDING_IMAGE
            ? setPropertyBuildingImage(null)
            : addon === AddonsEnum.BUILDING_IMAGE
            ? setBuildingImage(null)
            : setImage(null)
          : addon === AddonsEnum.ROOMS
          ? setRoomFile(null)
          : addon === AddonsEnum.FLOORS || addon === AddonsEnum.COMMERCIAL
          ? setFloorFile(null)
          : addon === AddonsEnum.PROPERTY_BUILDING_FILE
          ? setPropertyBuildingFile(null)
          : addon === AddonsEnum.BUILDING_FILE
          ? setBuildingFile(null)
          : setFile(null)
      );
    }

    if (!Object.keys(payload).length && folder && addon) {
      yield put(
        folder === FoldersEnum.IMAGE
          ? addon === AddonsEnum.ROOMS
            ? setCurrentRoomImage(null)
            : addon === AddonsEnum.FLOORS || addon === AddonsEnum.COMMERCIAL
            ? setCurrentFloorImage(null)
            : addon === AddonsEnum.PROPERTY_BUILDING_IMAGE
            ? setPropertyBuildingImage(null)
            : addon === AddonsEnum.BUILDING_IMAGE
            ? setCurrentBuildingImage(null)
            : setCurrentImage(null)
          : addon === AddonsEnum.ROOMS
          ? setCurrentRoomFile(null)
          : addon === AddonsEnum.FLOORS || addon === AddonsEnum.COMMERCIAL
          ? setCurrentFloorFile(null)
          : addon === AddonsEnum.PROPERTY_BUILDING_FILE
          ? setPropertyBuildingFile(null)
          : addon === AddonsEnum.BUILDING_FILE
          ? setCurrentBuildingFile(null)
          : setCurrentFile(null)
      );
    }

    if (!Object.keys(payload).length && !folder && !addon) {
      yield put(setImage(null));
      yield put(setFile(null));
      yield put(setPropertyBuildingImage(null));
      yield put(setPropertyBuildingFile(null));
      yield put(setBuildingImage(null));
      yield put(setBuildingFile(null));
      yield put(setCurrentImage(null));
      yield put(setCurrentFile(null));
      yield put(setCurrentBuildingImage(null));
      yield put(setCurrentBuildingFile(null));
    }
  } catch (e) {
    yield put(setErrors(e as TServerResponseError));
  }
};

const addAddressSaga = function* ({
  payload: {...payload},
  settings: {onFulfilled, onReject},
}: {payload: any; settings: TActionSettings} & {type: string}) {
  try {
    const {data}: {data: {success: {id: number}; error: TServerResponseError}} = yield call(addAddressService, payload);

    if (data.error) {
      throw data.error;
    } else {
      yield put(setErrors(null));
      yield put(setAddress(data.success));
      onFulfilled && onFulfilled();
    }
  } catch (e) {
    console.error(e);
    yield put(setErrors(e as TServerResponseError));
    onReject && onReject(e);
  }
};

const getCountriesSaga = function* ({
  payload: {...payload},
  settings: {...settings},
}: {payload: {offset?: number; length?: number}; settings: TActionSettings} & {type: string}) {
  yield getOptionsFn<TRegion[]>({
    actions: {loading: setLoading, error: setErrors, success: setRegions},
    service: searchRegionsService,
    payload,
    settings,
  });
};

const getRegionsSaga = function* ({
  payload: {...payload},
  settings: {...settings},
}: {payload: {offset?: number; length?: number}; settings: TActionSettings} & {type: string}) {
  yield getOptionsFn<TRegion[]>({
    actions: {loading: setLoading, error: setErrors, success: setRegions},
    service: searchRegionsService,
    payload,
    settings,
  });
};

const getSettlementsSaga = function* ({
  payload: {...payload},
  settings: {...settings},
}: {payload: {offset?: number; length?: number}; settings: TActionSettings} & {type: string}) {
  yield getOptionsFn<TSettlement[]>({
    actions: {loading: setLoading, error: setErrors, success: setSettlements},
    service: searchSettlementsService as any,
    payload,
    settings,
  });
};

const getDistrictsSaga = function* ({
  payload: {...payload},
  settings: {...settings},
}: {payload: {offset?: number; length?: number}; settings: TActionSettings} & {type: string}) {
  yield getOptionsFn<TDistrict[]>({
    actions: {loading: setLoading, error: setErrors, success: setDistricts},
    service: searchDistrictsService as any,
    payload,
    settings,
  });
};

const getStreetsSaga = function* ({
  payload: {...payload},
  settings: {...settings},
}: {payload: {offset?: number; length?: number}; settings: TActionSettings} & {type: string}) {
  yield getOptionsFn<TStreet[]>({
    actions: {loading: setLoading, error: setErrors, success: setStreets},
    service: searchStreetsService,
    payload,
    settings,
  });
};

const clearAddressSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setAddress(null));
};

export const appSaga = function* () {
  yield all([
    takeEvery(getProfileAction().type, getProfileSaga),
    takeEvery(getOptGroupsAction().type, getOptGroupsSaga),
    takeEvery(addFolderAction().type, addFolderSaga),
    takeEvery(updateFolderAction().type, updateFolderSaga),
    takeEvery(getFolderByIdAction().type, getFolderByIdSaga),
    takeEvery(clearMediaAction().type, clearMediaSaga),
    takeEvery(addAddressAction().type, addAddressSaga),
    takeEvery(getCountriesAction().type, getCountriesSaga),
    takeEvery(getRegionsAction().type, getRegionsSaga),
    takeEvery(getSettlementsAction().type, getSettlementsSaga),
    takeEvery(getDistrictsAction().type, getDistrictsSaga),
    takeEvery(getStreetsAction().type, getStreetsSaga),
    takeEvery(clearAddressAction().type, clearAddressSaga),
  ]);
};
