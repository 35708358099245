import {memo} from 'react';

import {PStatusIndicator} from '.';

const PStatus = ({types, ...props}: {types: Array<Record<string, string>>}) => {
  return (
    <div className="flex items-center gap-3" {...props}>
      {types.map(t => (
        <div key={t.text} className="flex items-center gap-[8px]">
          <PStatusIndicator type={t.type} />
          <div className="text-grey-100 text-[12px]">{t.text}</div>
        </div>
      ))}
    </div>
  );
};

export const PStatusMemoized = memo(PStatus);
