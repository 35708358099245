export enum LeadFieldsEnum {
  SOURCE = 'source',
  TYPE = 'type',
  ADDRESS = 'address',
  KEYWORD = 'keyword',
  MODE = 'mode',
  RENT = 'rent',
  ACTION = 'action',
  FLAG = 'flag',
  INFO = 'info',
  TOTAL_PRICE = 'total_price',
  TOTAL_PRICE_SALE = 'total_price_sale',
  TOTAL_PRICE_MONTHLY = 'total_price_monthly',
  TOTAL_PRICE_DAILY = 'total_price_daily',
  UNIT_PRICE = 'unit_price',
  UNIT_PRICE_SALE = 'unit_price_sale',
  UNIT_PRICE_MONTHLY = 'unit_price_monthly',
  UNIT_PRICE_DAILY = 'unit_price_daily',
  CURRENCY_SALE = 'currency_sale',
  CURRENCY_MONTHLY = 'currency_monthly',
  CURRENCY_DAILY = 'currency_daily',
  CURRENCY = 'currency',
  PERSONS = 'persons',
  AREA = 'area',
  SQUARE_METRE = 'square_metre',
  HECTARE = 'hectare',
  LAND_AREA = 'land_area',
  LIVING_AREA = 'living_area',
  FLOOR = 'floor',
  FLOORS = 'floors',
  ENTRANCE_FLOOR = 'entrance_floor',
  ROOMS = 'rooms',
  CONTRACT_TYPE = 'contract_type',
  USAGE = 'usage',

  STATUS = 'status',
  PHONE = 'phone',
  NAME = 'name',
  CREATED = 'created',
  LEAD_CREATED = 'lead_created',
  LEAD_MODIFIED = 'lead_modified',
  ENTRANCE_DATE = 'entrance_date',
}

export enum PersonFieldsEnum {
  NAME = 'name',
  EMAIL = 'email',
  PHONES = 'phones',
  PHONE = 'phone',
  RELATION = 'relation',
  TYPE = 'type',
}
