import {createSelector} from '@reduxjs/toolkit';

import {State} from 'store/types';

import {BuildingsState} from './types';

const getState = (state: State): BuildingsState => state.buildings;

export const buildingsLoadingSelector = createSelector(getState, state => state.loading);
export const buildingsErrorSelector = createSelector(getState, state => state.error);
export const buildingsListSelector = createSelector(getState, state => state.buildings);
export const buildingsCountSelector = createSelector(getState, state => state.buildingsCount);
export const buildingCurrentSelector = createSelector(getState, state => state.currentBuilding);
export const buildingFilterPresetsSelector = createSelector(getState, state => state.buildingFilterPresets);
export const buildingHasUnsavedChangesSelector = createSelector(getState, state => state.hasUnsavedChanges);
