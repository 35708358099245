// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {Provider as StoreProvider} from 'react-redux';
import {ConfigProvider} from 'antd';
import {YMaps} from '@pbe/react-yandex-maps';
import hyAM from 'antd/locale/hy_AM';
import 'dayjs/locale/hy-am';

import {store} from 'store';
import {App} from 'App';
import {theme} from 'constants/theme';
import {PopupProvider} from 'hooks';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <PopupProvider>
      <StoreProvider store={store}>
        <ConfigProvider theme={theme} locale={hyAM}>
          {/* TODO: add hy_AM language correctly to type 'lang' and remove ts-nocheck flag */}
          <YMaps
            query={{
              apikey: process.env.REACT_APP_YMAP_API_KEY,
              suggest_apikey: process.env.REACT_APP_YMAP_SUGGEST_KEY,
              lang: 'hy_AM',
            }}
          >
            <App />
          </YMaps>
        </ConfigProvider>
      </StoreProvider>
    </PopupProvider>
  </React.StrictMode>
);
