export enum StatusTranslationEnum {
  ALL = 'Բոլորը',
  NEW = 'Նոր',
  CURRENT = 'Ընթացիկ',
  FINALIZED = 'Վերջնական',
  CANCELED = 'Չեղարկված',
  SUCCESSFUL = 'Հաջողված',
  ARCHIVED = 'Արխիվացված',
  ARCHIVE = 'Արխիվացնել',
  ONHOLDING = 'Սառեցնել',
  DISABLED = 'Սառեցված',
  ONHOLD = 'Սառեցված',
  ENABLED = 'Ակտիվացված',
  FINAL = 'Վերջնական',
  UNDER_DEVELOPMENT = 'Մշակման փուլում',
  SAVED = 'Պահպանվածներ',
}
