export enum PersonTypesTranslationEnum {
  SELLER = 'Վաճառող',
  CUSTOMER = 'Գնորդ',
  RENTER = 'Վարձակալ',
  LANDLORD = 'Վարձատու',
  DOMESTIC_AGENT = 'Ներքին գործակալ',
  BUILDING_MANAGER = 'Շենքի պատասխանատու',
  PARTNER = 'Գործնկեր',
  OTHER = 'Այլ',
}
