import {
  BuildingPlanTranslationEnum,
  BuildingTypesTranslationEnum,
  CurrencyTranslationEnum,
  DepartmentsTranslationEnum,
  HeatingTranslationEnum,
  InquiryStateTranslationEnum,
  PersonRelationsTranslationEnum,
  PersonTypesTranslationEnum,
  PropertyActionsTypesTranslationEnum,
  PropertyTypesTranslationEnum,
  RoomNameTranslationEnum,
  StatusTranslationEnum,
  FlagsTranslationEnum,
  CustomerTypesTranslationEnum,
  ContractTypesTranslationEnum,
} from 'enums/translations';
import {
  BuildingPlanEnum,
  BuildingSubTypesEnum,
  BuildingTypesEnum,
  CurrencyEnum,
  FlagsEnum,
  HeatingEnum,
  InquiryStateEnum,
  PropertyActionsTypesEnum,
  PropertyTypesEnum,
  RolesEnum,
  StatusEnum,
  CustomerTypesEnum,
  ContractTypesEnum,
} from 'enums';

export const accountTypeOptions = [
  {label: DepartmentsTranslationEnum.SUPER, value: RolesEnum.SUPER},
  {label: DepartmentsTranslationEnum.ADMINISTRATOR, value: RolesEnum.ADMIN},
  {label: DepartmentsTranslationEnum.INFO, value: RolesEnum.INFO},
  {label: DepartmentsTranslationEnum.MARKETING, value: RolesEnum.MARKETING},
  // {label: DepartmentsTranslationEnum.LAWYER, value: RolesEnum.LAWYER},
  {label: DepartmentsTranslationEnum.BROKER, value: RolesEnum.BROKER},
];

export const residentialTypeOptions = [
  {label: BuildingTypesTranslationEnum.NEW, value: BuildingSubTypesEnum.NEW},
  {label: BuildingTypesTranslationEnum.PANEL, value: BuildingSubTypesEnum.PANEL},
  {label: BuildingTypesTranslationEnum.STONE, value: BuildingSubTypesEnum.STONE},
];

export const commericalSubTypeOptions = [
  {label: BuildingTypesTranslationEnum.STONE_COMMERICAL, value: BuildingTypesEnum.RESIDENTIAL_OLD},
  {label: BuildingTypesTranslationEnum.NEW, value: BuildingTypesEnum.RESIDENTIAL_NEW},
  {label: BuildingTypesTranslationEnum.COMMERCIAL, value: BuildingTypesEnum.COMMERCIAL_ONLY},
];

export const heatingOptions = [
  {label: HeatingTranslationEnum.CENTRALIZED, value: HeatingEnum.CENTRALIZED},
  {label: HeatingTranslationEnum.PERSONAL, value: HeatingEnum.PERSONAL},
];

export const buildingPlanOptions = [
  {label: BuildingPlanTranslationEnum.FIRST, value: BuildingPlanEnum.FIRST},
  {label: BuildingPlanTranslationEnum.SECOND, value: BuildingPlanEnum.SECOND},
  {label: BuildingPlanTranslationEnum.THIRD, value: BuildingPlanEnum.THIRD},
];

export const statusOptions = [
  {label: StatusTranslationEnum.NEW, value: StatusEnum.NEW},
  {label: StatusTranslationEnum.CURRENT, value: StatusEnum.CURRENT},
  {label: StatusTranslationEnum.FINALIZED, value: StatusEnum.FINALIZED},
  {label: StatusTranslationEnum.CANCELED, value: StatusEnum.CANCELED},
];

export const propertyStatusOptions = [
  // {label: StatusTranslationEnum.NEW, value: StatusEnum.NEW},
  {label: StatusTranslationEnum.CURRENT, value: StatusEnum.CURRENT},
  {label: StatusTranslationEnum.FINALIZED, value: StatusEnum.FINALIZED},
  {label: StatusTranslationEnum.ARCHIVED, value: StatusEnum.ARCHIVED},
];

export const flagOptions = [
  {label: FlagsTranslationEnum.UNAVAILABLE, value: FlagsEnum.UNAVAILABLE},
  {label: FlagsTranslationEnum.NO_ANSWER, value: FlagsEnum.NO_ANSWER},
  {label: FlagsTranslationEnum.RETRY_LATER, value: FlagsEnum.RETRY_LATER},
];

export const customerTypeOptions = [
  {label: CustomerTypesTranslationEnum.CUSTOMER, value: CustomerTypesEnum.CUSTOMER},
  {label: CustomerTypesTranslationEnum.SALE, value: CustomerTypesEnum.SALE},
  {label: CustomerTypesTranslationEnum.RENT, value: CustomerTypesEnum.RENT},
  {label: CustomerTypesTranslationEnum.RENTER, value: CustomerTypesEnum.RENTER},
  {label: CustomerTypesTranslationEnum.BUILDING, value: CustomerTypesEnum.BUILDING},
  // {label: CustomerTypesTranslationEnum.OTHER, value: CustomerTypesEnum.OTHER},
];

export const personTypeOptions = [
  {label: PersonTypesTranslationEnum.SELLER, value: PersonTypesTranslationEnum.SELLER},
  {label: PersonTypesTranslationEnum.CUSTOMER, value: PersonTypesTranslationEnum.CUSTOMER},
  {label: PersonTypesTranslationEnum.LANDLORD, value: PersonTypesTranslationEnum.LANDLORD},
  {label: PersonTypesTranslationEnum.RENTER, value: PersonTypesTranslationEnum.RENTER},
  {label: PersonTypesTranslationEnum.DOMESTIC_AGENT, value: PersonTypesTranslationEnum.DOMESTIC_AGENT},
  {label: PersonTypesTranslationEnum.BUILDING_MANAGER, value: PersonTypesTranslationEnum.BUILDING_MANAGER},
  {label: PersonTypesTranslationEnum.PARTNER, value: PersonTypesTranslationEnum.PARTNER},
  {label: PersonTypesTranslationEnum.OTHER, value: PersonTypesTranslationEnum.OTHER},
];

export const personRelationOptions = [
  {label: PersonRelationsTranslationEnum.OWNER, value: PersonRelationsTranslationEnum.OWNER},
  {label: PersonRelationsTranslationEnum.BUILDING_MANAGER, value: PersonRelationsTranslationEnum.BUILDING_MANAGER},
  {label: PersonRelationsTranslationEnum.ASSISTANT, value: PersonRelationsTranslationEnum.ASSISTANT},
  {label: PersonRelationsTranslationEnum.FRIEND, value: PersonRelationsTranslationEnum.FRIEND},
  {label: PersonRelationsTranslationEnum.NEIGHBOR, value: PersonRelationsTranslationEnum.NEIGHBOR},
  {label: PersonRelationsTranslationEnum.OTHER, value: PersonRelationsTranslationEnum.OTHER},
];

export const propertyTypeOptions = [
  {label: PropertyTypesTranslationEnum.APARTMENT, value: PropertyTypesEnum.APARTMENT},
  {label: PropertyTypesTranslationEnum.HOUSE, value: PropertyTypesEnum.HOUSE},
  {label: PropertyTypesTranslationEnum.LAND, value: PropertyTypesEnum.LAND},
  {label: PropertyTypesTranslationEnum.COMMERCIAL, value: PropertyTypesEnum.COMMERCIAL},
  // {label: PropertyTypesTranslationEnum.PARKING, value: PropertyTypesEnum.PARKING},
  // {label: PropertyTypesTranslationEnum.OTHER, value: PropertyTypesEnum.OTHER},
];

export const contractModeOptions = [
  {label: ContractTypesTranslationEnum.SALE, value: ContractTypesEnum.SALE},
  {label: ContractTypesTranslationEnum.MONTHLY, value: ContractTypesEnum.MONTHLY},
  {label: ContractTypesTranslationEnum.DAILY, value: ContractTypesEnum.DAILY},
];

export const rentOptions = [
  {label: ContractTypesTranslationEnum.MONTHLY, value: ContractTypesEnum.MONTHLY},
  {label: ContractTypesTranslationEnum.DAILY, value: ContractTypesEnum.DAILY},
];

export const currencyOptions = [
  {label: CurrencyTranslationEnum.AMD, value: CurrencyEnum.AMD},
  {label: CurrencyTranslationEnum.USD, value: CurrencyEnum.USD},
  {label: CurrencyTranslationEnum.RUB, value: CurrencyEnum.RUB},
  {label: CurrencyTranslationEnum.EUR, value: CurrencyEnum.EUR},
];

export const actionTypeOptions = [
  {label: PropertyActionsTypesTranslationEnum.OFFER, value: PropertyActionsTypesEnum.OFFER},
  {label: PropertyActionsTypesTranslationEnum.SEEK, value: PropertyActionsTypesEnum.SEEK},
  {label: PropertyActionsTypesTranslationEnum.OTHER, value: PropertyActionsTypesEnum.OTHER},
];

export const booleanOptions = [
  {label: 'Այո', value: 1},
  {label: 'Ոչ', value: 0},
];

export const roomNameOptions = [
  {label: RoomNameTranslationEnum.CORRIDOR, value: RoomNameTranslationEnum.CORRIDOR},
  {label: RoomNameTranslationEnum.LIVING_ROOM, value: RoomNameTranslationEnum.LIVING_ROOM},
  {label: RoomNameTranslationEnum.KITCHEN, value: RoomNameTranslationEnum.KITCHEN},
  {label: RoomNameTranslationEnum.BEDROOM, value: RoomNameTranslationEnum.BEDROOM},
  {label: RoomNameTranslationEnum.OTHER, value: RoomNameTranslationEnum.OTHER},
];

export const inquiryStateOptions = [
  {label: InquiryStateTranslationEnum.SEARCH, value: InquiryStateEnum.SEARCH, step: 1},
  {label: InquiryStateTranslationEnum.PRESENTATION, value: InquiryStateEnum.PRESENTATION, step: 2},
  {label: InquiryStateTranslationEnum.DEMONSTRATION, value: InquiryStateEnum.DEMONSTRATION, step: 3},
  {label: InquiryStateTranslationEnum.NEGOTIATIONS, value: InquiryStateEnum.NEGOTIATIONS, step: 4},
  {label: InquiryStateTranslationEnum.CONTRACT, value: InquiryStateEnum.CONTRACT, step: 5},
  {label: InquiryStateTranslationEnum.END, value: InquiryStateEnum.END, step: 6},
];
