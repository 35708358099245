export enum PopupSizesEnum {
  XXL = 'xxl',
  XL = 'xl',
  LG = 'lg',
  MD = 'md',
  SM = 'sm',
}

export enum PopupTypesEnum {
  PROMPT = 'prompt',
  CONFIRM = 'confirm',
  TEMPLATE = 'template',
}
