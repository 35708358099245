import {toFormData} from 'axios';

import {TServerResponse} from 'types/common';
import {Fetcher} from 'utils';
import {ApiUrl} from 'services/constants';
import {TAccount} from 'types/api';
import {LISTING_LENGTH, LISTING_OFFSET} from 'constants/pagination';

const fetcher = new Fetcher({baseURL: `${process.env.REACT_APP_API_URL}`});

export const getAccountsService = ({
  offset = LISTING_OFFSET,
  length = LISTING_LENGTH,
  ...params
}: {
  offset?: number;
  length: number;
}): Promise<TServerResponse<TAccount[]>> =>
  fetcher.request({
    url: ApiUrl.searchAccounts(offset, length),
    params,
  });

export const getAccountByIdService = ({id, ...params}: any): Promise<TServerResponse<TAccount>> =>
  fetcher.request({
    url: ApiUrl.getAccount(id),
    params,
  });

export const addAccountService = (data: any): Promise<TServerResponse<{id: number}>> =>
  fetcher.request({
    method: 'POST',
    headers: {
      'Content-Type': 'x-www-form-urlencoded',
    },
    url: ApiUrl.addAccount,
    data: toFormData(data),
  });

export const updateAccountService = (data: any): Promise<TServerResponse<boolean>> =>
  fetcher.request({
    method: 'POST',
    headers: {
      'Content-Type': 'x-www-form-urlencoded',
    },
    url: ApiUrl.updateAccount,
    data: toFormData(data),
  });
