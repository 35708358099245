export enum OptGroupsEnum {
  APPARTMENT_MARKERS = 'APPARTMENT_MARKERS',
  YARD_MARKERS = 'YARD_MARKERS',
  VIEW = 'VIEW',
  ENTRY_POSITION = 'ENTRY_POSITION',
  ENTRY_TYPE = 'ENTRY_TYPE',
  CONTRACT = 'CONTRACT',
  HEATING = 'HEATING',
  SOIL_IMPORTANCE = 'SOIL_IMPORTANCE',
  REPAIR_TYPES = 'REPAIR_TYPES',
  SOURCE = 'SOURCE',
  BUILDING_SUBTYPE_STONE = 'BUILDING_SUBTYPE_STONE',
  HOUSE_TYPES_PRIVATE = 'HOUSE_TYPES_PRIVATE',
}
