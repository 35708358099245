import {Icon} from 'assets/icons';
import {FlagsEnum, InquiryStateEnum, PropertyTypesEnum, StatusEnum} from 'enums';

export const pieIcons = {
  status: {
    [StatusEnum.NEW]: <Icon.Bookmark width={20} fill="white" />,
    [StatusEnum.CURRENT]: <Icon.Renew width={20} color="white" />,
    [StatusEnum.FINALIZED]: <Icon.Like width={20} fill="white" />,
    [StatusEnum.ONHOLD]: <Icon.Timer width={20} fill="white" />,
    [StatusEnum.ARCHIVED]: <Icon.Archive width={20} fill="white" />,
  },
  state: {
    [InquiryStateEnum.NEW]: <Icon.Bookmark width={20} fill="white" />,
    [InquiryStateEnum.SEARCH]: <Icon.Search width={20} color="white" />,
    [InquiryStateEnum.PRESENTATION]: <Icon.Monitor width={20} color="white" />,
    [InquiryStateEnum.DEMONSTRATION]: <Icon.Glass width={20} fill="white" />,
    [InquiryStateEnum.NEGOTIATIONS]: <Icon.People width={20} color="white" />,
    [InquiryStateEnum.CONTRACT]: <Icon.Book width={20} color="white" />,
    [InquiryStateEnum.END]: <Icon.TickCircle width={20} color="white" />,
  },
  flag: {
    [FlagsEnum.NO_ANSWER]: <Icon.CallSlash width={20} color="white" />,
    [FlagsEnum.RETRY_LATER]: <Icon.CallOutgoing width={20} fill="white" />,
    [FlagsEnum.UNAVAILABLE]: <Icon.NotAvailable width={20} color="white" />,
  },
  type: {
    [PropertyTypesEnum.APARTMENT]: <Icon.Apartment width={20} fill="white" />,
    [PropertyTypesEnum.COMMERCIAL]: <Icon.Building width={20} fill="white" />,
    [PropertyTypesEnum.LAND]: <Icon.Tree width={20} fill="white" />,
    [PropertyTypesEnum.HOUSE]: <Icon.House width={20} fill="white" />,
    [PropertyTypesEnum.PARKING]: <Icon.Car width={20} fill="white" />,
  },
  assignee: {
    default: <Icon.UserSquare width={20} color="white" />,
  },
  author: {
    default: <Icon.UserSquare width={20} color="white" />,
  },
};
