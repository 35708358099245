export const isWidgetNotValidated = ({
  fieldToCheck,
  isFieldHandled,
  querySelecor,
  setState,
  state,
}: {
  fieldToCheck?: any;
  isFieldHandled: boolean | undefined;
  querySelecor: string;
  state: any;
  setState: any;
}): boolean => {
  const isFieldExistedAndHandled =
    (fieldToCheck && !isFieldHandled && document.getElementById(querySelecor)) ||
    (!isFieldHandled && document.getElementById(querySelecor));

  if (isFieldExistedAndHandled) {
    setState((prev: any) => ({...prev, [state]: false}));

    document.getElementById(querySelecor) !== null &&
      document.getElementById(querySelecor)!.scrollIntoView({behavior: 'smooth', block: 'start'});
    return true;
  } else {
    return false;
  }
};
