import {notification} from 'antd';
import {ReactNode} from 'react';

import {NotificationTypesEnum} from 'enums';

type TNotification = {
  message?: string | ReactNode;
  description?: string | ReactNode;
  duration?: number | null;
  btn?: ReactNode;
  onClose?: () => void;
};

export const useToast = () => {
  const [api, contextHolder] = notification.useNotification();

  const showSuccess = ({message, ...rest}: TNotification) =>
    api[NotificationTypesEnum.SUCCESS]({
      message: message || 'Հաջողվեց',
      ...rest,
    });

  const showError = ({message, ...rest}: TNotification) =>
    api[NotificationTypesEnum.ERROR]({
      message: message || 'Ձախողում',
      ...rest,
    });

  const showWarning = ({message, ...rest}: TNotification) =>
    api[NotificationTypesEnum.WARNING]({message: message || 'Զգուշացում', ...rest});

  const showInfo = ({message, ...rest}: TNotification) =>
    api[NotificationTypesEnum.INFO]({
      message: message || 'Տեղեկություն',
      ...rest,
    });

  return {showSuccess, showError, showWarning, showInfo, contextHolder};
};
