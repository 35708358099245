export enum AddressFieldsEnum {
  COUNTRY_CODE = 'country_code',
  COUNTRY_NAME = 'country_name',
  REGION_NAME = 'region_name',
  SETTLEMENT_NAME = 'settlement_name',
  STREET_NAME = 'street_name',
  LAT = 'lat',
  LNG = 'lng',
  NUMBER = 'number',
  COMBINED = 'combined',
  DISTRICT_NAME = 'district_name',
  KEYWORD = 'keyword',
}
