import {takeEvery, all, call, put} from 'redux-saga/effects';

import {TPerson} from 'types/api';
import {TActionSettings, TServerResponseError} from 'types/common';
import {
  addPersonByBrokerService,
  addPersonByInfoService,
  getPersonByIdService,
  searchPersonsService,
  updatePersonByBrokerService,
  updatePersonByInfoService,
} from 'services/person';
import {RolesEnum} from 'enums';
import {getByIdFn} from 'store/core/sagas';

import {
  addPersonAction,
  clearCreatedPersonAction,
  clearCurrentPersonAction,
  clearPersonErrorsAction,
  clearSearchedPersonAction,
  getPersonByIdAction,
  searchPersonsAction,
  updatePersonAction,
} from './actions';
import {
  setErrors,
  setLoading,
  setPersons,
  setSearchedPerson,
  setPerson,
  setCurrentPerson,
  setCreatedPerson,
  setPersonErrors,
  setPersonsCount,
} from './slice-persons';

const searchPersonsSaga = function* ({
  payload: {...payload},
  settings: {isAttaching, isWithoutLoading, withCount = true, onFulfilled, onReject},
}: {payload: {offset: number; length: number}; settings: TActionSettings} & {type: string}) {
  yield !isWithoutLoading && put(setLoading(true));

  try {
    const response: {data: TPerson[]} = yield call(searchPersonsService, payload);

    yield put(isAttaching ? setSearchedPerson(response.data) : setPersons(response.data));

    if (withCount) {
      const count: {data: number} = yield call(searchPersonsService, {...payload, ...{cnt: ''}});

      yield put(setPersonsCount(count.data));
    }

    onFulfilled && onFulfilled();
  } catch (e) {
    console.error(e);
    yield put(setErrors(e as TServerResponseError));
    onReject && onReject(e);
  } finally {
    yield !isWithoutLoading && put(setLoading(false));
  }
};

const addPersonSaga = function* ({
  payload: {...payload},
  settings: {role, isWithoutLoading, onFulfilled, onReject},
}: {payload: TPerson; settings: TActionSettings} & {type: string}) {
  yield !isWithoutLoading && put(setLoading(true));

  try {
    const {data}: {data: {success: {id: number}; error: TServerResponseError}} = yield call(
      role === RolesEnum.INFO ? addPersonByInfoService : addPersonByBrokerService,
      payload
    );

    if (data.error) {
      throw data.error;
    } else {
      yield put(setPersonErrors(null));
      yield put(setPerson(data.success));
      yield put(setCreatedPerson(data.success));

      onFulfilled && onFulfilled();
    }
  } catch (e) {
    console.error(e);
    yield put(setPersonErrors(e as TServerResponseError));
    onReject && onReject(e);
  } finally {
    yield !isWithoutLoading && put(setLoading(false));
  }
};

const updatePersonSaga = function* ({
  payload: {...payload},
  settings: {role, isWithoutLoading, onFulfilled, onReject},
}: {payload: TPerson; settings: TActionSettings} & {type: string}) {
  yield !isWithoutLoading && put(setLoading(true));

  try {
    const {data}: {data: {success: {id: number}; error: TServerResponseError}} = yield call(
      role === RolesEnum.INFO ? updatePersonByInfoService : updatePersonByBrokerService,
      payload
    );

    if (data.error) {
      throw data.error;
    } else {
      yield put(setPersonErrors(null));
      yield put(setPerson(data.success));
      yield put(setCreatedPerson(data.success));

      onFulfilled && onFulfilled();
    }
  } catch (e) {
    console.error(e);
    yield put(setPersonErrors(e as TServerResponseError));
    onReject && onReject(e);
  } finally {
    yield !isWithoutLoading && put(setLoading(false));
  }
};

const getPersonByIdSaga = function* ({payload: {...payload}}: {payload: {id?: number}} & {type: string}) {
  yield getByIdFn<TPerson>({
    actions: {loading: setLoading, error: setErrors, success: setCurrentPerson},
    service: getPersonByIdService,
    payload,
    settings: {},
  });
};

const clearPersonErrorsSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setPersonErrors(null));
};

const clearCreatedPersonSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setPerson(null));
  yield put(setCreatedPerson(null));
};

const clearCurrentPersonSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setCurrentPerson(null));
};

const clearSearchedPersonSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setSearchedPerson(null));
};

export const personsSaga = function* () {
  yield all([
    takeEvery(searchPersonsAction().type, searchPersonsSaga),
    takeEvery(addPersonAction().type, addPersonSaga),
    takeEvery(updatePersonAction().type, updatePersonSaga),
    takeEvery(getPersonByIdAction().type, getPersonByIdSaga),
    takeEvery(clearPersonErrorsAction().type, clearPersonErrorsSaga),
    takeEvery(clearCreatedPersonAction().type, clearCreatedPersonSaga),
    takeEvery(clearCurrentPersonAction().type, clearCurrentPersonSaga),
    takeEvery(clearSearchedPersonAction().type, clearSearchedPersonSaga),
  ]);
};
