export enum ChangeBusinessProcessFieldsEnum {
  PROPERTY = 'property',
  DATE = 'date',
  START = 'start',
  TO = 'to',
  INFO = 'info',
  COMMENT = 'comment',
  COMMENTS = 'comments',
  ARCHIVE_INFO = 'archive_info',
}
