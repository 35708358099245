import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Image} from 'assets/images';
import {getTranslation, injectMediaPath} from 'utils';
import {BuildingTypesTranslationEnum} from 'enums/translations';
import {BuildingTypeCommonEnum, ImageViewEnum, PropertyTypesEnum} from 'enums';

import {TPropertyPDFDcoument} from './property-pdf-document.types';

const LocationIcon = () => (
  <svg width="30" height="30" viewBox="0 0 32 32" fill="none">
    <circle cx="16" cy="16" r="16" fill="#FFE7E7" />
    <path
      d="M16.0004 17.8084C14.2254 17.8084 12.7754 16.3667 12.7754 14.5834C12.7754 12.8 14.2254 11.3667 16.0004 11.3667C17.7754 11.3667 19.2254 12.8084 19.2254 14.5917C19.2254 16.375 17.7754 17.8084 16.0004 17.8084ZM16.0004 12.6167C14.9171 12.6167 14.0254 13.5 14.0254 14.5917C14.0254 15.6834 14.9087 16.5667 16.0004 16.5667C17.0921 16.5667 17.9754 15.6834 17.9754 14.5917C17.9754 13.5 17.0837 12.6167 16.0004 12.6167Z"
      fill="#FF1B1B"
    />
    <path
      d="M15.9994 24.9665C14.7661 24.9665 13.5244 24.4998 12.5577 23.5748C10.0994 21.2082 7.38275 17.4332 8.40775 12.9415C9.33275 8.8665 12.8911 7.0415 15.9994 7.0415C15.9994 7.0415 15.9994 7.0415 16.0077 7.0415C19.1161 7.0415 22.6744 8.8665 23.5994 12.9498C24.6161 17.4415 21.8994 21.2082 19.4411 23.5748C18.4744 24.4998 17.2327 24.9665 15.9994 24.9665ZM15.9994 8.2915C13.5744 8.2915 10.4577 9.58317 9.63275 13.2165C8.73275 17.1415 11.1994 20.5248 13.4327 22.6665C14.8744 24.0582 17.1327 24.0582 18.5744 22.6665C20.7994 20.5248 23.2661 17.1415 22.3827 13.2165C21.5494 9.58317 18.4244 8.2915 15.9994 8.2915Z"
      fill="#FF1B1B"
    />
  </svg>
);

const EditIcon = () => (
  <svg width="30" height="30" viewBox="0 0 32 32" fill="none">
    <circle cx="16" cy="16" r="16" fill="#F4F4F4" />
    <path
      d="M10.6176 22.2666C10.1093 22.2666 9.6343 22.0916 9.29263 21.7666C8.8593 21.3583 8.65097 20.7416 8.72597 20.075L9.0343 17.375C9.09263 16.8666 9.40097 16.1916 9.7593 15.825L16.601 8.58329C18.3093 6.77496 20.0926 6.72496 21.901 8.43329C23.7093 10.1416 23.7593 11.925 22.051 13.7333L15.2093 20.975C14.8593 21.35 14.2093 21.7 13.701 21.7833L11.0176 22.2416C10.876 22.25 10.751 22.2666 10.6176 22.2666ZM19.276 8.42496C18.6343 8.42496 18.076 8.82496 17.5093 9.42496L10.6676 16.675C10.501 16.85 10.3093 17.2666 10.276 17.5083L9.96763 20.2083C9.9343 20.4833 10.001 20.7083 10.151 20.85C10.301 20.9916 10.526 21.0416 10.801 21L13.4843 20.5416C13.726 20.5 14.126 20.2833 14.2926 20.1083L21.1343 12.8666C22.1676 11.7666 22.5426 10.75 21.0343 9.33329C20.3676 8.69163 19.7926 8.42496 19.276 8.42496Z"
      fill="#292D32"
    />
    <path
      d="M20.4497 15.125C20.433 15.125 20.408 15.125 20.3914 15.125C17.7914 14.8667 15.6997 12.8917 15.2997 10.3084C15.2497 9.9667 15.483 9.65004 15.8247 9.5917C16.1664 9.5417 16.483 9.77504 16.5414 10.1167C16.858 12.1334 18.4914 13.6834 20.5247 13.8834C20.8664 13.9167 21.1164 14.225 21.083 14.5667C21.0414 14.8834 20.7664 15.125 20.4497 15.125Z"
      fill="#292D32"
    />
    <path
      d="M23.5 24.9585H8.5C8.15833 24.9585 7.875 24.6752 7.875 24.3335C7.875 23.9918 8.15833 23.7085 8.5 23.7085H23.5C23.8417 23.7085 24.125 23.9918 24.125 24.3335C24.125 24.6752 23.8417 24.9585 23.5 24.9585Z"
      fill="#292D32"
    />
  </svg>
);

export const PropertyPDFDcoument = ({data}: {data: TPropertyPDFDcoument}) => {
  const {t} = useTranslation();
  const {
    type,
    area,
    address,
    totalArea,
    landArea,
    livingArea,
    floor,
    floorPlan,
    squareMetre,
    entranceFloor,
    building,
    bedrooms,
    usage,
    price,
    mode,
    addons,
    images,
    info,
  } = data;

  const filteredFiles = useCallback((folder: any) => {
    return folder?.files?.filter((f: any) => f.view === ImageViewEnum.ALL || f.view === ImageViewEnum.PRESENTATION);
  }, []);

  const mainImage = useMemo(
    () =>
      filteredFiles(images)?.length > 0
        ? injectMediaPath(filteredFiles(images)[0]?.path)
        : addons.length && filteredFiles(addons[0]?.image_folder)?.length > 0
        ? injectMediaPath(filteredFiles(addons[0]?.image_folder)[0]?.path)
        : Image.noImageProperty,
    [images, addons, filteredFiles]
  );

  const mainGallery = useMemo(() => filteredFiles(images), [images, filteredFiles]);

  return (
    <div className="px-[15px] bg-white relative z-20" id="pdf-document">
      <div className="flex justify-center py-[35px] px-[15px]">
        <img src={Image.brandLogo} alt="img" style={{width: 150}} />
      </div>

      <div>
        <div className="flex items-center justify-center gap-2 mb-4">
          <LocationIcon />
          <div className="text-[20px] font-semibold">{address}</div>
        </div>

        <div className="flex gap-5 items-start mb-2">
          <div className="relative my-2 max-w-[48%] flex-[0_0_48%]">
            <img src={mainImage} alt="img" className="w-full rounded-[14px]" />
          </div>
          <div className="max-w-[48%] flex-[0_0_48%] mt-[10px]">
            {livingArea && type === PropertyTypesEnum.HOUSE ? (
              <div className="pb-3 mb-3 flex justify-between items-center border-solid border-t-0 border-l-0 border-r-0 border-b border-grey-60">
                <div className="text-[15px] text-grey-100">{t('form.livingArea')}</div>
                <div className="text-[15px] font-medium text-grey-common">
                  {livingArea} {t('shorts.squareMeter')}
                </div>
              </div>
            ) : null}
            {landArea && type === PropertyTypesEnum.HOUSE ? (
              <div className="pb-3 mb-3 flex justify-between items-center border-solid border-t-0 border-l-0 border-r-0 border-b border-grey-60">
                <div className="text-[15px] text-grey-100">{t('form.landArea')}</div>
                <div className="text-[15px] font-medium text-grey-common">
                  {landArea} {t('shorts.squareMeter')}
                </div>
              </div>
            ) : null}
            {area || totalArea || squareMetre ? (
              <div className="pb-3 mb-3 flex justify-between items-center border-solid border-t-0 border-l-0 border-r-0 border-b border-grey-60">
                <div className="text-[15px] text-grey-100">{t('form.area')}</div>
                <div className="text-[15px] font-medium text-grey-common">
                  {type === PropertyTypesEnum.LAND ? squareMetre : area || totalArea} {t('shorts.squareMeter')}
                </div>
              </div>
            ) : null}
            {floorPlan || floor ? (
              <div className="pb-3 mb-3 flex justify-between items-center border-solid border-t-0 border-l-0 border-r-0 border-b border-grey-60">
                <div className="text-[15px] text-grey-100">
                  {type === PropertyTypesEnum.APARTMENT ? t('form.floor') : t('form.floors')}
                </div>
                <div className="text-[15px] font-medium text-grey-common">
                  {type === PropertyTypesEnum.APARTMENT
                    ? `${building.floors !== null ? building.floors + '/' : ''}${floor}`
                    : floorPlan || floor}
                </div>
              </div>
            ) : null}
            {entranceFloor ? (
              <div className="pb-3 mb-3 flex justify-between items-center border-solid border-t-0 border-l-0 border-r-0 border-b border-grey-60">
                <div className="text-[15px] text-grey-100">{t('form.entranceFloor')}</div>
                <div className="text-[15px] font-medium text-grey-common">{entranceFloor}</div>
              </div>
            ) : null}
            {usage ? (
              <div className="pb-3 mb-3 flex justify-between items-center border-solid border-t-0 border-l-0 border-r-0 border-b border-grey-60">
                <div className="text-[15px] text-grey-100">{t('form.usage')}</div>
                <div className="text-[15px] font-medium text-grey-common">{usage}</div>
              </div>
            ) : null}
            {bedrooms ? (
              <div className="pb-3 mb-3 flex justify-between items-center border-solid border-t-0 border-l-0 border-r-0 border-b border-grey-60">
                <div className="text-[15px] text-grey-100">
                  {type === PropertyTypesEnum.HOUSE ? t('form.bedrooms') : t('form.rooms')}
                </div>
                <div className="text-[15px] font-medium text-grey-common">{bedrooms}</div>
              </div>
            ) : null}
            {building?.type === BuildingTypeCommonEnum.RESIDENTIAL ? (
              <div className="pb-3 mb-3 flex justify-between items-center border-solid border-t-0 border-l-0 border-r-0 border-b border-grey-60">
                <div className="text-[15px] text-grey-100">{t('form.buildingType')}</div>
                <div className="text-[15px] font-medium text-grey-common">
                  {getTranslation(BuildingTypesTranslationEnum, building?.residential?.subtype)}
                </div>
              </div>
            ) : null}
            {mode ? (
              <div className="pb-3 mb-3 flex justify-between items-center border-solid border-t-0 border-l-0 border-r-0 border-b border-grey-60">
                <div className="text-[15px] text-grey-100">{t('form.contractType')}</div>
                <div className="text-[15px] font-medium text-grey-common">{mode}</div>
              </div>
            ) : null}
            {price ? (
              <div className="pb-3 mb-3 flex justify-between items-center">
                <div className="text-[15px] text-grey-100">{t('form.price')}</div>
                <div className="text-[15px] font-medium text-grey-common">{price}</div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="mb-2">
          {info ? (
            <div className="border border-solid border-grey-60 p-[15px] rounded-[15px]">
              <div className="flex items-center gap-2.5 mb-[10px]">
                <EditIcon />
                <div className="font-demibold">{t('form.addintionalNotes')}</div>
              </div>

              <div className="text-[14px] text-grey-100 text-justify">{info}</div>
            </div>
          ) : null}
        </div>

        {mainGallery?.length > 0 ? (
          <div
            className="flex gap-[16px] my-2"
            style={{
              pageBreakAfter: 'always',
            }}
          >
            {mainGallery?.slice(1)?.map((file: any) => (
              <div className="relative my-2 max-w-[48%] flex-[0_0_48%]" key={file.name}>
                <img src={injectMediaPath(file.path)} alt="img" className="w-full rounded-[14px]" />
              </div>
            ))}
          </div>
        ) : null}

        {addons?.length
          ? addons.map(({name, id, image_folder}, i) =>
              filteredFiles(image_folder)?.length > 0 ? (
                <div
                  className="my-2.5"
                  style={{
                    pageBreakInside: 'avoid',
                  }}
                  key={`${name}-${id}`}
                >
                  <div className="flex items-center gap-2.5 mb-[10px]">
                    <div className="text-[20px] font-semibold">{name}</div>
                  </div>
                  <div className="flex flex-wrap gap-4">
                    {filteredFiles(image_folder)?.map((file: any) => (
                      <div className="relative my-2 max-w-[48%] flex-[0_0_48%]" key={file.name}>
                        <img src={injectMediaPath(file.path)} alt="img" className="w-full rounded-[14px]" />
                      </div>
                    ))}
                  </div>
                </div>
              ) : null
            )
          : null}
      </div>
    </div>
  );
};
