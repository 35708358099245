import {Col, Form, FormInstance, Row} from 'antd';
import {useTranslation} from 'react-i18next';

import {PInput} from 'components/primitives/p-input';
import {FormsEnum} from 'enums';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {PRangepicker} from 'components/primitives/p-rangepicker';
import {dateDefaultFormat} from 'constants/date-formats';

import {IBookingFields} from './form-booking.interfaces';
import {BookingFieldsEnum} from './form-booking.enums';

export const FormBooking = ({
  form,
  values,
  initialValues,
  onSumbit,
}: {
  form: FormInstance<IBookingFields>;
  values: IBookingFields;
  initialValues?: IBookingFields;
  onSumbit: (v: IBookingFields) => void;
}) => {
  const {t} = useTranslation();

  return (
    <Form form={form} onFinish={onSumbit} initialValues={initialValues} name={FormsEnum.BOOKING}>
      <Row gutter={20}>
        <Col span={24}>
          <Form.Item
            name={BookingFieldsEnum.DATE}
            rules={[{required: true, message: fieldsMessageTranslations.common}]}
          >
            <PRangepicker
              label={t('form.date')}
              size="large"
              format={dateDefaultFormat}
              placeholder={[t('form.start'), t('form.end')]}
            />
          </Form.Item>
          <Form.Item
            name={BookingFieldsEnum.INFO}
            rules={[{required: false, message: fieldsMessageTranslations.common}]}
          >
            <PInput label={t('form.comments')} isTextarea rows={4} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
