import {ContractTypesEnum, CustomerTypesEnum, PropertyActionsTypesEnum} from 'enums';

export const injectedPersonBinding = ({
  value,
}: {
  value: CustomerTypesEnum | string | null | undefined;
}): Record<string, string[] | string | null> => {
  return value
    ? {
        binding:
          value === CustomerTypesEnum.CUSTOMER || value === CustomerTypesEnum.RENTER
            ? CustomerTypesEnum.INQUIRY
            : value === CustomerTypesEnum.SALE || value === CustomerTypesEnum.RENT
            ? CustomerTypesEnum.PROPERTY
            : value,
        action:
          value === CustomerTypesEnum.CUSTOMER || value === CustomerTypesEnum.RENTER
            ? PropertyActionsTypesEnum.SEEK
            : value === CustomerTypesEnum.SALE || value === CustomerTypesEnum.RENT
            ? PropertyActionsTypesEnum.OFFER
            : null,
        'mode[]':
          value === CustomerTypesEnum.CUSTOMER
            ? [ContractTypesEnum.ALL, ContractTypesEnum.SALE]
            : value === CustomerTypesEnum.RENTER
            ? [ContractTypesEnum.ALL, ContractTypesEnum.RENT]
            : value === CustomerTypesEnum.RENT
            ? [ContractTypesEnum.ALL, ContractTypesEnum.RENT]
            : value === CustomerTypesEnum.SALE
            ? [ContractTypesEnum.ALL, ContractTypesEnum.SALE]
            : null,
        'rent[]':
          value === CustomerTypesEnum.RENT || value === CustomerTypesEnum.RENTER
            ? [ContractTypesEnum.ALL, ContractTypesEnum.MONTHLY, ContractTypesEnum.DAILY]
            : null,
      }
    : {};
};
