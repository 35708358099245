import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {TServerResponseError} from 'types/common';

import {AuthState} from './types';

const sliceAuth = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    error: null,
    account: null,
  },
  reducers: {
    setErrors(state: AuthState, {payload}: PayloadAction<TServerResponseError | null>) {
      state.error = payload;
    },
    setLoading(state: AuthState, {payload}: PayloadAction<AuthState['loading']>) {
      state.loading = payload;
    },
    setAccount(state: AuthState, {payload}: PayloadAction<AuthState['account']>) {
      state.account = payload;
    },
  },
});

export const {setErrors, setLoading, setAccount} = sliceAuth.actions;

export const authReducer = sliceAuth.reducer;
