export enum PButtonSchemeEnum {
  DANGER = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success',
}

export enum PButtonThemeEnum {
  DEFAULT = 'default',
  WHITE = 'white',
}
