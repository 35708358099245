import {memo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {Icon} from 'assets/icons';
import {PButton} from 'components/primitives/p-button';
import {usePopup} from 'hooks';
import {PopupGeneratePDF} from 'components/popups/generate-pdf';

const CPdfGenerate = () => {
  const {t} = useTranslation();
  const {show} = usePopup();

  const handleGeneratePopup = useCallback(() => {
    show(PopupGeneratePDF);
  }, [show]);

  return (
    <div>
      <PButton
        type="text"
        theme="white"
        icon={<Icon.DocumentDownload stroke="currentColor" />}
        onClick={handleGeneratePopup}
      >
        {t('actions.generate')} PDF
      </PButton>
    </div>
  );
};

export const CPdfGenerateMemoized = memo(CPdfGenerate);
