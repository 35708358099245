import {TActionSettings} from 'types/common';

export const addPropertyAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/addPropertyAction',
  payload,
  settings: settings || {},
});

export const updatePropertyAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/updatePropertyAction',
  payload,
  settings: settings || {},
});

export const searchPropertiesAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'shared/searchPropertiesAction',
  payload,
  settings: settings || {},
});

export const getPropertyByIdAction = (payload?: {id?: number | string}, settings?: TActionSettings) => ({
  type: 'shared/getPropertyByIdAction',
  payload,
  settings: settings || {},
});

export const addPropertyHoldAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/addPropertyHoldAction',
  payload,
  settings: settings || {},
});

export const archivePropertyAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/archivePropertyAction',
  payload,
  settings: settings || {},
});

export const updatePropertyPriceAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/updatePropertyPriceAction',
  payload,
  settings: settings || {},
});

export const updatePropertyCommentAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/updatePropertyCommentAction',
  payload,
  settings: settings || {},
});

export const refreshPropertyPriceAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/refreshPropertyPriceAction',
  payload,
  settings: settings || {},
});

export const removePropertyHoldAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/removePropertyHoldAction',
  payload,
  settings: settings || {},
});

export const disablePropertyAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/disablePropertyAction',
  payload,
  settings: settings || {},
});

export const enablePropertyAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/broker/enablePropertyAction',
  payload,
  settings: settings || {},
});

export const savePropertyFilterPresetsAction = (payload?: any) => ({
  type: 'properties/savePropertyFilterPresetsAction',
  payload,
});

export const hasUnsavedChangesAction = (payload?: boolean) => ({
  type: 'properties/hasUnsavedChangesAction',
  payload,
});

export const clearPropertiesErrorsAction = () => ({
  type: 'properties/clearPropertiesErrorsAction',
});

export const clearPropertiesAction = () => ({
  type: 'properties/clearPropertiesAction',
});

export const clearCurrentPropertyAction = () => ({
  type: 'properties/clearCurrentPropertyAction',
});
