import {ContractTypesEnum, CustomerTypesEnum} from 'enums';

export const injectedContractMode = (
  value: ContractTypesEnum[] | string[] | null | undefined,
  type?: 'multiple' | 'single'
): Record<string, ContractTypesEnum[] | CustomerTypesEnum[] | null> => {
  const isModeSale =
    value?.includes(ContractTypesEnum.SALE) &&
    !value?.includes(ContractTypesEnum.MONTHLY) &&
    !value?.includes(ContractTypesEnum.DAILY);
  const isModeSaleAndMonthly =
    value?.includes(ContractTypesEnum.SALE) &&
    value?.includes(ContractTypesEnum.MONTHLY) &&
    !value?.includes(ContractTypesEnum.DAILY);
  const isModeSaleAndDaily =
    value?.includes(ContractTypesEnum.SALE) &&
    !value?.includes(ContractTypesEnum.MONTHLY) &&
    value?.includes(ContractTypesEnum.DAILY);
  const isModeMonthly =
    !value?.includes(ContractTypesEnum.SALE) &&
    value?.includes(ContractTypesEnum.MONTHLY) &&
    !value?.includes(ContractTypesEnum.DAILY);
  const isModeDaily =
    !value?.includes(ContractTypesEnum.SALE) &&
    !value?.includes(ContractTypesEnum.MONTHLY) &&
    value?.includes(ContractTypesEnum.DAILY);
  const isModeMonthlyAndDaily =
    !value?.includes(ContractTypesEnum.SALE) &&
    value?.includes(ContractTypesEnum.MONTHLY) &&
    value?.includes(ContractTypesEnum.DAILY);

  const mode = isModeSale
    ? [CustomerTypesEnum.ALL, CustomerTypesEnum.SALE]
    : isModeSaleAndDaily
    ? [ContractTypesEnum.ALL, ContractTypesEnum.SALE, ContractTypesEnum.RENT]
    : isModeMonthly
    ? [CustomerTypesEnum.ALL, CustomerTypesEnum.RENT]
    : isModeDaily
    ? [CustomerTypesEnum.ALL, CustomerTypesEnum.RENT]
    : isModeMonthlyAndDaily
    ? [CustomerTypesEnum.ALL, CustomerTypesEnum.RENT]
    : isModeSaleAndMonthly
    ? null
    : null;

  const rent =
    !value?.includes(ContractTypesEnum.MONTHLY) && value?.includes(ContractTypesEnum.DAILY)
      ? [ContractTypesEnum.ALL, ContractTypesEnum.DAILY]
      : null;

  return {mode, rent};
};
