import {takeEvery, all, call, put} from 'redux-saga/effects';

import {TActionSettings, TServerResponseError} from 'types/common';
import {TAccount} from 'types/api';
import {addAccountService, getAccountByIdService, getAccountsService, updateAccountService} from 'services/admin';
import {getByIdFn} from 'store/core/sagas';

import {
  addAccountAction,
  clearAccountAction,
  clearAccountsErrorsAction,
  getAccountByIdAction,
  getAccountsAction,
  updateAccountAction,
} from './actions';
import {setErrors, setLoading, setAccount, setCurrentAccount, setAccounts, setAccountsCount} from './slice-accounts';

const addAccountSaga = function* ({
  payload: {...payload},
  settings: {onFulfilled, onReject},
}: {payload: TAccount; settings: TActionSettings} & {type: string}) {
  try {
    const {data}: {data: {success: number; error: TServerResponseError}} = yield call(addAccountService, payload);

    if (data.error) {
      throw data.error;
    } else {
      yield put(setErrors(null));
      yield put(setAccount(data.success));
      onFulfilled && onFulfilled();
    }
  } catch (e) {
    console.error(e);
    yield put(setErrors(e as TServerResponseError));
    onReject && onReject(e);
  }
};

const updateAccountSaga = function* ({
  payload: {...payload},
  settings: {onFulfilled, onReject},
}: {payload: TAccount; settings: TActionSettings} & {type: string}) {
  try {
    const {data}: {data: {success: boolean; error: TServerResponseError}} = yield call(updateAccountService, payload);

    if (data.error) {
      throw data.error;
    } else {
      yield put(setErrors(null));
      yield put(setAccount(data.success));
      onFulfilled && onFulfilled();
    }
  } catch (e) {
    console.error(e);
    yield put(setErrors(e as TServerResponseError));
    onReject && onReject(e);
  }
};

const getAccountByIdSaga = function* ({
  payload: {...payload},
  settings: {...settings},
}: {payload: {id: number}; settings: TActionSettings} & {type: string}) {
  yield getByIdFn<TAccount>({
    actions: {loading: setLoading, error: setErrors, success: setCurrentAccount},
    service: getAccountByIdService,
    payload,
    settings,
  });
};

const getAccountsSaga = function* ({
  payload: {...payload},
  settings: {withCount = true, onFulfilled, onReject},
}: {payload: {offset: number; length: number}; settings: TActionSettings} & {type: string}) {
  yield put(setLoading(true));

  try {
    const response: {data: TAccount[]} = yield call(getAccountsService, payload);

    yield put(setAccounts(response.data));

    if (withCount) {
      const count: {data: number} = yield call(getAccountsService, {...payload, ...{cnt: ''}});

      yield put(setAccountsCount(count.data));
    }

    onFulfilled && onFulfilled();
  } catch (e) {
    console.error(e);
    yield put(setErrors(e as TServerResponseError));
    onReject && onReject(e);
  } finally {
    yield put(setLoading(false));
  }
};

const clearAccountsErrorsSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setErrors(null));
};

const clearAccountSaga = function* ({payload}: {payload: {}} & {type: string}) {
  yield put(setAccount(null));
};

export const accountsSaga = function* () {
  yield all([
    takeEvery(addAccountAction().type, addAccountSaga),
    takeEvery(updateAccountAction().type, updateAccountSaga),
    takeEvery(getAccountByIdAction().type, getAccountByIdSaga),
    takeEvery(getAccountsAction().type, getAccountsSaga),
    takeEvery(clearAccountsErrorsAction().type, clearAccountsErrorsSaga),
    takeEvery(clearAccountAction().type, clearAccountSaga),
  ]);
};
