import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import debounce from 'lodash.debounce';
import {useTranslation} from 'react-i18next';

import {PSelect} from 'components/primitives/p-select';
import {PLoader} from 'components/primitives/p-loader';

import {ICSelectAsyncProps} from './c-select-async.types';

const CSelectAsync = ({
  options,
  placeholder,
  isSearchDropdownOpen,
  dispatchActionParams,
  notFoundContentAction,
  disableFetchCondition,
  initialValue,
  name,
  onSelect,
  onClear,
  dispatchAction,
  ...props
}: ICSelectAsyncProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [searchedKeyword, setSearchedKeyword] = useState<string | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onKeyUp = useMemo(
    () =>
      debounce((e: any) => {
        setSearchedKeyword(e.target.value);
      }, 500),
    []
  );

  const onSearch = useCallback(() => {
    setIsLoading(true);
  }, []);

  const onDropdownVisibleChange = useCallback(
    (open: boolean) => {
      if (!open) return;

      setIsLoading(true);

      dispatchAction && dispatch(dispatchAction({...dispatchActionParams, length: 100}));
    },
    [dispatch, dispatchAction, dispatchActionParams]
  );

  const handleSelection = useCallback(
    (value: any, option: any) => {
      onSelect && onSelect();
    },
    [onSelect]
  );

  const handleClear = useCallback(() => {
    setSearchedKeyword('');
    onClear && onClear();
  }, [onClear]);

  useEffect(() => {
    if (disableFetchCondition) return;

    const keyword = !searchedKeyword?.length ? null : {keyword: searchedKeyword};

    dispatchAction &&
      dispatch(
        dispatchAction(
          {...dispatchActionParams, ...keyword, length: 100},
          {onFulfilled: () => setIsLoading(false), onReject: () => setIsLoading(false)}
        )
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedKeyword, dispatch]);

  useEffect(() => {
    setIsLoading(false);
  }, [options]);

  return (
    <PSelect
      {...props}
      size="large"
      defaultValue={initialValue}
      open={isSearchDropdownOpen}
      placeholder={placeholder}
      loading={isLoading}
      showSearch
      options={options}
      filterOption={false}
      labelInValue
      onKeyUp={onKeyUp}
      onSearch={onSearch}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onSelect={handleSelection}
      onClear={handleClear}
      dropdownRender={
        isLoading
          ? () => (
              <div className="py-3">
                <PLoader />
              </div>
            )
          : undefined
      }
      notFoundContent={
        <div className="flex flex-col justify-center items-center pb-2 pt-2">
          <div className="text-center text-grey-80">{t('info.noInfo')}</div>
          {notFoundContentAction}
        </div>
      }
    ></PSelect>
  );
};

export const CSelectAsyncMemoized = memo(CSelectAsync);
