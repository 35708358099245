import {TServerResponse} from 'types/common';
import {Fetcher} from 'utils';
import {ApiUrl} from 'services/constants';
import {TAnalyticsPerformance, TInquiryTotals, TLeadTotals, TPropertyTotals} from 'types/api';

const fetcher = new Fetcher({baseURL: `${process.env.REACT_APP_API_URL}`});

export const getPropertyTotalsService = ({...params}: any): Promise<TServerResponse<TPropertyTotals>> =>
  fetcher.request({
    url: ApiUrl.getPropertyTotals,
    params,
  });

export const getInquiryTotalsService = ({...params}: any): Promise<TServerResponse<TInquiryTotals>> =>
  fetcher.request({
    url: ApiUrl.getInquiryTotals,
    params,
  });

export const getLeadTotalsService = ({...params}: any): Promise<TServerResponse<TLeadTotals>> =>
  fetcher.request({
    url: ApiUrl.getLeadTotals,
    params,
  });

export const getPropertyPerformanceService = ({...params}: any): Promise<TServerResponse<TAnalyticsPerformance[]>> =>
  fetcher.request({
    url: ApiUrl.getPropertyPerformance,
    params,
  });

export const getInquiryPerformanceService = ({...params}: any): Promise<TServerResponse<TAnalyticsPerformance[]>> =>
  fetcher.request({
    url: ApiUrl.getInquiryPerformance,
    params,
  });

export const getLeadPerformanceService = ({...params}: any): Promise<TServerResponse<TAnalyticsPerformance[]>> =>
  fetcher.request({
    url: ApiUrl.getLeadPerformance,
    params,
  });
