import {createSelector} from '@reduxjs/toolkit';

import {State} from 'store/types';

import {PropertiesState} from './types';

const getState = (state: State): PropertiesState => state.properties;

export const propertiesLoadingSelector = createSelector(getState, state => state.loading);
export const propertiesErrorSelector = createSelector(getState, state => state.error);
export const propertiesListSelector = createSelector(getState, state => state.properties);
export const propertiesCountSelector = createSelector(getState, state => state.propertiesCount);
export const propertyCurrentSelector = createSelector(getState, state => state.currentProperty);
export const archivedPropertySelector = createSelector(getState, state => state.archivedProperty);
export const holdedPropertySelector = createSelector(getState, state => state.holdedProperty);
export const propertyUpdatedPriceSelector = createSelector(getState, state => state.updatedPrice);
export const propertyRefreshedPriceSelector = createSelector(getState, state => state.refreshedPrice);
export const propertyUpdatedCommentSelector = createSelector(getState, state => state.updatedComment);
export const propertyRemovedHoldSelector = createSelector(getState, state => state.removedHold);
export const propertyFilterPresetsSelector = createSelector(getState, state => state.propertyFilterPresets);
export const propertyHasUnsavedChangesSelector = createSelector(getState, state => state.hasUnsavedChanges);
export const propertyDisabledSelector = createSelector(getState, state => state.disabled);
export const propertyEnabledSelector = createSelector(getState, state => state.enabled);
