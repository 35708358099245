const findObjectInArrayByKey = ({
  targetArray,
  key,
  value,
}: {
  targetArray: Array<any>;
  key: string;
  value: string | number;
}) => {
  for (let i = 0; i < targetArray?.length; i++) {
    if (targetArray[i][key] === value) {
      return targetArray[i];
    }
  }
};

export const findObjectByKeys = ({
  options,
  state,
  key,
}: {
  options: Array<any>;
  state: Array<any>;
  key: string;
}): number[] => options?.filter((value: string) => findObjectInArrayByKey({targetArray: state, key, value}));
