export enum SortFieldsEnum {
  PROPERTY_CREATED = 'property_created',
  PROPERTY_MODIFIED = 'property_modified',
  PRICE_CREATED = 'price_created',
  PRICE_TOTAL_SALE = 'price_total_sale',
  PRICE_TOTAL_MONTHLY = 'price_total_monthly',
  PRICE_TOTAL_DAILY = 'price_total_daily',
  PRICE_UNIT_SALE = 'price_unit_sale',
  PRICE_UNIT_MONTHLY = 'price_unit_monthly',
  PRICE_UNIT_DAILY = 'price_unit_daily',
  PA_AREA = 'pa_area',
  PC_TOTAL_AREA = 'pc_total_area',
  PC_COMMERCIAL_AREA = 'pc_commercial_area',
  PC_LAND_AREA = 'pc_land_area',
  PH_LIVING_AREA = 'ph_living_area',
  PH_LAND_AREA = 'ph_land_area',
  PL_SQUARE_METRE = 'pl_square_metre',
  PL_HECTARE = 'pl_hectare',
  LEAD_CREATED = 'lead_created',
  LEAD_MODIFIED = 'lead_modified',
}
