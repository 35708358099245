import {memo} from 'react';

import {PInfoBarProps} from './p-info-bar.types';

const PInfoBar = ({children, className, label, value, ...props}: PInfoBarProps) => {
  return (
    <div
      className={`border-solid border-t-0 border-l-0 border-r-0 border-b border-grey-60 last:border-0 py-4 text-[14px] grid grid-cols-2 ${
        className || ''
      }`}
      {...props}
    >
      {label && <div className="font-semibold">{label}</div>}
      <div className={label && 'text-right leading-[130%] overflow-hidden'}>{value}</div>
      {children}
    </div>
  );
};

export const PInfoBarMemoized = memo(PInfoBar);
