import {memo} from 'react';
import {Tooltip} from 'antd';

import {Image} from 'assets/images';

import {PUserProps} from './p-user.types';
import {CSS} from './p-user.cssconfig';

const PUser = ({name, role, avatar, open, onClick}: PUserProps) => {
  return (
    <div className={CSS.root}>
      <Tooltip placement="right" title={open ? '' : `${name} / ${role}`}>
        <img src={avatar ? avatar : Image.noImageAvatar} alt="" width={50} height={50} className={CSS.avatar} />
      </Tooltip>

      {open && (
        <>
          <div className={CSS.name}>{name}</div>
          <div className={CSS.role}>{role}</div>
        </>
      )}
    </div>
  );
};

export const PUserMemoized = memo(PUser);
