export const CSS = {
  layer:
    'fixed top-0 left-0 right-0 bottom-0 z-30 bg-black/[0.11] backdrop-blur-[4.5px] flex justify-center items-start p-[2%] overflow-auto',
  inner: {
    base: 'bg-white rounded-md shadow-modal p-[26px] w-full',
    sizeXXL: 'max-w-popup-xxl',
    sizeXL: 'max-w-popup-xl',
    sizeLG: 'max-w-popup-lg',
    sizeMD: 'max-w-popup-md',
    sizeSM: 'max-w-popup-sm',
  },
  closeIcon: 'hover:text-primary transition-all cursor-pointer',
  header: 'flex items-top justify-between items-center mb-7',
  title: 'text-md font-bold',
  buttons: {
    base: 'flex gap-5',
    prompt: 'grid grid-cols-2 gap-5',
  },
  content: 'mb-3',
} as const;
