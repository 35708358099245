export enum PropertyFieldsEnum {
  CODE = 'code',
  CONTRACT = 'contract',
  CREATED = 'created',
  MODIFIED = 'modified',
  PROPERTY_CREATED = 'property_created',
  PROPERTY_MODIFIED = 'property_modified',
  LOCATION = 'location',
  ADDRESS = 'address',
  NUMBER = 'number',
  BUILDING = 'building',
  SQUARE_METRE = 'square_metre',
  HECTARE = 'hectare',
  LIVING_AREA = 'living_area',
  LAND_AREA = 'land_area',
  AREA = 'area',
  ROOMS = 'rooms',
  BEDROOMS = 'bedrooms',
  FLOOR = 'floor',
  FLOORS = 'floors',
  FLOOR_PLAN = 'floor_plan',
  ENTRANCE_FLOOR = 'entrance_floor',
  ENTRANCE_COUNT = 'entrance_count',
  ENTRY_POSITION = 'entry_position',
  ENTRY_TYPE = 'entry_type',
  PRICE = 'price',
  PRICES = 'prices',
  RENT = 'rent',
  REPAIR = 'repair',
  INFO = 'info',
  MODE = 'mode',
  IMAGE_FOLDER = 'image_folder',
  FILE_FOLDER = 'file_folder',
  STATUS = 'status',
  USAGE = 'usage',
  FRONT_LENGTH = 'front_length',
  CADASTRE_NUMBER = 'cadastre_number',
  COMMENTS = 'comments',
  PERSONS = 'persons',
  BATHROOMS = 'bathrooms',
  TOTAL_PRICE = 'total_price',
  TOTAL_PRICE_SALE = 'total_price_sale',
  TOTAL_PRICE_MONTHLY = 'total_price_monthly',
  TOTAL_PRICE_DAILY = 'total_price_daily',
  UNIT_PRICE = 'unit_price',
  UNIT_PRICE_SALE = 'unit_price_sale',
  UNIT_PRICE_MONTHLY = 'unit_price_monthly',
  UNIT_PRICE_DAILY = 'unit_price_daily',
  CURRENCY_SALE = 'currency_sale',
  CURRENCY_MONTHLY = 'currency_monthly',
  CURRENCY_DAILY = 'currency_daily',
  COMMERCIAL_AREA = 'commercial_area',
  TOTAL_AREA = 'total_area',
  AVAILABLE_FROM = 'available_from',
  AVAILABLE_TO = 'available_to',
  HEATING = 'heating',
  DIVISIBLE = 'divisible',
  IS_BUILDING = 'is_building',
  PARKING = 'parking',
  CAFE = 'cafe',
  BALCONIES = 'balconies',
  COMMERCIAL_NUMBER = 'commercial_number',
  TITLE = 'title',
  STREET = 'street',
  BUILDING_NUMBER = 'building_numbers',
  APARTMENT_NUMBER = 'apartment_number',
  HOLDS = 'holds',
  YOUTUBE = 'youtube',
  TOUR = '3d',
  GLASS = 'glass',
  OPTIONS = 'options',
  TYPE = 'type',
  SUB_TYPE = 'sub_type',
  VIEW = 'view',
  YARD_MARKERS = 'yard_markers',
  YARD_INFO = 'yard_info',
  YARD_GALLERY = 'yard_gallery',
  STATE = 'state',
  ARCHIVE_INFO = 'archive_info',
  MARKETING = 'marketing',
  WEBSITE = 'website',
  SEARCHED_BUILDING = 'searched_building',
  PARKINGS = 'parkings',
  BOOKMARKED = 'bookmarked',
  DISABLED_TO = 'disabled_to',
  DISABLED_COMMENT = 'disabled_comment',

  MAIN_IMAGE = 'mainImage',
  TITLE_HY = 'title_hy',
  TITLE_EN = 'title_en',
  TITLE_RU = 'title_ru',
  INFO_HY = 'info_hy',
  INFO_EN = 'info_en',
  INFO_RU = 'info_ru',
  ALT_HY = 'alt_hy',
  ALT_EN = 'alt_en',
  ALT_RU = 'alt_ru',
  META_HY = 'meta_hy',
  META_EN = 'meta_en',
  META_RU = 'meta_ru',
  TAB_HY = 'tab_hy',
  TAB_EN = 'tab_en',
  TAB_RU = 'tab_ru',
  KEYWORD_HY = 'keyword_hy',
  KEYWORD_EN = 'keyword_en',
  KEYWORD_RU = 'keyword_ru',
}
