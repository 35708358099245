import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Divider, Row} from 'antd';
import {v4 as uuidv4} from 'uuid';
import {useTranslation} from 'react-i18next';

import {getAccountsAction} from 'store/account/actions';
import {Icon} from 'assets/icons';
import {PButton} from 'components/primitives/p-button';
import {PCardWrapper} from 'components/primitives/p-card-wrapper';
import {PLoader} from 'components/primitives/p-loader';
import {usePopup, useToast} from 'hooks';
import {appProfileSelector} from 'store/app/selectors';
import {PPageTitle} from 'components/primitives/p-page-title';
import {PTable} from 'components/primitives/p-table';
import {ActionModesEnum, PaginationEnum} from 'enums';
import {ActionModeHero} from 'constants/translations';
import {IAccountFields} from 'components/forms/account/form-account.interfaces';
import {FormAccountsFilter} from 'components/forms/accounts-filter';
import {getTranslation} from 'utils';
import {TPaginationOptions, TSortOptions} from 'types/common';
import {LISTING_LENGTH, LISTING_OFFSET} from 'constants/pagination';
import {
  accountSelector,
  accountsCountSelector,
  accountsListSelector,
  accountsLoadingSelector,
} from 'store/account/selectors';
import {PopupAccount} from 'components/popups/account';

import {columns} from './constants/columns';

export const PageAccounts = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(accountsLoadingSelector);
  const accounts = useSelector(accountsListSelector);
  const accountsCount = useSelector(accountsCountSelector);
  const profile = useSelector(appProfileSelector);
  const account = useSelector(accountSelector);
  const {show} = usePopup();
  const {contextHolder, showSuccess} = useToast();
  const [paginationOptions, setPaginationOptions] = useState<TPaginationOptions>({
    [PaginationEnum.PAGE]: 1,
    [PaginationEnum.OFFSET]: LISTING_OFFSET,
    [PaginationEnum.LENGTH]: LISTING_LENGTH,
  });

  const handleAccountPopup = useCallback(
    ({mode, entity}: {mode?: string; entity?: IAccountFields}) => {
      show(PopupAccount, {mode: getTranslation(ActionModeHero, mode!), entity});
    },
    [show]
  );

  const handleAdd = useCallback(() => {
    handleAccountPopup({mode: ActionModesEnum.CREATE});
  }, [handleAccountPopup]);

  const handleTableChange = useCallback((pagination: TPaginationOptions, sorter: TSortOptions) => {
    setPaginationOptions(pagination);
  }, []);

  useEffect(() => {
    const pagination = {offset: paginationOptions.offset, length: paginationOptions.length};

    dispatch(getAccountsAction({...pagination}));
  }, [paginationOptions, dispatch]);

  useEffect(() => {
    if (account) {
      showSuccess({});
    }
  }, [account, showSuccess]);

  return (
    <PLoader spinning={isLoading} asLayer>
      <PCardWrapper padding="md">
        <Row justify="space-between">
          <Col>
            <PPageTitle text={t('pages.accounts.title')} />
          </Col>
          <Col>
            <PButton type="primary" icon={<Icon.AddCircle fill="white" />} onClick={handleAdd}>
              {t('actions.add')}
            </PButton>
          </Col>
        </Row>
        <Divider />
        <FormAccountsFilter />
      </PCardWrapper>

      <PCardWrapper padding="md">
        <PTable
          showLeged
          loading={isLoading}
          columns={columns({
            role: profile?.type!,
            onEdit: handleAccountPopup,
            translation: t,
          })}
          paginationOptions={{
            total: accountsCount,
            defaultPageSize: paginationOptions.length,
            pageSize: paginationOptions.length,
            defaultCurrent: paginationOptions.page,
            current: paginationOptions.page,
          }}
          dataSource={accounts}
          rowKey={() => uuidv4()}
          handleChange={handleTableChange}
        />
      </PCardWrapper>

      {contextHolder}
    </PLoader>
  );
};
