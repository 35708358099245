const transformNullishValuesToEmptyString = (values: Record<string, any>): object => {
  for (var prop in values) {
    if (values[prop] === null || values[prop] === undefined) {
      values[prop] = '';
    }
  }

  return values;
};

export const formatFormValues = (values: Record<string, any>): object => transformNullishValuesToEmptyString(values);
