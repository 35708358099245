export enum StatusEnum {
  ALL = 'ALL',
  NEW = 'NEW',
  CURRENT = 'CURRENT',
  ONHOLD = 'ONHOLD',
  SUCCESSFUL = 'SUCCESSFUL',
  CANCELED = 'CANCELED',
  FINALIZED = 'FINALIZED',
  FREEZED = 'FREEZED',
  ARCHIVED = 'ARCHIVED',
  SAVED = 'SAVED',
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}
