export enum OptGroupsTranslationEnum {
  APPARTMENT_MARKERS = 'Բնակարանի նշագրիչներ' as any,
  YARD_MARKERS = 'Բակի նշագրիչներ' as any,
  VIEW = 'Տեսարան' as any,
  ENTRY_POSITION = 'Մուտքի դիրք' as any,
  ENTRY_TYPE = 'Մուտքի տիպ' as any,
  CONTRACT = 'Պայմանագիր' as any,
  HEATING = 'Ջեռուցում' as any,
  SOIL_IMPORTANCE = 'Հողի նշանակություն' as any,
  REPAIR_TYPES = 'Վերանորոգման տեսակներ' as any,
  SOURCE = 'Աղբյուր' as any,
  BUILDING_SUBTYPE_STONE = 'Շենքի ենթատիպ քար' as any,
  HOUSE_TYPES_PRIVATE = 'Տան տեսակ - Առանձնատուն' as any,
}
