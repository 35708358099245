import {ColumnsType} from 'antd/es/table';
import {Col, Row, Tooltip} from 'antd';
import {Link} from 'react-router-dom';

import {Icon} from 'assets/icons';
import {IBuildingFields} from 'components/forms/building/form-building.interfaces';
import {BuildingFieldsEnum} from 'components/forms/building/form-building.enums';
import {BuildingTypesTranslationEnum, HeatingTranslationEnum, PropertyTypesTranslationEnum} from 'enums/translations';
import {RoutesEnum} from 'router/routes.enum';
import {ActionModesEnum, BuildingTypeCommonEnum} from 'enums';
import {getTranslation, formatNumber} from 'utils';
import {currencySymbols} from 'constants/currency-symbols';
import {PLoader} from 'components/primitives/p-loader';
import {ITableColumnCommonProps} from 'types/common';

interface ITableColumns extends IBuildingFields {}

export const columns = ({
  isLighboxLoading,
  entityId,
  buildingType,
  translation,
  beforeLightboxOpen,
}: ITableColumnCommonProps): ColumnsType<ITableColumns> => {
  const heatingAndFee = (): ColumnsType<ITableColumns> =>
    buildingType !== BuildingTypeCommonEnum.COMMERCIAL
      ? [
          {
            title: translation('form.heating'),
            dataIndex: BuildingFieldsEnum.HEATING,
            key: BuildingFieldsEnum.HEATING,
            render: (_, record) => <div>{getTranslation(HeatingTranslationEnum, record.heating!)}</div>,
          },
          {
            title: translation('form.fee'),
            dataIndex: BuildingFieldsEnum.FEE,
            key: BuildingFieldsEnum.FEE,
            render: (_, record) => record?.fee && `${formatNumber(record?.fee)} ${currencySymbols.AMD.props.children}`,
          },
        ]
      : [];
  return [
    {
      width: '16%',
      title: translation('form.address'),
      dataIndex: BuildingFieldsEnum.ADDRESS,
      key: BuildingFieldsEnum.ADDRESS,
      render: (_, record) => (
        <div>
          {record.street?.name}, {record.address?.number}
        </div>
      ),
    },
    {
      title: translation('form.type'),
      dataIndex: BuildingFieldsEnum.TYPE,
      key: BuildingFieldsEnum.TYPE,
      render: (_, record) => <div>{getTranslation(PropertyTypesTranslationEnum, record.type!)}</div>,
    },
    {
      title: translation('form.buildingType'),
      dataIndex: BuildingFieldsEnum.SUB_TYPE,
      key: BuildingFieldsEnum.SUB_TYPE,
      render: (_, record) => <div>{getTranslation(BuildingTypesTranslationEnum, record.subtype!)}</div>,
    },
    {
      width: '18%',
      title: translation('form.info'),
      key: BuildingFieldsEnum.INFO,
      dataIndex: BuildingFieldsEnum.INFO,
      render: (_, record) => <div className="line-clamp-2">{record.info}</div>,
    },
    ...heatingAndFee(),
    {
      title: translation('form.floors'),
      dataIndex: BuildingFieldsEnum.FLOORS,
      key: BuildingFieldsEnum.FLOORS,
    },
    {
      title: translation('form.action'),
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Row gutter={8} justify="end" align="middle" wrap={false}>
          <Col className="inline-flex">
            {record.image_folder ? (
              isLighboxLoading && entityId === record.id ? (
                <PLoader size="small" />
              ) : (
                <Tooltip title={translation('actions.images')}>
                  <Icon.Picture
                    color="#292D32"
                    width={20}
                    height={20}
                    cursor="pointer"
                    onClick={() => beforeLightboxOpen!(record.id!, record.image_folder!)}
                  />
                </Tooltip>
              )
            ) : (
              <button type="button" className="default disabled inline-flex">
                <Icon.Picture color="#292D32" width={20} height={20} />
              </button>
            )}
          </Col>
          <Col className="inline-flex">
            <Tooltip title={translation('actions.view')}>
              <Link to={`${RoutesEnum.BUILDINGS}/${ActionModesEnum.VIEW}/${record.id}`} className="inline-flex">
                <Icon.Eye color="#292D32" width={24} height={24} cursor="pointer" />
              </Link>
            </Tooltip>
          </Col>
          <Col className="inline-flex">
            <Tooltip title={translation('actions.change')}>
              <Link to={`${RoutesEnum.BUILDINGS}/${ActionModesEnum.UPDATE}/${record.id}`} className="inline-flex">
                <Icon.Edit color="#292D32" width={24} height={24} cursor="pointer" />
              </Link>
            </Tooltip>
          </Col>
        </Row>
      ),
    },
  ];
};
