import {memo} from 'react';

import {CSS} from './p-form-label.cssconfig';
import {PFormLabelProps} from './p-form-label.types';

const PFormLabel = ({text, id, isStatic, type, innerClassName, className, ...props}: PFormLabelProps) => {
  return (
    <div className={`${isStatic ? CSS.wrapperStatic : CSS.wrapper}`} {...props}>
      {isStatic ? (
        <div
          className={`${isStatic ? CSS.label.static : CSS.label.input} ${
            type ? CSS.label[type] : innerClassName
          } ${className}`}
        >
          {text}
        </div>
      ) : (
        <label
          className={`${isStatic ? CSS.label.static : CSS.label.input} ${
            type ? CSS.label[type] : innerClassName
          } ${className}`}
          htmlFor={id}
        >
          {text}
        </label>
      )}
    </div>
  );
};

export const PFormLabelMemoized = memo(PFormLabel);
