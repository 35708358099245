type TPieItem = {
  count: number;
  color: string;
};

export const createPie = ({canvas, data, total}: {canvas: any; data: any; total: number}): void => {
  let startAngle = 0;

  const ctx = canvas.getContext('2d');

  canvas.style.width = '100%';
  canvas.style.height = '100%';
  canvas.width = canvas.offsetWidth;
  canvas.height = canvas.offsetHeight;

  data?.forEach((pie: TPieItem) => {
    const angle = (pie.count / total) * Math.PI * 2;

    ctx.beginPath();
    ctx.moveTo(canvas.width / 2, canvas.height / 2);
    ctx.arc(canvas.width / 2, canvas.height / 2, canvas.width / 2, startAngle, startAngle + angle);
    ctx.closePath();

    ctx.fillStyle = pie.color;
    ctx.fill();

    startAngle += angle;
  });
};
