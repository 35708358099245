import {PropertyFilterFieldsEnum} from 'components/forms/property-filter/form-property-filter.enums';

export const formLabelTranslations = {
  email: 'էլ հասցե',
  firstName: 'Անուն',
  lastName: 'Ազգանուն',
  phone: 'Հեռախոս',
  userType: 'Օգտատիրոջ տիպ',
  personType: 'Անձի տիպ',
  department: 'Բաժին',
  password: 'Գաղտնաբառ',
  comments: 'Մեկնաբանություններ',
  innerComments: 'Ներքին մեկնաբանություն',
  archiveInfo: 'Արխիվացման մեկնաբանություն',
  date: 'Ամսաթիվ',
  endsIn: 'Ավարտվում է',
  from: 'ՍԿՍԱԾ',
  to: 'ՄԻՆՉԵՎ',
  customer: 'Հաճախորդ',
  permissions: 'Իրավունքներ',
  action: 'Գործողություն',
  address: 'Հասցե',
  type: 'Տիպ',
  propertyType: 'Գույքի տիպ',
  subType: 'Ենթատիպ',
  info: 'Նկարագրություն',
  addintionalNotes: 'Լրացուցիչ նշումներ',
  reason: 'Պատճառը',
  heating: 'Ջեռուցում',
  heatingNew: 'Ջեռուցում նոր․',
  fee: 'Սպաս․ վճար',
  floors: 'Հարկեր',
  floor: 'Հարկ',
  entranceFloor: 'Մուտքի հարկ',
  min: 'մին․',
  max: 'մաքս․',
  all: 'Բոլորը',
  plan: 'Շենքի դիրք',
  status: 'Կարգավիճակ',
  flag: 'Ընթացք',
  source: 'Աղբյուր',
  customerMode: 'Հաճախորդի տիպ',
  area: 'Մակերես',
  totlaArea: 'Ընդհանուր մակերես',
  price: 'Գին',
  prices: 'Գներ',
  pricePerArea: 'Գին',
  country: 'Երկիր',
  city: 'Քաղաք',
  district: 'Շրջան',
  propertyNumber: 'Համար',
  street: 'Փողոց',
  other: 'Այլ',
  noteRelation: 'Նշեք կապը',
  relationWithBuilding: 'Կապ շենքի հետ',
  relationWithProperty: 'Կապ գույքի հետ',
  buildingType: 'Շենքի տիպ',
  buildingSubType: 'Շենքի ենթատիպ',
  buildingInfo: 'Շենքի մասին տեղեկություն',
  leadInfo: 'Լիդի մասին տեղեկություն',
  persons: 'Անձեր',
  images: 'Նկարներ',
  commonFiles: 'Ընդհանուր ֆայլեր',
  files: 'Ֆայլեր',
  addPhone: 'Ավելացնել հեռախոսահամար',
  rooms: 'Սենյակներ',
  room: 'Սենյակ',
  contractType: 'Գործարքի տեսակ',
  mode: 'Գործարքի տեսակ',
  contract: 'Պայմանագրի տիպ',
  sale: 'Վաճառք',
  rent: 'Վարձ',
  rentDaily: 'Օրավարձ',
  currency: 'Փոխարժեք',
  livingArea: 'Բնակելի մակերես',
  landArea: 'Հողի մակերես',
  living: 'Բնակելի',
  land: 'Հող',
  usage: 'Հողի նշանակություն',
  actionTarget: 'Նպատակ',
  entranceDate: 'Մուտքի ամսաթիվ',
  start: 'Սկիզբ',
  end: 'Ավարտ',
  squareMeter: 'քմ',
  hectare: 'հա',
  id: 'ID',
  repair: 'Վերանորոգում',
  innerInfo: 'Ներքին մեկն․',
  eventComments: 'Կարգավիճակի մեկն․',
  frontLength: 'Ճակատի երկարություն',
  cadastre: 'Կադաստր',
  cadastreNumber: 'Կադաստրի թիվ',
  propertyId: 'Գույքի id',
  modified: 'Թարմացման ամսաթիվ',
  regionAddress: 'Հասցե (մարզ)',
  region: 'Մարզ',
  settlement: 'Բնակավայր',
  entryPosition: 'Մուտքի դիրք',
  entryType: 'Մուտքի տիպ',
  bathroom: 'Սանհանգույց',
  bathrooms: 'Սանհանգույցներ',
  balcony: 'Պատշգամբ',
  balconies: 'Պատշգամբներ',
  view: 'Տեսարան',
  markers: 'Նշագրիչներ',
  yardMarkers: 'Բակի նշագրիչներ',
  parking: 'Կայանատեղի',
  parkings: 'Կայանատեղիներ',
  bedroom: 'Ննջասենյակ',
  bedrooms: 'Ննջասենյակներ',
  meterShort: 'Մ',
  title: 'Անվանում',
  mainEntranceFloor: 'Հիմնական մուտքի հարկ',
  commercialArea: 'Առևվտրային մակերես',
  glass: 'Վիտրաժ',
  divisible: 'Բաժանվում է մասերի',
  isBuilding: 'Շենք է',
  cafe: 'Բաց սրճարան',
  apartmentNumbers: 'Բնակարանի համարներ',
  buildingNumbers: 'Շենքի համարներ',
  houseNumbers: 'Տան համարներ',
  number: 'Համար',
  numbers: 'Համարներ',
  commercialNumber: 'Տարածքի համար',
  entranceCount: 'Մուտքեր',
  freezeToDate: 'Սառեցնել մինչեվ',
  noteReason: 'Նշեք պատճառը',
  writeComment: 'Գրել մեկնաբանություն․․․',
  mainInfo: 'Հիմնական ինֆորմացիա',
  otherInfo: 'Այլ ինֆորմացիա',
  propertyUniqueId: 'Գույքի ունիկալ ID',
  apartmentNumber: 'Բնակարանի համար',
  choose: 'Ընտրել',
  video: 'Վիդեո',
  tour: '360 տուր',
  houseType: 'Տան տեսակ',
  yardInfo: 'Բակի նկարագրություն',
  mainGallery: 'Հիմնական պատկերասրահ',
  otherFiles: 'Այլ ֆայլեր',
  updatePrice: 'Գնի փոփոխում',
  height: 'Առաստաղի բարձրություն',
  freeze: 'Սառեցումներ',
  available: 'Ամրագրումներ',
  hy: 'Հայերեն',
  en: 'English',
  ru: 'Русский',
  alt: 'Ալտերնատիվ տեքստ',
  meta: 'Մետա տեքստ',
  keyword: 'Բանալի բառեր',

  [PropertyFilterFieldsEnum.CODE]: 'Գույքի ID',
  [PropertyFilterFieldsEnum.BUILDING_RESIDENTIAL_SUBTYPE]: 'Շենքի տիպ',
  [PropertyFilterFieldsEnum.STONE]: 'Շենքի ենթատիպ',
  [PropertyFilterFieldsEnum.OPTIONS]: 'Նշագրիչներ',
  [PropertyFilterFieldsEnum.MIN_AREA]: 'Մակերես մին․',
  [PropertyFilterFieldsEnum.MAX_AREA]: 'Մակերես մաքս․',

  [PropertyFilterFieldsEnum.MIN_LIVING_AREA]: 'Բնակելի մակերես մին․',
  [PropertyFilterFieldsEnum.MAX_LIVING_AREA]: 'Բնակելի մակերես մաքս․',
  [PropertyFilterFieldsEnum.MIN_LAND_AREA]: 'Հողի մակերես մին․',
  [PropertyFilterFieldsEnum.MAX_LAND_AREA]: 'Հողի մակերես մաքս․',
  [PropertyFilterFieldsEnum.MIN_HECTARE]: 'Հողի մակերես հեկատարով մին․',
  [PropertyFilterFieldsEnum.MAX_HECTARE]: 'Հողի մակերես հեկատարով մաքս․',
  [PropertyFilterFieldsEnum.MIN_SQUARE_METRE]: 'Հողի մակերես մին․',
  [PropertyFilterFieldsEnum.MAX_SQUARE_METRE]: 'Հողի մակերես մաքս․',
  [PropertyFilterFieldsEnum.MIN_COMMERCIAL_AREA]: 'Առևվտրային մակերես մին․',
  [PropertyFilterFieldsEnum.MAX_COMMERCIAL_AREA]: 'Առևվտրային մակերես մաքս․',
  [PropertyFilterFieldsEnum.MIN_TOTAL_AREA]: 'Ընդհանուր մակերես մին․',
  [PropertyFilterFieldsEnum.MAX_TOTAL_AREA]: 'Ընդհանուր մակերես մաքս․',

  [PropertyFilterFieldsEnum.MIN_BEDROOMS]: 'Ննջասենյակներ մին․',
  [PropertyFilterFieldsEnum.MAX_BEDROOMS]: 'Ննջասենյակներ մաքս․',
  [PropertyFilterFieldsEnum.MIN_BATHROOMS]: 'Սանհանգույցներ մին․',
  [PropertyFilterFieldsEnum.MAX_BATHROOMS]: 'Սանհանգույցներ մաքս․',

  [PropertyFilterFieldsEnum.MIN_FLOOR]: 'Հարկ մին․',
  [PropertyFilterFieldsEnum.MAX_FLOOR]: 'Հարկ մաքս․',
  [PropertyFilterFieldsEnum.MIN_FLOOR_PLAN]: 'Հարկեր մին․',
  [PropertyFilterFieldsEnum.MAX_FLOOR_PLAN]: 'Հարկեր մաքս․',
  [PropertyFilterFieldsEnum.MIN_ENTRANCE_FLOOR]: 'Մուտքի հարկ մին․',
  [PropertyFilterFieldsEnum.MAX_ENTRANCE_FLOOR]: 'Մուտքի հարկ մաքս․',

  [PropertyFilterFieldsEnum.MIN_FRONT_LENGTH]: 'Ճակատի երկարություն մին․',
  [PropertyFilterFieldsEnum.MAX_FRONT_LENGTH]: 'Ճակատի երկարություն մաքս․',

  [PropertyFilterFieldsEnum.MIN_TOTAL_PRICE]: 'Հարկ մին․',
  [PropertyFilterFieldsEnum.MAX_TOTAL_PRICE]: 'Հարկ մաքս․',
  [PropertyFilterFieldsEnum.MIN_UNIT_PRICE]: 'Հարկ մին․',
  [PropertyFilterFieldsEnum.MAX_UNIT_PRICE]: 'Հարկ մաքս․',

  [PropertyFilterFieldsEnum.MIN_TOTAL_PRICE_SALE]: 'Գին մին․ (վաճառք)',
  [PropertyFilterFieldsEnum.MAX_TOTAL_PRICE_SALE]: 'Գին մաքս․ (վաճառք)',
  [PropertyFilterFieldsEnum.MIN_UNIT_PRICE_SALE]: 'Գին քմ․ մին․ (վաճառք)',
  [PropertyFilterFieldsEnum.MAX_UNIT_PRICE_SALE]: 'Գին քմ․ մաքս․ (վաճառք)',

  [PropertyFilterFieldsEnum.MIN_TOTAL_PRICE_MONTHLY]: 'Գին մին․ (վարձ)',
  [PropertyFilterFieldsEnum.MAX_TOTAL_PRICE_MONTHLY]: 'Գին մաքս․ (վարձ)',
  [PropertyFilterFieldsEnum.MIN_UNIT_PRICE_MONTHLY]: 'Գին քմ․ մին․ (վարձ)',
  [PropertyFilterFieldsEnum.MAX_UNIT_PRICE_MONTHLY]: 'Գին քմ․ մաքս․ (վարձ)',

  [PropertyFilterFieldsEnum.MIN_TOTAL_PRICE_DAILY]: 'Գին մին․ (օրավարձ)',
  [PropertyFilterFieldsEnum.MAX_TOTAL_PRICE_DAILY]: 'Գին մաքս․ (օրավարձ)',
  [PropertyFilterFieldsEnum.MIN_UNIT_PRICE_DAILY]: 'Գին քմ․ մին․ (օրավարձ)',
  [PropertyFilterFieldsEnum.MAX_UNIT_PRICE_DAILY]: 'Գին քմ․ մաքս․ (օրավարձ)',

  [PropertyFilterFieldsEnum.CURRENCY_SALE]: 'Փոխարժեք (վաճառք)',
  [PropertyFilterFieldsEnum.CURRENCY_MONTHLY]: 'Փոխարժեք (վարձ)',
  [PropertyFilterFieldsEnum.CURRENCY_DAILY]: 'Փոխարժեք (օրավարձ)',

  [PropertyFilterFieldsEnum.REGION]: 'Մարզ',
  [PropertyFilterFieldsEnum.SETTLEMENT]: 'Բնակավայր',
  [PropertyFilterFieldsEnum.DISTRICT]: 'Շրջան',
  [PropertyFilterFieldsEnum.STREET]: 'Փողոց',
};
