import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {TServerResponseError} from 'types/common';

import {InquiriesState} from './types';

const sliceInquiries = createSlice({
  name: 'inquiries',
  initialState: {
    loading: false,
    error: null,
    inquiries: [],
    inquiriesCount: 0,
    currentInquiry: null,
    bookmarkAddedInquiry: null,
    bookmarkRemovedInquiry: null,
    addedInquiryComment: null,
    updatedInquiryInfo: null,
    updatedInquiryPersons: null,
    inquiryEvent: null,
    inquiryEventPropertyComment: null,
    inquiryUpdatedEventSearch: null,
    inquiriesFilterPresets: {},
  },
  reducers: {
    setErrors(state: InquiriesState, {payload}: PayloadAction<TServerResponseError | null>) {
      state.error = payload;
    },
    setLoading(state: InquiriesState, {payload}: PayloadAction<InquiriesState['loading']>) {
      state.loading = payload;
    },
    setInquiries(state: InquiriesState, {payload}: PayloadAction<InquiriesState['inquiries']>) {
      state.inquiries = payload;
    },
    setInquiriesCount(state: InquiriesState, {payload}: PayloadAction<InquiriesState['inquiriesCount']>) {
      state.inquiriesCount = payload;
    },
    setCurrentInquiry(state: InquiriesState, {payload}: PayloadAction<InquiriesState['currentInquiry']>) {
      state.currentInquiry = payload;
    },
    setBookmarkAddedInquiry(state: InquiriesState, {payload}: PayloadAction<InquiriesState['bookmarkAddedInquiry']>) {
      state.bookmarkAddedInquiry = payload;
    },
    setBookmarkRemovedInquiry(
      state: InquiriesState,
      {payload}: PayloadAction<InquiriesState['bookmarkRemovedInquiry']>
    ) {
      state.bookmarkRemovedInquiry = payload;
    },
    setAddedInquiryComment(state: InquiriesState, {payload}: PayloadAction<InquiriesState['addedInquiryComment']>) {
      state.addedInquiryComment = payload;
    },
    setUpdatedInquiryInfo(state: InquiriesState, {payload}: PayloadAction<InquiriesState['updatedInquiryInfo']>) {
      state.updatedInquiryInfo = payload;
    },
    setUpdatedInquiryPersons(state: InquiriesState, {payload}: PayloadAction<InquiriesState['updatedInquiryPersons']>) {
      state.updatedInquiryPersons = payload;
    },
    setInquiryEvent(state: InquiriesState, {payload}: PayloadAction<InquiriesState['inquiryEvent']>) {
      state.inquiryEvent = payload;
    },
    setInquiryEventPropertyComment(
      state: InquiriesState,
      {payload}: PayloadAction<InquiriesState['inquiryEventPropertyComment']>
    ) {
      state.inquiryEventPropertyComment = payload;
    },
    setInquiryUpdatedEventSearch(
      state: InquiriesState,
      {payload}: PayloadAction<InquiriesState['inquiryUpdatedEventSearch']>
    ) {
      state.inquiryUpdatedEventSearch = payload;
    },
    setInquiriesFilterPresets(
      state: InquiriesState,
      {payload}: PayloadAction<InquiriesState['inquiriesFilterPresets']>
    ) {
      state.inquiriesFilterPresets = payload;
    },
  },
});

export const {
  setErrors,
  setLoading,
  setInquiries,
  setInquiriesCount,
  setCurrentInquiry,
  setBookmarkAddedInquiry,
  setBookmarkRemovedInquiry,
  setAddedInquiryComment,
  setUpdatedInquiryInfo,
  setUpdatedInquiryPersons,
  setInquiryEvent,
  setInquiryEventPropertyComment,
  setInquiryUpdatedEventSearch,
  setInquiriesFilterPresets,
} = sliceInquiries.actions;

export const inquiriesReducer = sliceInquiries.reducer;
