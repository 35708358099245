import {memo} from 'react';

import {useClosePopupMixin} from 'hooks';
import {Icon} from 'assets/icons';
import {PButton} from 'components/primitives/p-button';

import {PopupBaseProps} from './popup-base.types';
import {CSS} from './popup-base.cssconfig';
import {PopupSizesEnum, PopupTypesEnum} from './popup-base.enums';

const PopupBase = ({layerClose, open, title, buttons, size, type, children, onCrossClick}: PopupBaseProps) => {
  useClosePopupMixin(onCrossClick);

  return open ? (
    <div className={`popup-wrapper ${CSS.layer}`} onClick={layerClose ? onCrossClick : undefined}>
      <div
        className={`popup-content ${CSS.inner.base} ${
          size === PopupSizesEnum.XXL
            ? CSS.inner.sizeXXL
            : size === PopupSizesEnum.XL
            ? CSS.inner.sizeXL
            : size === PopupSizesEnum.LG
            ? CSS.inner.sizeLG
            : size === PopupSizesEnum.MD
            ? CSS.inner.sizeMD
            : CSS.inner.sizeSM
        }`}
        onClick={e => e.stopPropagation()}
      >
        <div className={`popup-header ${CSS.header}`}>
          <div className={CSS.title}>{title}</div>
          <Icon.CloseSquare width={24} height={24} onClick={onCrossClick} className={CSS.closeIcon} />
        </div>

        <div className={buttons ? `popup-main ${CSS.content}` : ''}>{children}</div>

        {buttons && (
          <div
            className={`popup-controls ${type === PopupTypesEnum.PROMPT ? CSS.buttons.prompt : CSS.buttons.base} ${
              buttons?.position === 'right'
                ? 'justify-end'
                : buttons?.position === 'justify'
                ? 'justify-between'
                : buttons?.position === 'center'
                ? 'justify-center'
                : 'justify-start'
            }`}
          >
            {buttons?.reject ? (
              buttons?.reject?.template ? (
                <>{buttons?.reject?.template}</>
              ) : (
                <PButton
                  type={buttons?.reject?.type}
                  scheme={buttons?.reject?.scheme}
                  theme={buttons?.reject?.theme}
                  htmlType={buttons?.reject?.htmlType}
                  className={buttons?.reject?.className}
                  onClick={buttons?.reject?.onClick}
                >
                  {buttons?.reject?.name}
                </PButton>
              )
            ) : null}

            {buttons?.confirm ? (
              buttons?.confirm?.template ? (
                <>{buttons?.confirm?.template}</>
              ) : (
                <PButton
                  theme={buttons?.confirm?.theme}
                  scheme={buttons?.confirm?.scheme}
                  icon={buttons?.confirm?.icon}
                  onClick={buttons?.confirm?.onClick}
                  type={buttons?.confirm?.type}
                  htmlType={buttons?.confirm?.htmlType}
                  disabled={buttons?.confirm?.disabled}
                  loading={buttons?.confirm?.loading}
                  className={buttons?.confirm?.className}
                >
                  {buttons?.confirm?.name}
                </PButton>
              )
            ) : null}
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export const PopupBaseMemoized = memo(PopupBase);
