import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {TServerResponseError} from 'types/common';

import {AnalyticState} from './types';

const sliceAnalytics = createSlice({
  name: 'analytics',
  initialState: {
    loading: false,
    errors: null,
    propertyTotals: null,
    inquiryTotals: null,
    leadTotals: null,
    propertyPerformance: null,
    inquiryPerformance: null,
    leadPerformance: null,
  },
  reducers: {
    setErrors(state: AnalyticState, {payload}: PayloadAction<TServerResponseError | null>) {
      state.errors = payload;
    },
    setLoading(state: AnalyticState, {payload}: PayloadAction<AnalyticState['loading']>) {
      state.loading = payload;
    },
    setPropertyTotals(state: AnalyticState, {payload}: PayloadAction<AnalyticState['propertyTotals']>) {
      state.propertyTotals = payload;
    },
    setInquiryTotals(state: AnalyticState, {payload}: PayloadAction<AnalyticState['inquiryTotals']>) {
      state.inquiryTotals = payload;
    },
    setLeadTotals(state: AnalyticState, {payload}: PayloadAction<AnalyticState['leadTotals']>) {
      state.leadTotals = payload;
    },
    setPropertyPerformance(state: AnalyticState, {payload}: PayloadAction<AnalyticState['propertyPerformance']>) {
      state.propertyPerformance = payload;
    },
    setInquiryPerformance(state: AnalyticState, {payload}: PayloadAction<AnalyticState['inquiryPerformance']>) {
      state.inquiryPerformance = payload;
    },
    setLeadPerformance(state: AnalyticState, {payload}: PayloadAction<AnalyticState['leadPerformance']>) {
      state.leadPerformance = payload;
    },
  },
});

export const {
  setErrors,
  setLoading,
  setPropertyTotals,
  setInquiryTotals,
  setLeadTotals,
  setPropertyPerformance,
  setInquiryPerformance,
  setLeadPerformance,
} = sliceAnalytics.actions;

export const analyticsReducer = sliceAnalytics.reducer;
