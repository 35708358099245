import {ReactNode} from 'react';
import {Tooltip} from 'antd';

import {Icon} from 'assets/icons';
import {PropertyFieldsEnum} from 'components/forms/property/form-property.enums';
import {TTranslation} from 'types/common';

interface IWebsiteFields {
  icon: ReactNode | string;
  lang: string;
  fields: {
    name: string;
    type: string;
    label: string;
  }[];
}

export const websiteFields = ({translation}: TTranslation): Array<IWebsiteFields> => [
  {
    icon: (
      <Tooltip title={translation('form.hy')}>
        <Icon.FlagArmenia width={22} />
      </Tooltip>
    ),
    lang: translation('form.hy'),
    fields: [
      {
        name: PropertyFieldsEnum.TITLE_HY,
        type: 'textarea',
        label: translation('form.title'),
      },
      {
        name: PropertyFieldsEnum.INFO_HY,
        type: 'textarea',
        label: translation('form.info'),
      },
      {
        name: PropertyFieldsEnum.ALT_HY,
        type: 'textarea',
        label: translation('form.alt'),
      },
      {
        name: PropertyFieldsEnum.TAB_HY,
        type: 'textarea',
        label: translation('form.tab'),
      },
      {
        name: PropertyFieldsEnum.META_HY,
        type: 'textarea',
        label: translation('form.meta'),
      },
      {
        name: PropertyFieldsEnum.KEYWORD_HY,
        type: 'textarea',
        label: translation('form.keyword'),
      },
    ],
  },
  {
    icon: (
      <Tooltip title={translation('form.en')}>
        <Icon.FlagUK width={22} />
      </Tooltip>
    ),
    lang: translation('form.en'),
    fields: [
      {
        name: PropertyFieldsEnum.TITLE_EN,
        type: 'textarea',
        label: translation('form.title'),
      },
      {
        name: PropertyFieldsEnum.INFO_EN,
        type: 'textarea',
        label: translation('form.info'),
      },
      {
        name: PropertyFieldsEnum.ALT_EN,
        type: 'textarea',
        label: translation('form.alt'),
      },
      {
        name: PropertyFieldsEnum.TAB_EN,
        type: 'textarea',
        label: translation('form.tab'),
      },
      {
        name: PropertyFieldsEnum.META_EN,
        type: 'textarea',
        label: translation('form.meta'),
      },
      {
        name: PropertyFieldsEnum.KEYWORD_EN,
        type: 'textarea',
        label: translation('form.keyword'),
      },
    ],
  },
  {
    icon: (
      <Tooltip title={translation('form.ru')}>
        <Icon.FlagRussia width={22} />
      </Tooltip>
    ),
    lang: translation('form.ru'),
    fields: [
      {
        name: PropertyFieldsEnum.TITLE_RU,
        type: 'textarea',
        label: translation('form.title'),
      },
      {
        name: PropertyFieldsEnum.INFO_RU,
        type: 'textarea',
        label: translation('form.info'),
      },
      {
        name: PropertyFieldsEnum.ALT_RU,
        type: 'textarea',
        label: translation('form.alt'),
      },
      {
        name: PropertyFieldsEnum.TAB_RU,
        type: 'textarea',
        label: translation('form.tab'),
      },
      {
        name: PropertyFieldsEnum.META_RU,
        type: 'textarea',
        label: translation('form.meta'),
      },
      {
        name: PropertyFieldsEnum.KEYWORD_RU,
        type: 'textarea',
        label: translation('form.keyword'),
      },
    ],
  },
];
