export enum AccountFieldsEnum {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  EMAIL = 'email',
  PHONE = 'phone',
  TYPE = 'type',
  COMMENTS = 'comments',
  PASSWORD = 'password',
  PERMISSIONS = 'permissions',
  ACTIVE = 'active',
  ID = 'id',
  ACCOUNT = 'account',
  INFO = 'info',
  FLOORS = 'floors',
}
