import {TServerResponse} from 'types/common';
import {Fetcher} from 'utils';
import {ApiUrl} from 'services/constants';

const fetcher = new Fetcher({baseURL: `${process.env.REACT_APP_API_URL}`});

export const updateWebsiteFieldsService = (data: any): Promise<TServerResponse<any>> =>
  fetcher.request({
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    url: ApiUrl.updateWebsiteFields,
    data,
  });
