import {CrossCalculationModesEnum} from 'enums';
import {HECTARE_IN_SQUARE} from 'constants/measures';

import {divide, multiply} from './calc';

type UnitPriceProps = {
  values: any;
  FiledsEnum: any;
  form: any;
  value: number | string | null;
  mode: CrossCalculationModesEnum;
  fieldName: string | undefined;
  fieldTotalSale: string;
  fieldUnitSale: string;
  fieldTotalMonthly: string;
  fieldUnitMonthly: string;
};

export const calculateUnitPrices = ({
  values,
  FiledsEnum,
  form,
  mode,
  value,
  fieldName,
  fieldTotalSale,
  fieldUnitSale,
  fieldTotalMonthly,
  fieldUnitMonthly,
}: UnitPriceProps): void => {
  const totalSale = values[FiledsEnum.TOTAL_PRICE_SALE];
  const unitSale = values[FiledsEnum.UNIT_PRICE_SALE];
  const totalMonthly = values[FiledsEnum.TOTAL_PRICE_MONTHLY];
  const unitMonthly = values[FiledsEnum.UNIT_PRICE_MONTHLY];
  const val = Number(value);
  const area = values[FiledsEnum.AREA]! || values[FiledsEnum.SQUARE_METRE]! || values[FiledsEnum.TOTAL_AREA]!;

  if (mode === CrossCalculationModesEnum.PRICE_UNIT && val > 0) {
    const countedTotal = multiply(val, area, {toFix: 0});

    form.setFieldValue(fieldName!, countedTotal);
  } else if (mode === CrossCalculationModesEnum.PRICE_TOTAL && val > 0) {
    const countedUnit = divide(val, area, {toFix: 1});

    form.setFieldValue(fieldName!, countedUnit);
  }

  if (mode === CrossCalculationModesEnum.AREA && val > 0 && totalSale) {
    const countedUnit = divide(totalSale, val, {toFix: 1});

    form.setFieldValue(fieldUnitSale, countedUnit);
  } else if (mode === CrossCalculationModesEnum.AREA && val > 0 && unitSale) {
    const countedTotal = multiply(unitSale, val, {toFix: 0});

    form.setFieldValue(fieldTotalSale, countedTotal);
  }

  if (mode === CrossCalculationModesEnum.AREA && val > 0 && totalMonthly) {
    const countedUnitMonthly = divide(totalMonthly, val, {toFix: 1});

    form.setFieldValue(fieldUnitMonthly, countedUnitMonthly);
  } else if (mode === CrossCalculationModesEnum.AREA && val > 0 && unitMonthly) {
    const countedTotalMonthly = multiply(unitMonthly, val, {toFix: 0});

    form.setFieldValue(fieldTotalMonthly, countedTotalMonthly);
  }

  if (mode === CrossCalculationModesEnum.AREA && !val) {
    form.setFieldValue(fieldUnitSale, '');
    form.setFieldValue(fieldUnitMonthly, '');
  }

  if (mode === CrossCalculationModesEnum.LAND_SQUARE) {
    const countedHectare = divide(val, HECTARE_IN_SQUARE, {toFix: 4});

    form.setFieldValue(fieldName!, countedHectare);

    if (totalSale && val > 0) {
      const countedUnit = divide(totalSale, val, {toFix: 1});

      form.setFieldValue(fieldUnitSale, countedUnit);
    } else if (unitSale && val > 0) {
      const countedTotal = multiply(unitSale, val, {toFix: 0});

      form.setFieldValue(fieldTotalSale, countedTotal);
    }

    if (!val) {
      form.setFieldValue(fieldUnitSale, '');
    }
  }

  if (mode === CrossCalculationModesEnum.LAND_HECTARE) {
    const countedSquareMetre = multiply(val, HECTARE_IN_SQUARE, {toFix: 0});

    form.setFieldValue(fieldName!, countedSquareMetre);

    if (totalSale && countedSquareMetre! > 0) {
      const countedUnit = divide(totalSale, countedSquareMetre!, {toFix: 1});

      form.setFieldValue(fieldUnitSale, countedUnit);
    } else if (unitSale && countedSquareMetre! > 0) {
      const countedTotal = multiply(unitSale, countedSquareMetre!, {toFix: 0});

      form.setFieldValue(fieldTotalSale, countedTotal);
    }

    if (!val) {
      form.setFieldValue(fieldUnitSale, '');
    }
  }
};
