import Table, {ColumnsType} from 'antd/es/table';

import {IInquiryEventFieldsFields} from 'components/forms/inquiries-filter/form-inquiry-event.interfaces';
import {InquiryEventFieldsEnum} from 'components/forms/inquiries-filter/form-inquiry-event.enums';
import {getTranslation} from 'utils';
import {InquiryStateTranslationEnum} from 'enums/translations';
import {TTranslation} from 'types/common';

interface ITableColumns extends IInquiryEventFieldsFields {}

export const eventsHistoryColumns = ({translation}: TTranslation): ColumnsType<ITableColumns> => [
  {
    title: translation('common.businessProcess'),
    dataIndex: InquiryEventFieldsEnum.TYPE,
    key: InquiryEventFieldsEnum.TYPE,
    render: (_, record) => getTranslation(InquiryStateTranslationEnum, record?.type!),
  },
  {
    title: translation('common.propertyCount'),
    dataIndex: InquiryEventFieldsEnum.PROPERTIES,
    key: InquiryEventFieldsEnum.PROPERTIES,
    render: (_, record) => record.properties?.length || '-',
  },
  Table.EXPAND_COLUMN,
  {
    title: translation('common.comments'),
    dataIndex: InquiryEventFieldsEnum.COMMENTS,
    key: InquiryEventFieldsEnum.COMMENTS,
    render: (_, record) => record.comments?.length || '-',
  },
  {
    title: translation('form.date'),
    dataIndex: InquiryEventFieldsEnum.DATE,
    key: InquiryEventFieldsEnum.DATE,
  },
];
