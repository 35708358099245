import {memo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {PCardWrapper} from 'components/primitives/p-card-wrapper';
import {Icon} from 'assets/icons';
import {palette} from 'constants/theme';
import {PFormLabel} from 'components/primitives/p-form-label';
import {PPageTitle} from 'components/primitives/p-page-title';
import {PButton} from 'components/primitives/p-button';

const EmptyPage = ({showHeader}: {showHeader?: boolean}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  return (
    <>
      {showHeader ? (
        <PPageTitle
          text={
            <PButton
              type="text"
              theme="white"
              icon={<Icon.ChevronLeftRounded fill="currentColor" />}
              onClick={() => navigate(-1)}
            >
              {t('actions.back')}
            </PButton>
          }
        />
      ) : null}
      <PCardWrapper padding="md">
        <div className="text-center">
          <Icon.CloseCircle className="mb-4" width={60} height={60} fill={palette.danger.primary} />
          <PFormLabel className="mb-0" isStatic text={t('info.notFound')} />
        </div>
      </PCardWrapper>
    </>
  );
};

export const EmptyPageMemoized = memo(EmptyPage);
