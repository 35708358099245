export enum BuildingTypesTranslationEnum {
  STONE_COMMERICAL = 'Քար / պանել',
  STONE = 'Քար',
  NEW = 'Նորակառույց',
  COMMERCIAL = 'Կոմերցիոն',
  PANEL = 'Պանել',

  COMMERCIAL_ONLY = 'Կոմերցիոն',
  RESIDENTIAL_OLD = 'Քար / պանել',
  RESIDENTIAL_NEW = 'Նորակառույց',
  // COMMERCIAL_ONLY = 'Կոմերցիոն',
  // RESIDENTIAL_OLD = 'Բնակելի հին',
  // RESIDENTIAL_NEW = 'Բնակելի նոր',
}
