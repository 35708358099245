import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {TServerResponseError} from 'types/common';

import {PersonsState} from './types';

const slicePersons = createSlice({
  name: 'persons',
  initialState: {
    loading: false,
    errors: null,
    personErrors: null,
    persons: [],
    personsCount: 0,
    searchedPerson: null,
    currentPerson: null,
    person: null,
    createdPerson: null,
  },
  reducers: {
    setLoading(state: PersonsState, {payload}: PayloadAction<PersonsState['loading']>) {
      state.loading = payload;
    },
    setErrors(state: PersonsState, {payload}: PayloadAction<TServerResponseError | null>) {
      state.errors = payload;
    },
    setPersonErrors(state: PersonsState, {payload}: PayloadAction<TServerResponseError | null>) {
      state.personErrors = payload;
    },
    setPersons(state: PersonsState, {payload}: PayloadAction<PersonsState['persons']>) {
      state.persons = payload;
    },
    setPersonsCount(state: PersonsState, {payload}: PayloadAction<PersonsState['personsCount']>) {
      state.personsCount = payload;
    },
    setSearchedPerson(state: PersonsState, {payload}: PayloadAction<PersonsState['searchedPerson']>) {
      state.searchedPerson = payload;
    },
    setCurrentPerson(state: PersonsState, {payload}: PayloadAction<PersonsState['currentPerson']>) {
      state.currentPerson = payload;
    },
    setPerson(state: PersonsState, {payload}: PayloadAction<PersonsState['person']>) {
      state.person = payload;
    },
    setCreatedPerson(state: PersonsState, {payload}: PayloadAction<PersonsState['createdPerson']>) {
      state.createdPerson = payload;
    },
  },
});

export const {
  setErrors,
  setPersonErrors,
  setLoading,
  setPersons,
  setPersonsCount,
  setSearchedPerson,
  setCurrentPerson,
  setPerson,
  setCreatedPerson,
} = slicePersons.actions;

export const personsReducer = slicePersons.reducer;
