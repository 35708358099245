import {PropertyTypesEnum} from 'enums';
import {PropertyTypesTranslationEnum} from 'enums/translations';
import {TPersonStats} from 'types/api';

export const injectPersonPropertyTypes = (stats: TPersonStats): string[] => {
  const personPropertyTypes: string[] = [];

  (stats?.property?.type[PropertyTypesEnum.APARTMENT] || stats?.inquiry?.type[PropertyTypesEnum.APARTMENT]) &&
    personPropertyTypes.push(PropertyTypesTranslationEnum.APARTMENT);
  (stats?.inquiry?.type[PropertyTypesEnum.HOUSE] || stats?.property?.type[PropertyTypesEnum.HOUSE]) &&
    personPropertyTypes.push(PropertyTypesTranslationEnum.HOUSE);
  (stats?.inquiry?.type[PropertyTypesEnum.LAND] || stats?.property?.type[PropertyTypesEnum.LAND]) &&
    personPropertyTypes.push(PropertyTypesTranslationEnum.LAND);
  (stats?.inquiry?.type[PropertyTypesEnum.COMMERCIAL] || stats?.property?.type[PropertyTypesEnum.COMMERCIAL]) &&
    personPropertyTypes.push(PropertyTypesTranslationEnum.COMMERCIAL);
  (stats?.inquiry?.type[PropertyTypesEnum.PARKING] || stats?.property?.type[PropertyTypesEnum.PARKING]) &&
    personPropertyTypes.push(PropertyTypesTranslationEnum.PARKING);

  return personPropertyTypes;
};
