import {Col, Form, Row} from 'antd';
import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {PButton} from 'components/primitives/p-button';
import {PInput} from 'components/primitives/p-input';
import {PPhoneInput} from 'components/primitives/p-phone-input';
import {PSelect} from 'components/primitives/p-select';
import {inquiryStateOptions} from 'constants/select-options';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {FormsEnum} from 'enums';
import {IFilterProps} from 'types/common';
import {CSubmitButton} from 'components/containers/c-submit-button';
import {Icon} from 'assets/icons';
import {useMountedOnce} from 'hooks';

import {IInquiriesFilterFields} from './form-inquiries-filter.interfaces';
import {InquiriesFilterFieldsEnum} from './form-inquiries.enums';

export const FormInquiriesFilter = ({
  form,
  values,
  initialValues,
  isButtonLoading,
  onSubmit,
  onReset,
}: IFilterProps<IInquiriesFilterFields>) => {
  const {t} = useTranslation();
  const hasInitialValues = useMemo(() => initialValues && Object.keys(initialValues).length > 0, [initialValues]);

  const setInitialValus = useMountedOnce(() => {
    if (hasInitialValues) {
      form.setFieldsValue(initialValues!);
    }
  });

  useEffect(() => {
    setInitialValus();
  }, [setInitialValus]);

  return (
    <Form form={form} onFinish={onSubmit} name={FormsEnum.INQUIRIES_FILTER}>
      <Row gutter={16}>
        <Col span={5}>
          <Form.Item name={InquiriesFilterFieldsEnum.STATE}>
            <PSelect mode="multiple" label={t('form.status')} options={inquiryStateOptions} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name={InquiriesFilterFieldsEnum.NAME} rules={[{required: false}]}>
            <PInput label={t('form.firstName')} id={InquiriesFilterFieldsEnum.NAME} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name={InquiriesFilterFieldsEnum.PHONE}
            rules={[
              {required: false},
              {min: 9, message: fieldsMessageTranslations.phoneMin},
              {max: 14, message: fieldsMessageTranslations.phoneMax},
            ]}
          >
            <PPhoneInput label={t('form.phone')} id={InquiriesFilterFieldsEnum.PHONE} />
          </Form.Item>
        </Col>
        <Col>
          <CSubmitButton
            allowValidate={false}
            form={form}
            values={values}
            isButtonLoading={isButtonLoading}
            title={t('actions.filter')}
            icon={<Icon.Filter fill="white" />}
          />
        </Col>
        <Col>
          {hasInitialValues && !isButtonLoading && (
            <PButton htmlType="button" type="text" onClick={onReset}>
              {t('actions.clear')}
            </PButton>
          )}
        </Col>
      </Row>
    </Form>
  );
};
