import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Col, Form, Row} from 'antd';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {PopupBase} from 'components/popups/base';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {PInput} from 'components/primitives/p-input';
import {PNumericInput} from 'components/primitives/p-numeric-input';
import {PSelect} from 'components/primitives/p-select';
import {contractModeOptions, currencyOptions} from 'constants/select-options';
import {TPropertyPDFDcoument} from 'components/layout/property-pdf-document/property-pdf-document.types';
import {appCatalogSelector} from 'store/app/selectors';
import {propertyCurrentSelector} from 'store/properties/selectors';
import {OptGroupsEnum, PropertyTypesEnum} from 'enums';
import {formatNumber, getFullAddress, getTranslation, injectMediaPath} from 'utils';
import {currencySymbols} from 'constants/currency-symbols';
import {PropertyPDFDcoument} from 'components/layout/property-pdf-document';
import {PButton} from 'components/primitives/p-button';
import {Icon} from 'assets/icons';
import {PFormLabel} from 'components/primitives/p-form-label';
import {ContractTypesTranslationEnum, PropertyTypesTranslationEnum} from 'enums/translations';

enum GenerateFileFieldsEnum {
  PRICE = 'price',
  CURRENCY = 'currency',
  INFO = 'info',
  MODE = 'mode',
}

interface IGenerateFileFields {
  price: number;
  currency: string;
  info: string;
  mode: string;
}

const PopupGeneratePDF = ({onClose}: {onClose: () => void}) => {
  const {t} = useTranslation();
  const [form] = Form.useForm<IGenerateFileFields>();
  const values = Form.useWatch([], form);
  const [isNotSubmittable, setIsNotSubmittable] = useState<boolean>(true);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(true);

  const catalog = useSelector(appCatalogSelector);
  const currentProperty = useSelector(propertyCurrentSelector);

  const fileName = useMemo(
    () =>
      `${getTranslation(PropertyTypesTranslationEnum, currentProperty?.type!)}${
        currentProperty?.location?.street?.name
          ? '-' + currentProperty?.location?.street?.name?.split(' ').join('-')
          : ''
      }`,
    [currentProperty]
  );

  const dataForRender = useMemo((): TPropertyPDFDcoument => {
    const price = values?.price || currentProperty?.price?.total_sale!;
    const currency = values?.currency || currentProperty?.price?.currency_sale;

    return {
      type: currentProperty?.type!,
      building: currentProperty?.building!,
      address: getFullAddress({location: currentProperty?.location, showNumber: false}),
      area: currentProperty?.area!,
      totalArea: currentProperty?.total_area!,
      livingArea: currentProperty?.living_area!,
      landArea: currentProperty?.land_area!,
      squareMetre: currentProperty?.square_metre!,
      floor: currentProperty?.floor!,
      entranceFloor: currentProperty?.entrance_floor!,
      floorPlan: currentProperty?.floor_plan!,
      bedrooms: currentProperty?.bedrooms!,
      usage: catalog[OptGroupsEnum.SOIL_IMPORTANCE]?.find(o => o.value === currentProperty?.usage)?.label as string,
      mainImage: injectMediaPath(currentProperty?.image_folder?.files[0]?.path),
      price: `${formatNumber(price)} ${currency ? getTranslation(currencySymbols, currency)?.props.children : ''}`,
      mode: getTranslation(ContractTypesTranslationEnum, values?.mode),
      addons:
        currentProperty?.type === PropertyTypesEnum.APARTMENT
          ? currentProperty?.rooms!
          : currentProperty?.type === PropertyTypesEnum.HOUSE || currentProperty?.type === PropertyTypesEnum.COMMERCIAL
          ? currentProperty?.floors!
          : [],
      images: currentProperty?.image_folder,
      info: values?.info,
    };
  }, [currentProperty, catalog, values]);

  const handleSubmit = useCallback(
    (values: IGenerateFileFields) => {
      setIsButtonLoading(true);

      try {
        document.title = window.parent.document.title = fileName;
        window.print();
      } catch (e) {
        const printWindow = window.open(window.location.href)!;

        printWindow.onload = function () {
          printWindow.document.title = fileName;

          const closePopup = () => {
            printWindow.close();
          };

          if ('onafterprint' in printWindow) {
            printWindow.onafterprint = closePopup;
          } else {
            const mediaQueryList = (printWindow as Window).matchMedia('print');

            const mqlListener = (mql: any) => {
              if (!mql.matches) {
                closePopup();
                mediaQueryList.removeEventListener('change', mqlListener);
              }
            };

            mediaQueryList.addEventListener('change', mqlListener);
          }
        };

        printWindow.print();
      } finally {
        setIsButtonLoading(false);

        onClose();
      }
    },
    [fileName, onClose]
  );

  const onSubmit = useCallback(() => {
    handleSubmit(values);
  }, [values, handleSubmit]);

  useEffect(() => {
    const afterTimeout = setTimeout(() => {
      setIsNotSubmittable(false);
      setIsButtonLoading(false);
    }, 500);

    return () => {
      clearTimeout(afterTimeout);
    };
  }, []);

  return (
    <PopupBase
      title={`${t('actions.generate')} PDF`}
      onCrossClick={onClose}
      open
      type="prompt"
      buttons={{
        reject: {
          name: t('actions.close'),
          type: 'text',
          onClick: onClose,
        },
        confirm: {
          template: (
            <PButton
              style={{width: '100%', display: 'block'}}
              type="primary"
              htmlType="button"
              disabled={isNotSubmittable}
              loading={isButtonLoading}
              icon={<Icon.DocumentDownload stroke="currentColor" />}
              onClick={onSubmit}
            >
              {t('actions.download')}
            </PButton>
          ),
        },
      }}
    >
      <Form form={form} onFinish={handleSubmit} name="generate-pdf" className="pdf-form">
        <Row gutter={20}>
          <Col span={16}>
            <Form.Item
              name={GenerateFileFieldsEnum.PRICE}
              rules={[{required: false, message: fieldsMessageTranslations.common}]}
            >
              <PNumericInput label={t('form.price')} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={GenerateFileFieldsEnum.CURRENCY}
              rules={[{required: false, message: fieldsMessageTranslations.common}]}
            >
              <PSelect label={t('form.currency')} options={currencyOptions} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={GenerateFileFieldsEnum.MODE}
              rules={[{required: false, message: fieldsMessageTranslations.common}]}
            >
              <PSelect label={t('form.contractType')} options={contractModeOptions} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={GenerateFileFieldsEnum.INFO}
              rules={[{required: false, message: fieldsMessageTranslations.common}]}
            >
              <PInput label={t('form.addintionalNotes')} isTextarea rows={4} />
            </Form.Item>
          </Col>
          {isNotSubmittable && (
            <Col span={24}>
              <PFormLabel type="info" text={t('info.prepareForDownload')} className="!m-0" isStatic />
            </Col>
          )}
        </Row>
      </Form>
      <PropertyPDFDcoument data={dataForRender} />
    </PopupBase>
  );
};

export const PopupGeneratePDFMemoized = memo(PopupGeneratePDF);
