import {HistoryRouter as AppHistoryRouter} from 'redux-first-history/rr6';
import './i18n';

import {PopupView} from 'hooks';
import {Router} from 'router/router';
import {history} from 'store';

export const App = () => {
  return (
    <AppHistoryRouter history={history}>
      <Router></Router>
      <PopupView />
    </AppHistoryRouter>
  );
};
