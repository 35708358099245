import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form} from 'antd';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Icon} from 'assets/icons';
import {PCardWrapper} from 'components/primitives/p-card-wrapper';
import {PLoader} from 'components/primitives/p-loader';
import {RoutesEnum} from 'router/routes.enum';
import {
  ActionModesEnum,
  CrossCalculationModesEnum,
  PropertyActionsTypesEnum,
  PropertyTypesEnum,
  SubmitModesEnum,
  ContractTypesEnum,
  StatusEnum,
  ServerErrorsEnum,
} from 'enums';
import {useToast} from 'hooks';
import {TPerson} from 'types/api';
import {FormLead} from 'components/forms/lead';
import {ILeadFields} from 'components/forms/lead/form-lead.interfaces';
import {
  leadsCurrentSelector,
  leadsErrorSelector,
  leadsFilterPresetsSelector,
  leadsLoadingSelector,
} from 'store/leads/selectors';
import {
  addLeadAction,
  clearCurrentLeadAction,
  clearLeadsErrorsAction,
  getLeadByIdAction,
  hasUnsavedChangesAction,
  updateLeadAction,
} from 'store/leads/actions';
import {setLoading} from 'store/leads/slice-leads';
import {PButton} from 'components/primitives/p-button';
import {PPageTitle} from 'components/primitives/p-page-title';
import {LeadFieldsEnum, PersonFieldsEnum} from 'components/forms/lead/form-lead.enums';
import {PFormLabel} from 'components/primitives/p-form-label';
import {calculateUnitPrices, formatFormValues} from 'utils';
import {EmptyPage} from 'components/layout/empty-page';

export const PageLeadsDetailed = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {mode, id} = useParams();
  const navigate = useNavigate();
  const {contextHolder} = useToast();
  const isLoading = useSelector(leadsLoadingSelector);
  const error = useSelector(leadsErrorSelector);
  const currentLead = useSelector(leadsCurrentSelector);
  const leadsFilterPresets = useSelector(leadsFilterPresetsSelector);

  const [form] = Form.useForm<ILeadFields>();
  const values = Form.useWatch([], form);
  const [initialValues, setInitialValues] = useState<ILeadFields>();
  const fieldsForReset = useMemo(
    () => [
      [LeadFieldsEnum.SOURCE],
      [LeadFieldsEnum.TYPE],
      [LeadFieldsEnum.ADDRESS],
      [LeadFieldsEnum.MODE],
      [LeadFieldsEnum.RENT],
      [LeadFieldsEnum.ACTION],
      [LeadFieldsEnum.FLAG],
      [LeadFieldsEnum.INFO],
      [LeadFieldsEnum.TOTAL_PRICE_SALE],
      [LeadFieldsEnum.TOTAL_PRICE_MONTHLY],
      [LeadFieldsEnum.TOTAL_PRICE_DAILY],
      [LeadFieldsEnum.UNIT_PRICE],
      [LeadFieldsEnum.UNIT_PRICE_SALE],
      [LeadFieldsEnum.UNIT_PRICE_MONTHLY],
      [LeadFieldsEnum.UNIT_PRICE_DAILY],
      [LeadFieldsEnum.CURRENCY_SALE],
      [LeadFieldsEnum.CURRENCY_MONTHLY],
      [LeadFieldsEnum.CURRENCY_DAILY],
      [LeadFieldsEnum.AREA],
      [LeadFieldsEnum.LIVING_AREA],
      [LeadFieldsEnum.LAND_AREA],
      [LeadFieldsEnum.FLOOR],
      [LeadFieldsEnum.FLOORS],
      [LeadFieldsEnum.ENTRANCE_FLOOR],
      [LeadFieldsEnum.ROOMS],
      [LeadFieldsEnum.USAGE],
    ],
    []
  );
  const fieldsForInitial = useMemo(
    () => fieldsForReset.reduce((a, v: any) => ({...a, [v]: undefined}), {}),
    [fieldsForReset]
  );
  const [attachedPersons, setAttachedPersons] = useState<TPerson[]>([]);

  const [submitMode, setSubmitMode] = useState<string>('');
  const [isButtonLoading, setIsButtonLoading] = useState<Record<string, boolean>>({
    [SubmitModesEnum.CREATE_NEW]: false,
    [SubmitModesEnum.SEND]: false,
  });

  const handleSubmit = useCallback(
    (values: ILeadFields) => {
      setIsButtonLoading(prev =>
        submitMode === SubmitModesEnum.SEND
          ? {[SubmitModesEnum.CREATE_NEW]: false, [SubmitModesEnum.SEND]: true}
          : {[SubmitModesEnum.CREATE_NEW]: true, [SubmitModesEnum.SEND]: false}
      );

      const leadMode =
        values.rent?.includes(ContractTypesEnum.SALE) &&
        !values.rent?.includes(ContractTypesEnum.MONTHLY) &&
        !values.rent?.includes(ContractTypesEnum.DAILY)
          ? ContractTypesEnum.SALE
          : (values.rent?.includes(ContractTypesEnum.MONTHLY) || values.rent?.includes(ContractTypesEnum.DAILY)) &&
            !values.rent?.includes(ContractTypesEnum.SALE)
          ? ContractTypesEnum.RENT
          : values.rent?.includes(ContractTypesEnum.SALE) &&
            (values.rent?.includes(ContractTypesEnum.MONTHLY) || values.rent?.includes(ContractTypesEnum.DAILY))
          ? ContractTypesEnum.ALL
          : '';

      const rent =
        values.type !== PropertyTypesEnum.OTHER
          ? values.rent?.includes(ContractTypesEnum.MONTHLY) && values.rent?.includes(ContractTypesEnum.DAILY)
            ? ContractTypesEnum.ALL
            : values.rent?.includes(ContractTypesEnum.MONTHLY) && !values.rent?.includes(ContractTypesEnum.DAILY)
            ? ContractTypesEnum.MONTHLY
            : values.rent?.includes(ContractTypesEnum.DAILY) && !values.rent?.includes(ContractTypesEnum.MONTHLY)
            ? ContractTypesEnum.DAILY
            : ''
          : '';

      const action = values.action !== PropertyActionsTypesEnum.OTHER ? values.action : null;

      const person = {
        ...attachedPersons![0],
        email: values.email!,
        name: values.name!,
      };

      const type =
        values.action === PropertyActionsTypesEnum.OTHER || values.action === null
          ? PropertyTypesEnum.OTHER
          : values.type;

      const data = {
        ...values,
        type,
        rent,
        action,
        mode: leadMode,
        persons: [person],
      };

      const total_price_sale =
        leadMode === ContractTypesEnum.SALE || leadMode === ContractTypesEnum.ALL ? values?.total_price_sale : 0;

      const unit_price_sale =
        leadMode === ContractTypesEnum.SALE || leadMode === ContractTypesEnum.ALL ? values?.unit_price_sale : 0;

      const total_price_monthly =
        values?.rent?.includes(ContractTypesEnum.MONTHLY) || values?.rent?.includes(ContractTypesEnum.ALL)
          ? values?.total_price_monthly
          : 0;
      const total_price_daily =
        values?.rent?.includes(ContractTypesEnum.DAILY) || values?.rent?.includes(ContractTypesEnum.ALL)
          ? values?.total_price_daily
          : 0;

      const currency_sale =
        leadMode === ContractTypesEnum.SALE || leadMode === ContractTypesEnum.ALL ? values?.currency_sale : '';
      const currency_monthly =
        values?.rent?.includes(ContractTypesEnum.MONTHLY) || values?.rent?.includes(ContractTypesEnum.ALL)
          ? values?.currency_monthly
          : '';
      const currency_daily =
        values?.rent?.includes(ContractTypesEnum.DAILY) || values?.rent?.includes(ContractTypesEnum.ALL)
          ? values?.currency_daily
          : '';

      const updateData = {
        lead: id,
        ...initialValues,
        ...formatFormValues(values),
        type,
        rent,
        action,
        mode: leadMode,
        persons: [person],
        total_price_sale,
        total_price_monthly,
        total_price_daily,
        unit_price_sale,
        currency_sale,
        currency_monthly,
        currency_daily,
      };

      mode === ActionModesEnum.CREATE
        ? dispatch(
            addLeadAction(
              {...data},
              {
                mode: submitMode,
                onFulfilled: () => {
                  setIsButtonLoading({[SubmitModesEnum.CREATE_NEW]: false, [SubmitModesEnum.SEND]: false});
                  dispatch(hasUnsavedChangesAction(false));
                },
                onReject: () => {
                  setIsButtonLoading({
                    [SubmitModesEnum.CREATE_NEW]: false,
                    [SubmitModesEnum.SEND]: false,
                  });
                },
              }
            )
          )
        : dispatch(
            updateLeadAction(updateData, {
              mode: submitMode,
              filterPresets: leadsFilterPresets,
              onFulfilled: () => {
                setIsButtonLoading({[SubmitModesEnum.CREATE_NEW]: false, [SubmitModesEnum.SEND]: false});
                dispatch(hasUnsavedChangesAction(false));
              },
              onReject: () => {
                setIsButtonLoading({
                  [SubmitModesEnum.CREATE_NEW]: false,
                  [SubmitModesEnum.SEND]: false,
                });
              },
            })
          );

      if (submitMode === SubmitModesEnum.CREATE_NEW) {
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });

          dispatch(setLoading(true));
        }, 650);

        setTimeout(() => {
          form.resetFields(fieldsForReset);
          form.setFieldsValue(fieldsForInitial);
          setInitialValues(fieldsForInitial);
        }, 1500);

        setTimeout(() => {
          dispatch(setLoading(false));
        }, 2000);

        setAttachedPersons(data.persons);
      }
    },
    [
      mode,
      id,
      attachedPersons,
      initialValues,
      submitMode,
      fieldsForReset,
      fieldsForInitial,
      form,
      leadsFilterPresets,
      dispatch,
    ]
  );

  const handleFormSubmition = useCallback((type: string) => {
    if (type === SubmitModesEnum.SEND) {
      setSubmitMode(SubmitModesEnum.SEND);
    } else {
      setSubmitMode(SubmitModesEnum.CREATE_NEW);
    }
  }, []);

  const handlePriceChange = useCallback(
    (value: number | string | null, mode: CrossCalculationModesEnum, fieldName?: string) => {
      calculateUnitPrices({
        values,
        form,
        value,
        mode,
        fieldName,
        FiledsEnum: LeadFieldsEnum,
        fieldTotalSale: LeadFieldsEnum.TOTAL_PRICE_SALE,
        fieldUnitSale: LeadFieldsEnum.UNIT_PRICE_SALE,
        fieldTotalMonthly: LeadFieldsEnum.TOTAL_PRICE_MONTHLY,
        fieldUnitMonthly: LeadFieldsEnum.UNIT_PRICE_MONTHLY,
      });
    },
    [form, values]
  );

  useEffect(() => {
    if (attachedPersons) {
      form.setFieldValue(PersonFieldsEnum.NAME, attachedPersons[0]?.name);
      form.setFieldValue(
        PersonFieldsEnum.PHONE,
        attachedPersons[0]?.phone
          ? attachedPersons[0]?.phone
          : attachedPersons[0]?.phones
          ? attachedPersons[0]?.phones[0]?.number
          : null
      );
      form.setFieldValue(PersonFieldsEnum.EMAIL, attachedPersons[0]?.email);
    }
  }, [attachedPersons, form]);

  useEffect(() => {
    if (id) {
      dispatch(clearCurrentLeadAction());
      dispatch(getLeadByIdAction({id}));
    } else {
      dispatch(clearCurrentLeadAction());
    }
  }, [id, dispatch]);

  useEffect(() => {
    form.resetFields(fieldsForReset);
    form.setFieldsValue(fieldsForInitial);
    setInitialValues(fieldsForInitial);

    if (id && currentLead) {
      const {
        mode,
        action,
        address,
        info,
        type,
        id,
        persons,
        rent,
        area,
        total_price_monthly,
        total_price_daily,
        total_price_sale,
        currency_sale,
        currency_monthly,
        currency_daily,
        unit_price_daily,
        unit_price_monthly,
        unit_price_sale,
        ...rest
      } = currentLead;

      const initialAction = type === PropertyTypesEnum.OTHER ? PropertyActionsTypesEnum.OTHER : action;
      const initialType = type === PropertyTypesEnum.OTHER ? '' : type;

      const rentToSet = [];
      (mode === ContractTypesEnum.SALE || mode === ContractTypesEnum.ALL) && rentToSet.push(ContractTypesEnum.SALE);
      (rent === ContractTypesEnum.MONTHLY || rent === ContractTypesEnum.ALL) &&
        rentToSet.push(ContractTypesEnum.MONTHLY);
      (rent === ContractTypesEnum.DAILY || rent === ContractTypesEnum.ALL) && rentToSet.push(ContractTypesEnum.DAILY);

      const salePrice = mode === ContractTypesEnum.SALE || mode === ContractTypesEnum.ALL ? total_price_sale : null;
      const monthlyPrice =
        rent === ContractTypesEnum.MONTHLY || rent === ContractTypesEnum.ALL ? total_price_monthly : null;
      const dailyPrice = rent === ContractTypesEnum.DAILY || rent === ContractTypesEnum.ALL ? total_price_daily : null;

      const saleCurrency = mode === ContractTypesEnum.SALE || mode === ContractTypesEnum.ALL ? currency_sale : '';
      const monthlyCurrency =
        rent === ContractTypesEnum.MONTHLY || rent === ContractTypesEnum.ALL ? currency_monthly : '';
      const dailyCurrency = rent === ContractTypesEnum.DAILY || rent === ContractTypesEnum.ALL ? currency_daily : '';

      const initialValues = {
        action: initialAction,
        info,
        type: initialType,
        persons,
        address,
        area,
        mode,
        rent: rentToSet,
        total_price_monthly: monthlyPrice,
        total_price_daily: dailyPrice,
        total_price_sale: salePrice,
        currency_sale: saleCurrency,
        currency_monthly: monthlyCurrency,
        currency_daily: dailyCurrency,
        unit_price_daily,
        unit_price_monthly,
        unit_price_sale,
        ...rest,
      };

      form.setFieldsValue(initialValues!);

      setInitialValues(initialValues!);

      setAttachedPersons(initialValues.persons);
    }
  }, [id, currentLead, form, fieldsForInitial, fieldsForReset, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearLeadsErrorsAction());
    };
  }, [dispatch]);

  if (
    !isLoading &&
    mode === ActionModesEnum.UPDATE &&
    (error === ServerErrorsEnum.INVALID_ID || error?.response?.status === 404)
  ) {
    return <EmptyPage showHeader />;
  } else if (
    (mode !== ActionModesEnum.UPDATE && mode !== ActionModesEnum.VIEW && mode !== ActionModesEnum.CREATE) ||
    (currentLead && mode === ActionModesEnum.UPDATE && currentLead?.status === StatusEnum.FINALIZED) ||
    currentLead?.status === StatusEnum.CANCELED
  ) {
    return <Navigate to={RoutesEnum.LEADS} />;
  } else {
    return isLoading ? (
      <PLoader />
    ) : (
      <PLoader spinning={isLoading} asLayer>
        <PPageTitle
          text={
            <PButton
              type="text"
              theme="white"
              icon={<Icon.ChevronLeftRounded fill="currentColor" />}
              onClick={() => navigate(-1)}
            >
              {t('actions.back')}
            </PButton>
          }
        />

        <PCardWrapper padding="md" className="pb-0">
          <PFormLabel
            text={
              mode === ActionModesEnum.CREATE
                ? `${t('actions.add')} ${t('common.lead')}`
                : `${t('actions.change')} ${t('common.lead')}`
            }
            isStatic
            className="mb-6"
          />
          <FormLead
            mode={mode}
            form={form}
            values={values}
            initialValues={initialValues}
            attachedPersons={attachedPersons}
            isButtonLoading={isButtonLoading}
            setAttachedPersons={setAttachedPersons}
            handlePriceChange={handlePriceChange}
            handleFormSubmition={handleFormSubmition}
            onSubmit={handleSubmit}
          />
        </PCardWrapper>
        {contextHolder}
      </PLoader>
    );
  }
};
