import {
  FlagsTranslationEnum,
  InquiryStateTranslationEnum,
  PropertyTypesTranslationEnum,
  StatusTranslationEnum,
} from 'enums/translations';

export const pieLabels = {
  status: StatusTranslationEnum,
  state: InquiryStateTranslationEnum,
  type: PropertyTypesTranslationEnum,
  flag: FlagsTranslationEnum,
} as const;
