import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Divider, Form, RadioChangeEvent, Row} from 'antd';
import {Link} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import {useTranslation} from 'react-i18next';

import {Icon} from 'assets/icons';
import {PButton} from 'components/primitives/p-button';
import {PCardWrapper} from 'components/primitives/p-card-wrapper';
import {PLoader} from 'components/primitives/p-loader';
import {PPageTitle} from 'components/primitives/p-page-title';
import {PTable} from 'components/primitives/p-table';
import {ActionModesEnum, BuildingTypeCommonEnum, FoldersEnum, PaginationEnum} from 'enums';
import {getBuildingsAction, saveBuildingFilterPresetsAction} from 'store/buildings/actions';
import {buildingsCountSelector, buildingsListSelector, buildingsLoadingSelector} from 'store/buildings/selectors';
import {PRadio} from 'components/primitives/p-radio';
import {FormBuildingFilter} from 'components/forms/building-filter';
import {IBuildingFilterFields} from 'components/forms/building-filter/form-building-filter.interfaces';
import {RoutesEnum} from 'router/routes.enum';
import {PLightbox} from 'components/primitives/p-lightbox';
import {clearMediaAction, getFolderByIdAction} from 'store/app/actions';
import {appCurrentImageSelector} from 'store/app/selectors';
import {Image} from 'assets/images';
import {clearFromEmptyKeys, formatPhoneNumber, injectMediaPath, omittedParams} from 'utils';
import {useFilterPresets, useQueryParams} from 'hooks';
import {TPaginationOptions, TSortOptions} from 'types/common';
import {LISTING_LENGTH, LISTING_OFFSET, paginationDefaultOptions, paginationEmptyOptions} from 'constants/pagination';

import {columns} from './constants/columns';

export const PageBuildings = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const handlePresetsSave = useFilterPresets();
  const isLoading = useSelector(buildingsLoadingSelector);
  const buildings = useSelector(buildingsListSelector);
  const buildingsCount = useSelector(buildingsCountSelector);
  const currentImage = useSelector(appCurrentImageSelector);
  const params = useQueryParams();

  const [buildingType, setBuildingType] = useState<BuildingTypeCommonEnum>(params.type || BuildingTypeCommonEnum.ALL);
  const [filterForm] = Form.useForm<IBuildingFilterFields>();
  const filterValues = Form.useWatch([], filterForm);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [paginationOptions, setPaginationOptions] = useState<TPaginationOptions>({
    [PaginationEnum.PAGE]: params[PaginationEnum.PAGE] || 1,
    [PaginationEnum.OFFSET]: params[PaginationEnum.OFFSET] || LISTING_OFFSET,
    [PaginationEnum.LENGTH]: params[PaginationEnum.LENGTH] || LISTING_LENGTH,
  });

  const presets = useMemo(
    () => (buildingType === BuildingTypeCommonEnum.ALL ? null : {type: buildingType}),
    [buildingType]
  );

  const filterInitialValuesFromParams = useMemo(() => {
    const {type, length, ...rest} = omittedParams(params);

    return clearFromEmptyKeys<IBuildingFilterFields>({...rest});
  }, [params]);

  const [isLighboxVisible, setIsLighboxVisible] = useState<boolean>(false);
  const [isLighboxLoading, setIsLighboxLoading] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<number>(0);
  const [galleryId, setGalleryId] = useState<number>(0);
  const [srcReservePath, setSrcReservePath] = useState<string[]>([]);
  const [isFetchedFromParams, setIsFetchedFromParams] = useState<boolean>(Object.keys(params).length > 0);

  const handleFilterSubmit = useCallback(
    (values: IBuildingFilterFields) => {
      setIsButtonLoading(true);

      const {phone, ...rest} = values;

      const filterQueries = {
        ...rest,
        ...presets,
        phone: formatPhoneNumber(phone!),
      };

      handlePresetsSave({
        route: RoutesEnum.BUILDINGS,
        params: {...params, ...paginationEmptyOptions, ...values, phone: formatPhoneNumber(phone!)},
        action: saveBuildingFilterPresetsAction,
      });

      setPaginationOptions(paginationDefaultOptions);

      dispatch(
        getBuildingsAction(
          {...filterQueries},
          {onFulfilled: () => setIsButtonLoading(false), onReject: () => setIsButtonLoading(false)}
        )
      );
    },
    [presets, params, dispatch, handlePresetsSave]
  );

  const handleFilterClear = useCallback(() => {
    handlePresetsSave({
      route: RoutesEnum.BUILDINGS,
      params: {...presets},
      action: saveBuildingFilterPresetsAction,
    });

    setIsFetchedFromParams(false);
    setPaginationOptions(paginationDefaultOptions);

    dispatch(getBuildingsAction({...presets}));

    filterForm.resetFields();
  }, [filterForm, presets, dispatch, handlePresetsSave]);

  const handleBuildingTypeChange = useCallback(
    (e: RadioChangeEvent) => {
      setBuildingType(e.target.value);

      handlePresetsSave({
        route: RoutesEnum.BUILDINGS,
        params: {...params, ...paginationEmptyOptions, type: e.target.value},
        action: saveBuildingFilterPresetsAction,
      });

      setPaginationOptions(paginationDefaultOptions);

      setIsFetchedFromParams(false);
    },
    [params, handlePresetsSave]
  );

  const handleTableChange = useCallback(
    (pagination: TPaginationOptions, sorter: TSortOptions) => {
      setPaginationOptions(pagination);

      handlePresetsSave({
        route: RoutesEnum.BUILDINGS,
        params: {...params, ...pagination},
        action: saveBuildingFilterPresetsAction,
      });
    },
    [params, handlePresetsSave]
  );

  const beforeLightboxOpen = useCallback(
    (entityId: number, id?: number) => {
      dispatch(clearMediaAction());

      setEntityId(entityId);
      setGalleryId(id!);
      setIsLighboxLoading(true);
    },
    [dispatch]
  );

  const onLightboxClose = useCallback(() => {
    setGalleryId(0);
    setEntityId(0);
    setTimeout(() => setIsLighboxLoading(false), 600);

    if (!isLighboxLoading) {
      setIsLighboxVisible(false);
    }
  }, [isLighboxLoading]);

  useEffect(() => {
    const type = {type: buildingType === BuildingTypeCommonEnum.ALL ? null : buildingType};
    const filterPresets = filterForm.getFieldsValue(true);
    const pagination = {offset: paginationOptions.offset, length: paginationOptions.length};

    dispatch(clearMediaAction());
    dispatch(saveBuildingFilterPresetsAction(filterPresets));

    dispatch(getBuildingsAction({...pagination, ...type, ...filterPresets}));
  }, [buildingType, filterForm, isFetchedFromParams, paginationOptions, dispatch]);

  useEffect(() => {
    if (galleryId) {
      dispatch(
        getFolderByIdAction(
          {
            id: galleryId,
          },
          {folder: FoldersEnum.IMAGE}
        )
      );

      setIsLighboxVisible(true);
    }
  }, [galleryId, dispatch]);

  return (
    <PLoader spinning={isLoading} asLayer>
      <PCardWrapper padding="md">
        <Row justify="space-between">
          <Col>
            <PPageTitle text={t('pages.buildings.title')} />
          </Col>
          <Col>
            <Link to={`${RoutesEnum.BUILDINGS}/${ActionModesEnum.CREATE}`}>
              <PButton type="primary" icon={<Icon.AddCircle fill="white" />}>
                {t('actions.add')}
              </PButton>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <PRadio
              onChange={handleBuildingTypeChange}
              defaultValue={buildingType}
              options={[
                {label: t('common.all'), value: BuildingTypeCommonEnum.ALL},
                {label: t('catalog.propertyTypes.residental'), value: BuildingTypeCommonEnum.RESIDENTIAL},
                {label: t('catalog.propertyTypes.commercial'), value: BuildingTypeCommonEnum.COMMERCIAL},
              ]}
            ></PRadio>
          </Col>
        </Row>
        <Divider />
        <FormBuildingFilter
          buildingType={buildingType}
          isButtonLoading={isButtonLoading}
          onSubmit={handleFilterSubmit}
          onReset={handleFilterClear}
          form={filterForm}
          values={filterValues}
          initialValues={filterInitialValuesFromParams}
        />
      </PCardWrapper>

      <PCardWrapper padding="md">
        <PTable
          showLeged
          loading={isLoading}
          columns={columns({beforeLightboxOpen, isLighboxLoading, entityId, buildingType, translation: t})}
          paginationOptions={{
            total: buildingsCount,
            defaultPageSize: paginationOptions.length,
            pageSize: paginationOptions.length,
            defaultCurrent: paginationOptions.page,
            current: paginationOptions.page,
          }}
          dataSource={buildings}
          rowKey={() => uuidv4()}
          handleChange={handleTableChange}
        />
      </PCardWrapper>

      {currentImage && (
        <div className="hidden">
          <PLightbox
            showThumbnails={true}
            modalClose="clickOutside"
            open={isLighboxVisible}
            onClose={onLightboxClose}
            className="hidden"
          >
            {currentImage?.files.map((img: any) => (
              <img
                key={img}
                onError={() => {
                  setSrcReservePath([...srcReservePath, img.path]);
                }}
                src={srcReservePath.includes(img.path) ? Image.noImage : injectMediaPath(img?.path)}
                width="100%"
                alt="pic"
                className={isLighboxVisible ? '' : 'hidden'}
              />
            ))}
          </PLightbox>
        </div>
      )}
    </PLoader>
  );
};
