import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {TServerResponseError} from 'types/common';

import {PropertiesState} from './types';

const sliceProperties = createSlice({
  name: 'properties',
  initialState: {
    loading: false,
    error: null,
    properties: [],
    propertiesCount: 0,
    currentProperty: null,
    archivedProperty: null,
    holdedProperty: null,
    updatedPrice: null,
    updatedComment: null,
    refreshedPrice: null,
    removedHold: null,
    propertyFilterPresets: {},
    hasUnsavedChanges: false,
    disabled: null,
    enabled: null,
  },
  reducers: {
    setLoading(state: PropertiesState, {payload}: PayloadAction<PropertiesState['loading']>) {
      state.loading = payload;
    },
    setErrors(state: PropertiesState, {payload}: PayloadAction<TServerResponseError | null>) {
      state.error = payload;
    },
    setProperties(state: PropertiesState, {payload}: PayloadAction<PropertiesState['properties']>) {
      state.properties = payload;
    },
    setPropertiesCount(state: PropertiesState, {payload}: PayloadAction<PropertiesState['propertiesCount']>) {
      state.propertiesCount = payload;
    },
    setCurrentProperty(state: PropertiesState, {payload}: PayloadAction<PropertiesState['currentProperty']>) {
      state.currentProperty = payload;
    },
    setArchivedProperty(state: PropertiesState, {payload}: PayloadAction<PropertiesState['archivedProperty']>) {
      state.archivedProperty = payload;
    },
    setHoldedProperty(state: PropertiesState, {payload}: PayloadAction<PropertiesState['holdedProperty']>) {
      state.holdedProperty = payload;
    },
    setUpdatedPrice(state: PropertiesState, {payload}: PayloadAction<PropertiesState['updatedPrice']>) {
      state.updatedPrice = payload;
    },
    setRefreshedPrice(state: PropertiesState, {payload}: PayloadAction<PropertiesState['refreshedPrice']>) {
      state.refreshedPrice = payload;
    },
    setUpdatedComment(state: PropertiesState, {payload}: PayloadAction<PropertiesState['updatedComment']>) {
      state.updatedComment = payload;
    },
    setRemovedHold(state: PropertiesState, {payload}: PayloadAction<PropertiesState['removedHold']>) {
      state.removedHold = payload;
    },
    setPropertyFilterPresets(
      state: PropertiesState,
      {payload}: PayloadAction<PropertiesState['propertyFilterPresets']>
    ) {
      state.propertyFilterPresets = payload;
    },
    setHasUnsavedChanges(state: PropertiesState, {payload}: PayloadAction<PropertiesState['hasUnsavedChanges']>) {
      state.hasUnsavedChanges = payload;
    },
    setDisabled(state: PropertiesState, {payload}: PayloadAction<PropertiesState['disabled']>) {
      state.disabled = payload;
    },
    setEnabled(state: PropertiesState, {payload}: PayloadAction<PropertiesState['enabled']>) {
      state.enabled = payload;
    },
  },
});

export const {
  setErrors,
  setLoading,
  setProperties,
  setPropertiesCount,
  setCurrentProperty,
  setArchivedProperty,
  setHoldedProperty,
  setUpdatedPrice,
  setRefreshedPrice,
  setUpdatedComment,
  setRemovedHold,
  setPropertyFilterPresets,
  setHasUnsavedChanges,
  setDisabled,
  setEnabled,
} = sliceProperties.actions;

export const propertiesReducer = sliceProperties.reducer;
