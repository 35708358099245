export enum BuildingFieldsEnum {
  ADDRESS = 'address',
  STREET = 'street',
  FLOORS = 'floors',
  INFO = 'info',
  TYPE = 'type',
  SUB_TYPE = 'subtype',
  HEATING = 'heating',
  FEE = 'fee',
  PLAN = 'plan',
  NUMBER = 'number',
  SETTLEMENT = 'settlement',
  FILE_FOLDER = 'fileFolder',
  IMAGE_FOLDER = 'imageFolder',
  FILE_FOLDER_INITIAL = 'file_folder',
  IMAGE_FOLDER_INITIAL = 'image_folder',
  PERSONS = 'persons',
  ACTIVE = 'active',
  BUILDING = 'building',
  STONE = 'stone',
  NAME = 'name',
  PHONE = 'phone',

  MIN_FLOORS = 'minFloors',
  MAX_FLOORS = 'maxFloors',
  MIN_FEE = 'minFee',
  MAX_FEE = 'maxFee',
}
