export enum PropertyAddonsFieldsEnum {
  NAME = 'name',
  NAME_OTHER = 'name_other',
  DESCRIPTION = 'description',
  FILE_FOLDER = 'file_folder',
  IMAGE_FOLDER = 'image_folder',
  AREA = 'area',
  HEIGHT = 'height',
  COMMERCIAL = 'commercial',
  APARTMENT = 'apartment',
  HOUSE = 'house',
}
