import {memo, useCallback, useEffect, useState} from 'react';
import copy from 'copy-to-clipboard';

import {Icon} from 'assets/icons';

const CTextCopy = ({text, asLink}: {text?: string; asLink?: boolean}) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const onCopy = useCallback(
    (e: any) => {
      e.stopPropagation();

      setIsCopied(true);
      copy(text!);
    },
    [text]
  );

  useEffect(() => {
    const timeout = () =>
      setTimeout(() => {
        setIsCopied(false);
      }, 500);

    if (isCopied) {
      timeout();
    }

    return () => clearTimeout(timeout());
  }, [isCopied]);

  return (
    <>
      <span
        className={`${
          isCopied ? 'text-success' : ''
        } my-1 inline-block leading-[1] [&>span]:last:hidden [&>b]:hover:inline-block`}
      >
        <b className="min-w-[19px] min-h-[11px] hidden relative cursor-pointer" onClick={onCopy}>
          <Icon.CopySuccess
            width={16}
            height={16}
            className={`${isCopied ? 'text-success' : 'text-primary'} absolute top-[-2px] left-0`}
          />
        </b>

        <a
          href={asLink ? `to:${text}` : undefined}
          className={`text-inherit ${!asLink ? `hover:text-inherit cursor-default` : ''}`}
        >
          {text}
        </a>

        <span>,&nbsp;</span>
      </span>
    </>
  );
};

export const CTextCopyMemoized = memo(CTextCopy);
