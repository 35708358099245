import {memo, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Row} from 'antd';
import {useTranslation} from 'react-i18next';

import {getPropertyTotalsAction} from 'store/analytics/actions';
import {analyticsLoadingSelector, analyticsPropertyTotalsSelector} from 'store/analytics/selectors';
import {CPie} from 'components/containers/c-pie';
import {PLoader} from 'components/primitives/p-loader';

export const PropertyAnalyticsView = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(analyticsLoadingSelector);
  const propertyTotals = useSelector(analyticsPropertyTotalsSelector);

  useEffect(() => {
    dispatch(getPropertyTotalsAction());
  }, [dispatch]);

  return isLoading ? (
    <PLoader />
  ) : (
    <Row gutter={[20, 20]}>
      <Col span={12}>
        <CPie headline={t('common.propertyCount')} data={{key: 'type', items: propertyTotals?.type!}} />
      </Col>
      <Col span={12}>
        <CPie headline={t('common.propertyStatus')} data={{key: 'status', items: propertyTotals?.status!}} />
      </Col>
    </Row>
  );
};

export const PropertyAnalyticsViewMemoized = memo(PropertyAnalyticsView);
