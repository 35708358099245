import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Divider, Form, RadioChangeEvent, Row} from 'antd';
import {Link} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';

import {Icon} from 'assets/icons';
import {PButton} from 'components/primitives/p-button';
import {PCardWrapper} from 'components/primitives/p-card-wrapper';
import {PLoader} from 'components/primitives/p-loader';
import {PPageTitle} from 'components/primitives/p-page-title';
import {PTable} from 'components/primitives/p-table';
import {PRadio} from 'components/primitives/p-radio';
import {
  clearCurrentLeadAction,
  saveLeadsFilterPresetsAction,
  searchLeadsAction,
  updateLeadAction,
} from 'store/leads/actions';
import {leadsCountSelector, leadsListSelector, leadsLoadingSelector} from 'store/leads/selectors';
import {FormLeadsFilter} from 'components/forms/leads-filter';
import {ILeadsFilterFields} from 'components/forms/leads-filter/form-leads-filter.interfaces';
import {appCatalogSelector} from 'store/app/selectors';
import {RoutesEnum} from 'router/routes.enum';
import {
  StatusEnum,
  ActionModesEnum,
  PropertyActionsTypesEnum,
  PropertyTypesEnum,
  SortOrderEnum,
  PaginationEnum,
} from 'enums';
import {LeadFilterFieldsEnum} from 'components/forms/leads-filter/form-leads-filter.enums';
import {
  checkForArrayValidity,
  clearFromEmptyKeys,
  formatDate,
  formatFormValues,
  formatPhoneNumber,
  injectedContractMode,
  omittedParams,
} from 'utils';
import {useOutsideClick} from 'hooks';
import {ILeadFields} from 'components/forms/lead/form-lead.interfaces';
import {useQueryParams, useFilterPresets, useToast} from 'hooks';
import {LeadFieldsEnum} from 'components/forms/lead/form-lead.enums';
import {LISTING_LENGTH, LISTING_OFFSET, paginationDefaultOptions, paginationEmptyOptions} from 'constants/pagination';
import {TPaginationOptions, TSortOptions} from 'types/common';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {DAY_END, DAY_START} from 'constants/time';

import {columns} from './constants/columns';

export const PageLeads = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {contextHolder, showError} = useToast();
  const isLoading = useSelector(leadsLoadingSelector);
  const catalog = useSelector(appCatalogSelector);
  const leads = useSelector(leadsListSelector);
  const leadsCount = useSelector(leadsCountSelector);
  const flagRef = useRef<any>(null);
  const params = useQueryParams();
  const handlePresetsSave = useFilterPresets();

  const [filterForm] = Form.useForm<ILeadsFilterFields>();
  const filterValues = Form.useWatch([], filterForm);

  const filterInitialValuesFromParams = useMemo(() => {
    const {entrance_date, mode, 'status[]': status, ...rest} = omittedParams(params);

    const valuesFromParams = {
      ...rest,
      [LeadFieldsEnum.MODE]: checkForArrayValidity(mode),
      [LeadFieldsEnum.ENTRANCE_DATE]: entrance_date
        ? [dayjs(new Date(entrance_date[0])), dayjs(new Date(entrance_date[1]))]
        : null,
    };

    return clearFromEmptyKeys<ILeadsFilterFields>(valuesFromParams);
  }, [params]);

  const [statusType, setStatusType] = useState<StatusEnum>(params['status[]'] || StatusEnum.ALL);
  const [flagEntity, setFlagEntity] = useState<number>(0);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isFlagSelectVisibile, setIsFlagSelectVisibile] = useState<boolean>(false);
  const [isFlagEditLoading, setIsFlagEditLoading] = useState<boolean>(false);
  const [filterPresets, setFilterPresets] = useState<Record<string, string | string[] | number | null | undefined>>({});
  const [statusPreset, setStatusPreset] = useState<Record<string, string | number | null>>({});
  const [paginationOptions, setPaginationOptions] = useState<TPaginationOptions>({
    [PaginationEnum.PAGE]: params[PaginationEnum.PAGE] || 1,
    [PaginationEnum.OFFSET]: params[PaginationEnum.OFFSET] || LISTING_OFFSET,
    [PaginationEnum.LENGTH]: params[PaginationEnum.LENGTH] || LISTING_LENGTH,
  });
  const [sortOptions, setSortOptions] = useState<TSortOptions>({
    [SortOrderEnum.SORT]: params[SortOrderEnum.SORT] || null,
    [SortOrderEnum.ASC]: params[SortOrderEnum.ASC] || null,
    [SortOrderEnum.DESC]: params[SortOrderEnum.DESC] || null,
  });
  const [isFetchedFromParams, setIsFetchedFromParams] = useState<boolean>(Object.keys(params).length > 0);

  const handleFilterSubmit = useCallback(
    (values: ILeadsFilterFields) => {
      setIsButtonLoading(true);

      const status = {'status[]': statusType === StatusEnum.ALL ? null : statusType};

      const {type, action, mode, entrance_date, phone, ...rest} = values;

      const filterQueries = {
        ...rest,
        phone: formatPhoneNumber(phone!),
        'mode[]': injectedContractMode(mode).mode,
        'rent[]': injectedContractMode(mode).rent,
        action: action === PropertyActionsTypesEnum.OTHER ? null : action,
        type: action === PropertyActionsTypesEnum.OTHER ? PropertyTypesEnum.OTHER : type,
        [LeadFilterFieldsEnum.MIN_CREATED]:
          entrance_date && entrance_date![0] ? `${formatDate({value: entrance_date![0]})} ${DAY_START}` : null,
        [LeadFilterFieldsEnum.MAX_CREATED]:
          entrance_date && entrance_date![1] ? `${formatDate({value: entrance_date![1]})} ${DAY_END}` : null,
      };

      setStatusPreset(status);
      setFilterPresets(filterQueries);

      const paramValues = {...values, phone: formatPhoneNumber(phone!), rent: injectedContractMode(mode).rent};

      handlePresetsSave({
        route: RoutesEnum.LEADS,
        params: {...params, ...paramValues, ...paginationEmptyOptions},
        action: saveLeadsFilterPresetsAction,
      });

      setPaginationOptions(paginationDefaultOptions);

      dispatch(
        searchLeadsAction(
          {...status, ...filterQueries},
          {onFulfilled: () => setIsButtonLoading(false), onReject: () => setIsButtonLoading(false)}
        )
      );
    },
    [statusType, params, handlePresetsSave, dispatch]
  );

  const handleFilterClear = useCallback(() => {
    const status = {'status[]': statusType === StatusEnum.ALL ? null : statusType};

    filterForm.resetFields();

    handlePresetsSave({
      route: RoutesEnum.LEADS,
      params: {...status},
      action: saveLeadsFilterPresetsAction,
    });

    setIsFetchedFromParams(false);
    setPaginationOptions(paginationDefaultOptions);

    dispatch(searchLeadsAction({...status}));
  }, [filterForm, statusType, handlePresetsSave, dispatch]);

  const handleStatusTypeChange = useCallback(
    (e: RadioChangeEvent) => {
      setStatusType(e.target.value);

      handlePresetsSave({
        route: RoutesEnum.LEADS,
        params: {...params, ...paginationEmptyOptions, 'status[]': e.target.value},
        action: saveLeadsFilterPresetsAction,
      });

      setPaginationOptions(paginationDefaultOptions);

      setIsFetchedFromParams(false);
    },
    [params, handlePresetsSave]
  );

  const onFlagEdit = useCallback(
    (value: ILeadFields) => {
      const pagination = {offset: paginationOptions.offset, length: paginationOptions.length};

      setIsFlagEditLoading(true);

      dispatch(
        updateLeadAction(formatFormValues(value), {
          allowRedirect: false,
          onFulfilled: () => {
            handlePresetsSave({
              route: RoutesEnum.LEADS,
              params: {...paginationOptions, ...statusPreset, ...filterPresets},
              action: saveLeadsFilterPresetsAction,
            });

            dispatch(
              searchLeadsAction(
                {...pagination, ...statusPreset, ...filterPresets},
                {
                  isWithoutLoading: true,
                  onFulfilled: () => {
                    setIsFlagEditLoading(false);
                    setIsFlagSelectVisibile(false);
                    setFlagEntity(0);
                  },
                  onReject: () => {
                    setIsFlagEditLoading(false);
                    setIsFlagSelectVisibile(false);
                    setFlagEntity(0);
                  },
                }
              )
            );
          },
          onReject: () => {
            setIsFlagEditLoading(false);
            setIsFlagSelectVisibile(false);
            setFlagEntity(0);
            showError({message: fieldsMessageTranslations.commonErrors});
          },
        })
      );
    },
    [statusPreset, filterPresets, paginationOptions, handlePresetsSave, showError, dispatch]
  );

  useOutsideClick(flagRef, () => {
    setIsFlagSelectVisibile(false);
  });

  const handleTableChange = useCallback(
    (pagination: TPaginationOptions, sorter: TSortOptions) => {
      setPaginationOptions(pagination);
      setSortOptions(sorter);

      handlePresetsSave({
        route: RoutesEnum.LEADS,
        params: {...params, ...pagination, ...sorter},
        action: saveLeadsFilterPresetsAction,
      });
    },
    [params, handlePresetsSave]
  );

  useEffect(() => {
    const status = {'status[]': statusType === StatusEnum.ALL ? null : statusType};

    const {action, mode, type, entrance_date, phone, ...rest} = filterForm.getFieldsValue(true);

    const presets = {
      ...rest,
      'mode[]': injectedContractMode(mode).mode,
      'rent[]': injectedContractMode(mode).rent,
      action: action === PropertyActionsTypesEnum.OTHER ? null : action,
      type: action === PropertyActionsTypesEnum.OTHER ? PropertyTypesEnum.OTHER : type,
      [LeadFilterFieldsEnum.MIN_CREATED]:
        entrance_date && entrance_date![0] ? `${formatDate({value: entrance_date![0]})} ${DAY_START}` : null,
      [LeadFilterFieldsEnum.MAX_CREATED]:
        entrance_date && entrance_date![1] ? `${formatDate({value: entrance_date![1]})} ${DAY_END}` : null,
      phone: formatPhoneNumber(phone),
      offset: paginationOptions.offset,
      length: paginationOptions.length,
      ...sortOptions,
    };

    setStatusPreset(status);
    setFilterPresets(presets);

    dispatch(searchLeadsAction({...status, ...presets}));

    dispatch(clearCurrentLeadAction());
  }, [statusType, filterForm, isFetchedFromParams, paginationOptions, sortOptions, dispatch]);

  return (
    <PLoader spinning={isLoading} asLayer>
      <PCardWrapper padding="md">
        <Row justify="space-between">
          <Col>
            <PPageTitle text={t('pages.leads.title')} />
          </Col>
          <Col>
            <Link to={`${RoutesEnum.LEADS}/${ActionModesEnum.CREATE}`}>
              <PButton type="primary" icon={<Icon.AddCircle fill="white" />}>
                {t('actions.add')}
              </PButton>
            </Link>
          </Col>
        </Row>
        <Divider />
        <FormLeadsFilter
          form={filterForm}
          values={filterValues}
          initialValues={filterInitialValuesFromParams}
          isButtonLoading={isButtonLoading}
          onSubmit={handleFilterSubmit}
          onReset={handleFilterClear}
        />
      </PCardWrapper>
      <Row justify="space-between" className="mb-5">
        <Col>
          <PRadio
            onChange={handleStatusTypeChange}
            defaultValue={statusType}
            optionType="button"
            buttonStyle="outline"
            size="large"
            options={[
              {label: t('common.all'), value: StatusEnum.ALL},
              {label: t('catalog.statuses.new'), value: StatusEnum.NEW},
              {label: t('catalog.statuses.current'), value: StatusEnum.CURRENT},
              {label: t('catalog.statuses.final'), value: StatusEnum.FINALIZED},
              {label: t('catalog.statuses.canceled'), value: StatusEnum.CANCELED},
            ]}
          ></PRadio>
        </Col>
      </Row>
      <PCardWrapper padding="md">
        <PTable
          showLeged
          loading={isLoading}
          columns={columns({
            flagRef,
            isFlagEditLoading,
            catalog,
            flagEntity,
            isFlagSelectVisibile,
            sortOptions,
            handleFlagSelectVisibility: (e: React.MouseEvent<HTMLElement>, value: number) => {
              e.stopPropagation();
              setIsFlagSelectVisibile(true);
              setFlagEntity(value);
            },
            onFlagEdit,
            translation: t,
          })}
          paginationOptions={{
            total: leadsCount,
            defaultPageSize: paginationOptions.length,
            pageSize: paginationOptions.length,
            defaultCurrent: paginationOptions.page,
            current: paginationOptions.page,
          }}
          dataSource={leads}
          rowKey={() => uuidv4()}
          handleChange={handleTableChange}
        />
      </PCardWrapper>
      {contextHolder}
    </PLoader>
  );
};
