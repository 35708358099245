import {memo, useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Col, Form, Row} from 'antd';
import {AnyAction} from 'redux';

import {PFormLabel} from 'components/primitives/p-form-label';
import {FormAnalyticsPerformanceFilter} from 'components/forms/analytics-performance-filter';
import {IAnalyticsPerformanceFields} from 'components/forms/analytics-performance-filter/form-analytics-performance-filter.interfaces';
import {PTable} from 'components/primitives/p-table';
import {TAnalyticsPerformance} from 'types/api';
import {FormatsEnum, PerformanceTypeEnum, SeparatorEnum} from 'enums';
import {formatDate} from 'utils';
import {TActionSettings} from 'types/common';

import {performanceColumns} from './constants/columns';

export const CPerformanceTable = ({
  headline,
  type,
  dataSource,
  action,
}: {
  headline?: string;
  type: `${PerformanceTypeEnum}`;
  action: (payload?: any, settings?: TActionSettings) => AnyAction;
  dataSource: TAnalyticsPerformance[];
}) => {
  const dispatch = useDispatch();
  const [filterForm] = Form.useForm<IAnalyticsPerformanceFields>();
  const filterValues = Form.useWatch([], filterForm);
  const [isFilterLoading, setIsFilterLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFilterSubmit = useCallback(
    (values: any) => {
      setIsFilterLoading(true);
      setIsLoading(true);

      dispatch(
        action(
          {
            start: formatDate({
              value: values ? values[0] : '',
              format: FormatsEnum.REVERSE,
              separator: SeparatorEnum.HYPHEN,
            }),
            end: formatDate({
              value: values ? values[1] : '',
              format: FormatsEnum.REVERSE,
              separator: SeparatorEnum.HYPHEN,
            }),
          },
          {
            onFulfilled: () => {
              setIsFilterLoading(false);
              setIsLoading(false);
            },
            onReject: () => {
              setIsFilterLoading(false);
              setIsLoading(false);
            },
          }
        )
      );
    },
    [action, dispatch]
  );

  const handleFilterReset = useCallback(() => {
    filterForm.resetFields();
    setIsLoading(true);

    dispatch(
      action(
        {},
        {
          onFulfilled: () => {
            setIsLoading(false);
          },
          onReject: () => {
            setIsLoading(false);
          },
        }
      )
    );
  }, [filterForm, action, dispatch]);

  useEffect(() => {
    setIsLoading(true);

    dispatch(
      action(
        {},
        {
          onFulfilled: () => {
            setIsLoading(false);
          },
          onReject: () => {
            setIsLoading(false);
          },
        }
      )
    );
  }, [dispatch, action]);

  return (
    <div className="border border-solid border-grey-20 rounded-[18px] p-[20px]">
      <Row gutter={20} justify="space-between">
        <Col span={12}>{headline && <PFormLabel isStatic text={headline} />}</Col>
        <Col span={5}>
          <FormAnalyticsPerformanceFilter
            type={type}
            form={filterForm}
            values={filterValues}
            isButtonLoading={isFilterLoading}
            onSubmit={handleFilterSubmit}
            onReset={handleFilterReset}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PTable
            paginationOptions={{pageSize: 6}}
            columns={performanceColumns({type})}
            dataSource={dataSource}
            loading={isLoading}
            rowKey="id"
          />
        </Col>
      </Row>
    </div>
  );
};

export const CPerformanceTableMemoized = memo(CPerformanceTable);
