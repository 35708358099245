import {ReactNode} from 'react';

import {Icon} from 'assets/icons';
import {RoutesEnum} from 'router/routes.enum';
import {palette} from 'constants/theme';
import {RolesEnum} from 'enums';
import {TTranslation} from 'types/common';

export const navLinks = ({
  translation,
}: TTranslation): Array<{label: string; icon: ReactNode; href: RoutesEnum; visibleTo?: Array<RolesEnum>}> => [
  {
    label: translation('links.leads'),
    href: RoutesEnum.LEADS,
    icon: <Icon.Category width={24} height={24} fill={palette.grey[80]} />,
    visibleTo: [RolesEnum.SUPER, RolesEnum.ADMIN, RolesEnum.INFO],
  },
  {
    label: translation('links.buildings'),
    href: RoutesEnum.BUILDINGS,
    icon: <Icon.Building width={24} height={24} fill={palette.grey[80]} />,
    visibleTo: [RolesEnum.SUPER, RolesEnum.ADMIN, RolesEnum.BROKER],
  },
  {
    label: translation('links.properties'),
    href: RoutesEnum.PROPERTIES,
    icon: <Icon.House width={24} height={24} fill={palette.grey[80]} />,
    visibleTo: [RolesEnum.SUPER, RolesEnum.BROKER, RolesEnum.MARKETING],
  },
  {
    label: translation('links.inquiries'),
    href: RoutesEnum.INQUIRIES,
    icon: <Icon.TaskSquare width={24} height={24} fill={palette.grey[80]} />,
    visibleTo: [RolesEnum.SUPER, RolesEnum.ADMIN, RolesEnum.BROKER],
  },
  {
    label: translation('links.persons'),
    href: RoutesEnum.PERSONS,
    icon: <Icon.UserGroup width={24} height={24} fill={palette.grey[80]} />,
    visibleTo: [RolesEnum.SUPER, RolesEnum.ADMIN, RolesEnum.BROKER, RolesEnum.INFO],
  },
  {
    label: translation('links.accounts'),
    href: RoutesEnum.ACCOUNTS,
    icon: <Icon.User width={24} height={24} fill={palette.grey[80]} />,
    visibleTo: [RolesEnum.SUPER, RolesEnum.ADMIN],
  },
  {
    label: translation('links.reports'),
    href: RoutesEnum.ANALYTICS,
    icon: <Icon.Chart width={24} height={24} fill={palette.grey[80]} />,
    visibleTo: [RolesEnum.SUPER],
  },
];

export const extraLinks = ({
  translation,
  onLogout,
}: {
  onLogout: () => void;
} & TTranslation): Array<{
  label: string;
  icon: ReactNode;
  href?: string;
  textColor?: string;
  asButton?: boolean;
  visibleTo?: Array<RolesEnum>;
  onClick?: () => void;
}> => [
  // {
  //   label: translation('links.settings'),
  //   href: RoutesEnum.SETTINGS,
  //   icon: <Icon.Setting width={24} height={24} fill={palette.grey[80]} />,
  //   visibleTo: [RolesEnum.SUPER, RolesEnum.ADMIN, RolesEnum.INFO, RolesEnum.BROKER, RolesEnum.MARKETING],
  // },
  {
    asButton: true,
    label: translation('links.logout'),
    textColor: 'text-danger',
    icon: <Icon.LogOut width={24} height={24} color="#FF1B1B" />,
    visibleTo: [RolesEnum.SUPER, RolesEnum.ADMIN, RolesEnum.INFO, RolesEnum.BROKER, RolesEnum.MARKETING],
    onClick: onLogout,
  },
];
