import {memo, useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {Divider, Tooltip} from 'antd';
import {useTranslation} from 'react-i18next';
import './property-addon.css';

import {Image} from 'assets/images';
import {PCarousel} from 'components/primitives/p-carousel';
import {PLightbox} from 'components/primitives/p-lightbox';
import {TServerResponseError} from 'types/common';
import {getFolderService} from 'services/app';
import {Icon} from 'assets/icons';
import {palette} from 'constants/theme';
import {PFileEntity} from 'components/primitives/p-file-entity';
import {injectMediaPath} from 'utils';

const PropertyAddon = ({entity, ...props}: {entity: any}) => {
  const {t} = useTranslation();
  const [srcReservePath, setSrcReservePath] = useState<string[]>([]);
  const [isLighboxVisible, setIsLighboxVisible] = useState({
    property: false,
    building: false,
  });
  const [lighboxStartIndex, setLighboxStartIndex] = useState<number>(0);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (entity && entity?.image_folder) {
      (async () => {
        try {
          const {data}: {data: {success: any; error: TServerResponseError}} = await getFolderService({
            id: entity?.image_folder?.id,
          });

          const images = data?.success?.files?.map((file: any) => ({...file, caption: entity.name}));

          setFiles(images);
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [entity]);

  return (
    <div className="bg-white rounded-[12px] border border-solid border-[#ECECEC] p-2 w-full max-w-[162px] flex flex-col">
      <div className="overflow-hidden relative min-h-[90px] bg-grey-20 rounded-[6px] mb-2">
        {files.length > 0 && (
          <div className="m-auto relative image max-h-[90px] overflow-hidden">
            <PCarousel dots={false} arrows={false} className="rounded-[6px] overflow-hidden">
              {files?.map((img: any, i: number) => (
                <img
                  key={`${uuidv4()}`}
                  onError={() => {
                    setSrcReservePath([...srcReservePath, img.path]);
                  }}
                  src={srcReservePath.includes(img.path) ? Image.noImage : injectMediaPath(img?.path)}
                  onClick={e => {
                    e.stopPropagation();
                    setIsLighboxVisible(prev => ({...prev, property: true}));
                    setLighboxStartIndex(i);
                  }}
                  width="100%"
                  className="cursor-pointer"
                  alt="pic"
                />
              ))}
            </PCarousel>
            <div className="hidden">
              <PLightbox
                showThumbnails={true}
                modalClose="clickOutside"
                open={isLighboxVisible.property}
                startingSlideIndex={lighboxStartIndex}
                onClose={() => setIsLighboxVisible(prev => ({...prev, property: false}))}
                images={files}
                className="hidden"
              >
                {files?.map((img: any) => (
                  <img
                    key={`${uuidv4()}`}
                    onError={() => {
                      setSrcReservePath([...srcReservePath, img.path]);
                    }}
                    src={srcReservePath.includes(img.path) ? Image.noImage : injectMediaPath(img?.path)}
                    width="100%"
                    alt="pic"
                  />
                ))}
              </PLightbox>
            </div>
          </div>
        )}
        {files?.length > 1 && (
          <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[1] pointer-events-none text-white font-bold">
            {`+${files?.length - 1}`}
          </div>
        )}
      </div>
      <div className="flex flex-col gap-1 h-full">
        {entity.name && <div className="text-[15px] font-medium text-grey-common line-clamp-2">{entity.name}</div>}
        {(entity.height || entity.area) && (
          <>
            <div className="flex gap-2 item-center">
              {entity.area && (
                <Tooltip title={t('form.area')}>
                  <div className="flex item-center gap-0.5 text-[12px] text-[#A4A1A2] line-clamp-2">
                    <Icon.Ruler width={15} height={13} fill={palette.primary} />
                    {entity.area} {t('shorts.squareMeter')}
                  </div>
                </Tooltip>
              )}{' '}
              -
              {entity.height && (
                <Tooltip title={t('form.height')}>
                  <div className="flex item-center gap-0.5 text-[12px] text-[#A4A1A2] line-clamp-2">
                    <Icon.ArrowUp width={14} height={13} fill={palette.primary} />
                    {entity.height} մ
                  </div>
                </Tooltip>
              )}
            </div>
          </>
        )}
        {entity.description && (
          <div className="grow">
            <Divider className="my-1.5" />
            <div className="text-[13px] text-[#A4A1A2] line-clamp-2" title={entity.description}>
              {entity.description}
            </div>
          </div>
        )}

        {entity.file_folder?.files?.length ? (
          <div className="mt-2 w-full">
            <div className="rounded-[8px] px-2 py-1 bg-grey-40 max-w-full">
              {entity.file_folder?.files.map((file: any) => <PFileEntity key={file?.name} entity={file} size="sm" />)}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const PropertyAddonMemoized = memo(PropertyAddon);
