import {Col, Row, Upload, UploadFile, UploadProps} from 'antd';
import {memo, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import type {DragEndEvent} from '@dnd-kit/core';
import {closestCenter} from '@dnd-kit/core';
import {DndContext, PointerSensor, useSensor} from '@dnd-kit/core';
import {arrayMove, SortableContext} from '@dnd-kit/sortable';
import {useTranslation} from 'react-i18next';

import {Icon} from 'assets/icons';
import {PButton} from 'components/primitives/p-button';
import {addFolderAction, updateFolderAction} from 'store/app/actions';
import {
  appBuildingImageSelector,
  appCurrentBuildingImageSelector,
  appCurrentFloorImageSelector,
  appCurrentImageSelector,
  appCurrentRoomImageSelector,
  appFloorImageSelector,
  appImageSelector,
  appRoomImageSelector,
} from 'store/app/selectors';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {PFormLabel} from 'components/primitives/p-form-label';
import {injectFormattedFile} from 'utils';
import {IMAGE_SIZE, MAX_UPLOAD_LENGTH} from 'constants/sizes';
import {useToast} from 'hooks';
import {ImageViewEnum, AddonsEnum, FoldersEnum, HandledFieldsEnum} from 'enums';
import {IUploadedFile} from 'types/common';
import {TFile} from 'types/api';

import {CImageUploaderProps} from './c-image-uploader.types';
import {DraggableUploadListItem, PreviewGallery} from './components';

const config = ({isReadonly}: {isReadonly?: boolean}): UploadProps => ({
  listType: 'picture-card',
  multiple: true,
  defaultFileList: [],
  showUploadList: {
    showPreviewIcon: true,
    previewIcon: <Icon.Eye width={22} height={22} color="white" />,
    showRemoveIcon: !isReadonly,
    removeIcon: <Icon.CloseSquare width={22} height={22} color="white" />,
  },
});

const CImageUploader = ({
  id,
  isImagesAttached = true,
  addon,
  initialFiles,
  isDraggable,
  isReadonly = false,
  onUploadClick,
  ...props
}: CImageUploaderProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {contextHolder, showError, showSuccess, showInfo} = useToast();

  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [lighboxStartIndex, setLighboxStartIndex] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actions, setActions] = useState<Record<string, boolean>>({
    isDragTouched: false,
    isViewChanged: false,
  });
  const [isShowUploadButton, setIsShowUploadButton] = useState<boolean>(false);
  const [isShowClearButton, setIsShowClearButton] = useState<boolean>(false);
  const [uploadErrors, setUploadErrors] = useState<Record<string, boolean>>({maxFiles: false, fileSize: false});
  const [uploadedFiles, setUploadedFiles] = useState<IUploadedFile[]>([]);
  const [removals, setRemovals] = useState<number[]>([]);

  const image = useSelector(appImageSelector);
  const roomImage = useSelector(appRoomImageSelector);
  const floorImage = useSelector(appFloorImageSelector);
  const buildingImage = useSelector(appBuildingImageSelector);
  const currentImage = useSelector(appCurrentImageSelector);
  const currentRoomImage = useSelector(appCurrentRoomImageSelector);
  const currentFloorImage = useSelector(appCurrentFloorImageSelector);
  const currentBuildingImage = useSelector(appCurrentBuildingImageSelector);

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {distance: 10},
  });

  const beforeUpload = useCallback((file: UploadFile, fileList: any) => {
    return false;
  }, []);

  const handleChange: UploadProps['onChange'] = useCallback(
    ({file, fileList}: {file: any; fileList: any}) => {
      const duplicates = uploadedFiles.map((f: any) => (f.name === file.name ? file.name : null)).filter(f => f);
      const newFiles = fileList.filter((f: any) => !f.id);
      const largeFiles = fileList.filter((f: any) => f.size > IMAGE_SIZE);

      if (!newFiles.length) {
        setIsShowClearButton(false);
        setIsShowUploadButton(false);
        onUploadClick && onUploadClick(HandledFieldsEnum.IS_IMAGES_ATTACHED, undefined);
      }

      setUploadErrors(prev => ({...prev, maxFiles: newFiles.length > MAX_UPLOAD_LENGTH, fileSize: largeFiles.length}));

      if (duplicates.length) return;

      setUploadedFiles(fileList);
      setIsShowUploadButton(true);
      setIsShowClearButton(true);
    },
    [uploadedFiles, onUploadClick]
  );

  const handleFilesClear = useCallback(() => {
    setUploadedFiles(prev => {
      const newFiles = prev.filter((f: any) => !f.id);
      const filtered = prev.filter((f: any) => f.id);
      const isNewFilesCleared = prev.length - filtered.length === newFiles.length;

      if (isNewFilesCleared) {
        setIsShowClearButton(false);
      }

      if (isNewFilesCleared && !removals.length) {
        setIsShowUploadButton(false);
        onUploadClick && onUploadClick(HandledFieldsEnum.IS_IMAGES_ATTACHED, undefined);
      }

      return filtered;
    });

    setUploadErrors(prev => ({...prev, maxFiles: false, fileSize: false}));
  }, [removals, onUploadClick]);

  const handleFileRemove: UploadProps['onRemove'] = useCallback(
    (file: IUploadedFile) => {
      setUploadedFiles(uploadedFiles.filter((f: any) => f.uid !== file.uid));

      if (file.removal) {
        setRemovals(prev => [...prev, file.removal!]);
        setIsShowUploadButton(true);
      }
    },
    [uploadedFiles]
  );

  const onPreviewCancel = useCallback(() => setIsPreviewOpen(false), []);

  const onPreviewOpen = useCallback(
    async (file: UploadFile) => {
      setIsPreviewOpen(true);
      setLighboxStartIndex(uploadedFiles.indexOf(file));
    },
    [uploadedFiles]
  );

  const onViewChange = useCallback(
    ({file, view}: {file: any; view: string[]}) => {
      const updated = uploadedFiles.map((f: any) => {
        if (f.uid === file.uid) {
          f.view = view.length
            ? view.includes(ImageViewEnum.PRESENTATION) && view.includes(ImageViewEnum.WEBSITE)
              ? ImageViewEnum.ALL
              : view[0]
            : ImageViewEnum.NONE;
        }

        return f;
      });

      setUploadedFiles(updated);
      setIsShowUploadButton(true);
      setActions(prev => ({...prev, isViewChanged: true}));
    },
    [uploadedFiles]
  );

  const onDragEnd = useCallback(({active, over}: DragEndEvent) => {
    if (active.id !== over?.id) {
      setUploadedFiles(prev => {
        const activeIndex = prev?.findIndex(i => i.uid === active.id);
        const overIndex = prev?.findIndex(i => i.uid === over?.id);

        return arrayMove(prev, activeIndex, overIndex);
      });

      setIsShowUploadButton(true);
      setActions(prev => ({...prev, isDragTouched: true}));
    }
  }, []);

  const handleUploadFiles = useCallback(() => {
    const folder =
      addon === AddonsEnum.ROOMS
        ? currentRoomImage
        : addon === AddonsEnum.FLOORS || addon === AddonsEnum.COMMERCIAL
        ? currentFloorImage
        : addon === AddonsEnum.BUILDING_IMAGE
        ? currentBuildingImage
        : currentImage;

    const recentlyCreatedFolder =
      addon === AddonsEnum.ROOMS
        ? roomImage
        : addon === AddonsEnum.FLOORS || addon === AddonsEnum.COMMERCIAL
        ? floorImage
        : addon === AddonsEnum.BUILDING_IMAGE
        ? buildingImage
        : image;

    const existedFolder = folder || recentlyCreatedFolder;

    const updates = uploadedFiles
      .map((file, i) => {
        if (file.id) {
          return {
            view: file.view,
            order: i + 1,
            id: file.id,
          };
        }

        return undefined;
      })
      .filter(item => item !== undefined);

    const uploads = uploadedFiles
      .map((file, i) => {
        if (!file.id) {
          return {
            order: i + 1,
            view: file.view || ImageViewEnum.NONE,
          };
        }

        return undefined;
      })
      .filter(item => item !== undefined);

    const list = uploadedFiles.filter(f => !f.removal).map(f => f.originFileObj);

    setIsLoading(true);
    showInfo({message: t('info.imagesSaving')});

    if (existedFolder) {
      dispatch(
        updateFolderAction(
          {
            folder: existedFolder.id || existedFolder,
            updates: [...updates],
            uploads: [...uploads],
            removals: [...removals],
            'uploadfiles[]': [...list],
          },
          {
            folder: FoldersEnum.IMAGE,
            addon,
            onFulfilled: data => {
              const updatedList = data.map((file: TFile) => injectFormattedFile(file, FoldersEnum.IMAGE));

              setRemovals([]);
              setUploadedFiles(updatedList);
              setIsLoading(false);
              setIsShowUploadButton(false);
              setIsShowClearButton(false);
              setActions(prev => {
                Object.keys(prev).forEach(v => (prev[v as keyof typeof prev] = false));

                return prev;
              });
              onUploadClick && onUploadClick(HandledFieldsEnum.IS_IMAGES_EMPTY, !data.length ? true : false);
              showSuccess({message: t('info.imagesSaved')});
            },
            onReject: () => {
              setIsLoading(false);
              showError({message: fieldsMessageTranslations.commonErrors});
            },
          }
        )
      );
    } else {
      dispatch(
        addFolderAction(
          {
            uploads: [...uploads],
            'uploadfiles[]': [...list],
          },
          {
            folder: FoldersEnum.IMAGE,
            addon,
            onFulfilled: data => {
              const createdList = data.map((file: TFile) => injectFormattedFile(file, FoldersEnum.IMAGE));

              setUploadedFiles(createdList);
              setIsLoading(false);
              setIsShowUploadButton(false);
              setIsShowClearButton(false);
              setActions(prev => {
                Object.keys(prev).forEach(v => (prev[v as keyof typeof prev] = false));

                return prev;
              });
              onUploadClick && onUploadClick(HandledFieldsEnum.IS_IMAGES_EMPTY, !data.length ? true : false);
              showSuccess({message: t('info.imagesSaved')});
            },
            onReject: () => {
              setIsLoading(false);
              showError({message: fieldsMessageTranslations.commonErrors});
            },
          }
        )
      );
    }

    onUploadClick && onUploadClick(HandledFieldsEnum.IS_IMAGES_ATTACHED, true);
  }, [
    image,
    roomImage,
    floorImage,
    buildingImage,
    currentImage,
    currentRoomImage,
    currentFloorImage,
    currentBuildingImage,
    removals,
    uploadedFiles,
    addon,
    dispatch,
    onUploadClick,
    showError,
    showInfo,
    showSuccess,
    t,
  ]);

  useEffect(() => {
    if (initialFiles) {
      const list = initialFiles?.files.map((file: TFile) => injectFormattedFile(file, FoldersEnum.IMAGE));

      setUploadedFiles(list);
    } else {
      setUploadedFiles([]);
    }
  }, [initialFiles]);

  return (
    <>
      <DndContext sensors={[sensor]} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
        <SortableContext items={uploadedFiles.map(i => i.uid)}>
          {!uploadedFiles.length && isReadonly && (
            <div className="w-[130px] h-[130px] flex items-center justify-center bg-grey-60 rounded-sm">
              <Icon.Picture width={40} height={40} />
            </div>
          )}

          <Upload
            className="image-uploader"
            onPreview={onPreviewOpen}
            onChange={handleChange}
            onRemove={handleFileRemove}
            beforeUpload={beforeUpload}
            fileList={uploadedFiles}
            defaultFileList={uploadedFiles}
            itemRender={(originNode, file, fileList) => (
              <DraggableUploadListItem
                isDraggable={fileList.length > 1 && !isReadonly}
                originNode={originNode}
                file={file}
                onViewChange={onViewChange}
                setUploadedFiles={setUploadedFiles}
                setIsShowUploadButton={setIsShowUploadButton}
                setRemovals={setRemovals}
                isReadonly={isReadonly}
              />
            )}
            {...config({isReadonly})}
            {...props}
          >
            {!isReadonly ? <Icon.AddSquare /> : null}
          </Upload>

          {!isReadonly && (
            <Row gutter={15}>
              {(isShowUploadButton || removals.length > 0 || actions.isDragTouched || actions.isViewChanged) && (
                <Col>
                  <PButton
                    id={`image-upload-complete${id ? `-${id}` : ''}`}
                    loading={isLoading}
                    disabled={uploadErrors.maxFiles || uploadErrors.fileSize}
                    size="middle"
                    type="primary"
                    icon={<Icon.Upload fill="currentColor" />}
                    onClick={handleUploadFiles}
                  >
                    {t('actions.save')}
                  </PButton>
                </Col>
              )}
              {isShowClearButton && uploadedFiles.length > 0 && (
                <Col>
                  <PButton
                    disabled={isLoading}
                    size="middle"
                    type="default"
                    scheme="success"
                    icon={<Icon.CloseCircle fill="currentColor" />}
                    onClick={handleFilesClear}
                  >
                    {t('actions.clear')}
                  </PButton>
                </Col>
              )}
            </Row>
          )}
        </SortableContext>
      </DndContext>

      <PreviewGallery
        uploadedFiles={uploadedFiles}
        isPreviewOpen={isPreviewOpen}
        lighboxStartIndex={lighboxStartIndex}
        onPreviewCancel={onPreviewCancel}
      />

      {!isImagesAttached && (
        <PFormLabel type="danger" text={fieldsMessageTranslations.endImageAttach} isStatic className="!m-2" />
      )}
      {uploadErrors.maxFiles ? (
        <PFormLabel type="danger" text={fieldsMessageTranslations.uploadedFilesLength} isStatic className="!m-2" />
      ) : null}
      {uploadErrors.fileSize ? (
        <PFormLabel type="danger" text={fieldsMessageTranslations.fileSize} isStatic className="!m-2" />
      ) : null}
      {contextHolder}
    </>
  );
};

export const CImageUploaderMemoized = memo(CImageUploader);
