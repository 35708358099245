import {memo, useEffect, useMemo, useRef} from 'react';

import {calculateTotal, createPie, getTranslation, randomRgb} from 'utils';
import {pieColors} from 'constants/pie-colors';
import {Icon} from 'assets/icons';
import {PCountUp} from 'components/primitives/p-count-up';

import {IPieData, IPieProps} from './c-pie.types';
import {pieLabels} from './constants/pie-labels';
import {pieIcons} from './constants/pie-icons';

const CPie = ({headline, data}: IPieProps) => {
  const canvasRef = useRef(null);
  const pieData = useMemo(
    (): IPieData => ({
      total: calculateTotal({data: data?.items?.map(item => item.cnt)}),
      pies: data?.items?.map((d, i) => {
        const isContainsName = d[data.key] && typeof d[data.key] === 'object';
        const iconLib = pieIcons[data.key as keyof typeof pieIcons];
        const labelLib = pieLabels[data.key as keyof typeof pieLabels];

        return {
          count: d.cnt,
          color: pieColors[i] || randomRgb(),
          label: isContainsName ? d[data.key].name : getTranslation(labelLib, d[data.key]),
          icon: isContainsName
            ? iconLib['default' as keyof typeof iconLib]
            : iconLib[d[data.key] as keyof typeof iconLib] || <Icon.Chart width={20} color="white" />,
        };
      }),
    }),
    [data]
  );

  useEffect(() => {
    createPie({canvas: canvasRef.current, data: pieData.pies, total: pieData.total as number});
  }, [pieData]);

  return (
    <div className="rounded-[24px] border border-solid border-grey-20 p-[32px] flex flex-wrap justify-center content-start gap-[32px] h-full">
      {headline && <div className="w-full text-[20px] text-grey-common font-bold text-center">{headline}</div>}

      <div className="relative w-[256px] h-[256px]">
        <canvas className="pie-canvas fadein" ref={canvasRef} />
        <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white w-[calc(100%_-_55px)] h-[calc(100%_-_55px)] rounded-full flex items-center justify-center text-[60px] font-[900] text-grey-common">
          <PCountUp end={pieData.total as number} />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-[16px] w-full">
        {pieData.pies?.map(({count, label, color, icon}) =>
          pieData.pies?.length > 0 && label ? (
            <div
              className="flex items-center justify-between min-h-[48px] border border-solid border-grey-20 rounded-[8px] px-[18px] py-[8px]"
              key={color}
            >
              <div className="flex items-center gap-[8px]">
                <div
                  className="flex items-center justify-center w-[32px] h-[32px] rounded-full"
                  style={{backgroundColor: color}}
                >
                  {icon}
                </div>
                <div className="text-grey-common text-sm">{label}</div>
              </div>
              <div className="text-base font-black text-grey-common">
                <PCountUp end={count} />{' '}
              </div>
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export const CPieMemoized = memo(CPie);
