import {memo, useCallback, useEffect, useState} from 'react';
import {Slider} from 'antd';
import Cropper from 'react-easy-crop';
import {RcFile} from 'antd/es/upload';
import {useTranslation} from 'react-i18next';

import {Icon} from 'assets/icons';
import {getCroppedImage} from 'utils';
import {palette} from 'constants/theme';
import {PopupBase} from 'components/popups/base';

import {CImageCropperProps} from './c-image-cropper.types';

const props = {
  aspect: 4 / 3,
  style: {
    containerStyle: {
      minHeight: 200,
      height: '100%',
      objectPosition: 'center',
    },
  } as const,
};

const config = {minZoom: 1, maxZoom: 3, zoomStep: 0.1, minRotation: 0, maxRotation: 360, rotationStep: 1} as const;

const CImageCropper = ({
  originalFile,
  imageUrl,
  imageFile,
  isOpen,
  onClose,
  setUploadedFiles,
  setIsShowUploadButton,
  setRemovals,
}: CImageCropperProps) => {
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [cropSize, setCropSize] = useState({width: 0, height: 0});
  const [zoom, setZoom] = useState<number>(1);
  const [rotation, setRotation] = useState<number>(config.minRotation);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [file, setFile] = useState<string | ArrayBuffer>();

  useEffect(() => {
    if (imageFile) {
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.addEventListener('load', () => {
        setFile(reader.result!);
      });
    }
  }, [imageFile]);

  const onCropChange = useCallback((crop: any) => {
    setCrop(crop);
  }, []);

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onMediaLoaded = useCallback((mediaSize: any) => {
    setCropSize({width: mediaSize.width, height: mediaSize.height});
  }, []);

  const onZoom = useCallback((val: number) => {
    setZoom(val);
  }, []);

  const onRotation = useCallback((val: number) => {
    setRotation(val);
  }, []);

  const onFinish = async () => {
    setIsLoading(true);

    try {
      const {item, url} = (await getCroppedImage(file || imageUrl, croppedAreaPixels, rotation, originalFile)) as any;

      const newFile = new File([item], item.name) as RcFile;

      if (item || url) {
        onClose();

        setUploadedFiles(prev => [
          ...prev.filter(f => f.name !== item.name),
          {uid: item.uid, name: item.name, thumbUrl: url, originFileObj: newFile},
        ]);

        if (originalFile.removal) {
          setRemovals(prev => [...prev, originalFile.removal!]);
        }

        setIsShowUploadButton(true);
      }
    } catch (error: any) {
      console.error({
        isAlert: true,
        severity: 'error',
        message: error.message,
        timeout: 5000,
        location: 'modal',
      });
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PopupBase
      title={t('common.changeImage')}
      size="lg"
      onCrossClick={onClose}
      open={isOpen}
      buttons={{
        position: 'right',
        reject: {
          name: t('actions.close'),
          type: 'text',
          onClick: onClose,
        },
        confirm: {
          name: t('actions.save'),
          type: 'primary',
          htmlType: 'button',
          loading: isLoading,
          onClick: onFinish,
        },
      }}
    >
      <div className="min-h-[550px] relative mb-5">
        <Cropper
          {...props}
          image={imageUrl ? imageUrl : (file as string)}
          rotation={rotation}
          crop={crop}
          zoom={zoom}
          minZoom={config.minZoom}
          maxZoom={config.maxZoom}
          objectFit="vertical-cover"
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          onZoomChange={onZoom}
          onRotationChange={onRotation}
          onMediaLoaded={onMediaLoaded}
          cropSize={cropSize}
        />
      </div>
      <div>
        <div className="flex items-center justify-between">
          Zoom <span>{Math.round(zoom * 100)}%</span>
        </div>
        <Slider
          min={config.minZoom}
          max={config.maxZoom}
          step={config.zoomStep}
          defaultValue={1}
          value={zoom}
          onChange={onZoom}
          tooltip={{open: false}}
        />
      </div>
      <div className="mb-10">
        <div className="flex items-center justify-between">
          Rotation <span>{rotation}</span>
        </div>
        <Slider
          min={config.minRotation}
          max={config.maxRotation}
          step={10}
          defaultValue={0}
          onChange={onRotation}
          tooltip={{open: false}}
          marks={{
            0: '-',
            90: <Icon.RotateLeft width={20} fill={palette.primary} />,
            180: <Icon.Renew width={20} color={palette.primary} />,
            270: <Icon.RotateRight width={20} fill={palette.primary} />,
            360: '-',
          }}
        />
      </div>
    </PopupBase>
  );
};

export const CImageCropperMemoized = memo(CImageCropper);
