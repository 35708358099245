import {Col, Form, Row} from 'antd';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {PInput} from 'components/primitives/p-input';
import {Icon} from 'assets/icons';
import {PButton} from 'components/primitives/p-button';
import {ActionModeHero} from 'constants/translations';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {PPhoneInput} from 'components/primitives/p-phone-input';
import {FormsEnum} from 'enums';

import {PersonFieldsEnum} from './form-person.enums';
import {PersonFormProps} from './form-person.types';

export const FormPersonEdit = ({
  form,
  values,
  initialValues = {phones: [undefined]},
  mode,
  onSumbit,
}: PersonFormProps) => {
  const {t} = useTranslation();
  const columnSize = useMemo(() => (mode === ActionModeHero.add ? 6 : 8), [mode]);

  return (
    <Form form={form} onFinish={onSumbit} name={FormsEnum.PERSON_EDIT}>
      <Row gutter={20}>
        <Col span={columnSize}>
          <Form.Item name={PersonFieldsEnum.NAME} rules={[{required: true}]} initialValue={initialValues?.name}>
            <PInput label={t('form.firstName')} id="firstname" />
          </Form.Item>
        </Col>
        <Col span={columnSize}>
          <Form.Item
            name={PersonFieldsEnum.EMAIL}
            rules={[
              {
                type: 'email',
                message: fieldsMessageTranslations.validEmail,
              },
              {required: false},
            ]}
            initialValue={initialValues?.email}
          >
            <PInput label={t('form.email')} id="new-person-email" type="email" autoComplete="on" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.List
            name={PersonFieldsEnum.PHONES}
            initialValue={initialValues?.phones?.length ? initialValues?.phones : [{}]}
          >
            {(fields, {add, remove}) => {
              return (
                <>
                  <Row gutter={20}>
                    {fields.map(({key, name, ...restField}, index) => (
                      <Col span={columnSize} key={key}>
                        <Form.Item
                          {...restField}
                          name={[name, 'number']}
                          rules={[
                            {
                              required: true,
                              message: fieldsMessageTranslations.fillOrRemove,
                            },
                            {min: 9, message: fieldsMessageTranslations.phoneMin},
                            {max: 14, message: fieldsMessageTranslations.phoneMax},
                          ]}
                        >
                          <PPhoneInput
                            label={t('form.phone')}
                            id={`person-phone-${index}`}
                            suffix={
                              fields.length > 1 ? (
                                <Icon.CloseCircle cursor="pointer" onClick={() => remove(name)} />
                              ) : (
                                <span />
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    ))}
                  </Row>

                  <PButton size="middle" onClick={() => add()} icon={<Icon.AddCircle fill="currentColor" />}>
                    {t('form.addPhone')}
                  </PButton>
                </>
              );
            }}
          </Form.List>
        </Col>
      </Row>
    </Form>
  );
};
