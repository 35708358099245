import {combineReducers} from 'redux';
import {configureStore} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import {all} from 'redux-saga/effects';
import {createReduxHistoryContext} from 'redux-first-history';
import {createBrowserHistory} from 'history';

import {appReducer} from './app';
import {appSaga} from './app/sagas';
import {authReducer} from './auth/slice-auth';
import {authSaga} from './auth/sagas';
import {accountsSaga} from './account/sagas';
import {accountsReducer} from './account';
import {buildingsReducer} from './buildings/slice-buildings';
import {buildingSaga} from './buildings/sagas';
import {leadsSaga} from './leads/sagas';
import {leadsReducer} from './leads';
import {propertiesReducer} from './properties';
import {propertySaga} from './properties/sagas';
import {inquiriesSaga} from './inquiries/sagas';
import {inquiriesReducer} from './inquiries';
import {marketingReducer} from './marketing';
import {marketingSaga} from './marketing/sagas';
import {personsSaga} from './persons/sagas';
import {personsReducer} from './persons';
import {analyticsReducer} from './analytics';
import {analyticsSaga} from './analytics/sagas';

const sagas = [
  appSaga,
  authSaga,
  accountsSaga,
  personsSaga,
  leadsSaga,
  buildingSaga,
  propertySaga,
  inquiriesSaga,
  marketingSaga,
  analyticsSaga,
];

const {createReduxHistory, routerMiddleware, routerReducer} = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const reducers = {
  app: appReducer,
  analytics: analyticsReducer,
  auth: authReducer,
  accounts: accountsReducer,
  persons: personsReducer,
  leads: leadsReducer,
  buildings: buildingsReducer,
  properties: propertiesReducer,
  inquiries: inquiriesReducer,
  marketing: marketingReducer,
  router: routerReducer,
};

const saga = function* () {
  yield all(sagas.map(saga => saga()));
};

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers(reducers);
const store = configureStore({
  reducer,
  middleware: getDefaultMiddleWare =>
    getDefaultMiddleWare({
      serializableCheck: false,
    }).concat(sagaMiddleware, routerMiddleware),
});

sagaMiddleware.run(saga);

const history = createReduxHistory(store);

export {store, history};
