import {Radio} from 'antd';
import {memo} from 'react';

import {PRadiotProps} from './p-radio.types';
import {CSS} from './p-radio.cssconfig';

const PRadio = ({label, options, isWrapper, ...props}: PRadiotProps) => {
  return options ? (
    <Radio.Group className={isWrapper ? CSS.groupWrapper : CSS.base} options={options} {...props} />
  ) : (
    <Radio className={CSS.base} {...props}>
      {label}
    </Radio>
  );
};

export const PRadioMemoized = memo(PRadio);
