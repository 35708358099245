import {Input} from 'antd';
import {memo} from 'react';

import {PFormLabel} from 'components/primitives/p-form-label';
import {Icon} from 'assets/icons';

import {PInputProps} from './p-input.types';

const {TextArea, Password} = Input;

const PInput = ({id, label, size = 'large', isTextarea, isPassword, allowClear, ...props}: PInputProps) => {
  return (
    <div className="relative">
      {label && <PFormLabel text={label} id={id} />}

      {isTextarea ? (
        <TextArea
          id={id}
          {...props}
          allowClear={
            allowClear
              ? {
                  clearIcon: <Icon.CloseCircle width={20} height={20} className="relative top-[5px] left-[-2px]" />,
                }
              : undefined
          }
        />
      ) : isPassword ? (
        <Password
          size={size}
          id={id}
          {...props}
          iconRender={visible => (visible ? <Icon.EyeSlash cursor="pointer" /> : <Icon.Eye cursor="pointer" />)}
        />
      ) : (
        <Input
          id={id}
          size={size}
          {...props}
          allowClear={
            allowClear
              ? {
                  clearIcon: <Icon.CloseCircle width={20} height={20} className="relative top-[2px]" />,
                }
              : undefined
          }
        />
      )}
    </div>
  );
};

export const PInputMemoized = memo(PInput);
