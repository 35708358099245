import {ReactNode, memo} from 'react';
import {Col, Row} from 'antd';

import {CSS} from './p-page-title.cssconfig';

const PPageTitle = ({text, align, prefix}: {text?: string | ReactNode; align?: string; prefix?: ReactNode}) => {
  return (
    <Row align="middle" gutter={16} className={`${CSS.root} justify-${align}`}>
      {prefix && <Col>{prefix}</Col>}
      <Col>
        <h1 className={CSS.title.base}>{text}</h1>
      </Col>
    </Row>
  );
};

export const PPageTitleMemoized = memo(PPageTitle);
