export const palette = {
  common: '#373435',
  white: 'white',
  primary: '#F58634',
  warning: {
    secondary: '#ffc107',
  },
  danger: {
    primary: '#FF1B1B',
    secondary: '#FFE7E7',
  },
  success: {
    primary: '#40E150',
    secondary: '#40E15033',
  },
  info: {
    primary: '#4A8EF3',
    secondary: '#4A8EF333',
  },
  purple: '#9c27b0',
  grey: {
    common: '#252525',
    100: '#747474',
    80: '#686868',
    60: '#F4F4F4',
    40: '#FAFAFA',
    20: '#EFF1F3',
  },
} as const;

const formItemCommon = {
  colorBgContainer: '#F4F4F4',
  controlOutline: '#F4F4F4',
  controlHeightLG: 54,
  lineWidth: 1,
  borderRadius: 10,
  borderRadiusLG: 18,
  colorText: palette.common,
  colorTextPlaceholder: palette.common,
  paddingLG: 16,
  colorBorder: '#F4F4F4',
  colorPrimary: palette.primary,
  colorPrimaryHover: palette.primary,
  activeBorderColor: palette.primary,
  controlPaddingHorizontal: 16,
  controlPaddingHorizontalSM: 16,
};

export const theme = {
  hashed: false,
  components: {
    Carousel: {
      colorBgContainer: palette.primary,
    },
    Button: {
      colorPrimary: palette.primary,
      colorPrimaryHover: palette.primary,
      colorPrimaryActive: palette.primary,
      controlHeightLG: 54,
      borderRadiusLG: 14,
      lineHeight: 1,
      paddingContentHorizontal: 18,
      colorBorder: palette.primary,
      colorText: palette.primary,
      colorBgContainerDisabled: '#E5E5E5',
      colorTextDisabled: palette.white,
      colorLink: palette.primary,
      colorLinkHover: palette.primary,
      borderRadius: 10,
      controlHeight: 42,
      colorErrorBg: palette.primary,
      contentFontSizeSM: 13,
      controlHeightSM: 32,
    },
    Input: {
      ...formItemCommon,
    },
    InputNumber: {
      ...formItemCommon,
    },
    DatePicker: {
      ...formItemCommon,
      colorPrimary: palette.primary,
    },
    Select: {
      ...formItemCommon,
      controlPaddingHorizontal: 20,
      controlItemBgActive: '#f7e4d6',
    },
    Radio: {
      colorPrimaryHover: palette.common,
      colorPrimary: palette.primary,
      colorText: palette.common,
      radioSize: 20,
      dotSize: 15,
      marginXS: 32,
      colorWhite: palette.primary,
      colorBorder: palette.common,
      controlOutline: palette.primary,
      buttonColor: palette.common,
      controlHeightLG: 54,
      buttonPaddingInline: 19,
      borderRadiusLG: 14,
    },
    Table: {
      colorPrimary: palette.primary,
      colorText: palette.common,
      fontWeightStrong: 700,
      rowHoverBg: '#f3f3f3',
    },
    Form: {
      marginLG: 30,
    },
    Pagination: {
      colorBgContainer: palette.primary,
      colorPrimary: palette.white,
      colorBgTextActive: palette.white,
      colorPrimaryHover: palette.white,
      itemSize: 40,
      borderRadius: 12,
    },
    Upload: {
      borderRadiusLG: 16,
    },
    Spin: {
      colorPrimary: palette.primary,
    },
  },
} as const;
