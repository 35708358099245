import {TFile} from 'types/api';
import {FoldersEnum} from 'enums';

import {injectMediaPath} from './inject-media-path';

export const injectFormattedFile = (
  file: TFile,
  type: `${FoldersEnum}`
): Record<string, string | number | undefined> => {
  const common = {
    id: file.id,
    uid: file.id,
    removal: file.id,
    name: file.name,
    fileName: file.name,
    url: injectMediaPath(file?.path),
    view: file.view,
  };

  return type === FoldersEnum.IMAGE
    ? {
        ...common,
      }
    : {
        ...common,
        downloadUrl: file.path?.split('/').pop(),
        downloadName: file.path.substring(file.path?.lastIndexOf('/') + 1),
        status: 'done',
      };
};
