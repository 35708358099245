import {createSelector} from '@reduxjs/toolkit';

import {State} from 'store/types';

import {InquiriesState} from './types';

const getState = (state: State): InquiriesState => state.inquiries;

export const inquiriesLoadingSelector = createSelector(getState, state => state.loading);
export const inquiriesErrorSelector = createSelector(getState, state => state.error);
export const inquiriesListSelector = createSelector(getState, state => state.inquiries);
export const inquiriesCountSelector = createSelector(getState, state => state.inquiriesCount);
export const inquiriesCurrentSelector = createSelector(getState, state => state.currentInquiry);
export const inquiriesBookmarkeAddedSelector = createSelector(getState, state => state.bookmarkAddedInquiry);
export const inquiriesBookmarkeRemovedSelector = createSelector(getState, state => state.bookmarkRemovedInquiry);
export const inquiriesAddedCommentSelector = createSelector(getState, state => state.addedInquiryComment);
export const inquiriesUpdatedInfoSelector = createSelector(getState, state => state.updatedInquiryInfo);
export const inquiriesUpdatedPersonsSelector = createSelector(getState, state => state.updatedInquiryPersons);
export const inquiriesEventSelector = createSelector(getState, state => state.inquiryEvent);
export const inquiryEventPropertyCommentSelector = createSelector(getState, state => state.inquiryEventPropertyComment);
export const inquiryUpdatedEventSearchSelector = createSelector(getState, state => state.inquiryUpdatedEventSearch);
export const inquiriesFilterPresetsSelector = createSelector(getState, state => state.inquiriesFilterPresets);
