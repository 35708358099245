import {ReactNode, memo} from 'react';

const PLightboxCaption = ({children, ...props}: {children: ReactNode}) => {
  return (
    <div
      {...props}
      className="absolute bottom-0 left-0 w-full z-[1] bg-black/[.77] text-white p-3 text-center text-[18px] font-medium whitespace-nowrap text-ellipsis overflow-hidden"
    >
      {children}
    </div>
  );
};

export const PLightboxCaptionMemoized = memo(PLightboxCaption);
