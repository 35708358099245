import {memo, useMemo} from 'react';

import {Icon} from 'assets/icons';
import {Image} from 'assets/images';
import {getTranslation, injectMediaPath} from 'utils';

import {CSS} from './p-file-entity.cssconfig';
import {PFileEntityProps} from './p-file-entity.types';

const PFileEntity = ({entity, size, ...props}: PFileEntityProps) => {
  const extention = useMemo(() => entity?.name.substr(entity?.name.lastIndexOf('.') + 1), [entity]);

  return (
    <div className={`${CSS.root} ${size === 'sm' ? 'py-1' : 'py-4'} `}>
      <div className="flex items-center gap-3 w-full overflow-hidden">
        <img
          src={getTranslation(Image, extention!)}
          width={size === 'sm' ? 20 : 24}
          height={size === 'sm' ? 20 : 24}
          alt="file"
          className="flex-0"
        />
        <a
          className="text-inherit overflow-hidden text-ellipsis whitespace-nowrap"
          href={injectMediaPath(entity?.path)}
          title={entity?.name}
          target="_blank"
          rel="noreferrer"
        >
          {entity?.name}
        </a>
      </div>
      <a className="text-inherit" href={injectMediaPath(entity?.path)}>
        <Icon.DocumentDownload width={size === 'sm' ? 20 : 24} />
      </a>
    </div>
  );
};

export const PFileEntityMemoized = memo(PFileEntity);
