import {Col, Form, Popover, Row, Space} from 'antd';
import {memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {PFormLabel} from 'components/primitives/p-form-label';
import {PNumericInput} from 'components/primitives/p-numeric-input';
import {PSelect} from 'components/primitives/p-select';
import {currencySymbols} from 'constants/currency-symbols';
import {currencyOptions} from 'constants/select-options';
import {CurrencyEnum} from 'enums';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {Icon} from 'assets/icons';

interface IFromPricesFilter<T> {
  label: string;
  values: T;
  propertyType?: string;
  rent?: string | null;
  fields: {
    minTotal: string;
    maxTotal: string;
    minUnit?: string;
    maxUnit?: string;
    currency: string;
  };
}

const FormPricesFilter = <T extends {currency?: string | undefined}>({
  label,
  values,
  propertyType,
  rent,
  fields,
}: IFromPricesFilter<T>) => {
  const {t} = useTranslation();
  const requiredFields = useMemo(() => {
    const isPricesRequired =
      values &&
      values[fields.currency as keyof typeof values] &&
      !values[fields.minTotal as keyof typeof values] &&
      !values[fields.maxTotal as keyof typeof values] &&
      !values[fields.minUnit as keyof typeof values] &&
      !values[fields.maxUnit as keyof typeof values]
        ? true
        : false;
    const isCurrencyRequired =
      values &&
      (values[fields.minTotal as keyof typeof values] ||
        values[fields.maxTotal as keyof typeof values] ||
        values[fields.minUnit as keyof typeof values] ||
        values[fields.maxUnit as keyof typeof values]) &&
      !values[fields.currency as keyof typeof values]
        ? true
        : false;

    return {prices: isPricesRequired, currency: isCurrencyRequired};
  }, [values, fields.minTotal, fields.maxTotal, fields.minUnit, fields.maxUnit, fields.currency]);

  const currencySymbol = useMemo(
    () => currencySymbols[values && (values[fields.currency as keyof typeof values] as keyof typeof CurrencyEnum)],
    [values, fields.currency]
  );

  return (
    <Row gutter={16} align="middle" className="mb-[30px]">
      <Col span={2}>
        <div className="flex items-center gap-2">
          <PFormLabel isStatic text={label} className="!m-0" />
          {requiredFields.currency && (
            <Popover content={t('validations.chooseCurrency')}>
              <sup>
                <Icon.InfoCircle width={16} className="text-danger" />
              </sup>
            </Popover>
          )}
          {requiredFields.prices && (
            <Popover content={t('validations.fillPrice')}>
              <sup>
                <Icon.InfoCircle width={16} className="text-danger" />
              </sup>
            </Popover>
          )}
        </div>
      </Col>
      <Col span={5}>
        <Space.Compact>
          <Form.Item
            noStyle
            name={fields.minTotal}
            rules={[{required: requiredFields.prices, message: fieldsMessageTranslations.common}]}
          >
            <PNumericInput
              className="w-1/2"
              label={`${t('form.price')} ${t('form.from')}`}
              suffix={currencySymbol || <span />}
            />
          </Form.Item>
          <Form.Item
            noStyle
            name={fields.maxTotal}
            rules={[{required: requiredFields.prices, message: fieldsMessageTranslations.common}]}
          >
            <PNumericInput className="w-1/2" label={`${t('form.to')} `} suffix={currencySymbol || <span />} />
          </Form.Item>
        </Space.Compact>
      </Col>
      {fields.minUnit && fields.maxUnit && (
        <Col span={5}>
          <Space.Compact>
            <Form.Item
              noStyle
              name={fields.minUnit}
              rules={[{required: requiredFields.prices, message: fieldsMessageTranslations.common}]}
            >
              <PNumericInput
                className="w-1/2"
                label={`${t('form.price')} (${t('shorts.squareMeter')}) ${t('form.from')}`}
                suffix={currencySymbol || <span />}
              />
            </Form.Item>
            <Form.Item
              noStyle
              name={fields.maxUnit}
              rules={[{required: requiredFields.prices, message: fieldsMessageTranslations.common}]}
            >
              <PNumericInput className="w-1/2" label={`${t('form.to')} `} suffix={currencySymbol || <span />} />
            </Form.Item>
          </Space.Compact>
        </Col>
      )}
      <Col span={2}>
        <Form.Item
          noStyle
          name={fields.currency}
          rules={[{required: requiredFields.currency, message: fieldsMessageTranslations.common}]}
        >
          <PSelect label={t('form.currency')} options={currencyOptions} />
        </Form.Item>
      </Col>
    </Row>
  );
};

const genericMemo: <T>(component: T) => T = memo;

export const FormPricesFilterMemoized = genericMemo(FormPricesFilter);
