import {TActionSettings} from 'types/common';

export const updateWebsiteFieldsAction = (payload?: any, settings?: TActionSettings) => ({
  type: 'private/marketing/updateWebsiteFields',
  payload,
  settings: settings || {},
});

export const clearMarketingErrorsAction = () => ({
  type: 'private/marketing/clearMarketingErrors',
});
