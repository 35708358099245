export const formatPhoneNumber = (value: number | string): number | string => {
  const stringifiedValue = value?.toString();
  const isStartingWithPlus = stringifiedValue?.startsWith('+');
  const isStartingWithNull = stringifiedValue?.startsWith('0');

  return isStartingWithNull
    ? stringifiedValue?.replace(/^(0)/, '374')
    : isStartingWithPlus
    ? stringifiedValue?.substring(1)
    : value;
};
