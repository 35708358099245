import {useSearchParams} from 'react-router-dom';

const checkForNumberValidity = (v: string) => {
  return !isNaN(Number(v)) ? Number(v) : v;
};

export const useQueryParams = () => {
  const [searchParams] = useSearchParams();

  const params = [];

  for (let entry of searchParams.entries()) {
    params.push(entry);
  }

  const converted = params.map(item => ({[item[0]]: item[1]}));

  const formatted = converted.reduce((accum: any, obj) => {
    for (let key in obj) {
      if (accum[key]) {
        accum[key] = Array.isArray(accum[key])
          ? [...accum[key], checkForNumberValidity(obj[key])]
          : [checkForNumberValidity(accum[key]), checkForNumberValidity(obj[key])];
      } else {
        accum[key] = checkForNumberValidity(obj[key]);
      }
    }

    return accum;
  }, {});

  return formatted;
};
