import {TServerResponse} from 'types/common';
import {Fetcher} from 'utils';
import {ApiUrl} from 'services/constants';
import {TLead} from 'types/api';
import {LISTING_LENGTH, LISTING_OFFSET} from 'constants/pagination';

const fetcher = new Fetcher({baseURL: `${process.env.REACT_APP_API_URL}`});

export const getLeadsService = ({
  offset = LISTING_OFFSET,
  length = LISTING_LENGTH,
  ...params
}: {
  offset?: number;
  length?: number;
}): Promise<TServerResponse<TLead[]>> =>
  fetcher.request({
    url: ApiUrl.searchLeads(offset, length),
    params,
  });

export const getLeadByIdService = ({id, ...params}: any): Promise<TServerResponse<TLead>> =>
  fetcher.request({
    url: ApiUrl.getLeadById(id),
    params,
  });

export const addLeadService = (data: any): Promise<TServerResponse<TLead>> =>
  fetcher.request({
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    url: ApiUrl.addLead,
    data,
  });

export const updateLeadService = (data: any): Promise<TServerResponse<TLead>> =>
  fetcher.request({
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    url: ApiUrl.updateLead,
    data,
  });
