export enum PageNamesEnum {
  LEADS = 'leads',
  BUILDINGS = 'buildings',
  PROPERTIES = 'properties',
  INQUIRIES = 'inquiries',
  PERSONS = 'persons',
  ACCOUNTS = 'accounts',
  SETTINGS = 'settings',
  ANALYTICS = 'analytics',
  LOGIN = 'login',
  NOT_FOUND = 'not-found',
}
