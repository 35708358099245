import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Icon} from 'assets/icons';
import {PLoader} from 'components/primitives/p-loader';
import {PPageTitle} from 'components/primitives/p-page-title';
import {buildingsLoadingSelector} from 'store/buildings/selectors';
import {PButton} from 'components/primitives/p-button';
import {ScreenBuildingDetailed} from 'components/screens/building-detailed';

export const PageBuildingDetailed = () => {
  const {t} = useTranslation();
  const {mode, id} = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isLoading = useSelector(buildingsLoadingSelector);

  const property = useMemo(() => searchParams.get('property'), [searchParams]);

  return (
    <PLoader spinning={isLoading} asLayer>
      <PPageTitle
        text={
          <PButton
            type="text"
            theme="white"
            icon={<Icon.ChevronLeftRounded fill="currentColor" />}
            onClick={() => navigate(-1)}
          >
            {t('actions.back')}
          </PButton>
        }
      />

      <ScreenBuildingDetailed id={id} mode={mode} property={property} />
    </PLoader>
  );
};
