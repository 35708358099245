import {TServerResponse} from 'types/common';
import {Fetcher} from 'utils';
import {ApiUrl} from 'services/constants';
import {TAccount, TAddress, TOptGroup, TRegion, TStreet} from 'types/api';
import {LISTING_LENGTH, LISTING_OFFSET} from 'constants/pagination';

const fetcher = new Fetcher({baseURL: `${process.env.REACT_APP_API_URL}`});

const folderFetcher = new Fetcher({baseURL: `${process.env.REACT_APP_API_URL}`, timeout: 360 * 1000});

export const getProfileService = ({...params}: any): Promise<TServerResponse<TAccount>> =>
  fetcher.request({
    url: ApiUrl.getProfile,
    params,
  });

export const getOptGroupsService = ({
  offset = LISTING_OFFSET,
  length = LISTING_LENGTH,
  ...params
}: {
  offset?: number;
  length?: number;
}): Promise<TServerResponse<TOptGroup[]>> =>
  fetcher.request({
    url: ApiUrl.getOptgroups,
    params,
  });

export const addFolderService = (data: any): Promise<TServerResponse<any>> =>
  folderFetcher.request({
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: ApiUrl.addFolder,
    data,
  });

export const updateFolderService = (data: any): Promise<TServerResponse<any>> =>
  folderFetcher.request({
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: ApiUrl.updateFolder,
    data,
  });

export const getFolderService = ({id, ...params}: any): Promise<TServerResponse<any>> =>
  folderFetcher.request({
    url: ApiUrl.getFolder(id),
    params,
  });

export const addAddressService = (data: any): Promise<TServerResponse<TAddress>> =>
  fetcher.request({
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    url: ApiUrl.addAddress,
    data,
  });

export const searchStreetsService = ({
  offset = LISTING_OFFSET,
  length = 100,
  ...params
}: {
  offset?: number;
  length: number;
}): Promise<TServerResponse<TStreet[]>> =>
  fetcher.request({
    url: ApiUrl.searchStreets(offset, length),
    params,
  });

export const searchSettlementsService = ({
  offset = LISTING_OFFSET,
  length = 100,
  ...params
}: {
  offset?: number;
  length: number;
}): Promise<TServerResponse<unknown>> =>
  fetcher.request({
    url: ApiUrl.searchSettlements(offset, length),
    params,
  });

export const searchDistrictsService = ({
  offset = LISTING_OFFSET,
  length = 100,
  ...params
}: {
  offset?: number;
  length: number;
}): Promise<TServerResponse<unknown>> =>
  fetcher.request({
    url: ApiUrl.searchDistricts(offset, length),
    params,
  });

export const searchRegionsService = ({
  offset = LISTING_OFFSET,
  length = 100,
  ...params
}: {
  offset?: number;
  length: number;
}): Promise<TServerResponse<TRegion[]>> =>
  fetcher.request({
    url: ApiUrl.searchRegions(offset, length),
    params,
  });

export const searchCountriesService = ({
  offset = LISTING_OFFSET,
  length = 100,
  ...params
}: {
  offset?: number;
  length: number;
}): Promise<TServerResponse<unknown>> =>
  fetcher.request({
    url: ApiUrl.searchCountries(offset, length),
    params,
  });
