import {ColumnsType} from 'antd/es/table';
import {Col, Popover, Row, Tooltip} from 'antd';
import {Link} from 'react-router-dom';

import {Icon} from 'assets/icons';
import {RoutesEnum} from 'router/routes.enum';
import {
  ActionModesEnum,
  InquiryStateEnum,
  OptGroupsEnum,
  PropertyTypesEnum,
  StatusEnum,
  ContractTypesEnum,
  SortOrderEnum,
  SortFieldsEnum,
  RolesEnum,
} from 'enums';
import {PLoader} from 'components/primitives/p-loader';
import {IPropertyFields} from 'components/forms/property/form-property.interfaces';
import {PropertyFieldsEnum} from 'components/forms/property/form-property.enums';
import {isDateExpired, findObjectByKeys, formatDate, formatNumber, getTranslation, getFullAddress} from 'utils';
import {PStatusIndicator} from 'components/primitives/p-status';
import {currencySymbols} from 'constants/currency-symbols';
import {StatusTranslationEnum} from 'enums/translations';
import {CUpdateComment} from 'components/containers/c-comments-update';
import {CPriceUpdate} from 'components/containers/c-price-update';
import {PricesFieldsEnum} from 'components/forms/update-price/form-update-price.enums';
import {CPriceRefresh} from 'components/containers/c-price-refresh';
import {palette} from 'constants/theme';
import {CInquiryEventPropertyCommentUpdate} from 'components/containers/c-inquiry-event-property-comment-update';
import {ITableColumnCommonProps} from 'types/common';

interface ITableColumns extends IPropertyFields {}

export const columns = ({
  isLighboxLoading,
  entityId,
  propertyType,
  contractType,
  catalog,
  inquiry,
  currentInquiry,
  isInquiryInSearchMode,
  sortOptions,
  profile,
  beforeLightboxOpen,
  onPersonView,
  onPropcessChange,
  onBookmark,
  translation,
}: ITableColumnCommonProps): ColumnsType<ITableColumns> => {
  const area = (): ColumnsType<ITableColumns> =>
    propertyType === PropertyTypesEnum.HOUSE
      ? [
          {
            title: translation('form.area'),
            key: PropertyFieldsEnum.LIVING_AREA,
            dataIndex: SortFieldsEnum.PH_LIVING_AREA,
            sorter: true,
            defaultSortOrder:
              sortOptions?.sort === SortFieldsEnum.PH_LIVING_AREA && sortOptions?.asc
                ? SortOrderEnum.ASCEND
                : sortOptions?.sort === SortFieldsEnum.PH_LIVING_AREA && sortOptions?.desc
                ? SortOrderEnum.DESCEND
                : undefined,
            sortDirections: [SortOrderEnum.ASCEND, SortOrderEnum.DESCEND, SortOrderEnum.ASCEND],
            render: (_, record) => (
              <div>{record?.living_area && `${record?.living_area} ${translation('shorts.squareMeter')}`}</div>
            ),
          },
          {
            title: translation('form.landArea'),
            key: PropertyFieldsEnum.LAND_AREA,
            dataIndex: SortFieldsEnum.PH_LAND_AREA,
            sorter: true,
            defaultSortOrder:
              sortOptions?.sort === SortFieldsEnum.PH_LAND_AREA && sortOptions?.asc
                ? SortOrderEnum.ASCEND
                : sortOptions?.sort === SortFieldsEnum.PH_LAND_AREA && sortOptions?.desc
                ? SortOrderEnum.DESCEND
                : undefined,
            sortDirections: [SortOrderEnum.ASCEND, SortOrderEnum.DESCEND, SortOrderEnum.ASCEND],
            render: (_, record) => (
              <div>{record?.land_area && `${record?.land_area} ${translation('shorts.squareMeter')}`}</div>
            ),
          },
        ]
      : propertyType === PropertyTypesEnum.LAND
      ? [
          {
            title: translation('form.area'),
            key: PropertyFieldsEnum.SQUARE_METRE,
            dataIndex: SortFieldsEnum.PL_SQUARE_METRE,
            sorter: true,
            defaultSortOrder:
              sortOptions?.sort === SortFieldsEnum.PL_SQUARE_METRE && sortOptions?.asc
                ? SortOrderEnum.ASCEND
                : sortOptions?.sort === SortFieldsEnum.PL_SQUARE_METRE && sortOptions?.desc
                ? SortOrderEnum.DESCEND
                : undefined,
            sortDirections: [SortOrderEnum.ASCEND, SortOrderEnum.DESCEND, SortOrderEnum.ASCEND],
            render: (_, record) => (
              <Popover
                content={
                  record?.hectare ? (
                    <div>
                      {record?.hectare} {translation('shorts.hectare')}
                    </div>
                  ) : null
                }
              >
                <div>{record?.square_metre && `${record?.square_metre} ${translation('shorts.squareMeter')}`}</div>
              </Popover>
            ),
          },
        ]
      : propertyType === PropertyTypesEnum.COMMERCIAL
      ? [
          {
            title: translation('form.area'),
            key: PropertyFieldsEnum.TOTAL_AREA,
            dataIndex: SortFieldsEnum.PC_TOTAL_AREA,
            sorter: true,
            defaultSortOrder:
              sortOptions?.sort === SortFieldsEnum.PC_TOTAL_AREA && sortOptions?.asc
                ? SortOrderEnum.ASCEND
                : sortOptions?.sort === SortFieldsEnum.PC_TOTAL_AREA && sortOptions?.desc
                ? SortOrderEnum.DESCEND
                : undefined,
            sortDirections: [SortOrderEnum.ASCEND, SortOrderEnum.DESCEND, SortOrderEnum.ASCEND],
            render: (_, record) => (
              <Popover
                overlayInnerStyle={{padding: 0, maxHeight: 370, overflowY: 'auto'}}
                content={
                  <div>
                    {record?.floors?.map(f => (
                      <div
                        key={`${f.name}`}
                        className="flex items-center gap-[26px] justify-between even:bg-grey-20 rounded-[4px] px-[20px] py-[12px]"
                      >
                        {f.name ? <div>{f.name}</div> : null}
                        <div>{`${f.area} ${translation('shorts.squareMeter')}` || '-'}</div>
                      </div>
                    ))}
                  </div>
                }
              >
                <div>{record?.total_area && `${record?.total_area} ${translation('shorts.squareMeter')}`}</div>
              </Popover>
            ),
          },
        ]
      : [
          {
            title: translation('form.area'),
            key: PropertyFieldsEnum.AREA,
            dataIndex: SortFieldsEnum.PA_AREA,
            sorter: true,
            defaultSortOrder:
              sortOptions?.sort === SortFieldsEnum.PA_AREA && sortOptions?.asc
                ? SortOrderEnum.ASCEND
                : sortOptions?.sort === SortFieldsEnum.PA_AREA && sortOptions?.desc
                ? SortOrderEnum.DESCEND
                : undefined,
            sortDirections: [SortOrderEnum.ASCEND, SortOrderEnum.DESCEND, SortOrderEnum.ASCEND],
            render: (_, record) => <div>{record?.area && `${record?.area} ${translation('shorts.squareMeter')}`}</div>,
          },
        ];

  const room = (): ColumnsType<ITableColumns> =>
    propertyType !== PropertyTypesEnum.LAND && propertyType !== PropertyTypesEnum.COMMERCIAL
      ? [
          {
            title: propertyType === PropertyTypesEnum.HOUSE ? translation('form.bedroom') : translation('form.room'),
            key: PropertyFieldsEnum.BEDROOMS,
            dataIndex: PropertyFieldsEnum.BEDROOMS,
            render: (_, record) => <div>{record.bedrooms}</div>,
          },
        ]
      : [];

  const floor = (): ColumnsType<ITableColumns> =>
    propertyType === PropertyTypesEnum.HOUSE
      ? [
          {
            title: translation('form.floors'),
            key: PropertyFieldsEnum.FLOOR_PLAN,
            dataIndex: PropertyFieldsEnum.FLOOR_PLAN,
          },
        ]
      : propertyType === PropertyTypesEnum.APARTMENT
      ? [
          {
            title: translation('form.floor'),
            key: PropertyFieldsEnum.FLOOR,
            dataIndex: PropertyFieldsEnum.FLOOR,
            render: (_, record) => (
              <div>
                {record.building.floors && record.floor ? `${record.building.floors}/` : null}
                {record.floor}
              </div>
            ),
          },
        ]
      : propertyType === PropertyTypesEnum.COMMERCIAL
      ? [
          {
            title: translation('form.floors'),
            key: PropertyFieldsEnum.FLOOR_PLAN,
            dataIndex: PropertyFieldsEnum.FLOOR_PLAN,
          },
          {
            title: translation('form.entranceFloor'),
            key: PropertyFieldsEnum.ENTRANCE_FLOOR,
            dataIndex: PropertyFieldsEnum.ENTRANCE_FLOOR,
          },
          {
            title: translation('form.entranceCount'),
            key: PropertyFieldsEnum.ENTRANCE_COUNT,
            dataIndex: PropertyFieldsEnum.ENTRANCE_COUNT,
            render: (_, record) => {
              const entryPositionOptions = findObjectByKeys({
                options: record?.options!,
                state: catalog![OptGroupsEnum.ENTRY_POSITION],
                key: 'value',
              });
              const entryTypeOptions = findObjectByKeys({
                options: record?.options!,
                state: catalog![OptGroupsEnum.ENTRY_TYPE],
                key: 'value',
              });
              const entryPositions = entryPositionOptions
                ?.map((t: any) => catalog![OptGroupsEnum.ENTRY_POSITION].find(opt => opt.value === t))
                .map((type: any) => type.label);

              const entryTypes = entryTypeOptions
                ?.map((t: any) => catalog![OptGroupsEnum.ENTRY_TYPE].find(opt => opt.value === t))
                .map((type: any) => type.label);

              return entryTypes.length || entryPositions.length ? (
                <Popover
                  overlayInnerStyle={{padding: 0, maxHeight: 370, overflowY: 'auto'}}
                  content={
                    <div>
                      {entryTypes.length ? (
                        <div className="flex items-center gap-[15px] rounded-[4px] px-[20px] py-[12px]">
                          <div className="font-medium">{translation('form.type')}:</div>
                          <div>{entryTypes.join(', ')}</div>
                        </div>
                      ) : (
                        ''
                      )}
                      {entryPositions.length ? (
                        <div className="flex items-center gap-[15px] rounded-[4px] px-[20px] py-[12px]">
                          <div className="font-medium">{translation('form.position')}:</div>
                          <div>{entryPositions.join(', ')}</div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  }
                >
                  <div>{record.entrance_count || '-'}</div>
                </Popover>
              ) : (
                <div>{record.entrance_count || '-'}</div>
              );
            },
          },
        ]
      : [];

  const repair = (): ColumnsType<ITableColumns> =>
    propertyType !== PropertyTypesEnum.LAND && propertyType !== PropertyTypesEnum.COMMERCIAL
      ? [
          {
            width: '10%',
            title: translation('form.repair'),
            key: PropertyFieldsEnum.REPAIR,
            dataIndex: PropertyFieldsEnum.REPAIR,
            render: (_, record) => (
              <div>{catalog![OptGroupsEnum.REPAIR_TYPES]?.find(o => o.value === record.repair)?.label}</div>
            ),
          },
        ]
      : [];

  const priceSale = (): ColumnsType<ITableColumns> => [
    {
      width: '8%',
      title: translation('form.price'),
      key: PropertyFieldsEnum.PRICE,
      dataIndex: PropertyFieldsEnum.PRICE,
      render: (_, record) => (
        <Popover
          content={
            record.price?.unit_sale &&
            `${
              record.price?.currency_sale
                ? getTranslation(currencySymbols, record.price?.currency_sale!)?.props.children
                : ''
            } ${formatNumber(Math.ceil(record.price?.unit_sale)!)} ${translation('shorts.squareMeter')}`
          }
        >
          {profile?.type !== RolesEnum.MARKETING &&
          (record?.mode?.includes(ContractTypesEnum.ALL) || record?.mode?.includes(ContractTypesEnum.SALE)) ? (
            <div>
              <CPriceUpdate
                title="Փոփոխել գինը"
                total_field={PricesFieldsEnum.TOTAL_PRICE_SALE}
                unit_field={record?.type !== PropertyTypesEnum.HOUSE ? PricesFieldsEnum.UNIT_PRICE_SALE : null}
                currency_field={PricesFieldsEnum.CURRENCY_SALE}
                initialValues={{
                  total: record?.price?.total_sale!,
                  unit: record?.price?.unit_sale,
                  currency: record?.price?.currency_sale,
                }}
                property={record}
              >
                <div className="line-clamp-2 min-h-[25px] min-w-[50px] cursor-pointer">
                  {record.price?.total_sale &&
                    getTranslation(currencySymbols, record.price.currency_sale!)?.props.children}
                  {formatNumber(record.price?.total_sale!) || '-'}
                </div>
              </CPriceUpdate>
            </div>
          ) : (
            <div className="line-clamp-2 min-h-[25px] min-w-[50px]">
              {record.price?.total_sale && getTranslation(currencySymbols, record.price.currency_sale!)?.props.children}
              {formatNumber(record.price?.total_sale!) || '-'}
            </div>
          )}
        </Popover>
      ),
    },
  ];

  const priceMonthly = (): ColumnsType<ITableColumns> =>
    propertyType !== PropertyTypesEnum.LAND
      ? [
          {
            width: '7%',
            title: translation('form.rent'),
            key: PropertyFieldsEnum.PRICE,
            dataIndex: PropertyFieldsEnum.PRICE,
            render: (_, record) => (
              <div>
                <Popover
                  content={
                    record.price?.unit_monthly &&
                    `${
                      record.price?.currency_monthly
                        ? getTranslation(currencySymbols, record.price?.currency_monthly!)?.props.children
                        : ''
                    } ${formatNumber(Math.ceil(record.price?.unit_monthly)!)} ${translation('shorts.squareMeter')}`
                  }
                >
                  {profile?.type !== RolesEnum.MARKETING &&
                  (record?.rent?.includes(ContractTypesEnum.ALL) ||
                    record?.rent?.includes(ContractTypesEnum.MONTHLY)) ? (
                    <div>
                      <CPriceUpdate
                        title="Փոփոխել գինը"
                        total_field={PricesFieldsEnum.TOTAL_PRICE_MONTHLY}
                        unit_field={
                          record?.type === PropertyTypesEnum.COMMERCIAL ? PricesFieldsEnum.UNIT_PRICE_MONTHLY : null
                        }
                        currency_field={PricesFieldsEnum.CURRENCY_MONTHLY}
                        initialValues={{
                          total: record?.price?.total_monthly!,
                          unit: record?.price?.unit_monthly,
                          currency: record?.price?.currency_monthly,
                        }}
                        property={record}
                      >
                        <div className="line-clamp-2 min-h-[25px] min-w-[50px] cursor-pointer">
                          {record.price?.total_monthly &&
                            getTranslation(currencySymbols, record.price?.currency_monthly!)?.props.children}{' '}
                          {formatNumber(record.price?.total_monthly!) || '-'}
                        </div>
                      </CPriceUpdate>
                    </div>
                  ) : (
                    <div className="line-clamp-2 min-h-[25px] min-w-[50px]">
                      {record.price?.total_monthly &&
                        getTranslation(currencySymbols, record.price?.currency_monthly!)?.props.children}{' '}
                      {formatNumber(record.price?.total_monthly!) || '-'}
                    </div>
                  )}
                </Popover>
              </div>
            ),
          },
        ]
      : [];

  const priceDaily = (): ColumnsType<ITableColumns> =>
    propertyType !== PropertyTypesEnum.LAND && propertyType !== PropertyTypesEnum.COMMERCIAL
      ? [
          {
            width: '7%',
            title: translation('form.rentDaily'),
            key: PropertyFieldsEnum.RENT,
            dataIndex: PropertyFieldsEnum.RENT,
            render: (_, record) =>
              profile?.type !== RolesEnum.MARKETING &&
              (record?.rent?.includes(ContractTypesEnum.ALL) || record?.rent?.includes(ContractTypesEnum.DAILY)) ? (
                <div>
                  <CPriceUpdate
                    title="Փոփոխել գինը"
                    total_field={PricesFieldsEnum.TOTAL_PRICE_DAILY}
                    currency_field={PricesFieldsEnum.CURRENCY_DAILY}
                    initialValues={{
                      total: record?.price?.total_daily!,
                      currency: record?.price?.currency_daily,
                    }}
                    property={record}
                  >
                    <div className="line-clamp-2 min-h-[25px] min-w-[50px] cursor-pointer">
                      {record.price?.total_daily &&
                        getTranslation(currencySymbols, record.price?.currency_daily!)?.props.children}{' '}
                      {formatNumber(record.price?.total_daily!) || '-'}
                    </div>
                  </CPriceUpdate>
                </div>
              ) : (
                <div className="line-clamp-2 min-h-[25px] min-w-[50px]">
                  {record.price?.total_daily &&
                    getTranslation(currencySymbols, record.price?.currency_daily!)?.props.children}{' '}
                  {formatNumber(record.price?.total_daily!) || '-'}
                </div>
              ),
          },
        ]
      : [];

  const allPrices = (): ColumnsType<ITableColumns> =>
    !contractType?.length ? [...priceSale(), ...priceMonthly(), ...priceDaily()] : [];

  const priceSaleByFilter = (): ColumnsType<ITableColumns> =>
    contractType?.length && contractType?.includes(ContractTypesEnum.SALE) ? priceSale() : [];

  const priceMonthlyByFilter = (): ColumnsType<ITableColumns> =>
    contractType?.length && contractType?.includes(ContractTypesEnum.MONTHLY) ? priceMonthly() : [];

  const priceDailyByFilter = (): ColumnsType<ITableColumns> =>
    (contractType?.length && contractType?.includes(ContractTypesEnum.MONTHLY)) ||
    contractType?.includes(ContractTypesEnum.DAILY)
      ? priceDaily()
      : [];

  const usage = (): ColumnsType<ITableColumns> =>
    propertyType === PropertyTypesEnum.LAND
      ? [
          {
            title: translation('form.usage'),
            key: PropertyFieldsEnum.USAGE,
            dataIndex: PropertyFieldsEnum.USAGE,
            render: (_, record) => (
              <div>{catalog![OptGroupsEnum.SOIL_IMPORTANCE]?.find(o => o.value === record.usage)?.label}</div>
            ),
          },
          {
            width: '10%',
            title: translation('form.cadastre'),
            key: PropertyFieldsEnum.CADASTRE_NUMBER,
            dataIndex: PropertyFieldsEnum.CADASTRE_NUMBER,
          },
          {
            width: '10%',
            title: translation('form.frontLength'),
            key: PropertyFieldsEnum.FRONT_LENGTH,
            dataIndex: PropertyFieldsEnum.FRONT_LENGTH,
          },
        ]
      : [];

  const inquiryPropertyComment = (): ColumnsType<ITableColumns> =>
    inquiry && currentInquiry?.state === InquiryStateEnum.DEMONSTRATION && !isInquiryInSearchMode
      ? [
          {
            width: '15%',
            title: translation('form.eventComments'),
            render: (_, record) => {
              const property: any = currentInquiry?.events[0]?.properties.find((p: any) => p.id === record.id);

              return (
                <div className="cursor-pointer inline-block">
                  <CInquiryEventPropertyCommentUpdate
                    title=""
                    initialValue={property?.comment}
                    propertyId={record.id}
                    inquiry={inquiry}
                    event={currentInquiry?.events[0]?.id}
                  >
                    <div className="line-clamp-2 min-h-[25px] min-w-[50px]">{property?.comment || '-'}</div>
                  </CInquiryEventPropertyCommentUpdate>
                </div>
              );
            },
          },
        ]
      : [];

  const isBuilding = (): ColumnsType<ITableColumns> =>
    propertyType === PropertyTypesEnum.COMMERCIAL
      ? [
          {
            width: '7%',
            title: translation('form.isBuilding'),
            key: PropertyFieldsEnum.IS_BUILDING,
            dataIndex: PropertyFieldsEnum.IS_BUILDING,
            render: (_, record) => (
              <div>{record?.is_building === 1 ? 'Այո' : record?.is_building === 0 ? 'Ոչ' : '-'}</div>
            ),
          },
        ]
      : [];

  return [
    {
      title: translation('form.id'),
      dataIndex: PropertyFieldsEnum.CODE,
      key: PropertyFieldsEnum.CODE,
      render: (_, record) => (
        <div className="relative">
          {record.code}
          {!inquiry && (
            <Tooltip
              title={getTranslation(
                StatusTranslationEnum,
                record?.disabled_to && record.status !== StatusEnum.ARCHIVED ? StatusEnum.ONHOLD : record.status!
              )}
              className="absolute top-[6px] left-[-30px]"
            >
              <div>
                <PStatusIndicator
                  type={
                    record?.disabled_to && isDateExpired(record.disabled_to!)
                      ? 'danger'
                      : record?.disabled_to && record.status !== StatusEnum.ARCHIVED
                      ? 'info'
                      : record.status === StatusEnum.FINALIZED
                      ? 'success'
                      : record.status === StatusEnum.CURRENT
                      ? 'warning'
                      : record.status === StatusEnum.ARCHIVED
                      ? 'default'
                      : ''
                  }
                />
              </div>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: translation('form.date'),
      dataIndex: SortFieldsEnum.PRICE_CREATED,
      key: SortFieldsEnum.PRICE_CREATED,
      sorter: true,
      defaultSortOrder:
        sortOptions?.sort === SortFieldsEnum.PRICE_CREATED && sortOptions?.asc
          ? SortOrderEnum.ASCEND
          : sortOptions?.sort === SortFieldsEnum.PRICE_CREATED && sortOptions?.desc
          ? SortOrderEnum.DESCEND
          : undefined,
      sortDirections: [SortOrderEnum.ASCEND, SortOrderEnum.DESCEND, SortOrderEnum.ASCEND],
      render: (_, record) =>
        record?.prices?.length ? (
          <Popover
            overlayInnerStyle={{padding: 0, maxHeight: 370, overflowY: 'auto'}}
            content={
              <div>
                {record?.prices?.map(p => (
                  <div
                    key={`${p.created}-${record.price?.total_sale}`}
                    className="flex items-center gap-[26px] even:bg-grey-20 rounded-[4px] px-[20px] py-[12px]"
                  >
                    <div>{formatDate({value: p.created!})}</div>
                    <div>
                      {record.price?.total_sale
                        ? `${
                            record.price?.currency_sale
                              ? getTranslation(currencySymbols, record.price?.currency_sale)?.props.children
                              : ''
                          } ${formatNumber(record.price?.total_sale!)}`
                        : '-'}
                    </div>
                  </div>
                ))}
              </div>
            }
          >
            {profile?.type !== RolesEnum.MARKETING ? (
              <div className="cursor-pointer">
                <CPriceRefresh title="Թարմացնել ամսաթիվը" property={record}>
                  {formatDate({value: record.price?.created!})}
                </CPriceRefresh>
              </div>
            ) : (
              <div>{formatDate({value: record.price?.created!})}</div>
            )}
          </Popover>
        ) : null,
    },
    {
      width: '13%',
      title: translation('form.address'),
      dataIndex: PropertyFieldsEnum.LOCATION,
      key: PropertyFieldsEnum.LOCATION,
      render: (_, record) => {
        const address =
          record.type === PropertyTypesEnum.LAND
            ? getFullAddress({location: record.location, showCountry: false})
            : getFullAddress({
                location: record.location,
                number: record.number,
                showCountry: false,
                showRegion: false,
                showDistrict: false,
              });

        return <div>{address}</div>;
      },
    },
    ...area(),
    ...room(),
    ...floor(),
    ...isBuilding(),
    ...repair(),
    ...allPrices(),
    ...priceSaleByFilter(),
    ...priceMonthlyByFilter(),
    ...priceDailyByFilter(),
    ...usage(),
    {
      width: '15%',
      title: translation('form.innerInfo'),
      key: PropertyFieldsEnum.COMMENTS,
      dataIndex: PropertyFieldsEnum.COMMENTS,
      render: (_, record) => (
        <div className=" inline-block">
          {profile?.type === RolesEnum.MARKETING ? (
            <div className="line-clamp-2 min-h-[25px] min-w-[50px] break-words">{record.comments || '-'}</div>
          ) : (
            <CUpdateComment title="" initialValue={record?.comments} propertyId={record.id}>
              <div className="line-clamp-2 min-h-[25px] min-w-[50px] break-words cursor-pointer">
                {record.comments || '-'}
              </div>
            </CUpdateComment>
          )}
        </div>
      ),
    },
    ...inquiryPropertyComment(),
    {
      title: translation('form.action'),
      key: 'action',
      align: 'right',
      render: (_, record) => {
        const galleryExists =
          record.image_folder ||
          record.rooms?.filter(item => item.image_folder !== null).length ||
          record.floors?.filter(item => item.image_folder !== null).length;
        const isUserTypeMarketing = profile?.type === RolesEnum.MARKETING;

        return (
          <Row gutter={8} justify="end" align="middle" wrap={false}>
            {record.status !== StatusEnum.ARCHIVED && !inquiry && !isUserTypeMarketing && (
              <Col className="inline-flex">
                <Tooltip title={translation('actions.changeBusinessProcess')}>
                  <Icon.Archive
                    color="#292D32"
                    width={24}
                    height={24}
                    cursor="pointer"
                    onClick={() => onPropcessChange!(record.id!)}
                  />
                </Tooltip>
              </Col>
            )}
            <Col className="inline-flex">
              {galleryExists ? (
                isLighboxLoading && entityId === record.id ? (
                  <PLoader size="small" />
                ) : (
                  <Tooltip title={translation('actions.images')}>
                    <Icon.Picture
                      color="#292D32"
                      width={20}
                      height={20}
                      cursor="pointer"
                      onClick={() => beforeLightboxOpen!(record.id!)}
                    />
                  </Tooltip>
                )
              ) : (
                <button type="button" className="default disabled inline-flex">
                  <Icon.Picture color="#292D32" width={20} height={20} />
                </button>
              )}
            </Col>
            {!inquiry && (
              <Col className="inline-flex">
                {record.persons?.length ? (
                  <Tooltip title={translation('common.attachedPersons')}>
                    <Icon.Calling
                      color="#292D32"
                      width={24}
                      height={24}
                      cursor="pointer"
                      onClick={() =>
                        onPersonView!(
                          record.location?.street.name
                            ? `${record.location?.street.name} ${
                                record.location.address?.number && record.location.address?.number
                              }`
                            : '',
                          record.persons!
                        )
                      }
                    />
                  </Tooltip>
                ) : (
                  <button type="button" className="default disabled inline-flex">
                    <Icon.Calling color="#292D32" width={24} height={24} />
                  </button>
                )}
              </Col>
            )}
            <Col className="inline-flex">
              <Tooltip title={translation('actions.view')}>
                <Link to={`${RoutesEnum.PROPERTIES}/${ActionModesEnum.VIEW}/${record.id}`} className="inline-flex">
                  <Icon.Eye color="#292D32" width={24} height={24} cursor="pointer" />
                </Link>
              </Tooltip>
            </Col>
            {record.status !== StatusEnum.ARCHIVED && !inquiry && (
              <Col className="inline-flex">
                <Tooltip title={translation('actions.update')}>
                  <Link to={`${RoutesEnum.PROPERTIES}/${ActionModesEnum.UPDATE}/${record.id}`} className="inline-flex">
                    <Icon.Edit color="#292D32" width={24} height={24} cursor="pointer" />
                  </Link>
                </Tooltip>
              </Col>
            )}
            {inquiry && (
              <Col className="inline-flex">
                <Tooltip
                  title={!record.bookmarked ? translation('actions.save') : translation('catalog.statuses.saved')}
                >
                  {record.bookmarked ? (
                    <div onClick={() => onBookmark!({mode: 'remove', propertyId: record.id!})} className="inline-flex">
                      <Icon.Star fill={palette.primary} width={24} height={24} cursor="pointer" />
                    </div>
                  ) : (
                    <div onClick={() => onBookmark!({mode: 'add', propertyId: record.id!})} className="inline-flex">
                      <Icon.Star width={24} height={24} cursor="pointer" />
                    </div>
                  )}
                </Tooltip>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];
};
