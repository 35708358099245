import {memo, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {PopupBase} from 'components/popups/base';
import {Image} from 'assets/images';
import {PFormLabel} from 'components/primitives/p-form-label';
import {useNetwork} from 'hooks';

const PopupNetworkOffline = ({onClose}: {onClose: () => void}) => {
  const {t} = useTranslation();
  const {online} = useNetwork();

  useEffect(() => {
    if (online) {
      onClose();
    }
  }, [online, onClose]);

  return (
    <PopupBase
      onCrossClick={onClose}
      open
      type="confirm"
      buttons={{
        position: 'center',
        reject: {
          name: t('actions.close'),
          type: 'text',
          onClick: onClose,
        },
        confirm: undefined,
      }}
    >
      <div className="text-center flex flex-wrap justify-center gap-5">
        <img src={Image.noWifi} width={70} height={70} alt="No wifi" />
        <PFormLabel text={t('info.checkNetwork')} className="leading-[140%]" isStatic />
      </div>
    </PopupBase>
  );
};

export const PopupNetworkOfflineMemoized = memo(PopupNetworkOffline);
