import {createSelector} from '@reduxjs/toolkit';

import {State} from 'store/types';

import {PersonsState} from './types';

const getState = (state: State): PersonsState => state.persons;

export const personsLoadingSelector = createSelector(getState, state => state.loading);
export const personsErrorsSelector = createSelector(getState, state => state.personErrors);
export const personsSelector = createSelector(getState, state => state.persons);
export const personsCountSelector = createSelector(getState, state => state.personsCount);
export const personSelector = createSelector(getState, state => state.person);
export const personsCreatedSelector = createSelector(getState, state => state.createdPerson);
export const personsSearchedSelector = createSelector(getState, state => state.searchedPerson);
export const personsCurrentSelector = createSelector(getState, state => state.currentPerson);
