import {memo} from 'react';
import {Popconfirm, Skeleton, Tooltip} from 'antd';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {Icon} from 'assets/icons';
import {palette} from 'constants/theme';
import {RoutesEnum} from 'router/routes.enum';
import {ActionModesEnum} from 'enums';
import {CTextCopy} from 'components/containers/c-text-copy';
import {inquiriesCurrentSelector} from 'store/inquiries/selectors';

import {ICInquiryPersonProps} from './c-inquiry-person.types';

const CInquiryPerson = ({entity, loading, onRemove}: ICInquiryPersonProps) => {
  const {t} = useTranslation();
  const currentInquiry = useSelector(inquiriesCurrentSelector);

  const {name, email, id, phones} = entity;

  return loading ? (
    <Skeleton />
  ) : (
    <div className="p-2 rounded-[12px] border border-solid border-grey-20 grow">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-3 p-3 rounded-[16px] even:bg-grey-20">
          <Icon.User fill={palette.primary} width={24} height={24} className="flex-0" />
          <div className="text-sm">{name}</div>
        </div>

        <div className="flex gap-1 items-center">
          <Tooltip title={t('actions.change')}>
            <Link
              to={`${RoutesEnum.PERSONS}/${ActionModesEnum.UPDATE}/${id}?inquiry=${currentInquiry?.id}`}
              className="flex text-grey-common"
            >
              <Icon.Edit
                color="currentColor"
                width={20}
                height={20}
                className="cursor-pointer transition-all hover:text-primary"
              />
            </Link>
          </Tooltip>
          {currentInquiry?.persons?.length! > 1 && (
            <Tooltip title={t('actions.remove')}>
              <>
                <Popconfirm
                  icon={null}
                  title={t('prompts.remove')}
                  onConfirm={() => onRemove && onRemove(id)}
                  cancelText={t('actions.close')}
                  okText={t('actions.confirm')}
                  className="flex"
                >
                  <Icon.CloseSquare
                    color="currentColor"
                    width={20}
                    height={20}
                    className="cursor-pointer transition-all hover:text-danger"
                  />
                </Popconfirm>
              </>
            </Tooltip>
          )}
        </div>
      </div>
      {email && (
        <div className="flex items-center gap-3 p-3 rounded-[16px] even:bg-grey-20">
          <Icon.Message width={24} height={24} className="flex-0" />
          <a href={`mailto:${email}`} className="text-sm text-grey-common">
            {email}
          </a>
        </div>
      )}
      <div className="flex items-center gap-3 p-3 rounded-[16px] even:bg-grey-20">
        <Icon.Calling width={24} height={24} className="flex-0" />

        <div className="text-sm">
          {phones?.map(({id, number}) => <CTextCopy key={`${id}-${number}`} text={number} asLink />)}
        </div>
      </div>
    </div>
  );
};

export const CInquiryPersonMemoized = memo(CInquiryPerson);
