import {memo, ReactNode, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {PopupBase} from 'components/popups/base';

const PopupConfirm = ({
  title,
  content,
  onClose,
  onSubmit,
}: {
  title?: string;
  content?: ReactNode;
  onSubmit?: (callback?: any) => void;
  onClose: () => void;
}) => {
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFinish = useCallback(() => {
    onClose();
    setIsLoading(false);
  }, [onClose]);

  const handleConfirm = useCallback(() => {
    setIsLoading(true);

    onSubmit && onSubmit(onFinish);
  }, [onSubmit, onFinish]);

  return (
    <PopupBase
      title={title || t('actions.confirm')}
      onCrossClick={onClose}
      onSubmit={handleConfirm}
      open
      type="prompt"
      buttons={{
        reject: {
          name: t('actions.close'),
          type: 'text',
          onClick: onClose,
        },
        confirm: {
          name: t('actions.confirm'),
          type: 'primary',
          htmlType: 'button',
          loading: isLoading,
          onClick: handleConfirm,
        },
      }}
    >
      {content}
    </PopupBase>
  );
};

export const PopupConfirmMemoized = memo(PopupConfirm);
