import {memo} from 'react';
import {Select} from 'antd';

import {Icon} from 'assets/icons';
import {PFormLabel} from 'components/primitives/p-form-label';
import {palette} from 'constants/theme';

import {PSelectProps} from './p-select.types';

const PSelect = ({
  label,
  id,
  allowClear = {
    clearIcon: <Icon.CloseCircle width={20} height={20} className="relative top-[-3px] left-[-5px]" />,
  },
  size = 'large',
  theme,
  ...props
}: PSelectProps) => {
  return (
    <div className="relative">
      {label && <PFormLabel text={label} id={id} />}
      <Select
        {...props}
        className={theme === 'white' ? 'theme-white' : ''}
        style={{width: '100%'}}
        size={size}
        suffixIcon={<Icon.ChevronDownRounded width={13} height={7} color={palette.common} />}
        allowClear={allowClear}
      />
    </div>
  );
};

export const PSelectMemoized = memo(PSelect);
