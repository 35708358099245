import qs from 'query-string';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {AnyAction} from 'redux';

import {TActionSettings} from 'types/common';
import {clearFromEmptyKeys} from 'utils';

export const useFilterPresets = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePresetsSave = ({
    route,
    params,
    action,
  }: {
    route: string;
    params: Record<string, any>;
    action?: (payload: any, settings?: TActionSettings) => AnyAction;
  }) => {
    navigate({
      pathname: route,
      search: Object.keys(params).length > 0 ? qs.stringify(clearFromEmptyKeys(params)) : '',
    });

    action && dispatch(action(clearFromEmptyKeys(params)));
  };

  return handlePresetsSave;
};
