import {Col, Form, Row} from 'antd';
import {useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {PButton} from 'components/primitives/p-button';
import {PInput} from 'components/primitives/p-input';
import {PersonFieldsEnum} from 'components/forms/person/form-person.enums';
import {PPhoneInput} from 'components/primitives/p-phone-input';
import {fieldsMessageTranslations} from 'constants/fields-message-translations';
import {CustomerTypesEnum, FormsEnum} from 'enums';
import {IFilterProps} from 'types/common';
import {CSubmitButton} from 'components/containers/c-submit-button';
import {Icon} from 'assets/icons';
import {useMountedOnce} from 'hooks';
import {PSelect} from 'components/primitives/p-select';
import {customerTypeOptions, personRelationOptions, propertyTypeOptions} from 'constants/select-options';
import {PersonTypesTranslationEnum} from 'enums/translations';

import {IPersonsFilterFields} from './form-persons-filter.interfaces';

export const FormPersonFilter = ({
  form,
  values,
  initialValues,
  isButtonLoading,
  onSubmit,
  onReset,
}: IFilterProps<IPersonsFilterFields>) => {
  const {t} = useTranslation();
  const hasInitialValues = useMemo(() => initialValues && Object.keys(initialValues).length > 0, [initialValues]);

  const handleRelationChange = useCallback(
    (value: string) => {
      if (value !== PersonTypesTranslationEnum.OTHER) {
        form.setFieldValue(PersonFieldsEnum.RELATION, null);
      }
    },
    [form]
  );

  const setInitialValus = useMountedOnce(() => {
    if (hasInitialValues) {
      form.setFieldsValue(initialValues!);
    }
  });

  useEffect(() => {
    setInitialValus();
  }, [setInitialValus]);

  return (
    <Form form={form} onFinish={onSubmit} name={FormsEnum.PERSONS_FILTER}>
      <Row gutter={16}>
        <Col span={4}>
          <Form.Item name={PersonFieldsEnum.CUSTOMER_MODE} rules={[{required: false}]}>
            <PSelect label={t('form.customerMode')} id={PersonFieldsEnum.CUSTOMER_MODE} options={customerTypeOptions} />
          </Form.Item>
        </Col>
        {values?.customerMode ? (
          <>
            {values?.customerMode !== CustomerTypesEnum.BUILDING && (
              <Col span={4}>
                <Form.Item name={PersonFieldsEnum.PROPERTY_TYPE} rules={[{required: false}]}>
                  <PSelect
                    label={t('form.propertyType')}
                    id={PersonFieldsEnum.PROPERTY_TYPE}
                    options={propertyTypeOptions}
                  />
                </Form.Item>
              </Col>
            )}

            <Col span={4}>
              <Form.Item name={PersonFieldsEnum.TYPE} rules={[{required: false}]}>
                <PSelect
                  label={t('form.relationWithProperty')}
                  options={personRelationOptions}
                  onChange={handleRelationChange}
                />
              </Form.Item>
            </Col>
            {values?.type === PersonTypesTranslationEnum.OTHER && (
              <Col span={4}>
                <Form.Item name={PersonFieldsEnum.RELATION} rules={[{required: true}]}>
                  <PInput label={`${t('form.relationWithProperty')} (${t('form.other')})`} />
                </Form.Item>
              </Col>
            )}
          </>
        ) : null}
        <Col span={4}>
          <Form.Item name={PersonFieldsEnum.NAME} rules={[{required: false}]}>
            <PInput label={t('form.firstName')} id={PersonFieldsEnum.NAME} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name={PersonFieldsEnum.PHONE}
            rules={[
              {required: false},
              {min: 9, message: fieldsMessageTranslations.phoneMin},
              {max: 14, message: fieldsMessageTranslations.phoneMax},
            ]}
          >
            <PPhoneInput label={t('form.phone')} id={PersonFieldsEnum.PHONE} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name={PersonFieldsEnum.EMAIL}
            rules={[
              {
                type: 'email',
                message: fieldsMessageTranslations.validEmail,
              },
              {required: false},
            ]}
          >
            <PInput label={t('form.email')} id={PersonFieldsEnum.EMAIL} type="email" />
          </Form.Item>
        </Col>
        <Col span={24}></Col>
        <Col>
          <CSubmitButton
            allowValidate={false}
            form={form}
            values={values}
            isButtonLoading={isButtonLoading}
            title={t('actions.filter')}
            icon={<Icon.Filter fill="white" />}
          />
        </Col>
        <Col>
          {hasInitialValues && !isButtonLoading && (
            <PButton htmlType="button" type="text" onClick={onReset}>
              {t('actions.clear')}
            </PButton>
          )}
        </Col>
      </Row>
    </Form>
  );
};
