import {Checkbox} from 'antd';
import {memo} from 'react';

import {PFormLabel} from 'components/primitives/p-form-label';

import {CSS} from './p-checkbox.cssconfig';
import {PCheckboxProps} from './p-checkbox.types';

const PCheckbox = ({text, legend, options, ...props}: PCheckboxProps) => {
  return options ? (
    <>
      {legend && <PFormLabel text={legend} isStatic></PFormLabel>}
      <Checkbox.Group className={CSS.groupWrapper} options={options} {...props} />
    </>
  ) : (
    <Checkbox {...props}>{text}</Checkbox>
  );
};

export const PCheckboxMemoized = memo(PCheckbox);
