export const ApiUrl = {
  getPropertyTotals: '/private/super/getPropertyTotals',
  getInquiryTotals: '/private/super/getInquiryTotals',
  getLeadTotals: '/private/super/getLeadTotals',
  getPropertyPerformance: '/private/super/getPropertyPerformance',
  getInquiryPerformance: '/private/super/getInquiryPerformance',
  getLeadPerformance: '/private/super/getLeadPerformance',

  addAccount: 'private/admin/addAccount',
  updateAccount: 'private/admin/updateAccount',
  getAccount: (id: number) => `/private/admin/getAccount/${id}`,

  addLead: '/private/info/addLead',
  updateLead: '/private/info/updateLead',
  addPersonByInfo: '/private/info/addPerson',
  updatePersonByInfo: '/private/info/updatePerson',

  addFolder: '/private/broker/addFolder',
  updateFolder: '/private/broker/updateFolder',
  addAddress: '/private/broker/addAddress',
  addBuilding: '/private/broker/addBuilding',
  updateBuilding: '/private/broker/updateBuilding',
  addPersonByBroker: '/private/broker/addPerson',
  updatePersonByBroker: '/private/broker/updatePerson',
  addProperty: '/private/broker/addProperty',
  updateProperty: '/private/broker/updateProperty',
  addPropertyHold: '/private/broker/addPropertyHold',
  archiveProperty: '/private/broker/archiveProperty',
  updatePropertyPrice: '/private/broker/updatePropertyPrice',
  refreshPropertyPrice: '/private/broker/refreshPrice',
  updatePropertyComments: '/private/broker/updatePropertyComments',
  removePropertyHold: '/private/broker/removePropertyHold',
  disableProperty: '/private/broker/disableProperty',
  enableProperty: '/private/broker/enableProperty',
  addInquiryEvent: '/private/broker/addInquiryEvent',
  updateInquiryInfo: '/private/broker/updateInquiryInfo',
  updateInquiryPersons: '/private/broker/updateInquiryPersons',
  addInquiryBookmark: '/private/broker/addInquiryBookmark',
  removeInquiryBookmark: '/private/broker/removeInquiryBookmark',
  addInquiryEventComment: '/private/broker/addInquiryEventComment',
  setInquiryEventPropertyComment: '/private/broker/setInquiryEventPropertyComment',
  updateInquiryEventSearch: '/private/broker/updateInquiryEventSearch',

  updateWebsiteFields: '/private/marketing/updateWebsiteFields',

  login: '/public/shared/login',
  getProfile: '/private/shared/getProfile',
  getOptgroups: '/private/shared/getOptgroups',
  searchLeads: (offset?: number, length?: number) => `/private/shared/searchLeads/${offset}/${length}`,
  searchBuildings: (offset?: number, length?: number) => `/private/shared/searchBuildings/${offset}/${length}`,
  searchProperties: (offset?: number, length?: number) => `/private/shared/searchProperties/${offset}/${length}`,
  searchPersons: (offset?: number, length?: number) => `/private/shared/searchPersons/${offset}/${length}`,
  searchAccounts: (offset?: number, length?: number) => `/private/admin/searchAccounts/${offset}/${length}`,
  searchStreets: (offset?: number, length?: number) => `/private/shared/searchStreets/${offset}/${length}`,
  searchSettlements: (offset?: number, length?: number) => `/private/shared/searchSettlements/${offset}/${length}`,
  searchDistricts: (offset?: number, length?: number) => `/private/shared/searchDistricts/${offset}/${length}`,
  searchRegions: (offset?: number, length?: number) => `/private/shared/searchRegions/${offset}/${length}`,
  searchCountries: (offset?: number, length?: number) => `/private/shared/searchCountries/${offset}/${length}`,
  searchInquiries: (offset?: number, length?: number) => `/private/shared/searchInquiries/${offset}/${length}`,

  getPersonById: (id: number) => `/private/shared/getPerson/${id}`,
  getLeadById: (id: number) => `/private/shared/getLead/${id}`,
  getPropertyById: (id: number) => `/private/shared/getProperty/${id}`,
  getBuildingById: (id: number) => `/private/shared/getBuilding/${id}`,
  getInquiryById: (id: number) => `/private/shared/getInquiry/${id}`,
  getFolder: (id: number) => `/private/shared/getFolder/${id}`,
};
