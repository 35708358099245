import {ReactComponent as Add} from './add.svg';
import {ReactComponent as AddCircle} from './add-circle.svg';
import {ReactComponent as AddSquare} from './add-square.svg';
import {ReactComponent as Archive} from './archive.svg';
import {ReactComponent as ArrowUp} from './arrow-up.svg';
import {ReactComponent as ArrowLeft} from './arrow-left.svg';
import {ReactComponent as ArrowLeftCircle} from './arrow-left-circle.svg';
import {ReactComponent as ArrowLeftSquare} from './arrow-left-square.svg';
import {ReactComponent as ArrowRightCircle} from './arrow-right-circle.svg';
import {ReactComponent as Apartment} from './apartment.svg';
import {ReactComponent as Building} from './building.svg';
import {ReactComponent as Book} from './book.svg';
import {ReactComponent as Bookmark} from './bookmark.svg';
import {ReactComponent as Calendar} from './calendar.svg';
import {ReactComponent as Calling} from './calling.svg';
import {ReactComponent as CallOutgoing} from './call-outgoing.svg';
import {ReactComponent as CallSlash} from './call-slash.svg';
import {ReactComponent as Car} from './car.svg';
import {ReactComponent as Category} from './category.svg';
import {ReactComponent as Chart} from './chart.svg';
import {ReactComponent as ChevronDownRounded} from './chevron-down-rounded.svg';
import {ReactComponent as ChevronLeft} from './chevron-left.svg';
import {ReactComponent as ChevronLeftRounded} from './chevron-left-rounded.svg';
import {ReactComponent as ChevronRightRounded} from './chevron-right-rounded.svg';
import {ReactComponent as ChevronUpRounded} from './chevron-up-rounded.svg';
import {ReactComponent as CloseCircle} from './close-circle.svg';
import {ReactComponent as CloseSquare} from './close-square.svg';
import {ReactComponent as CopySuccess} from './copy-success.svg';
import {ReactComponent as Document} from './document.svg';
import {ReactComponent as DocumentDownload} from './document-download.svg';
import {ReactComponent as DollarSquare} from './dollar-square.svg';
import {ReactComponent as DragIndicator} from './drag-indicator.svg';
import {ReactComponent as Edit} from './edit.svg';
import {ReactComponent as Eye} from './eye.svg';
import {ReactComponent as EyeSlash} from './eye-slash.svg';
import {ReactComponent as Facebook} from './facebook.svg';
import {ReactComponent as FlagArmenia} from './flag-armenia.svg';
import {ReactComponent as FlagUK} from './flag-united-kingdom.svg';
import {ReactComponent as FlagRussia} from './flag-russia.svg';
import {ReactComponent as File} from './file.svg';
import {ReactComponent as Filter} from './filter.svg';
import {ReactComponent as Like} from './like.svg';
import {ReactComponent as House} from './house.svg';
import {ReactComponent as Global} from './global.svg';
import {ReactComponent as Glass} from './glass.svg';
import {ReactComponent as Instagram} from './instagram.svg';
import {ReactComponent as Information} from './information.svg';
import {ReactComponent as InfoCircle} from './info-circle.svg';
import {ReactComponent as Location} from './location.svg';
import {ReactComponent as LogIn} from './login.svg';
import {ReactComponent as LogOut} from './logout.svg';
import {ReactComponent as Map} from './map.svg';
import {ReactComponent as Message} from './message.svg';
import {ReactComponent as Monitor} from './monitor.svg';
import {ReactComponent as Note} from './note.svg';
import {ReactComponent as NotAvailable} from './not-available.svg';
import {ReactComponent as Search} from './search.svg';
import {ReactComponent as Setting} from './setting.svg';
import {ReactComponent as Star} from './star.svg';
import {ReactComponent as StarSlash} from './star-slash.svg';
import {ReactComponent as Pen} from './pen.svg';
import {ReactComponent as People} from './people.svg';
import {ReactComponent as Paper} from './paper.svg';
import {ReactComponent as Picture} from './picture.svg';
import {ReactComponent as Price} from './price.svg';
import {ReactComponent as Refresh} from './refresh.svg';
import {ReactComponent as Renew} from './renew.svg';
import {ReactComponent as RotateLeft} from './rotate-left.svg';
import {ReactComponent as RotateRight} from './rotate-right.svg';
import {ReactComponent as Ruler} from './ruler.svg';
import {ReactComponent as TaskSquare} from './task-square.svg';
import {ReactComponent as TickSquare} from './tick-square.svg';
import {ReactComponent as TickCircle} from './tick-circle.svg';
import {ReactComponent as Timer} from './timer.svg';
import {ReactComponent as Tree} from './tree.svg';
import {ReactComponent as User} from './user.svg';
import {ReactComponent as UserGroup} from './user-group.svg';
import {ReactComponent as UserSquare} from './user-square.svg';
import {ReactComponent as Upload} from './upload.svg';

export const Icon = {
  Add,
  AddCircle,
  AddSquare,
  Archive,
  ArrowUp,
  ArrowLeft,
  ArrowLeftCircle,
  ArrowLeftSquare,
  ArrowRightCircle,
  Apartment,
  Building,
  Book,
  Bookmark,
  Calendar,
  Calling,
  CallOutgoing,
  CallSlash,
  Car,
  Category,
  Chart,
  ChevronDownRounded,
  ChevronLeft,
  ChevronLeftRounded,
  ChevronRightRounded,
  ChevronUpRounded,
  CloseCircle,
  CloseSquare,
  CopySuccess,
  Document,
  DocumentDownload,
  DollarSquare,
  DragIndicator,
  Edit,
  Eye,
  EyeSlash,
  Facebook,
  FlagArmenia,
  FlagUK,
  FlagRussia,
  File,
  Filter,
  House,
  Global,
  Glass,
  Instagram,
  InfoCircle,
  Information,
  Like,
  Location,
  LogIn,
  LogOut,
  Map,
  Message,
  Monitor,
  Note,
  NotAvailable,
  Search,
  Setting,
  Star,
  StarSlash,
  Paper,
  Pen,
  People,
  Picture,
  Price,
  Ruler,
  Refresh,
  Renew,
  RotateLeft,
  RotateRight,
  TaskSquare,
  TickSquare,
  TickCircle,
  Timer,
  Tree,
  User,
  UserGroup,
  UserSquare,
  Upload,
};
